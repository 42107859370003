import {
    UPDATE_VENDOR_DC_ERROR,
    UPDATE_VENDOR_DC_LIST,
    UPDATE_SELECTED_VENDOR_DC,
    UPDATE_DELETE_VENDOR_FLAG,
    CLEAR_VENDOR_DC_LIST,
    UPDATE_VENDOR_DC_LIST_SPINNER,
    VendorDeleteDCError,
    VendorDeleteDCSuccess,
    FetchError
} from "../../../constants";

import {
    deleteVendorDC,
    getVendorDistributionCenterList
} from '../../../api/vendors';


import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const clearVendorDCList = () => ({
    type: CLEAR_VENDOR_DC_LIST
})

export const getVendorDC = () => async (dispatch, getState) => {
    const {vendor} = getState();
    const {vendorID} = vendor;
    dispatch({
        type: UPDATE_VENDOR_DC_LIST_SPINNER,
        vendorDcListSpinner: true
    })
    try {
        const vendorDCList = await getVendorDistributionCenterList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_DC_LIST,
            vendorDCList: vendorDCList.data
        })
        dispatch({
            type: UPDATE_VENDOR_DC_LIST_SPINNER,
            vendorDcListSpinner: false
        })
    } catch (error) {
        dispatch({
            type: UPDATE_VENDOR_DC_LIST_SPINNER,
            vendorDcListSpinner: false
        })
        if (error === UPDATE_VENDOR_DC_ERROR) {
            toast.error('Unable to fetch distribution center list')
        } else {
            toast.error(FetchError)
        }
    }
}


export const updateDeleteVendorDC = (dcId) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_VENDOR_DC,
        selectedDcID: dcId
    });
    dispatch({
        type: UPDATE_DELETE_VENDOR_FLAG,
        isDeleteUser: true
    });
};

export const handleDeleteVendorDC = () => async (dispatch, getState) => {
    const {vendor} = getState();
    const {vendorID, selectedDcID} = vendor;
    try {
        dispatch({
            type: UPDATE_VENDOR_DC_LIST_SPINNER,
            vendorDcListSpinner: true
        })
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        const response = await deleteVendorDC({
            vendorID,
            selectedDcID
        });
        const vendorDCList = await getVendorDistributionCenterList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_DC_LIST,
            vendorDCList: vendorDCList.data
        })
        dispatch({
            type: UPDATE_SELECTED_VENDOR_DC,
            selectedDcID: null
        });
        dispatch({
            type: UPDATE_VENDOR_DC_LIST_SPINNER,
            vendorDcListSpinner: false
        })
        const {success} = response;
        success ? toast.success(VendorDeleteDCSuccess) : null;
    } catch (error) {
        dispatch({
            type: UPDATE_SELECTED_VENDOR_DC,
            selectedDcID: null
        });
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        dispatch({
            type: UPDATE_VENDOR_DC_LIST_SPINNER,
            vendorDcListSpinner: false
        })
        if (error) {
            toast.error(VendorDeleteDCError)
        }
    }
}