import moment from "moment";
import {saveAs} from "file-saver";
import {
    SHOW_DOWLOAD_SPINNER,
    HIDE_DOWLOAD_SPINNER,
    UPDATE_DOWNLOAD_ICON_VISIBILITY,
    DocumentDownloadError
} from "../../constants";

import {downloadDocumentAPI} from "../../api/documents";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showDowloadSpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_DOWLOAD_SPINNER
    });
};

export const hideDowloadSpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_DOWLOAD_SPINNER
    });
};

export const downloadDocument = (selectedItem) => async (dispatch) => {
    const {
        id,
        name,
        fileextension
    } = selectedItem;
    const currentDate = moment().format('MM_DD_YYYY');
    dispatch(showDowloadSpinner())
    dispatch({
        type: UPDATE_DOWNLOAD_ICON_VISIBILITY,
        downloadIconVisible: true
    });
    try {
        const response = await downloadDocumentAPI(id, name, fileextension);
        saveAs(response, `${name}_${currentDate}.${fileextension}`);
        dispatch({
            type: UPDATE_DOWNLOAD_ICON_VISIBILITY,
            downloadIconVisible: false
        });
        dispatch(hideDowloadSpinner())
    } catch (error) {
        dispatch(hideDowloadSpinner())
        dispatch({
            type: UPDATE_DOWNLOAD_ICON_VISIBILITY,
            downloadIconVisible: false
        });
        toast.error(DocumentDownloadError)
    }
};