import { FC, useState, useEffect } from 'react';
import { MemberActiveEarningsFilter } from '../../header/memberActiveEarnings/MemberActiveEarningsFilter';
import { MemberActiveEarnings } from './MemberActiveEarnings';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../../../../machines';
import serverApiV2Instance from '../../../../../../services/serverApiV2Instance';

export const MembersMain: FC<{
  getRebateMemberOptions: () => void;
  getEarnedRebateQuarters: () => void;
  updateMemberAdminRebatesSelectedMember: () => void;
  updateMemberAdminRebatesQuarter: () => void;
  updateMemberAdminRebatesYear: () => void;
  handleMemberAdminExportQuarter: () => void;
  handleMemberAdminExportYear: () => void;
  onExportMemberAdminRebates: any;
  rebates: {
    rebatesModalSpinner: boolean;
  };
  memberAdminRebatesExportModal: any;
  memberAdminRebates: {
    quarter: any;
    year: any;
    isMemberAdminExport: boolean;
    exportQuarter: any;
    exportYear: any;
    memberProductList: any;
    memberOptionSpinner: any;
    memberOptionList: any;
    selectedMember: any;
  }
}> = ({
  getRebateMemberOptions,
  getEarnedRebateQuarters,
  updateMemberAdminRebatesSelectedMember,
  updateMemberAdminRebatesQuarter,
  updateMemberAdminRebatesYear,
  handleMemberAdminExportQuarter,
  handleMemberAdminExportYear,
  onExportMemberAdminRebates,
  rebates,
  memberAdminRebatesExportModal,
  memberAdminRebates,
}) => {

  const {selectedMember, quarter, year} = memberAdminRebates;
  const [searchText, setSearchText] = useState('');
  const [memberId, setMemberId] = useState<string>(selectedMember?.id.toString());

  useEffect(() => {
    getRebateMemberOptions();
  }, []);

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: 'productName',
        sortOrder: 'asc',
      },
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          quarter : quarter ? quarter : '',
          year: year ? year : '',
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/members/${context.additionalQueryStringValues.memberId ?? memberId}/earned-rebates?${query.toString()}`,
        );
      },
    },
  });

  return (
    <div>
      <MemberActiveEarningsFilter
        searchText={searchText}
        setSearchText={setSearchText}
        getEarnedRebateQuarters={getEarnedRebateQuarters}
        updateMemberAdminRebatesSelectedMember={updateMemberAdminRebatesSelectedMember}
        updateMemberAdminRebatesQuarter={updateMemberAdminRebatesQuarter}
        updateMemberAdminRebatesYear={updateMemberAdminRebatesYear}
        handleMemberAdminExportQuarter={handleMemberAdminExportQuarter}
        handleMemberAdminExportYear={handleMemberAdminExportYear}
        onExportMemberAdminRebates={onExportMemberAdminRebates}
        rebates={rebates}
        memberAdminRebatesExportModal={memberAdminRebatesExportModal}
        memberAdminRebates={memberAdminRebates}
        state={state}
        send={send}
      />
      <MemberActiveEarnings
        state={state}
        send={send}
      />
    </div>
  );
};

export default MembersMain;
