import { createMachine, sendParent } from 'xstate';
import { OpportunityQuarter } from '../../../../../common';
import { saveAs } from 'file-saver';
import { baseUrl } from '../../../../../constants';

interface OpportunitiesExportContext {
  selectedOpportunityQuarter: OpportunityQuarter;
}

type OpportunitiesExportEvent = { type: 'cancel' } | { type: 'export' };

export const opportunitiesExportMachine = createMachine(
  {
    id: 'opportunitiesExport',
    tsTypes: {} as import('./opportunitiesExportMachine.typegen').Typegen0,
    schema: {
      context: {} as OpportunitiesExportContext,
      events: {} as OpportunitiesExportEvent,
      services: {} as {
        'export rebate opportunities': {
          data: File | Blob;
        };
      },
    },
    initial: 'confirming export',
    states: {
      'confirming export': {
        on: {
          cancel: {
            actions: 'send parent close event',
          },
          export: 'exporting',
        },
      },
      exporting: {
        tags: ['loading', 'exporting', 'submitting'],
        invoke: {
          id: 'export rebate opportunities',
          src: 'export rebate opportunities',
          onDone: 'complete',
          onError: {
            actions: 'notify failure',
            target: 'confirming export',
          },
        },
      },
      complete: { entry: 'save file', type: 'final' },
    },
  },
  {
    actions: {
      'send parent close event': sendParent('close modal'),
      'notify failure': () =>
        console.error('Failed to export rebate opportunities.'),
      'save file': (context, event) => {
        saveAs(
          event.data,
          `rebate_product_list_documents/${context.selectedOpportunityQuarter.year}/${context.selectedOpportunityQuarter.quarter}/${context.selectedOpportunityQuarter.id}.xlsx`,
        );
      },
    },
    services: {
      'export rebate opportunities': (context) => {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        return fetch(
          `${baseUrl}/rebate-product-list-records/export?quarter=${context.selectedOpportunityQuarter.quarter}&year=${context.selectedOpportunityQuarter.year}`,
          {
            headers: new Headers({
              'x-access-token': user.accessToken,
              'Content-Type': 'application/json',
            }),
          },
        ).then((response) => {
          return response.blob();
        });
      },
    },
  },
);

export type OpportunitiesExportMachine = typeof opportunitiesExportMachine;
export default opportunitiesExportMachine;
