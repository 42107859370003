import {
  UPDATE_MANAGEADS_SPINNER,
  SHOW_ADD_MANAGEADS_MODAL,
  HIDE_ADD_MANAGEADS_MODAL,
  SHOW_ADSTYPE_MODAL,
  HIDE_ADSTYPE_MODAL,
  UPDATE_ADSTYPE_LIST,
  UPDATE_ADS_LIST,
  SHOW_VIEW_AD_MODAL,
  HIDE_VIEW_AD_MODAL,
  UPDATE_SELECTED_VIEW_AD,
} from "../../constants";

const initialState = {
  loading: false,
  showAddModal: false,
  showAddTypeModal: false,
  showViewAdModal: false,
  adsTypeList: [],
  adsList: [],
  selectedAd: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_MANAGEADS_SPINNER:
      return {
        ...state,
        loading: action.spinner,
      };
    case HIDE_ADD_MANAGEADS_MODAL:
      return {
        ...state,
        showAddModal: false,
      };
    case SHOW_ADD_MANAGEADS_MODAL:
      return {
        ...state,
        showAddModal: true,
      };
    case HIDE_ADSTYPE_MODAL:
      return {
        ...state,
        showAddTypeModal: false,
      };
    case SHOW_ADSTYPE_MODAL:
      return {
        ...state,
        showAddTypeModal: true,
      };
    case UPDATE_ADSTYPE_LIST:
      return {
        ...state,
        adsTypeList: action.list,
      };
    case UPDATE_ADS_LIST:
      return {
        ...state,
        adsList: action.list,
      };
    case SHOW_VIEW_AD_MODAL:
      return {
        ...state,
        showViewAdModal: true,
      };
    case HIDE_VIEW_AD_MODAL:
      return {
        ...state,
        showViewAdModal: false,
      };
    case UPDATE_SELECTED_VIEW_AD:
      return {
        ...state,
        selectedAd: action.item,
      }
    default:
      return state;
  }
};
