import {
  Button,
  Dialog,
} from '@material-ui/core';
import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from '../../../components/styledDialogChildren';
import { getFileSize, Loader } from '../../../utils';
import { OpportunitiesExportMachine } from './machines/opportunitiesExportMachine';

export const OpportunitiesExportModal: FC<{
  opportunitiesEditUploadActor: ActorRefFrom<OpportunitiesExportMachine>;
}> = ({ opportunitiesEditUploadActor }) => {
  const [state, send] = useActor(opportunitiesEditUploadActor);

  return (
    <Dialog
      open
      aria-labelledby="customized-dialog-title"
      className="dialog-box"
    >
      <StyledDialogTitle onClose={() => send('cancel')}>
        Export uploaded rebate opportunities
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className="edit-doc-section">
          <div className="edit-doc-section-box">
            <div className="documents-grid-img">
              <i className="far fa-file-excel" />
              <span className="documents-doc-type doc-xls">xlsx</span>
            </div>

            <div className="edit-doc-section-name">
              rebate_product_list_documents/
              {state.context.selectedOpportunityQuarter.year}/
              {state.context.selectedOpportunityQuarter.quarter}/
              {state.context.selectedOpportunityQuarter.id}.xlsx
            </div>
            <div className="edit-doc-section-size">
              {getFileSize(
                state.context.selectedOpportunityQuarter.fileSize ?? 0,
              )}
            </div>
          </div>
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div className="action-button">
            <button
              className="cancel-user-button"
              onClick={() => send('cancel')}
            >
              Cancel
            </button>
            {/* <a
              href={`/rebate-product-list-records/export?quarter=${state.context.selectedOpportunityQuarter.quarter}&year=${state.context.selectedOpportunityQuarter.year}`}
              download={`rebate_product_list_documents/${state.context.selectedOpportunityQuarter.year}/${state.context.selectedOpportunityQuarter.quarter}/${state.context.selectedOpportunityQuarter.id}.xlsx`}
            > */}
              <Button
                autoFocus
                onClick={() => send('export')}
                className={
                  state.hasTag('loading')
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={state.hasTag('loading')}
              >
                Export uploaded document{' '}
                {state.hasTag('loading') && <Loader size={20} />}
              </Button>
            {/* </a> */}
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OpportunitiesExportModal;
