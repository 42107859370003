import React from 'react';
import Grid from '@material-ui/core/Grid';
// default menu
import { NoAccessForInvalidPassword } from './NoAccessForInvalidPassword';

import '../../../css/fonts.css';
import '../../../css/all.min.css';
import '../../../css/style.css';
import './noaccessforinvalidpassword.scss';

export const PageNoAccessForInvalidPassword = () => {
  return (
    <div>
      <Grid item xs={10} className="body-container">
        <NoAccessForInvalidPassword />
      </Grid>
    </div>
  );
};
