import { FC, useEffect, useState } from 'react';
import { CategoryHeader, ChannelList } from './communityChannel';
import './community.scss';
import '../../scss/styles.scss';
import CommunityQuestionsDetail from './communityDiscussion/CommunityQuestionsDetail';
import HeaderWrapper from './communityDiscussion/HeaderWrapper';
import ChannelModal from './modal/ChannelModal';
import CommentReportModal from './modal/CommentReportModal';
import DiscussionReportModal from './modal/DiscussionReportModal';
import { useSelector } from 'react-redux';

export const CommunityMain: FC<any> = (props) => {
  const {
    communityDiscussion,
    communityCommentActions,
    getCommunityData,
    handleReportReason,
    getReportReasonList,
    handleReportDiscussion,
    handleReport,
    isReported,
    handleCommentReportChange,
    handleCommentReport,
    isCommentReported,
    handleCommentReportDiscussion,
    updateActiveDiscussionData,
    updateQuestionDetail,
  } = props;

  const {
    isQuestionDetail,
    activeDiscussion,
    isReport,
    reportReasonId,
    reportReasonList,
    discussionFormSpinner,
    isAlreadyReported,
  } = communityDiscussion;

  const { isCommentReport } = communityCommentActions;

  useEffect(() => {
    getCommunityData();

    return () => {
      updateActiveDiscussionData(null);
      updateQuestionDetail(false);
    };
  }, []);

  const [searchText, setSearchText] = useState('');
  const [isSearch, setSearch] = useState(false);
  const [isAutoFocus, setAutoFocus] = useState(false);
  const [showDetailedDiscussion, setDetailedDiscussion] = useState(false);

  const handleDiscussionReportClose = () => {
    handleReport(false, activeDiscussion);
    isReported(false);
  };

  const handleCommentReportClose = () => {
    handleCommentReport(false, activeDiscussion);
    isCommentReported(false);
  };
  const store = useSelector((state) => state)

  const {user}:any = store

  return (
    <div className="community">
      <div className="community-category">
        <CategoryHeader
          {...props}
          searchText={searchText}
          setSearchText={setSearchText}
          isSearch={isSearch}
          setSearch={setSearch}
        />
        <ChannelList
          {...props}
          searchText={searchText}
          isSearch={isSearch}
          setDetailedDiscussion={setDetailedDiscussion}
        />
      </div>
      <div
        className={
          showDetailedDiscussion
            ? 'community-section community-section-visible'
            : 'community-section'
        }
      >
        {!isQuestionDetail && (
          <HeaderWrapper
            {...props}
            setAutoFocus={setAutoFocus}
            setDetailedDiscussion={setDetailedDiscussion}
            isViewAsMember={user?.isViewAsMember}
          />
        )}
        {isQuestionDetail && (
          <CommunityQuestionsDetail
            {...props}
            activeDiscussion={activeDiscussion}
            isAutoFocus={isAutoFocus}
            setAutoFocus={setAutoFocus}
          />
        )}
        <ChannelModal {...props} />

        {isReport && (
          <DiscussionReportModal
            openModal={isReport}
            handleClose={handleDiscussionReportClose}
            handleReportReason={handleReportReason}
            reportReasonId={reportReasonId}
            getReportReasonList={getReportReasonList}
            reportReasonList={reportReasonList}
            discussionFormSpinner={discussionFormSpinner}
            handleReportDiscussion={handleReportDiscussion}
            isAlreadyReported={isAlreadyReported}
          />
        )}

        {isCommentReport && (
          <CommentReportModal
            openModal={isCommentReport}
            handleClose={handleCommentReportClose}
            handleCommentReportChange={handleCommentReportChange}
            communityCommentActions={communityCommentActions}
            getReportReasonList={getReportReasonList}
            reportReasonList={reportReasonList}
            handleCommentReportDiscussion={handleCommentReportDiscussion}
          />
        )}
      </div>
    </div>
  );
};

export default CommunityMain;
