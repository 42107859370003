import {baseUserUrl, UPDATE_MEMBER_USER_DELETE_ERROR} from "../../constants";

export const deleteMemberUser = async ({
    memberUserId,
    memberId,
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUserUrl}${memberUserId}`, {
            method: "DELETE",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                memberId: memberId
            })
        });
        if (!response.ok) throw UPDATE_MEMBER_USER_DELETE_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_MEMBER_USER_DELETE_ERROR;
    }
};