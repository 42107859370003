import { FC, useEffect } from 'react';
import { FormControl, Grid, InputLabel, Select } from '@material-ui/core';
import { ProductRebatesFileUpload } from './ProductRebatesFileUpload';
import { DateTime } from 'luxon';

const quarterList = [
  {
    label: 'Q1',
    value: 1,
  },
  {
    label: 'Q2',
    value: 2,
  },
  {
    label: 'Q3',
    value: 3,
  },
  {
    label: 'Q4',
    value: 4,
  },
];

const lastYear = `${DateTime.now().minus({ years: 1 }).year}`;
const currentYear = `${DateTime.now().year}`;
const nextYear = `${DateTime.now().plus({ years: 1}).year}`;

const yearList = [
  {
    label: lastYear,
    value: lastYear,
  },
  {
    label: currentYear,
    value: currentYear,
  },
  {
    label: nextYear,
    value: nextYear,
  },
];

export const ProductRebatesModal: FC<any> = (props) => {
  const {
    productRebates: {
      quarter,
      year,
      isEdit,
    },
    onChangeProductRebatesQuarter,
    onChangeProductRebatesYear,
    clearProductRebatesModal,
  } = props;

  useEffect(() => {
    return () => {
      clearProductRebatesModal();
    };
  }, []);

  return (
    <div className="upload-rebateform">
      <Grid container spacing={2} className="upload-rebateform-grid">
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Fiscal Quarter
            </InputLabel>
            <Select
              value={quarter}
              onChange={(event) =>
                onChangeProductRebatesQuarter(event.target.value)
              }
              label="Select Quarter"
              disabled={isEdit}
              style={{letterSpacing:"0.0958em"}}
            >
              {quarterList &&
                quarterList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Year
            </InputLabel>
            <Select
              value={year}
              onChange={(event) =>
                onChangeProductRebatesYear(event.target.value)
              }
              label="Select Year"
              disabled={isEdit}
            >
              {yearList &&
                yearList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <ProductRebatesFileUpload {...props} />
    </div>
  );
};

export default ProductRebatesModal;
