import { assign } from '@xstate/immer';
import { DateTime } from 'luxon';
import { createMachine, sendParent } from 'xstate';
import { IntervalType, IntervalTypes } from '../../common';

export const timeFrameIntervalMachine = createMachine(
  {
    id: 'timeFrameInterval',
    schema: {
      context: {} as {
        intervalTypes: IntervalTypes;
        intervalValueLists: {
          month: { label: string; value: number }[];
          quarter: { label: string; value: number }[];
        };
        selectedIntervalType: IntervalType | null;
        selectedIntervalValue: number | null;
        selectedYear: number;
        years: number[];
      },
      events: {} as
        | { type: 'updateSelectedIntervalType'; intervalType: IntervalType }
        | { type: 'updateSelectedIntervalValue'; intervalValue: number }
        | { type: 'updateSelectedYear'; year: number },
    },
    tsTypes: {} as import('./timeFrameIntervalMachine.typegen').Typegen0,
    context: {
      intervalTypes: ['month', 'quarter', 'year'],
      intervalValueLists: {
        month: Array.from(Array(12)).map((_, index) => ({
          label: DateTime.now()
            .set({ month: index + 1 })
            .toLocaleString({ month: 'long' }),
          value: index + 1,
        })),
        quarter: Array.from(Array(4)).map((_, index) => ({
          label: `${index + 1}`,
          value: index + 1,
        })),
      },
      selectedIntervalType: null,
      selectedIntervalValue: null,
      selectedYear: DateTime.now().year,
      years: Array.from(Array(5)).map(
        (_, years) => DateTime.now().minus({ years }).year,
      ),
    },
    on: {
      updateSelectedIntervalType: {
        actions: ['assignSelectedIntervalType', 'forwardEventToParent'],
        cond: 'noReassignSameSelectedIntervalType',
      },
      updateSelectedIntervalValue: {
        actions: ['assignSelectedIntervalValue', 'forwardEventToParent'],
        cond: 'noReassignSameSelectedIntervalValue',
      },
      updateSelectedYear: {
        actions: ['assignSelectedYear', 'forwardEventToParent'],
        cond: 'noReassignSameSelectedYear',
      },
    },
  },
  {
    actions: {
      assignSelectedYear: assign((context, event) => {
        if (
          DateTime.now().year !== event.year &&
          !context.selectedIntervalType
        ) {
          context.selectedIntervalType = 'quarter';
          context.selectedIntervalValue = 1;
        }
        context.selectedYear = event.year;
      }),
      assignSelectedIntervalType: assign((context, event) => {
        if (event.intervalType === 'month') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'quarter') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'half-year') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'year') {
          context.selectedIntervalValue = null;
        }
        context.selectedIntervalType = event.intervalType;
      }),
      assignSelectedIntervalValue: assign((context, event) => {
        let intervalValue = context.selectedIntervalValue;
        if (context.selectedIntervalType === 'month') {
          let monthValue = event.intervalValue;
          if (monthValue < 1) {
            monthValue = 1;
          }
          if (monthValue > 12) {
            monthValue = 12;
          }
          intervalValue = monthValue;
        }
        if (context.selectedIntervalType === 'quarter') {
          let quarterValue = event.intervalValue;
          if (quarterValue > 4) {
            quarterValue = 4;
          }
          if (quarterValue < 1) {
            quarterValue = 1;
          }
          intervalValue = quarterValue;
        }
        if (context.selectedIntervalType === 'half-year') {
          let halfYearValue = event.intervalValue;
          if (halfYearValue < 1) {
            halfYearValue = 1;
          }
          if (halfYearValue > 2) {
            halfYearValue = 2;
          }
          intervalValue = halfYearValue;
        }
        if (context.selectedIntervalType === 'year') {
          intervalValue = null;
        }
        context.selectedIntervalValue = intervalValue;
      }),
      forwardEventToParent: sendParent((_, event) => event),
    },
    guards: {
      noReassignSameSelectedIntervalType: (context, event) =>
        event.intervalType !== context.selectedIntervalType,
      noReassignSameSelectedIntervalValue: (context, event) =>
        event.intervalValue !== context.selectedIntervalValue,
      noReassignSameSelectedYear: (context, event) =>
        event.year !== context.selectedYear,
    },
  },
);

export type TimeFrameIntervalMachine = typeof timeFrameIntervalMachine;

export default timeFrameIntervalMachine;
