export function ZipcodeFormat(value) {
  let formattedValue = value
    ? value.length === 9
      ? value.replace(/(\d{5})(\d{4})/, "$1-$2")
      : value
    : "";
  return formattedValue;
}

export const removeZipcodeFormat = (value) => {
  return value.replace(/-|\s/g, "").trim();
};

export const isValidZipcode = (value) => {
  return !value || value.length <= 4 || (value.length >= 6 && value.length <= 8)
    ? false
    : true;
};
