import { isConstructorDeclaration } from "typescript";
import {
    SHOW_COMMUNITY_DISCUSSION_SPINNER,
    HIDE_COMMUNITY_DISCUSSION_SPINNER,
    SHOW_DISCUSSION_DETAIL_SPINNER,
    HIDE_DISCUSSION_DETAIL_SPINNER,
    SHOW_DISCUSSION_FORM_SPINNER,
    HIDE_DISCUSSION_FORM_SPINNER,
    SHOW_LOAD_MORE_SPINNER,
    HIDE_LOAD_MORE_SPINNER,
    UPDATE_SELECTED_TAB,
    UPDATE_ALL_DISCUSSIONS,
    UPDATE_QUESTION_DETAIL,
    UPDATE_ACTIVE_DISCUSSION,
    UPDATE_ACTIVE_DISCUSSION_COMMENTS,
    UPDATE_DISCUSSION_REPORTED_FLAG,
    UPDATE_TOTAL_COMMENTS_COUNT,
    UPDATE_DISCUSSION_LOAD_MORE,
    UPDATE_DISCUSSION_TITLE_ERROR,
    UPDATE_DISCUSSION_TITLE,
    UPDATE_DISCUSSION_DESC_ERROR,
    UPDATE_DISCUSSION_DESC,
    UPDATE_DISCUSSION_INPUT_FORM,
    CLEAR_COMMUNITY_DISCUSSION_MODAL,
    HANDLE_NEW_DISCUSSION_MODAL,
    UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER,
    UPDATE_EDIT_DISCUSSION_MODAL,
    UPDATE_REPORT_FLAG,
    UPDATE_REPORT_REASON,
    UPDATE_REPORT_REASON_LIST
} from "../../constants";

const initialState = {
    selectedTab: 0,
    discussionSpinner: false,
    discussionDetailSpinner: false,
    loadMoreSpinner: false,
    discussionFormSpinner: false,
    discussionList: null,
    isQuestionDetail: false,
    activeDiscussion: null,
    activeDiscussionComments: [],
    isLoadMore: false,
    discussionTitle: null,
    discussionDesc: null,
    discussionTitleError: false,
    discussionDescError: false,
    isDiscussionFormUpdated: false,
    isNewDiscussionModal: false,
    pageNumber: 1,
    totalCount: null,
    isEditDiscussion: false,
    reportReasonList: [],
    isReport: false,
    isAlreadyReported: false,
    reportReasonId: null
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case UPDATE_SELECTED_TAB:
            return ({
                ...state,
                selectedTab: action.selectedTab
            });
        case SHOW_COMMUNITY_DISCUSSION_SPINNER:
            return ({
                ...state,
                discussionSpinner: true
            });
        case HIDE_COMMUNITY_DISCUSSION_SPINNER:
            return ({
                ...state,
                discussionSpinner: false
            });
        case SHOW_DISCUSSION_DETAIL_SPINNER:
            return ({
                ...state,
                discussionDetailSpinner: true
            });
        case HIDE_DISCUSSION_DETAIL_SPINNER:
            return ({
                ...state,
                discussionDetailSpinner: false
            });
        case SHOW_LOAD_MORE_SPINNER:
            return ({
                ...state,
                loadMoreSpinner: true
            });
        case HIDE_LOAD_MORE_SPINNER:
            return ({
                ...state,
                loadMoreSpinner: false
            });
        case SHOW_DISCUSSION_FORM_SPINNER:
            return ({
                ...state,
                discussionFormSpinner: true
            });
        case HIDE_DISCUSSION_FORM_SPINNER:
            return ({
                ...state,
                discussionFormSpinner: false
            });
        case UPDATE_ALL_DISCUSSIONS:
            return {
                ...state,
                discussionList: action.discussionList
            };
        case UPDATE_TOTAL_COMMENTS_COUNT:
            return ({
                ...state,
                totalCount: action.totalCount
            });
        case UPDATE_QUESTION_DETAIL:
            return {
                ...state,
                isQuestionDetail: action.isQuestionDetail
            };
        case UPDATE_ACTIVE_DISCUSSION:
            return {
                ...state,
                activeDiscussion: action.activeDiscussion
            };
        case UPDATE_ACTIVE_DISCUSSION_COMMENTS:
            return {
                ...state,
                activeDiscussionComments: action.activeDiscussionComments
            };
        case UPDATE_DISCUSSION_LOAD_MORE:
            return {
                ...state,
                isLoadMore: action.isLoadMore
            };
        case UPDATE_DISCUSSION_TITLE_ERROR:
            return {
                ...state,
                discussionTitleError: action.discussionTitleError
            };
        case UPDATE_DISCUSSION_TITLE:
            return {
                ...state,
                discussionTitle: action.discussionTitle
            };
        case UPDATE_DISCUSSION_DESC_ERROR:
            return {
                ...state,
                discussionDescError: action.discussionDescError
            };
        case UPDATE_DISCUSSION_DESC:
            return {
                ...state,
                discussionDesc: action.discussionDesc
            };
        case UPDATE_DISCUSSION_INPUT_FORM:
            return {
                ...state,
                isDiscussionFormUpdated: action.isDiscussionFormUpdated
            };
        case HANDLE_NEW_DISCUSSION_MODAL:
            return {
                ...state,
                isNewDiscussionModal: action.isNewDiscussionModal
            };
        case UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER:
            return {
                ...state,
                pageNumber: action.pageNumber
            };
        case UPDATE_EDIT_DISCUSSION_MODAL:
            return {
                ...state,
                isEditDiscussion: action.isEditDiscussion
            };
        case UPDATE_REPORT_FLAG:
            return {
                ...state,
                isReport: action.isReport
            };
        case UPDATE_DISCUSSION_REPORTED_FLAG:
            return {
                ...state,
                isAlreadyReported: action.isAlreadyReported
            };
        case UPDATE_REPORT_REASON:
            return {
                ...state,
                reportReasonId: action.reportReasonId
            };
        case UPDATE_REPORT_REASON_LIST:
            return {
                ...state,
                reportReasonList: action.reportReasonList
            };
        case CLEAR_COMMUNITY_DISCUSSION_MODAL:
            return {
                ...state,
                discussionFormSpinner: false,
                discussionTitle: null,
                discussionDesc: null,
                discussionTitleError: false,
                discussionDescError: false,
                isDiscussionFormUpdated: false
            };
        default:
            return state;
    }
};
