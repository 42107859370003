import React from "react";
import Grid from "@material-ui/core/Grid";
import {ProfileSideNav} from "../../components/sideNav";
import "./notifications.scss";
import "../profile.scss";

export const NotificationComponent = (props) => {
  return (
    <div className="body-container">
      <Grid container className="change-password">
        <ProfileSideNav {...props} />
        <Grid item xs={9} className="change-password-right">
          <div className="messages">
            <h2 className="fund-transfer-heading">Notifications</h2>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
