import { EARNED_REBATES_MACHINE } from '../../constants';

const initialState = {
  selectedMemberId: null,
  acquiredAmount: 0,
  availableAmount: 0,
  pendingAmount: 0,
};

export default (
  state = initialState,
  action: { type: string; [key: string]: any }
) => {
  switch (action.type) {
    case EARNED_REBATES_MACHINE:
      return action.payload;
    default:
      return state;
  }
};
