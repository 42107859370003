import { toast } from 'react-toastify';
import { Dispatch } from 'redux';
import 'react-toastify/dist/ReactToastify.css';
import {
  CLEAR_CHANGE_PASSWORD,
  UPDATE_PASSWORD_USER_SPINNER,
  UPDATE_PASSWORD_USER_OLD_PASSWORD,
  UPDATE_PASSWORD_USER_NEW_PASSWORD,
  UPDATE_PASSWORD_USER_CONFIRM_PASSWORD,
  UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR,
  UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR,
  UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR,
  UPDATE_PASSWORD_NEW_PWD_MESSAGE,
  UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE,
  UPDATE_PASSWORD_SHOW_STATUS,
  UPDTAE_PASSOWRD_IS_REDIRECT,
  UPDATE_CHANGE_PASSWORD_ERROR,
  ChangePasswordSuccess,
  ChangePasswordError,
} from '../../constants';
import { updatePasswordApi } from '../../api/profile';
import { userData } from '../../api/userData';

export const clearChangePassword = () => ({
  type: CLEAR_CHANGE_PASSWORD,
});

export const onChangeOldPassword = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_USER_OLD_PASSWORD,
    value: value,
  });
  dispatch({
    type: UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR,
    flag: value == '' ? true : false,
  });
};

export const onChangeNewPassword = (value: string) => (dispatch: Dispatch) => {
  dispatch({
    type: UPDATE_PASSWORD_USER_NEW_PASSWORD,
    value: value,
  });
  let errorMessage = validatePassword(value);
  dispatch({
    type: UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR,
    flag: errorMessage === '' ? false : true,
  });
  dispatch({
    type: UPDATE_PASSWORD_NEW_PWD_MESSAGE,
    value: errorMessage,
  });
};

export const onChangeConfirmPassword =
  (value: string) =>
  (
    dispatch: Dispatch,
    getState: () => {
      changePasswordForm: {
        newPassword: string;
      };
    },
  ) => {
    const { changePasswordForm } = getState();
    const { newPassword } = changePasswordForm;

    dispatch({
      type: UPDATE_PASSWORD_USER_CONFIRM_PASSWORD,
      value: value,
    });
    dispatch({
      type: UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR,
      flag: value == '' || value != newPassword ? true : false,
    });
    dispatch({
      type: UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE,
      value:
        value == ''
          ? 'Please enter confirm password'
          : value != newPassword
          ? 'New password not matched'
          : '',
    });
  };
export const handleChangePasswordShow =
  (elementKey: string) =>
  (
    dispatch: Dispatch,
    getState: () => {
      changePasswordForm: {
        newPassword: string;
        passwordShowStatus: {
          [key: string]: boolean;
        };
      };
    },
  ) => {
    const { changePasswordForm } = getState();
    const { passwordShowStatus } = changePasswordForm;

    let currentValue = passwordShowStatus[elementKey];
    passwordShowStatus[elementKey] = !currentValue;

    dispatch({
      type: UPDATE_PASSWORD_SHOW_STATUS,
      value: passwordShowStatus,
    });
  };

export const onSubmitChangePassword =
  () =>
  async (
    dispatch: Dispatch,
    getState: () => {
      changePasswordForm: {
        confirmPassword: string;
        newPassword: string;
        oldPassword: string;
      };
      user: { id: number };
    },
  ) => {
    const { changePasswordForm, user } = getState();
    const { id } = user;
    const { oldPassword, newPassword, confirmPassword } = changePasswordForm;

    const isValidOldPassword = oldPassword == '' ? false : true;
    const isValidNewPassword =
      validatePassword(newPassword) == '' ? true : false;
    const isValidConfirmPassword =
      newPassword == confirmPassword ? true : false;

    const isError =
      !isValidOldPassword || !isValidNewPassword || !isValidConfirmPassword;

    if (!isValidOldPassword) {
      dispatch({
        type: UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR,
        flag: true,
      });
    }
    if (!isValidNewPassword) {
      dispatch({
        type: UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR,
        flag: true,
      });
    }
    if (!isValidConfirmPassword) {
      dispatch({
        type: UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR,
        flag: true,
      });
    }

    try {
      if (!isError) {
        dispatch({
          type: UPDATE_PASSWORD_USER_SPINNER,
          flag: true,
        });
        const response = await updatePasswordApi({
          id,
          oldPassword,
          newPassword,
          confirmPassword,
        });
        const { success, message, data } = response;
        success ? toast.success(ChangePasswordSuccess) : null;
        if (success) {
          //fetching the updated user data.
          const userDataResponse = await userData();
          if (userDataResponse?.success) {
            localStorage.setItem("user", JSON.stringify(userDataResponse?.data?.user));
            dispatch({
              type: "SET_USER",
              payload: userDataResponse?.data?.user,
            });
          }
          dispatch({
            type: UPDTAE_PASSOWRD_IS_REDIRECT,
            flag: true,
          });
        }
        dispatch({
          type: UPDATE_PASSWORD_USER_SPINNER,
          flag: false,
        });
        if (!success) {
          toast.error(message);
        }
      }
    } catch (error) {
      dispatch({
        type: UPDATE_PASSWORD_USER_SPINNER,
        flag: false,
      });
      if (error == UPDATE_CHANGE_PASSWORD_ERROR) {
        toast.error(ChangePasswordError);
      }
    }
  };

const validatePassword = (value: string) => {
  if (
    ![
      value.match(/[a-z]/g),
      value.match(/[A-Z]/g),
      value.match(/[0-9]/g),
      value.match(/[^a-zA-Z\d]/g),
    ].every((value) => value)
  ) {
    return 'Password combination should have 1 uppercase, 1 lowercase, 1 number and 1 special character.';
  } else if (value.length < 12) {
    return 'New password should have min 12 characters';
  }
  return '';
};
