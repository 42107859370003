import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { BRDropdown } from "../../../../components/BRDropdown";
import { SearchNotFound } from "../../../../components/SearchNotFound";
import { useMachine, useActor } from "@xstate/react";
import { Member } from "../../../../common";
import { dashboardEarnedRebatesMachine } from "../../../dashboard/section/DashboardEarnedRebates/machines";
import { getRebateReceiptsList } from "../../../../api/rebates/rebateReceipts/getEarnedRebatesOverview";
import { exportEarnedRebatesOverview } from "../../../../api/rebates/rebateReceipts/exportEarnedRebatesOverview";
import { saveAs } from "file-saver";
import moment from "moment";
import {
  centsToFormattedDollars,
  formatPhoneNumber,
  useStyles,
} from "../../../../utils";
import DefaultUserImg from "../../../../images/profile-placeholder.jpg";
import sortDefault from "../../../../images/sortDefault.svg";
import sortAscIcon from "../../../../images/sortAsc.svg";
import sortDescIcon from "../../../../images/sortDesc.svg";
import { NavLink } from "react-router-dom";
import useSortableData from "./useSortableData";
import AuthorizationService from '../../../../authorization/AuthorizationService';
import { handleSendReminderEmail } from '../../../../actions/rebates/overView';

export const OverviewTable: FC<any> = () => {
  const [state] = useMachine(dashboardEarnedRebatesMachine);
  const [memberState, memberSend] = useActor(state.children.members);
  const [members, setMembers] = useState<any>([]);
  const [selectedMebers, setSelectedMembers] = useState(0);
  const [quartesYear, setQuartersYears] = useState<[]>([]);
  const [OverAllData, setOverAllData] = useState<any>();
  const [selectedQuarterYear, setSelectedQuarterYear] = useState<
    any | number | string
  >({
    year: 0,
    quarter: 0,
    id: 0,
  });

  useEffect(() => {
    setMembers([
      {
        id: 0,
        name: "All",
      },
      ...memberState.context.members,
    ]);
  }, [memberState.context.members]);

  useEffect(() => {
    getRebateReceiptsList(
      selectedMebers
    )
      .then((res) => {
        const AlterData = Object.keys(res.data.data).map((i, index) => {
          return {
            name: i,
            total: res.data.data[i].total,
            data: res.data.data[i].data,
          };
        });
        setOverAllData(AlterData);
      })
      .catch((err) => console.log(err));
  }, [selectedMebers]);

  const ExportRebates = async () => {
    let data = {
      memberId: selectedMebers,
      quarter: selectedQuarterYear.quarter,
      year: selectedQuarterYear.year,
    };

    const response = await exportEarnedRebatesOverview(data);
    const date = new Date();
    const fileName = `Earned_Rebates_Overview${moment(date).format(
      "DD_MMM_YYYY_hh:mm:ss_A"
    )}.xls`;

    saveAs(response, fileName);
  };

  const showExport = AuthorizationService.isAuthorizedHTML('rebateOverviewExport');

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        sx={{
          mt: 2,
          pt: 4,
          mb: 2,
          borderTop: 1,
          display: "grid",
          gridTemplateColumns: "1fr min-content",
          alignItems: "center",
          gap: 1,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {/* <p
            style={{
              padding: "0 1rem",
            }}
          >
            Selected Member
          </p> */}
          <BRDropdown
            selectedOption={selectedMebers || 0}
            disabled={members.length <= 1}
            menuList={members.map((member: Member) => ({
              value: member.id,
              label: member.name,
            }))}
            isMemberList = {true}
            handleSelected={(item: any) => {
              const memberId = item?.value;
              // console.log(memberId);
              setSelectedMembers(memberId);
              if (memberId) {
                memberSend({
                  memberId,
                  type: "updateSelectedMember",
                });
              }
            }}
            spinner={memberState.hasTag("loading")}
          />
        </div>
        { showExport && <Button
          onClick={ExportRebates}
          variant="contained"
          size="small"
          className="btn-add-blue"
        >
          <span className="fas fa-file-export" />
          <span>Export</span>
        </Button>}
      </Grid>

      {/* Table */}
      <TableContainer>
        <Table
          aria-label="collapsible table"
          sx={{
            border: 0,
          }}
          className="rebates-overview-table"
        >
          {OverAllData?.length !== 0 ? (
            <TableBody>
              {OverAllData?.map(
                (row: { name: string; data: []; total: number }) => (
                  <Row key={row.name} row={row} />
                )
              )}
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <SearchNotFound sectionType={"Rebates Info"} />
                {/* <TableCell align="center">No Data</TableCell> */}
              </TableRow>
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </>
  );
};

function Row(props: any) {
  const { row } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const showAction = AuthorizationService.isAuthorizedHTML('overviewWidgetAction');

  const gridHeader = [
    {
      label: "Name",
      value: "name",
      sortKey: "MemberName",
      xs: 12,
      lg: row.name !== "Available" ? 2 : 3,
      display: "block",
    },
    {
      label:  "Member Admin",
      value: "salesPersonFirsttName",
      sortKey: "Sales Personnel",
      xs: 12,
      lg: 2,
      display: "block",
    },
    {
      label: "Member's Mobile Phone",
      value: "mobile",
      sortKey: "Sales Mobile Phone",
      xs: 12,
      lg: 2,
      display: "block",
    },
    {
      label: "Location",
      value: "location",
      sortKey: "Location",
      xs: 12,
      lg: 3,
      display: "block",
    },
    {
      label: "Transfer Type",
      value: "payment",
      sortKey: "TransferType",
      xs: 12,
      lg: 2,
      display: row.name !== "Available" ? "block" : "none",
    },
    {
      label: "Total",
      value: "total",
      sortKey:
        row.name === "Claimed"
          ? "AcquiredAmount"
          : row.name === "Available"
          ? "AvailableAmount"
          : "PendingAmount",
      xs: 12,
      lg: row.name === "Available" && showAction === true ? 1 : (row.name !== "Available" ? 1 : 2),
      display: "block",
    },
    {
      label: "Action",
      value: "LastEmailReminderSent",
      sortKey: "LastEmailReminderSent",
      xs: 12,
      lg: 1,
      display: row.name === "Available" && showAction === true ? "block" : "none",
    },
  ];

  const { items, requestSort, sortConfig } = useSortableData(row.data);
  return (
    <>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
          // background: "#dfdee0"
        }}
        className="rebates-overview-table__heading"
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          <span>{row.name}</span>
        </TableCell>
        <TableCell align="right">
          {" "}
          <h4 className="rebates-amount">
            {centsToFormattedDollars(row.total)}
          </h4>{" "}
        </TableCell>
      </TableRow>
      <TableRow className="rebates-overview-table__body">
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <div className="table-wrapper rebates-overview-wrapper">
              <div className={classes.root}>
                {items.length > 0 && (
                  <Grid container spacing={0} className="tr thead grid-header">
                    {gridHeader.map((item: any) => {
                      const { label, value, xs, lg, sortKey, display } = item;
                      const isValidHeader = label !== "";
                      return (
                        <Grid
                          className="th"
                          item
                          xs={xs}
                          lg={lg}
                          display={display}
                        >
                          {label === 'Action' ?
                            (<div className={label === 'Action' ? 'action-header' : ''}>
                              <span className="grid-header">{label}</span>
                            </div>) : (
                              <div
                                className={label === "Total" ? "action-header" : ""}
                                onClick={() => requestSort(sortKey)}
                              >
                                <span className="grid-header">{label}</span>
                                <span className="sort-icon">
                                  {isValidHeader &&
                                    (sortConfig?.key === sortKey ? (
                                      <img
                                        className="sort-svg"
                                        src={
                                          sortConfig.direction === "ascending"
                                            ? sortAscIcon
                                            : sortDescIcon
                                        }
                                      />
                                    ) : (
                                      <img className="sort-svg" src={sortDefault} />
                                    ))}
                                </span>
                              </div>)}
                        </Grid>
                      );
                    })}
                  </Grid>
                )}
                {items.map((item: any) => {
                  const {
                    id,
                    MemberName,
                    Phone,
                    mobile,
                    Avatar,
                    Location,
                    'Sales Personnel':Salesname,
                    'Sales Mobile Phone':SalesPhone,
                    HandDeliver,
                    TransferType,
                    LastEmailReminderSent,
                  } = item;

                  const lastSentOn = LastEmailReminderSent !== null ? ` - Last sent at : ${moment(LastEmailReminderSent).format('MM-DD-YYYY').toString()}` : '';
                  
                  function handleSendReminderEmailNotify(item: any): void {
                    handleSendReminderEmail(item);
                  }

                  return (
                    <Grid container spacing={0} className="tr">
                      <Grid
                        className="td"
                        item
                        xs={12}
                        lg={row.name !== "Available" ? 2 : 3}
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <div className="table-member-details">
                          <div className="member-img">
                            <img
                              src={
                                Avatar !== null ? `/${Avatar}` : DefaultUserImg
                              }
                            />
                          </div>
                          <div className="member-profile-details">
                            <div>
                              <NavLink
                                className="member-name"
                                style={{ color: "black" }}
                                to={`../member/view/${id}/`}
                                state={{
                                  path : '/rebates'
                                }}
                              >
                                {MemberName}
                              </NavLink>
                            </div>
                            <div className="member-mail-id">
                              <div className="br-number-normal">
                                {Phone ? formatPhoneNumber(Phone) : ""}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Grid>
                      <Grid
                        className="td xs-left-align xs-primary"
                        item
                        xs={12}
                        lg={2}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        {Salesname ? Salesname : "-"}
                      </Grid>
                      <Grid
                        className="td xs-left-align xs-primary"
                        item
                        xs={12}
                        lg={2}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        <div className="br-number-normal">
                          {SalesPhone && SalesPhone !== "null" ? (
                            <a
                              className="phone-link"
                              href={`tel:${SalesPhone}`}
                            >
                              {formatPhoneNumber(SalesPhone)}
                            </a>
                          ) : (
                            "-"
                          )}
                        </div>
                      </Grid>
                      <Grid
                        className="td xs-left-align"
                        item
                        xs={12}
                        lg={3}
                        container
                        direction="row"
                        alignItems="center"
                      >
                        {Location}
                      </Grid>
                      {row.name !== "Available" && (
                        <Grid
                          className="td xs-left-align"
                          item
                          xs={12}
                          lg={2}
                          container
                          direction="row"
                          justifyContent=""
                          alignItems="center"
                        >
                          {TransferType}
                        </Grid>
                      )}
                      <Grid
                        className="td action-col"
                        item
                        xs={12}
                        lg={row.name === "Available" && showAction === true ? 1 : (row.name !== "Available" ? 1 : 2)}
                        container
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <span className="rebates-amount">
                          {" "}
                          {row.name === "Claimed"
                            ? centsToFormattedDollars(item?.AcquiredAmount)
                            : row.name === "Pending"
                            ? centsToFormattedDollars(item?.PendingAmount)
                            : centsToFormattedDollars(item?.AvailableAmount)}
                        </span>
                      </Grid>
                      {row.name === "Available" && showAction && (
                        <Grid
                          className="td xs-left-align"
                          item
                          xs={12}
                          lg={1}
                          container
                          direction="row"
                          justifyContent="flex-end"
                          alignItems="center"
                        >
                          {item?.AvailableAmount > 0 && <Tooltip title={`Send Reminder Email ${LastEmailReminderSent !== null ? lastSentOn : ''}`}>
                            <Button
                              className="btn-circle btn-secondary"
                              variant="contained"
                              onClick={() => handleSendReminderEmailNotify(item)}
                            >
                              <i className="fas fa-envelope" />
                            </Button>
                          </Tooltip>}
                        </Grid>
                      )}
                    </Grid>
                  );
                })}
              </div>
            </div>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};