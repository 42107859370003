import { assign } from '@xstate/immer';
import { DateTime } from 'luxon';
import { createMachine } from 'xstate';
import { escalate } from 'xstate/lib/actions';
import { IntervalType, PaymentTerm, PaymentTerms } from '../../common';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const paymentTermsMachine = createMachine(
  {
    id: 'paymentTerms',
    tsTypes: {} as import('./paymentTermsMachine.typegen').Typegen0,
    schema: {
      context: {} as {
        paymentTerms: PaymentTerms;
        selectedPaymentTermName: string | null;
        selectedIntervalType: IntervalType | null;
        selectedIntervalValue: number | null;
        memberFilters: number[] | null;
        vendorFilters: number[] | null;
        selectedYear: number;
      },
      events: {} as
        | {
            type: 'done.invoke.loadPaymentTerms';
            data: {
              data: null | { [key: string]: PaymentTerm };
              success: boolean;
            };
          }
        | { type: 'error.platform.loadPaymentTerms'; data: any }
        | { type: 'refresh' }
        | { type: 'updateMemberFilters'; memberFilters: number[] }
        | { type: 'updateSelectedIntervalType'; intervalType: IntervalType }
        | { type: 'updateSelectedIntervalValue'; intervalValue: number }
        | { type: 'updateSelectedMember'; memberId: number }
        | { type: 'updateSelectedYear'; year: number },
    },
    context: {
      paymentTerms: [],
      selectedPaymentTermName: null,
      selectedIntervalType: null,
      selectedIntervalValue: null,
      memberFilters: null,
      vendorFilters: null,
      selectedYear: DateTime.now().year,
    },
    initial: 'loadingPaymentTerms',
    on: {
      updateSelectedMember: {
        actions: ['assignSelectedMemberFilters'],
        target: '.loadingPaymentTerms',
      },
      updateMemberFilters: {
        actions: 'assignMemberFilters',
        target: '.loadingPaymentTerms',
      },
      updateSelectedIntervalType: {
        actions: 'assignSelectedIntervalType',
        target: '#paymentTerms.loadingPaymentTerms',
      },
      updateSelectedIntervalValue: {
        actions: 'assignSelectedIntervalValue',
        target: '#paymentTerms.loadingPaymentTerms',
      },
      updateSelectedYear: {
        actions: 'assignSelectedYear',
        cond: 'requestedYearValueIsDifferentThanSelectedYearValue',
        target: '#paymentTerms.loadingPaymentTerms',
      },
    },
    states: {
      loadingPaymentTerms: {
        description:
          'Loading the KPI payment terms based on the current selected values.',
        invoke: {
          description: 'Load the selected member KPI terms.',
          id: 'loadPaymentTerms',
          src: 'loadPaymentTerms',
          onDone: [
            {
              actions: 'assignAgreements',
              cond: 'asyncRequestSucceeded',
              description:
                'The promise completed successfully, data was successfully returned.',
              target: 'idle',
            },
            {
              description:
                'The promise completed successfully but a failure occurred on the server.',
              target: 'error.server',
            },
          ],
          onError: [
            {
              cond: 'noMemberFiltersProvided',
              description:
                'Failure, member filters are required for this version of the machine.',
              target: 'error.fatal',
            },
            {
              description:
                'An error occurred but it was not categorized as known type of error.',
              target: 'error',
            },
          ],
        },
        tags: ['loading'],
      },
      idle: {},
      error: {
        initial: 'unknown',
        states: {
          unknown: {
            entry: 'escalateUnknownError',
            description:
              'An unknown error, this node is accessed when an error occurs and no specific error type was provided. This node should ideally be impossible to reach.',
          },
          server: {},
          fatal: {
            description:
              'A fatal error, this node is accessed when an error occurs and the error cannot be corrected by another event.',
          },
        },
        tags: ['error'],
      },
    },
  },
  {
    actions: {
      assignAgreements: assign((context, event) => {
        const { data } = event;
        const kpiVendorKeys = Object.keys(data?.data || {});
        const paymentTerms: PaymentTerms = [];
        kpiVendorKeys.forEach((key) => {
          if (data?.data?.[key]) {
            paymentTerms.push(data?.data?.[key] as PaymentTerm);
          }
        });
        if (
          !(
            paymentTerms.filter(
              (agreement) => agreement.name === context.selectedPaymentTermName,
            ).length > 0
          )
        ) {
          context.selectedPaymentTermName = paymentTerms?.[0]?.name ?? null;
        }
        context.paymentTerms = paymentTerms;
      }),
      assignMemberFilters: assign((context, event) => {
        context.memberFilters = event.memberFilters;
      }),
      assignSelectedMemberFilters: assign((context, event) => {
        context.memberFilters = event.memberId
          ? [event.memberId]
          : context.memberFilters;
      }),
      assignSelectedYear: assign((context, event) => {
        if (
          DateTime.now().year !== event.year &&
          !context.selectedIntervalType
        ) {
          context.selectedIntervalType = 'quarter';
          context.selectedIntervalValue = 1;
        }
        context.selectedYear = event.year;
      }),
      assignSelectedIntervalType: assign((context, event) => {
        if (event.intervalType === 'month') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'quarter') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'half-year') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'year') {
          context.selectedIntervalValue = null;
        }
        context.selectedIntervalType = event.intervalType;
      }),
      assignSelectedIntervalValue: assign((context, event) => {
        let intervalValue = context.selectedIntervalValue;
        if (context.selectedIntervalType === 'month') {
          let monthValue = event.intervalValue;
          if (monthValue < 1) {
            monthValue = 1;
          }
          if (monthValue > 12) {
            monthValue = 12;
          }
          intervalValue = monthValue;
        }
        if (context.selectedIntervalType === 'quarter') {
          let quarterValue = event.intervalValue;
          if (quarterValue > 4) {
            quarterValue = 4;
          }
          if (quarterValue < 1) {
            quarterValue = 1;
          }
          intervalValue = quarterValue;
        }
        if (context.selectedIntervalType === 'half-year') {
          let halfYearValue = event.intervalValue;
          if (halfYearValue < 1) {
            halfYearValue = 1;
          }
          if (halfYearValue > 2) {
            halfYearValue = 2;
          }
          intervalValue = halfYearValue;
        }
        if (context.selectedIntervalType === 'year') {
          intervalValue = null;
        }
        context.selectedIntervalValue = intervalValue;
      }),
      escalateUnknownError: escalate(() => ({
        message: 'An unknown error occurred.',
      })),
    },
    guards: {
      asyncRequestSucceeded: (_, event) => event.data?.success,
      noMemberFiltersProvided: (_, event) =>
        event.data.reason === 'No member filters provided.',
      requestedYearValueIsDifferentThanSelectedYearValue: (context, event) =>
        context.selectedYear !== event.year,
    },
    services: {
      loadPaymentTerms: (context) => {
        if (!context.memberFilters || context.memberFilters.length <= 0) {
          return Promise.reject({
            reason: 'No member filters provided.',
          });
        }
        return serviceInterceptor
          .get(
            `/members/${context.memberFilters[0]}/kpis/terms?interval=${context.selectedIntervalType}&intervalValue=${context.selectedIntervalValue}&year=${context.selectedYear}`,
          )
          .then((result) => result.data)
          .catch((error) => error);
      },
    },
  },
);

export type PaymentTermsMachine = typeof paymentTermsMachine;

export default paymentTermsMachine;
