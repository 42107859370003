import { FC, useEffect, useState } from "react";
import { Loader } from "../../../../../utils";

// popup window
import { WarningPopup } from "../../../../../utils";
import { ConnectedPagination } from "../../../../../components/ConnectedPagination";
import { getPaginatedData } from "../../../../../utils";
import { SearchNotFound } from "../../../../../components/SearchNotFound";
import LoginAttempt from "../../tableUtils/LoginAttempt";

const gridHeader = [
  {
    label: "User",
    value: "UserName",
    sortKey: "UserName",
    xs: 12,
    lg: 4,
  },
  {
    label: "Phone",
    value: "UserPhone",
    sortKey: "UserPhone",
    xs: 12,
    lg: 3,
  },
  {
    label: "Successful Logins",
    value: "LoginAttempt",
    sortKey: "LoginAttempt",
    xs: 12,
    lg: 3,
  },
  {
    label: "Recent Login",
    value: "LastLogin",
    sortKey: "LastLogin",
    xs: 12,
    lg: 2,
  },
];

export const LoginAttemptTab: FC<any> = ({
  memberDetail,
  getMemberLogin,
  members: { vendorListSpinner, loginAttempt },
  paginationReducer: { rowsPerPage, page },
  handleChangeRowsPerPage,
}) => {
  const [loginAttemptList, setLoginAttemptList] = useState([]);
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState("");

  useEffect(() => {
    getMemberLogin();
  }, []);

  useEffect(() => {
    setLoginAttemptList(loginAttempt);
    handleChangeRowsPerPage(10);
  }, [loginAttempt]);

  const modifiedList = getPaginatedData(loginAttemptList, rowsPerPage, page);

  const setSortKey = (newSortKey: any) => {
    if (sortKey === newSortKey) {
      if (sortType === "DESC") {
        updateSortType("ASC");
      } else {
        updateSortType("DESC");
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType("ASC");
    }
  };

  const sortedData =
    sortKey && sortType && modifiedList.length > 0
      ? modifiedList &&
        modifiedList.sort((a: any, b: any) => {
          // if (sortType === "DESC") {
          //   return b[sortKey]?.localeCompare(a[sortKey]);
          // }
          // return a[sortKey]?.localeCompare(b[sortKey]);
          if(sortKey !== 'LoginAttempt'){
            if (sortType === 'DESC') {
              return b[sortKey]?.localeCompare(a[sortKey]);
            }else{
              return a[sortKey]?.localeCompare(b[sortKey]);
            }
          }else{
            if (sortType === 'DESC') {
              return b[sortKey] - a[sortKey];
            }else{
              return a[sortKey] - b[sortKey];
            }
          }
        })
      : modifiedList;

  const memberName = memberDetail?.name ? memberDetail?.name : "-";

  return (
    <div className="vendor-assign-content">
      {vendorListSpinner ? (
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      ) : (
        <>
          <LoginAttempt
            gridHeader={gridHeader}
            gridData={sortedData ? sortedData : []}
            setSortKey={setSortKey}
            sortType={sortType}
            memberName={memberName}
          />
          {loginAttemptList?.length > 5 && modifiedList?.length > 0 ? (
            <ConnectedPagination count={loginAttemptList?.length} />
          ) : null}
        </>
      )}
    </div>
  );
};
