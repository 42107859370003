import {
  UPDATE_REBATE_OVERVIEW_SPINNER,
  UPDATE_REBATES_OVERVIEW_DATA,
  GET_REBATES_OVERVIEW_ERROR,
  RebatesOverviewError,
  SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR,
  MemberAvailableRebatesEmailSendError,
} from '../../constants';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getRebatesOverviewAPI } from '../../api/rebates/getRebatesOverview';
import { checkDwollaTransferStatusAPI } from '../../api/rebates/checkDwollaTransferStatus';
import { sendAvailableRebatesEmailNotification } from "../../api/members";

export const updateRebatesOverViewSpinner =
  (overViewSpinner: boolean) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_REBATE_OVERVIEW_SPINNER,
      overViewSpinner,
    });
  };

export const getRebatesOverview = () => async (dispatch: any) => {
  dispatch(updateRebatesOverViewSpinner(true));
  try {
    const res = await checkDwollaTransferStatusAPI();
    const response = await getRebatesOverviewAPI();
    const { data } = response?.data ?? {};
    dispatch({
      type: UPDATE_REBATES_OVERVIEW_DATA,
      overViewData: data,
    });
    dispatch(updateRebatesOverViewSpinner(false));
  } catch (error) {
    dispatch(updateRebatesOverViewSpinner(false));
    if (error === GET_REBATES_OVERVIEW_ERROR) {
      toast.error(RebatesOverviewError);
    }
  }
};

export const handleSendReminderEmail = async (selectedItem : any) => {
  try {
    const {id: memberId, AvailableAmount: amount} = selectedItem;
    const res = await sendAvailableRebatesEmailNotification(memberId, amount);
    const { message } = res?.data ?? '';
    toast.success(message);
  } catch (error: any) {
    if (error === SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR) {
      toast.error(MemberAvailableRebatesEmailSendError);
    }
    else{
      toast.error(error);
    }
  }
};
