import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  UPDATE_MEMBER_USER_FORM_SPINNER,
  UPDATE_MEMBER_USER_IS_EDIT,
  UPDATE_MEMBER_USER_ID,
  UPDATE_MEMBER_USER_ROLE_ERROR,
  MemberUserUpdateSuccess,
  MemberUserUpdateError,
  UPDATE_MEMBER_USER_UPDATE_ERROR,
  UPDATE_MEMBER_USER_LIST,
} from "../../constants";

import {showModal, closeModal} from "../modal";
import {updateMemberUser, getMemberUserList} from "../../api/members";

import {
  onChangeAddMemberUserFname,
  onChangeAddMemberUserLname,
  onChangeAddMemberUserMail,
  onChangeAddMemberUserRole,
} from "./memberUserAdd";

export const onChangeAddMemberUserId = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_USER_ID,
    value: value,
  });
};

export const handleEditMemberUser = (objData) => async (dispatch) => {

  if (typeof objData == "object" && Object.keys(objData).length > 0) {
    dispatch({
      type: UPDATE_MEMBER_USER_IS_EDIT,
      flag: true,
    });
    dispatch(onChangeAddMemberUserId(objData.id));
    dispatch(onChangeAddMemberUserFname(objData.firstname));
    dispatch(onChangeAddMemberUserLname(objData.lastname));
    dispatch(onChangeAddMemberUserMail(objData.email));
    dispatch(onChangeAddMemberUserRole(objData.roleid));
  }
  dispatch(showModal(true));
};

export const onEditMemberUser = () => async (dispatch, getState) => {
  const {memberUserForm, members} = getState();
  const {type, selectedMember} = members;
  const {id} = selectedMember;
  const {typeid, userId, role} = memberUserForm;

  const isvalidRole = isValidateSelectionType(role);
  const isError = !isvalidRole;

  if (!isvalidRole) {
    dispatch({
      type: UPDATE_MEMBER_USER_ROLE_ERROR,
      flag: true,
    });
  }

  try {
    if (!isError) {
      dispatch({
        type: UPDATE_MEMBER_USER_FORM_SPINNER,
        spinner: true,
      });
      const response = await updateMemberUser({
        id,
        typeid,
        userId,
        role,
      });
      const {success, message, data} = response;
      if (success) {
        const usersList = await getMemberUserList(id, type);
        dispatch({
          type: UPDATE_MEMBER_USER_LIST,
          list: usersList.data,
        });

        dispatch(closeModal());
        success ? toast.success(MemberUserUpdateSuccess) : null;
      }
      dispatch({
        type: UPDATE_MEMBER_USER_FORM_SPINNER,
        spinner: false,
      });
      if (!success) {
        toast.error(message);
      }
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_USER_FORM_SPINNER,
      spinner: false,
    });
    if (error == UPDATE_MEMBER_USER_UPDATE_ERROR) {
      toast.error(MemberUserUpdateError);
    }
  }
};

const isValidateSelectionType = (selection) => {
  if (selection == "0" || selection == null || selection == "") return false;
  else {
    return true;
  }
};

const isValidText = (value) => {
  return !(value?.trim() == "");
};
