import { FC, Fragment } from 'react';
import Grid from '@material-ui/core/Grid';
import { SearchNotFound } from '../../../../components/SearchNotFound';
import { useStyles, Loader } from '../../../../utils';
import { StringAvatar } from '../../../../utils/AvatarInitial';
import Avatar from '@mui/material/Avatar';
import DefaultMemberImg from '../../../../images/member-list-placeholder.jpg';

import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../../machines';
import serverApiV2Instance from '../../../../services/serverApiV2Instance';
import { TablePagination } from '@material-ui/core';

const gridHeader = [
  {
    label: 'Name',
    value: 'name',
    sortKey: 'name',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Email',
    value: 'email',
    sortKey: 'email',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Phone Number',
    value: 'phoneNumber',
    sortKey: 'phoneNumber',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Role',
    value: 'role',
    sortKey: 'role',
    xs: 12,
    lg: 3,
  },
];

export const CommunityMemberUsersList: FC<{
  memberId: any;
}> = ({ memberId }) => {
  const classes = useStyles();

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: '',
        sortOrder: 'desc',
      },
      pageSize: 5,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/members/${memberId}/users?${query.toString()}`,
        );
      },
    },
  });

  const orderBy = state.context.additionalQueryStringValues.orderBy as string;
  const sortOrder = state.context.additionalQueryStringValues.sortOrder as
    | 'asc'
    | 'desc';
  const setOrderBy = (orderBy: string) => {
    if (state.context.additionalQueryStringValues.orderBy === orderBy) {
      return send({
        type: 'addQueryStringValue',
        key: 'sortOrder',
        value:
          state.context.additionalQueryStringValues.sortOrder === 'asc'
            ? 'desc'
            : 'asc',
      });
    }
    send({
      type: 'addQueryStringValue',
      key: 'orderBy',
      value: orderBy,
    });
  };

  const formatContactNumber = (contactNumber: string) => {
    return contactNumber.replace(/(\d{3})(\d{3})(\d*)/, '($1) $2-$3');
  };

  console.log('hhhhi');

  if (state.hasTag('loading')) {
    return (

        <div className={`${classes.root} table-wrapper`} style={{ display: 'flex', alignItems: 'center'}}>
          <Loader size={40} />
        </div>
    );
  }

  return (
    <div className={`${classes.root} table-wrapper`}>
      {state.context.records?.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {
                  <div onClick={() => sortKey && setOrderBy(sortKey)}>
                    <span className="grid-header">{label}</span>
                    <span className="sort-icon">
                      {isValidHeader &&
                        (sortKey === orderBy ? (
                          <img
                            className="sort-svg"
                            src={
                              sortOrder === 'asc' ? sortAscIcon : sortDescIcon
                            }
                          />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        ))}
                    </span>
                  </div>
                }
              </Grid>
            );
          })}
        </Grid>
      )}

      {state.context.records?.length > 0 ? (
        state.context.records.map((item: any) => {
          const {
            firstname,
            lastname,
            rolename,
            email,
            isParentUser,
            avatar,
            phone,
            membername,
          } = item;
          const name = `${firstname} ${lastname}`;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                <div className="table-member-details">
                  <div className="member-img">
                    {avatar?.length > 0 ? (
                      <img src={avatar} alt="user" />
                    ) : name ? (
                      <Avatar {...StringAvatar(name)} />
                    ) : (
                      <img src={DefaultMemberImg} alt="user" />
                    )}
                  </div>
                  <div className="member-profile-details">
                    <div className="member-name">{name}</div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                <div className="member-email">
                  <a
                    href={`mailto:${email}?subject=Buy Right Application Inquiry for ${membername}`}
                  >
                    {email}
                  </a>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {phone && phone !== 'null' ? (
                  <a className="phone-link" href={`tel:${phone}`}>
                    {formatContactNumber(phone)}
                  </a>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {rolename ? rolename : '-'}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Users'} />
      )}
      <TablePagination
        component="div"
        count={state.context.totalRecords}
        page={state.context.pageNumber - 1}
        onPageChange={(_, newPage) => {
          send({
            type: 'goToPage',
            pageNumber: newPage + 1,
          });
        }}
        rowsPerPage={state.context.pageSize}
        onRowsPerPageChange={(event) =>
          send({
            type: 'updatePageSize',
            pageSize: parseInt(event.target.value),
          })
        }
        rowsPerPageOptions={[5, 10, 20, 50]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} of ${
            count !== -1 ? count.toLocaleString() : `more than ${to}`
          }`;
        }}
      />
    </div>
  );
};
