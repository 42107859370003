import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { createMachine } from 'xstate';
import { PaginationContext, PaginationEvent } from './paginationMachineTypes';

export const paginationMachine = createMachine(
  {
    context: {
      additionalQueryStringValues: {},
      pageNumber: 1,
      pageSize: 20,
      parameters: {},
      records: [],
      totalRecords: Infinity,
    },
    tsTypes: {} as import('./paginationMachine.typegen').Typegen0,
    schema: {
      context: {} as PaginationContext,
      events: {} as PaginationEvent,
      services: {} as {
        recordLoader: {
          data: AxiosResponse<{
            pageNumber: number;
            pageSize: number;
            records: any[];
            totalRecords: number;
          }>;
        };
      },
    },
    id: 'pagination',
    initial: 'loadingRecords',
    states: {
      loadingRecords: {
        invoke: {
          src: 'recordLoader',
          id: 'Load records from the API',
          onDone: [
            {
              actions: [
                'assignRecordResults',
              ],
              description: 'Successfully loaded records from the API.',
              target: '#pagination.idle',
            },
          ],
          onError: [
            {
              description: 'Failed to load records from the API.',
              target: '#pagination.idle',
            },
          ],
        },
        tags: ['loading'],
      },
      idle: {
        description: 'On standby to load more records.',
        on: {
          addParameter: {
            actions: 'assignParameter',
            description: 'Assign a parameter value.',
            cond: 'Requested parameter shares the same type as existing value',
            target: '#pagination.loadingRecords',
          },
          addQueryStringValue: {
            actions: 'assignQueryStringValue',
            description: 'Assign a query string value.',
            target: '#pagination.loadingRecords',
          },
          addQueryStringValues: {
            actions: 'assignQueryStringValues',
            cond: 'Requested value pair list is not empty',
            description: 'Assign one or more query string values.',
            target: '#pagination.loadingRecords',
          },
          deleteQueryStringValue: {
            actions: 'removeQueryStringValue',
            description: 'Remove a query string value.',
            target: '#pagination.loadingRecords',
          },
          goToFirst: {
            actions: 'assignPageNumberToOne',
            description: 'Load and go to the first page.',
            cond: 'Not already on the first page',
            target: '#pagination.loadingRecords',
          },
          goToLast: {
            actions: 'assignPageNumberToLast',
            description: 'Load and go to the last page.',
            cond: 'Not already on the last page',
            target: '#pagination.loadingRecords',
          },
          goToPage: {
            actions: 'assignPageNumber',
            description: 'Load and go to a specific page.',
            cond: 'Target page is valid',
            target: '#pagination.loadingRecords',
          },
          next: {
            actions: 'assignPageNumberToNext',
            description: 'Go to the next page.',
            cond: 'Next page exists',
            target: '#pagination.loadingRecords',
          },
          previous: {
            actions: 'assignPageNumberToPrevious',
            description: 'Go to the previous page.',
            cond: 'Previous page exists',
            target: '#pagination.loadingRecords',
          },
          updatePageSize: {
            actions: 'assignPageSize',
            description: 'Update the page size to the requested value.',
            cond: 'Requested page size is valid',
            target: '#pagination.loadingRecords',
          },
          refresh: {
            description: "Refreshes the current page's data",
            target: '#pagination.loadingRecords',
          },
        },
      },
    },
  },
  {
    actions: {
      assignPageNumberToOne: assign((context) => {
        context.pageNumber = 1;
      }),
      assignPageNumberToLast: assign((context) => {
        context.pageNumber = context.totalRecords / context.pageNumber;
      }),
      assignPageNumberToNext: assign((context) => {
        context.pageNumber += 1;
      }),
      assignPageNumberToPrevious: assign((context) => {
        context.pageNumber -= 1;
      }),
      assignPageNumber: assign((context, event) => {
        context.pageNumber = event.pageNumber;
      }),
      assignPageSize: assign((context, event) => {
        context.pageSize = event.pageSize;
        context.pageNumber = 1;
      }),
      assignParameter: assign((context, event) => {
        context.parameters[event.key] = event.value;
        context.pageNumber = 1;
      }),
      assignQueryStringValue: assign((context, event) => {
        context.additionalQueryStringValues[event.key] = event.value;
        if (event.destructive) {
          context.pageNumber = 1;
        }
      }),
      assignQueryStringValues: assign((context, event) => {
        let destructive = false;
        event.valuePairs.forEach((valuePair) => {
          context.additionalQueryStringValues[valuePair.key] = valuePair.value;
          if (valuePair.destructive) destructive = true;
        });
        if (destructive) {
          context.pageNumber = 1;
        }
      }),
      assignRecordResults: assign((context, event) => {
        context.pageNumber = event.data.data.pageNumber;
        context.pageSize = event.data.data.pageSize;
        context.totalRecords = event.data.data.totalRecords;
        const records = context.records.length > event.data.data.totalRecords ? [] : [...context.records];
        event.data.data.records.forEach((record, index) => {
          records[(context.pageNumber - 1) * context.pageSize + index] = record;
        });
        context.records = [...records];
      }),
      removeQueryStringValue: assign((context, event) => {
        delete context.additionalQueryStringValues[event.key];
      }),
    },
    guards: {
      'Next page exists': (context) =>
        context.pageNumber + 1 <= context.totalRecords / context.pageSize,
      'Not already on the first page': (context) => !(context.pageNumber <= 1),
      'Not already on the last page': (context) =>
        !((context.totalRecords / context.pageNumber) + 1 >= context.pageNumber),
      'Previous page exists': (context) => context.pageNumber - 1 > 0,
      'Requested page size is valid': (_, event) => event.pageSize > 0,
      'Requested value pair list is not empty': (_, event) => event.valuePairs.length > 0,
      'Requested parameter shares the same type as existing value': (
        context,
        event,
      ) => typeof context.parameters[event.key] === typeof event.value,
      'Target page is valid': (context, event) => {
        if (event.pageNumber === context.pageNumber) return false;
        if (event.pageNumber < 1) return false;
        return event.pageNumber <= (context.totalRecords / context.pageSize) + 1;
      },
    },
  },
);

export type PaginationMachine = typeof paginationMachine;
export default paginationMachine;
