import { GET_VENDORS_LINKS_API, UPDATE_VENDOR_LINKS_ERROR } from "../../constants";


export const getVendorLinksList = async (vendorId) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDORS_LINKS_API}/${vendorId}`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
              }), 
        }
        );
        if(!response.ok) throw UPDATE_VENDOR_LINKS_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_VENDOR_LINKS_ERROR;
    } 
}

