import {
  UPDATE_PRODUCT_BRAND_LIST,
  UPDATE_PRODUCT_BRAND_LIST_SPINNER,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_SPINNER,
} from "../../constants";

const initialState = {
  loadingBrands: false,
  loadingProducts: false,
  productBrands: [],
  productList: [],
  ratingList: [
    { id: -1, name: "N/A" },
    { id: 1, name: "High" },
    { id: 2, name: "Medium" },
    { id: 3, name: "Low" },
  ],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_BRAND_LIST:
      return {
        ...state,
        productBrands: action.list,
      };
    case UPDATE_PRODUCT_LIST:
      return {
        ...state,
        productList: action.list,
      };
    case UPDATE_PRODUCT_BRAND_LIST_SPINNER:
      return {
        ...state,
        loadingBrands: action.spinner,
      };
    case UPDATE_PRODUCT_LIST_SPINNER:
      return {
        ...state,
        loadingProducts: action.spinner,
      };
    default:
      return state;
  }
};
