import React from "react";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";

export const  ManageAdsHead = ({
  handleShowAddAdsModal,
  handleShowAdsTypeModal,
}) => {

  return (
    <Grid
      xs={12}
      container
      direction="row"
      justify="flex-end"
      alignItems="center"
      className="manage-ads-head-container"
    >
      <Grid item xs={12} sm={12} md={12} lg={2} className="manage-ads-head-left" >
        <h2 class="page-title">Manage Ads</h2>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={10} className="manage-ads-head-right">
        <div className="page-head">
          <div className="page-search-box">
            <i className="fas fa-search"></i>
            <InputBase placeholder="Search" />
          </div>
        </div>
        <div className="page-head">
          <Button variant="contained" size="small" className="btn-add" 
          onClick={handleShowAdsTypeModal}
          >
            <span className="fas fa-cog"></span> <span>Manage Ad Type</span>
          </Button>
        </div>
        <div className="page-head">
          <Button
            variant="contained"
            size="small"
            className="btn-add-blue"
            onClick={handleShowAddAdsModal}
          >
            <span className="fas fa-plus-circle"></span> <span>Create New Ad</span>
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};
