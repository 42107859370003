import { addSavingsPercentage } from "../api/dashboard/savings/addSavingsPercentage"

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const addPercentage = (data) => async (dispatch) =>{
    try {
        
        const response = await addSavingsPercentage(data);

        const {success} = response

        if(success){
            toast.success("Added successfully")
        }

    } catch (error) {
        console.log(error)
    }
}