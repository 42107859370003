import { FC, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import { useNavigate } from 'react-router-dom';
import Header from '../../conversion/login/header';
import FeatureInfo from '../../conversion/login/Featureinfo';
import '../../css/fonts.css';
import '../../css/all.min.css';
import '../../css/style.css';
import { Loader } from '../../utils';
import {
  EMAIL_EMPTY_MESSAGE,
  EMAIL_VALID_MESSAGE,
  PASSWORD_EMPTY_MESSAGE,
} from '../../utils/constants';

export const Login: FC<{
  onChangeEmail: any;
  onChangePassword: any;
  onChangeShowPassword: any;
  onLoginSubmit: any;
  clearLogin: any;
  login: {
    spinner: any;
    email: any;
    password: any;
    showPassword: any;
    loggedInUser: any;
    redirect: any;
    errorEmail: any;
    errorPassword: any;
    errorServerResponse: any;
  };
}> = ({
  onChangeEmail,
  onChangePassword,
  onChangeShowPassword,
  onLoginSubmit,
  clearLogin,
  login: {
    spinner,
    email,
    password,
    showPassword,
    loggedInUser,
    redirect,
    errorEmail,
    errorPassword,
    errorServerResponse,
  },
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      navigate('/dashboard');
    }
    return () => {
      clearLogin();
    };
  }, []);

  if (redirect && redirect == true) {
    if (loggedInUser && loggedInUser.isTemporaryPassword) {
      navigate('/profile/password-settings');
    }
    else if (loggedInUser.invalidPassword) {
      navigate('/password-update');
    } else {
      navigate('/dashboard');
    }
  }

  const onPasswordEntered = (event: any) => {
    if (event && event.charCode === 13) {
      onLoginSubmit();
    }
  };

  return (
    <div>
      <Header />
      <section className="login-wrapper">
        <div className="login-bg">
          <FeatureInfo />
        </div>
        <div className="login-form">
          <form>
            <div className="form-group">
              <label className="form-label">Email Address</label>
              <input
                type="text"
                id="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => onChangeEmail(e.target.value)}
                disabled={spinner}
              />
            </div>
            <div className="errorMsg">
              <p>
                {errorEmail &&
                  ((email && EMAIL_VALID_MESSAGE) || EMAIL_EMPTY_MESSAGE)}
              </p>
            </div>
            <div className="form-group">
              <label className="form-label">Password</label>
              <input
                type={showPassword ? 'text' : 'password'}
                id="password"
                className="form-control password"
                placeholder="*******"
                value={password}
                onChange={(e) => onChangePassword(e.target.value)}
                onKeyPress={(e) => onPasswordEntered(e)}
                disabled={spinner}
              />
              <i
                className={
                  showPassword
                    ? 'fa fa-fw fa-eye field-icon toggle-password'
                    : 'fa fa-fw fa-eye-slash field-icon toggle-password'
                }
                onClick={() => onChangeShowPassword()}
              />
              <a
                href=""
                className="forgot-password-link"
                onClick={() => navigate('/forgot-password')}
              >
                Forgot Password?
              </a>
            </div>
            <div className="errorMsg">
              <p>{errorPassword && PASSWORD_EMPTY_MESSAGE}</p>
            </div>
            <div className="errorMsg">
              <p>{errorServerResponse}</p>
            </div>
            <Button
              type="button"
              className={
                spinner
                  ? 'btn btn-sign-in-disable button-disable'
                  : 'btn btn-sign-in'
              }
              onClick={() => onLoginSubmit()}
              disabled={spinner}
            >
              Sign in {spinner && <Loader size={15} color={'white'} />}
            </Button>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            ></Grid>
          </form>
        </div>
      </section>
    </div>
  );
};
