import {
  baseUrl,
} from '../../../../constants';

export const getMemberProductList = async ({
  memberId,
  quarter,
  year,
}: any) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '');
    const response = await fetch(
      `${baseUrl}/member-rebate-documents-records/member/${memberId}?quarter=${quarter}&year=${year}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw 'GET_REBATE_MEMBER_PRODUCT_LIST_ERROR';
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw 'GET_REBATE_MEMBER_PRODUCT_LIST_ERROR';
  }
};
