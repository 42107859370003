import { AdListError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const getAllAdList = async () => {
  try {
    const response = await serviceInterceptor.get('/ads');
    if (!response?.data?.success) {
      throw AdListError;
    }
    return response.data;
  } catch (error) {
    throw AdListError;
  }
};
