import { ActorRefFrom, createMachine, spawn } from 'xstate';
import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { uniqueId } from 'lodash';
import serviceInterceptor from '../../../../../../../services/ServiceInterceptor';
import rebateReceiptCheckTypeItemMachine, {
  RebateReceiptCheckTypeItemMachine,
} from '../rebateReceiptCheckTypeItemMachine';
import rebateReceiptHistoricTypeItemMachine, {
  RebateReceiptHistoricTypeItemMachine,
} from '../rebateReceiptHistoricTypeItemMachine';
import rebateReceiptElectronicTypeItemMachine, {
  RebateReceiptElectronicTypeItemMachine,
} from '../rebateReceiptElectronicTypeItemMachine';
import { DwollaFundingSources } from '../../../../../../../common';
import serverApiV2Instance from '../../../../../../../services/serverApiV2Instance';
import { RebateReceiptItem, RebateReceiptItems } from '../machineTypes';
import { Encrypt, Decrypt } from '../../../../../../../utils/cryptoFunction'

interface ClaimRebatesMachineContext {
  amount: null | number;
  canHandDeliver: boolean;
  electronicTransferRequestsEnabled: boolean;
  fundingSources: DwollaFundingSources;
  itemLimit: number; // TODO is there a more elegant way to hard-cap the number of items?
  items: (
    | ActorRefFrom<RebateReceiptCheckTypeItemMachine>
    | ActorRefFrom<RebateReceiptElectronicTypeItemMachine>
    | ActorRefFrom<RebateReceiptHistoricTypeItemMachine>
  )[];
  memberName: null | string;
  memberId: null | number;
  transferFees: { name: 'standard'; amount: number }[]; // TODO set up endpoint
  userId: null | number;
  userType: null | 'buyRightAdmin' | 'memberAdmin';
}

type ClaimRebatesMachineEvent =
  | { type: 'addElectronicTransferItem' }
  | { type: 'close' }
  | { type: 'open' }
  | { type: 'openCheckRebateRequestForm' }
  | { type: 'openElectronicTransferRequestForm' }
  | {
      type: 'removeElectronicTransferItem';
      electronicRebateReceiptItemId: string;
    }
  | { type: 'submit' }
  | { type: 'warningMessage'; message: string };

export const claimRebatesMachine = createMachine(
  {
    context: {
      amount: null,
      canHandDeliver: false,
      electronicTransferRequestsEnabled: false,
      fundingSources: [],
      itemLimit: 6,
      items: [],
      memberName: null,
      memberId: null,
      transferFees: [{ name: 'standard', amount: 500 }],
      userId: null,
      userType: null,
    },
    tsTypes: {} as import('./claimRebatesMachine.typegen').Typegen0,
    schema: {
      context: {} as ClaimRebatesMachineContext,
      events: {} as ClaimRebatesMachineEvent,
      services: {} as {
        loadMemberResource: {
          data: AxiosResponse<{
            data?: {
              adminUserId: number | null;
              avatar: null | string;
              canHandDeliver: boolean;
              childCount: number;
              city: string;
              contactfirstname: string | null;
              contactlastname: string | null;
              id: number;
              memberhierarchyId: null | number;
              mobile: string;
              name: string;
              parentid: null | number;
              parentname: null | string;
              phone: string;
              startdate: DateTime;
              state: string;
              street: string;
              timestamp: DateTime;
              zip: string;
            };
            error?: any;
            success: true;
          }>;
        };
        loadMemberFundingSources: {
          data: AxiosResponse<DwollaFundingSources>;
        };
        submitRebateReceiptRequest: {
          data: AxiosResponse<{
            data?: any;
            error?: any;
            success: true;
          }>; // TODO
        };
      },
    },
    id: 'claimRebates',
    initial: 'closed',
    on: {
      warningMessage: {
        actions: 'notifyWarning',
      },
    },
    states: {
      closed: {
        initial: 'loading',
        states: {
          loading: {
            initial: 'memberDetails',
            states: {
              memberDetails: {
                invoke: {
                  src: 'loadMemberResource',
                  id: 'Load the Member resource',
                  onDone: [
                    {
                      cond: 'memberResourceInvalid',
                      target: '#claimRebates.error.invalidMemberResource',
                    },
                    {
                      actions: 'assignPermissions',
                      target: '#claimRebates.closed.loading.fundingSources',
                    },
                  ],
                  onError: [
                    {
                      target: '#claimRebates.error.failedToLoadMemberResource',
                    },
                  ],
                },
              },
              fundingSources: {
                invoke: {
                  src: 'loadMemberFundingSources',
                  id: 'Load the Member funding sources',
                  onDone: [
                    {
                      actions: ['assignFundingSources'],
                      target: '#claimRebates.closed.idle',
                    },
                  ],
                  onError: [
                    {
                      target:
                        '#claimRebates.error.failedToLoadMemberFundingSources',
                    },
                  ],
                },
              },
            },
            tags: ['loading'],
          },
          idle: {
            on: {
              open: [
                {
                  actions: 'notifyNoAvailableFunds',
                  cond: 'noAvailableFunds',
                  target: '#claimRebates.closed.idle',
                },
                {
                  target: '#claimRebates.opened',
                },
              ],
            },
          },
        },
      },
      opened: {
        initial: 'determineDefaultDisplay',
        states: {
          determineDefaultDisplay: {
            always: [
              {
                cond: 'userIsBRAdmin',
                target: '#claimRebates.opened.historicalRebateRequestForm',
              },
              {
                cond: 'userIsAValidMemberAdmin',
                target: '#claimRebates.opened.checkRebateRequestForm',
              },
              {
                cond: 'userIsAValidMemberAdmin',
                target: '#claimRebates.opened.electronicTransferRequestForm',
              },
            ],
          },
          electronicTransferRequestForm: {
            entry: [
              'clearRebateReceiptRequestItems',
              'generateElectronicTypeRebateReceiptRequestItem',
            ],
            initial: 'editing',
            states: {
              editing: {
                on: {
                  addElectronicTransferItem: {
                    actions: 'generateElectronicTypeRebateReceiptRequestItem',
                    cond: 'itemsLessThanOrEqualToItemLimit',
                  },
                  removeElectronicTransferItem: {
                    actions: 'removeElectronicTypeRebateReceiptRequestItemById',
                    cond: 'itemsAreGreaterThanOneAndIdMatchesListItem',
                  },
                  close: {
                    actions: 'clearRebateReceiptRequestItems',
                    target: '#claimRebates.closed.idle',
                  },
                  openCheckRebateRequestForm: {
                    target: '#claimRebates.opened.checkRebateRequestForm',
                  },
                  submit: [
                    {
                      cond: 'itemTypesMismatch',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                    {
                      cond: 'itemTotalsDoNotEqualTotalAmount',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                    {
                      target:
                        '#claimRebates.opened.electronicTransferRequestForm.submitting',
                    },
                  ],
                },
              },
              submitting: {
                invoke: {
                  src: 'submitRebateReceiptRequest',
                  id: 'Submit rebate receipt request',
                  onDone: [
                    {
                      actions: ['notifySuccess', 'refresh'],
                      target: '#claimRebates.closed.loading',
                    },
                  ],
                  onError: [
                    {
                      actions: 'notifyRequestError',
                      target:
                        '#claimRebates.opened.electronicTransferRequestForm.editing',
                    },
                  ],
                },
                tags: ['loading', 'submitting'],
              },
            },
          },
          historicalRebateRequestForm: {
            entry: [
              'clearRebateReceiptRequestItems',
              'generateHistoricalTypeRebateReceiptRequestItem',
            ],
            initial: 'editing',
            states: {
              editing: {
                on: {
                  close: {
                    actions: 'clearRebateReceiptRequestItems',
                    target: '#claimRebates.closed.idle',
                  },
                  submit: [
                    {
                      actions: () => console.log('1'),
                      cond: 'itemTypesMismatch',
                      target:
                        '#claimRebates.opened.historicalRebateRequestForm.editing',
                    },
                    {
                      actions: () => console.log('2'),
                      cond: 'itemTotalsAreEqualToOrLessThanZero',
                      target:
                        '#claimRebates.opened.historicalRebateRequestForm.editing',
                    },
                    {
                      actions: () => console.log('3'),
                      cond: 'itemTotalsExceedTotalAmount',
                      target:
                        '#claimRebates.opened.historicalRebateRequestForm.editing',
                    },
                    {
                      target:
                        '#claimRebates.opened.historicalRebateRequestForm.submitting',
                    },
                  ],
                },
              },
              submitting: {
                invoke: {
                  src: 'submitRebateReceiptRequest',
                  id: 'Submit rebate receipt request',
                  onDone: [
                    {
                      actions: ['notifySuccess', 'refresh'],
                      target: '#claimRebates.closed.loading',
                    },
                  ],
                  onError: [
                    {
                      actions: 'notifyRequestError',
                      target:
                        '#claimRebates.opened.historicalRebateRequestForm.editing',
                    },
                  ],
                },
                tags: ['loading', 'submitting'],
              },
            },
          },
          checkRebateRequestForm: {
            entry: [
              'clearRebateReceiptRequestItems',
              'generateCheckTypeRebateReceiptRequestItem',
            ],
            initial: 'editing',
            states: {
              editing: {
                on: {
                  close: {
                    actions: 'clearRebateReceiptRequestItems',
                    target: '#claimRebates.closed.idle',
                  },
                  submit: [
                    {
                      cond: 'itemTotalsDoNotEqualTotalAmount',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                    {
                      cond: 'itemTypesMismatch',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                    {
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.submitting',
                    },
                  ],
                  openElectronicTransferRequestForm: [
                    {
                      actions: 'notifyElectronicTransfersAreDisabled',
                      cond: 'electronicTransferRequestsAreDisabled',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                    {
                      target:
                        '#claimRebates.opened.electronicTransferRequestForm.editing',
                    },
                  ],
                },
              },
              submitting: {
                invoke: {
                  src: 'submitRebateReceiptRequest',
                  id: 'Submit rebate receipt request',
                  onDone: [
                    {
                      actions: ['notifySuccess', 'refresh'],
                      target: '#claimRebates.closed.loading',
                    },
                  ],
                  onError: [
                    {
                      actions: 'notifyRequestError',
                      target:
                        '#claimRebates.opened.checkRebateRequestForm.editing',
                    },
                  ],
                },
                tags: ['loading', 'submitting'],
              },
            },
          },
        },
      },
      error: {
        initial: 'unknown',
        states: {
          unknown: {
            entry: 'notifyUnknownError',
            type: 'final',
          },
          failedToLoadMemberResource: {
            entry: 'notifyLoadMemberResourceFailure',
            type: 'final',
          },
          invalidMemberResource: {
            entry: 'notifyMemberResourceInvalid',
            type: 'final',
          },
          failedToLoadMemberFundingSources: {
            entry: 'notifyLoadMemberFundingSourcesFailure',
            type: 'final',
          },
        },
      },
    },
  },
  {
    actions: {
      assignPermissions: assign((context, { data }) => {
        if (
          !context.userType &&
          data.data.data?.adminUserId === context.userId
        ) {
          context.userType = 'memberAdmin';
        }
        context.canHandDeliver = data.data.data?.canHandDeliver ?? false;
      }),
      assignFundingSources: assign((context, { data }) => {
        let fundingSources = data?.data ?? [];
        const availableFundingSouces = fundingSources.filter(item => {
          return !item.removed;
        });
        let memberFundingAccountList: any = [];
        availableFundingSouces?.length > 0 && fundingSources.map((item: any) => {
          if (!item.removed) {
            const fundingSourceId = Decrypt(item.id);
            const fundingSourceName = Decrypt(item.name);
            const fundingBankName = Decrypt(item.bankName);
            const fundingFingerPrint = Decrypt(item.fingerprint);
            const currentFundingAccount = {
              id: fundingSourceId.toString(),
              name: fundingSourceName.toString(),
              bankAccountType: item.bankAccountType.toString(),
              bankName: fundingBankName.toString(),
              channels: item.channels,
              created: item.created,
              fingerprint: fundingFingerPrint.toString(),
              removed: item.removed,
              status: item.status,
              type: item.type,
            }
            memberFundingAccountList.push(currentFundingAccount);
          }
        });
        context.fundingSources = memberFundingAccountList ?? [];
      }),
      clearRebateReceiptRequestItems: assign((context) => (context.items = [])),
      generateElectronicTypeRebateReceiptRequestItem: assign((context) => {
        let amount = context.amount ?? 0;
        let itemsLength = context.items.length;
        const transferFee =
          context.transferFees.filter(
            (transferFee) => transferFee.name === 'standard',
          )?.[0].amount ?? 0;
        context.items.forEach((item) => {
          const snapShot = item?.getSnapshot();
          if (amount > 0) {
            amount -= snapShot?.context?.amount ?? 0;
          }
        });
        console.log({ amount });
        context.items = [
          ...context.items,
          spawn(
            rebateReceiptElectronicTypeItemMachine.withContext({
              ...rebateReceiptElectronicTypeItemMachine.context,
              amount:
                amount - transferFee * itemsLength >
                (transferFee + 1) * itemsLength
                  ? amount
                  : transferFee + 1,
              parentAmount: context.amount ?? 0,
              transferFee,
              funding_source_id: context.fundingSources?.[0]?.id,
            }),
            'electronicTypeItem' + uniqueId(),
          ),
        ];
      }),
      generateCheckTypeRebateReceiptRequestItem: assign((context) => {
        context.items = [
          spawn(
            rebateReceiptCheckTypeItemMachine.withContext({
              ...rebateReceiptCheckTypeItemMachine.context,
              amount: context.amount as number,
              canHandDeliver: context.canHandDeliver,
            }),
            'checkTypeItem',
          ),
        ];
      }),
      generateHistoricalTypeRebateReceiptRequestItem: assign((context) => {
        context.items = [
          spawn(
            rebateReceiptHistoricTypeItemMachine.withContext({
              ...rebateReceiptHistoricTypeItemMachine.context,
              amount: context.amount as number,
              parentAmount: context.amount as number,
            }),
            'historicalTypeItem',
          ),
        ];
      }),
      notifyElectronicTransfersAreDisabled: () =>
        process.env.NODE_ENV !== 'production' &&
        console.error('Electronic transfers are not available.'),
      notifyLoadMemberResourceFailure: (_, event) =>
        process.env.NODE_ENV !== 'production' && console.error(event.data),
      notifyLoadMemberFundingSourcesFailure: (_, event) =>
        process.env.NODE_ENV !== 'production' && console.error(event.data),
      notifyMemberResourceInvalid: (_, event) =>
        process.env.NODE_ENV !== 'production' &&
        console.error(
          'Member resource successfully loaded but was missing one or more critical values: ',
          event.data,
        ),
      notifyNoAvailableFunds: () =>
        process.env.NODE_ENV !== 'production' &&
        console.error(
          'Member has no available funds, unable to create member receipts.',
        ),
      notifyRequestError: () =>
        process.env.NODE_ENV !== 'production' &&
        console.error('Failed to create rebate receipt request.'),
      notifySuccess: () =>
        process.env.NODE_ENV !== 'production' &&
        console.info('Successfully submitted rebate receipt request.'),
      notifyUnknownError: () =>
        process.env.NODE_ENV !== 'production' &&
        console.error('An unknown error occurred.'),
      notifyWarning: (_, event) =>
        process.env.NODE_ENV !== 'production' && console.warn(event.message),
      refresh: () =>
        process.env.NODE_ENV !== 'production' &&
        console.error('Refresh action not implemented.'),
      removeElectronicTypeRebateReceiptRequestItemById: assign(
        (context, event) => {
          let indexToRemove = 0;
          context.items.forEach((item, index) => {
            if (item.id === event.electronicRebateReceiptItemId) {
              indexToRemove = index;
            }
          });
          context.items.splice(indexToRemove, 1);
        },
      ),
    },
    guards: {
      electronicTransferRequestsAreDisabled: (context) =>
        !context.electronicTransferRequestsEnabled,
      itemTotalsDoNotEqualTotalAmount: (context) => {
        let total = 0;
        context.items.forEach((item) => {
          total += item.getSnapshot()?.context.amount ?? 0;
        });
        return total !== context.amount;
      },
      itemsLessThanOrEqualToItemLimit: (context) =>
        context.itemLimit >= context.items.length,
      itemsAreGreaterThanOneAndIdMatchesListItem: (context, event) => {
        if (context.items.length === 1) return false;
        let indexToRemove = null;
        context.items.forEach((item, index) => {
          if (item.id === event.electronicRebateReceiptItemId) {
            indexToRemove = index;
          }
        });
        console.log({ indexToRemove });
        return typeof indexToRemove === 'number';
      },
      itemTotalsExceedTotalAmount: (context) => {
        let total = 0;
        context.items.forEach((item) => {
          total += item.getSnapshot()?.context.amount ?? 0;
        });
        return total > (context?.amount ?? 0);
      },
      itemTotalsAreEqualToOrLessThanZero: (context) => {
        let total = 0;
        context.items.forEach((item) => {
          total += item.getSnapshot()?.context.amount ?? 0;
        });
        return total <= 0;
      },
      itemTypesMismatch: (context) => {
        let checkTypes = 0;
        let historicTypes = 0;
        let dwollaAchTypes = 0;
        context.items.forEach((item) => {
          const { type } = item.getSnapshot()?.context ?? {};
          if (type === 'check') checkTypes += 1;
          if (type === 'historic') historicTypes += 1;
          if (type === 'dwolla_ach') dwollaAchTypes += 1;
        });

        console.log({ checkTypes, historicTypes, dwollaAchTypes });

        if (checkTypes > 0) {
          return !(historicTypes === 0 && dwollaAchTypes === 0);
        }

        if (historicTypes > 0) {
          return !(checkTypes === 0 && dwollaAchTypes === 0);
        }

        return dwollaAchTypes === 0;
      },
      memberResourceInvalid: (context) => {
        if (context.memberId === null) return true;
        if (!context.memberName) return true;
        return false;
      },
      noAvailableFunds: ({ amount }) => !amount || amount <= 0,
      userIsAValidMemberAdmin: ({ userType }) => userType === 'memberAdmin',
      userIsBRAdmin: ({ userType }) => userType === 'buyRightAdmin',
    },
    services: {
      loadMemberResource: (context) =>
        serviceInterceptor.get(`/members/${context.memberId}`),
      loadMemberFundingSources: (context) =>
        serverApiV2Instance.get(`/members/${context.memberId}/funding-sources`),
      submitRebateReceiptRequest: (context) => {
        let totalAmount = 0;
        const items: RebateReceiptItems = [];
        context.items.forEach((item) => {
          const { amount, handDeliver, type, funding_source_id } =
            item.getSnapshot()?.context ?? {};
          totalAmount += amount ?? 0;
          items.push({
            amount: amount as number,
            handDeliver,
            type: type as 'check' | 'historic' | 'dwolla_ach',
            funding_source_id: Encrypt(funding_source_id?.toString()),
          });
        });
        const {username} = JSON.parse(localStorage.getItem('user') || '{}')
        return serverApiV2Instance.post(
          `/members/${context.memberId}/rebate-receipts`,
          {
            amount: totalAmount,
            items,
            username
          },
        );
      },
    },
  },
);

export type ClaimRebatesMachine = typeof claimRebatesMachine;

export default claimRebatesMachine;
