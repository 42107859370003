import {
    UPDATE_VENDOR_LINKS_TYPE_LIST,
    UPDATE_VENDOR_LINK_TYPE,
    UPDATE_VENDOR_LINK_TEXT,
    UPDATE_VENDOR_LINK_URL,
    UPDATE_VENDOR_LINK_TYPE_ERROR,
    UPDATE_VENDOR_LINK_TEXT_ERROR,
    UPDATE_VENDOR_LINK_URL_ERROR,
    CLEAR_VENDOR_MODAL,
    SHOW_VENDOR_FORM_SPINNER,
    HIDE_VENDOR_FORM_SPINNER,
    UPDATE_VENDOR_LINK_IS_EDIT,
    UPDATE_VENDOR_LINK_ID,
    UPDATE_FORM_INPUTS
} from '../../constants';

const initialState = {
    spinner: false,
    vendorLinkTypeList: [],
    linkId: null,
    linkType: null,
    linkText: null,
    linkUrl: null,
    linkTextError: false,
    linkTypeError: false,
    linkUrlError: false,
    isEdit: false,
    isFormUpdated: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_VENDOR_LINK_ID:
            return ({
                ...state,
                linkId: action.linkId,
            });
        case UPDATE_VENDOR_LINKS_TYPE_LIST:
            return ({
                ...state,
                vendorLinkTypeList: action.vendorLinkTypeList,
            });
        case UPDATE_VENDOR_LINK_TYPE:
            return ({
                ...state,
                linkType: action.linkType,
            });
        case UPDATE_VENDOR_LINK_TEXT:
            return ({
                ...state,
                linkText: action.linkText,
            });
        case UPDATE_VENDOR_LINK_URL:
            return ({
                ...state,
                linkUrl: action.linkUrl,
            });
        case UPDATE_VENDOR_LINK_TEXT_ERROR:
            return ({
                ...state,
                linkTextError: action.linkTextError,
            });
        case UPDATE_VENDOR_LINK_TYPE_ERROR:
            return ({
                ...state,
                linkTypeError: action.linkTypeError,
            });
        case UPDATE_VENDOR_LINK_URL_ERROR:
            return ({
                ...state,
                linkUrlError: action.linkUrlError,
            });
        case SHOW_VENDOR_FORM_SPINNER:
            return ({
                ...state,
                spinner: action.spinner,
            });
        case HIDE_VENDOR_FORM_SPINNER:
            return ({
                ...state,
                spinner: action.spinner,
            });
        case UPDATE_VENDOR_LINK_IS_EDIT:
            return ({
                ...state,
                isEdit: action.isEdit,
            });
        case UPDATE_FORM_INPUTS:
            return ({
                ...state,
                isFormUpdated: action.isFormUpdated,
            });
        case CLEAR_VENDOR_MODAL:
            return ({
                ...state,
                linkType: null,
                linkText: null,
                linkUrl: null,
                linkTextError: false,
                linkTypeError: false,
                linkUrlError: false
            });
        default:
            return state;
    }
};