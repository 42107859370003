import { UPDATE_MEMBER_USER_CREATE_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const createMemberUser = async ({
  id,
  typeid,
  firstName,
  lastName,
  email,
  role,
}) => {
  try {
    let data = {
      memberid: id,
      firstname: firstName,
      lastname: lastName,
      email: email,
      roleid: role,
      type: typeid,
    };

    const response = await serviceInterceptor.post('/users', data);
    if (!response?.data) {
      throw UPDATE_MEMBER_USER_CREATE_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPDATE_MEMBER_USER_CREATE_ERROR;
  }
};
