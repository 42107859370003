import Grid from '@material-ui/core/Grid';
import AuthorizationService from '../../authorization/AuthorizationService';
import HeaderBGimage from '../../images/bg-header-2.png';

export const VendorTitle = ({name} : any) => (
  <div>
    <Grid container className="details-header">
      <div className="details-header-img">
        <img src={HeaderBGimage} alt="member" title="Member Header" />
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="details-header-content"
        >
          <Grid item xs={12} sm={9} md={7}>
            <div className="details-header-left">
              <h2 className="details-header-title">{name}</h2>
            </div>
          </Grid>
          {AuthorizationService.isAuthorizedHTML('VendorTitleButton') && (
            <Grid item xs={12} sm={3} md={5}>
              <div className="details-header-right" />
            </Grid>
          )}
        </Grid>
      </div>
    </Grid>
  </div>
);
