import { FC, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { SelectMembers } from './SelectMembers';
import {
  Loader,
  getExtensionClassName,
  getFileClassName,
} from '../../../../utils';
import UploadImg from '../../../../images/file-upload.svg';

const StyleDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const DocumentCreateModal: FC<any> = (props) => {
  const {
    closeAddDocumentModal,
    clearDocumentModal,
    getMemberMenuList,
    handleFileSelect,
    handleRemoveFile,
    handlePublish,
    getDCLIST,
    handleModalCategory,
    onChangeEditDocDesc,
    documents: {
      isAllMembers,
      selectedMembers,
      selectedFileName,
      selectedFileSize,
      selectedFileExtension,
      fileExtensionError,
      categoryList,
      spinner,
      categoryError,
      documentDesc,
      documentDescError,
    },
    modal: { showModal },
  } = props;

  useEffect(() => {
    getMemberMenuList();
    getDCLIST();
    return () => {
      clearDocumentModal();
    };
  }, []);

  const errorFlag =
    fileExtensionError ||
    categoryError ||
    selectedFileName === null ||
    (!isAllMembers && selectedMembers.length === 0);

  const categoryType = categoryList && categoryList.slice(1);

  return (
    <div>
      <Dialog
        onClose={closeAddDocumentModal}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        className="dialog-box"
      >
        {spinner && (
          <div className="loader-wrapper">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        )}
        <StyleDialogTitle onClose={closeAddDocumentModal}>
          Add Document
        </StyleDialogTitle>

        <StyledDialogContent>
          <Grid container>
            <Grid item xs={12} sm={6}>
              {selectedFileName === null || fileExtensionError ? (
                <Button className="file-filed-document" component="label">
                  <img src={UploadImg} />
                  <div className="file-filed-text-size-sm">
                    Upload PDF, DOC OR XLS files
                  </div>
                  <div className="file-filed-text-size-md">
                    Drop your files here,{' '}
                  </div>
                  <div>
                    or{' '}
                    <span className="file-filed-text-size-highlight">
                      browse
                    </span>
                  </div>
                  <input
                    type="file"
                    id="fileUpload"
                    title=""
                    value=""
                    onChange={(event) =>
                      handleFileSelect(event?.target?.files?.[0])
                    }
                  />
                  {fileExtensionError && (
                    <div className="error-wrapper">
                      <i className="fas fa-exclamation-triangle error-icon" />
                      <div className="error-text">
                        {'Please select a valid file type.'}
                      </div>
                    </div>
                  )}
                </Button>
              ) : (
                <div className="edit-doc-section">
                  <div className="edit-doc-section-box">
                    <div className="documents-grid-img">
                      <i
                        className={getFileClassName(selectedFileExtension)}
                      ></i>
                      <span
                        className={getExtensionClassName(selectedFileExtension)}
                      >
                        {selectedFileExtension}
                      </span>
                    </div>

                    <div className="edit-doc-section-name">
                      {selectedFileName}
                    </div>
                    <div className="edit-doc-section-size">
                      {selectedFileSize}
                    </div>
                    <div className="edit-doc-section-buttons">
                      <Button
                        className="remove-button"
                        onClick={handleRemoveFile}
                      >
                        Remove
                      </Button>
                      <label className="replace-button" htmlFor="replaceFile">
                        Replace
                      </label>
                      <input
                        type="file"
                        id="replaceFile"
                        hidden
                        onChange={(event) =>
                          handleFileSelect(event?.target?.files?.[0])
                        }
                      />
                    </div>
                  </div>

                  <div className="edit-doc-section-select">
                    <FormControl className="select-box" variant="outlined">
                      {!categoryError ? (
                        <InputLabel htmlFor="outlined-age-native-simple">
                          Document Category
                        </InputLabel>
                      ) : (
                        <InputLabel
                          htmlFor="outlined-age-native-simple"
                          style={{ color: 'red' }}
                        >
                          Document Category
                        </InputLabel>
                      )}

                      <Select
                        label="Document category"
                        error={categoryError}
                        id={
                          categoryError
                            ? 'standard-error'
                            : 'outlined-age-native-simple'
                        }
                        onChange={(e) => handleModalCategory(e.target.value)}
                      >
                        <option value="NA">Select a Category</option>
                        {categoryType.map(
                          (categoryTypeValue: any, key: number) => (
                            <option key={key}>{categoryTypeValue.name}</option>
                          ),
                        )}
                      </Select>
                      {categoryError ? (
                        <p style={{ color: 'red', fontSize: '12px' }}>
                          Please select Document category
                        </p>
                      ) : (
                        ''
                      )}
                    </FormControl>
                  </div>

                  <div className="doc-desc">
                    <TextField
                      multiline
                      rows={3}
                      label="Document Description"
                      placeholder="Document Description"
                      variant="outlined"
                      className="text-box"
                      required
                      onChange={(e) => onChangeEditDocDesc(e.target.value)}
                      inputProps={{ maxLength: 150 }}
                      defaultValue={documentDesc}
                      id={
                        documentDescError ? 'standard-error' : 'outlined-basic'
                      }
                      error={documentDescError}
                      helperText={
                        documentDescError
                          ? 'Please enter document description'
                          : ''
                      }
                    />
                    <span className="br-conditions-text">
                      *Allowed max length of 150 characters only.
                    </span>
                  </div>
                </div>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <SelectMembers {...props} />
            </Grid>
          </Grid>
        </StyledDialogContent>

        <StyledDialogActions>
          <div className="modal-buttons-container">
            <div className="action-button">
              <button
                className="cancel-user-button"
                onClick={closeAddDocumentModal}
              >
                Cancel
              </button>
              <Button
                autoFocus
                onClick={handlePublish}
                className={
                  errorFlag || spinner
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={errorFlag}
              >
                Publish
              </Button>
            </div>
          </div>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default DocumentCreateModal;
