import {DOCUMENT_RECORDS_API, UPDATE_DOCUMENT_ERROR} from "../../constants";


export const updateDocument = async ({
    documentID,
    documentDesc,
    documentCategory
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DOCUMENT_RECORDS_API}/${documentID}`, {
            method: "PATCH",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                description: documentDesc,
                documentTypeId: documentCategory
            })
        }
        );
        if (!response.ok) throw UPDATE_DOCUMENT_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DOCUMENT_ERROR;
    }
};
