import { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import '../community.scss';

export const CommunityQuestionsBottomContent: FC<{
  userId: any;
  isBRAdmin: any;
  handleEditDiscussion: any;
  updateArchiveDiscussion: any;
  item: {
    viewCount: any;
    commentCount: any;
    userId: any;
    lockedAt: any;
  };
  handleHeaderClick: any;
  isViewAsMember: any
}> = ({
  userId,
  isBRAdmin,
  handleEditDiscussion,
  updateArchiveDiscussion,
  item,
  handleHeaderClick,
  isViewAsMember
}) => {
  const { viewCount, commentCount, userId: commentUserId, lockedAt } = item;
  return (
    <div className="community-questions-bottom">
      <div className="community-questions-bottom-left">
        <div className="community-questions-button">
          <i className="fas fa-comment-dots" />
          <span className="community-questions-number">{commentCount}</span>
          <span className="community-questions-text">Comments</span>
        </div>
        <div className="community-questions-button">
          <i className="fas fa-eye" />
          <span className="community-questions-number">{viewCount}</span>
          <span className="community-questions-text">Views</span>
        </div>
      </div>

      <div className="community-questions-bottom-right">
        {userId === commentUserId && (
          <Tooltip title="Edit">
            <Button
              variant="contained"
              size="small"
              className="btn-community-edit"
              onClick={() => handleEditDiscussion(true, item)}
              disabled={lockedAt || isViewAsMember}
            >
              <i className="fas fa-pen" />
            </Button>
          </Tooltip>
        )}
        {(isBRAdmin || lockedAt) && (
          <Tooltip title="Archive">
            <Button
              variant="contained"
              size="small"
              className={
                lockedAt
                  ? 'btn-community-trash action-active'
                  : 'btn-community-trash'
              }
              onClick={() => updateArchiveDiscussion(item)}
              disabled={lockedAt}
            >
              <i className="fas fa-lock" />
            </Button>
          </Tooltip>
        )}
        <Button
          variant="contained"
          size="small"
          className="add-comment-channel"
          onClick={() => handleHeaderClick(item, true)}
          disabled={lockedAt || isViewAsMember}
        >
          Comment
        </Button>
      </div>
    </div>
  );
};
