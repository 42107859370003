import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { createMachine, sendParent } from 'xstate';
import { getAvailableRebates } from '../../../../../../api/rebates';
import { membersMachine } from '../../../../../../machines';

export const dashboardEarnedRebatesMachine = createMachine(
  {
    id: 'dashboardEarnedRebates',
    schema: {
      context: {} as {
        acquiredAmount: number;
        availableAmount: number;
        pendingAmount: number;
        selectedMemberId: null | number;
      },
      events: {} as { type: 'updateSelectedMember'; memberId: number },
      services: {} as {
        getAvailableRebatesOverview: {
          data: AxiosResponse<{
            error?: any;
            message?: string;
            success: boolean;
            data?: {
              availableAmount: number | string;
              pendingAmount: number | string;
              acquiredAmount: number | string;
            };
          }>;
        };
      },
    },
    tsTypes: {} as import('./dashboardEarnedRebatesMachine.typegen').Typegen0,
    context: {
      acquiredAmount: 0,
      availableAmount: 0,
      pendingAmount: 0,
      selectedMemberId: null,
    },
    invoke: {
      id: 'members',
      src: 'members',
    },
    initial: 'waitingForMemberData',
    states: {
      waitingForMemberData: { tags: ['loading', 'hidden'],
      on: {
        updateSelectedMember: {
          actions: 'assignSelectedMember',
          target: '#dashboardEarnedRebates.loadingRebatesOverview',
        },
      },
    },
      loadingRebatesOverview: {
        invoke: {
          id: 'Load rebates overview',
          src: 'getAvailableRebatesOverview',
          onDone: {
            actions: 'assignOverviewValues',
            target: 'idle',
          },
          onError: 'error',
        },
        tags: ['loading'],
      },
      idle: {
        on: {
          updateSelectedMember: {
            actions: 'assignSelectedMember',
            target: '#dashboardEarnedRebates.loadingRebatesOverview',
          },
        },
      },
      error: { type: 'final' },
    },
  },
  {
    actions: {
      assignOverviewValues: assign((context, event) => {
        context.acquiredAmount = Number(event.data.data.data?.acquiredAmount) || 0;
        context.availableAmount = Number(event.data.data.data?.availableAmount) || 0;
        context.pendingAmount = Number(event.data.data.data?.pendingAmount) || 0;
      }),
      assignSelectedMember: assign((context, event) => {
        context.selectedMemberId = event.memberId;
      }),
    },
    services: {
      getAvailableRebatesOverview: (context) =>
        getAvailableRebates(context.selectedMemberId as number),
      members: membersMachine.withConfig({
        actions: {
          sendParentSelectedMember: sendParent((context) => ({
            memberId: context.selectedMember?.id ?? null,
            type: 'updateSelectedMember',
          })),
        },
      }),
    },
  },
);

export type DashboardEarnedRebatesMachine =
  typeof dashboardEarnedRebatesMachine;
export default dashboardEarnedRebatesMachine;
