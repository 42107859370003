import React, { useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

import DialogWindow from "../../../../components/DialogWindow";

export const AddAdsTypeModal = (props) => {
  const {
    showModal,
    handleCloseAdsTypeModal,
    clearAdsTypeForm,
    handleChangeAdTypeName,
    handleChangeAdTypeDescription,
    onAddNewAdType,
    adsTypeCreate: { 
      loading,
      isFormUpdated,
      adTypeName,
      adTypeDesc,
      errorAdTypeName,
      errorAdTypeDesc
    },
  } = props;

  useEffect(() => {
    return () => {
      clearAdsTypeForm();
    };
  }, []);

  const dialogWindowConfig = () => {
    const newConfig = {
      title: "Manage Ad Type",
      isCancel: true,
      submitText: "Save",
      isGoBack: false,
      backText: "",
    };
    return newConfig;
  };

  return (
    <DialogWindow
      openDialog={showModal}
      dialogConfiguration={dialogWindowConfig()}
      handleClose={handleCloseAdsTypeModal}
      handleSubmit={onAddNewAdType}
      isButtonDisabled={!isFormUpdated}
      spinnerEnabled={loading}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} >
          <div className="form-element-container">
            <TextField
              label="Name"
              variant="outlined"
              className="text-box"
              value={adTypeName}
              onChange={(e) => handleChangeAdTypeName(e.target.value)} 
              id={errorAdTypeName ? "standard-error name" : "outlined-basic name"} 
              helperText={errorAdTypeName ? "Please enter name" : ""} 
              required
            />
          </div>
        </Grid>
        <Grid item xs={12} >
          <div className="form-element-container">
            <TextField
              label="Description"
              variant="outlined"
              className="text-box"
              multiline
              minRows={3}
              maxRows={4}
              value={adTypeDesc}
              onChange={(e) => handleChangeAdTypeDescription(e.target.value)} 
              id={errorAdTypeDesc ? "standard-error name" : "outlined-basic name"} 
              helperText={errorAdTypeDesc ? "Please enter description" : ""} 
              required
            />
          </div>
        </Grid>
      </Grid>
    </DialogWindow>
  );
};
