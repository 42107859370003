import {
  baseUrl,
  UPDATE_VENDOR_SALES_ERROR,
} from '../../constants';

export const getVendorSalesList = async (
  vendorID: number,
  // type
) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const response = await fetch(
      `${baseUrl}/getUsers?vendorid=${vendorID}&type=${3}`, // TODO Easily the most poorly constructed endpoint I've seen yet.
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw UPDATE_VENDOR_SALES_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_VENDOR_SALES_ERROR;
  }
};
