import { FC, useEffect } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@mui/styles';
import { SelectMembers } from './SelectMembers';
import {
  Loader,
  getExtensionClassName,
  getFileClassName,
} from '../../../../utils';
import UploadImg from '../../../../images/file-upload.svg';

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const DocumentEditModal: FC<any> = (props) => {
  const {
    clearDocumentModal,
    handleFileSelect,
    handleUpdate,
    closeDocumentEditModal,
    onChangeEditDocDesc,
    handleModalCategory,
    documents: {
      documentDesc,
      selectedModalCategory,
      selectedFileName,
      selectedFileSize,
      selectedFileExtension,
      fileExtensionError,
      categoryList,
      spinner,
      categoryError,
      isEditDocument,
      modalSpinner,
      documentEditError,
      selectedDocumentData,
      isFormUpdated,
    },
  } = props;

  useEffect(() => {
    return () => {
      clearDocumentModal();
    };
  }, []);

  const categoryName =
    selectedModalCategory !== 'NA'
      ? categoryList.find((item: any) => item.id === selectedModalCategory)
      : 'NA';
  const lastUpdateDate = selectedDocumentData
    ? selectedDocumentData.uploadeddate
    : null;

  const errorFlag = documentEditError;

  const categoryType =
    categoryList && categoryList.filter((item: any) => item.id !== 0);

  return (
    <Dialog
      onClose={closeDocumentEditModal}
      aria-labelledby="customized-dialog-title"
      open={isEditDocument}
      className="dialog-box"
    >
      {modalSpinner && (
        <div className="loader-wrapper">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      )}
      <StyledDialogTitle onClose={closeDocumentEditModal}>
        Edit Document
      </StyledDialogTitle>

      <StyledDialogContent>
        <Grid container>
          <Grid item xs={12} sm={6}>
            {selectedFileName === null || fileExtensionError ? (
              <Button className="file-filed-document" component="label">
                <img src={UploadImg} />
                <div className="file-filed-text-size-sm">
                  Upload PDF, DOC OR XLS files
                </div>
                <div className="file-filed-text-size-md">
                  Drop your files here.{' '}
                </div>
                <div>
                  or{' '}
                  <span className="file-filed-text-size-highlight">browse</span>
                </div>
                <input
                  type="file"
                  id="fileUpload"
                  title=""
                  value=""
                  onChange={(event) =>
                    handleFileSelect(event?.target?.files?.[0])
                  }
                />
                {fileExtensionError && (
                  <div className="error-wrapper">
                    <i className="fas fa-exclamation-triangle error-icon" />
                    <div className="error-text">
                      {'Please select a valid file type.'}
                    </div>
                  </div>
                )}
              </Button>
            ) : (
              <div className="edit-doc-section">
                <div className="edit-doc-section-box edit-dropzone">
                  <div className="documents-grid-img">
                    <i className={getFileClassName(selectedFileExtension)} />
                    <span
                      className={getExtensionClassName(selectedFileExtension)}
                    >
                      {selectedFileExtension}
                    </span>
                  </div>

                  <div className="edit-doc-section-name">
                    {selectedFileName}
                  </div>
                  <div className="edit-doc-section-size">
                    {selectedFileSize}
                  </div>
                  {lastUpdateDate !== null && (
                    <div className="edit-doc-section-size">
                      {`Uploaded Date: ${lastUpdateDate}`}
                    </div>
                  )}
                </div>

                <div className="edit-doc-section-select">
                  <FormControl className="select-box" variant="outlined">
                    {!categoryError ? (
                      <InputLabel htmlFor="outlined-age-native-simple">
                        Document Category
                      </InputLabel>
                    ) : (
                      <InputLabel
                        htmlFor="outlined-age-native-simple"
                        style={{ color: 'red' }}
                      >
                        Document Category
                      </InputLabel>
                    )}

                    <Select
                      label="Document category"
                      defaultValue={categoryName.name}
                      onChange={(e) => handleModalCategory(e.target.value)}
                    >
                      {categoryType.map((item: any, key: number) => (
                        <option key={key}>{item.name}</option>
                      ))}
                    </Select>
                  </FormControl>
                </div>

                <div className="doc-desc">
                  <TextField
                    multiline
                    rows={3}
                    label="Document Description"
                    placeholder="Document Description"
                    variant="outlined"
                    className="text-box"
                    onChange={(e) => onChangeEditDocDesc(e.target.value)}
                    inputProps={{ maxLength: 150 }}
                    defaultValue={documentDesc}
                    id={errorFlag ? 'standard-error' : 'outlined-basic'}
                    error={errorFlag}
                    helperText={
                      errorFlag ? 'Please enter document description' : ''
                    }
                  />
                  <span className="br-conditions-text">
                    *Allowed max length of 150 characters only.
                  </span>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectMembers isEditDocument={isEditDocument} {...props} />
          </Grid>
        </Grid>
      </StyledDialogContent>

      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div className="action-button">
            <button
              className="cancel-user-button"
              onClick={closeDocumentEditModal}
            >
              Cancel
            </button>
            <Button
              autoFocus
              onClick={handleUpdate}
              className={
                errorFlag || modalSpinner || !isFormUpdated
                  ? 'invite-user-button button-disable'
                  : 'invite-user-button'
              }
              disabled={errorFlag}
            >
              Update
            </Button>
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default DocumentEditModal;
