import {
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST,
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER,
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST,
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER,
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS,
  UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW,
  GET_REBATE_MEMBER_PRODUCT_LIST_ERROR,
  UPDATE_MEMBERS_LIST_ERROR,
  ActiveEarningsMemberListError,
  FetchError,
} from '../../constants';
import { getDashboardActiveEarningDetails } from '../../api/dashboard';
import { getMemberRebatesQuarters } from '../../api/rebates';
import { getMembers } from '../../api/members';
import { updateActiveTab } from '../rebates';
import {
  DashboardActiveEarningsQuarterSpinner,
  DashboardActiveEarningsMemberSpinner,
  DashboardActiveEarningsDetailSpinner,
} from './dashboardSpinners';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavigateFunction } from 'react-router-dom';

export const updateActiveEarningsRoute =
  (navigate: NavigateFunction) => async (dispatch: any, getState: any) => {
    const { user } = getState();
    const { isBRAdmin, vendorusers } = user;
    navigate('/rebates');
    if (isBRAdmin || vendorusers?.length > 0) {
      dispatch(updateActiveTab(3));
    } else {
      dispatch(updateActiveTab(2));
    }
  };

export const getDashboardActiveEarningsQuarterList =
  () => async (dispatch: any) => {
    dispatch(DashboardActiveEarningsQuarterSpinner(true));
    try {
      const res = await getMemberRebatesQuarters();
      const data = res.data ? res.data : [];
      const filteredData =
        data && data.filter((item: any) => item.status === 'PUBLISHED');
      dispatch({
        type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST,
        activeEarningsQuarterList: filteredData,
      });
      dispatch({
        type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER,
        selectedActiveEarningsQuarter: filteredData[0],
      });
      dispatch(getDashboardActiveEarningsMemberList());
      dispatch(DashboardActiveEarningsQuarterSpinner(false));
    } catch (error) {
      dispatch(DashboardActiveEarningsQuarterSpinner(false));
      toast.error(FetchError);
    }
  };

export const getDashboardActiveEarningsMemberList =
  () => async (dispatch: any, getState: any) => {
    dispatch(DashboardActiveEarningsMemberSpinner(true));
    try {
      const res = await getMembers();
      const data = res.data.result ? res.data.result : [];
      const memberOptions =
        data &&
        data.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
      dispatch({
        type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST,
        activeEarningsMemberList: memberOptions,
      });
      dispatch({
        type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER,
        selectedActiveEarningsMember: memberOptions[0],
      });
      const { dashboardActiveEarnings } = getState();
      const { selectedActiveEarningsMember, selectedActiveEarningsQuarter } =
        dashboardActiveEarnings;
      const activeQuarter = selectedActiveEarningsQuarter
        ? selectedActiveEarningsQuarter.value.split(' ')
        : null;
      if (activeQuarter !== null) {
        const quarter = activeQuarter[0];
        const year = activeQuarter[1];
        const member =
          selectedActiveEarningsMember && selectedActiveEarningsMember.value;
        dispatch(getDashboardActiveEarningsRecords(quarter, year, member));
      }
      dispatch(DashboardActiveEarningsMemberSpinner(false));
    } catch (error) {
      dispatch(DashboardActiveEarningsMemberSpinner(false));
      if (error === UPDATE_MEMBERS_LIST_ERROR) {
        toast.error(ActiveEarningsMemberListError);
      } else {
        toast.error(FetchError);
      }
    }
  };

export const getDashboardActiveEarningsRecords =
  (quarter: any, year: any, member: any) => async (dispatch: any) => {
    dispatch(DashboardActiveEarningsDetailSpinner(true));
    try {
      const res = await getDashboardActiveEarningDetails({
        quarter,
        year,
        member,
      });
      const { data, success, overview } = res;
      if (success && overview) {
        dispatch({
          type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS,
          dashboardActiveEarningsDetails: data,
        });
        dispatch({
          type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW,
          dashboardActiveEarningsOverview: overview,
        });
      } else {
        dispatch({
          type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS,
          dashboardActiveEarningsDetails: null,
        });
        dispatch({
          type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW,
          dashboardActiveEarningsOverview: null,
        });
      }
      dispatch(DashboardActiveEarningsDetailSpinner(false));
    } catch (error) {
      console.error('ERROR: ', error);
      dispatch(DashboardActiveEarningsDetailSpinner(false));
      if (error === GET_REBATE_MEMBER_PRODUCT_LIST_ERROR) {
        toast.error('Unable to fetch Active Earnings details'); // TODO
      } else {
        toast.error(FetchError);
      }
    }
  };

export const getDashboardActiveEarningsData = () => async (dispatch: any) => {
  dispatch(getDashboardActiveEarningsQuarterList());
};

export const handleActiveEarningsMemberChange =
  (selectedMember: any) => async (dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER,
      selectedActiveEarningsMember: selectedMember,
    });
    const { dashboardActiveEarnings } = getState();
    const { selectedActiveEarningsMember, selectedActiveEarningsQuarter } =
      dashboardActiveEarnings;
    const activeQuarter =
      selectedActiveEarningsQuarter &&
      selectedActiveEarningsQuarter.value.split(' ');
    const quarter = activeQuarter[0];
    const year = activeQuarter[1];
    const member =
      selectedActiveEarningsMember && selectedActiveEarningsMember.value;
    dispatch(getDashboardActiveEarningsRecords(quarter, year, member));
  };

export const handleActiveEarningsQuarterChange =
  (selectedQuarter: any) => async (dispatch: any, getState: any) => {
    dispatch({
      type: UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER,
      selectedActiveEarningsQuarter: selectedQuarter,
    });
    const { dashboardActiveEarnings } = getState();
    const { selectedActiveEarningsMember, selectedActiveEarningsQuarter } =
      dashboardActiveEarnings;
    const activeQuarter =
      selectedActiveEarningsQuarter &&
      selectedActiveEarningsQuarter.value.split(' ');
    const quarter = activeQuarter[0];
    const year = activeQuarter[1];
    const member =
      selectedActiveEarningsMember && selectedActiveEarningsMember.value;
    dispatch(getDashboardActiveEarningsRecords(quarter, year, member));
  };
