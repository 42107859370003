import moment from "moment";
import {
    FOODBUY_ITEM,
    UPDATE_PRODUCT_REBATES_SPINNER,
    UPDATE_REBATE_QUARTER_SPINNER,
    UPDATE_PRODUCT_REBATES_LIST_SPINNER,
    UPDATE_PRODUCT_REBATES_QUARTER,
    UPDATE_PRODUCT_REBATES_QUARTER_LIST,
    UPDATE_PRODUCT_REBATES_LIST,
    UPDATE_PRODUCT_REBATES_YEAR,
    UPDATE_PRODUCT_REBATES_FILE_CONTENT,
    UPDATE_PRODUCT_REBATES_FILE_NAME,
    UPDATE_PRODUCT_REBATES_FILE_SIZE,
    UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
    UPDATE_PRODUCT_REBATES_DRAFT,
    UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR,
    CLEAR_PRODUCT_REBATES_MODAL,
    CLEAR_PRODUCT_REBATES_NOTES_MODAL,
    CLEAR_PRODUCT_REBATES_EXPORT_MODAL,
    UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER,
    UPDATE_PRODUCT_REBATES_EDIT_FLAG,
    UPDATE_PRODUCT_REBATES_EXPORT_MODAL,
    UPDATE_PRODUCT_REBATE_NOTES,
    UPDATE_PRODUCT_REBATE_NOTES_EDIT,
    UPDATE_SELECTED_PRODUCT,
    UPDATE_PRODUCT_REBATES_NOTES,
    UPDATE_PRODUCT_REBATES_EXPORT_QUARTER,
    UPDATE_PRODUCT_REBATES_EXPORT_YEAR,
    UPDATE_PRODUCT_REBATES_REQUEST_TYPE,
    UPDATE_PRODUCT_REBATES_INFO,
    RebateProductLists
} from "../../constants";
import {quarterOfTheYear} from "../../utils";

const currentYear = moment().format('YYYY');
const currentDate = new Date();
const currentQuarter = quarterOfTheYear(currentDate);

const initialState = {
    productRebatesSpinner: false,
    productRebatesListSpinner: false,
    activeQuarter: null,
    quarterList: [],
    productRebateslist: [],
    quarter: currentQuarter,
    year: currentYear,
    rebateType: FOODBUY_ITEM,
    requestRebateType: RebateProductLists,
    productRebatesInfo: null,
    fileContent: null,
    selectedProduct: null,
    selectedFileName: null,
    selectedFileSize: null,
    selectedFileExtension: null,
    notes: null,
    exportQuarter: null,
    exportYear: null,
    isDraft: false,
    isEdit: false,
    isProductRebateExportModal: false,
    isNotesModal: false,
    isNotesEditModal: false,
    fileExtensionError: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PRODUCT_REBATES_SPINNER:
            return {
                ...state,
                productRebatesSpinner: action.productRebatesSpinner,
            };
        case UPDATE_PRODUCT_REBATES_LIST_SPINNER:
            return {
                ...state,
                productRebatesListSpinner: action.productRebatesListSpinner,
            };
        case UPDATE_PRODUCT_REBATES_QUARTER_LIST:
            return {
                ...state,
                quarterList: action.quarterList,
            };
        case UPDATE_PRODUCT_REBATES_LIST:
            return {
                ...state,
                productRebateslist: action.productRebateslist,
            };
        case UPDATE_PRODUCT_REBATES_QUARTER:
            return {
                ...state,
                quarter: action.quarter,
            };
        case UPDATE_PRODUCT_REBATES_YEAR:
            return {
                ...state,
                year: action.year,
            };
        case UPDATE_PRODUCT_REBATES_FILE_CONTENT:
            return {
                ...state,
                fileContent: action.fileContent,
            };
        case UPDATE_PRODUCT_REBATES_FILE_NAME:
            return {
                ...state,
                selectedFileName: action.selectedFileName,
            };
        case UPDATE_PRODUCT_REBATES_FILE_SIZE:
            return {
                ...state,
                selectedFileSize: action.selectedFileSize,
            };
        case UPDATE_PRODUCT_REBATES_FILE_EXTENSION:
            return {
                ...state,
                selectedFileExtension: action.selectedFileExtension,
            };
        case UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR:
            return {
                ...state,
                fileExtensionError: action.fileExtensionError,
            };
        case UPDATE_PRODUCT_REBATES_DRAFT:
            return {
                ...state,
                isDraft: action.isDraft,
            };
        case UPDATE_PRODUCT_REBATES_EDIT_FLAG:
            return {
                ...state,
                isEdit: action.isEdit,
            };
        case UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER:
            return {
                ...state,
                activeQuarter: action.activeQuarter,
            };
        case UPDATE_PRODUCT_REBATES_EXPORT_MODAL:
            return {
                ...state,
                isProductRebateExportModal: action.isProductRebateExportModal,
            };
        case UPDATE_PRODUCT_REBATE_NOTES:
            return {
                ...state,
                isNotesModal: action.isNotesModal,
            };
        case UPDATE_PRODUCT_REBATE_NOTES_EDIT:
            return {
                ...state,
                isNotesEditModal: action.isNotesEditModal,
            };
        case UPDATE_SELECTED_PRODUCT:
            return {
                ...state,
                selectedProduct: action.selectedProduct,
            };
        case UPDATE_PRODUCT_REBATES_NOTES:
            return {
                ...state,
                notes: action.notes,
            };
        case UPDATE_PRODUCT_REBATES_EXPORT_QUARTER:
            return {
                ...state,
                exportQuarter: action.exportQuarter,
            };
        case UPDATE_PRODUCT_REBATES_EXPORT_YEAR:
            return {
                ...state,
                exportYear: action.exportYear,
            };
        case UPDATE_PRODUCT_REBATES_REQUEST_TYPE:
            return {
                ...state,
                requestRebateType: action.requestRebateType,
            };
        case UPDATE_PRODUCT_REBATES_INFO:
            return {
                ...state,
                productRebatesInfo: action.productRebatesInfo,
            };
        case CLEAR_PRODUCT_REBATES_MODAL:
            return ({
                ...state,
                productRebatesSpinner: false,
                rebateType: FOODBUY_ITEM,
                fileContent: null,
                selectedFileName: null,
                selectedFileSize: null,
                selectedFileExtension: null,
                fileExtensionError: false
            });
        case CLEAR_PRODUCT_REBATES_NOTES_MODAL:
            return ({
                ...state,
                isNotesModal: false,
                isNotesEditModal: false,
                selectedProduct: null,
                notes: null
            });
        case CLEAR_PRODUCT_REBATES_EXPORT_MODAL:
            return ({
                ...state,
                isProductRebateExportModal: false
            });
        default:
            return state;
    }
};
