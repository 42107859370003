import { FC } from 'react';
import { VendorTitle } from '../VendorTitle';
import { VendorsHeader } from '../VendorsHeader';
import { VendorTabs } from '../VendorsTabs';
import '../vendors.scss';
import performanceFoodserviceLogo from '../../../images/pfg-icon.png';

export const PerformanceFoodServiceMain: FC = (props) => {
  return (
    <>
      <VendorTitle name="Performance Foodservice" />
      <VendorsHeader image={performanceFoodserviceLogo}/>
      <VendorTabs {...props} />
    </>
  );
};

export type PerformanceFoodServiceMain = typeof PerformanceFoodServiceMain | any;
