import React, {Fragment, useState} from "react";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import {
  Loader,
  getExtensionClassName,
  getFileClassName,
} from "../../../../../utils";
import {FileOneUpload, FileTwoUpload, FileThreeUpload} from "./fileUpload";


export const StepFileUpload = (props) => {

  const {
    memberRebates,
    handleMemberRebatesFileOne,
    handleMemberRebatesFileTwo,
    handleMemberRebatesFileThree,
    onMemberRebatesFileOneRemove,
    onMemberRebatesFileTwoRemove,
    onMemberRebatesFileThreeRemove,
    updateActiveStep
  } = props;

  const {
    fileOneContent, fileTwoContent, fileThreeContent,
    activeStep, fileOneName, fileOneSize, fileOneExtension,
    fileTwoName, fileTwoSize, fileTwoExtension, fileThreeName,
    fileThreeSize, fileThreeExtension,
  } = memberRebates;

  const getSteps = () => {
    return [
      "FoodBuy Item Level Spend",
      "FoodBuy Allowances Solutions",
      `Buy Right - "Janets Programs SOL"`,
    ];
  }

  const handleNext = () => {
    updateActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    updateActiveStep(activeStep - 1);
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <div className="step-content">
            <FileOneUpload
              memberRebates={memberRebates}
              handleMemberRebatesFile={handleMemberRebatesFileOne}
              onMemberRebatesFileRemove={onMemberRebatesFileOneRemove}
              handleNext={handleNext}
            />
          </div>
        );
      case 1:
        return (
          <div className="step-content">
            <FileTwoUpload
              memberRebates={memberRebates}
              handleMemberRebatesFile={handleMemberRebatesFileTwo}
              onMemberRebatesFileRemove={onMemberRebatesFileTwoRemove}
              handleNext={handleNext}
            />
          </div>
        );
      case 2:
        return (
          <div className="step-content">
            <FileThreeUpload
              memberRebates={memberRebates}
              handleMemberRebatesFile={handleMemberRebatesFileThree}
              onMemberRebatesFileRemove={onMemberRebatesFileThreeRemove}
              handleNext={handleNext}
            />
          </div>
        );
      default:
        return "Unknown stepIndex";
    }
  }
  const count = [
    {
      fileName: fileOneName,
      fileSize: fileOneSize,
      fileExtension: fileOneExtension
    },
    {
      fileName: fileTwoName,
      fileSize: fileTwoSize,
      fileExtension: fileTwoExtension
    },
    {
      fileName: fileThreeName,
      fileSize: fileThreeSize,
      fileExtension: fileThreeExtension
    }
  ];

  const steps = getSteps();

  const leftArrowdisableFlag = fileOneContent === null || activeStep === 0;

  const rightArrowdisableFlag = activeStep === 0 && fileOneContent === null ||
    activeStep === 1 && fileTwoContent === null ||
    activeStep === 2 && fileThreeContent === null;

  return (
    <div className="step-nav">
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
      <div className="step-content-wrapper">
        {activeStep === steps.length ? (
          <div className="step-content">
            <div className="member-rebates-file-section">
              {
                count.map((item) => {
                  const {fileName, fileSize, fileExtension} = item;
                  return (
                    <div className="edit-doc-section">
                      <div className="edit-doc-section-box">
                        <div className="documents-grid-img">
                          <i className={getFileClassName(fileExtension)} />
                          <span
                            className={getExtensionClassName(fileExtension)}
                          >
                            {fileExtension}
                          </span>
                        </div>
                        <div className="edit-doc-section-name">
                          {fileName}
                        </div>
                        <div className="edit-doc-section-size">
                          {fileSize}
                        </div>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </div>
        ) : (
          <Fragment>
            <Typography>{getStepContent(activeStep)}</Typography>
            <div className="step-footer">
              <Button
                disabled={leftArrowdisableFlag}
                onClick={handleBack}
                className={leftArrowdisableFlag ? "btn-add-blue button-disable" : "btn-add-blue"}
              >
                <i className="fas fa-chevron-left arrow-left" />
              </Button>
              <Button
                variant="contained"
                className={rightArrowdisableFlag ? "btn-add-blue button-disable" : "btn-add-blue"}

                onClick={handleNext}
                disabled={rightArrowdisableFlag}
              >
                <i className="fas fa-chevron-right arrow-right" />
              </Button>
            </div>
          </Fragment>
        )}
      </div>
    </div>
  );
};
