import {COMMUNITY_CATEGORY_API, CREATE_NEW_DISCUSSION_ERROR} from '../../../constants';

export const createDiscussion = async ({
    activeChannelID,
    discussionTitle,
    discussionDesc,
    userId
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${COMMUNITY_CATEGORY_API}/${activeChannelID}/discussions`, {
            method: 'POST',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                title: discussionTitle,
                description: discussionDesc,
                userId
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw CREATE_NEW_DISCUSSION_ERROR;
    }
};