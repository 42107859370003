import {DISCUSSION_API, UPDATE_DISCUSSION_VIEW_ERROR} from '../../../constants';

export const UpdateViewDiscussion = async ({
    userId,
    discussionId
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${discussionId}/views`, {
            method: 'POST',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({userId})
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DISCUSSION_VIEW_ERROR;
    }
};