import {COMMUNITY_CATEGORY_API, UPDATE_COMMUNITY_CATEGORY_ERROR} from "../../../constants";


export const getCommunityCategory = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const member = JSON.parse(sessionStorage.getItem('user') || '{}');
        let accessToken = Object.keys(member).length ? member.accessToken : user.accessToken
        const response = await fetch(`${COMMUNITY_CATEGORY_API}`, {
            headers: new Headers({
                "x-access-token": accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_COMMUNITY_CATEGORY_ERROR;
    }
}

