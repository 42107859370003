import {
  UPDATE_VENDOR_DC_LIST,
  CLEAR_DC_MODAL,
  UPDATE_DC_IS_EDIT,
  UPDATE_VENDOR_DC_NAME_ERROR,
  UPDATE_VENDOR_DC_NAME,
  UPDATE_DC_FORM_INPUTS,
  UPDATE_VENDOR_DC_STREET_ERROR,
  UPDATE_VENDOR_DC_STREET,
  UPDATE_VENDOR_DC_CITY_ERROR,
  UPDATE_VENDOR_DC_CITY,
  UPDATE_VENDOR_DC_STATE_ERROR,
  UPDATE_VENDOR_DC_STATE,
  UPDATE_VENDOR_DC_ZIP_ERROR,
  UPDATE_VENDOR_DC_ZIP,
  UPDATE_VENDOR_DC_CODE_ERROR,
  UPDATE_VENDOR_DC_CODE,
  SHOW_VENDOR_DC_FORM_SPINNER,
  HIDE_VENDOR_DC_FORM_SPINNER,
  VendorDCCreateSuccess,
  VendorDCCreateError,
} from '../../../constants';
import { createVendorDC, getVendorDistributionCenterList } from '../../../api/vendors';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeZipcodeFormat, isValidZipcode } from '../../../utils';

import { showModal, closeModal } from '../../modal';

export const showDCModal = () => async (dispatch: any) => {
  dispatch(showModal());
};

export const closeDCModal = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_DC_MODAL,
  });
  dispatch({
    type: UPDATE_DC_IS_EDIT,
    isEdit: false,
  });
  dispatch(closeModal());
};

export const onChangeDCName = (value: any) => async (dispatch: any) => {
  if (value !== null) {
    dispatch({
      type: UPDATE_VENDOR_DC_NAME_ERROR,
      isDcNameError: false,
    });
  }
  dispatch({
    type: UPDATE_VENDOR_DC_NAME,
    dcName: value,
  });
  dispatch({
    type: UPDATE_DC_FORM_INPUTS,
    isFormUpdated: true,
  });
};

export const onChangeStreet = (value: any) => async (dispatch: any) => {
  if (value !== null) {
    dispatch({
      type: UPDATE_VENDOR_DC_STREET_ERROR,
      isDcStreetError: false,
    });
  }
  dispatch({
    type: UPDATE_VENDOR_DC_STREET,
    dcStreet: value,
  });
  dispatch({
    type: UPDATE_DC_FORM_INPUTS,
    isFormUpdated: true,
  });
};

export const onChangeCity = (value: any) => async (dispatch: any) => {
  if (value !== null) {
    dispatch({
      type: UPDATE_VENDOR_DC_CITY_ERROR,
      isDcCityError: false,
    });
  }
  dispatch({
    type: UPDATE_VENDOR_DC_CITY,
    dcCity: value,
  });
  dispatch({
    type: UPDATE_DC_FORM_INPUTS,
    isFormUpdated: true,
  });
};

export const onChangeState = (value: any) => async (dispatch: any) => {
  if (value !== 'NA' || value !== null) {
    dispatch({
      type: UPDATE_VENDOR_DC_STATE_ERROR,
      isDcStateError: false,
    });
  }
  dispatch({
    type: UPDATE_VENDOR_DC_STATE,
    dcState: value,
  });
  dispatch({
    type: UPDATE_DC_FORM_INPUTS,
    isFormUpdated: true,
  });
};

export const onChangeZip = (value: any) => async (dispatch: any, getState: any) => {
  const numberOnly = new RegExp(/[0-9]+$/);
  let { vendorDCModal } = getState();
  let { dcZip, isFormUpdated } = vendorDCModal;

  value = removeZipcodeFormat(value);

  if (!isValidZipcode(value)) {
    dispatch({
      type: UPDATE_VENDOR_DC_ZIP_ERROR,
      isDcZipError: true,
    });
  } else {
    dispatch({
      type: UPDATE_VENDOR_DC_ZIP_ERROR,
      isDcZipError: false,
    });
  }
  if (numberOnly.test(value) == true || value == '') {
    dispatch({
      type: UPDATE_VENDOR_DC_ZIP,
      dcZip: value,
    });
  } else {
    dispatch({
      type: UPDATE_VENDOR_DC_ZIP,
      dcZip: dcZip ? dcZip : '',
    });
  }
  if (!isFormUpdated) {
    dispatch({
      type: UPDATE_DC_FORM_INPUTS,
      isFormUpdated: true,
    });
  }
};
export const onChangeCode = (value: any) => async (dispatch: any) => {
  if (value === '' || value === null) {
  } else {
    dispatch({
      type: UPDATE_VENDOR_DC_CODE_ERROR,
      isDcCodeError: false,
    });
    dispatch({
      type: UPDATE_DC_FORM_INPUTS,
      isFormUpdated: true,
    });
  }
  dispatch({
    type: UPDATE_VENDOR_DC_CODE,
    dcCode: value,
  });
};
export const onAddDC = () => async (dispatch: any, getState: any) => {
  const { vendorDCModal, vendor } = getState();
  const { vendorID } = vendor;
  const { dcName, dcStreet, dcCity, dcState, dcZip, dcCode } = vendorDCModal;

  const isValidName = isValidText(dcName);
  const isValidStreet = isValidText(dcStreet);
  const isValidCity = isValidText(dcCity);
  const isValidState = validateSelectionType(dcState);
  const isValidZip = isValidZipcode(dcZip);

  const isError =
    !isValidName ||
    !isValidStreet ||
    !isValidCity ||
    !isValidState ||
    !isValidZip;

  if (!isValidName) {
    dispatch({
      type: UPDATE_VENDOR_DC_NAME_ERROR,
      isDcNameError: true,
    });
  }
  if (!isValidStreet) {
    dispatch({
      type: UPDATE_VENDOR_DC_STREET_ERROR,
      isDcStreetError: true,
    });
  }
  if (!isValidCity) {
    dispatch({
      type: UPDATE_VENDOR_DC_CITY_ERROR,
      isDcCityError: true,
    });
  }
  if (!isValidState) {
    dispatch({
      type: UPDATE_VENDOR_DC_STATE_ERROR,
      isDcStateError: true,
    });
  }
  if (!isValidZip) {
    dispatch({
      type: UPDATE_VENDOR_DC_ZIP_ERROR,
      isDcZipError: true,
    });
  }
  try {
    if (!isError) {
      dispatch({
        type: SHOW_VENDOR_DC_FORM_SPINNER,
        spinner: true,
      });
      const response = await createVendorDC({
        vendorID,
        dcName,
        dcStreet,
        dcCity,
        dcState,
        dcZip,
        dcCode,
      });
      const { success } = response;
      success ? toast.success(VendorDCCreateSuccess) : null;
      if (success) {
        const vendorDCList = await getVendorDistributionCenterList(vendorID);
        dispatch({
          type: UPDATE_VENDOR_DC_LIST,
          vendorDCList: vendorDCList.data,
        });
      }
      dispatch({
        type: HIDE_VENDOR_DC_FORM_SPINNER,
        spinner: false,
      });
      if (success) {
        dispatch({
          type: UPDATE_DC_FORM_INPUTS,
          isFormUpdated: false,
        });
        dispatch({
          type: CLEAR_DC_MODAL,
        });
      }
      dispatch(closeModal());
    }
  } catch (error) {
    dispatch({
      type: HIDE_VENDOR_DC_FORM_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: UPDATE_DC_FORM_INPUTS,
        isFormUpdated: false,
      });
      dispatch({
        type: CLEAR_DC_MODAL,
      });
    }
    if (error) {
      toast.error(VendorDCCreateError);
    }
  }
};

const isValidText = (value: any) => {
  return value && value.trim().length > 0 ? true : false;
};
const validateSelectionType = (selection: any) => {
  if (selection == '0' || selection == null || selection == '') return false;
  else {
    return true;
  }
};
