import { FC, useEffect, useState } from 'react';
import { Loader } from '../../../../utils';
import '../../dashboard.scss';
import DashboardCollapsibleSectionHeader from '../DashboardCollapsibleSectionHeader';
import { useCloseableDisplay } from '../../../../reactHooks';
import { BRDropdown } from '../../../../components/BRDropdown';
import { Grid } from '@mui/material';
import { useMachine, useActor } from '@xstate/react';
import { Member } from '../../../../common';
import { RebatesPieChart, RebatesTotalsOverview } from '../../../../components';
import dashboardEarnedRebatesMachine from './machines/dashboardEarnedRebatesMachine/dashboardEarnedRebatesMachine';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

export const DashboardEarnedRebates: FC<{
  updateActiveEarningsRoute: (navigate: NavigateFunction) => void;
}> = ({ updateActiveEarningsRoute }) => {
  const navigate = useNavigate();
  const [opened, { toggle }] = useCloseableDisplay();
  const [state] = useMachine(dashboardEarnedRebatesMachine);
  const [memberState, memberSend] = useActor(state.children.members);
  const { acquiredAmount, availableAmount, pendingAmount } = state.context;
  
  const [loading,setLoading] = useState<boolean>(false)
  const dispatch = useDispatch()
  const store = useSelector((state) => state)
  const {dashboardEarnedRebates}:any = store

  useEffect(() => {
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);
  }, [dashboardEarnedRebates]);
  
  useEffect(() => {
    dispatch({
      type : 'EARNED_REBATES_MACHINE',
      payload :{
        selectedMemberId: memberState.context.selectedMember?.id,
        acquiredAmount: acquiredAmount,
        availableAmount: availableAmount,
        pendingAmount: pendingAmount,
      }
    })
  },[state])

  return (
    <div className="dashboard-section">
      <DashboardCollapsibleSectionHeader
        title="Earned Rebates"
        toggle={toggle}
        opened={opened}
      />
      {opened && (
        <Grid
          container
          justifyContent="flex-start"
          sx={{
            mt: 2,
            mb: 2,
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            gap: 1,
          }}
        >
          <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
            <BRDropdown
              selectedOption={dashboardEarnedRebates.selectedMemberId || 0}
              disabled={memberState.context.members.length <= 1}
              menuList={memberState.context.members.map((member: Member) => ({
                value: member.id,
                label: member.name,
              }))}
              isMemberList = {true}
              handleSelected={(item: any) => {
                const memberId = item?.value;
                if (memberId) {
                  memberSend({
                    memberId,
                    type: 'updateSelectedMember',
                  });
                }
              }}
              spinner={memberState.hasTag('loading')}
            />
          </Grid>
        </Grid>
      )}
      <div
        className={
          opened ? 'table-wrapper' : 'table-wrapper table-wrapper-hide'
        }
      >
        {state.hasTag('loading') && (
          <div className="kpi-details-nodata">
            <Loader size={20} />
          </div>
        )}
        {state.matches('idle') &&
          ([
            dashboardEarnedRebates.acquiredAmount === 0,
            dashboardEarnedRebates.pendingAmount === 0,
            dashboardEarnedRebates.availableAmount === 0,
          ].every((value) => value) ? (
            <h4>No rebate data found for the selected member.</h4>
          ) : (
            <Grid container spacing={3} className="rebates-overview">
              <RebatesPieChart
                legend="Total Member Rebates"
                claimedAmount={dashboardEarnedRebates.acquiredAmount}
                pendingAmount={dashboardEarnedRebates.pendingAmount}
                unclaimedAmount={dashboardEarnedRebates.availableAmount}
              />
              <RebatesTotalsOverview
                grandTotalLabel="Total Member Rebates"
                claimedAmount={dashboardEarnedRebates.acquiredAmount}
                pendingAmount={dashboardEarnedRebates.pendingAmount}
                unclaimedAmount={dashboardEarnedRebates.availableAmount}
              />
            </Grid>
          ))}
        {!state.hasTag('loading') && (
          <div
            className="dashboard-link"
            onClick={() => updateActiveEarningsRoute(navigate)}
          >
            <span>View Detailed Earned Rebates</span>
            <i className="fas fa-chevron-right" />
          </div>
        )}
      </div>
    </div>
  );
};

export default DashboardEarnedRebates;
