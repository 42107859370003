import React, {FC, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import {SearchNotFound} from "../../../../../../components/SearchNotFound";
import {useStyles} from "../../../../../../utils";
import FormControlLabel from "@material-ui/core/FormControlLabel";

import Radio from "@material-ui/core/Radio";
import sortDefault from '../../../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../../../images/sortDesc.svg';
import DefaultUserImg from '../../../../../../images/profile-placeholder.jpg';
import {formatPhoneNumber} from '../../../../../../utils';

export const AssignMemberTable: FC<any> = ({
    gridHeader,
    gridData,
    setSortKey,
    sortType,
    onSelecteAssignMember,
    selectedMemberId,
    isEditModal
}) => {
    const classes = useStyles();

    const [activeHeader, setActiveHeader] = useState(null);

    const handleClick = (event: any, sortKey: any) => {
        const selectedHeader = event.target.innerHTML;
        setActiveHeader(selectedHeader);
        setSortKey(sortKey);
    };

    const baseUrl = process.env.REACT_APP_API_URL;
    
    return (
        <div className={classes.root}>
            {gridData.length > 0 && (
                <Grid container spacing={0} className="tr thead">
                    {gridHeader.map((item: any) => {
                        const {label, value, xs, lg, sortKey} = item;
                        const isValidHeader = label !== "";
                        return (
                            <Grid className="th" item xs={xs} lg={lg}>
                                <div>
                                    <span
                                        className="grid-header"
                                        onClick={(event) => handleClick(event, sortKey)}
                                    >
                                        {label}
                                    </span>
                                    <span className="sort-icon">
                                        {
                                            isValidHeader && (
                                                sortType === '' ?
                                                    <img className="sort-svg" src={sortDefault} /> : (label === activeHeader && sortType === 'ASC') ?
                                                        <img className="sort-svg" src={sortAscIcon} /> : (label === activeHeader && sortType === 'DESC') ?
                                                            <img className="sort-svg" src={sortDescIcon} /> : <img className="sort-svg" src={sortDefault} />
                                            )
                                        }
                                    </span>
                                </div>
                            </Grid>
                        );
                    })}
                </Grid>
            )}

            {gridData.length > 0 ? (
                gridData.map((item: any) => {
                    const {id, avatar, name, phone, mobile, street, city, state, zip} = item;
                    const isChecked = selectedMemberId === id || isEditModal;
                    return (
                        <Grid container spacing={0} className={isChecked ? "tr active-table-row" : "tr"}>
                            <Grid
                                className="td xs-left-align xs-primary"
                                item
                                xs={12}
                                lg={1}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <FormControlLabel
                                    value="end"
                                    control={
                                        <Radio
                                            color="primary"
                                            checked={isChecked}
                                            onChange={() => onSelecteAssignMember(id)}
                                        />
                                    }
                                    disabled={isEditModal}
                                    labelPlacement="end"
                                    label=''
                                />
                            </Grid>
                            <Grid
                                className="td xs-left-align xs-primary"
                                item
                                xs={12}
                                lg={4}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                <div className="table-member-details alignResponsive">
                                    <div className="member-img">
                                        <img
                                            src={
                                                avatar !== null
                                                    ? `/${avatar}`
                                                    : DefaultUserImg
                                            }
                                        />
                                    </div>
                                    <div className="member-profile-details">
                                        <div className="member-name">
                                            {name}
                                        </div>
                                        <div className="member-mail-id">
                                            {phone ? formatPhoneNumber(phone) : ''}
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                            <Grid
                                className="td xs-left-align xs-primary alignResponsive"
                                item
                                xs={12}
                                lg={3}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                {mobile && mobile !== 'null' ? mobile : '-'}
                            </Grid>
                            <Grid
                                className="td xs-left-align xs-primary alignResponsive"
                                item
                                xs={12}
                                lg={4}
                                container
                                direction="row"
                                alignItems="center"
                            >
                                {`${street}, ${city}, ${state} ${zip}`}
                            </Grid>
                        </Grid>
                    );
                })
            ) : (
                <SearchNotFound sectionType={"Member"} />
            )}
        </div>
    );
};

AssignMemberTable.propTypes = {
    gridHeader: PropTypes.array,
    gridData: PropTypes.array,
    isEdit: PropTypes.bool,
    isDelete: PropTypes.bool,
    handleDelete: PropTypes.func,
    editVendorLink: PropTypes.func,
    setSortKey: PropTypes.func,
    sortType: PropTypes.string,
};
