import { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DialogWindow from '../../../../../../components/DialogWindow';
import { formatPhoneNumber } from '../../../../../../utils';

const dialogAddConfiguration = {
  title: 'Create User',
  isCancel: true,
  submitText: 'Create & Invite User',
  isBack: true,
  backText: 'Back to Assign User',
};

const dialogEditConfiguration = {
  title: 'Edit User',
  isCancel: true,
  submitText: 'Update User',
};

// function start
export const AddMemberUser: FC<any> = ({
  getUserRoleList,
  moduletype,
  handleBacktoPrimary,
  assignedUsers,
  userRoles,
  memberUserForm: {
    isLoading,
    isEdit,
    firstName,
    lastName,
    email,
    role,
    errorFirstname,
    errorLastname,
    errorEmail,
    errorRole,
  },
  clearAddMemberUser,
  hideMemberUserModal,
  onChangeAddMemberUserFname,
  onChangeAddMemberUserLname,
  onChangeAddMemberUserMail,
  onChangeAddMemberUserRole,
  onAddMemberUser,
  onEditMemberUser,
}) => {
  // declaration
  const [open] = useState(true);
  const [phone, setPhone] = useState('');
  const [errorPhone, setErrorPhone] = useState(false);
  // passed data
  const isAddMode = isEdit ? false : true;
  const typeid = moduletype;

  useEffect(() => {
    if (userRoles.length == 0) {
      getUserRoleList();
    }
  }, []);

  const onPhoneNumberFormat = (event: any) => {
    if (event.target.value !== '') {
      setErrorPhone(false);
      setPhone(formatPhoneNumber(event.target.value));
    } else {
      setErrorPhone(true);
      setPhone('');
    }
  };

  const isAssignUserMemberAdmin = assignedUsers.filter(
    (item: any) => item.roleid === 3,
  );

  const roleOptions =
    isAssignUserMemberAdmin.length > 0
      ? userRoles.filter((item: any) => item.id !== 3)
      : userRoles;

  return (
    <DialogWindow
      openDialog={open}
      dialogConfiguration={
        !isAddMode ? dialogEditConfiguration : dialogAddConfiguration
      }
      handleBackToList={handleBacktoPrimary}
      handleClose={() => {
        clearAddMemberUser();
        hideMemberUserModal();
      }}
      handleSubmit={!isAddMode ? onEditMemberUser : onAddMemberUser}
      isButtonDisabled={isLoading}
      spinnerEnabled={isLoading}
    >
      <div className="br-form-element">
        <Grid container spacing={6}>
          <Grid item xs>
            <TextField
              error={errorFirstname}
              id={errorFirstname ? 'standard-error' : 'outlined-basic'}
              label="First name"
              variant="outlined"
              className="text-box"
              helperText={errorFirstname ? 'Please enter firstname' : ''}
              onChange={(e) => onChangeAddMemberUserFname(e.target.value)}
              disabled={!isAddMode || isLoading}
              required
              value={firstName}
            />
          </Grid>
          <Grid item xs>
            <TextField
              error={errorLastname}
              id={errorLastname ? 'standard-error' : 'outlined-basic'}
              label="Last name"
              variant="outlined"
              className="text-box"
              helperText={errorLastname ? 'Please enter lastname' : ''}
              onChange={(e) => onChangeAddMemberUserLname(e.target.value)}
              disabled={!isAddMode || isLoading}
              required
              value={lastName}
            />
          </Grid>
        </Grid>

        <Grid container spacing={6}>
          <Grid item xs>
            <TextField
              error={errorEmail}
              id={errorEmail ? 'standard-error' : 'outlined-basic'}
              label="Email"
              variant="outlined"
              className="text-box"
              helperText={errorEmail ? 'Please enter email' : ''}
              onChange={(e) => onChangeAddMemberUserMail(e.target.value)}
              disabled={!isAddMode || isLoading}
              required
              value={email}
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl variant="outlined" className="select-box">
              <InputLabel htmlFor="outlined-age-native-simple">Role</InputLabel>
              <Select
                value={role}
                onChange={(e) => onChangeAddMemberUserRole(e.target.value)}
                label="Role"
                inputProps={{
                  name: 'role',
                  id: errorRole
                    ? 'standard-error'
                    : 'outlined-age-native-simple',
                  helperText: errorRole ? 'Please enter email' : '',
                }}
                disabled={assignedUsers?.length == 0 || isLoading}
                required
              >
                {roleOptions.map((item: any) => {
                  return <option value={item.id}>{item.name}</option>;
                })}
              </Select>
              <FormHelperText error={errorRole}>
                {errorRole ? 'Please select user role' : ''}
              </FormHelperText>
            </FormControl>
          </Grid>
        </Grid>
        {typeid == 1 ? (
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <TextField
                error={errorPhone}
                id={errorPhone ? 'standard-error' : 'outlined-basic'}
                label="Phone"
                variant="outlined"
                className="text-box"
                value={phone}
                type="phone"
                helperText={errorPhone ? 'Please enter phone' : ''}
                onChange={(e) => onPhoneNumberFormat(e)}
                required
                disabled={isLoading}
              />
            </Grid>
          </Grid>
        ) : null}
      </div>
    </DialogWindow>
  );
};
