import {
  UPDATE_SELECTED_REBATE_RECEIPT,
  UPDATE_REBATE_RECEIPT_ACTION_MODAL,
  UPDATE_REBATE_RECEIPT_MODE,
  CLEAR_REBATE_RECEIPT_ACTION_MODAL,
} from '../../../constants';

export const updateRebateAction = (selectedRebate: { name: string; }) => async (dispatch: any) => {
  const { name } = selectedRebate;
  const mode = name === 'Check' ? 0 : 1;
  dispatch({
    type: UPDATE_SELECTED_REBATE_RECEIPT,
    selectedRebate,
  });
  dispatch({
    type: UPDATE_REBATE_RECEIPT_MODE,
    rebateReceiptMode: mode,
  });
  dispatch(updateRebateActionModal(true));
};

export const updateRebateActionModal =
  (isRebateReceiptAction?: boolean) => async (dispatch: any) => {
    if (isRebateReceiptAction) {
      dispatch({
        type: UPDATE_REBATE_RECEIPT_ACTION_MODAL,
        isRebateReceiptAction,
      });
    } else {
      dispatch({
        type: UPDATE_REBATE_RECEIPT_ACTION_MODAL,
        isRebateReceiptAction,
      });
      dispatch(clearRebateActionModal());
    }
  };

export const handleRebateReceiptMode =
  (rebateReceiptMode: string) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_REBATE_RECEIPT_MODE,
      rebateReceiptMode: parseInt(rebateReceiptMode),
    });
  };

export const clearRebateActionModal = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_REBATE_RECEIPT_ACTION_MODAL,
  });
};
