import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { ClaimRebatesModal } from './ClaimRebatesModal';
import { CustomModal } from '../../../../../components/CustomModal';
import { useMachine } from '@xstate/react';
import { claimRebatesMachine } from './machines';
import { useStore } from 'react-redux';

export const ClaimRebates: FC<any | { refresh: () => void }> = ({ refresh }) => {
  const store = useStore<{
    rebateReceipts: {
      rebatesAmount: {
        availableAmount: string;
      };
      selectedMember: {
        label: string;
        value: number;
      };
    };
    user: {
      id: number;
      isBRAdmin: boolean;
    };
  }>();
  const storeState = store.getState();
  const [state, send, actor] = useMachine(claimRebatesMachine, {
    actions: {
      refresh: () => refresh(),
    },
    context: {
      amount:
        Number(storeState.rebateReceipts.rebatesAmount.availableAmount),
      memberId: storeState.rebateReceipts.selectedMember.value,
      memberName: storeState.rebateReceipts.selectedMember.label,
      userId: storeState.user.id,
      userType: storeState.user.isBRAdmin ? 'buyRightAdmin' : null,
      electronicTransferRequestsEnabled: true,
    },
  });

  const renderButton = [
    !state.hasTag('loading'),
    !state.hasTag('submitting'),
    (state.context.amount ?? 0) > 0,
    state.context.userType === 'buyRightAdmin' ||
      state.context.userType === 'memberAdmin',
  ].every((value) => value);

  return (
    <div>
      {renderButton && (
        <Button
          onClick={() => send('open')}
          variant="contained"
          size="small"
          className='btn-add'
          disabled={state.hasTag('loading') || state.hasTag('submitting') || (state?.context?.amount ?? 0) <= 0}
        >
          <span className="fas fa-plus-circle" />
          <span>Claim Rebates</span>
        </Button>
      )}
      <CustomModal
        showModal={state.matches('opened')}
        closeModal={() => send('close')}
        modalTitle={`Request Rebate - ${state.context.memberName}`}
        isCancelButton
        isSubmitButton
        isSubmitText="Submit"
        onSubmit={() => send('submit')}
        isLoading={state.hasTag('loading')}
        isButtonDisabled={state.hasTag('loading')}
      >
        <ClaimRebatesModal actor={actor} />
      </CustomModal>
    </div>
  );
};

export default ClaimRebates;
