import { Grid, GridSize } from '@material-ui/core';
import { FC } from 'react';

export const EBrandTableHeaderRow: FC<{
  label: string;
  subLabel: string;
  xs: GridSize;
  lg: GridSize;
}> = ({
  label,
  subLabel,
  xs,
  lg,
}) => (
  <Grid className="th" item xs={xs} lg={lg}>
    <div className="brand-header">
      <div className="grid-header">{label}</div>
      {subLabel ? <div className="grid-header">{subLabel}</div> : null}
    </div>
  </Grid>
);

export default EBrandTableHeaderRow;
