import { GET_USERS_API, UPDATE_MEMBER_USER_LIST_ERROR } from "../../constants";

export const getMemberUserList = async (
    id, type
) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_USERS_API}?memberid=${id}&type=${type}`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
              }), 
        }
        );
        if(!response.ok) throw UPDATE_MEMBER_USER_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_MEMBER_USER_LIST_ERROR;
    } 
}
