import {
  UPLOAD_PRODUCT_REBATES,
  GET_PRODUCT_REBATES_INFO_ERROR,
} from '../../../constants';

export const getProductRebatesInfo = async ({ quarter, year }) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch(
      `${UPLOAD_PRODUCT_REBATES}/info?quarter=${quarter}&year=${year}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw GET_PRODUCT_REBATES_INFO_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw GET_PRODUCT_REBATES_INFO_ERROR;
  }
};
