import moment from 'moment';
import {
  UPDATE_CLAIM_REBATES_MODAL,
  UPDATE_PRODUCT_REBATES_DATE_RANGE,
  UPDATE_CLAIM_REBATES_EXPORT_MODAL,
  SHOW_REBATE_LIST_SPINNER,
  SHOW_REBATE_RECEIPT_MEMBER_SPINNER,
  SHOW_REBATE_RECEIPT_MODAL_SPINNER,
  UPDATE_REBATE_RECEIPT_LIST,
  UPDATE_REBATE_RECEIPT_MEMBER_LIST,
  UPDATE_AVAILABLE_REBATES,
  UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER,
  SHOW_REBATE_RECEIPT_EXPORT_SPINNER,
  UPDATE_CONFIRM_MODAL,
  UPDATE_MEMBERS_LIST_ERROR,
  GET_REBATE_MEMBERS_LIST_ERROR,
  EXPORT_REBATE_RECEIPT_ERROR,
  REBATE_RECEIPT_NO_RECORD_ERROR,
  rebateReceiptExportError,
  AvailableRebatesError,
  MembersListError,
  RebatesListError,
  exportRebateReceiptError,
  FetchError,
} from '../../../constants';
import { handleClaimRebatesCheck } from './claimRebates';
import { getMembers } from '../../../api/members';
import {
  getRebateReceiptsList,
  getAvailableRebates, 
  exportRebateReceipt,
} from '../../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DateTime } from 'luxon';
import { getPendingTotals } from '../../../api/rebates/rebateReceipts/getPendingTotals';

export const rebateReceiptMemberSpinner =
  (memberListSpinner: boolean) => async (dispatch: any) => {
    dispatch({
      type: SHOW_REBATE_RECEIPT_MEMBER_SPINNER,
      memberListSpinner,
    });
  };

export const rebateListSpinner = (rebateListSpinner: boolean) => async (dispatch: any) => {
  dispatch({
    type: SHOW_REBATE_LIST_SPINNER,
    rebateListSpinner,
  });
};

export const rebateReceiptModalSpinner =
  (rebateReceiptModalSpinner: boolean) => async (dispatch: any) => {
    dispatch({
      type: SHOW_REBATE_RECEIPT_MODAL_SPINNER,
      rebateReceiptModalSpinner,
    });
  };

export const rebateReceiptExportSpinner =
  (rebateReceiptExportSpinner: boolean) => async (dispatch: any) => {
    dispatch({
      type: SHOW_REBATE_RECEIPT_EXPORT_SPINNER,
      rebateReceiptExportSpinner,
    });
  };

export const showClaimRebatesModal =
  (isClaimRebatesModal: boolean) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_CLAIM_REBATES_MODAL,
      isClaimRebatesModal,
    });
    if (!isClaimRebatesModal) {
      dispatch(handleClaimRebatesCheck(false));
    }
  };

export const updateAvailableRebates = (memberId: number) => async (dispatch: any) => {
  dispatch(rebateReceiptModalSpinner(true));
  try {
    const res = memberId > 0 ? await getAvailableRebates(memberId) : await getPendingTotals();
    const { success, data } = res?.data ?? {};
    if (success) {
      dispatch({
        type: UPDATE_AVAILABLE_REBATES,
        rebatesAmount: data,
      });
    } else {
      dispatch({
        type: UPDATE_AVAILABLE_REBATES,
        rebatesAmount: null,
      });
    }
    dispatch(rebateReceiptModalSpinner(false));
  } catch (error) {
    dispatch(rebateReceiptModalSpinner(false));
    if (error === GET_REBATE_MEMBERS_LIST_ERROR) {
      toast.error(AvailableRebatesError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const showClaimRebateExportModal =
  (isClaimRebatesExportModal: boolean) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_CLAIM_REBATES_EXPORT_MODAL,
      isClaimRebatesExportModal,
    });
    dispatch({
      type: UPDATE_PRODUCT_REBATES_DATE_RANGE,
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      ],
    });
  };

export const updateExportDateRange = (dateRange: any) => async (dispatch: any) => {
  dispatch({
    type: UPDATE_PRODUCT_REBATES_DATE_RANGE,
    dateRange,
  });
};

export const getRebatesMemberList = () => async (dispatch: any, getState: any) => {
  const { rebateReceipts } = getState();
  const { isConfirmModalUpdate } = rebateReceipts;
  dispatch(rebateReceiptMemberSpinner(true));
  try {
    if (!isConfirmModalUpdate) {
      const res = await getMembers();
      const data = res.data.result ? res.data.result : [];
      const formattedOptions =
        data?.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        }) ?? [];
      const memberOptions = [
        // {
        //   label: 'Pending Rebate Requests',
        //   value: 0,
        // },
        ...formattedOptions,
      ];
      dispatch({
        type: UPDATE_REBATE_RECEIPT_MEMBER_LIST,
        memberList: memberOptions,
      });
      dispatch({
        type: UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER,
        selectedMember: memberOptions[0],
      });
    }
    const { rebateReceipts } = getState();
    const { selectedMember } = rebateReceipts;
    dispatch(getRebateReceiptList(selectedMember));
    dispatch({
      type: UPDATE_CONFIRM_MODAL,
      isConfirmModalUpdate: false,
    });
    dispatch(rebateReceiptMemberSpinner(false));
  } catch (error) {
    dispatch(rebateReceiptMemberSpinner(false));
    if (error === UPDATE_MEMBERS_LIST_ERROR) {
      toast.error(MembersListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getRebateReceiptList = (selectedMember: any) => async (dispatch: any) => {
  dispatch(rebateListSpinner(true));
  const memberId = selectedMember ? selectedMember.value : null;
  try {
    const res = await getRebateReceiptsList(memberId);

    console.log({ res });
    
    if (res.statusText === 'OK') {
      const formattedData =
        res.data.map((rebateReceiptItem: { createdat: Date }) => {
          const { createdat } = rebateReceiptItem;
          DateTime.now().minus({ month: 6 }).toFormat('yyyy-MM-dd')
          const formattedCreatedAtDate = createdat
            ? moment(createdat)
                .format('MM/DD/YYYY')
                .toString()
            : '';
          return {
            ...rebateReceiptItem,
            createdat: formattedCreatedAtDate,
          };
        });
      dispatch({
        type: UPDATE_REBATE_RECEIPT_LIST,
        rebatesList: formattedData,
      });
    } else {
      dispatch({
        type: UPDATE_REBATE_RECEIPT_LIST,
        rebatesList: [],
      });
    }
    dispatch(rebateListSpinner(false));
    dispatch(updateAvailableRebates(memberId));
  } catch (error) {
    dispatch(rebateListSpinner(false));
    if (error === GET_REBATE_MEMBERS_LIST_ERROR) {
      toast.error(RebatesListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getRebateReceiptDetails = () => async (dispatch: any) => {
  dispatch(getRebatesMemberList());
};

export const handleRebateReceiptMemberChange =
  (selectedMember: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER,
      selectedMember,
    });
    dispatch(getRebateReceiptList(selectedMember));
  };

export const onExportRebateReceipts = () => async (dispatch: any, getState: any) => {
  const { rebateReceipts } = getState();
  const { selectedMember, exportYear, exportQuarter } = rebateReceipts;
  const memberId = selectedMember ? selectedMember.value : null;
  dispatch(rebateReceiptExportSpinner(true));
  try {
    const response = await exportRebateReceipt({
      memberId,
      exportYear,
      exportQuarter,
    });
    const date = new Date();
    const fileName = `Rebate_Receipt_${moment(date).format(
      'DD_MMM_YYYY_hh:mm:ss_A',
    )}.xls`;
    dispatch(rebateReceiptExportSpinner(false));
  } catch (error) {
    dispatch(rebateReceiptExportSpinner(false));
    if (error === REBATE_RECEIPT_NO_RECORD_ERROR) {
      toast.error(rebateReceiptExportError);
    } else if (error === EXPORT_REBATE_RECEIPT_ERROR) {
      toast.error(exportRebateReceiptError);
    }
  }
};
