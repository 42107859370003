import {UPDATE_PRODUCT_REBATE_NOTES_API, UPDATE_PRODUCT_REBATES_NOTES_ERROR} from '../../../constants';

export const updateProductRebatesNotesAPI = async ({
    rebateId,
    notes
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${UPDATE_PRODUCT_REBATE_NOTES_API}/${rebateId}`, {
            method: 'PATCH',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                notes
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_PRODUCT_REBATES_NOTES_ERROR;
    }
};