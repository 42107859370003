import React, {Fragment} from "react";
import {FileOneUpdate, FileTwoUpdate, FileThreeUpdate} from "./components";

export const EditMemberRebateDocuments = (props) => {
  const {memberRebates} = props;
  const {quarter, year} = memberRebates;

  return (
    <Fragment>
      <div className="edit-member-rebates-doc-title">
        <span> {quarter} - {year} </span> Member Rebates
      </div>

      <div className="edit-rebate-documents">
        <FileOneUpdate
          {...props}
        />

        <FileTwoUpdate
          {...props}
        />

        <FileThreeUpdate
          {...props}
        />
      </div >

    </Fragment>
  );
};

