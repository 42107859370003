import { FC, useState } from 'react';
import { MemberDirectoryTitle } from './MemberDirectoryTitle';
import { MemberDirectoryFilters } from './MemberDirectoryFilters';
import { CommunityMemberList } from './Members/CommunityMemberList';
import { Loader } from '../../../utils';
import '../../members/members.scss';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../machines';
import serverApiV2Instance from '../../../services/serverApiV2Instance';

export const MemberDirectory: FC<any> = () => {

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: '',
        sortOrder: 'desc',
      },
      pageSize: 10,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/members-list?${query.toString()}`,
        );
      },
    },
  });


  return (
    <div>
      <MemberDirectoryTitle />

      <div className="container">
        <MemberDirectoryFilters
          send={send}
        />
        <div>
          <CommunityMemberList
            membersList={state.context.records}
            state={state}
            send={send}
          />
        </div>
      </div>
    </div>
  );
};
