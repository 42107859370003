import { FC } from 'react';
import { DwollaFundingSource, DwollaTransfer } from '../../../common';
import { centsToFormattedDollars } from '../../../utils';

export const Amount: FC<{
  amount: number;
  fundingSource?: DwollaFundingSource;
  transferFee?: number;
  transferInfo?: DwollaTransfer;
}> = ({ amount, fundingSource, transferFee = 0, transferInfo }) => {
  let amountValue = amount ? centsToFormattedDollars(amount) : '-';
  if (transferInfo) {
    amountValue = transferInfo.amount.value ? centsToFormattedDollars((Number(transferInfo.amount.value) * 100)) : '-';
  } else if (fundingSource) {
    amountValue = amount - transferFee > 0 ? centsToFormattedDollars(amount - transferFee) : '-';
  }

  return <>{amountValue}</>
};

export default Amount;
