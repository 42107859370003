import {GET_VENDORS_DC_API, UPDATE_DC_MENU_LIST_ERROR} from "../../constants";


export const getDocuments = async (vendorID) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDORS_DC_API}${vendorID}/distributioncenters`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw UPDATE_DC_MENU_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DC_MENU_LIST_ERROR;
    }
}

