import {
    CLEAR_DC_MODAL,
    UPDATE_DC_IS_EDIT,
    UPDATE_VENDOR_DC_NAME_ERROR,
    UPDATE_VENDOR_DC_NAME,
    UPDATE_DC_FORM_INPUTS,
    SHOW_VENDOR_DC_FORM_SPINNER,
    HIDE_VENDOR_DC_FORM_SPINNER,
    UPDATE_VENDOR_DC_STREET_ERROR,
    UPDATE_VENDOR_DC_STREET,
    UPDATE_VENDOR_DC_CITY_ERROR,
    UPDATE_VENDOR_DC_CITY,
    UPDATE_VENDOR_DC_STATE_ERROR,
    UPDATE_VENDOR_DC_STATE,
    UPDATE_VENDOR_DC_ZIP_ERROR,
    UPDATE_VENDOR_DC_ZIP,
    UPDATE_VENDOR_DC_CODE_ERROR,
    UPDATE_VENDOR_DC_CODE,
    UPDATE_VENDOR_DC_ID
} from '../../constants';


const initialState = {
    spinner: false,
    id: null,
    dcName: null,
    dcStreet: null,
    dcCity: null,
    dcState: null,
    dcZip: null,
    dcCode: null,
    isEdit: false,
    isDcNameError: false,
    isDcStreetError: false,
    isDcCityError: false,
    isDcStateError: false,
    isDcZipError: false,
    isDcCodeError: false,
    isFormUpdated: false,
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_VENDOR_DC_FORM_SPINNER:
            return ({
                ...state,
                spinner: action.spinner,
            });
        case HIDE_VENDOR_DC_FORM_SPINNER:
            return ({
                ...state,
                spinner: action.spinner,
            });
        case UPDATE_DC_IS_EDIT:
            return ({
                ...state,
                isEdit: action.isEdit
            });
        case UPDATE_VENDOR_DC_NAME_ERROR:
            return ({
                ...state,
                isDcNameError: action.isDcNameError
            });
        case UPDATE_VENDOR_DC_ID:
            return ({
                ...state,
                id: action.id
            });
        case UPDATE_VENDOR_DC_NAME:
            return ({
                ...state,
                dcName: action.dcName
            });
        case UPDATE_DC_FORM_INPUTS:
            return ({
                ...state,
                isFormUpdated: action.isFormUpdated
            });
        case UPDATE_VENDOR_DC_STREET_ERROR:
            return ({
                ...state,
                isDcStreetError: action.isDcStreetError
            });
        case UPDATE_VENDOR_DC_STREET:
            return ({
                ...state,
                dcStreet: action.dcStreet
            });
        case UPDATE_VENDOR_DC_CITY_ERROR:
            return ({
                ...state,
                isDcCityError: action.isDcCityError
            });
        case UPDATE_VENDOR_DC_CITY:
            return ({
                ...state,
                dcCity: action.dcCity
            });
        case UPDATE_VENDOR_DC_STATE_ERROR:
            return ({
                ...state,
                isDcStateError: action.isDcStateError
            });
        case UPDATE_VENDOR_DC_STATE:
            return ({
                ...state,
                dcState: action.dcState
            });
        case UPDATE_VENDOR_DC_ZIP_ERROR:
            return ({
                ...state,
                isDcZipError: action.isDcZipError
            });
        case UPDATE_VENDOR_DC_ZIP:
            return ({
                ...state,
                dcZip: action.dcZip
            });
        case UPDATE_VENDOR_DC_CODE_ERROR:
            return ({
                ...state,
                isDcCodeError: action.isDcCodeError
            });
        case UPDATE_VENDOR_DC_CODE:
            return ({
                ...state,
                dcCode: action.dcCode
            });
        case CLEAR_DC_MODAL:
            return ({
                ...state,
                spinner: false,
                id: null,
                dcName: null,
                dcStreet: null,
                dcCity: null,
                dcState: null,
                dcZip: null,
                dcCode: null,
                isEdit: false,
                isDcNameError: false,
                isDcStreetError: false,
                isDcCityError: false,
                isDcStateError: false,
                isDcZipError: false,
                isDcCodeError: false,
                isFormUpdated: false,
            });
        default:
            return state;
    }
};