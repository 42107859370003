import {
    SHOW_COMMENT_ACTION_SPINNER,
    HIDE_COMMENT_ACTION_SPINNER,
    SHOW_COMMENT_MODAL_SPINNER,
    HIDE_COMMENT_MODAL_SPINNER,
    UPDATE_COMMENT_LIKE_STATUS,
    UPDATE_COMMENT_LIKE_ID,
    UPDATE_COMMENT_ID,
    UPDATE_COMMENT_DELETE_FLAG,
    UPDATE_COMMENT_REPORTED_FLAG,
    UPDATE_COMMENT_REPORT_ID,
    UPDATE_COMMENT_REPORT_FLAG
} from "../../constants";

const initialState = {
    commentActionSpinner: false,
    commentModalSpinner: false,
    isCommentLiked: false,
    commentLikeId: null,
    commentId: null,
    isDeleteComment: false,
    isCommentReport: false,
    commentReportId: null,
    isAlreadyReportedComment: false
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_COMMENT_ACTION_SPINNER:
            return ({
                ...state,
                commentActionSpinner: true
            });
        case HIDE_COMMENT_ACTION_SPINNER:
            return ({
                ...state,
                commentActionSpinner: false
            });
        case SHOW_COMMENT_MODAL_SPINNER:
            return ({
                ...state,
                commentModalSpinner: true
            });
        case HIDE_COMMENT_MODAL_SPINNER:
            return ({
                ...state,
                commentModalSpinner: false
            });
        case UPDATE_COMMENT_ID:
            return ({
                ...state,
                commentId: action.commentId
            });
        case UPDATE_COMMENT_LIKE_STATUS:
            return ({
                ...state,
                isCommentLiked: action.isCommentLiked
            });
        case UPDATE_COMMENT_LIKE_ID:
            return ({
                ...state,
                commentLikeId: action.commentLikeId
            });
        case UPDATE_COMMENT_DELETE_FLAG:
            return ({
                ...state,
                isDeleteComment: action.isDeleteComment
            });
        case UPDATE_COMMENT_REPORT_ID:
            return ({
                ...state,
                commentReportId: action.commentReportId
            });
        case UPDATE_COMMENT_REPORTED_FLAG:
            return ({
                ...state,
                isAlreadyReportedComment: action.isAlreadyReportedComment
            });
        case UPDATE_COMMENT_REPORT_FLAG:
            return ({
                ...state,
                isCommentReport: action.isCommentReport
            });
        default:
            return state;
    }
};
