import { UPDATE_PRODUCT_LIST_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const getProductNameList = async ({ brandId }) => {
  try {
    const response = await serviceInterceptor.get(
      `/brands/${brandId}/products`,
    );
    if (!response?.data?.success) {
      throw UPDATE_PRODUCT_LIST_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPDATE_PRODUCT_LIST_ERROR;
  }
};
