import moment from 'moment';
import {
  SHOW_DOCUMENT_MODAL_SPINNER,
  HIDE_DOCUMENT_MODAL_SPINNER,
  UPDATE_DOCUMENTS_LIST,
  UPDATE_DOCUMENT_LIST_ERROR,
  UPDATE_DOCUMENT_CATEGORY_ERROR,
  UPDATE_DOCUMENTS_CATEGORY,
  UPDATE_SELECT_MODAL_CATEGORY,
  UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
  UPDATE_DOCUMENT_DESC_ERROR,
  UPDATE_SELECT_CATEGORY,
  UPDATE_DOCUMENT_INPUT_FORM,
  UPDATE_DELETE_DOCUMENT,
  UPDATE_SELECTED_DOCUMENT,
  SHOW_DOCUMENT_LOADING,
  HIDE_DOCUMENT_LOADING,
  DocumentListError,
  DocumentCategoryError,
  DocumentDeleteSuccess,
  DeleteDocumentError,
  FetchError,
} from '../../constants';
import {
  getDocumentsListAPI,
  getDocumentCategory,
  deleteDocument,
} from '../../api/documents';
import { showDocumentSpinner, hideDocumentSpinner } from './documentsCreate';
import { getFileSize } from '../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showDocumentLoader = () => async (dispatch) => {
  dispatch({
    type: SHOW_DOCUMENT_LOADING,
  });
};

export const hideDocumentLoader = () => async (dispatch) => {
  dispatch({
    type: HIDE_DOCUMENT_LOADING,
  });
};

export const showDocumentModalSpinner = () => async (dispatch) => {
  dispatch({
    type: SHOW_DOCUMENT_MODAL_SPINNER,
  });
};

export const hideDocumentModalSpinner = () => async (dispatch) => {
  dispatch({
    type: HIDE_DOCUMENT_MODAL_SPINNER,
  });
};

export const getDocumentsList = () => async (dispatch) => {
  dispatch(showDocumentLoader());
  try {
    const response = await getDocumentsListAPI();
    const { data } = response;
    const documentList =
      data &&
      data.map((item) => {
        const { uploadeddate, publicat, category, size } = item;
        const fileSize = size !== null ? getFileSize(size) : '';
        const formattedCategory =
          category && category.length > 0 ? category : 'All';
        const formattedUploadDate = uploadeddate
          ? moment(uploadeddate).format('MM-DD-YYYY').toString()
          : '';
        return {
          ...item,
          uploadeddate: formattedUploadDate,
          category: formattedCategory,
          size: fileSize,
          accessLevel:
            publicat && publicat.length > 0
              ? 'All Members'
              : 'Selected Members',
        };
      });
    dispatch({
      type: UPDATE_DOCUMENTS_LIST,
      documentList,
    });
    dispatch(hideDocumentLoader());
  } catch (error) {
    dispatch(hideDocumentLoader());
    if (error === UPDATE_DOCUMENT_LIST_ERROR) {
      toast.error(DocumentListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getDocumentsCategory = () => async (dispatch) => {
  dispatch(showDocumentSpinner());
  try {
    const response = await getDocumentCategory();
    const { data } = response;
    const allItem = { id: 0, name: 'All', description: null };
    const categoryMenuList = [allItem, ...data];
    dispatch({
      type: UPDATE_DOCUMENTS_CATEGORY,
      categoryList: categoryMenuList,
    });
    dispatch(hideDocumentSpinner());
  } catch (error) {
    dispatch(hideDocumentSpinner());
    if (error === UPDATE_DOCUMENT_CATEGORY_ERROR) {
      toast.error(DocumentCategoryError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const handleSelectedCategory =
  (selectedCategoryID) => async (dispatch) => {
    dispatch({
      type: UPDATE_SELECT_CATEGORY,
      selectedCategoryID: selectedCategoryID.id,
    });
  };

export const handleModalCategory =
  (selectedModalCategory) => async (dispatch, getState) => {
    const { documents } = getState();
    const { categoryList, categoryError, documentDescError } = documents;
    const selectedID =
      selectedModalCategory !== 'NA'
        ? categoryList.find((item) => item.name === selectedModalCategory)
        : 'NA';
    if (selectedModalCategory === 'NA') {
      dispatch({
        type: UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
        categoryError: true,
      });
    }
    if (categoryError) {
      dispatch({
        type: UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
        categoryError: false,
      });
    }
    if (documentDescError) {
      dispatch({
        type: UPDATE_DOCUMENT_DESC_ERROR,
        documentDescError: false,
      });
    }
    dispatch({
      type: UPDATE_SELECT_MODAL_CATEGORY,
      selectedModalCategory: selectedID.id ? selectedID.id : 'NA',
    });
    dispatch({
      type: UPDATE_DOCUMENT_INPUT_FORM,
      isFormUpdated: true,
    });
  };

export const updateDocumentDelete =
  (selectedDocumentID) => async (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_DOCUMENT,
      selectedDocumentID,
    });
    dispatch({
      type: UPDATE_DELETE_DOCUMENT,
      isDeleteDocument: true,
    });
  };

export const handleCloseDocumentDeleteModal = () => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_DOCUMENT,
    selectedDocumentID: null,
  });
  dispatch({
    type: UPDATE_DELETE_DOCUMENT,
    isDeleteDocument: false,
  });
};

export const onDeleteDocument = () => async (dispatch, getState) => {
  const { documents } = getState();
  const { selectedDocumentID } = documents;
  dispatch(showDocumentModalSpinner());
  try {
    const response = await deleteDocument({
      documentID: selectedDocumentID,
    });
    const documentRes = await getDocumentsListAPI();
    const { data } = documentRes;
    const documentList =
      data &&
      data.map((item) => {
        const { uploadeddate, publicat } = item;
        const formattedUploadDate = uploadeddate
          ? moment(uploadeddate).format('MM-DD-YYYY').toString()
          : '';
        return {
          ...item,
          uploadeddate: formattedUploadDate,
          accessLevel:
            publicat && publicat.length > 0
              ? 'All Members'
              : 'Selected Members',
        };
      });
    dispatch({
      type: UPDATE_DOCUMENTS_LIST,
      documentList,
    });
    const { success } = response;
    if (success) {
      dispatch(hideDocumentModalSpinner());
      dispatch(handleCloseDocumentDeleteModal());
      toast.success(DocumentDeleteSuccess);
    }
  } catch (error) {
    dispatch(hideDocumentModalSpinner());
    dispatch(handleCloseDocumentDeleteModal());
    if (error) {
      toast.error(DeleteDocumentError);
    }
  }
};
