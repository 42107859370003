import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { formatPhoneNumber } from '../../../../utils';
import profileplaceholder from '../../../../images/profile-placeholder.jpg';

export const MemberOverview: FC<any> = (props) => {
  const { selectedMember } = props;
  const { avatar } = selectedMember || '';

  const displayDate =
    selectedMember &&
    selectedMember.startdate &&
    selectedMember.startdate != 'null' &&
    moment(selectedMember.startdate).format('MM-DD-YYYY');

  const cardImg =
    avatar === '' || avatar === null || avatar === undefined
      ? profileplaceholder
      : `/${avatar}`;

  return (
    <Grid container spacing={2} className="details-info-box">
      <Grid item xs={5}>
        <div className="details-info-user">
          <div className="details-info-user-left">
            <div className="details-user-img">
              <img src={cardImg} alt="user Image" title="Member Header" />
            </div>
          </div>
          <div className="details-info-user-right">
            <div className="details-user-content">
              <div className="details-user-content-text">
                <i className="fas fa-mobile-alt"></i>
                <span>
                  {selectedMember
                    ? formatPhoneNumber(selectedMember.phone)
                    : null}
                </span>
              </div>
            </div>

            <div className="details-user-content">
              <div className="details-user-content-text">
                <i className="far fa-calendar-check"></i>
                <span>{displayDate || '-'}</span>
                {displayDate && 'Joined Since'}
              </div>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item xs={7}>
        {/* <div className="details-business-info-box">
                  <div className="details-business-info-icon">
                    <i className="fas fa-receipt details-blue-icon"></i>
                  </div>
                  <div className="details-business-info-count">135</div>
                  <div className="details-business-info-message">
                    Total Purchase
                  </div>
                </div> */}
        {/* {
          AuthorizationService.isAuthorizedHTML("memberHeaderCounts") &&
          <div className="details-business-info-container">
            <div className="details-business-info-box">
              <div className="details-business-info-icon">
                <i className="far fa-money-bill-alt details-orange-icon"></i>
              </div>
              <div className="details-business-info-count">$ 1,002.90</div>
              <div className="details-business-info-message">Total Rebates</div>
            </div>

            <div className="details-business-info-box">
              <div className="details-business-info-icon">
                <i className="fas fa-concierge-bell details-purple-icon"></i>
              </div>
              <div className="details-business-info-count">258</div>
              <div className="details-business-info-message">Total Orders</div>
            </div>
          </div>
        } */}

        {/* <div className="details-business-info-box">
                  <div className="details-business-info-icon">
                    <i className="fas fa-cubes details-green-icon"></i>
                  </div>
                  <div className="details-business-info-count">17</div>
                  <div className="details-business-info-message">
                    Total Vendors
                  </div>
                </div> */}
      </Grid>
    </Grid>
  );
};
