import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import {ConnectedPagination} from "../../../../components/ConnectedPagination";
import {LinkTable} from '../../tableUtils/linkTable';
import {WarningPopup, getPaginatedData} from '../../../../utils';
import '../../vendors.scss';

const gridHeader = [
    {
        label: 'Type',
        value: 'type',
        sortKey: 'type',
        xs: 12,
        lg: 3
    },
    {
        label: 'Text',
        value: 'text',
        sortKey: 'text',
        xs: 12,
        lg: 3
    },
    {
        label: 'URL',
        value: 'url',
        sortKey: 'url',
        xs: 12,
        lg: 5
    },
    {
        label: 'Action',
        value: 'action',
        xs: 12,
        lg: 1
    }
];

export const LinkList: FC<any> = ({
    vendorLinksList,
    updateDeleteVendorLink,
    showEditLinkModal,
    isDeleteUser,
    handleDeleteClose,
    handleDeleteVendorLink,
    searchText,
    sortKey,
    setSortKey,
    sortType,
    paginationReducer: {
        rowsPerPage,
        page
    },
    user,
}) => {
    const modifiedList = getPaginatedData(vendorLinksList, rowsPerPage, page);
    const filteredData = (searchText && modifiedList.length > 0) ? (
        modifiedList.filter((lineItem: any) => {
            return Object.keys(lineItem).some((key) => {
                const searchItem = lineItem[key] && lineItem[key].toString();
                return searchItem && searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            })
        })) : modifiedList;

    const sortedData = (sortKey && sortType && filteredData.length > 0) ? (filteredData && filteredData.sort((a: any, b: any) => {
        if (sortType === 'DESC') {
            return b[sortKey].localeCompare(a[sortKey]);
        }
        return a[sortKey].localeCompare(b[sortKey]);
    })) : filteredData;

    const [deleteUser, setDeleteUser] = useState(null);

    const handleDelete = (item: any) => {
        const name = item && item.typename
        setDeleteUser(name)
        updateDeleteVendorLink(item.id);
    }

    return (
        <div className="table-wrapper">
            <LinkTable
                gridHeader={gridHeader}
                gridData={sortedData ? sortedData : []}
                isEdit={true}
                isDelete={true}
                handleDelete={handleDelete}
                showEditLinkModal={showEditLinkModal}
                setSortKey={setSortKey}
                sortType={sortType}
                user={user}
            />

            {
                vendorLinksList.length > 0 ?
                    <ConnectedPagination
                        count={vendorLinksList.length}
                    /> : null
            }

            <WarningPopup
                open={isDeleteUser}
                type={"delete"}
                name={deleteUser}
                handleProceed={handleDeleteVendorLink}
                handleClose={handleDeleteClose}
            />
        </div>
    )
}

LinkList.propTypes = {
    vendorKpiList: PropTypes.array,
    isDeleteUser: PropTypes.bool,
    updateDeleteVendorLink: PropTypes.func,
    showEditLinkModal: PropTypes.func,
    handleDeleteClose: PropTypes.func,
    handleDeleteVendorLink: PropTypes.func,
    searchText: PropTypes.string,
    sortKey: PropTypes.string,
    setSortKey: PropTypes.func,
    sortType: PropTypes.string
}