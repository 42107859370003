import React, {FC, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import {SearchNotFound} from "../../../components/SearchNotFound";
import {useStyles} from "../../../utils";

import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import { useLocation } from "react-router-dom";

export const LinkTable: FC<any> = ({
  gridHeader,
  gridData,
  isEdit,
  isDelete,
  handleDelete,
  showEditLinkModal,
  setSortKey,
  sortType,
  user: {isBRAdmin},
}) => {
  const classes = useStyles();
  const {pathname} :any = useLocation()

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: any) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  return (
    <div className={classes.root}>
      {gridData.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const {label, value, xs, lg, sortKey} = item;
            const isValidHeader = label !== "";
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {label === 'Action' ?
                  (isBRAdmin && <div className={label === 'Action' ? 'action-header' : ''}>
                    <span
                      className="grid-header"
                    >
                      {label}
                    </span>
                  </div>) :
                  (<div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {
                        isValidHeader ? (
                          sortType === '' ?
                            <img className="sort-svg" src={sortDefault} /> : (label === activeHeader && sortType === 'ASC') ?
                              <img className="sort-svg" src={sortAscIcon} /> : (label === activeHeader && sortType === 'DESC') ?
                                <img className="sort-svg" src={sortDescIcon} /> : <img className="sort-svg" src={sortDefault} />
                        ) :
                          null
                      }
                    </span>
                  </div>)}
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const {type, text, url} = item;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {type ? type : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {text ? text : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                <Link
                  style={{color: "black", textDecoration: "underline"}}
                  href={url}
                  target='_blank'
                >
                  {url ? url : '-'}
                </Link>
              </Grid>

              <Grid
                className="td action-col"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {isBRAdmin && isEdit && (
                  <Tooltip title="Edit">
                    <Button
                      className="btn-circle btn-secondary"
                      variant="contained"
                      onClick={() => showEditLinkModal(item)}
                    >
                      <i className="fas fa-pen"></i>
                    </Button>
                  </Tooltip>
                )}

                {isBRAdmin && isDelete && (
                  <Tooltip title="Delete">
                    <Button
                      className="btn-circle-trash"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDelete(item)}
                    >
                      <i className="fas fa-times"></i>
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={"Links"} />
      )}
    </div>
  );
};

LinkTable.propTypes = {
  gridHeader: PropTypes.array,
  gridData: PropTypes.array,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  editVendorLink: PropTypes.func,
  setSortKey: PropTypes.func,
  sortType: PropTypes.string,
};
