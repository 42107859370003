import { FC, useCallback } from 'react';
import { useStore } from 'react-redux';
import { Members } from '../../../../../../common';
import ClaimRebates from '../../../../components/modal/ClaimRebates';
import { ExportRebateReceipts } from '../../../../components/modal/ExportRebateReceipts';

export const RebatesReceiptActions: FC<any> = (props) => {
  const store = useStore<{
    rebateReceipts: {
      memberListSpinner: boolean;
      memberList: Members;
      selectedMember: { label: string, value: number };
      rebatesAmount: { availableAmount: number };
    };
  }>();
  const storeState = store.getState();
  const selectedMemberId = storeState?.rebateReceipts?.selectedMember?.value;
  const availableAmount =
    storeState?.rebateReceipts?.rebatesAmount?.availableAmount;
  const refresh = useCallback(() => {
    const storeState = store.getState();
    props.handleSelected(storeState?.rebateReceipts?.selectedMember ?? {});
  }, [selectedMemberId]);

  return (
    <>
      {selectedMemberId > 0 && availableAmount > 0 && (
        <div className="header-filter-box-add">
          <ClaimRebates
            refresh={refresh}
            key={selectedMemberId + availableAmount}
            {...props}
          />
        </div>
      )}

      <div className="header-filter-box-add">
        <ExportRebateReceipts {...props} />
      </div>
    </>
  );
};
