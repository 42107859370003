import {
    SHOW_COMMUNITY_CATEGORY_SPINNER,
    HIDE_COMMUNITY_CATEGORY_SPINNER,
    UPDATE_COMMUNITY_CATEGORY,
    UPDATE_NEW_CHANNEL,
    UPDATE_ACTIVE_CHANNEL,
    UPDATE_CHANNEL_NAME,
    UPDATE_CHANNEL_DESC,
    CLEAR_COMMUNITY_CHANNEL_MODAL,
    UPDATE_CHANNEL_FORM_INPUTS,
    SHOW_CHANNEL_FORM_SPINNER,
    HIDE_CHANNEL_FORM_SPINNER,
    UPDATE_ALL_DISCUSSIONS,
    UPDATE_ALL_DISCUSSIONS_ERROR,
    UPDATE_CHANNEL_NAME_ERROR,
    UPDATE_CHANNEL_DESC_ERROR,
    UPDATE_COMMUNITY_CATEGORY_ERROR,
    CommunityCategoryError,
    GetAllDiscussionsError,
    CreateChannelError,
    FetchError
} from "../../../constants";

import {
    getCommunityCategory,
    createChannel,
    getAllDiscussions
} from "../../../api/community";

import {showDiscussionSpinner, hideDiscussionSpinner} from '../discussion/discussion';

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const isValidText = (value) => {
    return value && value.trim().length > 0 ? true : false;
}

export const showCommunitySpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_COMMUNITY_CATEGORY_SPINNER
    });
};

export const hideCommunitySpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_COMMUNITY_CATEGORY_SPINNER
    });
};

export const updateNewChannel = (isNewChannel) => async (dispatch) => {
    dispatch({
        type: UPDATE_NEW_CHANNEL,
        isNewChannel
    })
}

export const clearCommunityChannelModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_COMMUNITY_CHANNEL_MODAL
    });
};

export const handleChannelName = (channelName) => async (dispatch) => {
    if (channelName !== null) {
        dispatch({
            type: UPDATE_CHANNEL_NAME_ERROR,
            isChannelNameError: false
        });
    }
    dispatch({
        type: UPDATE_CHANNEL_NAME,
        channelName
    })
    dispatch({
        type: UPDATE_CHANNEL_FORM_INPUTS,
        isChannelFormUpdated: true
    });
}

export const handleChannelDesc = (channelDesc) => async (dispatch) => {
    if (channelDesc !== null) {
        dispatch({
            type: UPDATE_CHANNEL_DESC_ERROR,
            isChannelDescError: false
        });
    }
    dispatch({
        type: UPDATE_CHANNEL_DESC,
        channelDesc
    })
    dispatch({
        type: UPDATE_CHANNEL_FORM_INPUTS,
        isChannelFormUpdated: true
    });
}


export const getCommunityData = () => async (dispatch) => {
    dispatch(showCommunitySpinner());
    dispatch(showDiscussionSpinner());
    try {
        const {data, success} = await getCommunityCategory();

        if(success){
            dispatch({
                type: UPDATE_COMMUNITY_CATEGORY,
                communityCategory: data
            })
            const initalActiveChannelID = data[0].id;
            dispatch({
                type: UPDATE_ACTIVE_CHANNEL,
                activeChannelID: initalActiveChannelID
            })
            const res = await getAllDiscussions({
                activeChannelID: initalActiveChannelID
            });
            const {data: descussionList} = res;
            dispatch({
                type: UPDATE_ALL_DISCUSSIONS,
                discussionList: descussionList
            })
        }   
        dispatch(hideCommunitySpinner());  
        dispatch(hideDiscussionSpinner());
    } catch (error) {
        dispatch(hideCommunitySpinner());
        if (error === UPDATE_COMMUNITY_CATEGORY_ERROR) {
            toast.error(CommunityCategoryError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const handleCreateChannel = () => async (dispatch, getState) => {
    const {communityChannel} = getState();
    const {channelName, channelDesc} = communityChannel;
    const isValidchannelName = isValidText(channelName);
    const isValidChannelDesc = isValidText(channelDesc);
    if (!isValidchannelName) {
        dispatch({
            type: UPDATE_CHANNEL_NAME_ERROR,
            isChannelNameError: true
        });
    }
    if (!isValidChannelDesc) {
        dispatch({
            type: UPDATE_CHANNEL_DESC_ERROR,
            isChannelDescError: true
        });
    }
    const isError = !isValidchannelName || !isValidChannelDesc;
    try {
        if (!isError) {
            dispatch({
                type: SHOW_CHANNEL_FORM_SPINNER,
                channelFormSpinner: true
            });
            const response = await createChannel({
                channelName,
                channelDesc
            });
            const categoryRes = await getCommunityCategory();
            const {data} = categoryRes;
            dispatch({
                type: UPDATE_COMMUNITY_CATEGORY,
                communityCategory: data
            })
            dispatch({
                type: HIDE_CHANNEL_FORM_SPINNER,
                channelFormSpinner: false
            });
            const {success, message} = response;
            if (!success) {
                toast.error(message)
            }
            else {
                dispatch({
                    type: UPDATE_CHANNEL_FORM_INPUTS,
                    isChannelFormUpdated: false
                });
                dispatch(clearCommunityChannelModal())
                dispatch(updateNewChannel(false))
                toast.success(message);
            }
        }
    } catch (error) {
        dispatch({
            type: HIDE_CHANNEL_FORM_SPINNER,
            channelFormSpinner: false
        });
        if (error) {
            toast.error(CreateChannelError)
        }
    }
}

