import { FC, useEffect, useState } from 'react';
import { MemberHeader } from './memberHeader';
import { MemberList } from './memberList';
import { AssignNewMember } from './modal/AssignMemberModal';
import { KpiChartModal } from './modal/KpiCharts';
import { AssignKPIModal } from './modal/assignKPIModal';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../../machines';
import serverApiV2Instance from '../../../../services/serverApiV2Instance';
import { useLocation } from 'react-router-dom';

export const MemberSection: FC<any> = ({
  updateDeleteVendorMembers,
  handleDeleteClose,
  handleDeleteVendorMember,
  getVendorMembers,
  clearVendorMemberList,
  getVendorUsers,
  showMemberAssignModal,
  showEditVendorMember,
  vendorMemberAssignModal,
  vendorMemberAssignModal: { memberAssignModal, isEditModal, selectedMemberId },
  onChangeVendorMemberUser,
  onAssignSalesPersonnel,
  clearVendorMemberAssign,
  showAddKPIModal,
  closeKPIModal,
  getVendorKPITypesData,
  modal: { showModal },
  vendor: {
    vendorID,
    vendorUsersList,
    vendorKpiTypesList,
    isDeleteUser,
    vendorMembersList,
    vendorMemberListSpinner,
  },
  vendorKPIModal,
  onChangeVendorKPIkpi,
  onChangeVendorKPImember,
  onChangeVendorKPIcomments,
  onAddVendorKPI,
  onEditVendorKPI,
  updateSelectedKPIMember,
  getKPIYearQuarter,
  getKpiYears,
  handleSelectKpiYear,
  handleSelectkpiQuarter,
  clearChartModal,
  onUpdateSalesPersonnel,
  paginationReducer,
  updateMemberCustomerID,
  onSelecteAssignMember,
  updateMemberCustomerAbbr,
  user,
}) => {
  const { assignmembers, unassignmembers } = vendorMembersList || [];
  const { assignedVendorUsers } = vendorUsersList || [];
  const [searchText, setSearchText] = useState('');
  const [kpiChartModal, setKPIChartModal] = useState(false);
  const location:any = useLocation();

  const showKPIAddModal = () => {
    setKPIChartModal(false);
    showAddKPIModal();
  };

  const closeKPIAddModal = () => {
    setKPIChartModal(false);
    clearChartModal();
  };

  useEffect(() => {
    window.history.replaceState({}, '')
    getVendorMembers();
    getVendorUsers();

    return () => {
      clearVendorMemberList();
    };
  }, []);

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: 'userName',
        sortOrder: 'asc',
      },
      pageSize: location?.state?.isFromMemberTab ? location?.state?.pageSize : 10,
      pageNumber : location?.state?.isFromMemberTab ? location?.state?.pageNumber : 1
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/vendors/${vendorID}/assigned-members?${query.toString()}`,
        );
      },
    },
  });

  const showMemberModal = memberAssignModal || isEditModal;
  return (
    <div className="vendor-assign-content">
      <MemberHeader
        searchText={searchText}
        setSearchText={setSearchText}
        showMemberAssignModal={showMemberAssignModal}
        state={state}
        send={send}
      />
      <MemberList
        updateDeleteVendorMembers={updateDeleteVendorMembers}
        isDeleteUser={isDeleteUser}
        handleDeleteClose={handleDeleteClose}
        handleDeleteVendorMember={handleDeleteVendorMember}
        setKPIChartModal={setKPIChartModal}
        updateSelectedKPIMember={updateSelectedKPIMember}
        getKpiYears={getKpiYears}
        showEditVendorMember={showEditVendorMember}
        getKPIYearQuarter={getKPIYearQuarter}
        user={user}
        state={state}
        send={send}
      />
      <AssignNewMember
        showMemberModal={showMemberModal}
        assignedVendorUsers={assignedVendorUsers}
        unassignmembers={unassignmembers}
        assignmembers={assignmembers}
        clearVendorMemberAssign={clearVendorMemberAssign}
        vendorMemberAssignModal={vendorMemberAssignModal}
        onChangeVendorMemberUser={onChangeVendorMemberUser}
        onAssignSalesPersonnel={onAssignSalesPersonnel}
        paginationReducer={paginationReducer}
        updateMemberCustomerID={updateMemberCustomerID}
        onSelecteAssignMember={onSelecteAssignMember}
        onUpdateSalesPersonnel={onUpdateSalesPersonnel}
        selectedMemberId={selectedMemberId}
        updateMemberCustomerAbbr={updateMemberCustomerAbbr}
        send={send}
      />

      <AssignKPIModal
        openDialog={showModal}
        closeKPIModal={closeKPIModal}
        getVendorMembers={getVendorMembers}
        getVendorKPITypesData={getVendorKPITypesData}
        vendorKpiTypesList={vendorKpiTypesList}
        vendorMembersList={assignmembers}
        vendorKPIModal={vendorKPIModal}
        onChangeVendorKPIkpi={onChangeVendorKPIkpi}
        onChangeVendorKPImember={onChangeVendorKPImember}
        onChangeVendorKPIcomments={onChangeVendorKPIcomments}
        onAddVendorKPI={onAddVendorKPI}
        onEditVendorKPI={onEditVendorKPI}
        setKPIChartModal={setKPIChartModal}
      />

      <KpiChartModal
        openDialog={kpiChartModal}
        closeModal={closeKPIAddModal}
        showAddKPIModal={showKPIAddModal}
        vendorKPIModal={vendorKPIModal}
      />
    </div>
  );
};
