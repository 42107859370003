import {
    SHOW_DISCUSSION_ACTION_SPINNER,
    HIDE_DISCUSSION_ACTION_SPINNER,
    UPDATE_DISCUSSION_LIKE,
    UPDATE_DISCUSSION_ID,
    UPDATE_DISCUSSION_LIKE_ID,
    UPDATE_DISCUSSION_LIKE_SUCCESS,
    UPDATE_DISCUSSION_DISLIKE_SUCCESS,
    UPDATE_ARCHIVE_DISCUSSION
} from "../../constants";

const initialState = {
    spinner: false,
    isDiscussionLiked: false,
    discussionId: null,
    likeId: null,
    likeSuccess: false,
    disLikeSuccess: false,
    isArchive: false
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DISCUSSION_ACTION_SPINNER:
            return ({
                ...state,
                spinner: true
            });
        case HIDE_DISCUSSION_ACTION_SPINNER:
            return ({
                ...state,
                spinner: false
            });
        case UPDATE_DISCUSSION_LIKE:
            return ({
                ...state,
                isDiscussionLiked: action.isDiscussionLiked
            });
        case UPDATE_DISCUSSION_ID:
            return ({
                ...state,
                discussionId: action.discussionId
            });
        case UPDATE_DISCUSSION_LIKE_ID:
            return ({
                ...state,
                likeId: action.likeId
            });
        case UPDATE_DISCUSSION_LIKE_SUCCESS:
            return ({
                ...state,
                likeSuccess: action.likeSuccess
            });
        case UPDATE_DISCUSSION_DISLIKE_SUCCESS:
            return ({
                ...state,
                disLikeSuccess: action.disLikeSuccess
            });
        case UPDATE_ARCHIVE_DISCUSSION:
            return ({
                ...state,
                isArchive: action.isArchive
            });
        default:
            return state;
    }
};
