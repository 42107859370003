import { UPLOAD_PRODUCT_REBATES_ERROR } from '../../../constants';
import serviceInterceptor from '../../../services/ServiceInterceptor';

export const updateProductRebates = async ({
  quarter,
  year,
  rebateProductList,
}) => {
  try {
    let q;
    switch (
      quarter // TODO temporary switch to format quarter correctly
    ) {
      case 'Q1':
      case '1':
      case 1:
        q = 1;
        break;
      case 'Q2':
      case '2':
      case 2:
        q = 2;
        break;
      case 'Q3':
      case '3':
      case 3:
        q = 3;
        break;
      case 'Q4':
      case '4':
      case 4:
        q = 4;
        break;
      default:
        q = null;
        break;
    }
    const bodyFormData = new FormData();
    bodyFormData.append('rebateProductList', rebateProductList);
    bodyFormData.append('quarter', q);
    bodyFormData.append('year', year);

    const response = await serviceInterceptor.patch(
      `/rebate-product-list-records`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw UPLOAD_PRODUCT_REBATES_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPLOAD_PRODUCT_REBATES_ERROR;
  }
};
