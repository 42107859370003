import React,{FC} from 'react'

const useSortableData = (items:any[], config:any = null) => {
    const [sortConfig, setSortConfig] = React.useState(config);
    const [search,setSearch] = React.useState('')
  
    const sortedItems = React.useMemo(() => {
      let sortableItems = [...items];
      if (sortConfig !== null) {
        sortableItems.sort((a, b) => {
          if (a[sortConfig.key] < b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? -1 : 1;
          }
          if (a[sortConfig.key] > b[sortConfig.key]) {
            return sortConfig.direction === 'ascending' ? 1 : -1;
          }
          return 0;
        });
      }
      if(search !== ''){ 
        let searchedData = sortableItems.filter(data => data.label.toLowerCase().includes(search.toLowerCase()))
        
        // if(searchedData.length){
          return searchedData
        // }
      }
      return sortableItems
      
    }, [items, sortConfig,search]);
  
    const requestSort = (key:string) => {
      let direction = 'ascending';
      if (
        sortConfig &&
        sortConfig.key === key &&
        sortConfig.direction === 'ascending'
      ) {
        direction = 'descending';
      }
      setSortConfig({ key, direction });
    };

    const requestSearch = (data:any) => {
      setSearch(data)
    }
  
    return { items: sortedItems, requestSort, sortConfig, requestSearch };
  };

export default useSortableData