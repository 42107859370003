import {
    UPDATE_VENDOR_MEMBERS_SPINNER,
    SHOW_VENDOR_MEMBER_MODAL_LOADING,
    HIDE_VENDOR_MEMBER_MODAL_LOADING,
    UPDATE_VENDOR_MEMBERS_LIST_ERROR,
    UPDATE_VENDOR_MEMBERS_LIST,
    UPDATE_SELECTED_VENDOR_MEMBER,
    UPDATE_DELETE_VENDOR_FLAG,
    CLEAR_VENDOR_MEMBERS_LIST,
    UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER,
    UPDATE_VENDOR_MEMBER_ASSIGN_MODAL,
    UPDATE_VENDOR_MEMBER_SELECT_USER,
    UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
    UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
    UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
    UPDATE_VENDOR_MEMBER_SELECTED_ID,
    UPDATE_VENDOR_MEMBER_CUSTOMER_ID,
    UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION,
    UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR,
    CLEAR_VENDOR_MEMBER_ASSIGN_MODAL,
    UPDATE_VENDOR_MEMBER_EDIT_MODAL,
    UPDATE_VENDOR_MEMBER_EDIT_DATA,
    VendorMemberListError,
    VendorDeleteUserError,
    VendorMemberDeleteSuccess,
    VendorMemberAssignSuccess,
    VendorMemberUpdateSuccess,
    VendorMemberUpdateError,
    VendorMemberAssignError,
    FetchError
} from "../../constants";

import {
    getVendorMembersList,
    deleteVendorMember,
    assignVendorSalesPersonnel,
    updateVendorSalesPersonnel
} from '../../api/vendors';
import {showModal} from '../modal';


import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showModalLoader = () => async (dispatch) => {
    dispatch({
        type: SHOW_VENDOR_MEMBER_MODAL_LOADING
    });
};

export const hideModalLoader = () => async (dispatch) => {
    dispatch({
        type: HIDE_VENDOR_MEMBER_MODAL_LOADING
    });
};

export const clearVendorMemberList = () => ({
    type: CLEAR_VENDOR_MEMBERS_LIST
})

export const showMemberAssignModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ASSIGN_MODAL,
        memberAssignModal: true
    });
}

export const showEditVendorMember = (selectedMember) => async (dispatch, getState) => {
    const {vendor} = getState();
    const {vendorUsersList} = vendor;
    const {assignedVendorUsers} = vendorUsersList;
    const activeDc = assignedVendorUsers && assignedVendorUsers.find((item) => item.vendorUserId === selectedMember.salesPersonId);
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER,
        activeDistributionCenter: activeDc
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_EDIT_DATA,
        selectedMemberData: selectedMember
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID,
        customerID: selectedMember.customerId
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_SELECT_USER,
        vendorUserId: selectedMember.salesPersonId
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION,
        customerAbbreviation: selectedMember.customerAbbreviation
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_SELECTED_ID,
        selectedMemberId: selectedMember.id
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_EDIT_MODAL,
        isEditModal: true
    });
}

export const getVendorMembers = () => async (dispatch, getState) => {
    const {vendor} = getState();
    const {vendorID} = vendor;
    dispatch({
        type: UPDATE_VENDOR_MEMBERS_SPINNER,
        spinner: true
    });
    try {
        const vendorMembersList = await getVendorMembersList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_LIST,
            vendorMembersList: vendorMembersList.data
        })
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_SPINNER,
            spinner: false
        });
    } catch (error) {
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_SPINNER,
            spinner: false
        });
        if (error === UPDATE_VENDOR_MEMBERS_LIST_ERROR) {
            toast.error(VendorMemberListError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const updateDeleteVendorMembers = (memberId) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_VENDOR_MEMBER,
        slectedMemberID: memberId
    });
    dispatch({
        type: UPDATE_DELETE_VENDOR_FLAG,
        isDeleteUser: true
    });
};

export const handleDeleteVendorMember = () => async (dispatch, getState) => {
    const {vendor} = getState();
    const {slectedMemberID, vendorID} = vendor;
    dispatch({
        type: UPDATE_VENDOR_MEMBERS_SPINNER,
        spinner: true
    });
    try {
        const response = await deleteVendorMember({
            vendorID,
            slectedMemberID
        });
        const vendorMembersList = await getVendorMembersList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_LIST,
            vendorMembersList: vendorMembersList.data
        })
        dispatch({
            type: UPDATE_SELECTED_VENDOR_MEMBER,
            slectedMemberID: null
        });
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_SPINNER,
            spinner: false
        });
        const {success} = response;
        success ? toast.success(VendorMemberDeleteSuccess) : null;
    } catch (error) {
        dispatch({
            type: UPDATE_SELECTED_VENDOR_MEMBER,
            slectedMemberID: null
        });
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        dispatch({
            type: UPDATE_VENDOR_MEMBERS_SPINNER,
            spinner: false
        });
        if (error) {
            toast.error(VendorDeleteUserError)
        }
    }
}

export const onChangeVendorMemberUser = (userid) => async (dispatch, getState) => {
    const formatedVendoruserid = parseInt(userid);
    const {vendor, vendorMemberAssignModal} = getState();
    const {isEditModal} = vendorMemberAssignModal;
    const {vendorUsersList} = vendor;
    const {assignedVendorUsers} = vendorUsersList;
    const activeDc = assignedVendorUsers && assignedVendorUsers.find((item) => item.vendorUserId === formatedVendoruserid);
    const isValidAssignUser = validateAssignUser(userid);
    dispatch({
        type: UPDATE_VENDOR_MEMBER_SELECT_USER,
        vendorUserId: formatedVendoruserid
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER,
        activeDistributionCenter: activeDc
    });
    if (isValidAssignUser) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
            vendorUserError: false
        });
    } else {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
            vendorUserError: true
        });
    }
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
        isEditFormUpdated: true
    });
}

export const clearVendorMemberAssign = () => async (dispatch) => {
    dispatch({
        type: CLEAR_VENDOR_MEMBER_ASSIGN_MODAL
    });
}

export const onAssignSalesPersonnel = () => async (dispatch, getState) => {
    const {vendor, vendorMemberAssignModal} = getState();
    const {vendorUserId, customerID, selectedMemberId, activeDistributionCenter, customerAbbreviation} = vendorMemberAssignModal;
    const {vendorID} = vendor;

    if (vendorUserId === 0) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
            vendorUserError: true
        });
    }
    else if (customerAbbreviation === null || customerAbbreviation === '' || customerAbbreviation.length < 3) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR,
            customerAbbreviationError: true
        });
    } 
    else if (vendorUserId !== 0 && customerID === null) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
            customerIDError: true
        });
    }
    else if (selectedMemberId === null) {
        toast.error("Select a member")
    } else {
        try {
            const response = await assignVendorSalesPersonnel({
                vendorUserId,
                selectedMemberId,
                customerID,
                distributionId: activeDistributionCenter.distributionId,
                customerAbbreviation
            });
            const {success} = response;
            success ? toast.success(VendorMemberAssignSuccess) : null;

            if (success) {

                dispatch({
                    type: CLEAR_VENDOR_MEMBER_ASSIGN_MODAL
                });

                const vendorMembersList = await getVendorMembersList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_MEMBERS_LIST,
                    vendorMembersList: vendorMembersList.data
                });
            }
        } catch (error) {

            if (error) {
                toast.error(VendorMemberAssignError)
            }
        }
    }
}

export const onUpdateSalesPersonnel = () => async (dispatch, getState) => {
    const {vendor, vendorMemberAssignModal} = getState();
    const {vendorUserId, selectedMemberData, customerID, activeDistributionCenter, customerAbbreviation} = vendorMemberAssignModal;
    const {id, vendorMemberId, vendorUserMemberId} = selectedMemberData;
    const {vendorID} = vendor;

    if (vendorUserId === 0) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
            vendorUserError: true
        });
    } else if (customerAbbreviation === null || customerAbbreviation === '' || customerAbbreviation.length < 3) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR,
            customerAbbreviationError: true
        });
    } 
    else if (vendorUserId !== 0 && customerID === null) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
            customerIDError: true
        });
    } else {
        dispatch(showModalLoader());
        try {
            const response = await updateVendorSalesPersonnel({
                vendorID,
                memberId: id,
                vendorUserId,
                vendorMemberId,
                vendorUserMemberId,
                customerID,
                distributionId: activeDistributionCenter.distributionId,
                customerAbbreviation
            });
            const {success} = response;
            if (success) {
                const vendorMembersList = await getVendorMembersList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_MEMBERS_LIST,
                    vendorMembersList: vendorMembersList.data
                });
                success ? toast.success(VendorMemberUpdateSuccess) : null;
                dispatch({
                    type: CLEAR_VENDOR_MEMBER_ASSIGN_MODAL
                });
                dispatch(hideModalLoader());
            }
            else if (!success) {
                dispatch(hideModalLoader());
                dispatch({
                    type: CLEAR_VENDOR_MEMBER_ASSIGN_MODAL
                });
                toast.error(VendorMemberUpdateError)
            }
        } catch (error) {
            dispatch(hideModalLoader());
            if (error) {
                toast.error(VendorMemberAssignError)
            }
        }
    }
}

export const updateMemberCustomerAbbr = (value) => async (dispatch) => {
    if (value === null || value === '' || value.length < 3) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR,
            customerAbbreviationError: true
        });
    } else {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR,
            customerAbbreviationError: false
        });
    }
    dispatch({
        type: UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION,
        customerAbbreviation: value
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
        isEditFormUpdated: true
    });
}

export const updateMemberCustomerID = (value) => async (dispatch) => {
    dispatch({
        type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
        customerIDError: false
    });
    const numberOnly = new RegExp(/^[0-9]*$/);
    const isValidId = numberOnly.test(value);

    if (isValidId) {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID,
            customerID: parseInt(value)
        });
        dispatch({
            type: UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
            isEditFormUpdated: true
        });
    } else {
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID,
            customerID: null
        });
        dispatch({
            type: UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
            customerIDError: true
        });
    }
}

export const onSelecteAssignMember = (selectedMemberId) => async (dispatch) => {
    dispatch({
        type: UPDATE_VENDOR_MEMBER_SELECTED_ID,
        selectedMemberId
    });
    dispatch({
        type: UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
        isEditFormUpdated: true
    });
}

const validateAssignUser = (option) => {
    if (option && option != 0) return true;
    else {
        return false
    }
}
const validateAssignMemberList = (option) => {
    if (Array.isArray(option) && option.length > 0) return true;
    else {
        return false
    }
}

const returnValidatedNumber = (option) => {
    return option && !isNaN(parseInt(option)) ? parseInt(option) : '0';
}