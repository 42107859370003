import {COMMUNITY_CATEGORY_API, EDIT_DISCUSSION_ERROR} from '../../../constants';

export const editDiscussion = async ({
    activeChannelID,
    discussionId,
    userId,
    discussionTitle,
    discussionDesc
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${COMMUNITY_CATEGORY_API}/${activeChannelID}/discussions/${discussionId}`, {
            method: 'PATCH',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                title: discussionTitle,
                description: discussionDesc,
                userId
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw EDIT_DISCUSSION_ERROR;
    }
};