import { createRef, useEffect, useRef, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { ConnectedPagination } from '../../../components/ConnectedPagination';
import { getPaginatedData } from '../../../utils';
import { DocumentsGridContent } from './DocumentsGridContent';

export const DocumentsGrid = (props) => {

  const {
    documents: {
      documentList,
      selectedCategoryID,
      downloadSpinner, // TODO lot's of unused variables
      downloadIconVisible,
    },
    showDocumentEditModal,
    showDocumentDetailModal,
    handleDeleteDocument,
    searchText,
    downloadDocument,
    paginationReducer: { rowsPerPage, page },
    handleChangeRowsPerPage,
    handlePageType,
  } = props;

  useEffect(() => {
    handleChangeRowsPerPage(18);
    handlePageType('DOCUMENTS');
    return () => {
      handleChangeRowsPerPage(20);
      handlePageType(null);
    };
  }, []);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef([]);
  const [selectedIndex, setSelectIndex] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const handleToggle = (index) => {
    if (selectedIndex == index) {
      setOpen((prevOpen) => !prevOpen);
    } else {
      setSelectIndex(index);
      setOpen(true);
    }
  };

  const handleClose = (event, i) => {
    if (
      anchorRef.current &&
      anchorRef.current[i].current &&
      anchorRef.current[i].current.contains(event.target)
    ) {
      return;
    }
    setOpen(false);
    setSelectIndex(null);
  };

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };
  const categorisedData =
    selectedCategoryID === 0
      ? documentList
      : documentList.filter(
          (item) => item.documenttypeid === selectedCategoryID,
        );

  const filteredData =
    searchText && categorisedData.length > 0
      ? categorisedData.filter((lineItem) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : categorisedData;
  const modifiedList = getPaginatedData(filteredData, rowsPerPage, page);

  if (anchorRef.current.length !== filteredData.length) {
    // add or remove refs
    anchorRef.current = Array(filteredData.length)
      .fill()
      .map((_, i) => anchorRef.current[i] || createRef());
  }

  const handleDocumentDownload = (item) => {
    downloadDocument(item);
    setSelectedItemID(item.id);
  };

  return (
    <div>
      <Grid
        container
        alignItems="center"
        className="documents-grid"
        spacing={isSmall ? 3 : 5}
      >
        {modifiedList.length > 0 ? (
          modifiedList.map((item, index) => {

            const gridContentProps = {
              ...props,
              item,
              index,
              anchorRef,
              open,
              selectedIndex,
              selectedItemID,
              handleToggle,
              handleDocumentDownload,
              handleClose,
              handleListKeyDown
            }

            return (
              <DocumentsGridContent {...gridContentProps} />
            );
          })
        ) : (
          <SearchNotFound sectionType={'Documents'} />
        )}
      </Grid>
      {documentList.length > 0 ? (
        <ConnectedPagination count={documentList.length} />
      ) : null}
    </div>
  );
};
