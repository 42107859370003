import React from "react";
import Grid from "@material-ui/core/Grid";
import HeaderImage from "../../../../../images/bg-header-2.png";

export const MemberRebatesTitle = ({
  rebates: {
    activeTab,
  },
  productRebates: {
    activeQuarter
  },
  memberRebates: {
    memberActiveQuarter
  }
}) => {
  return (
    <div className="rebates-header">
      <Grid container className="details-header">
        <div className="details-header-img">
          <img src={HeaderImage} />
          <Grid
            container
            spacing={2}
            alignItems="center"
            className="details-header-content"
          >
            <Grid item xs={12}>
              <div className="details-header-left">
                <h2 className="details-header-title">
                  Rebates
                </h2>
              </div>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </div>
  )
};
