import {
    UPDATE_PAGINATION_SELECTED_ROWS,
    UPDATE_PAGINATION_PAGE,
    UPDATE_PAGE_TYPE
} from "../constants";

export const handleChangeRowsPerPage = (selectedRows) => async (dispatch) => {
    dispatch({
        type: UPDATE_PAGINATION_SELECTED_ROWS,
        rowsPerPage: selectedRows
    })
    dispatch({
        type: UPDATE_PAGINATION_PAGE,
        page: 0
    })
};

export const handleChangePage = (event, selectedPage) => async (dispatch) => {
    dispatch({
        type: UPDATE_PAGINATION_PAGE,
        page: selectedPage
    })
};

export const handlePageType = (pageType) => async (dispatch) => {
    dispatch({
        type: UPDATE_PAGE_TYPE,
        pageType
    })
};