import { FC, useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { RebatesReceiptMain } from '../components/RebateReceipts/RebatesReceiptMain';
import { MembersMain } from '../components/MemberActiveEarnings/MembersMain';
import { RebatesOptionsTabPanel } from '../../../components/RebatesOptionsTabPanel';
import { ExportProductRebates } from '../../../components/modal/ExportProductRebates';
import { RebateReceiptCounts } from './RebateReceiptCounts';
import { a11yProps } from '../../../../../utils';
import '../../../rebates.scss';
import '../../../../../scss/styles.scss';
import Opportunities from '../../../Opportunities';
import { trackPages } from '../../../../../api/trackPages';

export const MemberRebatesTabs: FC<any> = (props) => {
  const { rebates, updateActiveTab, productRebates, showNotesModal, updateSelectedProduct } = props;
  const { activeTab, rebatesModalSpinner } = rebates;

  const {
    isProductRebateExportModal,
    exportQuarter,
    exportYear,
    requestRebateType,
    quarterList,
  } = productRebates;

  const tabMenuLabel = [
    {
      label: 'Rebate Receipts',
      value: 0,
    },
    {
      label: 'Opportunities',
      value: 1,
    },
    {
      label: 'Earned Rebates',
      value: 2,
    },
  ];
  const handleChange = (_: any, newValue: any) => {
    const foundItem:{label:String,value:number}|any = tabMenuLabel.find(item => item.value === newValue);
   trackPages(foundItem.label === 'Opportunities' ? "Rebates Opportunities" : foundItem.label)
    updateActiveTab(newValue);
    handleClickAway();
  };
  const [tabMenuOpen, setTabMenuOpen] = useState(false);
  const tabResponsive = () => {
    setTabMenuOpen(!tabMenuOpen);
  };
  const handleClickAway = () => {
    setTabMenuOpen(false);
  };

  const {
    showProductRebateExportModal,
    handleExportQuarter,
    handleExportYear,
    onExportProductRebates,
    closeRebateExportModal,
    handleRequestRebateType,
    memberAdminRebates: {selectedMember},
  } = props;
  return (
    <div className="rebates-br-admin">
      <div className="container">
        <div className="br-tab">
          <div className={tabMenuOpen ? 'tab-ul tab-ul-open' : 'tab-ul'}>
            {/* <ClickAwayListener onClickAway={handleClickAway}> */}
              <button
                className="tab-button"
                tabIndex={0}
                onClick={tabResponsive}
              >
                {tabMenuLabel[activeTab]?.label}
                <i className="fas fa-caret-down" />
              </button>
            {/* </ClickAwayListener> */}
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Rebate Receipts" {...a11yProps(0)} />
              <Tab label="Opportunities" {...a11yProps(1)} />
              <Tab label="Earned Rebates" {...a11yProps(2)} />
            </Tabs>
          </div>

          <RebatesOptionsTabPanel value={activeTab} index={0}>
            <RebateReceiptCounts {...props} />
            <RebatesReceiptMain {...props} activeTab={activeTab} />
          </RebatesOptionsTabPanel>

          <RebatesOptionsTabPanel value={activeTab} index={1}>
            <Opportunities
              showNotesModal={showNotesModal}
              updateSelectedProduct={updateSelectedProduct}
            />
            {/* <OpportunitiesContent {...props} /> */}
          </RebatesOptionsTabPanel>

          <RebatesOptionsTabPanel value={activeTab} index={2}>
            <MembersMain {...props} key={selectedMember}/>
          </RebatesOptionsTabPanel>

          {isProductRebateExportModal && (
            <ExportProductRebates
              openModal={isProductRebateExportModal}
              setOpenModal={showProductRebateExportModal}
              handleExportQuarter={handleExportQuarter}
              exportQuarter={exportQuarter}
              exportYear={exportYear}
              handleExportYear={handleExportYear}
              onExportProductRebates={onExportProductRebates}
              rebatesModalSpinner={rebatesModalSpinner}
              closeRebateExportModal={closeRebateExportModal}
              handleRequestRebateType={handleRequestRebateType}
              requestRebateType={requestRebateType}
              quarterList={quarterList}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MemberRebatesTabs;
