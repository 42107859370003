import moment from "moment";
import {
    UPDATE_SELECTED_DOCUMENT_DATA,
    UPDATE_IS_EDIT_DOCUMENT,
    UPDATE_SELECTED_FILE_NAME,
    UPDATE_SELECTED_FILE_EXTENSION,
    UPDATE_SELECT_ALL_MEMBERS,
    UPDATE_SELECT_MEMBERS,
    UPDATE_SELECT_MODAL_CATEGORY,
    UPDATE_DOCUMENT_DESCRIPTION,
    UPDATE_DOCUMENT_INPUT_FORM,
    UPDATE_IS_EDIT_DOCUMENT_ERROR,
    UPDATE_SELECTED_FILE_SIZE,
    UPDATE_SELECT_MEMBERS_LIST,
    UPDATE_DOCUMENTS_LIST,
    UPDATE_DOCUMENT_ERROR,
    UPDATE_DOCUMENT_LIST_ERROR,
    UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR,
    UPDATE_DOCUMENT_DESC_ERROR,
    DocumentPublishSuccess,
    DocumentUpdateSuccess,
    DocumentListError,
    DocumentSelectMemberListError,
    FetchError
} from "../../constants";
import {showDocumentModalSpinner, hideDocumentModalSpinner} from "./documents";
import {getSelectMemberList, updateDocument, getDocumentsListAPI} from "../../api/documents"
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getSelectMembersListFromDocumentEdit = (memberID, publicat) => async (dispatch) => {
    dispatch(showDocumentModalSpinner());
    try {
        const response = await getSelectMemberList(memberID, publicat);
        const {data} = response || {};
        const {members} = data || [];
        dispatch({
            type: UPDATE_SELECT_MEMBERS_LIST,
            selectMemberList: members
        })
        dispatch(hideDocumentModalSpinner());
    } catch (error) {
        dispatch(hideDocumentModalSpinner());
        if (error === UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR) {
            toast.error(DocumentSelectMemberListError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const showDocumentEditModal = (selectedDocument) => async (dispatch, getState) => {
    const {
        id,
        name,
        fileextension,
        accessLevel,
        documenttypeid,
        description,
        publicat,
        size
    } = selectedDocument;
    dispatch(getSelectMembersListFromDocumentEdit(id, publicat));
    const {documents} = getState();
    const {categoryList} = documents;
    const documentCategory = categoryList && categoryList.find((item) => item.id === documenttypeid).id;
    dispatch({
        type: UPDATE_SELECTED_DOCUMENT_DATA,
        selectedDocumentData: selectedDocument
    });
    dispatch({
        type: UPDATE_IS_EDIT_DOCUMENT,
        isEditDocument: true
    });
    dispatch({
        type: UPDATE_SELECTED_FILE_NAME,
        selectedFileName: name
    })
    dispatch({
        type: UPDATE_SELECTED_FILE_SIZE,
        selectedFileSize: size
    })
    dispatch({
        type: UPDATE_SELECTED_FILE_EXTENSION,
        selectedFileExtension: fileextension
    })
    dispatch({
        type: UPDATE_DOCUMENT_DESCRIPTION,
        documentDesc: description,
    });
    if (accessLevel === "All Members") {
        dispatch({
            type: UPDATE_SELECT_ALL_MEMBERS,
            isAllMembers: true
        });
        dispatch({
            type: UPDATE_SELECT_MEMBERS,
            isSelectMember: false
        });
    } else {
        dispatch({
            type: UPDATE_SELECT_ALL_MEMBERS,
            isAllMembers: false
        });
        dispatch({
            type: UPDATE_SELECT_MEMBERS,
            isSelectMember: true
        });
    }

    dispatch({
        type: UPDATE_SELECT_MODAL_CATEGORY,
        selectedModalCategory: documentCategory !== "NA" ? documentCategory : 0
    });
};

export const closeDocumentEditModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_IS_EDIT_DOCUMENT,
        isEditDocument: false
    });
    dispatch({
        type: UPDATE_SELECTED_DOCUMENT_DATA,
        selectedDocumentData: null
    });
};

export const onChangeEditDocDesc = (value) => async (dispatch) => {
    if (value !== "") {
        dispatch({
            type: UPDATE_IS_EDIT_DOCUMENT_ERROR,
            documentEditError: false
        });
        dispatch({
            type: UPDATE_DOCUMENT_DESC_ERROR,
            documentDescError: false
        });
    }
    dispatch({
        type: UPDATE_DOCUMENT_DESCRIPTION,
        documentDesc: value,
    });
    dispatch({
        type: UPDATE_DOCUMENT_INPUT_FORM,
        isFormUpdated: true
    });
};

export const handleCloseDocumentEditModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_IS_EDIT_DOCUMENT,
        isEditDocument: false
    });
}


export const handleUpdate = () => async (dispatch, getState) => {
    const {documents} = getState();
    const {selectedDocumentData, documentDesc, selectedModalCategory} = documents;
    const {id} = selectedDocumentData
    if (documentDesc === null || documentDesc === "") {
        dispatch({
            type: UPDATE_IS_EDIT_DOCUMENT_ERROR,
            documentEditError: true
        });
    } else {
        dispatch(showDocumentModalSpinner());
        try {
            const response = await updateDocument({
                documentID: id,
                documentDesc,
                documentCategory: selectedModalCategory
            });
            const documentRecords = await getDocumentsListAPI();
            const {data} = documentRecords;
            const documentList = data && data.map((item) => {
                const {uploadeddate, publicat} = item;
                const formattedUploadDate = uploadeddate ? moment(uploadeddate).format('MM-DD-YYYY').toString()
                    : "";
                return ({
                    ...item,
                    uploadeddate: formattedUploadDate,
                    accessLevel: publicat && publicat.length > 0 ? "All Members" : "Selected Members"
                });
            });
            dispatch({
                type: UPDATE_DOCUMENTS_LIST,
                documentList
            })
            const {success} = response;
            if (success) {
                dispatch(hideDocumentModalSpinner());
                dispatch(handleCloseDocumentEditModal());
                toast.success(DocumentPublishSuccess);
            }
        } catch (error) {
            dispatch(handleCloseDocumentEditModal());
            dispatch(hideDocumentModalSpinner());
            if (error === UPDATE_DOCUMENT_ERROR) {
                toast.error(DocumentUpdateSuccess)
            }
            else if (error === UPDATE_DOCUMENT_LIST_ERROR) {
                toast.error(DocumentListError)
            }
            else {
                toast.error(FetchError)
            }
        }

    }
}
