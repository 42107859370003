import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDATE_PRODUCT_BRAND_LIST,
  UPDATE_PRODUCT_BRAND_LIST_SPINNER,
  UPDATE_PRODUCT_LIST,
  UPDATE_PRODUCT_LIST_SPINNER,
  UPDATE_PRODUCT_BRAND_LIST_ERROR,
  UPDATE_PRODUCT_LIST_ERROR,
  BrandListError,
  ProductListError,
} from "../../constants";

import {getProductBrandList, getProductNameList} from "../../api/products";
import {connect} from "react-redux";

export const getProductBrands = () => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT_BRAND_LIST_SPINNER,
    spinner: true,
  });

  try {
    const brandList = await getProductBrandList();
    dispatch({
      type: UPDATE_PRODUCT_BRAND_LIST,
      list: brandList.data,
    });
    dispatch({
      type: UPDATE_PRODUCT_BRAND_LIST_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_BRAND_LIST_SPINNER,
      spinner: false,
    });

    if (error === UPDATE_PRODUCT_BRAND_LIST_ERROR) {
      toast.error(BrandListError);
    } else {
      toast.error(FetchError);
    }
  }
};
export const getProductNames = (brandId) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PRODUCT_LIST_SPINNER,
      spinner: true,
    });

    const productList = await getProductNameList({brandId});
    dispatch({
      type: UPDATE_PRODUCT_LIST,
      list: productList.data,
    });

    dispatch({
      type: UPDATE_PRODUCT_LIST_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PRODUCT_LIST_SPINNER,
      spinner: false,
    });
    if (error === UPDATE_PRODUCT_LIST_ERROR) {
      toast.error(ProductListError);
    } else {
      toast.error(FetchError);
    }
  }
};
