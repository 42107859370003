import { Grid } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import DefaultUserImg from "../../../../images/profile-placeholder.jpg";
import moment from "moment";
const formatContactNumber = (contactNumber: string) => {
  return contactNumber.replace(/(\d{3})(\d{3})(\d*)/, "($1) $2-$3");
};

export const AnalyticsUserListRow: FC<{
  member: {
    avatar: null | string;
    id: number;
    firstname: null | string;
    lastname: null | string;
    email: null | string;
    members: null | string;
    phone?: string | null;
  }|any;
  send: any;
}> = ({ member, send }) => {
  const { id, firstname, lastname, email, members, phone, avatar,LastLogin,login_attempts,top_pages } = member;

  return (
    <>
      <Grid style={{ cursor: "pointer" }} container spacing={0} className="tr">
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={3}
          container
          direction="row"
          alignItems="center"
        >
          <div className="table-member-details">
            <div className="member-img">
              <img src={avatar !== null ? `/${avatar}` : DefaultUserImg} />
            </div>
            <div className="member-profile-details">
              <div>{`${firstname} ${lastname}`}</div>
              <div className="member-mail-id" style={{ marginTop: "4px" }}>
                <div className="br-number-normal">{email ? email : ""}</div>
              </div>
            </div>
          </div>
        </Grid>
 <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={3}
          container
          direction="row"
          alignItems="center"
        >
          {top_pages && top_pages !== "null" ? top_pages : "-"}
        </Grid> 
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={3}
          container
          direction="row"
          alignItems="center"
        >
          {members && members !== "null" ? members : "-"}
        </Grid>
        {/* <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title="Email">
            <Button className="btn-circle btn-secondary" variant="contained">
              <a
                className="email-link"
                href={`mailto:${email}?subject=Buy Right Application Inquiry for ${members}`}
              >
                <i className="fas fa-envelope" />
              </a>
            </Button>
          </Tooltip>
         
        </Grid> */}
       {/* <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
        >
          {top_pages && top_pages !== "null" ? top_pages : "-"}
      </Grid> */}
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={1}
          container
          direction="row"
          alignItems="center"
        > 
          {login_attempts && login_attempts !== "null" ? login_attempts : "-"}
        </Grid>
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
        >
          {LastLogin ? moment(LastLogin).format('MMM DD YYYY h:mm A') : "-"}
        </Grid>
      </Grid>
    </>
  );
};

export default AnalyticsUserListRow;
2