import {URL_USER_UPDATE, DELETE_VENDOR_USER_ERROR} from '../../constants';

export const deleteVendorUser = async ({
    selectedUser,
    vendorID,
    type
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${URL_USER_UPDATE}${selectedUser.vendorUserId}/?type=${type}`, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            })
        });
        if (!response.ok) throw DELETE_VENDOR_USER_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw DELETE_VENDOR_USER_ERROR;
    }
};