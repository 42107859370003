import { FC, useMemo } from 'react';
import '../../rebates.scss';
import '../../../../scss/styles.scss';
import { useActor } from '@xstate/react';
import { TablePagination } from '@material-ui/core';
import { ActorRefFrom } from 'xstate';
import { PaginationMachine } from '../../../../machines';
import { SearchNotFound } from '../../../../components/SearchNotFound';
import { Loader, useStyles } from '../../../../utils';
import OpportunitiesTableHead from './OpportunitiesTableHead';
import OpportunitiesTableRow from './OpportunitiesTableRow';
import { Opportunity, OpportunityQuarters } from '../../../../common';

export const OpportunitiesTable: FC<{
  showNotesModal: any;
  updateSelectedProduct: any;
  paginationActor: ActorRefFrom<PaginationMachine>;
  opportunityQuarters: OpportunityQuarters;
  selectedOpportunityQuarterId: number;
}> = ({
  showNotesModal,
  updateSelectedProduct,
  opportunityQuarters,
  paginationActor,
  selectedOpportunityQuarterId,
}) => {
  const [state, send] = useActor(
    paginationActor as ActorRefFrom<PaginationMachine>,
  );

  const classes = useStyles();

  const isInprogressStatus = opportunityQuarters?.filter(
    (item: any) => item.status === 'INPROGRESS',
  );

  const showLoader = useMemo(() => {
    return [state.context.records.length === 0, state.hasTag('loading')].every(
      (value) => value,
    );
  }, [state.context.records.length, state.hasTag('loading')]);

  const handleInfoClick = (item: any) => {
    updateSelectedProduct(item);
    showNotesModal(true);
  };

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  if (showLoader) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  }

  if (isInprogressStatus.length > 0 || state.context.records.length <= 0) {
    return <SearchNotFound sectionType="Opportunities" status="INPROGRESS" />;
  }

  const loading = state.hasTag('loading');

  return (
    <div className="table-wrapper" key={selectedOpportunityQuarterId}>
      <div className={classes.root}>
        <OpportunitiesTableHead
          sortOrder={
            state.context.additionalQueryStringValues.sortOrder as
              | 'asc'
              | 'desc'
          }
          setOrderBy={(orderBy: string) => {
            if (state.context.additionalQueryStringValues.orderBy === orderBy) {
              return send({
                type: 'addQueryStringValue',
                key: 'sortOrder',
                value:
                  state.context.additionalQueryStringValues.sortOrder === 'asc'
                    ? 'desc'
                    : 'asc',
              });
            }
            send({
              type: 'addQueryStringValue',
              key: 'orderBy',
              value: orderBy,
            });
          }}
          orderBy={state.context.additionalQueryStringValues.orderBy as string}
        />
        {slicedRecords.length === 0 && loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '20vh',
            }}
          >
            <Loader size={30} />
          </div>
        ) : (
          slicedRecords.map((opportunity: Opportunity) => (
            <OpportunitiesTableRow
              key={opportunity.rebateId}
              opportunity={opportunity}
              loading={loading}
              handleInfoClick={handleInfoClick}
            />
          ))
        )}
      </div>

      <TablePagination
        component="div"
        count={state.context.totalRecords}
        page={state.context.pageNumber - 1}
        onPageChange={(_, newPage) => {
          send({
            type: 'goToPage',
            pageNumber: newPage + 1,
          });
        }}
        rowsPerPage={state.context.pageSize}
        onRowsPerPageChange={(event) =>
          send({
            type: 'updatePageSize',
            pageSize: parseInt(event.target.value),
          })
        }
        rowsPerPageOptions={[10, 20, 50]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} of ${
            count !== -1 ? count.toLocaleString() : `more than ${to}`
          }`;
        }}
      />
    </div>
  );
};

export default OpportunitiesTable;
