import { DateTime } from 'luxon';
import { assign } from '@xstate/immer';
import serviceInterceptor from '../../services/ServiceInterceptor';
import { escalate } from 'xstate/lib/actions';
import {
  DropSizeKPIAgreement,
  DropSizeKPIAgreements,
  IntervalType,
} from '../../common';
import { createMachine } from 'xstate';

export const dropSizeAgreementsMachine = createMachine(
  {
    context: {
      agreements: [],
      selectedAgreementName: null,
      selectedIntervalType: null,
      selectedIntervalValue: null,
      memberFilters: null,
      vendorFilters: null,
      selectedYear: DateTime.now().year,
    },
    tsTypes: {} as import('./dropSizeAgreementsMachine.typegen').Typegen0,
    schema: {
      context: {} as {
        agreements: DropSizeKPIAgreements;
        selectedAgreementName: string | null;
        selectedIntervalType: IntervalType | null;
        selectedIntervalValue: number | null;
        memberFilters: number[] | null;
        vendorFilters: number[] | null;
        selectedYear: number;
      },
      events: {} as
        | {
            type: 'done.invoke.loadAgreements';
            data: {
              data: null | { [key: string]: DropSizeKPIAgreement };
              success: boolean;
            };
            success: boolean;
          }
        | { type: 'error.platform.loadAgreements'; data: any }
        | { type: 'refresh' }
        | { type: 'updateMemberFilters'; memberFilters: number[] }
        | { type: 'updateSelectedMember'; memberId: number }
        | { type: 'updateSelectedIntervalType'; intervalType: IntervalType }
        | { type: 'updateSelectedIntervalValue'; intervalValue: number }
        | { type: 'updateSelectedYear'; year: number },
    },
    id: 'dropSizeAgreements',
    initial: 'loadingAgreements',
    states: {
      loadingAgreements: {
        invoke: {
          src: 'loadAgreements',
          id: 'loadAgreements',
          onDone: [
            {
              actions: 'assignAgreements',
              cond: 'asyncRequestSucceeded',
              target: '#dropSizeAgreements.idle',
            },
            {
              target: '#dropSizeAgreements.error.server',
            },
          ],
          onError: [
            {
              cond: 'noMemberFiltersProvided',
              target: '#dropSizeAgreements.error.fatal',
            },
            {
              target: '#dropSizeAgreements.error',
            },
          ],
        },
        tags: 'loading',
      },
      idle: {},
      error: {
        initial: 'unknown',
        states: {
          unknown: {
            entry: 'escalateUnknownError',
          },
          server: {},
          fatal: {},
        },
      },
    },
    on: {
      updateSelectedMember: {
        actions: ['assignSelectedMemberFilters'],
        target: '#dropSizeAgreements.loadingAgreements',
      },
      updateMemberFilters: {
        actions: 'assignMemberFilters',
        target: '#dropSizeAgreements.loadingAgreements',
      },
      updateSelectedIntervalType: {
        actions: 'assignSelectedIntervalType',
        target: '#dropSizeAgreements.loadingAgreements',
      },
      updateSelectedIntervalValue: {
        actions: 'assignSelectedIntervalValue',
        target: '#dropSizeAgreements.loadingAgreements',
      },
      updateSelectedYear: {
        actions: 'assignSelectedYear',
        cond: 'requestedYearValueIsDifferentThanSelectedYearValue',
        target: '#dropSizeAgreements.loadingAgreements',
      },
    },
  },
  {
    actions: {
      assignAgreements: assign((context, event) => {
        const { data } = event;
        const kpiVendorKeys = Object.keys(data?.data || {});
        const agreements: DropSizeKPIAgreements = [];
        kpiVendorKeys.forEach((key) => {
          if (data?.data?.[key]) {
            agreements.push(data?.data?.[key] as DropSizeKPIAgreement);
          }
        });
        if (
          !(
            agreements.filter(
              (agreement) => agreement.name === context.selectedAgreementName,
            ).length > 0
          )
        ) {
          context.selectedAgreementName = agreements?.[0]?.name ?? null;
        }
        context.agreements = agreements;
      }),
      assignMemberFilters: assign((context, event) => {
        // TODO Obsolete?
        context.memberFilters = event.memberFilters;
      }),
      assignSelectedMemberFilters: assign((context, event) => {
        context.memberFilters = event.memberId
          ? [event.memberId]
          : context.memberFilters;
      }),
      assignSelectedYear: assign((context, event) => {
        if (
          DateTime.now().year !== event.year &&
          !context.selectedIntervalType
        ) {
          context.selectedIntervalType = 'quarter';
          context.selectedIntervalValue = 1;
        }
        context.selectedYear = event.year;
      }),
      assignSelectedIntervalType: assign((context, event) => {
        if (event.intervalType === 'month') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'quarter') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'half-year') {
          context.selectedIntervalValue = 1;
        }
        if (event.intervalType === 'year') {
          context.selectedIntervalValue = null;
        }
        context.selectedIntervalType = event.intervalType;
      }),
      assignSelectedIntervalValue: assign((context, event) => {
        let intervalValue = context.selectedIntervalValue;
        if (context.selectedIntervalType === 'month') {
          let monthValue = event.intervalValue;
          if (monthValue < 1) {
            monthValue = 1;
          }
          if (monthValue > 12) {
            monthValue = 12;
          }
          intervalValue = monthValue;
        }
        if (context.selectedIntervalType === 'quarter') {
          let quarterValue = event.intervalValue;
          if (quarterValue > 4) {
            quarterValue = 4;
          }
          if (quarterValue < 1) {
            quarterValue = 1;
          }
          intervalValue = quarterValue;
        }
        if (context.selectedIntervalType === 'half-year') {
          let halfYearValue = event.intervalValue;
          if (halfYearValue < 1) {
            halfYearValue = 1;
          }
          if (halfYearValue > 2) {
            halfYearValue = 2;
          }
          intervalValue = halfYearValue;
        }
        if (context.selectedIntervalType === 'year') {
          intervalValue = null;
        }
        context.selectedIntervalValue = intervalValue;
      }),
      escalateUnknownError: escalate(() => ({
        message: 'An unknown error occurred.',
      })),
    },
    guards: {
      asyncRequestSucceeded: (_, event) => {
        return event.data?.success;
      },
      noMemberFiltersProvided: (_, event) => {
        return event.data.reason === 'No member filters provided.';
      },
      requestedYearValueIsDifferentThanSelectedYearValue: (context, event) => {
        return context.selectedYear !== event.year;
      },
    },
    services: {
      loadAgreements: (context) => {
        if (!context.memberFilters || context.memberFilters.length <= 0) {
          return Promise.reject({
            reason: 'No member filters provided.',
          });
        }
        return serviceInterceptor
          .get(
            `/members/${context.memberFilters[0]}/kpis/drop-size?interval=${
              context.selectedIntervalType ?? ''
            }&intervalValue=${context.selectedIntervalValue ?? 1}&year=${
              context.selectedYear
            }`,
          )
          .then((result) => result.data)
          .catch((error) => error);
      },
    },
  },
);

export type DropSizeAgreementsMachine = typeof dropSizeAgreementsMachine;

export default dropSizeAgreementsMachine;
