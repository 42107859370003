import { FC } from 'react';
import { CustomModal } from '../../../../../components/CustomModal';
import { AssignNewUserModal } from './AssignNewUserModal';

export const AssignNewUser: FC<any> = ({
  showModal,
  clearVendorUserAssign,
  vendorDCList,
  onChangeVendorUserAssignDC,
  unassignedVendorUsers,
  vendorUserAssign,
  paginationReducer,
  onSelecteAssignUser,
  handleUserRoleChange,
  onAssignVendorUser,
  openCreateNewUserModal,
  clearVendorUserCreateModal,
  send,
}) => {
  const { spinner, errorDistributionCenter, selectedUsers, selectedUsersRole } =
    vendorUserAssign;
  const closeModal = () => {
    clearVendorUserAssign();
  };

  const openCreateNewUser = () => {
    clearVendorUserCreateModal();
    openCreateNewUserModal();
  }

  const inValidRoleID = selectedUsersRole.filter(
    (item: { roleId: string | number | null }) =>
      item.roleId === null || item.roleId === '',
  );
  const isButtonDisabled =
    spinner ||
    errorDistributionCenter ||
    selectedUsers.length === 0 ||
    inValidRoleID.length > 0;

  return (
    <CustomModal
      createNew={{
        entityLabel: 'User',
        onClick: openCreateNewUser,
      }}
      showModal={showModal}
      closeModal={closeModal}
      modalTitle="Assign New Sales Personnel"
      isCancelButton={true}
      isSubmitButton={true}
      isExportButton={false}
      isSubmitText="Assign & Invite User(s)"
      onSubmit={() => {
        onAssignVendorUser();
        setTimeout(() => send({
          type: 'addQueryStringValue',
          key: 'orderBy',
          value: 'id',
        }), 2000);
      }}
      isLoading={spinner}
      isButtonDisabled={isButtonDisabled}
      isEditButton={undefined}
      showNotesEditModal={undefined}
    >
      <AssignNewUserModal
        vendorDCList={vendorDCList}
        onChangeVendorUserAssignDC={onChangeVendorUserAssignDC}
        vendorUserAssign={vendorUserAssign}
        unassignedVendorUsers={unassignedVendorUsers}
        onSelecteAssignUser={onSelecteAssignUser}
        handleUserRoleChange={handleUserRoleChange}
      />
    </CustomModal>
  );
};
