import { FC, useState, useRef, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { MemberRebatesActions } from './MemberRebatesActions';
import { Loader } from '../../../../../../utils';

export const MemberRebatesFilter: FC<{
  searchText: string;
  setSearchText: (searchText: string) => void;
  showMemberRebateModal: () => void;
  rebates: { quarterSpinner: boolean };
  memberRebates: {
    isMemberRebateDraft: boolean;
    memberQuarterList: any[];
    memberActiveQuarter: any;
  };
  showMemberRebateExportModal: () => void;
  handleMemberRebateQuarter: (member: any) => void;
  onPublishMemberRebates: () => void;
  handleMemberEditRebates: () => void;
  setQuarterAndYear: (item: any) => void;
}> = ({
  searchText,
  setSearchText,
  showMemberRebateModal,
  rebates: { quarterSpinner },
  memberRebates: {
    isMemberRebateDraft,
    memberQuarterList,
    memberActiveQuarter,
  },
  showMemberRebateExportModal,
  handleMemberRebateQuarter,
  onPublishMemberRebates,
  handleMemberEditRebates,
  setQuarterAndYear,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  useEffect(() => {
    setQuarterAndYear(memberActiveQuarter);
  }, [memberActiveQuarter]);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef?.current?.contains?.(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSelected = (item: any, event: any) => {
    setQuarterAndYear(item);
    handleMemberRebateQuarter(item);
    handleClose(event);
  };

  const getClassName = (
    status:
      | ''
      | 'UPLOADED'
      | '(Draft)'
      | 'INPROGRESS'
      | '(In Progress)'
      | 'PUBLISHED'
      | '(Published)',
  ) => {
    if (status === 'UPLOADED' || status === '(Draft)')
      return 'details-title-draft';
    if (status === 'INPROGRESS' || status === '(In Progress)')
      return 'details-title-in-progress';
    return 'details-title-published';
  };

  const getLabelStatus = (
    status:
      | ''
      | 'UPLOADED'
      | '(Draft)'
      | 'INPROGRESS'
      | '(In Progress)'
      | 'PUBLISHED'
      | '(Published)',
  ) => {
    if (status === 'INPROGRESS') return '(In Progress)';
    if (status === 'UPLOADED') return '(Draft)';
    if (status === 'PUBLISHED') return '(Published)';
    return '';
  };

  const quarterStatus =
    memberActiveQuarter !== null
      ? getLabelStatus(memberActiveQuarter.status)
      : '';

  let [quarterLabel, yearLabel] = memberActiveQuarter?.label?.split(' ') || [
    '',
    '',
  ]; // TODO temporary label

  switch (quarterLabel) {
    case 'Q1':
    case '1':
    case 1:
      quarterLabel = 1;
      break;
    case 'Q2':
    case '2':
    case 2:
      quarterLabel = 2;
      break;
    case 'Q3':
    case '3':
    case 3:
      quarterLabel = 3;
      break;
    case 'Q4':
    case '4':
    case 4:
      quarterLabel = 4;
      break;
    default:
      break;
  }

  return (
    <div className="rebates-filter">
      <Grid container spacing={3} alignItems="center">
        <Grid
          xs={12}
          lg={4}
          alignItems="center"
          justify="flex-start"
          item
          alignContent="center"
        >
          <div className="header-filter-box-left">
            <div className="br-dropdown-menu">
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : ''}
                aria-haspopup="true"
                onClick={handleToggle}
                disabled={quarterSpinner || memberQuarterList.length === 0}
              >
                {memberQuarterList.length > 0 ? null : (
                  <span className="drop-menu-button-text">Quarter</span>
                )}
                <span className="drop-menu-button-number">
                  <>
                    {memberActiveQuarter !== null && memberActiveQuarter.label}
                  </>
                  <span className={getClassName(quarterStatus)}>
                    {quarterStatus}
                  </span>
                </span>
                {quarterSpinner ? (
                  <div className="category-spinner">
                    <Loader size={15} />
                  </div>
                ) : (
                  <i className="fas fa-chevron-down" />
                )}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={''}
                transition
                disablePortal
                modifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: "viewport",
                  },
                }}
                placement="bottom-start"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {memberQuarterList &&
                          memberQuarterList.map((item) => {
                            const { label, value, status } = item;
                            return (
                              <MenuItem
                                onClick={(event) => handleSelected(item, event)}
                                selected={item === memberActiveQuarter}
                              >
                                <>{value}</>
                                <span className={getClassName(status)}>
                                  {getLabelStatus(status)}
                                </span>
                              </MenuItem>
                            );
                          })}
                      </MenuList>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={8}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className="header-filter-box-search-add">
            <div className="header-filter-box-search">
              <div className="page-search-box">
                <i className="fas fa-search"></i>
                <InputBase
                  placeholder="Search"
                  value={searchText && searchText.toString()}
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </div>
            </div>

            <MemberRebatesActions
              isMemberRebateDraft={isMemberRebateDraft}
              showMemberRebateModal={showMemberRebateModal}
              showMemberRebateExportModal={showMemberRebateExportModal}
              onPublishMemberRebates={onPublishMemberRebates}
              handleMemberEditRebates={handleMemberEditRebates}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
