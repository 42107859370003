import React, {FC, useState} from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import {SearchNotFound} from "../../../components/SearchNotFound";
import {ZipcodeFormat} from "../../../utils/ZipcodeFormat";
import sortDefault from "../../../images/sortDefault.svg";
import sortAscIcon from "../../../images/sortAsc.svg";
import sortDescIcon from "../../../images/sortDesc.svg";
import {Loader, useStyles} from "../../../utils";
import { useLocation } from "react-router-dom";
export const DistributionCenterTable: FC<any> = ({
  gridHeader,
  gridData,
  isEdit,
  isDelete,
  handleDelete,
  editVendorDC,
  setSortKey,
  sortType,
  showEdit,
  showDelete,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: any) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };
  const {pathname} :any = useLocation()
  return (
    <div className={classes.root}>
      {gridData.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const {label, value, xs, lg, sortKey} = item;
            const isValidHeader = label !== "";
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {label === 'Action' ?
                  (<div className={label === 'Action' ? 'action-header' : ''}>
                    <span
                      className="grid-header"
                    >
                      {label}
                    </span>
                  </div>) :
                  (<div className={label === 'Distribution Center ID' ? 'white-space' : ''}>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader ? (
                        sortType === "" ? (
                          <img className="sort-svg" src={sortDefault} />
                        ) : label === activeHeader && sortType === "ASC" ? (
                          <img className="sort-svg" src={sortAscIcon} />
                        ) : label === activeHeader && sortType === "DESC" ? (
                          <img className="sort-svg" src={sortDescIcon} />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      ) : null}
                    </span>
                  </div>)}
              </Grid>
            );
          })}
        </Grid>
      ) : null}
      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const {name, code, street, city, state, zip} = item;
          return (
            <div>
              <Grid container spacing={0} className="tr">
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {name ? name : "-"}
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-number-normal">{code ? code : "-"}</div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={showEdit || showDelete ? 2 : 3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-table-data-ellipse">
                    {street ? street : "-"}
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-table-data-ellipse">
                    {city ? city : "-"}
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={1}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {state ? state : "-"}
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-number-normal">
                    {zip ? ZipcodeFormat(zip) : "-"}
                  </div>
                </Grid>
                <Grid
                  className="td action-col"
                  item
                  xs={12}
                  lg={1}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  {isEdit && showEdit ? (
                    <Tooltip title="Edit">
                      <Button
                        className="btn-circle btn-secondary"
                        variant="contained"
                        onClick={() => editVendorDC(item)}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                    </Tooltip>
                  ) : null}

                  {isDelete && showDelete ? (
                    <Tooltip title="Delete">
                      <Button
                        className="btn-circle-trash"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(item)}
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </Tooltip>
                  ) : null}
                </Grid>
              </Grid>
            </div>
          );
        })
      ) : (
        <SearchNotFound sectionType={"Distribution Centers"} />
      )}
    </div>
  );
};

DistributionCenterTable.propTypes = {
  gridHeader: PropTypes.array,
  gridData: PropTypes.array,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  selectedDcID: PropTypes.number,
  updateDeleteVendorKPI: PropTypes.func,
  editVendorDC: PropTypes.func,
  setSortKey: PropTypes.func,
  sortType: PropTypes.string,
};
