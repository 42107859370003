import {
  baseUrl,
  GET_MEMBER_REBATES_INFO_ERROR,
} from '../../../constants';

export const getMemberRebatesInfoAPI = async ({
  quarter,
  year,
  rebateId,
}) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const url = `${baseUrl}/member-rebate-documents-records/member/${rebateId}?quarter=${quarter}&year=${year}`; // TODO Not even remotely RESTful
    const response = await fetch(url, { // TODO Replace with Axios instance
      headers: new Headers({
        'x-access-token': user.accessToken,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw GET_MEMBER_REBATES_INFO_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw GET_MEMBER_REBATES_INFO_ERROR;
  }
};
