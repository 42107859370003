import {baseUrl, PUBLISH_PRODUCT_REBATES_ERROR} from "../../../constants";


export const publishProductRebates = async ({
    quarter,
    year
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUrl}/rebate-product-list-records/publish?quarter=${quarter}&year=${year}`, {
            method: "PATCH",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw PUBLISH_PRODUCT_REBATES_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw PUBLISH_PRODUCT_REBATES_ERROR;
    }
};
