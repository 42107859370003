import {
  ITEMS_GRID_VIEW,
  UPDATE_MEMBERS_LIST,
  UPDATE_MEMBERS_LIST_SPINNER,
  UPDATE_MEMBERS_MODAL_SPINNER,
  UPDATE_MEMBER_VIEW,
  CLEAR_MEMBER_DETAIL,
  UPDATE_MEMBER_DETAIL,
  UPDATE_MEMBER_USER_SPINNER,
  UPDATE_MEMBER_USER_LIST,
  UPDATE_MEMBER_VENDOR_LIST,
  UPDATE_MEMBER_VENDOR_SPINNER,
  UPDATE_MEMBER_LOCATION_LIST,
  UPDATE_MEMBER_LOCATION_SPINNER,
  UPDATE_MEMBER_DELETE_SPINNER,
  UPDATE_MEMBER_RIDERECT_ON_DELETE,
  UPDATE_MEMBER_SELECTED_LOCATION,
  UPDATE_MEMBER_LOCATION_DELETE_FLAG,
  UPDATE_MEMBER_LOCATION_DELETE_SPINNER,
  UPDATE_MEMBER_USER_DELETE,
  UPDATE_SELECTED_MEMBER_USER,
  GET_MEMBER_LOGIN_ATTEMPT,
  UPDATE_USER_MEMBER_DELETE,
  VIEW_AS_MEMBER,
  GET_ASSIGNED_MEMBER_USERS,
} from '../../constants';

const initialState = {
  type: 2,
  membersList: [],
  memberModalSpinner: false,
  viewType: ITEMS_GRID_VIEW,
  spinner: false,
  selectedMember: null,
  usersList: null,
  userListSpinner: false,
  vendorsList: null,
  vendorListSpinner: false,
  locationsList: null,
  locationListSpinner: false,
  selectedLocation: null,
  isDeleteMemberLocation: false,
  deleteLocationSpinner: false,
  deleteSpinner: false,
  redirectOnDelete: false,
  isMemberUserDelete: false,
  isUserMemberDelete : false,
  selectedUser: null,
  loginAttempt:[],
  assignedUsers:[],
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
  switch (action.type) {
    case UPDATE_MEMBERS_LIST:
      return {
        ...state,
        membersList: action.membersList,
      };
    case UPDATE_MEMBERS_LIST_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case UPDATE_MEMBERS_MODAL_SPINNER:
      return {
        ...state,
        memberModalSpinner: action.memberModalSpinner,
      };
    case UPDATE_MEMBER_VIEW:
      return {
        ...state,
        viewType: action.viewType,
      };
    case UPDATE_MEMBER_DETAIL:
      return {
        ...state,
        selectedMember: action.detail,
      };
    case UPDATE_SELECTED_MEMBER_USER:
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case UPDATE_MEMBER_USER_SPINNER:
      return {
        ...state,
        userListSpinner: action.spinner,
      };
    case UPDATE_MEMBER_USER_LIST:
      return {
        ...state,
        usersList: action.list,
      };
    case UPDATE_MEMBER_VENDOR_SPINNER:
      return {
        ...state,
        vendorListSpinner: action.spinner,
      };
    case UPDATE_MEMBER_VENDOR_LIST:
      return {
        ...state,
        vendorsList: action.list,
      };
    case UPDATE_MEMBER_LOCATION_SPINNER:
      return {
        ...state,
        locationListSpinner: action.spinner,
      };
    case UPDATE_MEMBER_LOCATION_LIST:
      return {
        ...state,
        locationsList: action.list,
      };
    case UPDATE_MEMBER_SELECTED_LOCATION:
      return {
        ...state,
        selectedLocation: action.item,
      };
    case UPDATE_MEMBER_LOCATION_DELETE_FLAG:
      return {
        ...state,
        isDeleteMemberLocation: action.flag,
      };
    case UPDATE_MEMBER_LOCATION_DELETE_SPINNER:
      return {
        ...state,
        deleteLocationSpinner: action.flag,
      }
    case UPDATE_MEMBER_DELETE_SPINNER:
      return {
        ...state,
        deleteSpinner: action.spinner,
      };
    case UPDATE_MEMBER_RIDERECT_ON_DELETE:
      return {
        ...state,
        redirectOnDelete: action.redirect,
      };
    case UPDATE_MEMBER_USER_DELETE:
      return {
        ...state,
        isMemberUserDelete: action.isMemberUserDelete,
      };
    case UPDATE_USER_MEMBER_DELETE :
      return {
        ...state,
        isUserMemberDelete : action.isUserMemberDelete,
      }
    case CLEAR_MEMBER_DETAIL:
      return {
        ...state,
        selectedMember: null,
        usersList: null,
        userListSpinner: false,
        vendorsList: null,
        vendorListSpinner: false,
        locationsList: null,
        locationListSpinner: false,
        deleteSpinner: false,
        redirectOnDelete: false,
        selectedLocation: null,
        isDeleteMemberLocation: false,
        deleteLocationSpinner: false,
        isMemberUserDelete: false,
        assignedUsers : []
      };
    case GET_MEMBER_LOGIN_ATTEMPT:
      return {
        ...state,
        loginAttempt : action.list
      }
    case VIEW_AS_MEMBER:
      if (action?.data?.success) {
        sessionStorage.setItem(
          'user',
          JSON.stringify(action?.data?.data?.user)
        );
      }
      return state;
    case GET_ASSIGNED_MEMBER_USERS:
      return { ...state, assignedUsers: action.payload };
    default:
      return state;
  }
};