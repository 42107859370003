import { FC, useMemo } from 'react';
import DialogWindow from '../../../../../components/DialogWindow';
import './graph.scss';
import { DropSize, PaymentTerms } from '../../../../../components/kpiTools';
import { Loader } from '../../../../../utils';
import { useMachine } from '@xstate/react';
import { membersMachine } from '../../../../../machines';
import AuthorizationService from '../../../../../authorization/AuthorizationService';

export const KpiChartModal: FC<{
  openDialog: boolean;
  closeModal: () => void;
  showAddKPIModal: () => void;
  vendorKPIModal: any;
}> = ({
  openDialog,
  closeModal,
  showAddKPIModal,
  vendorKPIModal,
}) => {
  const [state] = useMachine(membersMachine);

  const selectedMemberName = state.context.members.filter((member) => member.id === vendorKPIModal?.selectedKPIMemberId)?.[0]?.name;

  const assignNewKPIAccess = AuthorizationService.isAuthorizedHTML('assignNewKPI') ? true : false;

  const dialogConfiguration = useMemo(() => ({
    title: `${selectedMemberName ? `${selectedMemberName} - ` : ''}KPI Overviews`,
    isCancel: false,
    submitText: '',
    isCreate: assignNewKPIAccess,
    createText: 'Assign New',
  }), [selectedMemberName])

  return (
    <div className="vendor-assign-content">
      <DialogWindow
        openDialog={openDialog}
        dialogConfiguration={dialogConfiguration}
        handleClose={closeModal}
        handleSubmit={''}
        handleOpenCreate={showAddKPIModal}
      >
        {(state.hasTag('loading') || vendorKPIModal?.selectedKPIMemberId) ? (
          <div className="chart-wrapper">
            <PaymentTerms
              compact
              id="vendor-member-kpi-payment-terms-chart"
              memberId={vendorKPIModal?.selectedKPIMemberId}
            />
            <DropSize
              compact
              id="vendor-member-kpi-drop-size-chart"
              memberId={vendorKPIModal?.selectedKPIMemberId}
            />
          </div>
        ) : (
          <Loader />
        )}
      </DialogWindow>
    </div>
  );
};
