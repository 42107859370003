import { connect } from 'react-redux';
import { ProfileNavBar } from './ProfileNavBar';
import { updateProfileRoute } from '../../../../actions/profile';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';

const mapStateToProps = ({
  user,
  changePasswordForm,
  updateProfileRoute,
}: {
  user: any;
  changePasswordForm: any;
  updateProfileRoute: (
    activeMenu: {
      route: string;
      label: string;
    },
    navigate: NavigateFunction,
  ) => (dispatch: Dispatch) => Promise<void>;
}) => ({
  user,
  changePasswordForm,
  updateProfileRoute,
});

export const ProfileSideNav = connect(mapStateToProps, {
  updateProfileRoute,
})(ProfileNavBar);
