import { createMachine, forwardTo, sendParent } from 'xstate';
import { IntervalType } from '../../../../../../common';
import {
  dropSizeAgreementsMachine,
  membersMachine,
  paymentTermsMachine,
  timeFrameIntervalMachine,
  vendorsMachine,
} from '../../../../../../machines';

export const dashboardKpiMachine = createMachine(
  {
    tsTypes: {} as import('./dashboardKpiMachine.typegen').Typegen0,
    schema: {
      context: {},
      events: {} as
        | { type: 'updateSelectedIntervalType'; intervalType: IntervalType }
        | { type: 'updateSelectedIntervalValue'; intervalValue: number }
        | { type: 'updateSelectedMember'; memberId: number }
        | { type: 'updateSelectedYear'; year: number },
    },
    id: 'dashboardKpi',
    invoke: [
      {
        id: 'dropSizeAgreements',
        src: 'dropSizeAgreements',
      },
      {
        id: 'members',
        src: 'members',
      },
      {
        id: 'paymentTerms',
        src: 'paymentTerms',
      },
      {
        id: 'timeFrameInterval',
        src: 'timeFrameInterval',
      },
      {
        id: 'vendors',
        src: 'vendors',
      },
    ],
    on: {
      updateSelectedIntervalType: {
        actions: [
          'forwardEventToDropSizeAgreements',
          'forwardEventToPaymentTerms',
        ],
      },
      updateSelectedIntervalValue: {
        actions: [
          'forwardEventToDropSizeAgreements',
          'forwardEventToPaymentTerms',
        ],
      },
      updateSelectedMember: {
        actions: [
          'forwardEventToDropSizeAgreements',
          'forwardEventToPaymentTerms',
        ],
      },
      updateSelectedYear: {
        actions: [
          'forwardEventToDropSizeAgreements',
          'forwardEventToPaymentTerms',
        ],
      },
    },
  },
  {
    actions: {
      forwardEventToDropSizeAgreements: forwardTo('dropSizeAgreements'),
      forwardEventToPaymentTerms: forwardTo('paymentTerms'),
    },
    services: {
      dropSizeAgreements: dropSizeAgreementsMachine,
      members: membersMachine.withConfig({
        actions: {
          sendParentSelectedMember: sendParent((context) => ({
            memberId: context.selectedMember?.id ?? null,
            type: 'updateSelectedMember',
          })),
        },
      }),
      paymentTerms: paymentTermsMachine,
      timeFrameInterval: timeFrameIntervalMachine,
      vendors: vendorsMachine,
    },
  },
);

export type DashboardKpiMachine = typeof dashboardKpiMachine;

export default dashboardKpiMachine;
