import { FC, useMemo } from 'react';
import { SearchNotFound } from '../../../../components/SearchNotFound';
import { CommunityMembersListRow } from './CommunityMembersListRow';
import CommunityMembersListHeaderRow from './CommunityMembersListHeaderRow';
import { TablePagination } from '@material-ui/core';
import { Loader } from '../../../../utils';

interface CommunityMemberListProps {
  membersList: any;
  state: any;
  send: any;
}

export const CommunityMemberList: FC<CommunityMemberListProps> = ({
  membersList,
  state,
  send,
}) => {

  const showLoader = useMemo(() => {
    return [state.context.records.length === 0, state.hasTag('loading')].every(
      (value) => value,
    );
  }, [state.context.records.length, state.hasTag('loading')]);

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  const loading = state.hasTag('loading');

  if (showLoader) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  }

  if (membersList && membersList.length === 0) {
    return <SearchNotFound sectionType={'Members'} />;
  } else {
    return (
      <div className="table-wrapper">
        <CommunityMembersListHeaderRow
          sortOrder={
            state.context.additionalQueryStringValues.sortOrder as
            | 'asc'
            | 'desc'
          }
          setOrderBy={(orderBy: string) => {
            if (state.context.additionalQueryStringValues.orderBy === orderBy) {
              return send({
                type: 'addQueryStringValue',
                key: 'sortOrder',
                value:
                  state.context.additionalQueryStringValues.sortOrder === 'asc'
                    ? 'desc'
                    : 'asc',
              });
            }
            send({
              type: 'addQueryStringValue',
              key: 'orderBy',
              value: orderBy,
            });
          }}
          orderBy={state.context.additionalQueryStringValues.orderBy as string}
        />
        {slicedRecords.length === 0 && loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '20vh',
            }}
          >
            <Loader size={30} />
          </div>
        ) : (
          slicedRecords.map((member: any) => {
            return (
              <CommunityMembersListRow
                key={member.id}
                member={member}
              />
            );
          })
        )}

        <TablePagination
          component="div"
          count={state.context.totalRecords}
          page={state.context.pageNumber - 1}
          onPageChange={(_, newPage) => {
            send({
              type: 'goToPage',
              pageNumber: newPage + 1,
            })
          }
          }
          rowsPerPage={state.context.pageSize}
          onRowsPerPageChange={(event) =>
            send({
              type: 'updatePageSize',
              pageSize: parseInt(event.target.value),
            })
          }
          rowsPerPageOptions={[10, 15, 25, 50]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`
              }`;
          }}
        />
      </div>
    );
  }
};
