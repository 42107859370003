import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { ConnectedPagination } from "../../../../components/ConnectedPagination";
import { MemberTable } from "../../tableUtils/memberTable";
import { WarningPopup, getPaginatedData } from "../../../../utils";
import AuthorizationService from "../../../../authorization/AuthorizationService";
import "../../vendors.scss";


const gridHeader = [
  {
    label: "Name",
    value: "name",
    sortKey: "name",
    xs: 12,
    lg: 3,
  },
  {
    label: "Sales Personnel",
    value: "salesPersonFirsttName",
    sortKey: "salesPersonFirsttName",
    xs: 12,
    lg: 2,
  },
  {
    label: "Sales Mobile Phone",
    value: "mobile",
    sortKey: "mobile",
    xs: 12,
    lg: 2,
  },
  {
    label: "Location",
    value: "location",
    sortKey: "street",
    xs: 12,
    lg: 3,
  },
  {
    label: 'Action',
    value: 'action',
    xs: 12,
    lg: 2
  }
];

const showDelete = AuthorizationService.isAuthorizedHTML("VendorMemberDeleteAction")

export const MemberList: FC<any> = ({
  updateDeleteVendorMembers,
  isDeleteUser,
  handleDeleteClose,
  handleDeleteVendorMember,
  setKPIChartModal,
  updateSelectedKPIMember,
  getKPIYearQuarter,
  getKpiYears,
  showEditVendorMember,
  user,
  state,
  send,
}) => {
  const [deleteUser, setDeleteUser] = useState(null);

  const handleDelete = (item: any) => {
    const name = item && item.name
    setDeleteUser(name)
    updateDeleteVendorMembers(item.vendorMemberId);
  }

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  return (
    <div>
      <MemberTable
        gridHeader={gridHeader}
        gridData={slicedRecords ? slicedRecords : []}
        handleDelete={handleDelete}
        setKPIChartModal={setKPIChartModal}
        updateSelectedKPIMember={updateSelectedKPIMember}
        getKpiYears={getKpiYears}
        showEditVendorMember={showEditVendorMember}
        showDelete={showDelete}
        getKPIYearQuarter={getKPIYearQuarter}
        user={user}
        state={state}
        send={send}
      />


      <WarningPopup
        open={isDeleteUser}
        type={"delete"}
        name={deleteUser}
        handleProceed={() => {
          handleDeleteVendorMember();
          setTimeout(() => send({
            type: 'addQueryStringValue',
            key: 'orderBy',
            value: 'id',
          }), 1000);
        }
        }
        handleClose={handleDeleteClose}
      />
    </div>
  );
};

MemberList.propTypes = {
  vendorKpiList: PropTypes.array,
  isDeleteUser: PropTypes.bool,
  updateDeleteVendorMembers: PropTypes.func,
  handleDeleteClose: PropTypes.func,
  handleDeleteVendorMember: PropTypes.func,
  searchText: PropTypes.string,
};
