import { Grid, GridSize } from '@material-ui/core';
import { FC, useState } from 'react';
import sortDefault from '../../../../images/sortDefault.svg'
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';

const gridHeader = [
  {
    label: 'Account Nick Name',
    value: 'accountNickName',
    sortKey: 'accountNickName',
    xs: 12,
    lg: 5,
  },
  {
    label: 'Account Type',
    value: 'bankAccountType',
    sortKey: 'bankAccountType',
    xs: 12,
    lg: 5,
  },
  {
    label: 'Action',
    value: 'action',
    xs: 12,
    lg: 2,
  },
];

export const FundTransferHeaderRow: FC<{
  setSortKey: (newSortKey: any) => void;
  sortType: string;
}> = ({ setSortKey, sortType }) => {

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: any) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  return (<Grid container spacing={0} className="tr thead">
    {gridHeader.map((item, index) => {
      const { label, value, xs, lg, sortKey } = item;
      const isValidHeader = (label !== '' && (sortKey !== '' || sortKey !== undefined));

      return (
        <Grid
          className="th"
          item
          xs={xs as GridSize}
          lg={lg as GridSize}
          key={index}
        >
          <div className={label === 'Action' ? 'action-header' : ''}>
            <span
              className="grid-header"
              onClick={(event) => handleClick(event, sortKey)}
            >
              {label}
            </span>
            {value !== 'action' && (
              <span className="sort-icon">
                {isValidHeader && (
                  sortType === '' ?
                    <img className="sort-svg" src={sortDefault} /> : (label === activeHeader && sortType === 'ASC') ?
                      <img className="sort-svg" src={sortAscIcon} /> : (label === activeHeader && sortType === 'DESC') ?
                        <img className="sort-svg" src={sortDescIcon} /> : <img className="sort-svg" src={sortDefault} />
                )}
              </span>
            )}
          </div>
        </Grid>
      );
    })}
  </Grid>
  );
};

export default FundTransferHeaderRow;
