import { FC, useEffect } from 'react';
import { ProfileSideNav } from '../../components/sideNav';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Loader } from '../../../../utils';
import { useSelector } from 'react-redux';
interface PasswordSettingsProps {
    clearChangePassword: () => void;
    onChangeOldPassword: (oldPassword: string) => void;
    onChangeNewPassword: (newPassword: string) => void;
    onChangeConfirmPassword: (confirmPassword: string) => void;
    handleChangePasswordShow: (elementKey: string) => void;
    onSubmitChangePassword: () => Promise<void>;
    changePasswordForm: {
      isLoading: boolean;
      oldPassword: string;
      newPassword: string;
      confirmPassword: string;
      errorOldPassword: any;
      errorNewPassword: any;
      errorConfirmPassword: any;
      messageForNewPWD: string;
      messageForConfirmPWD: string;
      passwordShowStatus: {
        confirmPassword: boolean;
        newPassword: boolean;
        oldPassword: boolean;
      };
      isRedirect: boolean;
    },
  }

export const PasswordSettings: FC<PasswordSettingsProps> = (props) => {
  const {
    clearChangePassword,
    onChangeOldPassword,
    onChangeNewPassword,
    onChangeConfirmPassword,
    handleChangePasswordShow,
    onSubmitChangePassword,
    changePasswordForm: {
      isLoading,
      oldPassword,
      newPassword,
      confirmPassword,
      errorOldPassword,
      errorNewPassword,
      errorConfirmPassword,
      messageForNewPWD,
      messageForConfirmPWD,
      passwordShowStatus,
      isRedirect,
    },
  } = props;
  const navigate = useNavigate();
  useEffect(() => {
    return () => {
      clearChangePassword();
    };
  }, []);
  if (isRedirect && isRedirect == true) {
    navigate('/dashboard');
  }
  const store = useSelector((state) => state)

  const {user}:any = store
  return (
    <div className="body-container">
      <Grid container className="change-password">
        <ProfileSideNav {...props} />
        <Grid item xs={12} lg={9} className="change-password-right">
          <div className="br-form-element">
            <Typography variant="h5" style={{ marginBottom: '1em' }}>
              Update Password
            </Typography>
            <div className="relative-wrapper">
              {isLoading ? (
                <div className="loader-wrapper list-spinner">
                  <div className="document-loader">
                    <Loader size={40} />
                  </div>
                </div>
              ) : null}
              <Grid container spacing={5}>
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <div className="input-with-icons w-50 pos-50">
                    <TextField
                      label="Old Password"
                      variant="outlined"
                      className="text-box"
                      type={
                        passwordShowStatus.oldPassword ? 'text' : 'password'
                      }
                      name="oldPassword"
                      value={oldPassword}
                      onChange={(e) => onChangeOldPassword(e.target.value)}
                      required
                      id={
                        errorOldPassword ? 'standard-error' : 'outlined-basic'
                      }
                      error={errorOldPassword}
                      helperText={
                        errorOldPassword ? 'Please enter old password' : ''
                      }
                      disabled={user?.isViewAsMember}
                    />
                    <i
                      className={
                        passwordShowStatus.oldPassword
                          ? 'fa fa-fw fa-eye field-icon'
                          : 'fa fa-fw fa-eye-slash field-icon'
                      }
                      onClick={() => handleChangePasswordShow('oldPassword')}
                    ></i>
                  </div>
                </Grid>

                <Grid item xs={12} lg={6} md={6} sm={6}>
                  <div className="input-with-icons">
                    <TextField
                      label="New Password"
                      variant="outlined"
                      className="text-box"
                      type={
                        passwordShowStatus.newPassword ? 'text' : 'password'
                      }
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => onChangeNewPassword(e.target.value)}
                      onBlur={(e) => onChangeNewPassword(e.target.value)}
                      autoComplete="off"
                      required
                      id={
                        errorNewPassword ? 'standard-error' : 'outlined-basic'
                      }
                      error={errorNewPassword}
                      helperText={errorNewPassword && messageForNewPWD}
                      disabled={user?.isViewAsMember}
                    />
                    <i
                      className={
                        passwordShowStatus.newPassword
                          ? 'fa fa-fw fa-eye field-icon'
                          : 'fa fa-fw fa-eye-slash field-icon'
                      }
                      onClick={() => handleChangePasswordShow('newPassword')}
                    ></i>
                  </div>
                </Grid>
                <Grid item xs={12} lg={6} md={6} sm={6}>
                  <div className="input-with-icons">
                    <TextField
                      label="Confirm Password"
                      variant="outlined"
                      className="text-box"
                      type={
                        passwordShowStatus.confirmPassword ? 'text' : 'password'
                      }
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => onChangeConfirmPassword(e.target.value)}
                      onBlur={(e) => onChangeConfirmPassword(e.target.value)}
                      autoComplete="off"
                      required
                      id={
                        errorConfirmPassword
                          ? 'standard-error'
                          : 'outlined-basic'
                      }
                      error={errorConfirmPassword}
                      helperText={
                        errorConfirmPassword ? messageForConfirmPWD : ''
                      }
                      disabled={user?.isViewAsMember}
                    />
                    <i
                      className={
                        passwordShowStatus.confirmPassword
                          ? 'fa fa-fw fa-eye field-icon'
                          : 'fa fa-fw fa-eye-slash field-icon'
                      }
                      onClick={() =>
                        handleChangePasswordShow('confirmPassword')
                      }
                    />
                  </div>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    className="button-cancel btn-mobile"
                    onClick={() => navigate('/dashboard')}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="button-save btn-mobile"
                    onClick={() => onSubmitChangePassword()}
                    disabled={user?.isViewAsMember}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
