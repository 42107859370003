import { FC, useState } from 'react';
import Button from '@material-ui/core/Button';
import { Loader } from '../../../utils';
import '../community.scss';

export const ChannelList: FC<{
  communityChannel: any;
  searchText: any;
  updateQuestionDetail: any;
  updateActiveDiscussionData: any;
  editCommunityCategory: any;
  getChannelDiscussion: any;
  updatePageNumber: any;
  setDetailedDiscussion: any;
  user: any;
}> = ({
  communityChannel: { communityCategory, categorySpinner },
  searchText,
  updateQuestionDetail,
  updateActiveDiscussionData,
  editCommunityCategory,
  getChannelDiscussion,
  updatePageNumber,
  setDetailedDiscussion,
  user: { isBRAdmin },
}) => {
  const filteredData =
    communityCategory.length > 0
      ? communityCategory.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : [];

  const initialValue = filteredData.length > 0 ? filteredData[0].id : 0;
  const [activeIndex, setActiveIndex] = useState(initialValue);

  const handleChannelChange = (id: any) => {
    setActiveIndex(id);
    setDetailedDiscussion(true);
    getChannelDiscussion(id);
    updateQuestionDetail(false);
    updateActiveDiscussionData(null);
    updatePageNumber(1);
  };

  return (
    <div className="community-channel-list">
      {categorySpinner ? (
        <div className="section-spinner">
          <Loader size={20} />
        </div>
      ) : filteredData && filteredData.length > 0 ? (
        filteredData.map((item: any, index: number) => {
          const { id, name, description } = item;
          return (
            <div
              onClick={() => handleChannelChange(id)}
              className={
                id === activeIndex
                  ? 'community-channel-list-box community-channel-active'
                  : 'community-channel-list-box'
              }
            >
              <h2>{name}</h2>
              <p>{description}</p>
              {isBRAdmin && (
                <div className="community-channel-action-buttons">
                  <Button
                    className="btn-channel-edit"
                    variant="outlined"
                    onClick={() => editCommunityCategory(item)}
                  >
                    <i className="fas fa-pen" />
                  </Button>
                </div>
              )}
            </div>
          );
        })
      ) : (
        <div className="channel-notfound">
          <div>No Channel found</div>
        </div>
      )}
    </div>
  );
};
