import { VendorUserCreateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const createVendorUser = async ({
  vendorID,
  type,
  firstName,
  lastName,
  email,
  phone,
  selectedRole,
  jobTitle,
  selectedDC,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('vendorid', vendorID);
    bodyFormData.append('type', type);
    bodyFormData.append('firstname', firstName);
    bodyFormData.append('lastname', lastName);
    bodyFormData.append('email', email);
    bodyFormData.append('phone', phone);
    bodyFormData.append('roleid', selectedRole);
    bodyFormData.append('jobtitle', jobTitle);
    bodyFormData.append('distributionId', selectedDC);

    const response = await serviceInterceptor.post('/users', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response?.data?.success) {
      throw VendorUserCreateError;
    }
    return response.data;
  } catch (error) {
    throw VendorUserCreateError;
  }
};
