import { FC, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthorizationService from '../../../authorization/AuthorizationService';
import { formatPhoneNumber } from '../../../utils';
import MemberImg from '../../../images/member-grid-placeholder.jpg';
import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  img: {
    objectFit: 'contain',
  },
}));

export const MemberGridCard: FC<{
  member: {
    avatar: null | string;
    id: number | any;
    name: null | string;
    city: string;
    contactfirstname?: string | null;
    contactlastname?: string | null;
    phone?: string | null;
    state?: string | null;
    childCount: number;
    canHandDeliver: boolean;
  };
  statesList: { abbreviation: string; name: string }[];
  pageNumber:number;
  pageSize:number;
}> = ({ member, statesList, pageNumber, pageSize}) => {
  const {
    id,
    name,
    city,
    state,
    contactfirstname,
    contactlastname,
    phone,
    avatar,
    childCount,
    canHandDeliver,
  } = member ?? {};

  const tempFormattedAvatar =
    avatar?.substring(0, 1) === '/' ? avatar : `/${avatar}`; // TODO this forces old style to still work
  const [nav, setNav] = useState('none');
  const [imageBroken, setImageBroken] = useState(false);
  const cardImg = !avatar || imageBroken ? MemberImg : tempFormattedAvatar;
  const stateName =
    statesList?.find((item: any) => item.abbreviation === state)?.name ?? null;

  const classes = useStyles();
  const location:any = useLocation()
  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      {nav === 'view' && (
        <Navigate
          to={`/member/view/${id}/`}
          state={
            { 
              detail: member, 
              isFromMemberTab: true,
              pageNumber : pageNumber,
              pageSize:pageSize,
              path : '/members' 
            }
          }
        />
      )}
      {nav === 'edit' && (
        <Navigate
          to={`/members/edit/${id}/`}
          state={{
            detail: member,
            navigateFrom: 'LIST',
            pageType: 'EDIT',
            isFromMemberTab: true,
            pageNumber : pageNumber,
            pageSize:pageSize,
            path : '/members' 
          }}
        />
      )}
      <Card className="card-member-box">
        <CardActionArea>
          <div className="card-media-img">
            <CardMedia
              className={classes.img}
              onError={() => {
                setImageBroken(true);
              }}
              component="img"
              height="140"
              image={cardImg}
              alt={name || ''}
              title={name || ''}
              onClick={() => setNav('view')}
            />
            <CardActions className="members-actions">
              {AuthorizationService.isAuthorizedHTML('editmemberbutton') && (
                <Tooltip title="Edit">
                  <Button onClick={() => setNav('edit')}>
                    <i className="fas fa-pen" />
                  </Button>
                </Tooltip>
              )}
            </CardActions>
          </div>
          <CardContent
            onClick={() => {
              setNav('view');
            }}
          >
            <Typography
              gutterBottom
              variant="h5"
              component="h2"
              className="member-title"
            >
              {name} {childCount > 0 && <i className="fa fa-users" />}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className="member-place"
            >
              {city}, {stateName}
            </Typography>
            <Typography
              gutterBottom
              variant="h5"
              component="h3"
              className="member-name"
            >
              {[contactfirstname, contactlastname]
                .filter((name) => typeof name === 'string')
                .join(' ')}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              component="p"
              className="member-contact"
            >
              {formatPhoneNumber(phone)}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

export default MemberGridCard;
