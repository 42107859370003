import { FC, useState } from 'react';
import DefaultUserImg from '../../../images/profile-placeholder.jpg';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { formatPhoneNumber, useStyles } from '../../../utils';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import { VendorUser, VendorUsers } from '../../../constants/vendors';
import { Button, Grid, GridSize, Tooltip } from '@material-ui/core';
import { StringAvatar } from "../../../utils/AvatarInitial";
import Avatar from '@mui/material/Avatar';
import { Loader } from '../../../utils';
import { useLocation } from "react-router-dom";

export const UserTable: FC<{
  gridData: VendorUsers;
  gridHeader: {
    label: string;
    value: any;
    xs: boolean | GridSize | undefined;
    lg: boolean | GridSize | undefined;
    sortKey?: string;
  }[];
  handleDelete: (vendorUser: VendorUser) => void;
  isDelete: boolean;
  isEdit: boolean;
  showDelete: boolean;
  showEdit: boolean;
  showVendorUser: (vendorUser: VendorUser) => void;
  user: any;
  hasActionPermission: boolean;
  state: any;
  send: any;
}> = ({
  gridHeader,
  gridData,
  isEdit,
  isDelete,
  handleDelete,
  showVendorUser,
  showEdit,
  showDelete,
  user: { isBRAdmin },
  hasActionPermission,
  state,
  send,
}) => {
    const classes = useStyles();
    const {pathname} :any = useLocation()
    const orderBy = state.context.additionalQueryStringValues.orderBy as string;
    const sortOrder = state.context.additionalQueryStringValues.sortOrder as
      | 'asc'
      | 'desc';
    const setOrderBy = (orderBy: string) => {
      if (state.context.additionalQueryStringValues.orderBy === orderBy) {
        return send({
          type: 'addQueryStringValue',
          key: 'sortOrder',
          value:
            state.context.additionalQueryStringValues.sortOrder === 'asc'
              ? 'desc'
              : 'asc',
        });
      }
      send({
        type: 'addQueryStringValue',
        key: 'orderBy',
        value: orderBy,
      });
    };

    return (
      <div className={classes.root}>
        {gridData.length > 0 && (
          <Grid container spacing={0} className="tr thead grid-header">
            {gridHeader.map((item) => {
              const { label, xs, lg, sortKey } = item;
              const isValidHeader = label !== '';
              return (
                <Grid className="th" item xs={xs} lg={lg}>
                  {label === 'Action' ? (
                    <div className={label === 'Action' ? 'action-header' : ''}>
                      <span className="grid-header">{label}</span>
                    </div>
                  ) : (
                    <div onClick={() => sortKey && setOrderBy(sortKey)}>
                      <span className="grid-header">{label}</span>
                      <span className="sort-icon">
                        {isValidHeader && (sortKey === orderBy ? (
                          <img
                            className="sort-svg"
                            src={
                              sortOrder === 'asc' ? sortAscIcon : sortDescIcon
                            }
                          />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        ))}
                      </span>
                    </div>
                  )}
                </Grid>
              );
            })}
          </Grid>
        )}
        {gridData.length > 0 ? (
          gridData.map((vendorUser) => {
            const {
              avatar,
              firstName,
              lastName,
              userName,
              email,
              distributionName,
              jobTitle,
              phone,
            } = vendorUser;
            return (
              <Grid container spacing={0} className="tr">
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="table-member-details">
                    <div className="member-img">
                      {avatar && avatar.length > 0 ? (
                        <img src={avatar} alt="user" />)
                        : ((userName && userName !== undefined && userName?.toString()?.length > 0) ? (
                          <Avatar {...StringAvatar(userName.toString())} />) : (
                          <img src={DefaultUserImg} alt="user" />)
                        )}
                    </div>
                    <div className="member-profile-details">
                      <div className="member-name">{userName}</div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {jobTitle ? jobTitle : '-'}
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-table-data-ellipse">{email}</div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-table-data-ellipse">
                    {distributionName ? distributionName : '-'}
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-number-normal">
                    {phone && phone !== 'null' ? (
                      <a className="phone-link" href={`tel:${phone}`}>
                        {formatPhoneNumber(phone)}
                      </a>
                    ) : (
                      '-'
                    )}
                  </div>
                </Grid>
                {
                  <Grid
                    className="td action-col"
                    item
                    xs={12}
                    lg={2}
                    container
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    {hasActionPermission ? (
                      <Tooltip title="Email">
                        <Button
                          className="btn-circle btn-secondary"
                          variant="contained"
                        >
                          <a
                            className="email-link"
                            href={`mailto:${email}?subject=Buy Right Application Inquiry for Performance Foodservice`}
                          >
                            <i className="fas fa-envelope" />
                          </a>
                        </Button>
                      </Tooltip>
                    ) : null}
                    {isBRAdmin && isEdit && showEdit ? (
                      <Tooltip title="Edit">
                        <Button
                          className="btn-circle btn-secondary"
                          variant="contained"
                          onClick={() => showVendorUser(vendorUser)}
                        >
                          <i className="fas fa-pen" />
                        </Button>
                      </Tooltip>
                    ) : null}

                    {isBRAdmin && isDelete && showDelete ? (
                      <Tooltip title="Unassign">
                        <Button
                          className="btn-circle-trash"
                          variant="outlined"
                          color="secondary"
                          onClick={() => handleDelete(vendorUser)}
                        >
                          <i className="fas fa-times" />
                        </Button>
                      </Tooltip>
                    ) : null}
                  </Grid>
                }
              </Grid>
            );
          })
        ) : (
          <SearchNotFound sectionType={'Sales Personnel'} />
        )}
      </div>
    );
  };
