import {
  GET_MEMBER_REBATES_QUARTER_LIST,
  GET_MEMBER_REBATES_EDIT_ERROR,
} from '../../../constants';

export const getMemberRebatesEditData = async ({ year, quarter }) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const url = `${GET_MEMBER_REBATES_QUARTER_LIST}/info?year=${year}&quarter=${quarter}`;
    const response = await fetch(url, {
      headers: new Headers({
        'x-access-token': user.accessToken,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw GET_MEMBER_REBATES_EDIT_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw GET_MEMBER_REBATES_EDIT_ERROR;
  }
};
