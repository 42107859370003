import { VendorKPIEditError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const updateVendorKPI = async ({
  agreementId,
  kpiId,
  kpiDate,
  memberId,
  comments,
  deactivateDate,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('kpiId', kpiId);
    bodyFormData.append('kpiDate', kpiDate);
    bodyFormData.append('comment', comments);
    bodyFormData.append(
      'deactivateDate',
      deactivateDate ? deactivateDate : null,
    );

    const response = await serviceInterceptor.patch(
      `/members/${memberId}/kpi-agreements/${agreementId}`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );

    if (!response?.data?.success) {
      throw VendorKPIEditError;
    }
    return response.data;
  } catch (error) {
    throw VendorKPIEditError;
  }
};
