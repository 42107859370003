import { FC } from 'react';
import InputBase from '@material-ui/core/InputBase';

export const Search: FC<{
  searchText: string;
  setSearchText: (searchText: string) => void;
  handleChangePage: (event: any, page: number) => void;
}> = ({ searchText, setSearchText, handleChangePage }) => {
  const onChangeSearchText = (event: any) => {
    handleChangePage(event, 0);
    setSearchText(event.target.value);
  };

  return (
    <div className="header-filter-box-search">
      <div className="page-search-box">
        <i className="fas fa-search" />
        <InputBase
          placeholder="Search"
          value={searchText && searchText.toString()}
          onChange={(event) => onChangeSearchText(event)}
        />
      </div>
    </div>
  );
};

export default Search;
