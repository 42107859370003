import { Button } from '@material-ui/core';
import { FC } from 'react';

export const OpportunitiesActionButton: FC<{
  show?: boolean;
  buttonClassName?: string;
  buttonText?: string;
  iconClassName?: string;
  onClick?: () => void;
}> = ({ show, buttonClassName, buttonText, iconClassName, onClick }) => {
  if (!show) return null;
  return (
    <div className="header-filter-box-add">
      <Button
        onClick={onClick}
        variant="contained"
        size="small"
        className={buttonClassName}
      >
        <span className={iconClassName}></span>
        {buttonText && <span>{buttonText}</span>}
      </Button>
    </div>
  );
};

export default OpportunitiesActionButton;
