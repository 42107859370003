import {
    UPDATE_REBATES_MODAL_SPINNER,
    UPDATE_REBATES_ACTIVE_TAB,
    UPDATE_PRODUCT_REBATE_MODAL,
    UPDATE_PRODUCT_REBATES_QUARTER_LIST,
    UPDATE_MEMBER_REBATE_MODAL,
    UPDATE_REBATE_QUARTER_SPINNER,
    UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER,
    UPDATE_MEMBER_REBATES_EXPORT_MODAL,
    UPDATE_PUBLISH_SPINNER,
    UPDATE_PRODUCT_REBATES_QUARTER,
    UPDATE_PRODUCT_REBATES_YEAR,
    UPDATE_PRODUCT_REBATES_NOTES,
    UPDATE_PRODUCT_REBATES_NOTES_ERROR,
    UPDATE_SELECTED_PRODUCT,
    UPDATE_PRODUCT_REBATES_DATE_RANGE,
    UPDATE_PRODUCT_REBATE_NOTES,
    UPDATE_PRODUCT_REBATE_NOTES_EDIT,
    UPDATE_PRODUCT_REBATES_EXPORT_MODAL,
    UPDATE_PRODUCT_REBATES_EXPORT_QUARTER,
    UPDATE_PRODUCT_REBATES_EXPORT_YEAR,
    CLEAR_PRODUCT_REBATES_NOTES_MODAL,
    CLEAR_PRODUCT_REBATES_EXPORT_MODAL,
    PRODUCT_REBATES_QUARTER_ERROR,
    GET_PRODUCT_REBATES_INFO_ERROR,
    UPDATE_PRODUCT_REBATES_INFO,
    UPDATE_PRODUCT_REBATES_EDIT_FLAG,
    UPDATE_PRODUCT_REBATES_FILE_NAME,
    UPDATE_PRODUCT_REBATES_FILE_SIZE,
    UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
    UpdateNotesError,
    ProductRebatesQuarterError,
    FetchError,
    getProductRebatesInfoError
} from "../../constants";

import {getProductRebatesQuarters, updateProductRebatesNotesAPI, getProductRebatesInfo} from "../../api/rebates";
import {handleProductRebateQuarter} from "./productRebates";
import {getEditFileName, getFileSize, getEditFileExtension} from "../../utils";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const rebatesModalSpinner = (rebatesModalSpinner) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATES_MODAL_SPINNER,
        rebatesModalSpinner
    });
};

export const updateActiveTab = (selectedTab) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATES_ACTIVE_TAB,
        activeTab: selectedTab
    });
};

export const showProductRebateModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATE_MODAL,
        isProductRebateModal: true
    });
};

export const showMemberRebateModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATE_MODAL,
        isMemberRebateModal: true
    });
};

export const hideMemberRebateModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATE_MODAL,
        isMemberRebateModal: false
    });
};

export const updatePublishSpinner = (publishSpinner) => async (dispatch) => {
    dispatch({
        type: UPDATE_PUBLISH_SPINNER,
        publishSpinner
    });
};

export const hideProductRebateModal = () => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATE_MODAL,
        isProductRebateModal: false
    });
    dispatch(updateProductRebatesEditFlag(false))
};

export const updateRebatesQuarterSpinner = (quarterSpinner) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATE_QUARTER_SPINNER,
        quarterSpinner
    });
};

export const showProductRebateExportModal = (isProductRebateExportModal) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATES_EXPORT_MODAL,
        isProductRebateExportModal
    });
};

export const showMemberRebateExportModal = (isMemberRebateExportModal) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_EXPORT_MODAL,
        isMemberRebateExportModal
    });
};


export const showNotesModal = (isNotesModal) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATE_NOTES,
        isNotesModal
    });
};

export const showNotesEditModal = (isNotesEditModal) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATE_NOTES_EDIT,
        isNotesEditModal
    });
};


export const updateSelectedProduct = (selectedProduct) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_PRODUCT,
        selectedProduct
    });
}

export const handleNotesChange = (value) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATES_NOTES,
        notes: value
    });
}

export const handleExportQuarter = (value) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATES_EXPORT_QUARTER,
        exportQuarter: value
    });
}

export const handleExportYear = (value) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATES_EXPORT_YEAR,
        exportYear: value
    });
}

export const closeRebateNotesModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_PRODUCT_REBATES_NOTES_MODAL,
    });
};

export const closeRebateExportModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_PRODUCT_REBATES_EXPORT_MODAL,
    });
};

export const updateProductRebatesEditFlag = (isEdit) => async (dispatch) => {
    dispatch({
        type: UPDATE_PRODUCT_REBATES_EDIT_FLAG,
        isEdit
    });
};

export const updateProductRebateNotes = () => async (dispatch, getState) => {
    const {productRebates} = getState();
    const {selectedProduct, notes, activeQuarter} = productRebates;
    const {rebateId} = selectedProduct;
    dispatch(rebatesModalSpinner(true))
    try {
        const response = await updateProductRebatesNotesAPI({
            rebateId,
            notes
        })
        const {success, message} = response;
        if (success) {
            toast.success(message);
            dispatch(handleProductRebateQuarter(activeQuarter))
            dispatch(closeRebateNotesModal());
        }
        dispatch(rebatesModalSpinner(false))
    } catch (error) {
        dispatch(rebatesModalSpinner(false))
        if (error === UPDATE_PRODUCT_REBATES_NOTES_ERROR) {
            toast.error(UpdateNotesError)
        }
    }
}

export const getProductRebatesQuarter = () => async (dispatch) => {
    dispatch(updateRebatesQuarterSpinner(true));
    try {
        const response = await getProductRebatesQuarters();
        const {data} = response;
        dispatch({
            type: UPDATE_PRODUCT_REBATES_QUARTER_LIST,
            quarterList: data
        })
        dispatch({
            type: UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER,
            activeQuarter: data.length > 0 ? data[0] : null
        });
        const formattedQuarter = data ? data[0].value.split(' ') : [];
        const quarter = formattedQuarter[0];
        const year = formattedQuarter[1];
        dispatch({
            type: UPDATE_PRODUCT_REBATES_QUARTER,
            quarter
        });
        dispatch({
            type: UPDATE_PRODUCT_REBATES_YEAR,
            year
        });
        dispatch(handleExportQuarter(quarter));
        dispatch(handleExportYear(year));
        dispatch(handleProductRebateQuarter(data[0]))
        dispatch(updateRebatesQuarterSpinner(false));
    } catch (error) {
        dispatch(updateRebatesQuarterSpinner(false));
        if (error === PRODUCT_REBATES_QUARTER_ERROR) {
            toast.error(ProductRebatesQuarterError)
        }
    }
};


export const onEditProductRebase = (isEdit) => async (dispatch, getState) => {
    const {productRebates} = getState();
    const {quarter, year} = productRebates;
    dispatch(updateProductRebatesEditFlag(isEdit))
    dispatch(showProductRebateModal());
    dispatch(rebatesModalSpinner(true))
    try {
        const response = await getProductRebatesInfo({
            quarter,
            year
        })
        dispatch({
            type: UPDATE_PRODUCT_REBATES_INFO,
            productRebatesInfo: response.data
        })
        const fileName = getEditFileName(response.data.fileName);
        const fileSize = getFileSize(response.data.filesize)
        const fileExtension = getEditFileExtension(response.data.fileName);
        dispatch({
            type: UPDATE_PRODUCT_REBATES_FILE_NAME,
            selectedFileName: fileName
        })
        dispatch({
            type: UPDATE_PRODUCT_REBATES_FILE_SIZE,
            selectedFileSize: fileSize
        })
        dispatch({
            type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
            selectedFileExtension: fileExtension
        })
        dispatch(rebatesModalSpinner(false))
    } catch (error) {
        dispatch(rebatesModalSpinner(false))
        if (error === GET_PRODUCT_REBATES_INFO_ERROR) {
            toast.error(getProductRebatesInfoError)
        }
    }
}
