import { FC } from 'react';
import Button from '@material-ui/core/Button';

export const AddDocumentButton: FC<{
  showAddDocumentModal: () => void;
  isAddDocument: boolean;
}> = ({ showAddDocumentModal, isAddDocument }) => {
  return (
    <div className="header-filter-box-add">
      {isAddDocument && (
        <Button
          variant="contained"
          size="small"
          className="btn-add"
          onClick={showAddDocumentModal}
        >
          <span className="fas fa-plus-circle" />{' '}
          <span>Add Documents</span>
        </Button>
      )}
    </div>
  );
};

export default AddDocumentButton;
