import { createMachine } from 'xstate';

export const closeableDisplayMachine = createMachine(
  {
    context: { defaultClosed: false },
    tsTypes: {} as import("./closeableDisplayMachine.typegen").Typegen0,
    schema: {
      context: {} as { defaultClosed: boolean },
      events: {} as { type: 'close'} | { type: 'open' } | { type: 'toggle' },
    },
    initial: 'shouldBeOpen',
    states: {
      shouldBeOpen: {
        always: [
          { cond: 'defaultClosed', target: 'closed' },
          { target: 'opened' },
        ],
      },
      closed: {
        on: {
          open: 'opened',
          toggle: 'opened',
        },
      },
      opened: {
        on: {
          close: 'closed',
          toggle: 'closed',
        },
      },
    },
  },
  {
    guards: {
      defaultClosed: (context) => context.defaultClosed,
    },
  },
);

export type CloseableDisplayMachine = typeof closeableDisplayMachine;

export default closeableDisplayMachine;
