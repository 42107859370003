import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import { Dropdown } from '../../mainComponents/Dropdown';
import { Toggle } from '../../mainComponents/Toggle';
import { Search } from '../../mainComponents/Search';
import { AddDocumentButton } from '../../mainComponents/AddDocumentButton';
import AuthorizationService from '../../../../authorization/AuthorizationService';

export const DocumentsFilters: FC<any> = ({
  documents,
  handleToggle,
  showAddDocumentModal,
  searchText,
  setSearchText,
  handleSelectedCategory,
  handleChangePage,
}) => {
  const isAddDocument = AuthorizationService.isAuthorizedHTML('addNewDocument') ?? false;

  return (
    <Grid container spacing={3} className="header-filter-box">
      <Grid
        xs={12}
        md={isAddDocument ? 4 : 8}
        alignItems="center"
        // justifyItems="flex-start"
        justify="flex-start"
        item
        alignContent="center"
      >
        <div className="header-filter-box-left">
          <Dropdown
            documents={documents}
            handleSelectedCategory={handleSelectedCategory}
          />
          <Toggle handleToggle={handleToggle} documents={documents} />
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={isAddDocument ? 8 : 4}
        direction="row"
        // justifyItems="flex-end"
        justify="flex-start"
        alignItems="center"
      >
        <div className="header-filter-box-search-add">
          <Search
            searchText={searchText}
            setSearchText={setSearchText}
            handleChangePage={handleChangePage}
          />
          <AddDocumentButton
            showAddDocumentModal={showAddDocumentModal}
            isAddDocument={isAddDocument}
          />
        </div>
      </Grid>
    </Grid>
  );
};
