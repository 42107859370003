import {toast} from "react-toastify";
import {
  CLEAR_VENDOR_USER_ASSIGN_MODAL,
  SHOW_VENDOR_USER_ASSIGN_SPINNER,
  HIDE_VENDOR_USER_ASSIGN_SPINNER,
  UPDATE_VENDOR_USER_ASSIGN_SELECT_DC,
  UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS,
  UPDATE_VENDOR_USER_ASSIGN_DC_ERROR,
  UPDATE_VENDOR_USER_ROLE,
  VendorUserAssignSuccess,
  VendorUserAssignError,
  FetchError,
} from "../../../constants";
import {assignVendorUser} from "../../../api/vendors";
import {showModal, closeModal} from "../../modal";
import {getVendorUsersAction} from "../users";

export const showVendorUserAssignModal = () => async (dispatch) => {
  dispatch(showModal());
};

export const clearVendorUserAssign = () => async (dispatch) => {
  dispatch({
    type: CLEAR_VENDOR_USER_ASSIGN_MODAL,
  });

  dispatch(closeModal());
};

export const onChangeVendorUserAssignDC = (dcid) => async (dispatch) => {
  const formatedVendorDcid = returnValidatedNumber(dcid);
  const isValidDC = validateSelectedDC(formatedVendorDcid);

  dispatch({
    type: UPDATE_VENDOR_USER_ASSIGN_SELECT_DC,
    selectedDCid: formatedVendorDcid,
  });
  if (isValidDC) {
    dispatch({
      type: UPDATE_VENDOR_USER_ASSIGN_DC_ERROR,
      errorDistributionCenter: false,
    });
  } else {
    dispatch({
      type: UPDATE_VENDOR_USER_ASSIGN_DC_ERROR,
      errorDistributionCenter: true,
    });
  }
};

export const onSelecteAssignUser = (selectedUserId) => async (dispatch) => {
  dispatch({
    type: UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS,
    selectedUsers: selectedUserId,
  });
};

export const handleUserRoleChange = (selectedRoleId, selectedItem) => async (dispatch, getState) => {
  const {vendorUserAssign} = getState();
  const {selectedUsers} = vendorUserAssign;
  const activeUsers = [...selectedUsers];
  const selectedUserIndex = activeUsers.findIndex((item) => item.userId === selectedItem.userId);
  const updatedUser = activeUsers[selectedUserIndex].roleId = selectedRoleId;
  dispatch({
    type: UPDATE_VENDOR_USER_ROLE,
    selectedUsersRole: activeUsers,
  });
}

export const onAssignVendorUser = () => async (dispatch, getState) => {
  const {vendorUserAssign, common} = getState();
  const {selectedDCid, selectedUsers, selectedUsersRole} = vendorUserAssign;
  //const activeUsers = selectedUsers.filter(item => selectedUsersRole.includes(item));

  if (selectedDCid === 0) {
    dispatch({
      type: UPDATE_VENDOR_USER_ASSIGN_DC_ERROR,
      errorDistributionCenter: true,
    });
  }

  const formattedActiveUsers = selectedUsers.map((item) => {
    const {userId, roleId} = item;
    return {
      userId,
      roleId,
      selectedDC: selectedDCid,
    }
  })
  const inValidRoleID = formattedActiveUsers.filter((item) => item.roleId === null || item.roleId === '');
  if (selectedDCid === 0) return toast.warning("Please select a distribution center");
  if (inValidRoleID.length > 0) return toast.warning("Please select user roles");
  dispatch({
    type: SHOW_VENDOR_USER_ASSIGN_SPINNER,
    spinner: true,
  });
  try {
    if (formattedActiveUsers.length > 0 && inValidRoleID.length == 0 && selectedDCid !== 0) {
      const response = await assignVendorUser(formattedActiveUsers);
      const {success} = response;
      if (success) {
        success && toast.success(VendorUserAssignSuccess);
        dispatch(clearVendorUserAssign());
        dispatch(closeModal());
        dispatch(getVendorUsersAction());
      }
    }
  } catch (error) {
    dispatch({
      type: HIDE_VENDOR_USER_ASSIGN_SPINNER,
      spinner: false,
    });
    toast.error(VendorUserAssignError);
  }
}

const validateAssignUser = (option) => {
  if (option && option != 0) return true;
  else {
    return false;
  }
};

const validateSelectedDC = (option) => {
  if (option && option != 0) return true;
  else {
    return false;
  }
};

const validateItemsList = (option) => {
  if (Array.isArray(option) && option.length > 0) return true;
  else {
    return false;
  }
};

const returnValidatedNumber = (option) => {
  return option && !isNaN(parseInt(option)) ? parseInt(option) : "0";
};
