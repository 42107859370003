import { FC } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { GRID_VIEW, LIST_VIEW } from '../../../constants';

export const Toggle: FC<{
  handleToggle: (message: string) => void;
  documents: { viewType?: string };
}> = ({ handleToggle, documents }) => {
  const { viewType } = documents;
  return (
    <div className="view-icon">
      <Tooltip title="Grid View">
        <i
          className={
            viewType === GRID_VIEW
              ? 'fas fa-th-large active'
              : 'fas fa-th-large'
          }
          onClick={() => handleToggle(GRID_VIEW)}
        />
      </Tooltip>
      <Tooltip title="List View">
        <i
          className={
            viewType === LIST_VIEW ? 'fas fa-list active' : 'fas fa-list'
          }
          onClick={() => handleToggle(LIST_VIEW)}
        />
      </Tooltip>
    </div>
  );
};

export default Toggle;
