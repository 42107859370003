import { withStyles, DialogActions } from '@material-ui/core';

export const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    background: '#e9f0f9',
  },
}))(DialogActions);

export type StyledDialogActionsType = typeof StyledDialogActions;
