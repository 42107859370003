import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListItem,
  IconButton,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core';
import { useActor } from '@xstate/react';
import { FC, useMemo } from 'react';
import { ActorRefFrom } from 'xstate';
import DeleteIcon from '@material-ui/icons/Delete';
import { DwollaFundingSources } from '../../../../../../common';
import { RebateReceiptElectronicTypeItemMachine } from '../machines';

export const RebateReceiptElectronicItemTypeUI: FC<{
  actor: ActorRefFrom<RebateReceiptElectronicTypeItemMachine>;
  disabled?: boolean;
  fundingSources: DwollaFundingSources;
  removeReceiptItem: (id: string) => void;
}> = ({ actor, disabled = false, fundingSources, removeReceiptItem }) => {
  const [state, send] = useActor(actor);

  const selectedFundingSource = useMemo(() => {
    const fundingSource =
      fundingSources.filter(
        (fundingSource) => fundingSource.id === state.context.funding_source_id,
      )?.[0] ?? {};
    const accountNameArray = fundingSource.name?.toString()?.split('<');

    return {
      name: accountNameArray?.[0]?.trim() ?? '',
      fourDigitNumber: accountNameArray?.[1]?.split('>')?.[0].trim() ?? '',
    };
  }, [state.context.funding_source_id]);

  return (
    <ListItem>
      <ListItemText
        primary={
          <TextField
            type="number"
            //value={(state.context.amount - state.context.transferFee) / 100}
            value={(state.context.amount) / 100}
            label="Amount"
            variant="outlined"
            className="number-input-box"
            InputProps={{ inputProps: { min: 1 } }}
            onChange={(e) => {
              const numArr = e.target.value.split('.');
              const wholeValue = numArr?.[0] || '';
              let decimalValue = numArr?.[1] || '00';
              if (decimalValue.length > 2) {
                decimalValue = decimalValue.substring(0, 2);
              } else if (decimalValue.length === 1) {
                decimalValue += '0';
              }
              send({
                type: 'update amount',
                amount: Number(wholeValue + decimalValue),
                //amount: Number(wholeValue + decimalValue) + 500,
              });
            }}
          />
        }
        secondary={''}
        //secondary={'$5.00 Transfer fee'} // TODO Update to non-hardcoded value
      />
      <ListItemText
        primary={
          <FormControl
            className="fund-transfer-drop-down-box"
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-age-native-simple">
              Fund Transfer Account
            </InputLabel>
            <Select
              label="Fund Transfer Account"
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
              value={state.context.funding_source_id}
              onChange={(e: any) =>
                send({
                  type: 'update funding source',
                  funding_source_id: e.target.value,
                })
              }
            >
              {fundingSources?.map((fundingSource) => {
                const accountNameArray = fundingSource.name
                  ?.toString()
                  ?.split('<');
                const accountName = accountNameArray?.[0]?.trim() ?? '';
                const fourDigitAccountNumber =
                  accountNameArray?.[1]?.split('>')?.[0].trim() ?? '';
                return (
                  <MenuItem value={fundingSource.id}>
                    {accountName}
                    <span style={{ fontWeight: 'bold' }}>
                      &nbsp;{`${fourDigitAccountNumber}`}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        }
        secondary={
          <div style={{ color: 'transparent', userSelect: 'none' }}>0</div>
        }
      />
      <ListItemSecondaryAction style={{ top: '40%' }}>
        <IconButton
          edge="end"
          aria-label="delete"
          disabled={disabled}
          onClick={() => removeReceiptItem(actor.id)}
        >
          {!disabled && <DeleteIcon />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
};

export default RebateReceiptElectronicItemTypeUI;
