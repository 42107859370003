import { InputBase } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { Loader } from '../../../../utils';

export const OpportunitiesSearchBar: FC<{
  loading?: boolean;
  defaultSearchStringValue?: string;
  updateSearchStringQueryValue: (searchString: string) => void;
}> = ({
  defaultSearchStringValue,
  loading = false,
  updateSearchStringQueryValue,
}) => {
  const [searchString, setSearchString] = useState(
    defaultSearchStringValue ?? '',
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      updateSearchStringQueryValue(searchString);
    }, 400);
    return () => {
      clearTimeout(timeout);
    };
  }, [searchString]);

  return (
    <div className="header-filter-box-search">
      <div className="page-search-box">
        <i className="fas fa-search" />
        <InputBase
          placeholder="Search"
          value={searchString}
          onChange={(event) => {
            setSearchString(event.target.value);
          }}
        />
        {loading && searchString.length > 3 && <Loader size={10} />}
      </div>
    </div>
  );
};

export default OpportunitiesSearchBar;
