import { FC, useEffect } from 'react';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';
import { SelectMembers } from './SelectMembers';
import { getExtensionClassName, getFileClassName } from '../../../../utils';
import UploadImg from '../../../../images/file-upload.svg';

export const DocumentEditModalContent: FC<any> = (props) => {
  const {
    clearDocumentModal,
    handleFileSelect,
    onChangeEditDocDesc,
    handleModalCategory,
    documents: {
      documentDesc,
      selectedModalCategory,
      selectedFileName,
      selectedFileSize,
      selectedFileExtension,
      fileExtensionError,
      categoryList,
      categoryError,
      isEditDocument,
      selectedDocumentData,
      documentEditError,
    },
  } = props;

  useEffect(() => {
    return () => {
      clearDocumentModal();
    };
  }, []);

  const categoryName =
    selectedModalCategory !== 'NA'
      ? categoryList.find((item: any) => item.id === selectedModalCategory)
      : 'NA';
  const lastUpdateDate = selectedDocumentData
    ? selectedDocumentData.uploadeddate
    : null;

  const categoryType =
    categoryList && categoryList.filter((item: any) => item.id !== 0);

  const errorFlag = documentEditError;

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        {selectedFileName === null || fileExtensionError ? (
          <Button className="file-filed-document" component="label">
            <img src={UploadImg} />
            <div className="file-filed-text-size-sm">
              Upload PDF, DOC, DOCX, XLS OR XLSX files
            </div>
            <div className="file-filed-text-size-md">
              Drop your files here.{' '}
            </div>
            <div>
              or <span className="file-filed-text-size-highlight">browse</span>
            </div>
            <input
              type="file"
              id="fileUpload"
              title=""
              value=""
              onChange={(event) => handleFileSelect(event?.target?.files?.[0])}
            />
            {fileExtensionError && (
              <div className="error-wrapper">
                <i className="fas fa-exclamation-triangle error-icon" />
                <div className="error-text">
                  {'Please select a valid file type.'}
                </div>
              </div>
            )}
          </Button>
        ) : (
          <div className="edit-doc-section">
            <div className="edit-doc-section-box edit-dropzone">
              <div className="documents-grid-img">
                <i className={getFileClassName(selectedFileExtension)}></i>
                <span className={getExtensionClassName(selectedFileExtension)}>
                  {selectedFileExtension}
                </span>
              </div>

              <div className="edit-doc-section-name">{selectedFileName}</div>
              <div className="edit-doc-section-size">{selectedFileSize}</div>
              {lastUpdateDate !== null && (
                <div className="edit-doc-section-size">
                  {`Uploaded Date: ${lastUpdateDate}`}
                </div>
              )}
            </div>

            <div className="edit-doc-section-select">
              <FormControl className="select-box" variant="outlined">
                {!categoryError ? (
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Document Category
                  </InputLabel>
                ) : (
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    style={{ color: 'red' }}
                  >
                    Document Category
                  </InputLabel>
                )}

                <Select
                  native
                  label="Document category"
                  defaultValue={categoryName.name}
                  onChange={(e) => handleModalCategory(e.target.value)}
                >
                  {categoryType.map((item: any, key: number) => (
                    <option key={key}>{item.name}</option>
                  ))}
                </Select>
              </FormControl>
            </div>

            <div className="doc-desc">
              <TextField
                multiline
                rows={3}
                label="Document Description"
                placeholder="Document Description"
                variant="outlined"
                className="text-box"
                onChange={(e) => onChangeEditDocDesc(e.target.value)}
                inputProps={{ maxLength: 150 }}
                defaultValue={documentDesc}
                id={errorFlag ? 'standard-error' : 'outlined-basic'}
                error={errorFlag}
                helperText={
                  errorFlag ? 'Please enter document description' : ''
                }
              />
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectMembers isEditDocument={isEditDocument} {...props} />
      </Grid>
    </Grid>
  );
};

export default DocumentEditModalContent;
