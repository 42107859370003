import { FC, useEffect } from 'react';
import DialogWindow from '../../../../../components/DialogWindow';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const dialogAddConfiguration = {
  title: 'Add Link',
  isCancel: true,
  submitText: 'Add Link',
};

const dialogEditConfiguration = {
  title: 'Edit Link',
  isCancel: true,
  submitText: 'Update Link',
};

export const AddLinkModal: FC<any> = ({
  showModal,
  closeModal,
  getVendorLinkTypeList,
  onChangeLinkType,
  onChangeLinkText,
  onChangeLinkUrl,
  onAddNewLink,
  onEditLink,
  vendorLinkModal: {
    spinner,
    vendorLinkTypeList,
    linkType,
    linkText,
    linkUrl,
    linkTextError,
    linkTypeError,
    linkUrlError,
    isEdit,
    isFormUpdated,
  },
}) => {
  useEffect(() => {
    getVendorLinkTypeList();
  }, []);

  const isError =
    linkType === null ||
    linkText === null ||
    linkUrl === null ||
    linkTextError ||
    linkTypeError ||
    linkUrlError ||
    !isFormUpdated;

  return (
    <div className="vendor-assign-content">
      <DialogWindow
        openDialog={showModal}
        dialogConfiguration={
          isEdit ? dialogEditConfiguration : dialogAddConfiguration
        }
        handleClose={closeModal}
        handleSubmit={isEdit ? onEditLink : onAddNewLink}
        isButtonDisabled={spinner || isError}
      >
        <div className="br-form-element">
          <Grid container spacing={6}>
            <Grid item xs={12} sm={6}>
              <TextField
                error={linkTypeError}
                id="linkTypeField" // Unique id for linkType field
                label="Type"
                variant="outlined"
                className="text-box"
                helperText={linkTypeError ? 'Please enter valid type' : ''}
                onChange={(e) => onChangeLinkType(e.target.value)}
                disabled={spinner}
                required
                value={linkType}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                error={linkTextError}
                id="linkTextField" // Unique id for linkText field
                label="Text"
                variant="outlined"
                className="text-box"
                helperText={linkTextError ? 'Please enter valid text' : ''}
                onChange={(e) => onChangeLinkText(e.target.value)}
                disabled={spinner}
                required
                value={linkText}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <TextField
                error={linkUrlError}
                id="linkUrlField" // Unique id for linkUrl field
                label="URL"
                variant="outlined"
                className="text-box"
                helperText={linkUrlError ? 'Please enter valid URL' : ''}
                onChange={(e) => onChangeLinkUrl(e.target.value)}
                disabled={spinner}
                required
                value={linkUrl}
              />
            </Grid>
          </Grid>
        </div>
      </DialogWindow>
    </div>
  );
};
