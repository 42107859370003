import React from "react";

function Header() {
  return (
    <header>
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <title>.: BuyRight :.</title>
      <meta name="description" content="" />
      <meta name="keyword" content="" />
    </header>
  );
}

export default Header;
