import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  UPDATE_MEMBERS_MODAL_SPINNER,
  MemberUserDeleteSuccess,
  MemberUserUnassignSuccess,
  UPDATE_MEMBER_USER_LIST,
  UPDATE_MEMBER_USER_DELETE_ERROR,
  UPDATE_MEMBER_USER_DELETE,
  UPDATE_SELECTED_MEMBER_USER,
  MemberDeleteUserError,
  FetchError,
  UPDATE_USER_MEMBER_DELETE,
  UserMemberDeletedSuccess,
} from "../../constants";

import {deleteMemberUser, getMemberUserList, deleteUserMember} from "../../api/members";

export const handleDeleteMemberUser = (isMemberUserDelete, selectedUser) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_USER_DELETE,
    isMemberUserDelete
  });
  dispatch({
    type: UPDATE_SELECTED_MEMBER_USER,
    selectedUser
  });
};
// Delete user from the member
export const handleDeleteUserMember = (isUserMemberDelete, selectedUser) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_MEMBER_DELETE,
    isUserMemberDelete
  });
  dispatch({
    type: UPDATE_SELECTED_MEMBER_USER,
    selectedUser
  });
};

export const onDeleteMemberUser = () => async (dispatch, getState) => {
  const {members} = getState();
  const {type, selectedMember, selectedUser} = members;
  const {id} = selectedMember;
  const {memberUserId} = selectedUser;
  dispatch({
    type: UPDATE_MEMBER_USER_DELETE,
    isMemberUserDelete: true
  });
  try {
    dispatch({
      type: UPDATE_MEMBERS_MODAL_SPINNER,
      memberModalSpinner: true,
    });
    const response = await deleteMemberUser({
      memberUserId,
      memberId: id
    });
    const {success, message, data} = response;
    if (success) {
      const usersList = await getMemberUserList(id, type);
      dispatch({
        type: UPDATE_MEMBER_USER_LIST,
        list: usersList.data,
      });
      dispatch({
        type: UPDATE_MEMBERS_MODAL_SPINNER,
        memberModalSpinner: false,
      });
      dispatch({
        type: UPDATE_MEMBER_USER_DELETE,
        isMemberUserDelete: false,
      });
      success ? toast.success(MemberUserUnassignSuccess) : null;
      dispatch({
        type: UPDATE_MEMBER_USER_DELETE,
        isMemberUserDelete: false
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBERS_MODAL_SPINNER,
      memberModalSpinner: false,
    });
    if (error == UPDATE_MEMBER_USER_DELETE_ERROR) {
      toast.error(MemberDeleteUserError);
    } else {
      toast.error(FetchError)
    }
  }
};

export const onDeleteUserMember = () => async (dispatch, getState) => {
  const {members} = getState();
  const {type, selectedUser} = members;
  const {id, memberUserId, memberid} = selectedUser;
  dispatch({
    type: UPDATE_USER_MEMBER_DELETE,
    isUserMemberDelete: true
  });
  try {
    dispatch({
      type: UPDATE_MEMBERS_MODAL_SPINNER,
      memberModalSpinner: true,
    });
    const response = await deleteUserMember(id);
    const {success, message, data} = response;
    if (success) {
      const usersList = await getMemberUserList(memberid, type);
      dispatch({
        type: UPDATE_MEMBER_USER_LIST,
        list: usersList.data,
      });
      dispatch({
        type: UPDATE_MEMBERS_MODAL_SPINNER,
        memberModalSpinner: false,
      });
      dispatch({
        type: UPDATE_USER_MEMBER_DELETE,
        isUserMemberDelete: false,
      });
      success ? toast.success(UserMemberDeletedSuccess) : null;
      dispatch({
        type: UPDATE_USER_MEMBER_DELETE,
        isUserMemberDelete: false
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBERS_MODAL_SPINNER,
      memberModalSpinner: false,
    });
    if (error == UPDATE_MEMBER_USER_DELETE_ERROR) {
      toast.error(MemberDeleteUserError);
    } else {
      toast.error(FetchError)
    }
  }
};
