import { FC, useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import DetailHeaderImage from '../../../../images/user-details-bg.png';
import UserImg from '../../../../images/profile-placeholder.jpg';
import { Dispatch } from 'redux';

export const ProfileNavBar: FC<{
  updateProfileRoute: (
    activeMenu: {
      route: string;
      label: string;
    },
    navigate: NavigateFunction,
  ) => (dispatch: Dispatch) => Promise<void>;
  user: any; // TODO
}> = ({ user, updateProfileRoute }) => {
  console.log({ user });
  console.log(process.env.REACT_APP_HIDE_DWOLLA_FEATURES)
  const navigate = useNavigate();
  const [path, setPath] = useState(null);

  //on logout call
  const handleLogout = () => {
    localStorage.clear();
    navigate('/');
    const activeMenu = {
      label: 'Logout',
      route: '/profile/messages',
    };
    updateProfileRoute(activeMenu, navigate);
  };

  const menus = [
    // {
    //   label: 'Messages',
    //   icon: 'fa-envelope',
    //   route: '/profile/messages',
    // },
    // {
    //   label: 'Notifications',
    //   icon: 'fa-bell',
    //   route: '/profile/notification',
    // },
    {
      label: 'Password Settings',
      icon: 'fa-key',
      route: '/profile/password-settings',
    },
    {
      label: 'Account Settings',
      icon: 'fa-user',
      route: '/profile/account-settings',
    },
    // {
    //   label: 'Roles & Permissions',
    //   icon: 'fa-user-tie',
    //   route: '/profile/roles',
    // },
    {
      label: 'Logout',
      icon: 'fa-sign-out-alt',
    },
  ];

  if (process.env.REACT_APP_HIDE_DWOLLA_FEATURES != 'true') {
    menus.unshift(    {
      label: 'Fund Transfer',
      icon: 'fa-university',
      route: '/profile/fund-transfer',
    });
  }

  const handleMenu = (activeMenu: any) => {
    const { label } = activeMenu;
    if (label !== 'Logout') {
      updateProfileRoute(activeMenu, navigate);
    } else {
      handleLogout();
    }
  };

  return (
    <Grid item xs={12} lg={3} className="change-password-left">
      <Grid container className="details-header">
        <div className="details-header-img">
          <img src={DetailHeaderImage} alt="member" title="Member Header" />
          <div className="details-header-backdrop"></div>
        </div>
        <div className="user-img">
          <img src={user.avatar || UserImg} />
        </div>
      </Grid>
      <div className="name-change-password">{user?.firstname || ''}</div>
      <div className="email-change-password">{user?.email || ''}</div>

      <div className="menu-change-password">
        <div className="menu-change-password-list">
          {menus.map((item) => {
            const { label, route, icon } = item;
            return (
              <div
                className={
                  route === path
                    ? 'menu-change-password-content menu-change-password-active'
                    : 'menu-change-password-content'
                }
                onClick={() => handleMenu(item)}
              >
                <i className={`fas ${icon}`} />
                <span>{label}</span>
              </div>
            );
          })}
        </div>
      </div>
    </Grid>
  );
};
