import {
    UPDATE_DASHBOARD_KPI_SELECTED_MEMBER,
    DashboardFetchError
} from "../../constants";

import {getDashboardOpportunityData} from "./dashboardOpportunity";
import {getDashboardActiveEarningsData} from "./dashboardActiveEarnings";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const getDashboardInfo = () => async (dispatch) => {
    try {
        // dispatch(getDashboardKPIData()) //KPI
        dispatch(getDashboardActiveEarningsData()) //EarnedRebates
        dispatch(getDashboardOpportunityData()) //Opportunity
    } catch (error) {
        toast.error(DashboardFetchError);
    }
}