import React, { FC } from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import AuthorizationService from "../../../../../authorization/AuthorizationService";
import { useSelector } from 'react-redux';

export const UserActions: FC<any> = ({
  searchText,
  setSearchText,
  assignedList,
  handleClickOpen
}) => {
  const store = useSelector((state) => state)

  const {user}:any = store
  return (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      {assignedList && assignedList.length > 0 ?
        <Grid>
          <div className="page-search-box">
            <i className="fas fa-search"></i>
            <InputBase
              placeholder="Search"
              value={searchText.toString()}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </div>
        </Grid>
        :
        null
      }
      {
        AuthorizationService.isAuthorizedHTML("memberAssignButton") &&
        <Grid>
          <Button
            className="btn-add ml-10"
            component="label"
            onClick={() => handleClickOpen()}
            disabled={user?.isViewAsMember}
          >
            <span className="fas fa-plus-circle"></span>
            <span>Assign New User</span>
          </Button>
        </Grid>
      }
    </Grid>
  );
};
