import { FC } from 'react';
import { VendorTitle } from '../VendorTitle';
import { VendorsHeader } from '../VendorsHeader';
import { VendorTabs } from '../VendorsTabs';
import '../vendors.scss';
import brandedImageLogo from '../../../images/brandedImage.jpg'

export const BrandedImageMain: FC = (props) => {
  return (
    <>
      <VendorTitle name="Branded Image" />
      <VendorsHeader image={brandedImageLogo} />
      <VendorTabs {...props} />
    </>
  );
};

export type BrandedImageMain = typeof BrandedImageMain;
