import React, { FC } from "react";
import { useMachine } from "@xstate/react";
import serviceInterceptor from "../../../services/ServiceInterceptor";
import { paginationMachine } from "../../../machines";
import { UserAnalyticsFilter } from "./UserAnalyticsFilter";
import UserAnalyticsTitle from "./UserAnalyticsTitle";
import { AnalyticsUserList } from "./User/AnalyticsUserList";

const UserAnalytics: FC<any> = () => {
  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: "",
        sortOrder: "desc",
      },
      pageSize: 10,
      pageNumber: 1,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serviceInterceptor.get(
          `/users/overall-analytics?${query.toString()}`
        );
      },
    },
  });

  return (
    <div>
      <UserAnalyticsTitle />

      <div className="container">
        <UserAnalyticsFilter send={send} />
        <div>
          <AnalyticsUserList
            membersList={state.context.records}
            state={state}
            send={send}
          />
        </div>
      </div>
    </div>
  );
};

export default UserAnalytics;
