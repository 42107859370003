import {
  UPDATE_STATE_LIST,
  UPDATE_STATE_LIST_ERROR,
  StateListError,
  UPDATE_USER_ROLE_LIST,
  UPDATE_USER_ROLE_LIST_ERROR,
  UserRoleListError,
  FetchError,
} from "../constants";

import {getStateListAPI, getUserRoleListAPI} from "../api/common";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getStateLists = () => async (dispatch) => {
  try {
    const StateList = await getStateListAPI();
    dispatch({
      type: UPDATE_STATE_LIST,
      statesList: StateList.data.state,
    });
  } catch (error) {
    if (error === UPDATE_STATE_LIST_ERROR) {
      toast.error(StateListError);
    } else {
      toast.error(FetchError);
    }
  }
};
export const getUserRoleList = () => async (dispatch) => {
  try {
    const list = await getUserRoleListAPI();
    dispatch({
      type: UPDATE_USER_ROLE_LIST,
      list: list.data,
    });
  } catch (error) {
    if (error === UPDATE_USER_ROLE_LIST_ERROR) {
      toast.error(UserRoleListError);
    } else {
      toast.error(FetchError);
    }
  }
};
