import { FC, useEffect } from 'react';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import DialogWindow from '../../../../components/DialogWindow';
import {
  ZIP_EMPTY_MESSAGE,
  ZIP_VALID_MESSAGE,
  CODE_EMPTY_MESSAGE,
  CODE_VALID_MESSAGE,
} from '../../../../utils/constants';
import { ZipcodeFormat } from '../../../../utils';

const dialogAddConfiguration = {
  title: 'Create New Distribution Center',
  isCancel: true,
  submitText: 'Save',
};

const dialogEditConfiguration = {
  title: 'Edit Distribution Center',
  isCancel: true,
  submitText: 'Update',
};

export const DistributionCentersModal: FC<any> = ({
  showModal,
  closeModal,
  onChangeDCName,
  onChangeStreet,
  onChangeCity,
  getStateLists,
  statesList,
  onChangeState,
  onChangeZip,
  onChangeCode,
  onAddDC,
  onEditDC,
  vendorDCModal: {
    spinner,
    isEdit,
    dcName,
    dcStreet,
    dcCity,
    dcState,
    dcZip,
    dcCode,
    isDcNameError,
    isDcStreetError,
    isDcCityError,
    isDcStateError,
    isDcCodeError,
    isDcZipError,
    isFormUpdated,
  },
}) => {
  useEffect(() => {
    if (!(statesList?.length > 0)) {
      getStateLists();
    }
  }, []);

  const isError =
    !dcName ||
    !dcStreet ||
    !dcCity ||
    !dcState ||
    !dcZip ||
    isDcNameError ||
    isDcStreetError ||
    isDcCityError ||
    isDcStateError ||
    isDcZipError ||
    !isFormUpdated;

  return (
    <div className="vendor-assign-content">
      <DialogWindow
        openDialog={showModal}
        dialogConfiguration={
          isEdit ? dialogEditConfiguration : dialogAddConfiguration
        }
        handleClose={closeModal}
        handleSubmit={isEdit ? onEditDC : onAddDC}
        isButtonDisabled={!isFormUpdated || isError}
        spinnerEnabled={spinner}
      >
        <div className="br-form-element">
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={isDcNameError}
                id={
                  isDcNameError ? 'standard-error name' : 'outlined-basic name'
                }
                label="Name"
                variant="outlined"
                className="text-box"
                helperText={isDcNameError ? 'Please enter name' : ''}
                onChange={(e) => onChangeDCName(e.target.value)}
                disabled={spinner}
                required
                value={dcName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={isDcStreetError}
                id={
                  isDcStreetError
                    ? 'standard-error street'
                    : 'outlined-basic street'
                }
                label="Street"
                variant="outlined"
                className="text-box"
                helperText={isDcStreetError ? 'Please enter description' : ''}
                onChange={(e) => onChangeStreet(e.target.value)}
                disabled={spinner}
                required
                value={dcStreet}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={isDcCityError}
                id={
                  isDcCityError ? 'standard-error city' : 'outlined-basic city'
                }
                label="City"
                variant="outlined"
                className="text-box"
                helperText={isDcCityError ? 'Please enter text' : ''}
                onChange={(e) => onChangeCity(e.target.value)}
                disabled={spinner}
                required
                value={dcCity}
              />
            </Grid>
            <Grid item xs>
              <FormControl variant="outlined" className="select-box">
                {!isDcStateError ? (
                  <InputLabel htmlFor="Type">State</InputLabel>
                ) : (
                  <InputLabel htmlFor="Type" style={{ color: 'red' }}>
                    State
                  </InputLabel>
                )}

                <Select
                  native
                  value={dcState}
                  onChange={(e) => onChangeState(e.target.value)}
                  label="State"
                  error={isDcStateError}
                  placeholder=""
                  id={
                    isDcStateError
                      ? 'standard-error'
                      : 'outlined-age-native-simple'
                  }
                  disabled={spinner}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left"
                    },
                    getContentAnchorEl: null
                  }}
                >
                  <option key={'state-NA'} value="NA">
                    Select
                  </option>
                  {statesList &&
                    statesList.map((item: { abbreviation: string; name: string; }) => {
                      return (
                        <option key={item.name} value={item.abbreviation}>
                          {item.name}
                        </option>
                      );
                    })}
                </Select>
                {isDcStateError ? (
                  <p style={{ color: 'red', fontSize: '12px' }}>
                    Please select state
                  </p>
                ) : (
                  ''
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={isDcZipError}
                id={isDcZipError ? 'standard-error' : 'outlined-basic'}
                label="Zip"
                variant="outlined"
                className="text-box"
                helperText={
                  isDcZipError
                    ? dcZip === null
                      ? ZIP_EMPTY_MESSAGE
                      : ZIP_VALID_MESSAGE
                    : null
                }
                inputProps={{
                  maxLength: 9,
                }}
                onChange={(e) => onChangeZip(e.target.value)}
                disabled={spinner}
                required
                value={ZipcodeFormat(dcZip)}
              />
            </Grid>
            <Grid item xs>
              <TextField
                error={isDcCodeError}
                id={isDcCodeError ? 'standard-error' : 'outlined-basic'}
                label="Distribution Center ID"
                variant="outlined"
                className="text-box"
                helperText={
                  isDcCodeError
                    ? dcCode === null
                      ? CODE_EMPTY_MESSAGE
                      : CODE_VALID_MESSAGE
                    : null
                }
                inputProps={{
                  maxLength: 10,
                }}
                onChange={(e) => onChangeCode(e.target.value)}
                disabled={spinner}
                value={dcCode}
              />
            </Grid>
          </Grid>
        </div>
      </DialogWindow>
    </div>
  );
};

export type DistributionCentersModalType = typeof DistributionCentersModal;
