var CryptoJS = require("crypto-js");
import { EndpointSecretKey } from '../constants';
 
export const Encrypt = function (data) {
  try {
    const keySize = 128;
    const salt = CryptoJS.lib.WordArray.random(128 / 8);
    const key = CryptoJS.PBKDF2(EndpointSecretKey, salt, {
      keySize: keySize / 4
    });
    const iv = CryptoJS.lib.WordArray.random(128 / 8);
    const encrypted = CryptoJS.AES.encrypt(data, key, {
      iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return salt.toString() + iv.toString() + encrypted.toString();
  }
  catch (error) {
    console.log("Error while encrypting: ", error);
  }
}

export const Decrypt = function (data) {
  try {
    if (data) {
      const keySize = 128;
      const salt = CryptoJS.enc.Hex.parse(data.substr(0, 32));
      const iv = CryptoJS.enc.Hex.parse(data.substr(32, 32));
      const encrypted = data.substring(64);
      const key = CryptoJS.PBKDF2(EndpointSecretKey, salt, {
        keySize: keySize / 4
      });
      const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
        iv,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
      });
      return decrypted.toString(CryptoJS.enc.Utf8);
    }
  }
  catch (error) {
    console.log("Error while decrypting: ", error);
  }
}
