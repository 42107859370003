import { FC, useEffect } from 'react';
import { SelectMembers } from './SelectMembers';
import { getExtensionClassName, getFileClassName } from '../../../../utils';
import UploadImg from '../../../../images/file-upload.svg';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  Select,
  TextField,
} from '@material-ui/core';

export const DocumentCreateModalContent: FC<any> = (props) => {
  const {
    handleFileSelect,
    handleRemoveFile,
    handleModalCategory,
    onChangeEditDocDesc,
    getMemberMenuList,
    getDCLIST,
    clearDocumentModal,
    documents: {
      selectedFileName,
      selectedFileSize,
      selectedFileExtension,
      fileExtensionError,
      categoryList,
      categoryError,
      documentDesc,
      documentDescError,
    },
  } = props;

  const categoryType = categoryList && categoryList.slice(1);

  useEffect(() => {
    getMemberMenuList();
    getDCLIST();
    return () => {
      clearDocumentModal();
    };
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} sm={6}>
        {selectedFileName === null || fileExtensionError ? (
          <Button className="file-filed-document" component="label">
            <img src={UploadImg} />
            <div className="file-filed-text-size-sm">
              Upload PDF, DOC, DOCX, XLS OR XLSX files
            </div>
            <div className="file-filed-text-size-md">
              Drop your files here,{' '}
            </div>
            <div>
              or <span className="file-filed-text-size-highlight">browse</span>
            </div>
            <input
              type="file"
              id="fileUpload"
              title=""
              value=""
              onChange={(event) => handleFileSelect(event?.target?.files?.[0])}
            />
            {fileExtensionError && (
              <div className="error-wrapper">
                <i className="fas fa-exclamation-triangle error-icon" />
                <div className="error-text">
                  {'Please select a valid file type.'}
                </div>
              </div>
            )}
          </Button>
        ) : (
          <div className="edit-doc-section">
            <div className="edit-doc-section-box">
              <div className="documents-grid-img">
                <i className={getFileClassName(selectedFileExtension)} />
                <span className={getExtensionClassName(selectedFileExtension)}>
                  {selectedFileExtension}
                </span>
              </div>
              <div className="edit-doc-section-name">{selectedFileName}</div>
              <div className="edit-doc-section-size">{selectedFileSize}</div>
              <div className="edit-doc-section-buttons">
                <Button className="remove-button" onClick={handleRemoveFile}>
                  Remove
                </Button>
                <label className="replace-button" htmlFor="replaceFile">
                  Replace
                </label>
                <input
                  type="file"
                  id="replaceFile"
                  hidden
                  onChange={(event) =>
                    handleFileSelect(event?.target?.files?.[0])
                  }
                />
              </div>
            </div>
            <div className="edit-doc-section-select">
              <FormControl className="select-box" variant="outlined">
                {!categoryError ? (
                  <InputLabel htmlFor="outlined-age-native-simple">
                    Document Category
                  </InputLabel>
                ) : (
                  <InputLabel
                    htmlFor="outlined-age-native-simple"
                    style={{ color: 'red' }}
                  >
                    Document Category
                  </InputLabel>
                )}
                <Select
                  native
                  label="Document category"
                  error={categoryError}
                  id={
                    categoryError
                      ? 'standard-error'
                      : 'outlined-age-native-simple'
                  }
                  onChange={(e) => handleModalCategory(e.target.value)}
                >
                  <option value="NA">Select a Category</option>
                  {categoryType.map((item: any, key: number) => (
                    <option key={key}>{item.name}</option>
                  ))}
                </Select>
                {categoryError && (
                  <p style={{ color: 'red', fontSize: '12px' }}>
                    Please select Document category
                  </p>
                )}
              </FormControl>
            </div>
            <div className="doc-desc">
              <TextField
                multiline
                rows={3}
                label="Document Description"
                placeholder="Document Description"
                variant="outlined"
                className="text-box"
                required
                onChange={(e) => onChangeEditDocDesc(e.target.value)}
                inputProps={{ maxLength: 150 }}
                defaultValue={documentDesc}
                id={documentDescError ? 'standard-error' : 'outlined-basic'}
                error={documentDescError}
                helperText={
                  documentDescError ? 'Please enter document description' : ''
                }
              />
            </div>
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectMembers {...props} />
      </Grid>
    </Grid>
  );
};

export default DocumentCreateModalContent;
