import {
  Button,
  Popper,
  Grow,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { FC, useMemo, useRef, useState } from 'react';
import { OpportunityQuarters } from '../../../../common';
import { statusDisplay } from '../util';

const OpportunitiesDropdownMenu: FC<{
  opportunityQuarters: OpportunityQuarters;
  selectedOpportunityQuarterValue?: string;
  selectedOpportunityQuarterId: number | null;
  setSelectedOpportunityQuarterId: (opportunityQuarterId: number) => void;
  status: 'INPROGRESS' | 'UPLOADED' | 'PUBLISHED' | 'ERROR' | 'NOSTATUS';
}> = ({
  opportunityQuarters,
  selectedOpportunityQuarterId,
  selectedOpportunityQuarterValue,
  setSelectedOpportunityQuarterId,
  status,
}) => {
  const { className: statusClassName, label: statusLabel } = useMemo(
    () =>
      statusDisplay?.[status] ?? {
        className: 'details-title-in-progress',
        label: '(In Progress)',
      },
    [status],
  );
  const [popperOpen, setPopperOpen] = useState(false);
  const popperTriggerRef = useRef<any>(null);
  return (
    <div className="br-dropdown-menu">
      <Button
        ref={popperTriggerRef}
        aria-controls={popperOpen ? 'menu-list-grow' : ''}
        aria-haspopup="true"
        onClick={() => setPopperOpen((currentPopperOpen) => !currentPopperOpen)}
        disabled={opportunityQuarters.length === 0}
      >
        <span className="drop-menu-button-number">
          <>{selectedOpportunityQuarterValue}</>
          <span className={statusClassName}>{statusLabel}</span>
        </span>

        <i className="fas fa-chevron-down" />
      </Button>
      <Popper
        open={popperOpen}
        anchorEl={popperTriggerRef.current}
        role={''}
        transition
        disablePortal
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
        }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <div>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (
                    popperTriggerRef?.current &&
                    popperTriggerRef?.current.contains(event.target)
                  ) {
                    return;
                  }
                  setPopperOpen(false);
                }}
              >
                <MenuList
                  autoFocusItem={popperOpen}
                  id="menu-list-grow"
                  onKeyDown={(event) => {
                    if (event.key === 'Tab') {
                      event.preventDefault();
                      setPopperOpen(false);
                    }
                  }}
                >
                  {opportunityQuarters?.map((opportunityQuarter) => {
                    const { value, status, id } = opportunityQuarter;
                    const { className, label } = statusDisplay[status] || {
                      className: 'details-title-in-progress',
                      label: '(In Progress)',
                    };
                    return (
                      <MenuItem
                        key={id}
                        onClick={() => {
                          setSelectedOpportunityQuarterId(id);
                          setPopperOpen(false);
                        }}
                        selected={id === selectedOpportunityQuarterId}
                      >
                        <>{value}</>
                        <span className={className}>{label}</span>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </div>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default OpportunitiesDropdownMenu;
