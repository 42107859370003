import { FC, useState, useEffect } from 'react';
import { RebatesReceiptFilter } from '../../header/rebateReceipts/RebatesReceiptFilter';
import { ReceiptContent } from './ReceiptContent';

export const RebatesReceiptMain: FC<{
  getRebateReceiptDetails: () => void;
  handleRebateReceiptMemberChange: (selectedMember: any) => void;
  updateRebateAction: (selectedRebate: any) => void;
  showClaimRebateExportModal: (isClaimRebatesExportModal: boolean) => void;
}> = ({
  getRebateReceiptDetails,
  handleRebateReceiptMemberChange,
  updateRebateAction,
  showClaimRebateExportModal,
}) => {
  useEffect(() => {
    getRebateReceiptDetails();
  }, []);

  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState<string | null>(null);
  const [sortType, updateSortType] = useState<'ASC' | 'DESC'>('ASC');

  const setSortKey = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  return (
    <div>
      <RebatesReceiptFilter
        handleRebateReceiptMemberChange={handleRebateReceiptMemberChange}
        searchText={searchText}
        setSearchText={setSearchText}
        showClaimRebateExportModal={showClaimRebateExportModal}
      />
      <ReceiptContent
        searchText={searchText}
        sortKey={sortKey}
        setSortKey={setSortKey}
        setSortType={updateSortType}
        sortType={sortType}
        updateRebateAction={updateRebateAction}
      />
    </div>
  );
};

export default RebatesReceiptMain;
