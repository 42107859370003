import { FC, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Navigate, useNavigate, useRoutes, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// pages
import { Login } from './modules/login';
import { Dashboard } from './modules/dashboard';
import { ResetPasswordPage } from './modules/ResetPasswordPage';
//new
import { Members } from './modules/members';
import { AddMember } from './modules/members/AddMemberMod';
import { MemberDetailPage } from './modules/members/memberDetails';
import { Messages } from './modules/profile/section/messages';
import { Notifications } from './modules/profile/section/notifications';
import { FundTransfer } from './modules/profile/section/fundTransfer';
import { Roles } from './modules/profile/section/roles';
import { PerformanceFoodService } from './modules/vendors/Performanec-foodservice';
import {BrandedImage} from './modules/vendors/Branded-Image'
import { Documents } from './modules/documents';
import { ManageAdsNew } from './modules/manageads';
import { Community } from './modules/community';
import { Rebates } from './modules/rebates';
import { PageNotFound } from './modules/errorPage/404';
import { getStateLists, getUserRoleList } from './actions/common';
import { PasswordSettings } from './modules/profile/section/PasswordSettings';
import { AccountSettings } from './modules/profile/section/AccountSettings';
import { MemberDirectory } from './modules/community/communityMemberDirectory/MemberDirectory';
import './App.css';
import AdminLayout from './modules/layout/AdminLayout';
import { PasswordUpdate } from './modules/passwordupdate';
import { PageNoAccessForInvalidPassword } from './modules/passwordupdate/noaccess';
import { TermsOfService } from './modules/termsofservice/TermsOfService';
import { useIdleTimer } from 'react-idle-timer';
import { updateProfileRoute } from './actions/profile';
import UserDirectory from './modules/community/communityUserDirectory/UserDirectory';
import AuthorizationService from './authorization/AuthorizationService';
import { vendorID } from './actions/vendors/vendorID';
import { NotAssignedToMember } from './modules/errorPage/notAssigned';
import UserAnalytics from './modules/community/communityUserAnalytics/UserAnalytics';


const AppComponent: FC<{
  getUserRoleList: () => void;
  getStateLists: () => void;
}> = ({ getUserRoleList, getStateLists }) => {
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  const navigate = useNavigate();
  const dispatch:any = useDispatch();
  const location = useLocation();
  useEffect(() => {
    if (user?.id) {
      getUserRoleList();
      getStateLists();
    }
  }, []);

  useEffect(() => {
    if(location.pathname === '/vendors/branded-image') dispatch(vendorID(2))
  }, []);

  const isValidPassword = () => {
    return user?.invalidPassword ? false : true;
  };

  const isAssignedMemberUser = () => {
    return (!user?.memberusers?.length && !user?.isBRAdmin && !(user?.vendorusers?.[0]?.id)) ? true : false;
  }

  const isAssignedMember = useRoutes([
    { path: '/', element: <Navigate to='/login' /> },
    { path: '/login', element: <Login /> },
    { path: '/forgot-password', element: <ResetPasswordPage /> },
    {
      path: '/dashboard',
      element: (
        <AdminLayout>
          <NotAssignedToMember/>
        </AdminLayout>
      ),
    },
    {
      path: '/profile/messages',
      element: (
        <AdminLayout>
          <Messages />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/notification',
      element: (
        <AdminLayout>
          <Notifications />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/fund-transfer',
      element: (
        <AdminLayout>
          <FundTransfer />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/password-settings',
      element: (
        <AdminLayout>
          <PasswordSettings />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/account-settings',
      element: (
        <AdminLayout>
          <AccountSettings />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/roles',
      element: (
        <AdminLayout>
          <Roles />
        </AdminLayout>
      ),
    },   
    {
      path: '*',
      element: (
        <AdminLayout>
          <PageNoAccessForInvalidPassword />
        </AdminLayout>
      ),
    },
  ])


  const tempElement = useRoutes([
    { path: '/', element: <Navigate to='/login' /> },
    { path: '/login', element: <Login /> },
    { path: '/forgot-password', element: <ResetPasswordPage /> },
    {
      path: '/password-update',
      element: (
        <AdminLayout>
          <PasswordUpdate />
        </AdminLayout>
      ),
    },
    {
      path: '*',
      element: (
        <AdminLayout>
          <PageNoAccessForInvalidPassword />
        </AdminLayout>
      ),
    },
  ]);

  const element = useRoutes([
    { path: '/', element: <Navigate to='/login' /> },
    { path: '/login', element: <Login /> },
    { path: '/forgot-password', element: <ResetPasswordPage /> },
    {
      path: '/dashboard',
      element: (
        <AdminLayout>
          <Dashboard />
        </AdminLayout>
      ),
    },
    {
      path: '/members',
      element: (
        <AdminLayout>
          <Members />
        </AdminLayout>
      ),
    },
    {
      path: '/members/members-add',
      element: (
        <AdminLayout>
          <AddMember />
        </AdminLayout>
      ),
    },
    {
      path: '/members/edit/:id/',
      element: (
        <AdminLayout>
          <AddMember />
        </AdminLayout>
      ),
    },
    {
      path: '/member/view/:id',
      element: (
        <AdminLayout>
          <MemberDetailPage />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/messages',
      element: (
        <AdminLayout>
          <Messages />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/notification',
      element: (
        <AdminLayout>
          <Notifications />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/fund-transfer',
      element: (
        <AdminLayout>
          <FundTransfer />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/password-settings',
      element: (
        <AdminLayout>
          <PasswordSettings />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/account-settings',
      element: (
        <AdminLayout>
          <AccountSettings />
        </AdminLayout>
      ),
    },
    {
      path: '/profile/roles',
      element: (
        <AdminLayout>
          <Roles />
        </AdminLayout>
      ),
    },
    {
      path: '/vendors/performance-foodservice',
      element: (
        <AdminLayout>
          <PerformanceFoodService />
        </AdminLayout>
      ),
    },
    {
      path: '/vendors/branded-image',
      element: (
        <AdminLayout>
          <BrandedImage/>
        </AdminLayout>
      ),
    },
    {
      path: '/documents',
      element: (
        <AdminLayout>
          <Documents />
        </AdminLayout>
      ),
    },
    {
      path: '/manage-ads',
      element: (
        <AdminLayout>
          <ManageAdsNew />
        </AdminLayout>
      ),
    },
    {
      path: '/community',
      element: (
        <AdminLayout>
          <Community />
        </AdminLayout>
      ),
    },
    {
      path: '/rebates',
      element: (
        <AdminLayout>
          <Rebates />
        </AdminLayout>
      ),
    },
    {
      path: '/member-directory',
      element: (
        <AdminLayout>
          <MemberDirectory />
        </AdminLayout>
      ),
    },
    {
      path: '/user-directory',
      element: (
        <AdminLayout>
          {AuthorizationService.isAuthorizedHTML('userDirectory') ? (
            <UserDirectory />
          ) : (
            <PageNotFound />
          )}
        </AdminLayout>
      ),
    },
    {
      path: "/analytics",
      element: (
        <AdminLayout>
          {AuthorizationService.isAuthorizedHTML("userAnalytics") ? (
            <UserAnalytics />
          ) : (<PageNotFound/>)}
        </AdminLayout>
      ),
    },
    {
      path: '*',
      element: (
        <AdminLayout>
          <PageNotFound />
        </AdminLayout>
      ),
    },
  ]);

  // idle-timer
  const idleTimer = useIdleTimer({
    onIdle() {
      localStorage.clear();
      navigate('/login');
      const activeMenu = {
        label: 'Logout',
        route: '/profile/messages',
      };
      dispatch(updateProfileRoute(activeMenu, navigate) as any);
    },
    timeout: 1000 * 60 * 15,
  });

  return (
    <main>
      {/* {isValidPassword() ? element : tempElement} */}
      {isAssignedMemberUser() ? isAssignedMember : isValidPassword() ? element : tempElement}
      <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
    </main>
  );
};

const App = connect(null, { getStateLists, getUserRoleList })(AppComponent);

export default App;
