export const getFileName = (name) => {
    const fileName = name.split('.').slice(0, -1).join('.');
    return fileName;
}

export const getFileSize = (fileInBytes) => {
    const decimals = 2;
    if (fileInBytes === 0) return '0 fileInBytes';
    const filekb = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['fileInBytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(fileInBytes) / Math.log(filekb));
    const fileSize = parseFloat((fileInBytes / Math.pow(filekb, i)).toFixed(dm)) + ' ' + sizes[i];
    return fileSize;
}

export const getEditFileName = (fileName) => {
    const file = fileName.length > 0 ? fileName.split('/') : [];
    const fileWithExtension = file[0].split('.');
    const file_Name = fileWithExtension[0];
    return file_Name
}

export const getMemberRebateEditFileName = (fileName) => {
    const file = fileName.length > 0 ? fileName.split('.') : [];
    const file_Name = file[0];
    return file_Name
}

export const getEditFileExtension = (fileName) => {
    const file = fileName.length > 0 ? fileName.split('.') : [];
    const fileExtension = file[1];
    return fileExtension
}

export const getExtensionClassName = (fileExtension) => {
    if (fileExtension === "pdf") return "documents-doc-type doc-pdf";
    else if (fileExtension === "xls" || fileExtension === "xlsx") return "documents-doc-type doc-xls";
    else return "documents-doc-type doc-type"
}

export const getRebatesExtensionClassName = (fileExtension) => {
    if (fileExtension === "pdf") return "documents-doc-type doc-pdf";
    else if (fileExtension === "xls" || fileExtension === "xlsx") return "documents-doc-type doc-xls";
    else return ""
}

export const getFileClassName = (fileExtension) => {
    if (fileExtension === "pdf") return "far fa-file-pdf";
    else if (fileExtension === "xls" || fileExtension === "xlsx") return "far fa-file-excel";
    else return "far fa-file-alt"
}

export const getFileExtensionColor = (fileExtension) => {
    if (fileExtension === "pdf") return "#f2994a";
    else if (fileExtension === "xls" || fileExtension === "xlsx") return "#9b51e0";
    else return "#27ae60"
}

export const getAccessColor = (accessLevel) => {
    if (accessLevel === "Selected Members") return "#f2994a";
    else return "#27ae60"
}

export const a11yProps = (index) => {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}
export const quarterOfTheYear = (currentDate) => {
    const month = currentDate.getMonth() + 1;
    const quarterInNumber = (Math.ceil(month / 3));
    if (quarterInNumber === 1) return 'Q1';
    else if (quarterInNumber === 2) return 'Q2';
    else if (quarterInNumber === 3) return 'Q3';
    else if (quarterInNumber === 4) return 'Q4';
}