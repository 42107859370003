import { VendorUsers } from '../../constants/vendors';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const getVendorUsers = async (
  vendorId: number,
): Promise<{
  data?: {
    assignedVendorUsers: VendorUsers[];
    unassignedVendorUsers: VendorUsers[];
  } | null;
  message?: string;
  error?: any;
  success: boolean;
}> => serviceInterceptor.get(`/vendors/${vendorId}/users`).then((result) => {
  return result.data
});

export default getVendorUsers;
