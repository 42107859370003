export const baseUrl = `${process.env.REACT_APP_API_URL || ''}/api/v1`;
export const GET_ROLES = `${baseUrl}/roles`;
export const URL_LOGIN = `${baseUrl}/auth`;
export const GET_STATE_LISTS = `${baseUrl}/states`;
export const URL_STATE_LIST = `${baseUrl}/states`;
export const FORGOT_PASSWORD = `${baseUrl}/forgotpassword`;
//Member
export const baseMembersUrl = `${baseUrl}/members`;
export const GET_USERS_API = `${baseUrl}/users`;
export const DELETE_MEMBER_USER_API = `${baseUrl}/user/deleteUser`;
export const GET_MEMBER_VENDORS_API = `${baseUrl}/member/vendor`;
export const UNASSIGN_MEMBER_VENDOR_API = `${baseUrl}/member/vendorunassign`;
export const GET_MEMBER_LOCATIONS_API = `${baseUrl}/location/getMemberLocations/`;
export const DELETE_MEMBER_LOCATIONS_API = `${baseUrl}/members`;
// Vendor
export const baseVendorsUrl = `${baseUrl}/vendors`;
export const ASSIGN_VENDORS_API = `${baseUrl}/vendor/`;
export const GET_VENDORS_DC_API = `${baseUrl}/vendor/`;
export const GET_VENDORS_SALES_API = `${baseUrl}/getUsers`;
export const GET_VENDORS_USERS_API = `${baseUrl}/users`;
export const GET_VENDORS_MEMBERS_API = `${baseUrl}/vendor/`;
export const GET_VENDORS_LINKS_API = `${baseUrl}/vendor/link/list`;
export const GET_VENDORS_KPI_API = `${baseUrl}/kpi-agreements`;
export const DELETE_VENDOR_DC_API = `${baseUrl}/vendor/`;
export const DELETE_VENDOR_USER_API = `${baseUrl}/user/deleteUser`;
export const DELETE_VENDOR_MEMBER_API = `${baseUrl}`;
export const DELETE_VENDOR_LINK_API = `${baseUrl}/vendor/link/delete`;
export const DELETE_VENDOR_KIP_API = `${baseUrl}/vendor/kpi/delete`;
export const GET_KPI_OVERVIEW = `${baseUrl}/vendor/kpioverview`;
export const GET_KPI_YEARS = `${baseUrl}/members`;
// User
export const baseUserUrl = `${baseUrl}/user/`;
export const GET_MEMBER_VENDOR_API = `${baseUrl}/member/vendor`;
export const URL_USER_ROLE = `${baseUrl}/roles`;
export const URL_USER_CREATE = `${baseUrl}/users`;
export const URL_USER_UPDATE = `${baseUrl}/user/`;
export const GET_VENDOR_LINK_TYPES_API = `${baseUrl}/vendor/link/types`;
export const CREATE_VENDOR_LINK_API = `${baseUrl}/vendor/link/add`;
export const UPDATE_VENDOR_LINK_API = `${baseUrl}/vendor/link/edit`;
export const URL_VENDOR_CREATE_KPI = `${baseUrl}/vendor/kpi/add`;
export const URL_VENDOR_UPDATE_KPI = `${baseUrl}/vendor/kpi/edit`;
export const DISTRIBUTION_CENTER_API = `${baseUrl}/vendor/`;
// Members
export const GET_MEMBER_LIST_API = `${baseUrl}/members`;
//Document
export const DOCUMENT_RECORDS_API = `${baseUrl}/documents`;
export const DOCUMENT_CATEGORY_API = `${baseUrl}/document-types`;
export const ADD_NEW_DISTRIBUTION_CENTER = `${baseUrl}/vendor/`;
// Manage Ads
export const baseAdsTypesUrl = `${baseUrl}/ad-types`;
export const baseAdsUrl = `${baseUrl}/ads`;
// Products
export const baseBrandUrl = `${baseUrl}/brands`;
export const GET_PRODUCT_NAME_BY_BRAND = `${baseBrandUrl}/:brandId/products`;
//Community
export const COMMUNITY_CATEGORY_API = `${baseUrl}/channels`;
export const CREATE_COMMUNITY_CATEGORY_API = `${baseUrl}/channel`;
export const DISCUSSION_API = `${baseUrl}/discussions`;
//Rebates
export const UPLOAD_PRODUCT_REBATES = `${baseUrl}/rebate-product-list-records`;
export const GET_PRODUCT_REBATES_QUARTER = `${baseUrl}/quarters`;
export const UPDATE_PRODUCT_REBATE_NOTES_API = `${baseUrl}/rebates`;
export const EXPORT_PRODUCT_REBATES = `${baseUrl}/rebate-product-list-records`;
export const GET_MEMBER_REBATES_QUARTER_LIST = `${baseUrl}/member-rebate-documents-records`;
export const EXPORT_MEMBER_REBATES = `${baseUrl}/member-rebate-documents-records/export`;
export const EXPORT_MEMBER_ADMIN_REBATES = `${baseUrl}/member-rebate-documents-records/member-admin`;
//Member Admin Rebates
export const GET_REBATE_MEMBER_PRODUCT_LIST = `${baseUrl}/member-rebate-documents-records`;
//Dashboard
export const CONFIRM_REBATE_RECEIPT = `${baseUrl}/receipts`;