import { FC } from 'react';
import Button from '@material-ui/core/Button';
import { ExportRebateReceiptsModal } from './ExportRebateReceiptsModal';
import { CustomModal } from '../../../../../components/CustomModal';
import AuthorizationService from '../../../../../authorization/AuthorizationService';

export const ExportRebateReceipts: FC<any> = ({
  rebateReceipts,
  showClaimRebateExportModal,
  handleRebateReceiptExportQuarter,
  handleRebateReceiptExportYear,
  onExportRebateReceipts,
  clearRebateReceiptExportModal,
}) => {

  const showExport = AuthorizationService.isAuthorizedHTML('rebateReceiptsExport');

  return (
    <div>
      {showExport && <Button
        onClick={() => showClaimRebateExportModal(true)}
        variant="contained"
        size="small"
        className="btn-add-blue"
      >
        <span className="fas fa-file-export"></span>
        <span>Export</span>
      </Button>}

      <CustomModal
        showModal={rebateReceipts?.isClaimRebatesExportModal}
        closeModal={() => {
          showClaimRebateExportModal(false);
          clearRebateReceiptExportModal();
        }}
        modalTitle="Export Request Rebate"
        isCancelButton={true}
        isSubmitButton={true}
        isSubmitText={'Submit'}
        onSubmit={onExportRebateReceipts}
        isLoading={rebateReceipts?.rebateReceiptExportSpinner ?? false}
      >
        <ExportRebateReceiptsModal
          rebateReceipts={rebateReceipts}
          handleRebateReceiptExportQuarter={handleRebateReceiptExportQuarter}
          handleRebateReceiptExportYear={handleRebateReceiptExportYear}
        />
      </CustomModal>
    </div>
  );
};

export default ExportRebateReceipts;
