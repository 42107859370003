import { FC } from 'react';
import { TextField } from '@material-ui/core';

export const InputWithFloatingLabel: FC<{
  labelName: string;
  inputValue: string | number;
  handleOnChange: (string: any) => void;
  errorStatus?: boolean;
  errorMessage: string | null;
  required?: boolean;
  isLoading: boolean;
}> = ({
  labelName,
  inputValue,
  handleOnChange,
  errorStatus,
  errorMessage,
  required = false,
  isLoading,
}) => (
  <TextField
    placeholder={labelName}
    variant="outlined"
    className="text-box"
    label={labelName}
    value={inputValue}
    error={errorStatus}
    id={errorStatus ? 'standard-error' : 'outlined-helperText'}
    helperText={errorStatus ? errorMessage : ''}
    onChange={(e) => handleOnChange(e.target.value)}
    required={required}
    disabled={isLoading}
  />
);

export default InputWithFloatingLabel;
