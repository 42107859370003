import {
  TableHead,
  TableRow,
  TableCell,
  TableSortLabel,
} from '@material-ui/core';
import { FC, SyntheticEvent } from 'react';
import { headCells } from './util';

export const EnhancedTableHead: FC<{
  classes: {
    visuallyHidden: string;
  };
  order: 'asc' | 'desc';
  orderBy: string;
  onRequestSort: (event: SyntheticEvent, property: string) => void;
}> = ({ classes, order, orderBy, onRequestSort }) => {

  const createSortHandler = (property: string) => (event: SyntheticEvent) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={
                headCell.enableSorting ? createSortHandler(headCell.id) : undefined
              }
              hideSortIcon={!headCell.enableSorting}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export type EnhancedTableHeadType = typeof EnhancedTableHead;
