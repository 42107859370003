import React from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import NotFoundImg from '../../../images/NotAssigned.svg';

export const NotAssign = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="user-notfound"
    >
      <grid>
        <img src={NotFoundImg}></img>
      </grid>
      <grid className="user-not-found-text">You're not assigned to any member. Please contact your administration or Buy-Right Admin.</grid>
      {/* <grid className="user-not-found-home">
        <NavLink className="not-found-back" to={'/'}>
          Go back
        </NavLink>
      </grid> */}
    </Grid>
  );
};
