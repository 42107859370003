import {removePhoneNoFormat} from "./constants";

export const formatPhoneNumber = (value) => {
  if (!value) return value;
  const phoneNumber = value.replace(/[^\d]/g, "");
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength < 4) return phoneNumber;
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }
  return `(${phoneNumber.slice(0, 3)}) 
  ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}


export const isValidPhoneNo = (phoneNo) => {
  const numberOnly = new RegExp(/[0-9]+$/);
  const value = removePhoneNoFormat(phoneNo);
  return value.length != 10 || !numberOnly.test(value) ? false : true;
};
