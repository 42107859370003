import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderImage from '../../../images/bg-header-2.png';

export const MemberHeader: FC<{
  isAddMode: boolean;
  handleGoBack: () => void;
}> = ({ isAddMode, handleGoBack }) => {
  return (
    <Grid container className="details-header">
      <div className="details-header-img">
        <img src={HeaderImage} />

        <Grid
          container
          spacing={2}
          alignItems="center"
          className="details-header-content"
        >
          <Grid item xs={12} sm={6}>
            <div
              className="details-header-left"
              style={{ display: 'inline-block' }}
            >
              <div
                className="details-header-back"
                onClick={() => handleGoBack()}
              >
                <i className="fas fa-arrow-left"></i>
                <span>Back</span>
              </div>
              <h2
                className="details-header-title"
                style={{ paddingTop: '22px' }}
              >
                <span>{isAddMode ? 'Add New Member' : 'Edit Member'}</span>
              </h2>
            </div>
          </Grid>
        </Grid>
      </div>
    </Grid>
  );
};
