import { baseMembersUrl, UPDATE_MEMBER_LOCATION_DELETE_ERROR } from "../../constants";

export const deleteMemberLocationById = async (parentid, memberid) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(`${baseMembersUrl}/${parentid}/locations/${memberid}`, {
      method: "DELETE",
      headers: new Headers({
        "x-access-token": user.accessToken,
        "Content-Type": "application/json",
      }),
    });
    if (!response.ok) throw UPDATE_MEMBER_LOCATION_DELETE_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_MEMBER_LOCATION_DELETE_ERROR;
  }
};
