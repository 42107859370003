import {
    UPDATE_VENDOR_LINKS_ERROR,
    UPDATE_VENDOR_LINKS_LIST,
    UPDATE_SELECTED_VENDOR_LINK,
    UPDATE_DELETE_VENDOR_FLAG,
    CLEAR_VENDOR_LINKS_LIST,
    UPDATE_VENDOR_LINKS_TYPE_LIST,
    GET_VENDOR_LINK_TYPES_ERROR,
    UPDATE_VENDOR_LINK_TYPE,
    UPDATE_VENDOR_LINK_TEXT,
    UPDATE_VENDOR_LINK_URL,
    VendorLinkTypeError,
    VendorLinksError,
    UnAssignVendorLinkError,
    VendorLinkUnAssignSuccess,
    VendorLinkAddSuccess,
    VendorLinkEditSuccess,
    CreteVendorLinkError,
    FetchError,
    UPDATE_VENDOR_LINK_TEXT_ERROR,
    UPDATE_VENDOR_LINK_TYPE_ERROR,
    UPDATE_VENDOR_LINK_URL_ERROR,
    CLEAR_VENDOR_MODAL,
    SHOW_VENDOR_FORM_SPINNER,
    HIDE_VENDOR_FORM_SPINNER,
    UPDATE_VENDOR_LINK_IS_EDIT,
    UPDATE_VENDOR_LINK_ID,
    UPDATE_FORM_INPUTS,
    UPDATE_VENDOR_LINKS_SPINNER
} from "../../constants";

import {showModal, closeModal} from '../modal';

import {
    getVendorLinksList,
    deleteVendorLink,
    getVendorLinkTypes,
    createVendorLink,
    updateVendorLink
} from '../../api/vendors';

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const clearVendorLinkList = () => ({
    type: CLEAR_VENDOR_LINKS_LIST
})

export const getVendorLinks = () => async (dispatch,getState) => {

    const {vendor} = getState();
    const {vendorID} = vendor
    dispatch({
        type: UPDATE_VENDOR_LINKS_SPINNER,
        spinner: true,
    });
    try {
        const vendorLinksList = await getVendorLinksList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_LINKS_LIST,
            vendorLinksList: vendorLinksList.data
        })
        dispatch({
            type: UPDATE_VENDOR_LINKS_SPINNER,
            spinner: false,
        });
    } catch (error) {
        dispatch({
            type: UPDATE_VENDOR_LINKS_SPINNER,
            spinner: false,
        });
        if (error === UPDATE_VENDOR_LINKS_ERROR) {
            toast.error(VendorLinksError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const updateDeleteVendorLink = (linkId) => async (dispatch) => {
    dispatch({
        type: UPDATE_SELECTED_VENDOR_LINK,
        slectedLinkID: linkId
    });
    dispatch({
        type: UPDATE_DELETE_VENDOR_FLAG,
        isDeleteUser: true
    });
};

export const handleDeleteVendorLink = () => async (dispatch, getState) => {
    const {vendor} = getState();
    const {vendorID, slectedLinkID} = vendor;
    dispatch({
        type: UPDATE_VENDOR_LINKS_SPINNER,
        spinner: true,
    });
    try {
        const response = await deleteVendorLink({
            vendorID,
            slectedLinkID
        });
        const vendorLinksList = await getVendorLinksList(vendorID);
        dispatch({
            type: UPDATE_VENDOR_LINKS_LIST,
            vendorLinksList: vendorLinksList.data
        })
        dispatch({
            type: UPDATE_SELECTED_VENDOR_LINK,
            slectedLinkID: null
        });
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        dispatch({
            type: UPDATE_VENDOR_LINKS_SPINNER,
            spinner: false,
        });
        const {success} = response;
        success ? toast.success(VendorLinkUnAssignSuccess) : null;
    } catch (error) {
        dispatch({
            type: UPDATE_SELECTED_VENDOR_LINK,
            slectedLinkID: null
        });
        dispatch({
            type: UPDATE_DELETE_VENDOR_FLAG,
            isDeleteUser: false
        });
        dispatch({
            type: UPDATE_VENDOR_LINKS_SPINNER,
            spinner: false,
        });
        if (error) {
            toast.error(UnAssignVendorLinkError)
        }
    }
}

export const showAddLinkModal = () => async (dispatch) => {
    dispatch(showModal());
}

export const closeLinkModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_VENDOR_MODAL
    });
    dispatch({
        type: UPDATE_VENDOR_LINK_IS_EDIT,
        isEdit: false
    });
    dispatch(closeModal());
}

export const getVendorLinkTypeList = () => async (dispatch) => {
    try {
        const vendorLinkTypeList = await getVendorLinkTypes();
        dispatch({
            type: UPDATE_VENDOR_LINKS_TYPE_LIST,
            vendorLinkTypeList: vendorLinkTypeList.data
        })
    } catch (error) {
        if (error === GET_VENDOR_LINK_TYPES_ERROR) {
            toast.error(VendorLinkTypeError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const onChangeLinkType = (linkType) => async (dispatch) => {
    // const formatedLinkType = linkType !== '0' ? parseInt(linkType) : '0';
    // dispatch({
    //     type: UPDATE_VENDOR_LINK_TYPE,
    //     linkType: formatedLinkType
    // });
    // dispatch({
    //     type: UPDATE_FORM_INPUTS,
    //     isFormUpdated: true
    // });
    // if (linkType !== '0') {
    //     dispatch({
    //         type: UPDATE_VENDOR_LINK_TYPE_ERROR,
    //         linkTypeError: false
    //     });
    // } else {
    //     dispatch({
    //         type: UPDATE_VENDOR_LINK_TYPE_ERROR,
    //         linkTypeError: true
    //     });
    // }


    //New implementation
    if (linkType !== null && linkType.toString().trim().length > 0) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TYPE_ERROR,
            linkTypeError: false
        });
    } else {
        dispatch({
            type: UPDATE_VENDOR_LINK_TYPE_ERROR,
            linkTypeError: true
        });
    }

    dispatch({
        type: UPDATE_VENDOR_LINK_TYPE,
        linkType: linkType
    });
    dispatch({
        type: UPDATE_FORM_INPUTS,
        isFormUpdated: true
    });
}

export const onChangeLinkText = (value) => async (dispatch) => {
    if (value !== null) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TEXT_ERROR,
            linkTextError: false
        });
    }
    dispatch({
        type: UPDATE_VENDOR_LINK_TEXT,
        linkText: value
    });
    dispatch({
        type: UPDATE_FORM_INPUTS,
        isFormUpdated: true
    });

}

export const onChangeLinkUrl = (value) => async (dispatch) => {
    if (value !== null) {
        dispatch({
            type: UPDATE_VENDOR_LINK_URL_ERROR,
            linkUrlError: false
        });
    }
    dispatch({
        type: UPDATE_VENDOR_LINK_URL,
        linkUrl: value
    });
    dispatch({
        type: UPDATE_FORM_INPUTS,
        isFormUpdated: true
    });
}


const validateLinkType = (linkType) => {
    if (linkType === null) return true;
    else {
        return false
    }
}

const validateLinkText = (linkText) => {
    const textRegex = (text) => {
        if (text === null) return false;
        else if (text.startsWith(' ')) return false;
        else if ((text && text.charAt(text.length - 1) === ' ')) return false;
        else return true
    };

    const isValidText = textRegex(linkText)
    if (!isValidText) {
        return true;
    } else {
        return false
    }
}

const validateLinkUrl = (linkUrl) => {
    const expression = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/;
    const regex = new RegExp(expression);

    if (linkUrl === null) return true;
    else if (linkUrl.match(regex)) {
        return false
    }
    else return true;
}

export const onAddNewLink = () => async (dispatch, getState) => {
    const {vendorLinkModal, vendor} = getState();
    const {vendorID} = vendor;
    const {linkType, linkText, linkUrl} = vendorLinkModal;
    const isValidLinkType = validateLinkType(linkType);
    const isValidLinkText = validateLinkText(linkText);
    const isValidLinkURL = validateLinkUrl(linkUrl);
    const isError = isValidLinkType || isValidLinkText || isValidLinkURL;
    if (isValidLinkType) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TYPE_ERROR,
            linkTypeError: true
        });
    }
    if (isValidLinkText) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TEXT_ERROR,
            linkTextError: true
        });
    }
    if (isValidLinkURL) {
        dispatch({
            type: UPDATE_VENDOR_LINK_URL_ERROR,
            linkUrlError: true
        });
    }
    if (isError) {
        dispatch({
            type: HIDE_VENDOR_FORM_SPINNER,
            spinner: false
        });
    } else {
        dispatch({
            type: SHOW_VENDOR_FORM_SPINNER,
            spinner: true
        });
    }
    try {
        if (!isError) {
            const response = await createVendorLink({
                vendorID,
                linkType,
                linkText,
                linkUrl
            });
            const {success} = response;
            success ? toast.success(VendorLinkAddSuccess) : null;
            if (success) {
                const vendorLinksList = await getVendorLinksList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_LINKS_LIST,
                    vendorLinksList: vendorLinksList.data
                })
            }
            dispatch({
                type: HIDE_VENDOR_FORM_SPINNER,
                spinner: false
            });
            if (success) {
                dispatch({
                    type: UPDATE_FORM_INPUTS,
                    isFormUpdated: false
                });
                dispatch({
                    type: CLEAR_VENDOR_MODAL
                });
            }
            dispatch(closeModal());
        }

    } catch (error) {
        dispatch({
            type: HIDE_VENDOR_FORM_SPINNER,
            spinner: false
        });
        if (!error) {
            dispatch({
                type: UPDATE_FORM_INPUTS,
                isFormUpdated: false
            });
            dispatch({
                type: CLEAR_VENDOR_MODAL
            });
        }
        if (error) {
            toast.error(CreteVendorLinkError)
        }
    }
}

export const showEditLinkModal = (selectedLink) => async (dispatch) => {
    const {id, type, text, url} = selectedLink
    dispatch(showModal());
    dispatch({
        type: UPDATE_VENDOR_LINK_IS_EDIT,
        isEdit: true
    });
    dispatch({
        type: UPDATE_VENDOR_LINK_TYPE,
        linkType: type
    });
    dispatch({
        type: UPDATE_VENDOR_LINK_TEXT,
        linkText: text
    });
    dispatch({
        type: UPDATE_VENDOR_LINK_ID,
        linkId: id
    });
    dispatch({
        type: UPDATE_VENDOR_LINK_URL,
        linkUrl: url
    });
}

export const onEditLink = () => async (dispatch, getState) => {
    const {vendorLinkModal, vendor} = getState();
    const {vendorID} = vendor;
    const {linkId, linkType, linkText, linkUrl} = vendorLinkModal;
    const isValidLinkType = validateLinkType(linkType);
    const isValidLinkText = validateLinkText(linkText);
    const isValidLinkURL = validateLinkUrl(linkUrl);
    const isError = isValidLinkType || isValidLinkText || isValidLinkURL;
    if (isValidLinkType) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TYPE_ERROR,
            linkTypeError: true
        });
    }
    if (isValidLinkText) {
        dispatch({
            type: UPDATE_VENDOR_LINK_TEXT_ERROR,
            linkTextError: true
        });
    }
    if (isValidLinkURL) {
        dispatch({
            type: UPDATE_VENDOR_LINK_URL_ERROR,
            linkUrlError: true
        });
    }
    if (isError) {
        dispatch({
            type: HIDE_VENDOR_FORM_SPINNER,
            spinner: false
        });
    } else {
        dispatch({
            type: SHOW_VENDOR_FORM_SPINNER,
            spinner: true
        });
    }
    try {
        if (!isError) {
            const response = await updateVendorLink({
                vendorID,
                linkId,
                linkType,
                linkText,
                linkUrl
            });
            const {success} = response;
            success ? toast.success(VendorLinkEditSuccess) : null;
            if (success) {
                const vendorLinksList = await getVendorLinksList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_LINKS_LIST,
                    vendorLinksList: vendorLinksList.data
                })
            }
            dispatch({
                type: HIDE_VENDOR_FORM_SPINNER,
                spinner: false
            });
            dispatch({
                type: UPDATE_VENDOR_LINK_IS_EDIT,
                isEdit: false
            });
            dispatch({
                type: CLEAR_VENDOR_MODAL
            });
            dispatch(closeModal());
            dispatch({
                type: UPDATE_FORM_INPUTS,
                isFormUpdated: false
            });
        }

    } catch (error) {
        dispatch({
            type: HIDE_VENDOR_FORM_SPINNER,
            spinner: false
        });
        dispatch({
            type: UPDATE_FORM_INPUTS,
            isFormUpdated: false
        });
        dispatch({
            type: UPDATE_VENDOR_LINK_IS_EDIT,
            isEdit: false
        });
        dispatch({
            type: CLEAR_VENDOR_MODAL
        });
        if (error) {
            toast.error(CreteVendorLinkError)
        }
    }

}