import { UPDATE_STATE_LIST, UPDATE_USER_ROLE_LIST } from "../constants";

const initialState = {
  statesList: [],
  senderEmailId: "dhanraaj.sreenagesh@goaugment.io",
  rolesList: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_STATE_LIST:
      return {
        ...state,
        statesList: action.statesList,
      };
    case UPDATE_USER_ROLE_LIST:
      return {
        ...state,
        rolesList: action.list,
      };
    default:
      return state;
  }
};
