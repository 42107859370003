import React from 'react';
import Grid from '@material-ui/core/Grid';

import '../../../css/fonts.css';
import '../../../css/all.min.css';
import '../../../css/style.css';
import './notAssigned.scss';
import { NotAssign } from './NotAssigned';

export const NotAssignedToMember = () => {
  return (
    <div>
      <Grid item xs={12} className="body-container">
        <NotAssign/>
      </Grid>
    </div>
  );
};
