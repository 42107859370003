import { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Loader } from '../utils';
import Box from '@material-ui/core/Box';
import { textTransform } from '@mui/system';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times"></i>
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    backgroundColor: '#EDF2F7',
  },
}))(MuiDialogActions);

export const WarningPopup = ({isMemberDelete = false,assignedMember='',...props}) => {
  const { spinner, name } = props;
  const [open, setOpen] = useState(props.open);
  const [type, setType] = useState(props.type || 'cancel');

  useEffect(() => {
    setType(props.type);
  }, [props.type]);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  const handleClose = () => {
    setOpen(false);
    props.handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="br-alert"
    >
      <div className="relative-wrapper">
        {spinner && (
          <div className="loader-wrapper">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        )}
        <DialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        ></DialogTitle>
        <DialogContent>
          <div className="br-alert">
            <div className="br-alert-icon">
              <i className="fas fa-exclamation-triangle"></i>
            </div>
            <h3>Are you sure ? </h3>
            {type == 'cancel' ? (
              <p>You want to Cancel ? You may lose the filled data</p>
            ) : null}
            {type == 'delete' ? (
              <>
                <p>{`Do you want to delete ${name} from Buy Right?`} </p>
                {
                  assignedMember !== '' && <><p>Please remove this member user <span style={{textTransform:'uppercase'}}>{name},</span> from their linked Members on the Member page.</p></>
                }
              </>
            ) : type == 'unassign' ? (
              <p>Do you want to unassign ?</p>
            ) : type == 'assigned' ? (
              <p>
                {`The ${name} from buy-right will be deleted all of its users also will be unassigned.`}
              </p>
            ) : null}
            {type == 'archive' && <p>{`You want to archive the ${name} ?`} </p>}
          </div>
        </DialogContent>
      </div>
      <DialogActions>
        <Button
          onClick={props.handleProceed}
          className={spinner ? 'alert-cancel button-disable' : 'alert-cancel'}
          variant="contained" 
          disabled={assignedMember !== '' ? true : false}
        >
          {type == 'unassign'
            ? 'Yes, Unassign'
            : (type == 'delete' || type == 'assigned')
            ? 'Yes, Delete'
            : 'Yes, Cancel'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};