import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useActor } from '@xstate/react';
import { FC, useMemo } from 'react';
import { ActorRefFrom } from 'xstate';
import {
  StyledDialogActions,
  StyledDialogContent,
  StyledDialogTitle,
} from '../../../components/styledDialogChildren';
import { OpportunitiesUploadMachine } from './machines/opportunitiesUploadMachine';
import { DateTime } from 'luxon';
import UploadImg from '../../../images/file-upload.svg';
import { getFileSize } from '../../../utils/fileInfo';
import { Loader } from '../../../utils';

export const OpportunitiesUploadModal: FC<{
  opportunitiesCreateUploadActor: ActorRefFrom<OpportunitiesUploadMachine>;
}> = ({ opportunitiesCreateUploadActor }) => {
  const [state, send] = useActor(opportunitiesCreateUploadActor);

  const disabled = useMemo(() => {
    if (!state.context.opportunitiesDocumentFile) return true;
    return state.hasTag('loading');
  }, [!state.context.opportunitiesDocumentFile, state.hasTag('loading')]);

  return (
    <Dialog
      open
      aria-labelledby="customized-dialog-title"
      className="dialog-box"
    >
      <StyledDialogTitle onClose={() => send('cancel')}>
        Upload rebate opportunities document
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className="upload-rebateform">
          <Grid container spacing={2} className="upload-rebateform-grid">
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" className="select-box">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Select Fiscal Quarter
                </InputLabel>
                <Select
                  value={state.context.quarter}
                  onChange={(event) => {
                    const quarter = event.target.value;
                    if (
                      quarter === 1 ||
                      quarter === 2 ||
                      quarter === 3 ||
                      quarter === 4
                    ) {
                      send({ type: 'update quarter', quarter });
                    }
                  }}
                  label="Select Quarter"
                  style={{ letterSpacing: '0.0958em' }}
                >
                  {[1, 2, 3, 4].map((value) => {
                    return (
                      <option key={value} value={value}>
                        Quarter {value}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" className="select-box">
                <InputLabel htmlFor="outlined-age-native-simple">
                  Select Year
                </InputLabel>
                <Select
                  value={state.context.year}
                  onChange={(event) =>
                    send({
                      type: 'update year',
                      year: event.target.value as number,
                    })
                  }
                  label="Select Year"
                >
                  {[
                    DateTime.now().plus({ years: 1 }).year,
                    DateTime.now().year,
                    DateTime.now().minus({ years: 1 }).year,
                  ].map((year) => {
                    return (
                      <option key={year} value={year}>
                        {year}
                      </option>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="rebates-file-upload">
            <Grid container spacing={2} justifyContent={'center'}>
              <Grid item xs={12} lg={6}>
                {state.context.opportunitiesDocumentFile === null ? (
                  <Button className="file-filed-document" component="label" style={{ cursor: 'pointer' }}>
                    <img src={UploadImg} />
                    <div className="file-filed-text-size-sm">
                      Upload XLS OR XLSX files
                    </div>
                    <div className="file-filed-text-size-md">
                      Drop your files here.{' '}
                    </div>
                    <div>
                      or{' '}
                      <span className="file-filed-text-size-highlight">
                        browse
                      </span>
                    </div>
                    <input
                      accept=".xls,.xlsx"
                      type="file"
                      id="fileUpload"
                      title=""
                      value=""
                      onChange={(event) => {
                        if ((event.target.files?.length ?? 0) > 0) {
                          const file = event.target.files?.[0] as File;
                          if (
                            file.name.split('.')?.[1] === 'xls' ||
                            file.name.split('.')?.[1] === 'xlsx'
                          ) {
                            send({
                              type: 'select file',
                              file: event.target.files?.[0] as File,
                            });
                          }
                        }
                      }}
                    />
                  </Button>
                ) : (
                  <div className="edit-doc-section">
                    <div className="edit-doc-section-box">
                      <div className="documents-grid-img">
                        <i className="far fa-file-excel" />
                        <span className="documents-doc-type doc-xls">
                          {
                            state.context.opportunitiesDocumentFile?.name.split(
                              '.',
                            )?.[1]
                          }
                        </span>
                      </div>

                      <div className="edit-doc-section-name">
                        {state.context.opportunitiesDocumentFile?.name}
                      </div>
                      <div className="edit-doc-section-size">
                        {getFileSize(state.context.opportunitiesDocumentFile?.size ?? 0)}
                      </div>
                      <div className="edit-doc-section-buttons">
                        <Button
                          className="remove-button"
                          onClick={() => send('remove file')}
                        >
                          Remove
                        </Button>
                        <label className="replace-button" htmlFor="replaceFile">
                          Replace
                        </label>
                        <input
                          accept=".xls,.xlsx"
                          type="file"
                          id="replaceFile"
                          hidden
                          onChange={(event) => {
                            if ((event.target.files?.length ?? 0) > 0) {
                              const file = event.target.files?.[0] as File;
                              if (
                                file.name.split('.')?.[1] === 'xls' ||
                                file.name.split('.')?.[1] === 'xlsx'
                              ) {
                                send({
                                  type: 'select file',
                                  file: event.target.files?.[0] as File,
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div className="action-button">
            <button
              className="cancel-user-button"
              onClick={() => send('cancel')}
            >
              Cancel
            </button>
            <Button
              autoFocus
              onClick={() => send('submit file')}
              className={
                disabled
                  ? 'invite-user-button button-disable'
                  : 'invite-user-button'
              }
              disabled={disabled}
            >
              Upload selected document {state.hasTag('loading') && <Loader size={20} />}
            </Button>
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OpportunitiesUploadModal;
