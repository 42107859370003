import {connect} from "react-redux";

import {getUserRoleList} from "../../../actions/common";
import {
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage
} from "../../../actions/pagination"
import {
  viewMember,
  getMemberUsers,
  getMemberVendors,
  getMemberLocations,
  deleteMember,
  clearMemberDetails,
  clearAddMemberUser,
  showAddMemberUserModal,
  showAssignMemberUserModal,
  hideMemberUserModal,
  onChangeAddMemberUserId,
  onChangeAddMemberUserFname,
  onChangeAddMemberUserLname,
  onChangeAddMemberUserMail,
  onChangeAddMemberUserRole,
  onAddMemberUser,
  handleEditMemberUser,
  handleDeleteMemberUser,
  onDeleteMemberUser,
  hideAssignMemberUserModal,
  onEditMemberUser,
  updateDeleteMemberLocation,
  handleDeleteMemberLocationClose,
  onDeleteMemberLocation,
  updateMemberInActive,
  getMemberLogin,
  handleDeleteUserMember,
  onDeleteUserMember,
  viewAsMember,
  fetchAssignedMemberUsers
} from "../../../actions/members";
import {MemberDetailComponent} from "./MemberDetails";

const mapStateToProps = (state: any) => {
  const {common, members, memberUserForm, modal, paginationReducer} = state;
  return {
    common,
    members,
    memberUserForm,
    modal,
    paginationReducer
  };
};

export const MemberDetailPage = connect(mapStateToProps, {
  getUserRoleList,
  viewMember,
  getMemberUsers,
  getMemberVendors,
  getMemberLocations,
  deleteMember,
  clearMemberDetails,
  clearAddMemberUser,
  showAddMemberUserModal,
  showAssignMemberUserModal,
  hideMemberUserModal,
  onChangeAddMemberUserId,
  onChangeAddMemberUserFname,
  onChangeAddMemberUserLname,
  onChangeAddMemberUserMail,
  onChangeAddMemberUserRole,
  onAddMemberUser,
  handleEditMemberUser,
  handleDeleteMemberUser,
  onDeleteMemberUser,
  hideAssignMemberUserModal,
  onEditMemberUser,
  updateDeleteMemberLocation,
  handleDeleteMemberLocationClose,
  onDeleteMemberLocation,
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage,
  updateMemberInActive,
  getMemberLogin,
  handleDeleteUserMember,
  onDeleteUserMember,  
  viewAsMember,
  fetchAssignedMemberUsers
})(MemberDetailComponent);
