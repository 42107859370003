import { assign } from '@xstate/immer';
import { createMachine } from 'xstate';

export const rebatesReceiptTableMachine =
  /** @xstate-layout N4IgpgJg5mDOIC5QCcwCMCGAXOACVAxmAJYAOWuWGaANmAHQ0D2GExAdlJchu7AGZhkuQXADEEJuwYcAbkwDWDZq268BQkWDiJQpJrGJZiU3SAAeiAMwBWACz0AbAA4ADAHY7NgIxWAnK42rs4ANCAAnoh+DjYu7lYATHbezglW3t4JAL5ZYaiYOLD4YERkFFS0yixsnGp8gsKisGJCyEzI9KQ02PztALaM1XUajdrwSCD6hsamE5YINu70Hs6OVu6uaXYbiWGRCI703n5JCTY+dla2gY45eejYeIQk5JTUdPTEEHRiAK6kEEeuFg7QoSn2egMRhM7DM8zcrno8WcVm27iCfj8GT2iAAtPZ6AlMZkzn4XPZbrkQPlHkVnmU3pVPt8wH8AUCMARjLIwLgABZgVhCMxTaGzUDzdxuehedzeQJ2aJneI4hAJVbLRXeOyOeLuRxnKx3akPQrFUqvCofVrtMSoLDIcK4FQQYYNLQ6CaimawuZ47w2Pz0CmBZx2FF+dxS1W4jUohJyqwuBIJRyuQI5KnsJgQT0oU1PEovcrvKqsDhcB3qd1NEVQn1wvEJejOE72bwuRzyjzqmNXREJqW+WxrbXeY00s30y2l5l0OvTGGNhDE4PrQLHFHxJOqsfByNEjtXVOXbJUyeFi0lpk25ALsW+iV4pP0fyONbpjvuM5eXfOZxIu+GyYsmZxnvcBSXsWjLzl69ZLn6CC4ikVjBjqobhv4UahBEeJ2K4qGDs48r+LENi2DYmZZEAA */
  createMachine(
    {
      context: { 'transfer fees': [] },
      tsTypes: {} as import('./rebatesReceiptTableMachine.typegen').Typegen0,
      schema: {
        context: {} as {
          'transfer fees': { name: string; amount: number }[];
        },
        events: {} as { type: 'retry load transfer fees' },
        services: {} as {
          'load transfer fees': {
            data: { name: string; amount: number }[];
          };
        },
      },
      id: 'rebates receipt table',
      initial: 'loading transfer fees',
      states: {
        'loading transfer fees': {
          tags: 'loading',
          description: 'Load the transfer fee list.',
          invoke: {
            src: 'load transfer fees',
            id: 'load transfer fees',
            onDone: [
              {
                actions: 'assign transfer fees',
                target: 'idle',
              },
            ],
            onError: [
              {
                target: 'error',
              },
            ],
          },
        },
        idle: {},
        error: {
          tags: 'error',
          description:
            'An error occurred loading the resource and the application could not display data.',
          on: {
            'retry load transfer fees': {
              description: 'Retry loading the transfer fee info again.',
              target: 'loading transfer fees',
            },
          },
        },
      },
    },
    {
      actions: {
        'assign transfer fees': assign((context, event) => {
          context['transfer fees'] = event.data;
        }),
      },
      services: {
        'load transfer fees': () =>
          Promise.resolve([{ name: 'standard', amount: 500 }]), // TODO Don't use hardcoded data, create an endpoint to retrieve the transfer fees
      },
    },
  );

export type RebatesReceiptTableMachine = typeof rebatesReceiptTableMachine;

export default rebatesReceiptTableMachine;
