import {
  Button,
  Dialog,
  FormControl,
  Grid,
  InputLabel,
  Select,
} from '@material-ui/core';
import { useActor } from '@xstate/react';
import { FC, useMemo } from 'react';
import { ActorRefFrom } from 'xstate';
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from '../../../components/styledDialogChildren';
import { getFileSize, Loader } from '../../../utils';
import { OpportunitiesEditMachine } from './machines/opportunitiesEditMachine';
import UploadImg from '../../../images/file-upload.svg';

export const OpportunitiesEditModal: FC<{
  opportunitiesEditUploadActor: ActorRefFrom<OpportunitiesEditMachine>;
}> = ({ opportunitiesEditUploadActor }) => {
  const [state, send] = useActor(opportunitiesEditUploadActor);

  const disabled = useMemo(() => {
    if (!state.context.opportunitiesDocumentFile) return true;
    return state.hasTag('loading');
  }, [!state.context.opportunitiesDocumentFile, state.hasTag('loading')]);

  if (state.hasTag('loading')) {
    return (
      <Dialog
        open
        aria-labelledby="customized-dialog-title"
        className="dialog-box"
      >
        <StyledDialogContent>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Loader size={50} />
          </div>
        </StyledDialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog
      open
      aria-labelledby="customized-dialog-title"
      className="dialog-box"
    >
      <StyledDialogTitle onClose={() => send('cancel')}>
        Update uploaded rebate opportunities
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className="upload-rebateform">
          <Grid container spacing={2} className="upload-rebateform-grid">
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" className="select-box">
                <Select
                  disabled
                  value={state.context.selectedOpportunityQuarter.quarter}
                  style={{ letterSpacing: '0.0958em' }}
                >
                  <option
                    value={state.context.selectedOpportunityQuarter.quarter}
                  >
                    Quarter {state.context.selectedOpportunityQuarter.quarter}
                  </option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" className="select-box">
                <Select
                  disabled
                  value={state.context.selectedOpportunityQuarter.year}
                >
                  <option value={state.context.selectedOpportunityQuarter.year}>
                    {state.context.selectedOpportunityQuarter.year}
                  </option>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <div className="rebates-file-upload">
            <Grid container spacing={2} justifyContent="center">
              <Grid item xs={12} lg={6}>
                {state.context.opportunitiesDocumentFile === null ? (
                  <Button
                    className="file-filed-document"
                    component="label"
                    style={{ cursor: 'pointer' }}
                  >
                    <div className="edit-doc-section-box" style={{ marginBottom: '1em'}}>
                      <div
                        className="documents-grid-img"
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexDirection: 'column',
                        }}
                      >
                        <i
                          className="far fa-file-excel"
                          style={{ position: 'unset' }}
                        />
                        <span
                          className="documents-doc-type doc-xls"
                          style={{
                            position: 'relative',
                            right: '15px',
                            left: 'unset',
                            top: '-8px',
                          }}
                        >
                          xlsx
                        </span>
                      </div>

                      <div className="edit-doc-section-name">
                        rebate_product_list_documents/
                        {state.context.selectedOpportunityQuarter.year}/
                        {state.context.selectedOpportunityQuarter.quarter}/
                        {state.context.selectedOpportunityQuarter.id}.xlsx
                      </div>
                      <div className="edit-doc-section-size">
                        {getFileSize(
                          state.context.selectedOpportunityQuarter.fileSize ?? 0,
                        )}
                      </div>
                    </div>

                    <img src={UploadImg} />
                    <div className="file-filed-text-size-sm">
                      Replace with XLS OR XLSX files
                    </div>
                    <div className="file-filed-text-size-md">
                      Drop your file here.{' '}
                    </div>
                    <div>
                      or{' '}
                      <span className="file-filed-text-size-highlight">
                        browse
                      </span>
                    </div>
                    <input
                      accept=".xls,.xlsx"
                      type="file"
                      id="fileUpload"
                      title=""
                      value=""
                      onChange={(event) => {
                        if ((event.target.files?.length ?? 0) > 0) {
                          const file = event.target.files?.[0] as File;
                          if (
                            file.name.split('.')?.[1] === 'xls' ||
                            file.name.split('.')?.[1] === 'xlsx'
                          ) {
                            send({
                              type: 'select file',
                              file: event.target.files?.[0] as File,
                            });
                          }
                        }
                      }}
                    />
                    {false && (
                      <div className="error-wrapper">
                        <i className="fas fa-exclamation-triangle error-icon" />
                        <div className="error-text">
                          {'Please upload only xls or xlsx extension file.'}
                        </div>
                      </div>
                    )}
                  </Button>
                ) : (
                  <div className="edit-doc-section">
                    <div className="edit-doc-section-box">
                      <div className="documents-grid-img">
                        <i className="far fa-file-excel" />
                        <span className="documents-doc-type doc-xls">
                          {
                            state.context.opportunitiesDocumentFile?.name.split(
                              '.',
                            )?.[1]
                          }
                        </span>
                      </div>

                      <div className="edit-doc-section-name">
                        {state.context.opportunitiesDocumentFile?.name}
                      </div>
                      <div className="edit-doc-section-size">
                        {getFileSize(
                          state.context.opportunitiesDocumentFile?.size ?? 0,
                        )}
                      </div>
                      <div className="edit-doc-section-buttons">
                        <Button
                          className="remove-button"
                          onClick={() => send('remove file')}
                        >
                          Remove
                        </Button>
                        <label className="replace-button" htmlFor="replaceFile">
                          Replace
                        </label>
                        <input
                          accept=".xls,.xlsx"
                          type="file"
                          id="replaceFile"
                          hidden
                          onChange={(event) => {
                            if ((event.target.files?.length ?? 0) > 0) {
                              const file = event.target.files?.[0] as File;
                              if (
                                file.name.split('.')?.[1] === 'xls' ||
                                file.name.split('.')?.[1] === 'xlsx'
                              ) {
                                send({
                                  type: 'select file',
                                  file: event.target.files?.[0] as File,
                                });
                              }
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Grid>
            </Grid>
          </div>
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div className="action-button">
            <button
              className="cancel-user-button"
              onClick={() => send('cancel')}
            >
              Cancel
            </button>
            <Button
              autoFocus
              onClick={() => send('submit file')}
              className={
                disabled
                  ? 'invite-user-button button-disable'
                  : 'invite-user-button'
              }
              disabled={disabled}
            >
              Upload selected document{' '}
              {state.hasTag('loading') && <Loader size={20} />}
            </Button>
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OpportunitiesEditModal;
