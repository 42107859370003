import {
  CLEAR_CHANGE_PASSWORD,
  UPDATE_PASSWORD_USER_SPINNER,
  UPDATE_PASSWORD_USER_OLD_PASSWORD,
  UPDATE_PASSWORD_USER_NEW_PASSWORD,
  UPDATE_PASSWORD_USER_CONFIRM_PASSWORD,
  UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR,
  UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR,
  UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR,
  UPDATE_PASSWORD_NEW_PWD_MESSAGE,
  UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE,
  UPDATE_PASSWORD_SHOW_STATUS,
  UPDTAE_PASSOWRD_IS_REDIRECT,
} from "../../constants";

const initialState = {
  isLoading: false,
  oldPassword: "",
  newPassword: "",
  confirmPassword: "",
  errorOldPassword: false,
  errorNewPassword: false,
  errorConfirmPassword: false,
  messageForNewPWD: "",
  messageForConfirmPWD: "",
  passwordShowStatus: {
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  },
  isRedirect: false,
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
  switch (action.type) {
    case UPDATE_PASSWORD_USER_SPINNER:
      return {
        ...state,
        isLoading: action.flag,
      };
    case UPDATE_PASSWORD_USER_OLD_PASSWORD:
      return {
        ...state,
        oldPassword: action.value,
      };
    case UPDATE_PASSWORD_USER_NEW_PASSWORD:
      return {
        ...state,
        newPassword: action.value,
      };
    case UPDATE_PASSWORD_USER_CONFIRM_PASSWORD:
      return {
        ...state,
        confirmPassword: action.value,
      };
    case UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR:
      return {
        ...state,
        errorOldPassword: action.flag,
      };
    case UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR:
      return {
        ...state,
        errorNewPassword: action.flag,
      };
    case UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR:
      return {
        ...state,
        errorConfirmPassword: action.flag,
      };
    case UPDATE_PASSWORD_NEW_PWD_MESSAGE:
      return {
        ...state,
        messageForNewPWD: action.value,
      };
    case UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE:
      return {
        ...state,
        messageForConfirmPWD: action.value,
      };
    case UPDATE_PASSWORD_SHOW_STATUS:
      return {
        ...state,
        passwordShowStatus: action.value,
      };
    case UPDTAE_PASSOWRD_IS_REDIRECT:
      return {
        ...state,
        isRedirect: action.flag,
      };
    case CLEAR_CHANGE_PASSWORD:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
