import moment from 'moment';
import {
  UPDATE_CLAIM_REBATES_EXPORT_MODAL,
  UPDATE_PRODUCT_REBATES_DATE_RANGE,
  UPDATE_CLAIM_REBATES_MODAL,
  SHOW_REBATE_LIST_SPINNER,
  SHOW_REBATE_RECEIPT_MEMBER_SPINNER,
  SHOW_REBATE_RECEIPT_MODAL_SPINNER,
  UPDATE_REBATE_RECEIPT_MEMBER_LIST,
  UPDATE_REBATE_RECEIPT_LIST,
  UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER,
  UPDATE_AVAILABLE_REBATES,
  UPDATE_HAND_DELIVER_CHECK,
  SHOW_REBATE_RECEIPT_EXPORT_SPINNER,
  UPDATE_REBATE_RECEIPT_EXPORT_QUARTER,
  UPDATE_REBATE_RECEIPT_EXPORT_YEAR,
  CLEAR_REBATE_RECEIPT_EXPORT_MODAL,
  UPDATE_SELECTED_REBATE_RECEIPT,
  UPDATE_REBATE_RECEIPT_ACTION_MODAL,
  UPDATE_REBATE_RECEIPT_MODE,
  CLEAR_REBATE_RECEIPT_ACTION_MODAL,
  UPDATE_CONFIRM_MODAL,
} from '../../constants';
import { quarterOfTheYear } from '../../utils';

const currentYear = moment().format('YYYY');
const currentDate = new Date();
const currentQuarter = quarterOfTheYear(currentDate);

const initialState = {
  memberListSpinner: false,
  rebateListSpinner: false,
  rebateReceiptModalSpinner: false,
  rebateReceiptExportSpinner: false,
  isClaimRebatesModal: false,
  isClaimRebatesExportModal: false,
  dateRange: [
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection',
    },
  ],
  memberList: [],
  rebatesList: [],
  selectedMember: null,
  rebatesAmount: null,
  isHandDeliverChecked: false,
  exportYear: currentYear,
  exportQuarter: currentQuarter,
  selectedRebate: null,
  isRebateReceiptAction: false,
  rebateReceiptMode: null,
  isConfirmModalUpdate: false,
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
  switch (action.type) {
    case SHOW_REBATE_LIST_SPINNER:
      return {
        ...state,
        rebateListSpinner: action.rebateListSpinner,
      };
    case SHOW_REBATE_RECEIPT_MEMBER_SPINNER:
      return {
        ...state,
        memberListSpinner: action.memberListSpinner,
      };
    case SHOW_REBATE_RECEIPT_MODAL_SPINNER:
      return {
        ...state,
        rebateReceiptModalSpinner: action.rebateReceiptModalSpinner,
      };
    case UPDATE_CLAIM_REBATES_MODAL:
      return {
        ...state,
        isClaimRebatesModal: action.isClaimRebatesModal,
      };
    case UPDATE_CLAIM_REBATES_EXPORT_MODAL:
      return {
        ...state,
        isClaimRebatesExportModal: action.isClaimRebatesExportModal,
      };
    case UPDATE_PRODUCT_REBATES_DATE_RANGE:
      return {
        ...state,
        dateRange: action.dateRange,
      };
    case UPDATE_REBATE_RECEIPT_MEMBER_LIST:
      return {
        ...state,
        memberList: action.memberList,
      };
    case UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER:
      return {
        ...state,
        selectedMember: action.selectedMember,
      };
    case UPDATE_REBATE_RECEIPT_LIST:
      return {
        ...state,
        rebatesList: action.rebatesList,
      };
    case UPDATE_AVAILABLE_REBATES:
      return {
        ...state,
        rebatesAmount: action.rebatesAmount,
      };
    case UPDATE_HAND_DELIVER_CHECK:
      return {
        ...state,
        isHandDeliverChecked: action.isHandDeliverChecked,
      };
    case SHOW_REBATE_RECEIPT_EXPORT_SPINNER:
      return {
        ...state,
        rebateReceiptExportSpinner: action.rebateReceiptExportSpinner,
      };
    case UPDATE_REBATE_RECEIPT_EXPORT_QUARTER:
      return {
        ...state,
        exportQuarter: action.exportQuarter,
      };
    case UPDATE_REBATE_RECEIPT_EXPORT_YEAR:
      return {
        ...state,
        exportYear: action.exportYear,
      };
    case UPDATE_SELECTED_REBATE_RECEIPT:
      return {
        ...state,
        selectedRebate: action.selectedRebate,
      };
    case UPDATE_REBATE_RECEIPT_ACTION_MODAL:
      return {
        ...state,
        isRebateReceiptAction: action.isRebateReceiptAction,
      };
    case UPDATE_REBATE_RECEIPT_MODE:
      return {
        ...state,
        rebateReceiptMode: action.rebateReceiptMode,
      };
    case UPDATE_CONFIRM_MODAL:
      return {
        ...state,
        isConfirmModalUpdate: action.isConfirmModalUpdate,
      };
    case CLEAR_REBATE_RECEIPT_EXPORT_MODAL:
      return {
        ...state,
        exportYear: currentYear,
        exportQuarter: currentQuarter,
      };
    case CLEAR_REBATE_RECEIPT_ACTION_MODAL:
      return {
        ...state,
        selectedRebate: null,
        isRebateReceiptAction: false,
        rebateReceiptMode: null,
      };
    default:
      return state;
  }
};
