import { FC } from 'react';
import { CommunityQuestionsComments } from './CommunityQuestionsComments';
import { CommunityCommentsAdd } from './CommunityCommentsAdd';

export const QuestionDetailWrapper: FC<any> = (props) => {
  return (
    <div className="community-comments">
      <CommunityQuestionsComments {...props} />
      <CommunityCommentsAdd {...props} />
    </div>
  );
};

export default QuestionDetailWrapper;
