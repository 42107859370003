import { SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR } from '../../constants';
import serverApiV2Instance from '../../services/serverApiV2Instance';

export const sendAvailableRebatesEmailNotification = async (memberId, amount) => {
    try {
        let formBody = { amount };

        const response = await serverApiV2Instance.post(`/members/${memberId}/reminder-email`, formBody);
        if (!response?.data) {
            throw SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR;
        }
        return response;
    } catch (error) {
        if (error?.response?.data?.message) {
            throw error?.response?.data?.message
        }
        else {
            throw SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR;
        }
    }
};
