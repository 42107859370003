import { connect } from 'react-redux';
import {
  getMembers,
  clearAddMember,
  onChangeAddMemberParent,
  onChangeAddMemberName,
  onChangeAddMemberStreet,
  onChangeAddMemberCity,
  onChangeAddMemberState,
  onChangeAddMemberZip,
  onChangeAddMemberPhone,
  onChangeAddMemberMobile,
  onChangeAddMemberJoinDate,
  onChangeAddMemberAvatarImage,
  onChangeAddMemberCanHandDeliver,
  updateNavigationFromParent,
  updateNavigationFromView,
  updateNavigationFromTab,
  onAddMember,
  getMember,
  onEditMember,
} from '../../../actions/members';
import { getStateLists } from '../../../actions/common';

import { AddMemberContent } from './AddMemberContent';

const mapStateToProps = (state: {
  memberForm: any;
  members: any;
  common: any;
}) => {
  const { memberForm, members, common } = state;
  return {
    memberForm,
    members,
    common,
  };
};

export const AddMember = connect(mapStateToProps, {
  getMembers: getMembers,
  clearAddMember,
  onChangeAddMemberParent,
  onChangeAddMemberName,
  onChangeAddMemberStreet,
  onChangeAddMemberCity,
  onChangeAddMemberState,
  onChangeAddMemberZip,
  onChangeAddMemberPhone,
  onChangeAddMemberMobile,
  onChangeAddMemberJoinDate,
  onChangeAddMemberAvatarImage,
  onChangeAddMemberCanHandDeliver,
  updateNavigationFromParent,
  updateNavigationFromView,
  updateNavigationFromTab,
  onAddMember,
  getMember,
  onEditMember,
  getStateLists,
})(AddMemberContent);
