import { connect } from 'react-redux';
import {
  getDashboardInfo,
  handleOppQuarterChange,
  handleActiveEarningsMemberChange,
  handleActiveEarningsQuarterChange,
  updateActiveEarningsRoute,
  updateOpportunitiesRoute,
} from '../../actions/dashboard';
import {
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage,
} from '../../actions/pagination';
import {
  getMemberRebatesInfo,
  updateMemberRebatesInfo,
} from '../../actions/rebates';
import { getUserRoleList } from '../../actions/common';

import { DashboardMain } from './DashboardMain';

export const Dashboard = connect((state: any) => {
  const {
    dashboardKPI,
    dashboardActiveEarnings,
    dashboardOpportunities,
    modal,
    paginationReducer,
    memberRebates,
  } = state;

  return {
    dashboardKPI,
    dashboardActiveEarnings,
    dashboardOpportunities,
    modal,
    paginationReducer,
    memberRebates,
  };
}, {
  getDashboardInfo,
  handleOppQuarterChange,
  getMemberRebatesInfo,
  updateMemberRebatesInfo,
  handleActiveEarningsMemberChange,
  handleActiveEarningsQuarterChange,
  updateActiveEarningsRoute,
  updateOpportunitiesRoute,
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage,
  getUserRoleList,
})(DashboardMain);
