import { FC, Fragment } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core';
import { getFileExtensionColor, getAccessColor } from '../../../../utils';

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const DocumentDetailsModal: FC<any> = (props) => {
  const {
    closeDocumentDetailModal,
    documents: {
      isDetailModal,
      selectedDocumentData: {
        name,
        fileextension,
        size,
        description,
        uploadeddate,
        category,
        accessLevel,
      },
      selectMemberList,
    },
  } = props;

  const commaSeparatedMembers =
    selectMemberList &&
    Array.prototype.map
      .call(selectMemberList, function (item) {
        return item.name;
      })
      .join(',');

  const formattedData = [
    {
      label: 'File Name',
      value: name,
    },
    {
      label: 'File Type',
      value: fileextension,
      fill: getFileExtensionColor(fileextension),
    },
    {
      label: 'File Size',
      value: size,
    },
    {
      label: 'Description',
      value: description,
    },
    {
      label: 'File Uploaded Date',
      value: uploadeddate,
    },
    {
      label: 'Category',
      value: category,
    },
    {
      label: 'Access Level',
      value:
        accessLevel === 'Selected Members'
          ? `Private (${commaSeparatedMembers})`
          : 'Public',
      fill: getAccessColor(accessLevel),
    },
  ];

  return (
    <div>
      <Dialog
        onClose={closeDocumentDetailModal}
        aria-labelledby="customized-dialog-title"
        open={isDetailModal}
        className="dialog-box"
      >
        <StyledDialogTitle onClose={closeDocumentDetailModal}>
          Document Details
        </StyledDialogTitle>

        <StyledDialogContent>
          <div className="document-detail-wrapper">
            <Grid container spacing={2} className="tr details">
              {formattedData.map(({ label, value, fill }, index: number) => {
                return (
                  <Fragment key={index}>
                    <Grid
                      className="td xs-left-align xs-primary detail-label"
                      item
                      xs={5}
                      lg={3}
                      container
                      direction="column"
                      // alignItems="left"
                      alignItems="flex-start"
                    >
                      {`${label}`}
                    </Grid>
                    <Grid
                      className="td xs-left-align xs-primary detail-label"
                      item
                      xs={1}
                      lg={1}
                      container
                      direction="column"
                      alignItems="flex-start"
                      // alignItems="left"
                    >
                      :
                    </Grid>
                    <Grid
                      className="td xs-left-align xs-primary"
                      item
                      xs={5}
                      lg={8}
                      container
                      direction="column"
                      // alignItems="left"
                      alignItems="flex-start"
                    >
                      <div style={{ color: fill }}>{value ? value : '-'}</div>
                    </Grid>
                  </Fragment>
                );
              })}
            </Grid>
          </div>
        </StyledDialogContent>
        <StyledDialogActions />
      </Dialog>
    </div>
  );
};

export default DocumentDetailsModal;
