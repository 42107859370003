import { FC, useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Loader } from '../utils';
import {
  Button,
  Dialog,
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
  IconButton,
  Typography,
} from '@material-ui/core';

const DialogHead = withStyles((theme: any) => ({
  root: {
    margin: `0 !important`,
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    width : '100% !important',
    background : '#FFF !important'
  },
  closeButton: {
    right: `${theme.spacing(4)} !important`,
    top: `${theme.spacing(1)} !important`,
    color: `${theme.palette.grey[500]} !important`,
  },
}))(({ children, classes, onClose, ...other }: any) => {
  return (
    <MuiDialogTitle
      style={{ position: 'relative !important' }}
      disableTypography
      className={classes.root}
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          // style={{ position: 'absolute' }}
          aria-label="close"
          className={`${classes.closeButton} position-abs`}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    background : '#FFF !important'
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: `0 !important`,
    padding: `${theme.spacing(2)} ${theme.spacing(5)}`,
    background: '#e9f0f9 !important',
  },
}))(MuiDialogActions);

const DialogWindow: FC<any> = ({
  openDialog,
  dialogConfiguration,
  handleClose,
  handleSubmit,
  handleOpenCreate,
  handleBackToList,
  children,
  isButtonDisabled,
  spinnerEnabled,
  setKPIChartModal,
  fullWidth = '',
  maxWidth = '',
}) => {
  const [open, setOpen] = useState(openDialog);
  const config = dialogConfiguration;
  const [isLoading] = useState(false);

  useEffect(() => {
    setOpen(openDialog);
  }, [openDialog]);

  const handleGoBack = () => {
    setKPIChartModal?.(true);
    handleClose();
  };

  return (
    <Dialog
      onClose={() => handleClose()}
      aria-labelledby="customized-dialog-title"
      open={open}
      className="dialog-box"
      fullWidth={fullWidth}
      maxWidth={maxWidth}
    >
      <DialogHead onClose={() => handleClose()}>{config.title}</DialogHead>
      {spinnerEnabled && (
        <div className="loader-wrapper">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      )}
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <div className="modal-buttons-container">
          <div>
            {config.isGoBack && (
              <span className="go-back" onClick={handleGoBack}>
                <i className="fas fa-arrow-left back-icon" /> Back
              </span>
            )}
            {config.isCreate && (
              <button
                className="create-user-button"
                onClick={handleOpenCreate}
                disabled={isLoading}
              >
                {config.createText ? config.createText : 'Create New'}
              </button>
            )}
            {config?.isBack && (
              <button
                className="back-to-assign-button"
                onClick={handleBackToList}
              >
                <i className="fas fa-arrow-left" />
                {config.backText}
              </button>
            )}
          </div>
          <div>
            {config?.isCancel && (
              <button
                className="cancel-user-button"
                onClick={() => handleClose()}
              >
                Cancel
              </button>
            )}
            {config?.submitText && (
              <Button
                autoFocus
                onClick={handleSubmit}
                className={
                  isButtonDisabled
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={isButtonDisabled}
              >
                {config.submitText ? config.submitText : 'Submit'}
              </Button>
            )}
          </div>
          {isLoading && <Loader />}
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default DialogWindow;
