import { CLEAR_MEMBER_VIEW, MEMBER_VIEW, MEMBER_VIEW_STATE, MEMBER_VIEW_TRUE } from "../constants";
import AuthenticationService from "../services/AuthenticationService";

const loggedInUser = AuthenticationService.getCurrentUser();

export default (state = loggedInUser, action) => {
  switch (action.type) {
    case "SET_USER":
      return {...state, ...action.payload};
    case "GET_USER":
      return {...state.user};
    case MEMBER_VIEW_TRUE:
      return {...state, isViewAsMember : true};
    case MEMBER_VIEW_STATE:
      const data = JSON.parse(sessionStorage.getItem('user'))
      return {...data, isViewAsMember : true};
    case CLEAR_MEMBER_VIEW:
      sessionStorage.removeItem('user');
      const loginData = JSON.parse(localStorage.getItem('user'))
      return {...loginData, isViewAsMember : false};
    default:
      return state;
  }
};

