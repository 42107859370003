import moment from "moment";
import {
    UPDATE_MEMBER_REBATES_LIST_SPINNER,
    UPDATE_MEMBER_REBATES_QUARTER_LIST,
    UPDATE_MEMBER_REBATES_LIST,
    UPDATE_MEMBER_REBATES_ACTIVE_QUARTER,
    UPDATE_MEMBER_REBATES_QUARTER,
    UPDATE_MEMBER_REBATES_YEAR,
    UPDATE_MEMBER_REBATES_EXPORT_QUARTER,
    UPDATE_MEMBER_REBATES_EXPORT_YEAR,
    UPDATE_MEMBER_REBATES_DRAFT,
    UPDATE_MEMBER_REBATES_EXPORT_MODAL,
    UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_ONE_NAME,
    UPDATE_MEMBER_REBATES_FILE_ONE_SIZE,
    UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_TWO_NAME,
    UPDATE_MEMBER_REBATES_FILE_TWO_SIZE,
    UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_THREE_NAME,
    UPDATE_MEMBER_REBATES_FILE_THREE_SIZE,
    UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
    UPDATE_FILE_UPLOAD_ACTIVE_STEP,
    CLEAR_MEMBER_REBATE_MODAL,
    UPDATE_MEMBER_REBATES_EDIT,
    UPDATE_MEMBER_REBATES_INFO,
    UPDATE_SELECTED_MEMBER_REBATES_INFO
} from "../../constants";
import {quarterOfTheYear} from "../../utils";

const currentYear = moment().format('YYYY');
const currentDate = new Date();
const currentQuarter = quarterOfTheYear(currentDate);

const initialState = {
    memberQuarterList: [],
    memberRebateList: [],
    memberActiveQuarter: null,
    quarter: currentQuarter,
    year: currentYear,
    memberExportQuarter: null,
    memberExportYear: null,
    isMemberRebateDraft: false,
    memberRebatesListSpinner: false,
    isMemberRebateExportModal: false,
    activeStep: 0,
    fileOneContent: null,
    fileOneName: null,
    fileOneSize: null,
    fileOneExtension: null,
    fileTwoContent: null,
    fileTwoName: null,
    fileTwoSize: null,
    fileTwoExtension: null,
    fileThreeContent: null,
    fileThreeName: null,
    fileThreeSize: null,
    fileThreeExtension: null,
    fileExtensionError: false,
    isEdit: false,
    memberRebatesInfo: [],
    memberRebatesId: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_MEMBER_REBATES_QUARTER_LIST:
            return {
                ...state,
                memberQuarterList: action.memberQuarterList,
            };
        case UPDATE_MEMBER_REBATES_ACTIVE_QUARTER:
            return {
                ...state,
                memberActiveQuarter: action.memberActiveQuarter,
            };
        case UPDATE_MEMBER_REBATES_QUARTER:
            return {
                ...state,
                quarter: action.quarter,
            };
        case UPDATE_MEMBER_REBATES_YEAR:
            return {
                ...state,
                year: action.year,
            };
        case UPDATE_MEMBER_REBATES_EXPORT_QUARTER:
            return {
                ...state,
                memberExportQuarter: action.memberExportQuarter,
            };
        case UPDATE_MEMBER_REBATES_EXPORT_YEAR:
            return {
                ...state,
                memberExportYear: action.memberExportYear,
            };
        case UPDATE_MEMBER_REBATES_LIST_SPINNER:
            return {
                ...state,
                memberRebatesListSpinner: action.memberRebatesListSpinner,
            };
        case UPDATE_MEMBER_REBATES_LIST:
            return {
                ...state,
                memberRebateList: action.memberRebateList,
            };
        case UPDATE_MEMBER_REBATES_DRAFT:
            return {
                ...state,
                isMemberRebateDraft: action.isMemberRebateDraft,
            };
        case UPDATE_MEMBER_REBATES_EXPORT_MODAL:
            return {
                ...state,
                isMemberRebateExportModal: action.isMemberRebateExportModal,
            };
        case UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT:
            return {
                ...state,
                fileOneContent: action.fileOneContent,
            };
        case UPDATE_MEMBER_REBATES_FILE_ONE_NAME:
            return {
                ...state,
                fileOneName: action.fileOneName,
            };
        case UPDATE_MEMBER_REBATES_FILE_ONE_SIZE:
            return {
                ...state,
                fileOneSize: action.fileOneSize,
            };
        case UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION:
            return {
                ...state,
                fileOneExtension: action.fileOneExtension,
            };
        case UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT:
            return {
                ...state,
                fileTwoContent: action.fileTwoContent,
            };
        case UPDATE_MEMBER_REBATES_FILE_TWO_NAME:
            return {
                ...state,
                fileTwoName: action.fileTwoName,
            };
        case UPDATE_MEMBER_REBATES_FILE_TWO_SIZE:
            return {
                ...state,
                fileTwoSize: action.fileTwoSize,
            };
        case UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION:
            return {
                ...state,
                fileTwoExtension: action.fileTwoExtension,
            };
        case UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT:
            return {
                ...state,
                fileThreeContent: action.fileThreeContent,
            };
        case UPDATE_MEMBER_REBATES_FILE_THREE_NAME:
            return {
                ...state,
                fileThreeName: action.fileThreeName,
            };
        case UPDATE_MEMBER_REBATES_FILE_THREE_SIZE:
            return {
                ...state,
                fileThreeSize: action.fileThreeSize,
            };
        case UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION:
            return {
                ...state,
                fileThreeExtension: action.fileThreeExtension,
            };
        case UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR:
            return {
                ...state,
                fileExtensionError: action.fileExtensionError,
            };
        case UPDATE_FILE_UPLOAD_ACTIVE_STEP:
            return {
                ...state,
                activeStep: action.activeStep,
            };
        case UPDATE_MEMBER_REBATES_EDIT:
            return {
                ...state,
                isEdit: action.isEdit,
            };
        case UPDATE_MEMBER_REBATES_INFO:
            return {
                ...state,
                memberRebatesInfo: action.memberRebatesInfo,
            };
        case UPDATE_SELECTED_MEMBER_REBATES_INFO:
            return {
                ...state,
                memberRebatesId: action.memberRebatesId,
            };
        case CLEAR_MEMBER_REBATE_MODAL:
            return {
                ...state,
                quarter: currentQuarter,
                year: currentYear,
                activeStep: 0,
                fileOneContent: null,
                fileOneName: null,
                fileOneSize: null,
                fileOneExtension: null,
                fileTwoContent: null,
                fileTwoName: null,
                fileTwoSize: null,
                fileTwoExtension: null,
                fileThreeContent: null,
                fileThreeName: null,
                fileThreeSize: null,
                fileThreeExtension: null,
                fileExtensionError: false
            };
        default:
            return state;
    }
};
