import { assign, createMachine } from 'xstate';

export const rebatesPermissionsMachine = createMachine(
  {
    id: 'rebatesPermissions',
    tsTypes: {} as import('./rebatesPermissionsMachine.typegen').Typegen0,
    initial: 'loadingPermissions',
    schema: {} as {
      services: {
        loadUserPermissions: {
          data: {
            user: {
              isBRAdmin: false;
              isMember: false;
              isVendor: false;
              memberusers: {
                roleid: number;
              }[];
              vendorusers: {
                roleid: number;
              }[];
            };
            rolesList: {
              description: string;
              id: number;
              name: string;
            }[];
          };
        };
      };
    },
    context: {
      isBRAdmin: false,
      isMember: false,
      isVendor: false,
    },
    states: {
      loadingPermissions: {
        invoke: {
          id: 'Load user permissions',
          src: 'loadUserPermissions',
          onDone: [
            {
              actions: 'assignIsBRAdmin',
              cond: 'userIsAuthorized',
              target: 'authorized',
            },
            {
              target: 'notAuthorized',
            },
          ],
        },
        tags: 'loading',
      },
      authorized: {
        type: 'final',
      },
      notAuthorized: {
        entry: 'redirectUser',
        type: 'final',
      },
    },
  },
  {
    actions: {
      assignIsBRAdmin: assign({
        isBRAdmin: (_, event) => event.data.user.isBRAdmin,
        isMember: (_, event) => event.data.user.memberusers.length > 0 ? true : false,
        isVendor: (_, event) => event.data.user.vendorusers.length > 0 ? true : false,
      }),
    },
    guards: {
      userIsAuthorized: (_, { data }) => {
        if (data.user.isBRAdmin) return true;
        let adminRoleId: null | number = null;
        let managerRoleId: null | number = null;
        let vendorRoleId: null | number = null;
        let vendorAdminRoleId: null | number = null;
        data.rolesList.forEach((role) => {
          if (role.name === 'Member Admin') {
            adminRoleId = role.id;
          }
          if (role.name === 'Member Manager') {
            managerRoleId = role.id;
          }
          if (role.name === 'Account Representative') {
            vendorRoleId = role.id;
          }
          if (role.name === 'Vendor Admin') {
            vendorAdminRoleId = role.id;
          }
        });
        let authorized = false;
        data.user.memberusers.forEach((memberUser) => {
          if (
            memberUser.roleid === adminRoleId ||
            memberUser.roleid === managerRoleId
          ) {
            authorized = true;
          }
        });
        data.user.vendorusers.forEach((vendorUser) => {
          if (
            vendorUser.roleid === vendorAdminRoleId ||
            vendorUser.roleid === vendorRoleId
          ) {
            authorized = true;
          }
        });
        return authorized;
      },
    },
  },
);

export type RebatesPermissionsMachine = typeof rebatesPermissionsMachine;
export default rebatesPermissionsMachine;
