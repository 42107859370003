import { FC } from "react";
import { CustomModal } from "../../../../../../components/CustomModal";
import { AssignNewMemberModal } from "./AssignNewMemberModal";

export const AssignNewMember: FC<any> = (props) => {
    const {
        showMemberModal,
        assignedVendorUsers,
        assignmembers,
        unassignmembers,
        clearVendorMemberAssign,
        onAssignSalesPersonnel,
        onUpdateSalesPersonnel,
        vendorMemberAssignModal,
        onChangeVendorMemberUser,
        selectedVendorUser,
        paginationReducer,
        updateMemberCustomerID,
        onSelecteAssignMember,
        selectedMemberId,
        updateMemberCustomerAbbr,
        send,
    } = props;
    const { isEditModal, loading, customerIDError, vendorUserError, isEditFormUpdated, customerAbbreviationError } = vendorMemberAssignModal;

    const closeModal = () => {
        clearVendorMemberAssign();
    };

    const buttonDisableFlag = () => {
        return [
            !isEditFormUpdated,
            customerIDError,
            vendorUserError,
            loading,
            selectedMemberId === null,
            customerAbbreviationError,
        ].some((value) => value);
    }

    const assignNewMemberModalProps = {
        assignedVendorUsers,
        assignmembers,
        unassignmembers,
        onChangeVendorMemberUser,
        selectedVendorUser,
        updateMemberCustomerID,
        onSelecteAssignMember,
        vendorMemberAssignModal,
        updateMemberCustomerAbbr,
    }

    return (
        <div>
            <CustomModal
                showModal={showMemberModal || isEditModal}
                closeModal={closeModal}
                modalTitle={!isEditModal ? "Assign New Member" : "Edit Assigned Member"}
                isCancelButton={true}
                isSubmitButton={true}
                isExportButton={false}
                isSubmitText={!isEditModal ? "Assign Member" : "Update Member"}
                onSubmit={() => {
                    isEditModal ? onUpdateSalesPersonnel() : onAssignSalesPersonnel();
                    setTimeout(() => send({
                        type: 'addQueryStringValue',
                        key: 'orderBy',
                        value: 'id',
                    }), 2000);
                }}
                isLoading={loading}
                isButtonDisabled={buttonDisableFlag()}
            >
                <AssignNewMemberModal {...assignNewMemberModalProps} />
            </CustomModal>
        </div>
    );
};
