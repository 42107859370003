import { Grid } from '@material-ui/core';
import { FC, useState } from 'react';
import DefaultUserImg from '../../../../images/profile-placeholder.jpg';
import { CustomModal } from '../../../../components/CustomModal/index';
import { CommunityMemberUsersList } from '../MemberUsers/CommunityMemberUsersList';

const formatContactNumber = (contactNumber: string) => {
  return contactNumber.replace(/(\d{3})(\d{3})(\d*)/, '($1) $2-$3');
};

export const CommunityMembersListRow: FC<{
  member: {
    avatar: null | string;
    id: number;
    name: null | string;
    city: string;
    contactfirstname?: string | null;
    contactlastname?: string | null;
    phone?: string | null;
    state?: string | null;
    childCount: number;
    street: string | null;
    zip: string | null;
    mobile: string | null;
  };
}> = ({ member }) => {
  const {
    id,
    name,
    childCount,
    city,
    state: stateName,
    contactfirstname,
    contactlastname,
    street,
    zip,
    mobile,
    phone,
    avatar,
  } = member;

  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const handleMemberClick = () => {
    setShowModal(true);
  };

  return (
    <>
      <Grid style={{ cursor: 'pointer' }} container spacing={0} className="tr">
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={3}
          container
          direction="row"
          alignItems="center"
          onClick={() => handleMemberClick()}
        >
          <div className="table-member-details">
            <div className="member-img">
              <img src={avatar !== null ? `/${avatar}` : DefaultUserImg} />
            </div>
            <div className="member-profile-details">
              {name} {childCount > 0 && <i className="fa fa-users" />}
            </div>
          </div>
        </Grid>
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={3}
          container
          direction="row"
          alignItems="center"
          onClick={() => handleMemberClick()}
        >
          {`${street}, ${city}, ${stateName}, ${zip}`}
        </Grid>
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
          onClick={() => handleMemberClick()}
        >
          {[contactfirstname, contactlastname]
            .filter((name) => typeof name === 'string')
            .join(' ')}
        </Grid>
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
        >
          {phone && phone !== 'null' ? (
            <a className="phone-link" href={`tel:${phone}`}>
              {formatContactNumber(phone)}
            </a>
          ) : (
            '-'
          )}
        </Grid>
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
        >
          {mobile && mobile !== 'null' ? (
            <a className="phone-link" href={`tel:${mobile}`}>
              {formatContactNumber(mobile)}
            </a>
          ) : (
            '-'
          )}
        </Grid>
      </Grid>
      {showModal && (
        <CustomModal
          showModal={showModal}
          closeModal={closeModal}
          modalTitle={`${name} - Users List`}
          isCancelButton={false}
          isSubmitButton={true}
          isSubmitText={'Ok'}
          onSubmit={closeModal}
        >
          <CommunityMemberUsersList memberId={id} />
        </CustomModal>
      )}
    </>
  );
};

export default CommunityMembersListRow;
