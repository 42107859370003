import { FC, useState } from "react";
import { SearchNotFound } from "../../../../components/SearchNotFound";
import { useStyles } from "../../../../utils";
import DefaultMemberImg from "../../../../images/member-list-placeholder.jpg";
import sortDefault from "../../../../images/sortDefault.svg";
import sortAscIcon from "../../../../images/sortAsc.svg";
import sortDescIcon from "../../../../images/sortDesc.svg";
import { formatPhoneNumber } from "../../../../utils/PhoneNumberUtils";
import { Button, Grid, Tooltip } from "@material-ui/core";
import { StringAvatar } from "../../../../utils/AvatarInitial";
import Avatar from "@mui/material/Avatar";
import moment from "moment";

interface LoginAttemptProps {
  gridHeader: any[];
  gridData: any[];
  setSortKey: (sortKey: string) => void;
  sortType: string;
  memberName: string;
}

export const LoginAttempt: FC<LoginAttemptProps> = ({
  gridHeader,
  gridData,
  setSortKey,
  sortType,
  memberName,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: string) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  return (
    <div className={`${classes.root} table-wrapper`}>
      {gridData?.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item, index) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = label !== "";
            return (
              <Grid className="th" item xs={xs} lg={lg} key={index}>
                {label === "Action" ? (
                  <div className={label === "Action" ? "action-header" : ""}>
                    <span className="grid-header">{label}</span>
                  </div>
                ) : (
                  <div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader ? (
                        sortType === "" ? (
                          <img className="sort-svg" src={sortDefault} />
                        ) : label === activeHeader && sortType === "ASC" ? (
                          <img className="sort-svg" src={sortAscIcon} />
                        ) : label === activeHeader && sortType === "DESC" ? (
                          <img className="sort-svg" src={sortDescIcon} />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      ) : null}
                    </span>
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {gridData?.length > 0 ? (
        gridData.map((item, index) => {
          const {
            UserName: userName,
            UserEmail,
            UserPhone: phone,
            LoginAttempt,
            avatar,
            LastLogin,
          } = item;
          return (
            <Grid container spacing={0} className="tr" key={index}>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                alignItems="center"
              >
                <div className="table-member-details">
                  <div className="member-img">
                    {avatar && avatar?.length > 0 ? (
                      <img src={avatar} alt="user" />
                    ) : userName ? (
                      <Avatar {...StringAvatar(userName)} />
                    ) : (
                      <img src={DefaultMemberImg} alt="user" />
                    )}
                  </div>
                  <div className="member-profile-details">
                    <div className="member-name">
                      {userName ? userName : "-"}
                    </div>
                    <div className="member-mail-id">{UserEmail}</div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {phone ? (
                  <a className="phone-link" href={`tel:${phone}`}>
                    {formatPhoneNumber(phone)}
                  </a>
                ) : (
                  "-"
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {LoginAttempt ? LoginAttempt : "-"}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {LastLogin ? moment(LastLogin).format('MMM DD YYYY h:mm A') : "-"}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType="User Analytics" />
      )}
    </div>
  );
};

export default LoginAttempt;
