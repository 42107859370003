import { Grid, InputBase, Button } from '@material-ui/core';
import { FC, useState } from 'react';

export const CommunityHeader: FC<any> = ({
  searchText,
  setSearchText,
  handleNewDiscussionModal,
  setDetailedDiscussion,
  isViewAsMember
}) => {
  const [isMobileSearch, setMobileSearch] = useState(false);
  const handleMobileSearch = () => {
    setMobileSearch(true);
  };

  return (
    <div className="community-header">
      <Grid
        xs={12}
        container
        direction="row"
        // justifyItems="flex-end"
        justify="flex-end"
        alignItems="center"
      >
        <Grid
          item
          xs={5}
          sm={12}
          md={12}
          lg={3}
          className="community-header-left"
        >
          <h2 className="community-title">
            <i
              className="fas fa-arrow-left"
              onClick={() => setDetailedDiscussion(false)}
            ></i>
            Questions
          </h2>
        </Grid>
        <Grid
          item
          xs={7}
          sm={12}
          md={12}
          lg={9}
          className="community-header-right"
        >
          <div className="page-head">
            <div
              className={
                isMobileSearch
                  ? 'page-search-box page-search-box-visible'
                  : 'page-search-box'
              }
            >
              <i className="fas fa-search" onClick={handleMobileSearch}></i>
              <InputBase
                placeholder="Search"
                value={searchText && searchText.toString()}
                onChange={(event) => setSearchText(event.target.value)}
              />
              <i
                className="fas fa-times"
                onClick={() => setMobileSearch(false)}
              ></i>
            </div>
          </div>
          <div className="page-head">
            <Button
              variant="contained"
              size="small"
              className="btn-add"
              onClick={() => handleNewDiscussionModal(true)}
              disabled={isViewAsMember}
            >
              <span className="fas fa-plus-circle"></span> <span>New</span>
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};
