import { connect } from 'react-redux';
import { AccountSettings as AccountSettingsContent } from './AccountSettings';
import {
  clearChangePassword,
  onChangeOldPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  handleChangePasswordShow,
  onSubmitChangePassword,
  updateProfileRoute,
} from '../../../../actions/profile';

const mapStateToProps = ({
  user,
  changePasswordForm,
  profile,
}: {
  changePasswordForm: any;
  user: any;
  profile: any;
}) => ({
  user,
  changePasswordForm,
  profile,
});

export const AccountSettings = connect(mapStateToProps, {
  clearChangePassword,
  onChangeOldPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  handleChangePasswordShow,
  onSubmitChangePassword,
  updateProfileRoute,
})(AccountSettingsContent);
