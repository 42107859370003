import { Dispatch } from 'redux';
import { TOGGLE_MODAL } from '../constants';

export const showModal =
  (flag = false) =>
  async (dispatch: Dispatch) => {
    return dispatch({
      type: TOGGLE_MODAL,
      isModal: true,
      isCreate: flag,
    });
  };

export const closeModal = () => async (dispatch: Dispatch) => {
  dispatch({
    type: TOGGLE_MODAL,
    isModal: false,
    isCreate: false,
  });
};
