import { FC, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate, useParams } from 'react-router-dom'; // TODO implement v6
import { MemberHeader } from './MemberHeader';
import { AddMemberForm } from './AddMemberForm';
import { Loader, WarningPopup } from '../../../utils';
import '../../../css/fonts.css';
import '../../../css/all.min.css';
import '../../../css/style.css';
import './members-add.scss';
import '../../../scss/styles.scss';

export const AddMemberContent: FC<any> = ({
  getMembers,
  clearAddMember,
  onChangeAddMemberParent,
  onChangeAddMemberName,
  onChangeAddMemberStreet,
  onChangeAddMemberCity,
  onChangeAddMemberState,
  onChangeAddMemberZip,
  onChangeAddMemberPhone,
  onChangeAddMemberMobile,
  onChangeAddMemberJoinDate,
  onChangeAddMemberAvatarImage,
  onChangeAddMemberCanHandDeliver,
  updateNavigationFromParent,
  updateNavigationFromView,
  updateNavigationFromTab,
  onAddMember,
  getMember,
  onEditMember,
  members: { membersList },
  memberForm,
  getStateLists,
  common: { statesList },
}) => {
  const location: any = useLocation();
  const { spinner, redirectTo, navFromParent, navFromTab, isFormUpdated } =
    memberForm;
  // declare
  const [enableWarningAlert, setEnableWarningAlert] = useState(false);
  const [nav, setNav] = useState('none');
  const [goBackMember, setGoBackMember] = useState<any>(null);
  // get data from navigation
  const navigate = useNavigate();
  const { id: idValue } = useParams<{ id: string }>();
  // get params
  const id = idValue ? idValue : memberForm.id;
  const isAddMode = !idValue;
  const isLocationMode =
    location && location.state && location.state.navigateTab === 'LOCATIONS'
      ? true
      : false;

  // get passed details
  const fromParentID =
    location && location.state ? location.state.parentID : null;
  const navigateFrom =
    location.state && location.state.navigateFrom
      ? location.state.navigateFrom
      : 'LIST';
  const navigateTab =
    location.state && location.state.navigateTab
      ? location.state.navigateTab
      : 'USERS';

  useEffect(() => {
    if (!(statesList?.length > 0)) {
      getStateLists();
    }
    getMembers();
    // set navigation details
    updateNavigationFromParent(fromParentID);
    updateNavigationFromView(navigateFrom);
    updateNavigationFromTab(navigateTab);

    if (id) {
      getMember(id);
    }
    // clear
    return () => {
      clearAddMember();
    };
  }, []);

  // functions start
  const handleClickOpen = () => {
    setEnableWarningAlert(true);
  };
  const handleClose = () => {
    setEnableWarningAlert(false);
  };

  const handleCancel = () => {
    setEnableWarningAlert(false);
    handleBackNavigation(navFromParent || id);
  };
  const handleBackNavigation = (callBackId: number) => {
    // goback id
    const g = fromParentID ? fromParentID : callBackId;
    setGoBackMember(g);

    if (
      (isAddMode && g) ||
      (!isAddMode && navigateFrom && navigateFrom == 'VIEW')
    ) {
      setNav('goBack');
    } else {
      navigate('/members');
    }
  };

  //To sort the membersList data by name property
  membersList =
    membersList && membersList.length > 0
      ? membersList.sort((a: any, b: any) => {
          return a['name'].localeCompare(b['name']);
        })
      : membersList;

  if (redirectTo) {
    return (
      <Navigate
        to={
          navFromParent
            ? `/member/view/${navFromParent}/`
            : `/member/view/${id}/`
        }
        state={{ openTab: navFromTab }}
      />
    );
  }

  if (nav === 'goBack' && goBackMember) {
    return (
      <Navigate
        to={`/member/view/${goBackMember}/`}
        state={{ openTab: navFromTab }}
      />
    );
  }

  return (
    <div className="relative-wrapper">
      {spinner && (
        <div className="member-loader">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      )}
      <div>
        <MemberHeader
          isAddMode={isAddMode}
          handleGoBack={() =>
            !isFormUpdated
              ? handleBackNavigation(navFromParent || id)
              : handleClickOpen()
          }
        />
        <AddMemberForm
          memberForm={memberForm}
          membersList={membersList}
          onChangeAddMemberParent={onChangeAddMemberParent}
          onChangeAddMemberName={onChangeAddMemberName}
          onChangeAddMemberStreet={onChangeAddMemberStreet}
          onChangeAddMemberCity={onChangeAddMemberCity}
          onChangeAddMemberState={onChangeAddMemberState}
          onChangeAddMemberZip={onChangeAddMemberZip}
          onChangeAddMemberPhone={onChangeAddMemberPhone}
          onChangeAddMemberMobile={onChangeAddMemberMobile}
          onChangeAddMemberJoinDate={onChangeAddMemberJoinDate}
          onChangeAddMemberAvatarImage={onChangeAddMemberAvatarImage}
          onChangeAddMemberCanHandDeliver={onChangeAddMemberCanHandDeliver}
          onAddMember={onAddMember}
          onEditMember={onEditMember}
          isLocationMode={isLocationMode}
          handleClickCancel={() =>
            !isFormUpdated
              ? handleBackNavigation(navFromParent || id)
              : handleClickOpen()
          }
          statesList={statesList}
        />

        {isFormUpdated && (
          <WarningPopup
            open={enableWarningAlert}
            type={'cancel'}
            handleProceed={handleCancel}
            handleClose={handleClose}
          />
        )}
      </div>
    </div>
  );
};
