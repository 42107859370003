import { FC } from 'react';
import { DwollaFundingSource, DwollaTransfer } from '../../../../../common';
import { centsToFormattedDollars } from '../../../../../utils';
import { Encrypt, Decrypt } from '../../../../../utils/cryptoFunction'

export const RebateActionModal: FC<{
  selectedRebate: {
    amount: number;
    clearedat: null | Date;
    createdat: null | Date;
    deliveredat: null | Date;
    handdeliver: boolean | number;
    id: number;
    memberName: string | null;
    memberid: number;
    memberrebatereceiptid: number;
    memberrerebatereceiptitemtypeid: number;
    name: 'check' | 'historic';
    sentat: null | Date;
    updatedat: null | Date;
    fundingSource?: DwollaFundingSource;
    transferInfo?: DwollaTransfer;
    transferFee: number;
  };
}> = ({ selectedRebate }) => {
  if (selectedRebate?.fundingSource) {
    const { fundingSource } = selectedRebate ?? {};
    const fundingAccountName = Decrypt(fundingSource?.name?.toString());
    if (selectedRebate.transferInfo) {
      const { value } = selectedRebate.transferInfo.amount;
      return (
        <>
          <div className="rebates-action-header">
            <p>
              Member has requested a transfer to their bank account{' '}
              <strong>{fundingAccountName}</strong>
            </p>
            <p>
              The Dwolla transfer is in progress, the current status is <strong>{selectedRebate.transferInfo.status}</strong>.
            </p>
          </div>
          <h3>
            Requested amount:{' '}
            {Number(value)
              ? centsToFormattedDollars(Number(value) * 100)
              : '-'}
          </h3>
        </>
      );
    }
    const { amount, transferFee } = selectedRebate ?? {};
    const requestedAmount = amount - transferFee;
    return (
      <>
        <div className="rebates-action-header">
          <p>
            Member has requested a transfer to their bank account{' '}
            <strong>{fundingAccountName}</strong>
          </p>
        </div>
        <h3>
          Requested amount:{' '}
          {Number(requestedAmount)
            ? centsToFormattedDollars(requestedAmount)
            : '-'}
        </h3>
        {transferFee > 0 && (
          <h4 style={{ marginBottom: '10px' }}>
            Standard fee: {centsToFormattedDollars(transferFee)}
          </h4>
        )}
        <h3>Total: {Number(amount) ? centsToFormattedDollars(amount) : '-'}</h3>
      </>
    );
  }

  const { handdeliver: handDeliver, amount } = selectedRebate || {};

  return (
    <>
      <div className="rebates-action-header">
        {(typeof handDeliver === 'number' ? handDeliver === 1 : handDeliver) ? (
          <p>
            Member has requested the check to be <strong>Hand-Delivered</strong>
          </p>
        ) : (
          <p>
            Member has requested the check to be <strong>Mailed</strong>
          </p>
        )}
      </div>
      <h3>
        Requested amount:{' '}
        {Number(amount) ? centsToFormattedDollars(amount) : '-'}
      </h3>
    </>
  );
};

export default RebateActionModal;
