interface RoleInfo {
  roleId: number;
  label: string;
  group: 'admin' | 'member' | 'vendor';
}

interface RolesConfiguration {
  memberuser: RoleInfo;
  membermanager: RoleInfo;
  memberadmin: RoleInfo;
  vendorsales: RoleInfo;
  vendoradmin: RoleInfo;
  bradmin: RoleInfo;
}

const rolesConfiguration: RolesConfiguration = {
  memberuser: {
    roleId: 1,
    label: 'Member User',
    group: 'member',
  },
  membermanager: {
    roleId: 2,
    label: 'Member Manager',
    group: 'member',
  },
  memberadmin: {
    roleId: 3,
    label: 'Member Admin',
    group: 'member',
  },
  vendorsales: {
    roleId: 4,
    label: 'Account Representative',
    group: 'vendor',
  },
  vendoradmin: {
    roleId: 5,
    label: 'Vendor Admin',
    group: 'vendor',
  },
  bradmin: {
    roleId: 6,
    label: 'BR Admin',
    group: 'admin',
  },
};

export const rolesEnum: Readonly<RolesConfiguration> = Object.freeze(rolesConfiguration);
export const rolesEnumKeys = Object.keys(rolesEnum);
