import { assign, send } from 'xstate';
import { createModel } from 'xstate/lib/model';
import { isValidPhoneNo, removePhoneNoFormat } from '../../../../utils';
import { toast } from 'react-toastify';

const accountSettingsModel = createModel(
  {
    userId: null as null | number,
    firstName: null as string | null,
    lastName: null as string | null,
    avatarFile: null as File | null,
    avatarUrl: null as string | null,
    email: null as string | null,
    phoneNumber: null as string | null,
  },
  {
    events: {
      submit: () => ({}),
      successfullyUpdated: (
        userId: number,
        firstName: string,
        lastName: string,
        avatarUrl: string,
        email: string,
        phoneNumber: string,
        message: string,
      ) => ({
        userId,
        firstName,
        lastName,
        avatarUrl,
        email,
        phoneNumber,
        message,
      }),
      updateAvatarFile: (avatarFile: File) => ({ avatarFile }),
      updateAvatarUrl: (avatarUrl: string) => ({ avatarUrl }),
      updateFirstName: (firstName: string) => ({ firstName }),
      updateLastName: (lastName: string) => ({ lastName }),
      updatePhoneNumber: (phoneNumber: string) => ({ phoneNumber }),
    },
  },
);

export const accountSettingsMachine = accountSettingsModel.createMachine(
  {
    context: accountSettingsModel.initialContext,
    initial: 'editingForm',
    states: {
      editingForm: {
        tags: 'editing',
        on: {
          submit: [
            {
              actions: () => toast.error("The user's first name is required."),
              description: 'The user first name is required.',
              cond: (context) => !context.firstName,
            },
            {
              actions: () => toast.error("The user's last name is required."),
              description: 'The user last name is required.',
              cond: (context) => !context.lastName,
            },
            {
              actions: () => toast.error("The user's phone number must be formatted correctly."),
              description: 'The user phone number must be blank or correctly formatted.',
              cond: (context) => !!context.phoneNumber && !isValidPhoneNo(context.phoneNumber),
            },
            {
              target: 'submittingForm',
            },
          ],
          updateAvatarFile: {
            actions: assign((_, { avatarFile }) => {
              return {
                avatarFile,
                avatarUrl: URL.createObjectURL(avatarFile),
              }
            }),
          },
          updateFirstName: {
            actions: assign({
              firstName: (_, { firstName }) => firstName,
            }),
          },
          updateLastName: {
            actions: assign({
              lastName: (_, { lastName }) => lastName,
            }),
          },
          updatePhoneNumber: {
            actions: assign({
              phoneNumber: (_, { phoneNumber}) => removePhoneNoFormat(phoneNumber),
            }),
          },
        },
      },
      submittingForm: {
        tags: 'loading',
        on: {
          successfullyUpdated: {
            actions: [
              'notifySuccess',
              assign((_, {
                userId,
                firstName,
                lastName,
                avatarUrl,
                email,
                phoneNumber,
              }) => ({
                userId,
                firstName,
                lastName,
                avatarUrl,
                email,
                phoneNumber,
                avatarFile: null,
              })),
              'updateUserSettings',
            ],
            description: 'User has been successfully updated, send the user back to editingForm.',
            target: 'editingForm',
          },
        },
        invoke: {
          src: 'updateUserSettings',
          onDone: [
            {
              actions: send((_, { data }) => ({
                type: 'successfullyUpdated',
                userId: data.data.id,
                firstName: data.data.firstname,
                lastName: data.data.lastname,
                avatarUrl: `${data.data.avatar}?${new Date().getTime()}`,
                email: data.data.email,
                phoneNumber: data.data.phone,
                message: data.message,
              })),
              cond: (_, { data }) => data.success,
              description: 'User has been successfully updated, relay the data to the successfullyUpdated event.',
            },
            {
              actions: [
                'notifyError',
                (_, event) => console.error('error event: ', event),
              ],
              target: 'editingForm',
            },
          ],
          onError: {
            actions: [
              'notifyError',
              (_, event) => console.error('error event: ', event),
            ],
            target: 'editingForm',
          },
        },
      },
    },
  },
  {
    actions: {
      notifyError: () => console.error('The notify error action has not been implemented.'),
      notifySuccess: () => console.error('The notify success action has not been implemented.'),
      updateUserSettings: () => console.error('The update user settings action has not been implemented.'),
    },
    services: {
      updateUserSettings: () =>
        Promise.reject({
          data: null,
          error: null,
          success: false,
          message: 'The update user settings service has not been implemented.',
        }),
    },
  },
);

export type AccountSettingsMachine = typeof accountSettingsMachine;
