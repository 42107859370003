import { FC } from 'react';
import Button from '@material-ui/core/Button';
import '../community.scss';

export const CategoryHeader: FC<any> = ({
  updateNewChannel,
  searchText,
  setSearchText,
  isSearch,
  setSearch,
  user: { isBRAdmin },
}) => {
  const onChangeSearchText = (value: string) => {
    setSearchText(value);
  };

  const setSearchTextValue = () => {
    setSearch(false);
    setSearchText('');
  };

  return (
    <div className="community-category-header">
      <div className="community-left-heading">Channels</div>
      {isBRAdmin && (
        <div className="community-add-new-channel">
          <Button
            className="add-new-channel"
            onClick={() => updateNewChannel(true)}
          >
            <i className="fas fa-plus-circle channel-add-icon" />
            New
          </Button>
        </div>
      )}
      <div className="community-channel-search">
        <div
          className="community-channel-search-box"
          onClick={() => setSearch(true)}
        >
          <i className="fas fa-search search-icon" />
          <input type="text" placeholder="search channel.." />
        </div>

        {isSearch && (
          <div className="community-channel-search-box-click">
            <i className="fas fa-search"></i>
            <input
              type="text"
              placeholder="Search Channel.."
              value={searchText && searchText.toString()}
              onChange={(event) => onChangeSearchText(event.target.value)}
            />
            <i
              className="fas fa-times search-icon"
              onClick={() => setSearchTextValue()}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CategoryHeader;
