import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import CardImg from "../../../../images/ad1.jpg";
import Tooltip from "@material-ui/core/Tooltip";

import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";

export const ViewAdCard=({
  handleCloseViewAdModal,
  item,
  manageAdsList : { adsTypeList }
})=>{

  const {
    adId,
    adImage,
    adUrl,
    ranking,
    productId,
    adTypeId,
    disableDate,
    specials,
    description,
  } = item;

  const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });
  const Dialoghead = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <i class="fas fa-times"></i>
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

  const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(0),
    },
  }))(MuiDialogContent);

  const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2, 5),
      background: "#e9f0f9",
    },
  }))(MuiDialogActions);

  const getAdTypeName = () => {
    let item = adsTypeList.filter((currentItem)=>currentItem.id == adTypeId) || [];
    return item.length>0 && item[0]['name'] || "-";
  };

  return (
    <Dialog
        onClose={handleCloseViewAdModal}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="dialog-box manage-ads-popup-details"
      >
        <Dialoghead
          onClose={handleCloseViewAdModal}
          className="manage-ads-popup-details-head"
        ></Dialoghead>

        <DialogContent>
          <Grid container spacing={0}>
            <Grid item xs={12} lg={6}>
              <div className="manage-ad-details-img">
                <img src={CardImg}></img>
              </div>
            </Grid>
            <Grid item xs={12} lg={6}>
              <div className="manage-ad-details">
                <div className="manage-ads-h3">{adUrl}</div>
                <div className="manage-ads-h5">{getAdTypeName()}</div>
                <div className="manage-ads-h4">Convection Ovens</div>
                <div className="manage-ads-h6">by Adcraft</div>
                <div className="manage-ads-p">
                  {description}
                </div>

                <div container className="manage-ads-details-action-bar">
                  <div className="manage-ads-details-action-bar-left">
                    <div className="manage-ads-active">ACTIVE</div>
                    <div className="manage-ads-title-info">
                      {specials}
                    </div>
                  </div>
                  <div className="manage-ads-details-action-bar-right">
                    <div className="manage-ads-rank-number">#{ranking}</div>
                    <div className="manage-ads-rank-text">Ranking</div>
                  </div>
                </div>

                <div className="manage-ads-details-buttons modal-buttons-container">
                  <Button className="cancel-user-button">Delete</Button>
                  <Button
                    autoFocus
                    className="button-red"
                  >
                    InActive
                  </Button>
                  <Button
                    autoFocus
                    className="button-grey"
                  >
                    Edit
                  </Button>
                </div>
              </div>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
  );
};
