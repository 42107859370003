import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { SearchFilter } from '../../../../components/Search';
import AuthorizationService from '../../../../authorization/AuthorizationService';

export const DistributionCentersHeader: FC<{
  searchText: string;
  setSearchText: (searchText: string) => void;
  showDCModal: () => void;
}> = ({ searchText, setSearchText, showDCModal }) => {
  return (
    <Grid
      container
      direction="row"
      alignItems="center"
      className="filter-form-header"
    >
      <Grid>
        <SearchFilter searchText={searchText} setSearchText={setSearchText} />
      </Grid>
      {AuthorizationService.isAuthorizedHTML('VendorDcAddButton') && (
        <Grid>
          <Button className="btn-add" component="label" onClick={showDCModal}>
            <span className="fas fa-plus-circle" />
            <span>Add New</span>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

export type DistributionCentersHeaderType = typeof DistributionCentersHeader;
