import {GET_VENDORS_KPI_API, VendorDeleteKpiError} from '../../constants';

export const deleteVendorKPI = async ({
    slectedkpiID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDORS_KPI_API}/${slectedkpiID}`, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
        });
        if (!response.ok) throw VendorDeleteKpiError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw VendorDeleteKpiError;
    }
};