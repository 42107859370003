import {
  Button,
  Popper,
  Grow,
  // Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from '@material-ui/core';
import { useEffect, useState, useRef, FC, useMemo } from 'react';
import { Loader } from '../utils';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import useSortableData from '../modules/rebates/components/Table/useSortableData';
import {SearchNotFound} from './SearchNotFound';

export const BRDropdown: FC<{
  selectedOption: number | string | null;
  menuList: { value: number; label: string }[];
  handleSelected?: (value: any) => void;
  spinner: boolean;
  className?: string;
  noOptionLabel?: string;
  disabled?: boolean;
  isMemberList ?: any;
  isYear ?: boolean;
}> = ({
  selectedOption,
  menuList,
  handleSelected,
  spinner,
  className,
  noOptionLabel,
  disabled = false,
  isMemberList = false,
  isYear = false,
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const[isASC,setIsASC] = useState(true)
  const[search,setSearch] = useState('')
  const{ items, requestSort, requestSearch } = useSortableData(menuList)

  const handleToggle = () => {
    if (disabled) return;
    setOpen((prevOpen) => !prevOpen);
    setSearch('')
    requestSearch('')
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    // if (prevOpen.current === true && open === false) {
    //   anchorRef.current.focus();
    // }
    prevOpen.current = open;
  }, [open]);

  const handleOptionChange = (item: any, event: any) => {
    handleClose(event);
    handleSelected?.(item);
  };

  const handleSearch = (e:any) => {
    setSearch(e.target.value)
    requestSearch(e.target.value)
  }

  // BR-785
  const elementRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      { threshold: 0.1 } // Customize the threshold value as needed
    );
    if (elementRef.current) {
      observer.observe(elementRef.current);
    }
    return () => {
      if (elementRef.current) {
        observer.unobserve(elementRef.current);
      }
    };
  }, []);
  useEffect(() => {
    if(!isVisible) {
      setOpen(false)
    }
  },[isVisible])

  return (
    <div className={`dashboard-kpi-dropdown ${className ? className : ""}`}
      style={{
        display : isYear ? 'none' : 'block'
      }}
      ref={elementRef}
    >
      <Button
        className={`dashboard-kpi-dropdown-button ${isMemberList && 'br-bropdown-responsive'}`}
        ref={anchorRef}
        aria-controls={open ? "menu-list-dropdown" : ""}
        aria-haspopup="true"
        onClick={handleToggle}
        disabled={disabled}
      >
        {isMemberList ? (
          <span className='br-dropdown-label'>
            {selectedOption
              ? menuList.find((data) => data.value === selectedOption)?.label
              : items.find((data) => data.value === selectedOption)?.label}
          </span>
        ) : (
          <span>
            {(selectedOption === "" || selectedOption === null) &&
              (noOptionLabel ?? "")}
            {
              items.filter((option) => option.value === selectedOption)?.[0]
                ?.label
            }
          </span>
        )}
        {spinner ? (
          <div className="dropdown-spinner">
            <Loader size={15} />
          </div>
        ) : (
          <i className="fas fa-sort-down" />
        )}
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={""}
        transition
        disablePortal
        className={`dashboard-kpi-dropdown-box ${
          isMemberList && "member-drop-down"
        }`}
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
          flip: {
            enabled: false, // Disable the flip modifier
          },
        }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper
                sx={{
                  pt: isMemberList ? "12px" : 0,
                }}
              >
                {isMemberList && (
                  <Paper
                    sx={{
                      p: "2px 0px",
                      display: "flex",
                      alignItems: "center",
                      height: "50px",
                      width: ["100%", "90%", "385px"],
                      margin: "0 auto 5px auto",
                    }}
                  >
                    <div
                      // sx={{ p: '2px 5px' }}
                      style={{
                        width: "77px",
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "space-around",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setIsASC(!isASC);
                        requestSort("label");
                      }}
                    >
                      <p>All</p>
                      <span className="sort-icon">
                        <i className={`sort-svg fas fa-sort-down`} />
                      </span>
                    </div>
                    <Divider className="sortDivider" orientation="vertical" />
                    <InputBase
                      sx={{ ml: 2, flex: 1 }}
                      placeholder="Search"
                      value={search}
                      onChange={handleSearch}
                    />
                    <i className="fas fa-search search-icon" />
                  </Paper>
                )}
                <MenuList
                  autoFocusItem={isMemberList ? false : open}
                  id="menu-list-dropdown"
                  onKeyDown={handleListKeyDown}
                  className={`dropdown-menulist ${isMemberList && "dropdown-memberlist"}`}
                >
                  {items.length ? (
                    items.map((item, index) => {
                      const { label, value } = item;
                      return (
                        <MenuItem
                          onClick={(event) => handleOptionChange(item, event)}
                          selected={item.value === selectedOption}
                          key={index}
                        >
                          {label}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem>
                      <SearchNotFound sectionType={"Member"} />
                    </MenuItem>
                  )}
                </MenuList>
              </Paper>
            </ClickAwayListener>
          </Grow>
        )}
      </Popper>
    </div>
  );
};
