import moment from "moment";
import {
    UPDATE_REBATES_MEMBER_EXPORT_QUARTER,
    UPDATE_REBATES_MEMBER_EXPORT_YEAR,
    UPDATE_REBATES_MEMBER_EXPORT_MODAL,
    EXPORT_MEMBER_ADMIN_REBATE_ERROR,
    exportMemberRebatesError
} from "../../../constants";
import {saveAs} from "file-saver";
import {rebatesModalSpinner} from "../rebates";
import {exportMemberAdminList} from "../../../api/rebates/memberAdminRebates/EarnedRebates"

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const memberAdminRebatesExportModal = (isMemberAdminExport) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATES_MEMBER_EXPORT_MODAL,
        isMemberAdminExport
    });
}

export const handleMemberAdminExportQuarter = (exportQuarter) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATES_MEMBER_EXPORT_QUARTER,
        exportQuarter
    });
}

export const handleMemberAdminExportYear = (exportYear) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATES_MEMBER_EXPORT_YEAR,
        exportYear
    });
}

export const onExportMemberAdminRebates = () => async (dispatch, getState) => {
    const {memberAdminRebates} = getState();
    const {exportQuarter, exportYear, selectedMember} = memberAdminRebates;
    dispatch(rebatesModalSpinner(true))
    try {
        const response = await exportMemberAdminList({
            memberId: selectedMember.id,
            exportQuarter,
            exportYear
        })
        const date = new Date();
        const fileName = `Member_Admin_Rebates_${moment(date).format('DD_MMM_YYYY_hh:mm:ss_A')}.xls`;

        saveAs(response, fileName);
        dispatch(rebatesModalSpinner(false))
        dispatch(memberAdminRebatesExportModal(false))
    } catch (error) {
        dispatch(memberAdminRebatesExportModal(false))
        dispatch(rebatesModalSpinner(false))
        if (error === EXPORT_MEMBER_ADMIN_REBATE_ERROR) {
            toast.error(exportMemberRebatesError)
        }
    }
}