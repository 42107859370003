import { connect } from "react-redux";

import "../../css/fonts.css";
import "../../css/all.min.css";
import "../../css/style.css";
import "./ads.scss";
import "../../scss/styles.scss";

import { ManageAdsComponent } from "./manageAdsComponent";
import {
  getAdTypes,
  getAdList,
  handleShowAddAdsModal,
  handleCloseAddAdsModal,
  clearAdsForm,
  handleChangeAdUrl,
  handleChangeAdType,
  handleChangeAdProductName,
  handleChangeAdProductBrand,
  handleChangeAdSpecial,
  handleChangeAdDescription,
  handleChangeAdStatus,
  handleChangeAdRating,
  handleChangeAdImage,
  onAddNewAds,
  onEditAds,
  handleShowAdsTypeModal,
  handleCloseAdsTypeModal,
  clearAdsTypeForm,
  handleChangeAdTypeName,
  handleChangeAdTypeDescription,
  onAddNewAdType,
  handleShowViewAdModal,
  handleCloseViewAdModal,
} from "../../actions/manageads";
import { getProductBrands, getProductNames } from "../../actions/products";

const mapStateToProps = (state) => {
  const { manageAdsList, adsCreate, adsTypeCreate, products } = state;
  return {
    manageAdsList,
    adsCreate,
    adsTypeCreate,
    products,
  };
};
export const ManageAdsNew = connect(mapStateToProps, {
  getAdTypes,
  getAdList,
  getProductBrands,
  getProductNames,
  handleShowAddAdsModal,
  handleCloseAddAdsModal,
  clearAdsForm,
  handleChangeAdUrl,
  handleChangeAdType,
  handleChangeAdProductName,
  handleChangeAdProductBrand,
  handleChangeAdSpecial,
  handleChangeAdDescription,
  handleChangeAdStatus,
  handleChangeAdRating,
  handleChangeAdImage,
  onAddNewAds,
  onEditAds,
  handleShowAdsTypeModal,
  handleCloseAdsTypeModal,
  clearAdsTypeForm,
  handleChangeAdTypeName,
  handleChangeAdTypeDescription,
  onAddNewAdType,
  handleShowViewAdModal,
  handleCloseViewAdModal,
})(ManageAdsComponent);
