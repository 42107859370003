import moment from "moment";
import {
  UPDATE_MEMBER_FORM_SPINNER,
  UPDATE_MEMBER_FORM_IS_EDIT,
  UPDATE_MEMBER_FORM_FORM_UPDATED,
  UPDATE_MEMBER_FORM_PARENTID,
  UPDATE_MEMBER_FORM_ID,
  UPDATE_MEMBER_FORM_NAME,
  UPDATE_MEMBER_FORM_STREET,
  UPDATE_MEMBER_FORM_CITY,
  UPDATE_MEMBER_FORM_STATE,
  UPDATE_MEMBER_FORM_ZIP,
  UPDATE_MEMBER_FORM_PHONE,
  UPDATE_MEMBER_FORM_MOBILE,
  UPDATE_MEMBER_FORM_JOININGDATE,
  UPDATE_MEMBER_FORM_CANHANDDELIVER,
  UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME,
  UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH,
  UPDATE_MEMBER_FORM_REDIRECT,
  UPDATE_MEMBER_FORM_NAME_ERROR,
  UPDATE_MEMBER_FORM_STREET_ERROR,
  UPDATE_MEMBER_FORM_CITY_ERROR,
  UPDATE_MEMBER_FORM_STATE_ERROR,
  UPDATE_MEMBER_FORM_ZIP_ERROR,
  UPDATE_MEMBER_DETAIL,
  MemberUpdateSuccess,
  MemberUpdateError,
} from "../../constants";
import {
  isValidZipcode,
  ZipcodeFormat,
  formatPhoneNumber,
  isValidPhoneNo,
} from "../../utils";
import {viewMember} from "./members";
import {getMemberById, updateMember} from "../../api/members";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const getMember = (id) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_FORM_SPINNER,
    spinner: true,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_IS_EDIT,
    isEdit: true,
  });
  try {
    const memberDetail = await getMemberById(id);
    if (memberDetail.success) {
      if (memberDetail.data && typeof memberDetail.data == "object") {
        dispatch({
          type: 'UPDATE_CHILD_COUNT',
          childCount: memberDetail.data.childCount,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_PARENTID,
          parentId: memberDetail.data.parentid,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_ID,
          id: memberDetail.data.id,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_NAME,
          name: memberDetail.data.name,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_STREET,
          street: memberDetail.data.street,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_CITY,
          city: memberDetail.data.city,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_STATE,
          state: memberDetail.data.state,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_ZIP,
          zip: ZipcodeFormat(memberDetail.data.zip),
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_PHONE,
          phone: formatPhoneNumber(memberDetail.data.phone),
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_MOBILE,
          mobile: formatPhoneNumber(memberDetail.data.mobile),
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_JOININGDATE,
          joiningDate: memberDetail.data.startdate,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_CANHANDDELIVER,
          canHandDeliver: memberDetail.data.canHandDeliver,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME,
          avatar: memberDetail.data.avatar,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH,
          avatarPath: memberDetail.data.avatar
            ? memberDetail.data.avatar
            : "",
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_SPINNER,
          spinner: false,
        });
      }
    }
  } catch (error) {
  }
};

export const onEditMember = () => async (dispatch, getState) => {
  const {memberForm} = getState();
  const {
    parentId,
    id,
    name,
    street,
    city,
    state,
    zip,
    phone,
    mobile,
    joiningDate,
    canHandDeliver,
    avatar,
    avatarFile,
    navFromParent,
    navFromView,
    navFromTab,
  } = memberForm;
  const isValidName = validateText(name);
  const isValidStreet = validateText(street);
  const isValidCity = validateText(city);
  const isvalidState = validateSelectionType(state);
  const isValidZip = isValidZipcode(zip);
  const isValidPhone = validateText(phone);
  const isValidMobile = mobile == "" || isValidPhoneNo(mobile);

  const isError =
    !isValidName ||
    !isValidStreet ||
    !isValidCity ||
    !isvalidState ||
    !isValidZip

  if (!isValidName) {
    dispatch({
      type: UPDATE_MEMBER_FORM_NAME_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidStreet) {
    dispatch({
      type: UPDATE_MEMBER_FORM_STREET_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidCity) {
    dispatch({
      type: UPDATE_MEMBER_FORM_CITY_ERROR,
      errorFlag: true,
    });
  }
  if (!isvalidState) {
    dispatch({
      type: UPDATE_MEMBER_FORM_STATE_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidZip) {
    dispatch({
      type: UPDATE_MEMBER_FORM_ZIP_ERROR,
      errorFlag: true,
    });
  }
  try {
    if (!isError) {
      dispatch({
        type: UPDATE_MEMBER_FORM_SPINNER,
        spinner: true,
      });
      const response = await updateMember({
        parentId,
        id,
        name,
        street,
        city,
        state,
        zip,
        phone,
        mobile,
        joiningDate,
        canHandDeliver,
        avatar,
        avatarFile,
      });
      const {success, data} = response;
      success ? toast.success(MemberUpdateSuccess) : null;

      if (success) {
        dispatch(viewMember(data.id));
        dispatch({
          type: UPDATE_MEMBER_DETAIL,
          detail: data,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_FORM_UPDATED,
          isFormUpdated: false,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_REDIRECT,
          redirectTo: true,
        });
      }
    }
    dispatch({
      type: UPDATE_MEMBER_FORM_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_FORM_SPINNER,
      spinner: false,
    });
    if (error) {
      toast.error(MemberUpdateError);
    }
  }
};

const validateSelectionType = (selection) => {
  if (selection == "0" || selection == null || selection == "") return false;
  else {
    return true;
  }
};

const validateText = (text) => {
  if (text == "0" || text == null || text.trim() == "") return false;
  else {
    return true;
  }
};
