import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { SearchNotFound } from '../../../../components/SearchNotFound';
import AuthorizationService from '../../../../authorization/AuthorizationService';
import { useStyles } from '../../../../utils';
import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';

export const LocationTable: FC<{
  gridHeader: any[];
  gridData: any[];
  updateDeleteMemberLocation: any;
  setSortKey: any;
  sortType: any;
  memberDetail: any;
}> = ({
  gridHeader,
  gridData,
  updateDeleteMemberLocation,
  setSortKey,
  sortType,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: string) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  const showLocationDelete = AuthorizationService.isAuthorizedHTML(
    'deleteMemberLocation',
  );

  return (
    <div className={`${classes.root} table-wrapper`}>
      {gridData.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {label === 'Action' ? (
                  <div className={label === 'Action' ? 'action-header' : ''}>
                    <span className="grid-header">{label}</span>
                  </div>
                ) : (
                  <div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader ? (
                        sortType === '' ? (
                          <img className="sort-svg" src={sortDefault} />
                        ) : label === activeHeader && sortType === 'ASC' ? (
                          <img className="sort-svg" src={sortAscIcon} />
                        ) : label === activeHeader && sortType === 'DESC' ? (
                          <img className="sort-svg" src={sortDescIcon} />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      ) : null}
                    </span>
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {gridData.length > 0 ? (
        gridData.map((item) => {
          const { name, street, city, state, zip, phone, mobile } = item;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {name}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {street}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {city}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                {state}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                {zip}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                {phone ? phone : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {mobile ? mobile : '-'}
              </Grid>
              <Grid
                className="td action-col"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {showLocationDelete ? (
                  <Tooltip title="Unassign">
                    <Button
                      className="btn-circle-trash"
                      variant="outlined"
                      color="secondary"
                      onClick={() => updateDeleteMemberLocation(item)}
                    >
                      <i className="fas fa-times" />
                    </Button>
                  </Tooltip>
                ) : null}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Locations'} />
      )}
    </div>
  );
};
