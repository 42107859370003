import { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import '../community.scss';

export const CommunityQuestionsDetailsBottomContent: FC<any> = (props) => {
  const {
    activeDiscussion,
    handleReport,
    user: { id: loggedInUserID, isBRAdmin },
  } = props;

  const { userId, viewCount, commentCount, flagreasonid, lockedAt } =
    activeDiscussion || {};

  return (
    <div className="community-questions-bottom">
      <div className="community-questions-bottom-left">
        <div className="community-questions-button">
          <i className="fas fa-comment-dots" />
          <span className="community-questions-number">{commentCount}</span>
          <span className="community-questions-text">Comments</span>
        </div>
        <div className="community-questions-button">
          <i className="fas fa-eye" />
          <span className="community-questions-number">{viewCount}</span>
          <span className="community-questions-text">Views</span>
        </div>
      </div>
      <div className="community-questions-bottom-right">
        {isBRAdmin && (
          <Tooltip title="Archive">
            <Button
              variant="contained"
              size="small"
              className={
                lockedAt
                  ? 'btn-community-trash action-active'
                  : 'btn-community-trash'
              }
            >
              <i className="fas fa-lock" />
            </Button>
          </Tooltip>
        )}
        {loggedInUserID !== userId && (
          <Tooltip title="Report">
            <Button
              variant="contained"
              size="small"
              className={
                flagreasonid
                  ? 'btn-community-report report-active'
                  : 'btn-community-report'
              }
              onClick={() => handleReport(true, activeDiscussion)}
            >
              <i className="fas fa-exclamation-triangle" />
            </Button>
          </Tooltip>
        )}
      </div>
    </div>
  );
};
