import { FC } from 'react';
import '../scss/styles.scss';
import NotFoundIconSearch from '../images/search-not-found.svg';
import InProgressIcon from '../images/1.gif';

export const SearchNotFound: FC<{
  sectionType: string;
  status?: string;
}> = ({ sectionType, status }) => {
  if (status && status === 'INPROGRESS') {
    return (
      <div className="section-not-found">
        <div className="in-progress-img">
          <img src={InProgressIcon} />
        </div>
        <h4>{sectionType} Are In Progress</h4>
      </div>
    );
  }
  if (sectionType !== '') {
    const paragraphContent = sectionType.toLowerCase();
    return (
      <div className="section-not-found">
        <div className="section-not-found-img">
          <img src={NotFoundIconSearch} />
        </div>
        <h4>No {sectionType} Found</h4>
        <p>We can’t find any {paragraphContent} matching your search</p>
      </div>
    );
  } else {
    return (
      <div className="section-not-found">
        <div className="section-not-found-img">
          <img src={NotFoundIconSearch} />
        </div>
        <h4>No Items Found</h4>
        <p>We can’t find any item matching your search</p>
      </div>
    );
  }
};
