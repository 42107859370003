import { AdsTypeCreateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const createAdType = async ({ adTypeName, adTypeDesc }) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('name', adTypeName);
    bodyFormData.append('description', adTypeDesc);
    const response = await serviceInterceptor.post('/ad-types', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response?.data?.success) {
      throw AdsTypeCreateError;
    }
    return response.data;
  } catch (error) {
    throw AdsTypeCreateError;
  }
};
