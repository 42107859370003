import axios, { AxiosRequestHeaders } from 'axios';

export const serverApiV2Instance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL ?? ''}/api/v2`,
  headers: {
    'cache-control': 'no-cache',
  },
});

// Request call
serverApiV2Instance.interceptors.request.use(
  async (config) => {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const member = JSON.parse(sessionStorage.getItem('user') || '{}');
    if (Object.keys(member).length) {
      (config.headers as AxiosRequestHeaders)['x-access-token'] = member.accessToken;
    }else{
      (config.headers as AxiosRequestHeaders)['x-access-token'] = user.accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response call
serverApiV2Instance.interceptors.response.use(
  (next) => {
    return Promise.resolve(next);
  },
  (error) => {
    // You can handle error here and trigger warning message without get in the code inside
    return Promise.reject(error);
  },
);

export default serverApiV2Instance;
