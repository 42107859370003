import {
  CLEAR_MEMBER_USER_FORM,
  UPDATE_MEMBER_USER_FORM_SPINNER,
  UPDATE_MEMBER_USER_IS_EDIT,
  UPDATE_MEMBER_USER_ID,
  UPDATE_MEMBER_USER_FIRST_NAME,
  UPDATE_MEMBER_USER_LAST_NAME,
  UPDATE_MEMBER_USER_EMAIL,
  UPDATE_MEMBER_USER_ROLE,
  UPDATE_MEMBER_USER_FIRST_NAME_ERROR,
  UPDATE_MEMBER_USER_LAST_NAME_ERROR,
  UPDATE_MEMBER_USER_EMAIL_ERROR,
  UPDATE_MEMBER_USER_ROLE_ERROR,
} from "../../constants";

const initialState = {
  typeid: 2,
  isLoading: false,
  isEdit: false,
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  role: 3,
  errorFirstname: false,
  errorLastname: false,
  errorEmail: false,
  errorRole: false,
  errorPhone: false,
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
  switch (action.type) {
    case CLEAR_MEMBER_USER_FORM:
      return {
        ...state,
        ...initialState,
      };
    case UPDATE_MEMBER_USER_FORM_SPINNER:
      return {
        ...state,
        isLoading: action.spinner,
      };
    case UPDATE_MEMBER_USER_IS_EDIT:
      return {
        ...state,
        isEdit: action.flag
      }
    case UPDATE_MEMBER_USER_ID:
      return {
        ...state,
        userId: action.value,
      };
    case UPDATE_MEMBER_USER_FIRST_NAME:
      return {
        ...state,
        firstName: action.value,
      };
    case UPDATE_MEMBER_USER_LAST_NAME:
      return {
        ...state,
        lastName: action.value,
      };
    case UPDATE_MEMBER_USER_EMAIL:
      return {
        ...state,
        email: action.value,
      };
    case UPDATE_MEMBER_USER_ROLE:
      return {
        ...state,
        role: action.value,
      };
    case UPDATE_MEMBER_USER_FIRST_NAME_ERROR:
      return {
        ...state,
        errorFirstname: action.flag,
      };
    case UPDATE_MEMBER_USER_LAST_NAME_ERROR:
      return {
        ...state,
        errorLastname: action.flag,
      };
    case UPDATE_MEMBER_USER_EMAIL_ERROR:
      return {
        ...state,
        errorEmail: action.flag,
      };
    case UPDATE_MEMBER_USER_ROLE_ERROR:
      return {
        ...state,
        errorRole: action.flag,
      };
    default:
      return state;
  }
};
