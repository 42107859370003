import { FC, useEffect, useState } from 'react';
import { DistributionCentersHeader } from './DistributionCentersHeader';
import { DistributionCentersList } from './DistributionCentersList';
import { DistributionCentersModal } from './DistributionCentersModal';
import { Loader } from '../../../../utils';

export const DistributionCenters: FC<{ [value: string]: any }> = ({ // TODO Flesh out types
  getVendorDC,
  clearVendorDCList,
  updateDeleteVendorDC,
  handleDeleteClose,
  handleDeleteVendorDC,
  vendorDCModal,
  showDCModal,
  closeDCModal,
  onChangeDCName,
  onChangeStreet,
  onChangeCity,
  getStateLists,
  onChangeState,
  onChangeZip,
  onChangeCode,
  onAddDC,
  showEditDcModal,
  onEditDC,
  vendor: { vendorDCList, isDeleteUser, selectedDcID, vendorDcListSpinner },
  common: { statesList },
  modal: { showModal },
  paginationReducer,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState<string | null>(null);
  const [sortType, updateSortType] = useState('');

  const setSortKey = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  useEffect(() => {
    getVendorDC();

    return () => {
      clearVendorDCList();
    };
  }, []);

  return (
    <div className="vendor-assign-content">
      <DistributionCentersHeader
        searchText={searchText}
        setSearchText={setSearchText}
        showDCModal={showDCModal}
      />
      {vendorDcListSpinner ? (
        <div className="relative-wrapper">
          <div className="loader-wrapper list-spinner">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        </div>
      ) : (
        <DistributionCentersList
          vendorDCList={vendorDCList}
          updateDeleteVendorDC={updateDeleteVendorDC}
          isDeleteUser={isDeleteUser}
          selectedDcID={selectedDcID}
          handleDeleteClose={handleDeleteClose}
          handleDeleteVendorDC={handleDeleteVendorDC}
          editVendorDC={showEditDcModal}
          searchText={searchText}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortType={sortType}
          paginationReducer={paginationReducer}
        />
      )}
      <DistributionCentersModal
        showModal={showModal}
        closeModal={closeDCModal}
        vendorDCModal={vendorDCModal}
        onChangeDCName={onChangeDCName}
        onChangeStreet={onChangeStreet}
        onChangeCity={onChangeCity}
        getStateLists={getStateLists}
        statesList={statesList}
        onChangeState={onChangeState}
        onChangeZip={onChangeZip}
        onChangeCode={onChangeCode}
        onAddDC={onAddDC}
        onEditDC={onEditDC}
      />
    </div>
  );
};

export type DistributionCentersType = typeof DistributionCenters;
