import { FC } from 'react';
import '../../css/fonts.css';
import '../../css/all.min.css';
import '../../css/style.css';
import Grid from '@material-ui/core/Grid';
import imgLogo from '../../images/logo.png';
import { ResetPasswordForm } from './ResetPasswordForm';

export const ResetPasswordPage: FC = () => (
  <div>
    <section className="login-wrapper">
      <div className="login-bg">
        <div className="features-info">
          <Grid
            className="login-content"
            container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <img
              className="logo logo-forgot-password"
              src={imgLogo}
              alt="buyright"
              title="buyright"
            />
            <h3>Plan your spendings, Save more</h3>
            <p>
              View promotional products offering rebates Navigate to Vendors’
              purchasing sites
            </p>
          </Grid>
        </div>
      </div>

      <div className="login-form">
        <h2>Forgot Password</h2>
        <ResetPasswordForm />
      </div>
    </section>
  </div>
);

export type ResetPasswordPageType = typeof ResetPasswordPage;
export default ResetPasswordPage;
