import {
  CLEAR_VENDOR_USER_CREATE_MODAL,
  SHOW_VENDOR_USER_CREATE_SPINNER,
  HIDE_VENDOR_USER_CREATE_SPINNER,
  UPDATE_VENDOR_USER_IS_EDIT,
  UPDATE_VENDOR_USER_USERID,
  UPDATE_VENDOR_USER_CREATE_FNAME,
  UPDATE_VENDOR_USER_CREATE_LNAME,
  UPDATE_VENDOR_USER_CREATE_EMAIL,
  UPDATE_VENDOR_USER_CREATE_PHONE,
  UPDATE_VENDOR_USER_CREATE_ROLE,
  UPDATE_VENDOR_USER_CREATE_JOBTITLE,
  UPDATE_VENDOR_USER_CREATE_DC,
  UPDATE_VENDOR_USER_CREATE_FNAME_ERROR,
  UPDATE_VENDOR_USER_CREATE_LNAME_ERROR,
  UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR,
  UPDATE_VENDOR_USER_CREATE_PHONE_ERROR,
  UPDATE_VENDOR_USER_CREATE_ROLE_ERROR,
  UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
  UPDATE_VENDOR_USER_CREATE_DC_ERROR,
} from "../../constants";

const initialState = {
  spinner: false,
  isEdit: false,
  userId: null,
  firstName: "",
  lastName: "",
  email: "",
  jobTitle: "",
  phoneNo: "",
  selectedRole: "0",
  selectedDC: "0",
  errorFirstname: false,
  errorLastname: false,
  errorJobTitle: false,
  errorEmail: false,
  errorPhoneNo: false,
  errorRole: false,
  errorDC: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_USER_IS_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };
    case UPDATE_VENDOR_USER_USERID:
      return {
        ...state,
        userId: action.userId,
      };
    case UPDATE_VENDOR_USER_CREATE_FNAME:
      return {
        ...state,
        firstName: action.firstName,
      };
    case UPDATE_VENDOR_USER_CREATE_LNAME:
      return {
        ...state,
        lastName: action.lastName,
      };
    case UPDATE_VENDOR_USER_CREATE_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case UPDATE_VENDOR_USER_CREATE_JOBTITLE:
      return {
        ...state,
        jobTitle: action.jobTitle,
      };
    case UPDATE_VENDOR_USER_CREATE_PHONE:
      return {
        ...state,
        phoneNo: action.phoneNo,
      };
    case UPDATE_VENDOR_USER_CREATE_ROLE:
      return {
        ...state,
        selectedRole: action.selectedRole,
      };
    case UPDATE_VENDOR_USER_CREATE_DC:
      return {
        ...state,
        selectedDC: action.selectedDC,
      };
    case UPDATE_VENDOR_USER_CREATE_FNAME_ERROR:
      return {
        ...state,
        errorFirstname: action.errorFirstname,
      };
    case UPDATE_VENDOR_USER_CREATE_LNAME_ERROR:
      return {
        ...state,
        errorLastname: action.errorLastname,
      };
    case UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR:
      return {
        ...state,
        errorEmail: action.errorEmail,
      };
    case UPDATE_VENDOR_USER_CREATE_PHONE_ERROR:
      return {
        ...state,
        errorPhoneNo: action.errorPhoneNo,
      };
    case UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR:
      return {
        ...state,
        errorJobTitle: action.errorJobTitle,
      };
    case UPDATE_VENDOR_USER_CREATE_ROLE_ERROR:
      return {
        ...state,
        errorRole: action.errorRole,
      };
    case UPDATE_VENDOR_USER_CREATE_DC_ERROR:
      return {
        ...state,
        errorDC: action.errorDC,
      };
    case SHOW_VENDOR_USER_CREATE_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case HIDE_VENDOR_USER_CREATE_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case CLEAR_VENDOR_USER_CREATE_MODAL:
      return {
        ...state,
        spinner: false,
        isEdit: false,
        userId: null,
        firstName: "",
        lastName: "",
        email: "",
        jobTitle: "",
        phoneNo: "",
        selectedRole: "0",
        selectedDC: "0",
        errorFirstname: false,
        errorLastname: false,
        errorJobTitle: false,
        errorEmail: false,
        errorPhoneNo: false,
        errorRole: false,
        errorDC: false,
      };
    default:
      return state;
  }
};
