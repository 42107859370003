import { FC, useMemo } from 'react';
import { SearchNotFound } from '../../../components/SearchNotFound';
import MemberGridCard from './MemberGridCard';
import { Grid } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import { Loader } from '../../../utils';

interface MemberGridProps {
  membersList: any;
  statesList: any;
  state: any;
  send: any;
}

export const MemberGrid: FC<MemberGridProps> = ({
  membersList,
  statesList,
  state,
  send,
}) => {

  const showLoader = useMemo(() => {
    return [state.context.records.length === 0, state.hasTag('loading')].every(
      (value) => value,
    );
  }, [state.context.records.length, state.hasTag('loading')]);

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  const loading = state.hasTag('loading');

  if (showLoader) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  }

  if (membersList && membersList.length === 0) {
    return <SearchNotFound sectionType={'Members'} />;
  } else {
    return (
      <div>
        <Grid container spacing={4} className="members-content">
        {slicedRecords.length === 0 && loading ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '20vh',
            }}
          >
            <Loader size={30} />
          </div>
        ) : (
          slicedRecords?.map((member: any) => (
            <MemberGridCard
              key={member?.id}
              member={member}
              statesList={statesList}
              pageNumber = {state.context.pageNumber}
              pageSize= {state.context.pageSize}
            />
          ))
        )}
        </Grid>

        {slicedRecords?.length !== 0 && (
          <TablePagination
            component="div"
            count={state.context.totalRecords}
            page={state.context.pageNumber - 1}
            onPageChange={(_, newPage) => {
              send({
                type: "goToPage",
                pageNumber: newPage + 1,
              });
            }}
            rowsPerPage={state.context.pageSize}
            onRowsPerPageChange={(event) =>
              send({
                type: "updatePageSize",
                pageSize: parseInt(event.target.value),
              })
            }
            rowsPerPageOptions={[8, 16, 32, 40]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} of ${
                count !== -1 ? count.toLocaleString() : `more than ${to}`
              }`;
            }}
          />
        )}
      </div>
    );
  }
};
