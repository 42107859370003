import React from "react";
import moment from "moment";
import Button from "@material-ui/core/Button";
import {CustomModal} from "../../../../../components/CustomModal";
import {ExportMemberAdminRebatesModal} from "./ExportMemberAdminRebatesModal";

export const ExportMemberAdminRebates = ({
  handleMemberAdminExportQuarter,
  handleMemberAdminExportYear,
  onExportMemberAdminRebates,
  rebates: {
    rebatesModalSpinner
  },
  memberAdminRebatesExportModal,
  memberAdminRebates: {
    isMemberAdminExport,
    exportQuarter,
    exportYear,
    //memberProductList
  },
  memberProductList,
}) => {

  const closeModal = () => {
    memberAdminRebatesExportModal(false);
  };

  const quarterList = [
    {
      label: "Q1",
      value: "1",
    },
    {
      label: "Q2",
      value: "2",
    },
    {
      label: "Q3",
      value: "3",
    },
    {
      label: "Q4",
      value: "4",
    },
  ];

  const currentDate = new Date();
  const currentYear = moment().format("YYYY");
  const nextYear = moment(currentDate).add(1, "Y").format("YYYY");

  const yearList = [
    {
      label: currentYear,
      value: currentYear,
    },
    {
      label: nextYear,
      value: nextYear,
    },
  ];

  const child =
    <ExportMemberAdminRebatesModal
      handleMemberAdminExportQuarter={handleMemberAdminExportQuarter}
      handleMemberAdminExportYear={handleMemberAdminExportYear}
      exportQuarter={exportQuarter}
      exportYear={exportYear}
      quarterList={quarterList}
      yearList={yearList}
    />

  const isExportDisabled = memberProductList.length === 0

  return (
    <div>
      <Button
        onClick={() => memberAdminRebatesExportModal(true)}
        variant="contained"
        size="small"
        className={isExportDisabled ? "btn-add-blue button-disable" : "btn-add-blue"}
        disabled={isExportDisabled}
      >
        <span className="fas fa-file-export"></span>
        <span>Export</span>
      </Button>

      <CustomModal
        showModal={isMemberAdminExport}
        closeModal={closeModal}
        modalTitle={"Export Member Rebate Documents"}
        children={child}
        isCancelButton={true}
        isSubmitButton={false}
        isExportButton={true}
        isSubmitText={"Export"}
        onSubmit={onExportMemberAdminRebates}
        isLoading={rebatesModalSpinner}
      />
    </div>
  );
};
