import Grid from "@material-ui/core/Grid";
import HeaderImage from "../../../images/bg-header-2.png";

export const MemberTitle = () => {
    return (
        <Grid container className="details-header">
            <div className="details-header-img">
                <img src={HeaderImage} />
                <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    className="details-header-content"
                >
                    <Grid item xs={12} sm={6}>
                        <div className="details-header-left">
                            <h2 className="details-header-title">Members</h2>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </Grid>
    )
}
