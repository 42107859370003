import { DELETE_VENDOR_DC_API, VendorDeleteDCError } from '../../constants';

export const deleteVendorDC = async ({
    vendorID,
    selectedDcID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DELETE_VENDOR_DC_API}${vendorID}/distributioncenter/${selectedDcID}`, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            })
        });
        if (!response.ok) throw VendorDeleteDCError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw VendorDeleteDCError;
    }
};