import React from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import NotFoundImg from '../../../images/404.svg';

export const NoAccessForInvalidPassword = () => {
    return (
        <Grid
            container
            direction="column"
            justify="center"
            alignItems="center"
            className="page-notfound"
        >
            <Grid>
                <img src={NotFoundImg}></img>
            </Grid>
            <Grid className="page-not-found-home">
                Click <NavLink className="not-found-back" to={'/password-update'}>
                    here
                </NavLink> to update password.
            </Grid>
        </Grid>
    );
};
