import { FC, useEffect } from 'react';
import { Grid, GridSize } from '@material-ui/core';
import MemberRebateInfoTableRow from './MemberRebateInfoTableRow';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import { TablePagination } from '@material-ui/core';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../machines';
import serverApiV2Instance from '../../../services/serverApiV2Instance';
import { Loader } from '../../../utils';

const gridHeader: {
  label: string;
  value: string;
  sortKey: string;
  xs: GridSize;
  lg: GridSize;
}[] = [
    {
      label: 'Product Name',
      value: 'productName',
      sortKey: 'productName',
      xs: 12,
      lg: 5,
    },
    {
      label: 'Product #',
      value: 'productNumber',
      sortKey: 'productNumber',
      xs: 12,
      lg: 2,
    },
    {
      label: 'Quantity',
      value: 'orderSize',
      sortKey: 'orderSize',
      xs: 12,
      lg: 2,
    },
    {
      label: 'Amount ',
      value: 'amountEarned',
      sortKey: 'amountEarned',
      xs: 12,
      lg: 2,
    },
    {
      label: '',
      value: '',
      sortKey: '',
      xs: 12,
      lg: 1,
    },
  ];

export const MemberRebateInfoTable: FC<any> = ({
  updateMemberRebatesInfo,
  tableType,
  quarter,
  year,
  memberId,
  searchText,
}) => {
  useEffect(() => {
    return () => {
      updateMemberRebatesInfo();
    };
  }, []);

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: 'productName',
        sortOrder: 'asc',
      },
      pageSize: 10,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          quarter : quarter ? quarter : '',
          year: year ? year : '',
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
          searchString: searchText ?? '',
        });
        return serverApiV2Instance.get(
          `/members/${memberId}/earned-rebates?${query.toString()}`,
        );
      },
    },
  });

  const orderBy = state.context.additionalQueryStringValues.orderBy as string;
  const sortOrder = state.context.additionalQueryStringValues.sortOrder as | 'asc' | 'desc';
  const setOrderBy = (orderBy: string) => {
    if (state.context.additionalQueryStringValues.orderBy === orderBy) {
      return send({
        type: 'addQueryStringValue',
        key: 'sortOrder',
        value:
          state.context.additionalQueryStringValues.sortOrder === 'asc'
            ? 'desc'
            : 'asc',
      });
    }
    send({
      type: 'addQueryStringValue',
      key: 'orderBy',
      value: orderBy,
    });
  }

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  return (
    <div className="grid-tr-detail-box grid-tr-detail-box-close">
      <div className="static-table-header">
        {state.context.records.length >= 0 ? (
          <Grid container spacing={0} className="tr thead">
            {gridHeader.map((item) => {
              const { label, xs, lg, sortKey } = item;
              const isValidHeader = (label !== "" && (sortKey !== '' || sortKey !== undefined));
              return (
                <Grid className="th" item xs={xs} lg={lg}>
                  <div
                    className={
                      label === 'Amount Earned' ? 'info-table-header-right' : ''
                    }
                    onClick={() =>
                      sortKey && setOrderBy(sortKey)
                    }
                  >
                    <span
                      className="grid-header"
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader && (
                        sortKey === orderBy ? (
                          <img
                            className="sort-svg"
                            src={sortOrder === 'asc' ? sortAscIcon : sortDescIcon}
                          />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      )}
                    </span>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        ) : null }
      </div>
      <div
        className={
          tableType === 'Dashboard'
            ? 'dashboard-table-records'
            : 'overflow-table-records'
        }
      >
        {
          ((slicedRecords.length > 0) ?
            slicedRecords.map(
              (
                item: {
                  productName: string | null;
                  manufacturerName: string | null;
                  productNumber: string | null;
                  orderSize: number | null;
                  amountEarned: number | null;
                },
                key: number,
              ) => <MemberRebateInfoTableRow {...item} key={key} />,
            ) : (
              <SearchNotFound sectionType={'Member Rebates List'} />
            ))}
      </div>
      <TablePagination
        component="div"
        count={state.context.totalRecords}
        page={state.context.pageNumber - 1}
        onPageChange={(_, newPage) => {
          send({
            type: 'goToPage',
            pageNumber: newPage + 1,
          })
        }
        }
        rowsPerPage={state.context.pageSize}
        onRowsPerPageChange={(event) =>
          send({
            type: 'updatePageSize',
            pageSize: parseInt(event.target.value),
          })
        }
        rowsPerPageOptions={[10, 20, 30, 40]}
        labelDisplayedRows={({ from, to, count }) => {
          return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`
            }`;
        }}
      />
    </div>
  );
};

export default MemberRebateInfoTable;
