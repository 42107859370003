import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CLEAR_LOGIN,
  CLEAR_LOGIN_ERROR,
  UPDATE_LOGIN_SPINNER,
  UPDATE_LOGIN_EMAIL,
  UPDATE_LOGIN_PASSWORD,
  UPDATE_LOGIN_SHOW_PASSWORD,
  UPDATE_LOGIN_EMAIL_ERROR,
  UPDATE_LOGIN_PASSWORD_ERROR,
  UPDATE_LOGIN_REDIRECT,
  UPDATE_LOGIN_SERVER_ERROR,
  LoginError,
} from "../constants";
import {EMAIL_REGEXP} from "../utils/constants";
import {submitLogin} from "../api/submitLogin";

export const clearLogin = () => ({
  type: CLEAR_LOGIN,
});

export const onChangeEmail = (value) => async (dispatch) => {
  const emailRegExp = new RegExp(EMAIL_REGEXP);
  const isValidEmail = emailRegExp.test(value);

  dispatch({
    type: UPDATE_LOGIN_EMAIL,
    email: value,
  });
  dispatch({
    type: CLEAR_LOGIN_ERROR,
  });
  dispatch({
    type: UPDATE_LOGIN_EMAIL_ERROR,
    errorEmail: !isValidEmail ? true : false,
  });
};
export const onChangePassword = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_LOGIN_PASSWORD,
    password: value,
  });
  dispatch({
    type: CLEAR_LOGIN_ERROR,
  });
  dispatch({
    type: UPDATE_LOGIN_PASSWORD_ERROR,
    errorPassword: value == "" ? true : false,
  });
};
export const onChangeShowPassword = () => async (dispatch, getState) => {
  const {login} = getState();
  const {showPassword} = login;
  dispatch({
    type: UPDATE_LOGIN_SHOW_PASSWORD,
    showPassword: !showPassword,
  });
};
export const onLoginSubmit = () => async (dispatch, getState) => {
  const {login} = getState();
  const {email, password} = login;

  const emailRegExp = new RegExp(EMAIL_REGEXP);
  const isValidEmail = emailRegExp.test(email);

  const isvalidPassword = password && password.trim().length > 0 ? true : false;
  const isError = !isValidEmail || !isvalidPassword;

  if (!isValidEmail) {
    dispatch({
      type: UPDATE_LOGIN_EMAIL_ERROR,
      errorEmail: true,
    });
  }
  if (!isvalidPassword) {
    dispatch({
      type: UPDATE_LOGIN_PASSWORD_ERROR,
      errorPassword: true,
    });
  }

  try {
    dispatch({
      type: UPDATE_LOGIN_SPINNER,
      spinner: isError ? false : true,
    });

    if (!isError) {
      const response = await submitLogin({
        email,
        password,
      });
      const {success, data, message} = response;
      if (success && data.user) {
        const user = {
          ...data.user,
          isNotAssignedToMember:
            !data?.user?.memberusers?.length &&
            !data?.user?.isBRAdmin &&
            !data?.user?.vendorusers?.[0]?.id
              ? true
              : false,
        };

        localStorage.setItem("user", JSON.stringify(user));
        dispatch({
          type: "SET_USER",
          payload: user,
        });

        dispatch({
          type: UPDATE_LOGIN_REDIRECT,
          redirect: true,
          payload: user,
        });
        
      } else if (!success) {
        dispatch({
          type: UPDATE_LOGIN_SERVER_ERROR,
          errorMessage: message,
        });
      }

      dispatch({
        type: UPDATE_LOGIN_SPINNER,
        spinner: false,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_LOGIN_SPINNER,
      spinner: false,
    });
    if (error) {
      toast.error(LoginError);
    }
  }
};
