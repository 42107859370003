import { FC, useState } from 'react';
import { MemberTabPanel } from '.';
import { MemberUserTab } from '../tabs/users/memberUserTab';
import { MemberVendorTab } from '../tabs/vendor/MemberVendorTab';
import { MemberLocationsTab } from '../tabs/locations/memberLocationsTab';
import { useStyles } from '../../../../utils';
import '../members-details.scss';
import { Tab, Tabs } from '@material-ui/core';
import { LoginAttemptTab } from '../tabs/loginAttempts/loginAttemptTabs';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const MembersTab: FC<any> = (props) => {
  const { navigateTab } = props;
  const classes = useStyles();
  const [value, setValue] = useState(navigateTab || 0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);
  };

  return (
    <div className={classes.membersRoot}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Users" {...a11yProps(0)} />
        <Tab label="Vendors" {...a11yProps(1)} />
        <Tab label="Locations" {...a11yProps(2)} />
        <Tab label="Analytics" {...a11yProps(3)} />
        {/* To Do for V1.2.X */}
        {/* { AuthorizationService.isAuthorizedHTML('memberUsageTab') && (
          <Tab label="Usage" {...a11yProps(3)} />
        )} */}
      </Tabs>
      <MemberTabPanel value={value} index={0}>
        <MemberUserTab {...props} />
      </MemberTabPanel>
      <MemberTabPanel value={value} index={1}>
        <MemberVendorTab {...props} />
      </MemberTabPanel>
      <MemberTabPanel value={value} index={2}>
        <MemberLocationsTab {...props} />
      </MemberTabPanel>
      <MemberTabPanel value={value} index={3}>
        <LoginAttemptTab {...props} />
      </MemberTabPanel>
      {/* To Do for V1.2.X */}
      {/* {AuthorizationService.isAuthorizedHTML('memberUsageTab') && (
        <MemberTabPanel value={value} index={3}>
          <Usage {...props} />
        </MemberTabPanel>
      )} */}
    </div>
  );
};
