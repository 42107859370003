import moment from 'moment';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { Loader, WarningPopup } from '../../../utils';
import '../community.scss';
import { CommunityQuestionsBottomContent } from './CommunityQuestionsBottomContent';
import profilePlaceholder from '../../../images/profile-placeholder.jpg';
import { FC } from 'react';
import { Button, Tooltip } from '@material-ui/core';
import { StringAvatar } from '../../../utils/AvatarInitial';
import Avatar from '@mui/material/Avatar';

export const CommunityQuestions: FC<any> = ({
  updateQuestionDetail,
  updateActiveDiscussion,
  filteredData,
  handleDiscussionLike,
  communityDiscussionActions: {
    isDiscussionLiked,
    discussionId,
    spinner,
    isArchive,
  },
  userId,
  isBRAdmin,
  setAutoFocus,
  handleEditDiscussion,
  handleReport,
  updateArchiveDiscussion,
  handleArchiveDiscussion,
  handleArchiveModal,
  discussionFormSpinner,
  isViewAsMember
}) => {
  const handleHeaderClick = (item: any, autoFocus: any) => {
    setAutoFocus(autoFocus);
    updateQuestionDetail(true);
    updateActiveDiscussion(item.id);
  };

  return (
    <>
      {filteredData && filteredData.length > 0 ? (
        filteredData.map((item: any, index: any) => {
          const {
            id,
            likeCount,
            title,
            createdBy,
            createdAt,
            lockedAt,
            avatar,
            userDiscussionLikeId,
          } = item;
          const lastUpdated = moment(createdAt).fromNow();

          const bottomComponentProps = {
            userId,
            isBRAdmin,
            handleEditDiscussion,
            handleReport,
            updateArchiveDiscussion,
            item,
            handleHeaderClick,
          };

          return (
            <div className="community-questions" key={index}>
              <div className="community-questions-left">
                <div className="community-questions-user">
                  {avatar && avatar.length > 0 ? (
                    <img src={avatar} alt="user" />
                  ) : createdBy ? (
                    <Avatar {...StringAvatar(createdBy)} />
                  ) : (
                    <img src={profilePlaceholder} alt="user" />
                  )}
                </div>
                <div className="community-questions-likes">
                  <Tooltip
                    title={userDiscussionLikeId ? 'Unlike' : 'Like'}
                    placement="left"
                  >
                    <Button
                      variant="contained"
                      size="small"
                      className={
                        userDiscussionLikeId
                          ? 'btn-community-trash btn-like comment-liked'
                          : 'btn-community-trash btn-like'
                      }
                      onClick={() =>
                        handleDiscussionLike(
                          discussionId === id ? !isDiscussionLiked : true,
                          item,
                        )
                      }
                      disabled={(spinner && id === discussionId ) || lockedAt || isViewAsMember}
                    >
                      {spinner && id === discussionId ? (
                        <div className="section-spinner">
                          <Loader size={10} />
                        </div>
                      ) : (
                        <i className="far fa-heart" />
                      )}
                    </Button>
                  </Tooltip>
                  <div className="community-questions-likes-text">
                    {likeCount}
                  </div>
                </div>
              </div>

              <div className="community-questions-right">
                <h2
                  className="community-questions-title"
                  onClick={() => {
                    if(!isViewAsMember){
                     return handleHeaderClick(item, false)
                    }
                  }}
                >
                  {title}
                </h2>
                <div className="community-questions-created-info">
                  <span>
                    By <b>{createdBy}</b>
                  </span>
                  <span>
                    <b>{lastUpdated}</b>
                  </span>
                </div>
                <CommunityQuestionsBottomContent {...bottomComponentProps} isViewAsMember={isViewAsMember} />
              </div>
            </div>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Discussion'} />
      )}

      <WarningPopup
        open={isArchive}
        type={'archive'}
        name={'discussion'}
        handleProceed={handleArchiveDiscussion}
        handleClose={() => handleArchiveModal(false)}
        spinner={discussionFormSpinner}
      />
    </>
  );
};

export default CommunityQuestions;
