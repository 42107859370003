import {
  GET_REBATE_MEMBER_PRODUCT_LIST,
  GET_REBATE_MEMBER_PRODUCT_LIST_ERROR,
} from '../../../constants';

export const getDashboardActiveEarningDetails = async ({
  quarter,
  year,
  member,
}: {
  quarter: any;
  year: any;
  member: any;
}) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '');
    const response = await fetch(
      `${GET_REBATE_MEMBER_PRODUCT_LIST}/member/${member}?quarter=${quarter}&year=${year}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw response;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw GET_REBATE_MEMBER_PRODUCT_LIST_ERROR;
  }
};
