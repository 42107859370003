import { Grid, TextField, Tooltip } from '@material-ui/core';
import { FC, useRef, useState } from 'react';
import { centsToFormattedDollars } from '../../../utils';
import { CustomModal } from "../../../components/CustomModal/index";

export const MemberRebateInfoTableRow: FC<{
  productName: string | null;
  manufacturerName: string | null;
  productNumber: string | null;
  orderSize: number | null;
  amountEarned: number | null;
}> = ({
  productName,
  manufacturerName,
  productNumber,
  orderSize,
  amountEarned,
}) => {
    const anchorEl = useRef<HTMLDivElement | null>(null);
    const [open, setOpen] = useState(false);

    return (
      <Grid container spacing={0} className="tr">
        <Grid
          className="td grid-tr-detail-box-td grid-tr-detail-box-amount"
          item
          lg={5}
        >
          {productName ? productName : '-'}
        </Grid>
        <Grid
          className="td grid-tr-detail-box-td grid-tr-detail-box-date"
          item
          lg={2}
        >
          {productNumber ? productNumber : '-'}
        </Grid>
        <Grid
          className="td grid-tr-detail-box-td br-align-centre grid-tr-detail-box-date"
          item
          lg={2}
        >
          {orderSize ? orderSize : '-'}
        </Grid>
        <Grid
          className="td grid-tr-detail-box-td br-align-left grid-tr-detail-box-pay-mode"
          item
          lg={2}
        >
          {Number(amountEarned) ? centsToFormattedDollars(amountEarned) : '-'}
        </Grid>
        <Grid item lg={1}>
          <CustomModal
            showModal={open}
            closeModal={() => { setOpen(false); }}
            modalTitle={`Additional production information - ${productName}`}
            isCancelButton={false}
            isSubmitButton={false}
          >
            <div className="br-form-element">
              <TextField
                style={{ marginBottom: '1em' }}
                aria-readonly
                label="Manufacturer Name"
                variant="outlined"
                className="text-box"
                value={manufacturerName}
                InputProps={{
                  readOnly: true,
                }}
              />
            </div>
          </CustomModal>
          <Tooltip
            style={{ margin: '1em' }}
            onClick={() => { setOpen(true); }}
            title="Additional information product"
          >
            <div ref={anchorEl} className="info-rebates-notes">
              <i className="fas fa-info" />
            </div>
          </Tooltip>
        </Grid>
      </Grid>
    );
  };

export default MemberRebateInfoTableRow;
