import {FC} from 'react';
import '../../scss/styles.scss';
import NotFoundIcon from '../../images/folder-not-found.svg';

const ItemsNotFound: FC<{ userType: any }> = ({ userType }) => {
  return (
    <div className="section-not-found">
      <div className="section-not-found-img">
        <img src={NotFoundIcon} />
      </div>
      <h4>No {userType ? userType : 'Members'} Found</h4>
      <p>
        We can’t find any {userType ? userType : 'members'} yet,
        create your first {userType ? userType : 'members'} using
        the button above
      </p>
    </div>
  );
}

export default ItemsNotFound;
