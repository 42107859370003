import {
    SHOW_COMMENT_ACTION_SPINNER,
    HIDE_COMMENT_ACTION_SPINNER,
    UPDATE_ACTIVE_DISCUSSION_COMMENTS,
    UPDATE_DISCUSSION_LOAD_MORE,
    UPDATE_COMMENT_LIKE_STATUS,
    UPDATE_COMMENT_LIKE_ID,
    UPDATE_COMMENT_DELETE_FLAG,
    UPDATE_DISCUSSION_COMMENT_ID,
    UPDATE_DISCUSSION_COMMENT,
    UPDATE_DISCUSSION_COMMENT_EDIT_FLAG,
    UPDATE_COMMENT_ID,
    UPDATE_COMMENT_ERROR,
    GET_SINGLE_DISCUSSIONS_ERROR,
    GET_DISCUSSION_COMMENTS_ERROR,
    AddCommentLikeError,
    AddCommentDisLikeError,
    getDiscussionCommentsError,
    UpdateCommentsError,
    getSingleDiscussionError,
    FetchError
} from "../../../constants";

import {
    addCommentLike,
    getDiscussionComments,
    removeCommentLike,
    deleteComment,
    getSingleDiscussion,
    editComment
} from '../../../api/community';

import {isValidText} from '../channel/channel';
import {updateActiveDiscussionData} from '../discussion/discussion';
import {showDiscussionCommentSpinner, hideDiscussionCommentSpinner} from './comments';
import {showDiscussionDetailSpinner, hideDiscussionDetailSpinner} from '../discussion/discussion';
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showCommentActionSpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_COMMENT_ACTION_SPINNER
    });
};

export const hideCommentActionSpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_COMMENT_ACTION_SPINNER
    });
};

export const updateDeleteCommentFlag = (isDeleteComment) => async (dispatch) => {
    dispatch({
        type: UPDATE_COMMENT_DELETE_FLAG,
        isDeleteComment
    });
};

export const handleCommentLike = (selectedComment) => async (dispatch, getState) => {
    const {id: commentId} = selectedComment;
    const {user, communityDiscussion} = getState();
    const {id: userId} = user;
    const {activeDiscussion, pageNumber} = communityDiscussion;
    const {id: discussionId} = activeDiscussion;
    dispatch(showCommentActionSpinner());
    dispatch({
        type: UPDATE_COMMENT_ID,
        commentId
    });
    try {
        const res = await addCommentLike({
            discussionId,
            commentId,
            userId
        });
        const {success, data} = res;
        const {insertId} = data;
        if (success) {
            dispatch({
                type: UPDATE_COMMENT_LIKE_STATUS,
                isCommentLiked: true
            });
            dispatch({
                type: UPDATE_COMMENT_LIKE_ID,
                commentLikeId: insertId
            });
            const discussionRes = await getDiscussionComments({
                discussionId,
                pageNumber
            });
            const {data} = discussionRes;
            const isLoadMore = data && data.length > 5 ? true : false;
            dispatch({
                type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                activeDiscussionComments: data
            });
            dispatch({
                type: UPDATE_DISCUSSION_LOAD_MORE,
                isLoadMore
            });
        }
        dispatch(hideCommentActionSpinner());
    } catch (error) {
        dispatch(hideCommentActionSpinner());
        if (error === ADD_COMMENT_LIKE_ERROR) {
            toast.error(AddCommentLikeError)
        } else if (error = GET_DISCUSSION_COMMENTS_ERROR) {
            toast.error(getDiscussionCommentsError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const handleCommentDisLike = (selectedComment) => async (dispatch, getState) => {
    const {id: commentId, discussionCommentLikeId, userDiscussionCommentLikeId} = selectedComment;
    const {communityDiscussion} = getState();
    const {activeDiscussion, pageNumber} = communityDiscussion;
    const {id: discussionId} = activeDiscussion;
    dispatch(showCommentActionSpinner());
    dispatch({
        type: UPDATE_COMMENT_ID,
        commentId
    });
    try {
        const res = await removeCommentLike({
            discussionId,
            commentId,
            commentLikeId: userDiscussionCommentLikeId
        });
        const {success} = res;
        if (success) {
            dispatch({
                type: UPDATE_COMMENT_LIKE_STATUS,
                isCommentLiked: false
            });
            dispatch({
                type: UPDATE_COMMENT_LIKE_ID,
                commentLikeId: null
            });
            const discussionRes = await getDiscussionComments({
                discussionId,
                pageNumber
            });
            const {data} = discussionRes;
            const isLoadMore = data && data.length > 5 ? true : false;
            dispatch({
                type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                activeDiscussionComments: data
            });
            dispatch({
                type: UPDATE_DISCUSSION_LOAD_MORE,
                isLoadMore
            });
        }
        dispatch(hideCommentActionSpinner());
    } catch (error) {
        dispatch(hideCommentActionSpinner());
        if (error === ADD_COMMENT_DISLIKE_ERROR) {
            toast.error(AddCommentDisLikeError)
        } else if (error = GET_DISCUSSION_COMMENTS_ERROR) {
            toast.error(getDiscussionCommentsError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const deleteDiscussionComment = (selectedComment) => async (dispatch, getState) => {
    const {communityDiscussion, communityChannel} = getState();
    const {pageNumber} = communityDiscussion;
    const {activeChannelID} = communityChannel;
    const {discussionid : discussionId, id : commentId } = selectedComment;
    dispatch(showCommentActionSpinner());
    try {
        const res = await deleteComment({
            discussionId,
            commentId
        });
        const {success, message: commentDeleteSuccess} = res;
        if (success) {
            const res = await getSingleDiscussion({
                discussionId,
                activeChannelID
            });
            const {data, success: singleDiscussionStatus} = res;
            dispatch(updateActiveDiscussionData(data));
            if (singleDiscussionStatus) {
                const discussionRes = await getDiscussionComments({
                    discussionId,
                    pageNumber
                });
                const {data} = discussionRes;
                const isLoadMore = data && data.length > 5 ? true : false;
                dispatch({
                    type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                    activeDiscussionComments: data
                });
                dispatch({
                    type: UPDATE_DISCUSSION_LOAD_MORE,
                    isLoadMore
                });

            }
        }
        dispatch(updateDeleteCommentFlag(false))
        toast.success(commentDeleteSuccess)
        dispatch(hideCommentActionSpinner());
    } catch (error) {
        dispatch(hideCommentActionSpinner());
    }
}

export const handleCommentEdit = (selectedComment) => async (dispatch, getState) => {
    const {id, content} = selectedComment;
    dispatch({
        type: UPDATE_DISCUSSION_COMMENT_EDIT_FLAG,
        isCommentEdit: true
    });
    dispatch({
        type: UPDATE_DISCUSSION_COMMENT_ID,
        discussionCommentId: id
    });
    dispatch({
        type: UPDATE_DISCUSSION_COMMENT,
        discussionComment: content
    });
}

export const onUpdateCommentContent = () => async (dispatch, getState) => {
    const {communityChannel, communityDiscussion, communityComments} = getState();
    const {activeChannelID} = communityChannel;
    const {activeDiscussion, pageNumber} = communityDiscussion;
    const {discussionCommentId, discussionComment} = communityComments;
    const {id: discussionId} = activeDiscussion;
    const isValidDiscussionComment = isValidText(discussionComment);
    if (!isValidDiscussionComment) {
        dispatch({
            type: UPDATE_DISCUSSION_COMMENT_ERROR,
            discussionCommentError: true,
        });
    }
    try {
        if (isValidDiscussionComment) {
            dispatch(showDiscussionCommentSpinner());
            const response = await editComment({
                discussionId,
                discussionCommentId,
                discussionComment
            });
            const {success, message} = response;
            dispatch({
                type: UPDATE_DISCUSSION_COMMENT_EDIT_FLAG,
                isCommentEdit: null
            });
            dispatch({
                type: UPDATE_DISCUSSION_COMMENT_ID,
                discussionCommentId: null
            });
            dispatch({
                type: UPDATE_DISCUSSION_COMMENT,
                discussionComment: ''
            });
            dispatch(showDiscussionDetailSpinner());
            if (success) {
                const res = await getSingleDiscussion({
                    discussionId,
                    activeChannelID,
                });
                const {data, success: singleDiscussionStatus} = res;
                dispatch(updateActiveDiscussionData(data));
                if (singleDiscussionStatus) {
                    const res = await getDiscussionComments({
                        discussionId,
                        pageNumber,
                    });
                    const {data, totalCount} = res;
                    const isLoadMore = totalCount && totalCount > 5 ? true : false;
                    dispatch({
                        type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                        activeDiscussionComments: data,
                    });
                    dispatch({
                        type: UPDATE_DISCUSSION_LOAD_MORE,
                        isLoadMore,
                    });
                }
            } else {
                dispatch({
                    type: UPDATE_DISCUSSION_COMMENT_INPUT_FORM,
                    isCommentFormUpdated: false,
                });
                toast.success(message);
            }
        }
        dispatch(hideDiscussionCommentSpinner());
        dispatch(hideDiscussionDetailSpinner());
    } catch (error) {
        dispatch(hideDiscussionCommentSpinner());
        dispatch(hideDiscussionDetailSpinner());
        if (error === UPDATE_COMMENT_ERROR) {
            toast.error(UpdateCommentsError);
        } else if (error === GET_SINGLE_DISCUSSIONS_ERROR) {
            toast.error(getSingleDiscussionError);
        } else if (error === GET_DISCUSSION_COMMENTS_ERROR) {
            toast.error(getDiscussionCommentsError);
        } else {
            toast.error(FetchError);
        }
    }
}