import { Button, TextField } from '@material-ui/core';
import { FC } from 'react';
import { COMMENT_MSG } from '../../../constants';
import profileplaceholder from '../../../images/profile-placeholder.jpg';
import { StringAvatar } from '../../../utils/AvatarInitial';
import Avatar from '@mui/material/Avatar';

export const CommunityCommentsAdd: FC<any> = ({
  handleNewComment,
  onCommentCancel,
  onAddNewComment,
  user: { avatar, username },
  communityComments: {
    discussionCommentError,
    discussionComment,
    isCommentFormUpdated,
    spinner,
    isCommentEdit,
  },
  isAutoFocus,
  lockedAt,
  onUpdateCommentContent,
}) => {
  const disableFlag =
    !isCommentFormUpdated || discussionCommentError || spinner;

  return (
    <div className="community-comments-add">
      <div className="community-comments-add-left">
        <div className="community-comments-add-user">
          {avatar && avatar.length > 0 ? (
            <img src={avatar} alt="user" />
          ) : username ? (
            <Avatar {...StringAvatar(username)} />
          ) : (
            <img src={profileplaceholder} alt="user" />
          )}
        </div>
      </div>

      <div className="community-comments-add-right">
        <TextField
          label="Want to leave a comment!"
          variant="outlined"
          inputProps={{ maxLength: 2000 }}
          className="text-box community-comments-add-textbox"
          multiline
          autoFocus={isAutoFocus || isCommentEdit}
          minRows={8}
          maxRows={10}
          required
          disabled={lockedAt !== null ? true : false}
          defaultValue={lockedAt !== null ? COMMENT_MSG : ''}
          value={discussionComment}
          onChange={(e) => handleNewComment(e.target.value)}
          error={discussionCommentError}
          id={discussionCommentError ? 'standard-error' : 'outlined-basic'}
          helperText={
            discussionCommentError ? 'Please enter valid comment' : ''
          }
        />
        <div className="upload-img upload-img-size">
          {/* TODO is something supposed to go here? */}
        </div>

        {!lockedAt && (
          <div className="Community-add-buttons">
            <Button className="cancel-button" onClick={onCommentCancel}>
              Cancel
            </Button>
            <Button
              onClick={isCommentEdit ? onUpdateCommentContent : onAddNewComment}
              className={
                disableFlag
                  ? 'submit-comment-button button-disable'
                  : 'submit-comment-button'
              }
              disabled={disableFlag}
            >
              <span className="button-spinner-wrapper">Submit</span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default CommunityCommentsAdd;
