import {
    UPDATE_REPORT_FLAG,
    UPDATE_ACTIVE_DISCUSSION,
    UPDATE_REPORT_REASON,
    UPDATE_REPORT_REASON_LIST,
    UPDATE_DISCUSSION_REPORTED_FLAG,
    UPDATE_ALL_DISCUSSIONS,
    GET_REPROT_REASON_ERROR,
    REPORT_DISCUSSION_ERROR,
    UPDATE_ALL_DISCUSSIONS_ERROR,
    ReportReasonListError,
    ReportDiscussionError,
    FetchError
} from "../../../constants";

import {
    getReportReasons,
    reportDiscussion,
    getAllDiscussions
} from "../../../api/community";

import {showDiscussionModalSpinner, hideDiscussionModalSpinner} from './discussionModals';
import {showCommentModalSpinner, hideCommentModalSpinner} from '../comments/commentReport'

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export const isReported = (reportedflag) => async (dispatch) => {
    dispatch({
        type: UPDATE_DISCUSSION_REPORTED_FLAG,
        isAlreadyReported: reportedflag
    });
};


export const handleReport = (isReport, selectedDiscussion) => async (dispatch) => {
    const {flagreasonid} = selectedDiscussion || {};
    if (flagreasonid !== null) {
        dispatch({
            type: UPDATE_REPORT_REASON,
            reportReasonId: flagreasonid
        });
        dispatch(isReported(true));
    }
    dispatch({
        type: UPDATE_REPORT_FLAG,
        isReport
    });
    dispatch({
        type: UPDATE_ACTIVE_DISCUSSION,
        activeDiscussion: selectedDiscussion
    });
};

export const handleReportReason = (reportReasonId) => async (dispatch) => {
    if (reportReasonId !== null) {
        dispatch({
            type: UPDATE_REPORT_REASON,
            reportReasonId: parseInt(reportReasonId)
        });
    } else {
        dispatch({
            type: UPDATE_REPORT_REASON,
            reportReasonId: null
        });
    }

};

export const getReportReasonList = () => async (dispatch) => {
    dispatch(showDiscussionModalSpinner());
    dispatch(showCommentModalSpinner());
    try {
        const response = await getReportReasons();
        const {data} = response;
        dispatch({
            type: UPDATE_REPORT_REASON_LIST,
            reportReasonList: data
        });
        dispatch(hideDiscussionModalSpinner());
        dispatch(hideCommentModalSpinner());
    } catch (error) {
        dispatch(hideDiscussionModalSpinner());
        dispatch(hideCommentModalSpinner());
        if (error === GET_REPROT_REASON_ERROR) {
            toast.error(ReportReasonListError)
        } else {
            toast.error(FetchError)
        }
    }
};

export const handleReportDiscussion = () => async (dispatch, getState) => {
    const {user, communityDiscussion, communityChannel} = getState();
    const {id: userId} = user;
    const {activeDiscussion, reportReasonId} = communityDiscussion;
    const {id: discussionId} = activeDiscussion;
    const {activeChannelID} = communityChannel;
    dispatch(showDiscussionModalSpinner());
    try {
        const response = await reportDiscussion({
            discussionId,
            userId,
            reasonId: reportReasonId
        });
        const {success, message} = response;
        if (success) {
            const res = await getAllDiscussions({
                activeChannelID
            });
            const {data} = res;
            dispatch({
                type: UPDATE_ALL_DISCUSSIONS,
                discussionList: data
            })
            toast.success(message)
        }
        dispatch(hideDiscussionModalSpinner());
        dispatch(handleReport(false, null));
        dispatch(isReported(false));
    } catch (error) {
        dispatch(hideDiscussionModalSpinner());
        if (error === REPORT_DISCUSSION_ERROR) {
            toast.error(ReportDiscussionError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        };
    }
}