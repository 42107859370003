import { FC, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Loader } from "../../../../../../../utils";

export const MemberDropdown: FC<{
    memberAdminRebates: any;
    updateMemberAdminRebatesSelectedMember: (item: any) => void;
    setMemberId: (memberId: number | string) => void;
}> = ({
    memberAdminRebates: {
        memberOptionSpinner,
        memberOptionList,
        selectedMember
    },
    updateMemberAdminRebatesSelectedMember,
    setMemberId,
}) => {
        const [open, setOpen] = useState(false);
        const anchorRef = useRef<any>(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: any) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            setOpen(false);
        };


        const handleListKeyDown = (event: any) => {
            if (event.key === "Tab") {
                event.preventDefault();
                setOpen(false);
            }
        }

        const handleSelected = (item: any, event: any) => {
            updateMemberAdminRebatesSelectedMember(item);
            setMemberId(item?.id);
            handleClose(event);
        };

        return (

            <div className="br-dropdown-menu">
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : ""}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    disabled={memberOptionSpinner || memberOptionList.length === 0}
                >
                    <span className="drop-menu-button-text">
                        {selectedMember ? selectedMember.name : 'Select Member'}
                    </span>

                    {memberOptionSpinner ? (
                        <div className="category-spinner">
                            <Loader size={15} />
                        </div>
                    ) : (
                        <i className="fas fa-chevron-down"></i>
                    )}
                </Button>
                <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={""}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                    className="member-dropdown-menu-list"
                                >
                                    {memberOptionList &&
                                        memberOptionList.map((item: any) => {
                                            const { id, name } = item;
                                            return (
                                                <MenuItem
                                                    onClick={(event) => handleSelected(item, event)}
                                                    selected={item === selectedMember}
                                                >
                                                    <>{name}</>
                                                </MenuItem>
                                            );
                                        })}
                                </MenuList>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>
            </div>
        );
    };
