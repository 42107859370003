import serviceInterceptor from '../../../services/ServiceInterceptor';

export const updateEarnedRebatesRecord = async ({
  earnedRebatesRecordId,
  foodbuyItemLevelSpend,
  foodbuyAllowancesSol,
  buyRightSol,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('foodbuyItemLevelSpend', foodbuyItemLevelSpend);
    bodyFormData.append('foodbuyAllowancesSol', foodbuyAllowancesSol);
    bodyFormData.append('buyRightSol', buyRightSol);

    const response = await serviceInterceptor.patch(
      `/earned-rebates-records/${earnedRebatesRecordId}`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw response;
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
