import { assign } from '@xstate/immer';
import { createMachine } from 'xstate';
import { RebateReceiptItem } from '../machineTypes';

const rebateReceiptCheckTypeItemMachine = createMachine(
  {
    id: 'rebateReceiptItem',
    schema: {
      context: {} as RebateReceiptItem,
      events: {} as {
        type: 'toggleHandDeliver';
      },
    },
    tsTypes:
      {} as import('./rebateReceiptCheckTypeItemMachine.typegen').Typegen0,
    context: {
      amount: 0,
      canHandDeliver: false,
      handDeliver: false,
      type: 'check',
    },
    on: {
      toggleHandDeliver: {
        actions: 'assignToggledHandDeliver',
      },
    },
  },
  {
    actions: {
      assignToggledHandDeliver: assign((context) => {
        if (context.canHandDeliver && !context.handDeliver) {
          return context.handDeliver = true;
        }
        context.handDeliver = false;
      }),
    },
  },
);

export type RebateReceiptCheckTypeItemMachine =
  typeof rebateReceiptCheckTypeItemMachine;
export default rebateReceiptCheckTypeItemMachine;
