import SelectBox from '../../../../components/SelectBox';
import InputWithFloatingLabel from '../../../../components/InputWithFloatingLabel';
import { FC } from 'react';
import {
  Button,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

export const AddAdsContent: FC<any> = ({
  handleChangeAdUrl,
  handleChangeAdType,
  handleChangeAdProductName,
  handleChangeAdProductBrand,
  handleChangeAdSpecial,
  handleChangeAdDescription,
  handleChangeAdStatus,
  handleChangeAdRating,
  handleChangeAdImage,
  adsCreate: {
    loading,
    isEdit,
    adUrl,
    adType,
    productName,
    productBrand,
    adSpecial,
    description,
    adStatus,
    adRating,
    adImagePath,
    errorAdUrl,
    errorAdType,
    errorProductName,
    errorBrandName,
    errorSpecial,
    errorDesc,
    errorAdRating,
  },
  manageAdsList: { adsTypeList },
  products: { productBrands, productList, ratingList },
}) => {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} lg={4}>
        <div className="form-element-container">
          <InputWithFloatingLabel
            labelName="Ad URL"
            inputValue={adUrl}
            handleOnChange={handleChangeAdUrl}
            errorStatus={errorAdUrl}
            errorMessage={'Please enter ad url'}
            required
            isLoading={loading}
          />
        </div>
        <div className="form-element-container">
          <SelectBox
            labelName="Product Brand"
            inputValue={productBrand}
            handleSelectChange={handleChangeAdProductBrand}
            optionList={productBrands}
            errorState={errorBrandName}
            errorMessage={'Select brand'}
            isLoading={loading}
            objFormat={{ key: 'brandId', value: 'brandName' }}
          />
        </div>
        <div className="form-element-container">
          <InputWithFloatingLabel
            labelName="Special"
            inputValue={adSpecial}
            handleOnChange={handleChangeAdSpecial}
            errorStatus={errorSpecial}
            errorMessage="Please enter special"
            required
            isLoading={loading}
          />
        </div>
        <TextField
          label="Description"
          variant="outlined"
          className="text-box"
          multiline
          minRows={3}
          maxRows={4}
          value={description}
          onChange={(e) => handleChangeAdDescription(e.target.value)}
          error={errorDesc}
          id={errorDesc ? 'standard-error' : 'outlined-helperText'}
          helperText={errorDesc ? 'Enter description' : ''}
        />
      </Grid>
      <Grid item xs={12} lg={4}>
        <div className="form-element-container">
          <SelectBox
            labelName="Ad Type"
            inputValue={adType}
            handleSelectChange={handleChangeAdType}
            optionList={adsTypeList}
            errorState={errorAdType}
            errorMessage="Select Ad Type"
            isLoading={loading}
          />
        </div>
        <div className="form-element-container">
          <SelectBox
            labelName="Product Name"
            inputValue={productName}
            handleSelectChange={handleChangeAdProductName}
            optionList={productList}
            errorState={errorProductName}
            errorMessage="Select product"
            isLoading={loading}
            objFormat={{ key: 'productId', value: 'productName' }}
          />
        </div>
        <div className="form-element-container">
          <SelectBox
            labelName="Ranking"
            inputValue={adRating}
            handleSelectChange={handleChangeAdRating}
            optionList={ratingList}
            errorState={errorAdRating}
            errorMessage="Select rating"
            isLoading={loading}
          />
        </div>
        {isEdit && (
          <div className="form-element-container">
            <FormLabel component="legend">Status</FormLabel>
            <RadioGroup
              className="radio-group-element"
              aria-label="gender"
              name="gender1"
              value={adStatus}
              onChange={(e) => handleChangeAdStatus(e.target.value)}
            >
              <FormControlLabel
                value="female"
                control={<Radio />}
                label="Active"
              />
              <FormControlLabel
                value="male"
                control={<Radio />}
                label="Inactive"
              />
            </RadioGroup>
          </div>
        )}
      </Grid>
      <Grid item xs={12} lg={4}>
        <div className="image-add-field">
          {adImagePath && <img src={adImagePath} />}
        </div>
        <div className="upload-img">
          <Button className="img-filed" component="label">
            <i className="far fa-image image-field-icon"></i>
            {adImagePath ? 'Change Image' : 'Upload Image'}
            <input
              type="file"
              onChange={(e) => {
                handleChangeAdImage(e.target?.files?.[0]);
              }}
              hidden
              disabled={loading}
            />
          </Button>
        </div>
      </Grid>
    </Grid>
  );
};

export default AddAdsContent;
