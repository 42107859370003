import React, { useState } from "react";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import InputAdornment from "@mui/material/InputAdornment";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addPercentage } from "../../../actions/savingPercentage";
import { percentage } from '../../../utils/percentage';
import CustomModal from '../../../components/CustomModal/CustomModal';
type Props = {};

const SavingsForm = ({show,handleShow}: any) => {
  const [percentage, setPercentage] = useState(0);
  const [disable, setIsdisable] = useState(true);
  const [isLoading, setIsLoading] = useState(false); // New state for loading indicator
  const dispatch: any = useDispatch();

  const handleChange = (e: any) => {
    if (e.target.value > 0) {
      setPercentage(e.target.value);
      setIsdisable(false);
    } else {
      setIsdisable(true);
    }
  };

  const handleSubmit = () => {
    if (percentage > 99) {
      toast.error("The range of values should be 0-99.");
    } else {
      setIsLoading(true); // Show loader when submitting
      dispatch(addPercentage(percentage));

      // Simulate asynchronous operation (API call, etc.)
      setTimeout(() => {
        setIsLoading(false); // Hide loader after a few seconds
        setPercentage(0);
        handleShow();
      }, 2000); // 2000 milliseconds (2 seconds) - adjust as needed
    }
  };
  return (
    <CustomModal
      showModal={show}
      closeModal={handleShow}
      modalTitle={'Savings Percentage'}
      isCancelButton
      isSubmitButton={ true}
      isSubmitText="Submit"
      onSubmit={handleSubmit} // TODO
      // onSubmit={() => setShowAuthenticateModal(true)} // TODO
      isLoading={isLoading}
      isButtonDisabled={percentage === 0}
    >
      {/* <Grid className="savings-container"> */}
      <Grid item xs={12} lg={6} md={4} sm={6}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <TextField
            label="Percentage"
            variant="outlined"
            className="text-box savings-container"
            type="number"
            InputProps={{
              inputProps: {
                min: 0,
                max: 100,
                maxLength: 3,
              },
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-percentage" />
                </InputAdornment>
              ),
            }}
            value={percentage || ''}
            onChange={handleChange}
          />
        </Grid>
      </Grid>
      {/* <Grid item xs={12} lg={12} md={12} sm={12}>
        <Button className="button-cancel btn-mobile" onClick={() =>{
          setPercentage(0)
        }}>Cancel</Button>
        <Button
          type="submit"
          className={`btn-mobile ${
            disable ? "button-save-disabled" : "button-save"
          }`}
          disabled={disable}
          onClick={handleSubmit}
        >
          Save
        </Button>
      </Grid> */}
    {/* </Grid> */}
    </CustomModal>
  );
};

export default SavingsForm;
