import React, {FC, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import DialogWindow from "../../../../../components/DialogWindow";


export const AssignKPIModal: FC<any> = ({
  openDialog,
  closeKPIModal,
  getVendorKPITypesData,
  getVendorMembers,
  vendorKpiTypesList,
  vendorMembersList,
  vendorKPIModal: {
    spinner,
    kpiId,
    memberId,
    comments,
    kpiIdError,
    memberIdError,
    commentsError,
    isEdit,
    isFormUpdated,
  },
  onChangeVendorKPIkpi,
  onChangeVendorKPImember,
  onChangeVendorKPIcomments,
  onAddVendorKPI,
  onEditVendorKPI,
  setKPIChartModal
}) => {
  useEffect(() => {
    getVendorKPITypesData();
    return () => {
      // clearVendorKPIModal()
    };
  }, []);

  const dialogWindowConfig = () => {
    const newConfig = {
      title: isEdit ? "Edit KPI Agreement" : "Add KPI Agreement",
      isCancel: true,
      submitText: isEdit ? "Edit KPI Agreement" : "Add KPI Agreement",
      isGoBack: true,
      backText: ''
    };
    return newConfig;
  };

  const errorFlag = !isFormUpdated || kpiIdError || commentsError;


  return (
    <div className="vendor-assign-content">
      <DialogWindow
        openDialog={openDialog}
        dialogConfiguration={dialogWindowConfig()}
        handleClose={closeKPIModal}
        handleSubmit={isEdit ? onEditVendorKPI : onAddVendorKPI}
        isButtonDisabled={errorFlag}
        spinnerEnabled={spinner}
        setKPIChartModal={setKPIChartModal}
      >
        <div className="br-form-element">
          <Grid container spacing={6}>
            <Grid item xs>
              <FormControl variant="outlined" className="select-box">
                <InputLabel htmlFor="Type">KPI</InputLabel>

                <Select
                  native
                  label="KPI id"
                  value={kpiId}
                  onChange={(e) => onChangeVendorKPIkpi(e.target.value)}
                  error={kpiIdError}
                  id={
                    kpiIdError ? "standard-error" : "outlined-age-native-simple"
                  }
                  disabled={spinner}
                >
                  <option value="0">Select Option</option>
                  {vendorKpiTypesList &&
                    vendorKpiTypesList.length > 0 &&
                    vendorKpiTypesList.map((item: any) => {
                      let lowValue =
                        item.lowValue && item.lowValue > 99
                          ? item.lowValue
                          : item.lowValue;
                      let highValue =
                        item.highValue && item.highValue > 99
                          ? item.highValue
                          : item.highValue;

                      let prependData = item.kpiTypeId == 1 ? "$" : "";
                      let appenedData = item.kpiTypeId == 2 ? "days" : "";
                      return (
                        <option value={item.kpiId}>
                          {item.typeName} - {prependData} {lowValue}
                          {lowValue && highValue
                            ? " - " + prependData + " " + highValue
                            : ""}{" "}
                          {appenedData} 
                          {/* ({item.kpiId > 5 ? 'Old KPI value' : 'New KPI value'}) */}
                        </option>
                      );
                    })}
                </Select>
                {kpiIdError ? (
                  <p style={{color: "red", fontSize: "12px"}}>
                    {"Please select KPI"}
                  </p>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
            {/* <Grid item xs>
              <FormControl variant="outlined" className="select-box">
                <InputLabel htmlFor="Type">Member</InputLabel>

                <Select
                  label="Member id"
                  value={memberId}
                  onChange={(e) => onChangeVendorKPImember(e.target.value)}
                  error={memberIdError}
                  id={
                    memberIdError
                      ? "standard-error"
                      : "outlined-age-native-simple"
                  }
                  disabled={spinner}
                >
                  <option value="0">Select Option</option>
                  {vendorMembersList &&
                    vendorMembersList.map((item) => {
                      return <option value={item.id}>{item.name}</option>;
                    })}
                </Select>
                {memberIdError ? (
                  <p style={{color: "red", fontSize: "12px"}}>
                    {"Please select member"}
                  </p>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid> */}
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                multiline
                rows={3}
                rowsMax={4}
                label="Comment"
                variant="outlined"
                className="text-box"
                // required
                value={comments}
                onChange={(e) => onChangeVendorKPIcomments(e.target.value)}
                // error={commentsError}
                id={
                  commentsError
                    ? "standard-error"
                    : "outlined-age-native-simple"
                }
                // helperText={commentsError ? "Please enter valid comments" : ""}
                disabled={spinner}
              />
            </Grid>
          </Grid>
        </div>
      </DialogWindow>
    </div>
  );
};
