import {
  UPDATE_VENDOR_SALES_ERROR,
  UPDATE_VENDOR_SALES_LIST,
  CLEAR_VENDOR_SALES_LIST,
  VendorSalesError,
  FetchError,
} from '../../constants';

import { getVendorSalesList } from '../../api/vendors';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const clearVendorSalesList = () => ({
  type: CLEAR_VENDOR_SALES_LIST,
});

export const getVendorSales = () => async (dispatch: any, getState: any) => {
  const { vendor } = getState();
  const { vendorID, type } = vendor;
  try {
    const vendorSalesList = await getVendorSalesList(vendorID);
    dispatch({
      type: UPDATE_VENDOR_SALES_LIST,
      vendorSalesList: vendorSalesList.data,
    });
  } catch (error) {
    if (error === UPDATE_VENDOR_SALES_ERROR) {
      toast.error(VendorSalesError);
    } else {
      toast.error(FetchError);
    }
  }
};
