import {DISCUSSION_API, ADD_COMMENT_DISLIKE_ERROR} from '../../../constants';

export const removeCommentLike = async ({
    discussionId,
    commentId,
    commentLikeId
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${discussionId}/comments/${commentId}/likes/${commentLikeId}`, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw ADD_COMMENT_DISLIKE_ERROR;
    }
};