import { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { Loader } from '../../utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';

const StyledDialogHead = withStyles((theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(2),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle style={{ position: 'relative' }} disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          style={{ position: 'absolute' }}
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme: any) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const CustomModal: FC<any> = ({
  showModal,
  closeModal,
  modalTitle,
  children,
  isCancelButton,
  isSubmitButton,
  isExportButton,
  isSubmitText,
  onSubmit,
  isButtonDisabled,
  isLoading,
  isEditButton,
  showNotesEditModal,
  createNew,
}) => {
  return (
    <div>
      <Dialog
        onClose={() => closeModal()}
        aria-labelledby="customized-dialog-title"
        open={showModal}
        className="dialog-box"
      >
        {isLoading && (
          <div className="loader-wrapper">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        )}
        <StyledDialogHead onClose={closeModal}>{modalTitle}</StyledDialogHead>

        <StyledDialogContent>{children}</StyledDialogContent>

        <StyledDialogActions>
          <div className="modal-buttons-container">
            {createNew && (
              <div>
                <button
                  className="create-user-button"
                  onClick={() => {
                    createNew?.onClick();
                  }}
                >
                  Create New {createNew?.entityLabel || 'Entity'}
                </button>
              </div>
            )}
            {isEditButton && (
              <Button
                className={
                  isButtonDisabled
                    ? 'export-button button-disable'
                    : 'export-button'
                }
                onClick={() => showNotesEditModal(true)}
              >
                Edit
              </Button>
            )}
            <div className="action-button">
              {isCancelButton && (
                <button
                  className="cancel-user-button"
                  onClick={() => closeModal()}
                >
                  Cancel
                </button>
              )}
              {isSubmitButton && (
                <Button
                  className={
                    isButtonDisabled
                      ? 'invite-user-button button-disable'
                      : 'invite-user-button'
                  }
                  onClick={onSubmit}
                  disabled={isButtonDisabled}
                >
                  {isSubmitText}
                </Button>
              )}
              {isExportButton && (
                <Button
                  className={
                    isButtonDisabled
                      ? 'export-button button-disable'
                      : 'export-button'
                  }
                  onClick={onSubmit}
                >
                  {isSubmitText}
                </Button>
              )}
            </div>
          </div>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default CustomModal;
