import serverApiV2Instance from '../../services/serverApiV2Instance';

export const viewAdminAsMember = async (email) => {
    try {

        const response = await serverApiV2Instance.post('/view-as-member', email);

        if(!response?.data){
            throw 'Failed to view as member'
        }
        
        return response?.data
    } catch (error) {
        if(error){
            throw `Failed to view as member - ${error?.response?.data?.message}`
        }        
    }
}