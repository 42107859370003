export const sideMenuConfiguration = [
  {
    menuId: "dashboard",
    label: "Dashboard",
    url: "/dashboard",
    iconStyle: "fas fa-chart-pie",
    subMenu: [],
  },
  {
    menuId: "members",
    label: "Members",
    url: "/members",
    iconStyle: "fas fa-glass-martini-alt",
    subMenu: [],
  },
  {
    menuId: "vendors",
    label: "vendors",
    url: "",
    iconStyle: "fas fa-store",
    subMenu: [{
      menuId: 1,
      label: "Performance Foodservice",
      url: "/vendors/performance-foodservice",
      isVendor : true,
    },
    {
      menuId: 2,
      label: "Branded Image",
      url: "/vendors/branded-image",
      isVendor : true,
    }],
  },
  {
    menuId: "documents",
    label: "Documents",
    url: "/documents",
    iconStyle: "fas fa-file-alt",
    subMenu: [],
  },
  {
    menuId: "rebates",
    label: "Rebates",
    url: "/rebates",
    iconStyle: "fas fa-money-bill-wave-alt",
    subMenu: [],
  },
  {
    menuId: "community",
    label: "Community",
    url: "",
    iconStyle: "fas fa-user-friends",
    subMenu: [{
      menuId: "channel",
      label: "Channels",
      url: "/community",
    },
    {
      menuId: "memberDirectory",
      label: "Member Directory",
      url: "/member-directory",
    },
    {
      menuId: "userDirectory",
      label: "user Directory",
      url: "/user-directory",
    },{
      menuId: "userAnalytics",
      label: "Analytics",
      url: "/analytics",
    }],
  },
];
