import {DISCUSSION_API, ADD_COMMENT_LIKE_ERROR} from '../../../constants';

export const addCommentLike = async ({
    discussionId,
    commentId,
    userId
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${discussionId}/comments/${commentId}/likes`, {
            method: 'POST',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                userId
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw ADD_COMMENT_LIKE_ERROR;
    }
};