import { UPDATE_MEMBER_USER_UPDATE_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const updateMemberUser = async ({ id, typeid, userId, role }) => {
  try {
    let data = {
      roleid: role,
      type: typeid,
      memberid: id,
    };

    const response = await serviceInterceptor.patch(
      `/user/${userId}/`, // TODO NOT RESTFUL!!
      data,
    );
    if (!response?.data) {
      throw UPDATE_MEMBER_USER_UPDATE_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPDATE_MEMBER_USER_UPDATE_ERROR;
  }
};
