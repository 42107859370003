import {
  UPDATE_HAND_DELIVER_CHECK,
} from '../../../constants';
import 'react-toastify/dist/ReactToastify.css';

export const handleClaimRebatesCheck =
  (isHandDeliverChecked: any) => async (dispatch: any) => {
    dispatch({
      type: UPDATE_HAND_DELIVER_CHECK,
      isHandDeliverChecked,
    });
  };
