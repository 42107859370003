import { AdCreateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const createAd = async ({
  adUrl,
  adType,
  productName,
  productBrand, // TODO Is this being used?
  adSpecial,
  description,
  adRating,
  adImage,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('url', adUrl);
    bodyFormData.append('vendorProductId', productName);
    bodyFormData.append('adTypeId', adType);
    bodyFormData.append('specials', adSpecial);
    bodyFormData.append('description', description);
    bodyFormData.append('image', adImage);
    bodyFormData.append('disableDate', '');
    bodyFormData.append('ranking', adRating);

    const response = await serviceInterceptor.post('/ads', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response?.data?.success) {
      throw AdCreateError;
    }
    return response.data;
  } catch (error) {
    throw AdCreateError;
  }
};
