import {DOCUMENT_RECORDS_API, DeleteDocumentError} from "../../constants";

export const deleteDocument = async ({
    documentID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DOCUMENT_RECORDS_API}/${documentID}`, {
            method: "DELETE",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            })
        });
        if (!response.ok) throw DeleteDocumentError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw DeleteDocumentError;
    }
};