import {baseUserUrl, UPDATE_MEMBER_USER_DELETE_ERROR} from "../../constants";

export const deleteUserMember = async (id) => {
    console.log('id from front',id)
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUserUrl}${id}/delete`, {
            method: "DELETE",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
            // body: JSON.stringify({
            //     memberUserId: memberUserId,
            //     memberId: memberid
            // })
        });
        if (!response.ok) throw UPDATE_MEMBER_USER_DELETE_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_MEMBER_USER_DELETE_ERROR;
    }
};