import React, {useEffect} from "react";
import Button from "@material-ui/core/Button";
import {
  Loader,
  getExtensionClassName,
  getFileClassName,
} from "../../../../../../utils";
import UploadImg from "../../../../../../images/file-upload.svg";

export const FileOneUpload = (props) => {
  const {handleMemberRebatesFile, memberRebates, onMemberRebatesFileRemove, handleNext} = props;
  const {fileExtensionError, fileOneExtension, fileOneName, fileOneSize} = memberRebates;
  return (
    <div className="rebates-file-upload">
      {fileOneName === null || fileExtensionError ? (
        <Button className="file-filed-document" component="label">
          <img src={UploadImg} />
          <div className="file-filed-text-size-sm">Upload XLS OR XLSX files</div>
          <div className="file-filed-text-size-md">Drop your files here. </div>
          <div>
            or <span className="file-filed-text-size-highlight">browse</span>
          </div>
          <input
            type="file"
            id="fileUpload"
            title=""
            value=""
            onChange={(event) => {
              handleMemberRebatesFile(event.target.files[0]);
              handleNext();
            }
            }
          />
          {fileExtensionError && (
            <div className="error-wrapper">
              <i className="fas fa-exclamation-triangle error-icon" />
              <div className="error-text">
                {"Please upload only xls or xlsx extenstion file."}
              </div>
            </div>
          )}
        </Button>
      ) : (
        <div className="edit-doc-section">
          <div className="edit-doc-section-box">
            <div className="documents-grid-img">
              <i className={getFileClassName(fileOneExtension)} />
              <span
                className={getExtensionClassName(fileOneExtension)}
              >
                {fileOneExtension}
              </span>
            </div>

            <div className="edit-doc-section-name">
              {fileOneName}
            </div>
            <div className="edit-doc-section-size">
              {fileOneSize}
            </div>
            <div className="edit-doc-section-buttons">
              <Button
                className="remove-button"
                onClick={onMemberRebatesFileRemove}
              >
                Remove
              </Button>
              <label className="replace-button" for="replaceFile">
                Replace
              </label>
              <input
                type="file"
                id="replaceFile"
                hidden
                onChange={(event) =>
                  handleMemberRebatesFile(event.target.files[0])
                }
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
