import { connect } from 'react-redux';
import {
  onChangeEmail,
  onChangePassword,
  onChangeShowPassword,
  onLoginSubmit,
  clearLogin,
} from '../../actions/login';

import { Login as LoginContent } from './Login';

const mapStateToProps = (state: any) => {
  const { login } = state;

  return {
    login,
  };
};

export const Login = connect(mapStateToProps, {
  onChangeEmail,
  onChangePassword,
  onChangeShowPassword,
  onLoginSubmit,
  clearLogin,
})(LoginContent);
