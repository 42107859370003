import React, { useEffect } from "react";

import {
  ManageAdsHead,
  ManageAdsList,
  AddAdsModal,
  AddAdsTypeModal,
  ViewAdCard,
} from "./components";
import { Loader } from "../../utils";

export const ManageAdsComponent = (props) => {
  const {
    getAdTypes,
    getAdList,
    handleShowAddAdsModal,
    handleShowAdsTypeModal,
    manageAdsList: { loading, showAddModal, showAddTypeModal, showViewAdModal, selectedAd },
  } = props;
  useEffect(() => {
    getAdTypes();
    getAdList();
  }, []);
  return (
    <div className="container">
      <div className="manage-ads-content">
        <ManageAdsHead
          handleShowAddAdsModal={handleShowAddAdsModal}
          handleShowAdsTypeModal={handleShowAdsTypeModal}
        />
        {loading ? (
          <div className="relative-wrapper">
            <div className="loader-wrapper list-spinner">
              <div className="document-loader">
                <Loader size={40} />
              </div>
            </div>
          </div>
        ) : (
          <ManageAdsList {...props} />
        )}
        {showAddModal && <AddAdsModal showModal={showAddModal} {...props} />}
        {showAddTypeModal && (
          <AddAdsTypeModal showModal={showAddTypeModal} {...props} />
        )}
        {showViewAdModal && <ViewAdCard item={selectedAd} {...props} />}
      </div>
    </div>
  );
};
