import React, { FC, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import { formatPhoneNumber } from "../../../utils/PhoneNumberUtils";
import DefaultUserImg from "../../../images/profile-placeholder.jpg";
import { SearchNotFound } from "../../../components/SearchNotFound";
import { baseUrl } from "../../../constants/api";
import { useStyles } from "../../../utils";
import { Loader } from '../../../utils';
import { TablePagination } from '@material-ui/core';

import sortDefault from "../../../images/sortDefault.svg";
import sortAscIcon from "../../../images/sortAsc.svg";
import sortDescIcon from "../../../images/sortDesc.svg";
import { useLocation } from "react-router-dom";
export const MemberTable: FC<any> = ({
  gridHeader,
  gridData,
  handleDelete,
  setKPIChartModal,
  updateSelectedKPIMember,
  getKpiYears,
  showEditVendorMember,
  showDelete,
  getKPIYearQuarter,
  user: { isBRAdmin },
  state,
  send,
}) => {
  const classes = useStyles();

  const handleKPIClick = (item: any) => {
    updateSelectedKPIMember(item.id);
    getKPIYearQuarter(item.id);
    setKPIChartModal(true);
  };
  const {pathname} :any = useLocation()
  const orderBy = state.context.additionalQueryStringValues.orderBy as string;
  const sortOrder = state.context.additionalQueryStringValues.sortOrder as
    | 'asc'
    | 'desc';
  const setOrderBy = (orderBy: string) => {
    if (state.context.additionalQueryStringValues.orderBy === orderBy) {
      return send({
        type: 'addQueryStringValue',
        key: 'sortOrder',
        value:
          state.context.additionalQueryStringValues.sortOrder === 'asc'
            ? 'desc'
            : 'asc',
      });
    }
    send({
      type: 'addQueryStringValue',
      key: 'orderBy',
      value: orderBy,
    });
  };

  return (
    <div className="table-wrapper">
      <div className={classes.root}>
        {gridData.length > 0 && (
          <Grid container spacing={0} className="tr thead grid-header">
            {gridHeader.map((item: any) => {
              const { label, value, xs, lg, sortKey } = item;
              const isValidHeader = label !== "";
              return (
                <Grid className="th" item xs={xs} lg={lg}>
                  {label === 'Action' ?
                    (<div className={label === 'Action' ? 'action-header' : ''}>
                      <span className="grid-header">{label}</span>
                    </div>) :
                    (<div onClick={() => sortKey && setOrderBy(sortKey)} className={label === 'Sales Mobile Phone' ? 'white-space' : ''}>
                      <span className="grid-header">{label}</span>
                      <span className="sort-icon">
                        {isValidHeader && (sortKey === orderBy ? (
                          <img
                            className="sort-svg"
                            src={
                              sortOrder === 'asc' ? sortAscIcon : sortDescIcon
                            }
                          />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        ))}
                      </span>
                    </div>)}
                </Grid>
              );
            })}
          </Grid>
        )}

        {gridData.length > 0 ? (
          gridData.map((item: any) => {
            const {
              id,
              name,
              phone,
              mobile,
              street,
              city,
              state: stateName,
              zip,
              avatar,
              salesPersonFirsttName,
              salesPersonLastName,
              salesPhone,
              salesEmail,
            } = item ?? {};
            return (
              <Grid container spacing={0} className="tr">
                <Grid
                  className="td"
                  item
                  xs={12}
                  lg={3}
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  <div className="table-member-details">
                    <div className="member-img">
                      <img
                        src={
                          avatar !== null
                            ? `/${avatar}`
                            : DefaultUserImg
                        }
                      />
                    </div>
                    <div className="member-profile-details">
                      <div>
                        <NavLink
                          className="member-name"
                          style={{ color: "black" }}
                          to={`../member/view/${id}/`}
                          state={{
                            path : pathname,
                            isFromMemberTab: true,
                            pageNumber : state.context.pageNumber,
                            pageSize : state.context.pageSize,
                            vendortabs : 3
                          }}
                        >
                          {name}
                        </NavLink>
                      </div>
                      <div className="member-mail-id">
                        <div className="br-number-normal">
                          {phone ? formatPhoneNumber(phone) : ""}
                        </div>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {salesPersonFirsttName
                    ? `${salesPersonFirsttName} ${salesPersonLastName}`
                    : "-"}
                </Grid>
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <div className="br-number-normal">
                    {salesPhone && salesPhone !== 'null' ?
                      <a className="phone-link" href={`tel:${salesPhone}`}>{formatPhoneNumber(salesPhone)}</a> : "-"}
                  </div>
                </Grid>
                <Grid
                  className="td xs-left-align"
                  item
                  xs={12}
                  lg={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  {`${street}, ${city}, ${stateName}, ${zip}`}
                </Grid>
                <Grid
                  className="td action-col"
                  item
                  xs={12}
                  lg={2}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Tooltip title="Email">
                    <Button
                      className="btn-circle btn-secondary"
                      variant="contained"
                    >
                      <a className="email-link" href={`mailto:${salesEmail}?subject=Buy Right Application Inquiry for ${name}`}>
                        <i className="fas fa-envelope" />
                      </a>
                    </Button>
                  </Tooltip>
                  
                  {
                    pathname === '/vendors/performance-foodservice' &&
                      <Tooltip title="KPI">
                        <Button
                          className="btn-circle btn-secondary"
                          variant="contained"
                          onClick={() => handleKPIClick(item)}
                        >
                          <i className="fas fa-star-half-alt"></i>
                        </Button>
                      </Tooltip>
                  }
                  {isBRAdmin && (
                    <Tooltip title="Edit">
                      <Button
                        className="btn-circle btn-secondary"
                        variant="contained"
                        onClick={() => showEditVendorMember(item)}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                    </Tooltip>
                  )}

                  {isBRAdmin && showDelete && (
                    <Tooltip title="Unassign">
                      <Button
                        className="btn-circle-trash"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDelete(item)}
                      >
                        <i className="fas fa-times"></i>
                      </Button>
                    </Tooltip>
                  )}
                </Grid>
              </Grid>
            );
          })
        ) : (
          <SearchNotFound sectionType={"Members"} />
        )}

        {
          gridData.length > 0 && (
            <TablePagination
              component="div"
              count={state.context.totalRecords}
              page={state.context.pageNumber - 1}
              onPageChange={(_, newPage) => {
                send({
                  type: 'goToPage',
                  pageNumber: newPage + 1,
                })
              }
              }
              rowsPerPage={state.context.pageSize}
              onRowsPerPageChange={(event) =>
                send({
                  type: 'updatePageSize',
                  pageSize: parseInt(event.target.value),
                })
              }
              rowsPerPageOptions={[10, 20, 30, 40]}
              labelDisplayedRows={({ from, to, count }) => {
                return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`
                  }`;
              }}
            />
          )
        }
      </div>
    </div>
  );
};

MemberTable.propTypes = {
  gridHeader: PropTypes.array,
  gridData: PropTypes.array,
  isEdit: PropTypes.bool,
  isDelete: PropTypes.bool,
  handleDelete: PropTypes.func,
  setSortKey: PropTypes.func,
  sortType: PropTypes.string,
};
