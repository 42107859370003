import { baseUrl } from '../../constants';

export const getVendorDistributionCenterList = async (vendorID: number) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const member = JSON.parse(sessionStorage.getItem('user') || '{}');
    let accessToken = Object.keys(member).length ? member.accessToken : user.accessToken
    const response = await fetch(
      `${baseUrl}/vendors/${vendorID}/vendor-distribution-centers`, // TODO not RESTful :(
      {
        headers: new Headers({
          'x-access-token': accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw 'Unable to fetch distribution center list';
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw 'Unable to fetch distribution center list';
  }
};
