import { UPDATE_VENDOR_LINK_API, UpdateVendorLinkError } from '../../constants';

export const updateVendorLink = async ({
    vendorID,
    linkType,
    linkText,
    linkUrl,
    linkId
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(UPDATE_VENDOR_LINK_API, {
            method: 'PUT',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                vendorid: vendorID,
                type: linkType,
                text: linkText,
                url: linkUrl,
                vendorlinkid: linkId
            })
        });
        if (!response.ok) throw UpdateVendorLinkError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UpdateVendorLinkError;
    }
};