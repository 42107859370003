import {
    SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER,
    SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER,
    SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS,
    UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW
} from "../../constants";

const initialState = {
    dashboardActiveEarningsQuarterSpinner: false,
    dashboardActiveEarningsMemberSpinner: false,
    dashboardActiveEarningsDetailSpinner: false,
    activeEarningsQuarterList: [],
    selectedActiveEarningsQuarter: null,
    activeEarningsMemberList: [],
    selectedActiveEarningsMember: null,
    dashboardActiveEarningsDetails: null,
    dashboardActiveEarningsOverview: null
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER:
            return ({
                ...state,
                dashboardActiveEarningsQuarterSpinner: action.dashboardActiveEarningsQuarterSpinner
            });
        case SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER:
            return ({
                ...state,
                dashboardActiveEarningsMemberSpinner: action.dashboardActiveEarningsMemberSpinner
            });
        case SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER:
            return ({
                ...state,
                dashboardActiveEarningsDetailSpinner: action.dashboardActiveEarningsDetailSpinner
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST:
            return ({
                ...state,
                activeEarningsQuarterList: action.activeEarningsQuarterList
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER:
            return ({
                ...state,
                selectedActiveEarningsQuarter: action.selectedActiveEarningsQuarter
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST:
            return ({
                ...state,
                activeEarningsMemberList: action.activeEarningsMemberList
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER:
            return ({
                ...state,
                selectedActiveEarningsMember: action.selectedActiveEarningsMember
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS:
            return ({
                ...state,
                dashboardActiveEarningsDetails: action.dashboardActiveEarningsDetails
            });
        case UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW:
            return ({
                ...state,
                dashboardActiveEarningsOverview: action.dashboardActiveEarningsOverview
            });
        default:
            return state;
    }
};
