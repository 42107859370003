import React from 'react';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import {
    Loader,
    getFileClassName,
    getExtensionClassName,
} from '../../../utils';
import AuthorizationService from '../../../authorization/AuthorizationService';

export const DocumentsGridContent = ({
    documents: {
        downloadSpinner,
        downloadIconVisible,
    },
    showDocumentEditModal,
    showDocumentDetailModal,
    handleDeleteDocument,
    paginationReducer: { rowsPerPage, page },
    item,
    index,
    anchorRef,
    open,
    selectedIndex,
    selectedItemID,
    handleToggle,
    handleDocumentDownload,
    handleClose,
    handleListKeyDown
}) => {

    const { id, name, uploadeddate, fileextension, accessLevel } = item;
    const selectedDownloadItem = item.id === selectedItemID;
    const showDownloadIcon =
        downloadIconVisible && selectedDownloadItem && downloadSpinner;

    return (<Grid item xs={6} sm={4} md={3} lg={2}>
        <div className="documents-grid-box">
            <div className="documents-grid-actions">
                <div>
                    <Tooltip title="Download" placement="top">
                        <span>
                            <span
                                className="btn-circle btn-secondary"
                                variant="contained"
                                onClick={() => handleDocumentDownload(item)}
                                disabled={downloadIconVisible}
                            >
                                <i
                                    class={
                                        showDownloadIcon
                                            ? 'fas fa-download icon-visibe'
                                            : 'fas fa-download'
                                    }
                                ></i>
                            </span>
                            {selectedDownloadItem && downloadSpinner && (
                                <div className="document-loader-grid">
                                    <Loader size={12} />
                                </div>
                            )}
                        </span>
                    </Tooltip>
                </div>
                <div>
                    {accessLevel === 'Selected Members' ? (
                        <Tooltip title="Private" placement="top">
                            <span
                                className="btn-circle btn-secondary"
                                variant="contained"
                            >
                                <i class="fas fa-user-cog" />
                            </span>
                        </Tooltip>
                    ) : null}
                    <Tooltip title="Actions" placement="top">
                        <span
                            className="btn-popup-action btn-secondary"
                            variant="contained"
                        >
                            <i
                                className="fas fa-ellipsis-v"
                                ref={anchorRef[index]}
                                aria-controls={open ? 'menu-list-grow' : undefined}
                                aria-haspopup="true"
                                onClick={() => handleToggle(index)}
                            ></i>
                            <Popper
                                open={selectedIndex == index && open}
                                anchorEl={anchorRef.current[index]}
                                role={undefined}
                                transition
                                disablePortal
                            >
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        className="popup-alignment"
                                        {...TransitionProps}
                                    >
                                        <Paper>
                                            <ClickAwayListener
                                                onClickAway={(event) =>
                                                    handleClose(event, index)
                                                }
                                            >
                                                <MenuList
                                                    autoFocusItem={open}
                                                    id="menu-list-grow"
                                                    className="doc-grid-action-menu"
                                                    onKeyDown={handleListKeyDown}
                                                >
                                                    {AuthorizationService.isAuthorizedHTML(
                                                        'editDocument',
                                                    ) && (
                                                            <MenuItem
                                                                onClick={(event) =>
                                                                    handleClose(event, index)
                                                                }
                                                            >
                                                                <span
                                                                    onClick={() =>
                                                                        showDocumentEditModal(item)
                                                                    }
                                                                >
                                                                    <i class="fas fa-pen pen-icon" />
                                                                    Edit
                                                                </span>
                                                            </MenuItem>
                                                        )}
                                                    {AuthorizationService.isAuthorizedHTML(
                                                        'deleteDocument',
                                                    ) && (
                                                            <MenuItem
                                                                onClick={(event) =>
                                                                    handleClose(event, index)
                                                                }
                                                            >
                                                                <span
                                                                    onClick={() =>
                                                                        handleDeleteDocument(item)
                                                                    }
                                                                >
                                                                    <i class="far fa-trash-alt trash-icon" />
                                                                    Delete
                                                                </span>
                                                            </MenuItem>
                                                        )}
                                                    <MenuItem
                                                        onClick={(event) =>
                                                            handleClose(event, index)
                                                        }
                                                    >
                                                        <span
                                                            onClick={() =>
                                                                showDocumentDetailModal(item)
                                                            }
                                                        >
                                                            <i class="far fa-eye eye-icon" />
                                                            Details
                                                        </span>
                                                    </MenuItem>
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </span>
                    </Tooltip>
                </div>
            </div>
            <div className="documents-grid-img">
                <i className={getFileClassName(fileextension)} />
                <span className={getExtensionClassName(fileextension)}>
                    {fileextension}
                </span>
            </div>
            <div className="documents-grid-name">{name}</div>
            <div className="documents-grid-date">{uploadeddate}</div>
        </div>
    </Grid>
    );
};