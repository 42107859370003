import {
    SHOW_DISCUSSION_COMMENT_SPINNER,
    HIDE_DISCUSSION_COMMENT_SPINNER,
    UPDATE_DISCUSSION_COMMENT_ERROR,
    UPDATE_DISCUSSION_COMMENT,
    UPDATE_DISCUSSION_COMMENT_INPUT_FORM,
    CLEAR_COMMUNITY_COMMENT_STATE,
    UPDATE_DISCUSSION_COMMENT_ID,
    UPDATE_DISCUSSION_COMMENT_EDIT_FLAG
} from "../../constants";

const initialState = {
    spinner: false,
    discussionCommentError: false,
    discussionCommentId: null,
    discussionComment: null,
    isCommentFormUpdated: false,
    isCommentEdit: false
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DISCUSSION_COMMENT_SPINNER:
            return ({
                ...state,
                spinner: true
            });
        case HIDE_DISCUSSION_COMMENT_SPINNER:
            return ({
                ...state,
                spinner: false
            });
        case UPDATE_DISCUSSION_COMMENT_ERROR:
            return ({
                ...state,
                discussionCommentError: action.discussionCommentError
            });
        case UPDATE_DISCUSSION_COMMENT:
            return ({
                ...state,
                discussionComment: action.discussionComment
            });
        case UPDATE_DISCUSSION_COMMENT_INPUT_FORM:
            return ({
                ...state,
                isCommentFormUpdated: action.isCommentFormUpdated
            });
        case UPDATE_DISCUSSION_COMMENT_ID:
            return ({
                ...state,
                discussionCommentId: action.discussionCommentId
            });
        case UPDATE_DISCUSSION_COMMENT_EDIT_FLAG:
            return ({
                ...state,
                isCommentEdit: action.isCommentEdit
            });
        case CLEAR_COMMUNITY_COMMENT_STATE:
            return initialState;
        default:
            return state;
    }
};
