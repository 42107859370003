import { FC, useEffect } from 'react';
import UserProfileDropdown from './UserProfileDropdown';
import imgLogo from '../../images/logo.svg';
import Tooltip from '@material-ui/core/Tooltip';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
const HeaderBar: FC<{
  setDesktopMenu: (desktopMenu: boolean) => void;
  isDesktopMenu: boolean;
  isMobileMenu: boolean;
  setMobileMenu: (mobileMenu: boolean) => void;
  clearMemberView : () => void;
}> = ({ setDesktopMenu, isDesktopMenu, isMobileMenu, setMobileMenu,clearMemberView }) => {
  
  const store = useSelector((state) => state)

  const {user}:any = store

  const navigate = useNavigate();


  return (
    <div className="nav-warp">
      <div className="side-nav">
        <div
          className={isDesktopMenu ? 'logo-wrap' : 'logo-wrap logo-size-change'}
        >
          <i
            className="fas fa-bars mobile-menu-icon"
            onClick={() => setMobileMenu(!isMobileMenu)}
          ></i>
          <img src={imgLogo} alt="buyright" title="buyright"  />
          <Tooltip title="Collapse">
            <i
              className={
                isDesktopMenu
                  ? 'fas fa-angle-double-left side-menu-arrow'
                  : 'fas fa-angle-double-right side-menu-arrow side-menu-arrow-move'
              }
              onClick={() => setDesktopMenu(!isDesktopMenu)}
            />
          </Tooltip>
        </div>
      </div>
  
      <div className="nav-header">
          {
            user.isViewAsMember && (
              <div className='nav-item'>
                <a
                  onClick={() => {
                    navigate('/dashboard')
                    clearMemberView()
                  }}
                >
                  <Tooltip title="Back to admin">
                    <i className="fas fa-sign-out-alt sign-out"/>                  
                  </Tooltip>
                </a>
              </div>
            )
          }
        {/* TODO Add these options back in when their functionality is available */}
        {/* <div className="nav-item">
            <a>
              <Tooltip title="Mail">
                <i className="fas fa-envelope"></i>
              </Tooltip>
            </a>
          </div>
  
          <div className="nav-item">
            <a>
              <Tooltip title="Notifications">
                <i className="fas fa-bell"></i>
              </Tooltip>
            </a>
          </div> */}
        <div className="nav-item">
          <a href="mailto:app-help@buyrightpurchasing.com?subject=Buy Right Purchasing application help&body=Hello Buy Right Purchasing Support,%0D%0A%0D%0AI would like your help with...">
            <Tooltip title="Help">
              <i className="fas fa-question-circle"></i>
            </Tooltip>
          </a>
        </div>
        <UserProfileDropdown />
      </div>
    </div>
  )
};

export default HeaderBar;
