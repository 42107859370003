import { FC, useState, useRef } from "react";
import Button from "@material-ui/core/Button";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Grow from "@material-ui/core/Grow";
import Popper from "@material-ui/core/Popper";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import { Loader } from "../../../../../../../utils";

export const QuarterDropdown: FC<{
    memberAdminRebates: any;
    updateMemberAdminRebatesQuarter: (item: any) => void;
    setQuarter: (quarter: number | string) => void;
}> = ({
    memberAdminRebates: {
        memberAdminQuarterSpinner,
        memberOptionList,
        quarter,
        memberQuartersList,
    },
    updateMemberAdminRebatesQuarter,
    setQuarter,
}) => {
        const [open, setOpen] = useState(false);
        const anchorRef = useRef<any>(null);

        const handleToggle = () => {
            setOpen((prevOpen) => !prevOpen);
        };

        const handleClose = (event: any) => {
            if (anchorRef.current && anchorRef.current.contains(event.target)) {
                return;
            }
            setOpen(false);
        };


        const handleListKeyDown = (event: any) => {
            if (event.key === "Tab") {
                event.preventDefault();
                setOpen(false);
            }
        }

        const handleSelected = (item: any, event: any) => {
            updateMemberAdminRebatesQuarter(item);
            setQuarter(item);
            handleClose(event);
        };

        return (

            <div className="br-dropdown-menu">
                <Button
                    ref={anchorRef}
                    aria-controls={open ? "menu-list-grow" : ""}
                    aria-haspopup="true"
                    onClick={handleToggle}
                    disabled={memberAdminQuarterSpinner || memberOptionList.length === 0}
                >
                    <span className="drop-menu-button-text">
                        Quarter: {" "}
                        <span className="active-option">
                            {quarter}
                        </span>
                    </span>

                    {memberAdminQuarterSpinner ? (
                        <div className="category-spinner">
                            <Loader size={15} />
                        </div>
                    ) : (
                        <i className="fas fa-chevron-down"></i>
                    )}
                </Button>
                {memberQuartersList && memberQuartersList?.length > 0 && <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={""}
                    transition
                    disablePortal
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === "bottom" ? "center top" : "center bottom",
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList
                                    autoFocusItem={open}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                >
                                    {memberQuartersList &&
                                        memberQuartersList.map((item: any) => {
                                            const { label, value } = item;
                                            return (
                                                <MenuItem
                                                    onClick={(event) => handleSelected(value, event)}
                                                    selected={item.value === quarter}
                                                >
                                                    <>{label}</>
                                                </MenuItem>
                                            );
                                        })}
                                </MenuList>
                            </ClickAwayListener>
                        </Grow>
                    )}
                </Popper>}
            </div>
        );
    };
