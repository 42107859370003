import { Grid } from '@material-ui/core';
import { FC, useMemo } from 'react';
import AuthorizationService from '../../../../authorization/AuthorizationService';
import { columns, actionColumns } from './util/constants';
import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';

export const OpportunitiesTableHead: FC<{
  orderBy: string;
  sortOrder: 'asc' | 'desc';
  setOrderBy: (orderBy: string) => void;
}> = ({ orderBy, setOrderBy, sortOrder }) => {
  const isRebateNote = AuthorizationService.isAuthorizedHTML(
    'rebatesOpportunityNote',
  );
  const gridHeader = useMemo(
    () => (isRebateNote ? [...columns, ...actionColumns] : columns),
    [columns.toString(), actionColumns.toString(), isRebateNote],
  );
  return (
    <Grid container spacing={0} className="tr thead">
      {gridHeader.map((item: any) => {
        const { label, xs, lg, sortKey } = item;
        const isValidHeader = label !== '' && sortKey !== '';
        let sortOrderIcon = null;
        if (isValidHeader) {
          sortOrderIcon = <img className="sort-svg" src={sortDefault} />;
          if (sortKey === orderBy) {
            sortOrderIcon = (
              <img
                className="sort-svg"
                src={sortOrder === 'asc' ? sortAscIcon : sortDescIcon}
              />
            );
          }
        }
        return (
          <Grid className="th" item xs={xs} lg={lg} key={label}>
            <div
              className={label === 'Amount' ? 'table-header-right' : ''}
              onClick={() => sortKey && setOrderBy(sortKey)}
              role="button"
            >
              <span className="grid-header">{label}</span>
              <span className="sort-icon">{sortOrderIcon}</span>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

export default OpportunitiesTableHead;
