import serverApiV2Instance from '../../../services/serverApiV2Instance';

export const getSavingsPercentage = async () => {
    try {

        const response = await serverApiV2Instance.get('/saving-percentage');

        if(!response?.data){
            throw 'Failed to get saving percentage'
        }
   
        return response?.data
    } catch (error) {
        if(error){
            throw `Failed to get savings percentage - ${error?.response?.data?.message}`
        }        
    }
}