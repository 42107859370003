import { FC } from 'react';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { centsToFormattedDollars, Loader, useStyles } from '../../../utils';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import Grid from '@material-ui/core/Grid';
import { Tooltip } from '@material-ui/core';

export const ProductRebatesTable: FC<any> = ({
  loading,
  gridHeader,
  gridData,
  showNotesModal,
  updateSelectedProduct,
  setSortKey,
  sortType,
  activeQuarterStatus,
  isRebateNote,
  isDashboardList,
  orderBy,
}) => {
  const classes = useStyles();

  const handleInfoClick = (item: any) => {
    updateSelectedProduct(item);
    showNotesModal(true);
  };

  return (
    <div className={classes.root}>
      {gridData.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, xs, lg, sortKey } = item;
            const isValidHeader = label !== '' && sortKey !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                <div className={label === 'Amount' ? 'table-header-right' : ''}>
                  <span
                    className="grid-header"
                    onClick={() => sortKey && setSortKey(sortKey)}
                  >
                    {label}
                  </span>
                  <span className="sort-icon">
                    {isValidHeader &&
                      (sortType === '' ? (
                        <img className="sort-svg" src={sortDefault} />
                      ) : sortKey === orderBy && sortType === 'ASC' ? (
                        <img className="sort-svg" src={sortAscIcon} />
                      ) : sortKey === orderBy && sortType === 'DESC' ? (
                        <img className="sort-svg" src={sortDescIcon} />
                      ) : (
                        <img className="sort-svg" src={sortDefault} />
                      ))}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const {
            productName,
            productNumber,
            packSize,
            rebateType,
            rebateAmount,
            rebatePercent,
          } = item;
          const formattedRebateAmount = rebateAmount
            ? parseInt(rebateAmount).toFixed(2)
            : '';
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={isDashboardList ? 4 : 3}
                container
                direction="row"
                alignItems="center"
              >
                {loading ? (
                  <Loader size={10} />
                ) : productName ? (
                  productName
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                <div className="br-number-normal">
                  {loading ? (
                    <Loader size={10} />
                  ) : productNumber ? (
                    productNumber
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                <div className="br-number-normal">
                  {loading ? <Loader size={10} /> : packSize ? packSize : '-'}
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary table-data-nowrap"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                {loading ? <Loader size={10} /> : rebateType ? rebateType : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                <div className="br-number-normal br-align-right br-block-100 table-data-spacing">
                  {loading ? (
                    <Loader size={10} />
                  ) : formattedRebateAmount.length > 0 &&
                    Number(formattedRebateAmount) ? (
                    centsToFormattedDollars(Number(formattedRebateAmount))
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                <div className="br-number-normal">
                  {loading ? (
                    <Loader size={10} />
                  ) : rebatePercent ? (
                    `${rebatePercent} %`
                  ) : (
                    '-'
                  )}
                </div>
              </Grid>

              {isRebateNote && (
                <Grid
                  className="td action-col"
                  item
                  xs={12}
                  lg={1}
                  container
                  direction="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <Tooltip title="Notes">
                    <div
                      className="info-rebates-notes"
                      onClick={() => handleInfoClick(item)}
                    >
                      <i className="fas fa-info" />
                    </div>
                  </Tooltip>
                </Grid>
              )}
            </Grid>
          );
        })
      ) : (
        <SearchNotFound
          sectionType={'Opportunities'}
          status={activeQuarterStatus ?? ''}
        />
      )}
    </div>
  );
};
