import { baseUrl, UPDATE_MEMBER_GET_ERROR } from '../../constants';

export const getMemberById = async (id: number) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const response = await fetch(`${baseUrl}/members/${id}`, {
      headers: new Headers({
        'x-access-token': user.accessToken,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw UPDATE_MEMBER_GET_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_MEMBER_GET_ERROR;
  }
};
