import { DELETE_VENDOR_LINK_API, VendorDeleteLinkError } from '../../constants';

export const deleteVendorLink = async ({
    vendorID,
    slectedLinkID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(DELETE_VENDOR_LINK_API, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                vendorid: vendorID,
                vendorlinkid: slectedLinkID
            })
        });
        if (!response.ok) throw VendorDeleteLinkError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw VendorDeleteLinkError;
    }
};