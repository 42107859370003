import { UPDATE_CHANGE_PASSWORD_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const updatePasswordApi = async ({
  id,
  oldPassword,
  newPassword,
  confirmPassword,
}) => {
  try {
    let data = {
      oldPassword: oldPassword,
      newPassword: newPassword,
      confirmPassword: confirmPassword,
    };

    const response = await serviceInterceptor.put(
      `/user/${id}/password`, // TODO NOT RESTFUL!!
      data,
    );
    if (!response?.data) {
      throw UPDATE_CHANGE_PASSWORD_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPDATE_CHANGE_PASSWORD_ERROR;
  }
};
