import { FC, useState } from 'react';
import { SearchNotFound } from '../../../../components/SearchNotFound';
import { useStyles } from '../../../../utils';
import DefaultMemberImg from '../../../../images/member-list-placeholder.jpg';
import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';
import { formatPhoneNumber } from '../../../../utils/PhoneNumberUtils';
import { Button, Grid, Tooltip } from '@material-ui/core';
import {StringAvatar} from "../../../../utils/AvatarInitial";
import Avatar from '@mui/material/Avatar';

interface VendorTableProps {
  gridHeader: any[];
  gridData: any[];
  setSortKey: (sortKey: string) => void;
  sortType: string;
  memberName: string;
}

export const VendorTable: FC<VendorTableProps> = ({
  gridHeader,
  gridData,
  setSortKey,
  sortType,
  memberName,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: string) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  return (
    <div className={`${classes.root} table-wrapper`}>
      {gridData?.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item, index) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg} key={index}>
                {label === 'Action' ? (
                  <div className={label === 'Action' ? 'action-header' : ''}>
                    <span className="grid-header">{label}</span>
                  </div>
                ) : (
                  <div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader ? (
                        sortType === '' ? (
                          <img className="sort-svg" src={sortDefault} />
                        ) : label === activeHeader && sortType === 'ASC' ? (
                          <img className="sort-svg" src={sortAscIcon} />
                        ) : label === activeHeader && sortType === 'DESC' ? (
                          <img className="sort-svg" src={sortDescIcon} />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      ) : null}
                    </span>
                  </div>
                )}
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {gridData?.length > 0 ? (
        gridData.map((item, index) => {
          const { userName, roleName, phone, email, avatar } = item;
          return (
            <Grid container spacing={0} className="tr" key={index}>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                alignItems="center"
              >
                <div className="table-member-details">
                  <div className="member-img">
                    {avatar && avatar?.length > 0 ? (
                      <img src={avatar} alt="user" />)
                      : (userName ? (
                        <Avatar {...StringAvatar(userName)} />) : (
                        <img src={DefaultMemberImg} alt="user" />)
                      )}
                  </div>
                  <div className="member-profile-details">
                    <div className="member-name">
                      {userName ? userName : '-'}
                    </div>
                    <div className="member-mail-id">{email}</div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {roleName ? roleName : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {phone ? (
                  <a className="phone-link" href={`tel:${phone}`}>
                    {formatPhoneNumber(phone)}
                  </a>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td action-col"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Tooltip title="Email">
                  <Button
                    className="btn-circle btn-secondary"
                    variant="contained"
                  >
                    <a
                      className="email-link"
                      href={`mailto:${email}?subject=Buy Right Application Inquiry for ${memberName}`}
                    >
                      <i className="fas fa-envelope" />
                    </a>
                  </Button>
                </Tooltip>
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType="Vendors" />
      )}
    </div>
  );
};

export default VendorTable;
