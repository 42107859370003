import {
  baseUrl,
  MEMBER_REBATES_QUARTER_LIST_ERROR,
} from '../../../constants';

export const getMemberRebatesQuarterList = async ({ quarter, year }) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch( // TODO replace with Axios instance
      `${baseUrl}/member-rebate-documents-records?quarter=${quarter}&year=${year}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw MEMBER_REBATES_QUARTER_LIST_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw MEMBER_REBATES_QUARTER_LIST_ERROR;
  }
};
