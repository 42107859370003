import { FC, useState } from 'react';
import { AssignUserFilter } from './AssignUserFilter';
import { AssignUserTable } from './AssignUserTable';
import { getPaginatedData } from '../../../../../utils';
import { TablePagination } from '@material-ui/core';

const gridHeader = [
  {
    label: '',
    value: '',
    sortKey: '',
    xs: 12,
    lg: 1,
  },
  {
    label: 'User',
    value: 'userName',
    sortKey: 'userName',
    xs: 12,
    lg: 7,
  },
  {
    label: 'Role',
    value: 'mobile',
    sortKey: 'mobile',
    xs: 12,
    lg: 4,
  },
];

export const AssignNewUserModal: FC<any> = ({
  vendorDCList,
  onChangeVendorUserAssignDC,
  vendorUserAssign: {
    errorDistributionCenter,
    selectedUsers,
    selectedUsersRole,
  },
  unassignedVendorUsers,
  onSelecteAssignUser,
  handleUserRoleChange,
}) => {
  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState<string | null>(null);
  const [sortType, updateSortType] = useState('ASC');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const setSortKey = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  const filteredData =
    searchText && unassignedVendorUsers.length > 0
      ? unassignedVendorUsers.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : unassignedVendorUsers;

  const sortedData =
    sortKey && sortType && filteredData.length > 0
      ? filteredData &&
        filteredData.sort((a: any, b: any) => {
          if (sortType === 'DESC') {
            return b[sortKey]?.localeCompare(a[sortKey]);
          }
          return a[sortKey]?.localeCompare(b[sortKey]);
        })
      : filteredData;

  const modifiedList = getPaginatedData(sortedData, rowsPerPage, page);

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt((event as any).target.value, 10));
    setPage(0);
  };

  return (
    <div className="table-wrapper">
      <AssignUserFilter
        searchText={searchText}
        setSearchText={setSearchText}
        vendorDCList={vendorDCList}
        onChangeVendorUserAssignDC={onChangeVendorUserAssignDC}
        errorDistributionCenter={errorDistributionCenter}
      />

      <AssignUserTable
        gridHeader={gridHeader}
        gridData={modifiedList ? modifiedList : []}
        setSortKey={setSortKey}
        sortType={sortType}
        onSelecteAssignUser={onSelecteAssignUser}
        selectedUsers={selectedUsers}
        handleUserRoleChange={handleUserRoleChange}
        selectedUsersRole={selectedUsersRole}
        selectedMemberId={undefined}
        isEditModal={undefined}
      />

      {filteredData.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filteredData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  );
};
