import { sideMenuConfiguration } from '../constants/sideMenuConfiguration';
import store from '../store';
import {
  first,
  filter,
  find,
  includes,
  isEmpty,
  mapValues,
  orderBy,
  values,
} from 'lodash';
import {
  sideMenuAccess,
  memberMenuOrder,
  brAdminMenuOrder,
  vendorMenuOrder,
} from './sideMenuConfiguration';
import { elementAccess } from './uiElementsConfiguration';
class AuthorizationService {
  getTopRoles = () => {
    const { user } = store.getState();
    const { memberusers, vendorusers, isBRAdmin } = user || {};
    const topRole = {
      memberRole: first(orderBy(memberusers, 'roleid', 'desc')),
      vendorRole: first(orderBy(vendorusers, 'roleid', 'desc')),
      isBRAdmin,
    };
    return topRole;
  };

  mapOrder = (array: any[], order: any, key: string | number) => {
    array.sort(function (a, b) {
      var A = a[key],
        B = b[key];

      if (order.indexOf(A) > order.indexOf(B)) {
        return 1;
      } else {
        return -1;
      }
    });

    return array;
  };

  getUserRoleMenus = (topRoles: any) => {
    if (topRoles.isBRAdmin) return brAdminMenuOrder;
    if (!isEmpty(topRoles.vendorRole) && topRoles.vendorRole.id !== null)
      return vendorMenuOrder;
    if (!isEmpty(topRoles.memberRole)) return memberMenuOrder;
    return [];
  };

  getRoleBasedMenu = (filteredMenu: any[], loggedUserMenu: any[]) => {
    const menus = filteredMenu.reduce(
      (accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.menuId]: currentValue,
      }),
      {},
    );
    const result = loggedUserMenu.map((key) => menus[key]);
    return result;
  };

  getAuthorizedSideMenu = () => {
    const topRoles = this.getTopRoles();
    const loggedUserMenu = this.getUserRoleMenus(topRoles);
    if (topRoles.isBRAdmin) {
      return sideMenuConfiguration;
    } else {
      const filteredMenu = filter(sideMenuConfiguration, (menuItem) => {
        const currentPageAuthorizedUsers = values(
          mapValues(
            find(sideMenuAccess, ['menuId', menuItem.menuId])?.users,
            'roleId',
          ),
        );
        if (
          (topRoles.memberRole &&
            includes(currentPageAuthorizedUsers, topRoles.memberRole.roleid)) ||
          (topRoles.vendorRole &&
            includes(currentPageAuthorizedUsers, topRoles.vendorRole.roleid))
        ) {
          return true;
        }
        return false;
      });
      const roleBasedMenu = this.getRoleBasedMenu(filteredMenu, loggedUserMenu);
      // console.log(roleBasedMenu)
      const authorizedRoleBasedMenu = roleBasedMenu.map((data:any) => {
        if(data?.subMenu?.length > 0){
          return {...data, subMenu : data.subMenu.filter((y:any) => y.menuId !== "userDirectory" && y.menuId !== "userAnalytics") }           
        }else{
          return data
        }
      })
      return authorizedRoleBasedMenu;
    }
  };

  isAuthorizedHTML = (elementId: string) => {
    const topRoles = this.getTopRoles();

    if (topRoles.isBRAdmin) {
      return true;
    } else {
      const currentElement = find(elementAccess, ['elementId', elementId]);
      if (currentElement) {
        if (
          topRoles.memberRole &&
          includes(currentElement.users, topRoles.memberRole.roleid)
        ) {
          return true;
        } else if (
          topRoles.vendorRole &&
          includes(currentElement.users, topRoles.vendorRole.roleid)
        ) {
          return true;
        }
      } else {
        return false;
      }
    }
  };

  isAuthorizedHTMLForMember = (elementId: string, memberId = '') => {
    const topRoles = this.getTopRoles();

    if (topRoles.isBRAdmin) {
      return true;
    } else {
      const currentElement = find(elementAccess, ['elementId', elementId]);
      if (currentElement) {
        if (topRoles.memberRole) {
          const currentMemberDetails = this.getRoleForMember(memberId);
          if (
            currentMemberDetails &&
            includes(currentElement.users, currentMemberDetails.roleid)
          ) {
            return true;
          }
        }
        return false;
      } else {
        return false;
      }
    }
  };

  getRoleForMember = (id: string) => {
    const { user } = store.getState();
    const { memberusers } = user;
    const memberRoleDetails =
      (memberusers && find(memberusers, ['memberid', id])) || null;
    return memberRoleDetails;
  };
}

export default new AuthorizationService();
