import {
  GET_STATE_LISTS,
  UPDATE_STATE_LIST_ERROR,
  URL_USER_ROLE,
  UPDATE_USER_ROLE_LIST_ERROR,
} from "../constants";

export const getStateListAPI = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(`${GET_STATE_LISTS}`, {
      headers: new Headers({
        "x-access-token": user.accessToken,
        "Content-Type": "application/json",
      }),
    });
    if (!response.ok) throw UPDATE_STATE_LIST_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_STATE_LIST_ERROR;
  }
};

export const getUserRoleListAPI = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(`${URL_USER_ROLE}`, {
      headers: new Headers({
        "x-access-token": user.accessToken,
        "Content-Type": "application/json",
      }),
    });
    if (!response.ok) throw UPDATE_USER_ROLE_LIST_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_USER_ROLE_LIST_ERROR;
  }
};
