import {
    SHOW_COMMENT_MODAL_SPINNER,
    HIDE_COMMENT_MODAL_SPINNER,
    UPDATE_COMMENT_REPORTED_FLAG,
    UPDATE_COMMENT_REPORT_ID,
    UPDATE_COMMENT_REPORT_FLAG,
    UPDATE_COMMENT_ID,
    UPDATE_ACTIVE_DISCUSSION_COMMENTS,
    UPDATE_DISCUSSION_LOAD_MORE
} from "../../../constants";

import {
    reportComment,
    getDiscussionComments,
} from "../../../api/community";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showCommentModalSpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_COMMENT_MODAL_SPINNER
    });
};

export const hideCommentModalSpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_COMMENT_MODAL_SPINNER
    });
};


export const isCommentReported = (reportedflag) => async (dispatch) => {
    dispatch({
        type: UPDATE_COMMENT_REPORTED_FLAG,
        isAlreadyReportedComment: reportedflag
    });
};

export const handleCommentReport = (isCommentReport, selectedComment) => async (dispatch) => {
    const {id, flagReasonId} = selectedComment || {};
    if (flagReasonId !== null) {
        dispatch({
            type: UPDATE_COMMENT_REPORT_ID,
            commentReportId: flagReasonId
        });
        dispatch(isCommentReported(true));
    }
    dispatch({
        type: UPDATE_COMMENT_REPORT_FLAG,
        isCommentReport
    });
    dispatch({
        type: UPDATE_COMMENT_ID,
        commentId: id
    });
};

export const handleCommentReportChange = (commentReportId) => async (dispatch) => {
    if (commentReportId !== null) {
        dispatch({
            type: UPDATE_COMMENT_REPORT_ID,
            commentReportId: parseInt(commentReportId)
        });
    } else {
        dispatch({
            type: UPDATE_COMMENT_REPORT_ID,
            commentReportId: null
        });
    }
};

export const handleCommentReportDiscussion = () => async (dispatch, getState) => {
    const {user, communityDiscussion, communityChannel, communityCommentActions} = getState();
    const {id: userId} = user;
    const {activeDiscussion, pageNumber} = communityDiscussion;
    const {id: discussionId} = activeDiscussion;
    const {commentId, commentReportId} = communityCommentActions;
    dispatch(showCommentModalSpinner());
    try {
        const response = await reportComment({
            discussionId,
            commentId,
            userId,
            reasonId: commentReportId
        });
        const {success, message} = response;
        if (success) {
            const res = await getDiscussionComments({
                discussionId,
                pageNumber,
            });
            const {data, totalCount} = res;
            const isLoadMore = totalCount && totalCount > 5 ? true : false;
            dispatch({
                type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                activeDiscussionComments: data,
            });
            dispatch({
                type: UPDATE_DISCUSSION_LOAD_MORE,
                isLoadMore,
            });
            toast.success(message)
        }
        dispatch(hideCommentModalSpinner());
        dispatch(handleCommentReport(false, null));
        dispatch(isCommentReported(false))
    } catch (error) {
        dispatch(hideCommentModalSpinner());
    }
}