import { Grid } from '@material-ui/core';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HeaderBar from './HeaderBar';
import SideMenu from './SideMenu';
import { clearMemberView } from '../../actions/members';
import { vendorID } from '../../actions/vendors/vendorID';
import Tooltip from '@material-ui/core/Tooltip';
import serviceInterceptor from '../../services/ServiceInterceptor';
import serverApiV2Instance from '../../services/serverApiV2Instance';
import { trackPages } from '../../api/trackPages';

const mapStateToProps = (state: { user: any }) => {
  const { user } = state;
  return {
    user,
  };
};

const AdminLayoutContent: FC<any> = ({ user, children, clearMemberView, vendorID }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (!user?.id) {
      navigate('/login');
    }
  }, [user]);

  const [isDesktopMenu, setDesktopMenu] = useState(true);
  const [isMobileMenu, setMobileMenu] = useState(false);
  const mobileMenuClose = () => {
    setDesktopMenu(true);
    setMobileMenu(false);
  };

useEffect(() => {
  if(user?.isNotAssignedToMember === true){
    setDesktopMenu(false)
  }else{
    setDesktopMenu(true)
  }
},[user])

// Page Visit tracker

const location = useLocation();

const trackPageVisit = () =>{
  try {
    let user = JSON.parse(localStorage.getItem('user') || '{}');
    const path = location.pathname

    let visitedPage;

    switch (path) {
      case '/dashboard':
        visitedPage = 'Dashboard';
        break;
      case '/members':
      case '/member/':
        visitedPage = 'Members';
        break;
      case '/vendors/performance-foodservice':
        visitedPage = 'Performance FoodService Links';
        break;
      case '/vendors/branded-image':
        visitedPage = 'Branded Image Links';
        break;
      case '/documents':
        visitedPage = 'Documents';
        break;
      case '/manage-ads':
        visitedPage = 'Manage Ads';
        break;
      case '/rebates':
        visitedPage = user?.isBRAdmin ? 'Rebates Overview' : 'Rebates Receipts';
        break;
      case '/member-directory':
        visitedPage = 'Member Directory';
        break;
      case '/user-directory':
        visitedPage = 'User Directory';
        break;
      case '/analytics':
        visitedPage = 'User Analytics';
        break;
      default:
        visitedPage = 'Unknown Page';
    }
    trackPages(visitedPage)
  } catch (error) {
    console.log('Error in track page visit function ',error)
  }
}

useEffect(() =>{
  trackPageVisit()
},[location])

  return (
    <div>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <HeaderBar
            setDesktopMenu={setDesktopMenu}
            isDesktopMenu={isDesktopMenu}
            isMobileMenu={isMobileMenu}
            setMobileMenu={setMobileMenu}
            clearMemberView={clearMemberView}
          />
        </Grid>
      </Grid>
      <Grid className='container-warp'>
        <Grid
          className={
            !isDesktopMenu
              ? 'side-bar-container side-bar-container-desktop-collapse'
              : !isMobileMenu
              ? 'side-bar-container side-bar-container-mobile-close'
              : 'side-bar-container'
          }
        >
          {isMobileMenu && (
            <i
              className='fas fa-times side-menu-close-icon'
              onClick={mobileMenuClose}
            />
          )}
          <SideMenu
            mobileMenuClose={mobileMenuClose}
            isMobileMenu={isMobileMenu}
            vendorID={vendorID}
          />
          <div className='br-copyrights'>
            {isDesktopMenu ? (
              <>
                <span>©2021 BuyRight</span>
                <span>Ver 1.1.0</span>
              </>
            ) : (
              <Tooltip title='2021 BuyRight'>
                <a className='side-bar-item'>
                  <i className='fas fa-copyright' />
                </a>
              </Tooltip>
            )}
          </div>
          <div className='br-tos'>
            {isDesktopMenu ? (
              <span>
                <a href='https://buyrightpurchasing.com/tos/' target={'_blank'}>
                  Read our Terms of Service
                </a>
              </span>
            ) : (
              <Tooltip title='Read our Terms of Service'>
                <a
                  href='https://buyrightpurchasing.com/tos/'
                  className='side-bar-item'
                  target={'_blank'}
                >
                  <i className='fas fa-shield-alt'></i>
                </a>
              </Tooltip>
            )}
          </div>
        </Grid>
        <Grid className='body-container'>{children}</Grid>
      </Grid>
    </div>
  );
};

export const AdminLayout = connect(mapStateToProps, {clearMemberView,vendorID})(AdminLayoutContent);
export default AdminLayout;
