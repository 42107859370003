import { assign } from '@xstate/immer';
import { createMachine, sendParent } from 'xstate';
import { getMembers } from '../../api/members';
import { Members, Member } from '../../common';

export const membersMachine = createMachine(
  {
    context: {
      members: [],
      selectedMember: null,
    },
    tsTypes: {} as import('./membersMachine.typegen').Typegen0,
    schema: {
      context: {} as {
        members: Members;
        selectedMember: null | Member;
      },
      events: {} as
        | { type: 'done.invoke.loadMembers'; data: any }
        | { type: 'refresh' }
        | { type: 'updateSelectedMember'; memberId: number },
    },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'loadMembers',
          src: 'loadMembers',
          onDone: {
            actions: 'assignMembers',
            target: 'idle',
          },
          onError: 'error',
        },
        tags: ['loading'],
      },
      idle: {
        entry: 'sendParentSelectedMember',
        on: {
          refresh: 'loading',
          updateSelectedMember: {
            actions: ['assignSelectedMember', 'sendParentSelectedMember'],
            cond: 'canAssignSelectedMember',
          },
        },
      },
      error: {},
    },
  },
  {
    actions: {
      assignMembers: assign((context, event) => {
        const members: Members = event?.data?.data?.result || [];
        if (
          (!context.selectedMember ||
            !(
              members.filter(
                (member) => member.id === context.selectedMember?.id,
              ).length > 0
            )) &&
          members.length > 0
        ) {
          context.selectedMember = members?.[0];
        }
        context.members = members;
      }),
      assignSelectedMember: assign((context, event) => {
        context.selectedMember =
          context.members.filter(
            (member) => member.id === event.memberId,
          )?.[0] ?? context.selectedMember;
      }),
      sendParentSelectedMember: () => {
        if (process.env.NODE_ENV !== 'production') {
          console.info(
            'Implement sendParentSelectedMember to send the selected member id to the parent machine.',
          );
        }
      },
    },
    guards: {
      canAssignSelectedMember: (context, event) =>
        context.members.filter((member) => member.id === event.memberId)
          .length > 0,
    },
    services: {
      loadMembers: () => getMembers(),
    },
  },
);

export type MembersMachine = typeof membersMachine;

export default membersMachine;
