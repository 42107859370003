import { baseUrl, UPDATE_MEMBER_LIST_ERROR } from '../../constants';

export const getMembers = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const member = JSON.parse(sessionStorage.getItem('user') || '{}');
    let accessToken = Object.keys(member).length ? member.accessToken : user.accessToken
    const response = await fetch(`${baseUrl}/members`, {
      headers: new Headers({
        'x-access-token': accessToken,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw UPDATE_MEMBER_LIST_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_MEMBER_LIST_ERROR;
  }
};
