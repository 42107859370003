import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Loader } from '../../utils';
import { toast } from 'react-toastify';
import { CustomModal } from '../../components/CustomModal/index';


interface PasswordSettingsProps {
    clearChangePassword: () => void;
    onChangeOldPassword: (oldPassword: string) => void;
    onChangeNewPassword: (newPassword: string) => void;
    onChangeConfirmPassword: (confirmPassword: string) => void;
    handleChangePasswordShow: (elementKey: string) => void;
    onSubmitChangePassword: () => Promise<void>;
    changePasswordForm: {
        isLoading: boolean;
        oldPassword: string;
        newPassword: string;
        confirmPassword: string;
        errorOldPassword: any;
        errorNewPassword: any;
        errorConfirmPassword: any;
        messageForNewPWD: string;
        messageForConfirmPWD: string;
        passwordShowStatus: {
            confirmPassword: boolean;
            newPassword: boolean;
            oldPassword: boolean;
        };
        isRedirect: boolean;
    },
}

export const PasswordUpdate: FC<PasswordSettingsProps> = (props) => {
    const {
        clearChangePassword,
        onChangeOldPassword,
        onChangeNewPassword,
        onChangeConfirmPassword,
        handleChangePasswordShow,
        onSubmitChangePassword,
        changePasswordForm: {
            isLoading,
            oldPassword,
            newPassword,
            confirmPassword,
            errorOldPassword,
            errorNewPassword,
            errorConfirmPassword,
            messageForNewPWD,
            messageForConfirmPWD,
            passwordShowStatus,
            isRedirect,
        },
    } = props;
    const [showUpdatePasswordModal, setShowUpdatePasswordModal] = useState(true);
    const navigate = useNavigate();
    useEffect(() => {
        return () => {
            clearChangePassword();
        };
    }, []);
    if (isRedirect && isRedirect == true) {
        navigate('/dashboard');
    }

    const closeUpdatePasswordModal = () => {
        setShowUpdatePasswordModal(false);
        localStorage.clear();
        navigate('/login');
    }

    const isSaveButtonDisabled = errorOldPassword || errorNewPassword || errorConfirmPassword
        || oldPassword.length === 0 || newPassword.length === 0 || confirmPassword.length === 0;

    return (
        <CustomModal
            showModal={showUpdatePasswordModal}
            closeModal={closeUpdatePasswordModal}
            modalTitle={`Update Password`}
            isCancelButton={false}
            isSubmitButton={true}
            isSubmitText={'Save'}
            onSubmit={() => onSubmitChangePassword()}
            isLoading={isLoading}
            isButtonDisabled={isSaveButtonDisabled || isLoading}
        >
            <Grid container justifyContent="center" className="change-password">
                <Grid item xs={12} lg={12} className="change-password-right">
                    <div className="br-form-element">
                        <div className="relative-wrapper">
                            <Grid container spacing={5}>
                                <Grid item xs={12} lg={12} md={12} sm={12}>
                                    <Typography variant="caption" style={{ marginBottom: '1em' }}>
                                        <i className="fas fa-info-circle" />:
                                        Buy Right's latest version supports automatic payments. Please update your password to a minimum of 12 characters to meet our new security requirements.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={12} sm={12} lg={12}>
                                    <div className="input-with-icons w-50 pos-50">
                                        <TextField
                                            label="Old Password"
                                            variant="outlined"
                                            className="text-box"
                                            type={
                                                passwordShowStatus.oldPassword ? 'text' : 'password'
                                            }
                                            name="oldPassword"
                                            value={oldPassword}
                                            onChange={(e) => onChangeOldPassword(e.target.value)}
                                            required
                                            id={
                                                errorOldPassword ? 'standard-error' : 'outlined-basic'
                                            }
                                            error={errorOldPassword}
                                            helperText={
                                                errorOldPassword ? 'Please enter old password' : ''
                                            }
                                        />
                                        <i
                                            className={
                                                passwordShowStatus.oldPassword
                                                    ? 'fa fa-fw fa-eye field-icon'
                                                    : 'fa fa-fw fa-eye-slash field-icon'
                                            }
                                            onClick={() => handleChangePasswordShow('oldPassword')}
                                        ></i>
                                    </div>
                                </Grid>

                                <Grid item xs={12} lg={6} md={6} sm={6}>
                                    <div className="input-with-icons">
                                        <TextField
                                            label="New Password"
                                            variant="outlined"
                                            className="text-box"
                                            type={
                                                passwordShowStatus.newPassword ? 'text' : 'password'
                                            }
                                            name="newPassword"
                                            value={newPassword}
                                            onChange={(e) => onChangeNewPassword(e.target.value)}
                                            onBlur={(e) => onChangeNewPassword(e.target.value)}
                                            autoComplete="off"
                                            required
                                            id={
                                                errorNewPassword ? 'standard-error' : 'outlined-basic'
                                            }
                                            error={errorNewPassword}
                                            helperText={errorNewPassword && messageForNewPWD}
                                        />
                                        <i
                                            className={
                                                passwordShowStatus.newPassword
                                                    ? 'fa fa-fw fa-eye field-icon'
                                                    : 'fa fa-fw fa-eye-slash field-icon'
                                            }
                                            onClick={() => handleChangePasswordShow('newPassword')}
                                        ></i>
                                    </div>
                                </Grid>
                                <Grid item xs={12} lg={6} md={6} sm={6}>
                                    <div className="input-with-icons">
                                        <TextField
                                            label="Confirm Password"
                                            variant="outlined"
                                            className="text-box"
                                            type={
                                                passwordShowStatus.confirmPassword ? 'text' : 'password'
                                            }
                                            name="confirmPassword"
                                            value={confirmPassword}
                                            onChange={(e) => onChangeConfirmPassword(e.target.value)}
                                            onBlur={(e) => onChangeConfirmPassword(e.target.value)}
                                            autoComplete="off"
                                            required
                                            id={
                                                errorConfirmPassword
                                                    ? 'standard-error'
                                                    : 'outlined-basic'
                                            }
                                            error={errorConfirmPassword}
                                            helperText={
                                                errorConfirmPassword ? messageForConfirmPWD : ''
                                            }
                                        />
                                        <i
                                            className={
                                                passwordShowStatus.confirmPassword
                                                    ? 'fa fa-fw fa-eye field-icon'
                                                    : 'fa fa-fw fa-eye-slash field-icon'
                                            }
                                            onClick={() =>
                                                handleChangePasswordShow('confirmPassword')
                                            }
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </CustomModal>
    );
};
