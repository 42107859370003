import {
  UPDATE_VENDOR_USER_ASSIGN_SELECT_DC,
  UPDATE_VENDOR_USER_ASSIGN_DC_ERROR,
  UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS,
  CLEAR_VENDOR_USER_ASSIGN_MODAL,
  SHOW_VENDOR_USER_ASSIGN_SPINNER,
  HIDE_VENDOR_USER_ASSIGN_SPINNER,
  UPDATE_VENDOR_USER_ROLE
} from "../../constants";

const initialState = {
  spinner: false,
  uniqueItemKey: "userId",
  selectedDCid: 0,
  selectedUsers: [],
  errorDistributionCenter: false,
  jobTitle: "",
  selectedUsersRole: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_USER_ASSIGN_SELECT_DC:
      return {
        ...state,
        selectedDCid: action.selectedDCid,
      };
    case UPDATE_VENDOR_USER_ASSIGN_DC_ERROR:
      return {
        ...state,
        errorDistributionCenter: action.errorDistributionCenter,
      };
    case UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS:
      return {
        ...state,
        selectedUsers: action.selectedUsers,
      };
    case SHOW_VENDOR_USER_ASSIGN_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case HIDE_VENDOR_USER_ASSIGN_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case UPDATE_VENDOR_USER_ROLE:
      return {
        ...state,
        selectedUsersRole: action.selectedUsersRole,
      };
    case CLEAR_VENDOR_USER_ASSIGN_MODAL:
      return {
        ...state,
        spinner: false,
        selectedDCid: 0,
        selectedUsers: [],
        errorDistributionCenter: false,
        selectedUsersRole: []
      };
    default:
      return state;
  }
};