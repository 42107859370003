import Grid from '@mui/material/Grid';
import { FC } from 'react';

export const DashboardCollapsibleSectionHeader: FC<{
  title: string;
  opened: boolean;
  toggle: () => void;
}> = ({ opened, title, toggle }) => {
  return (
    <Grid
      container
      sx={{
        marginBottom: '0.25em',
      }}
      rowSpacing={1}
      columnSpacing={{ xs: 1 }}
      className="dashboard-head-container"
    >
      <Grid
        xs={11}
        alignItems="center"
        justifyItems="flex-start"
        item
        container
        alignContent="center"
      >
        <h2>{title}</h2>
      </Grid>
      <Grid container item xs={1} alignContent="flex-end" alignItems="center">
        <Grid
          xs={12}
          item
          className="dashboard-table-header-icon"
          alignItems="flex-end"
          onClick={toggle}
        >
          <i className={opened ? 'fas fa-chevron-up' : 'fas fa-chevron-down'} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DashboardCollapsibleSectionHeader;
