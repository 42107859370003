import { BRAdminRebatesTitle } from './section/BrAdmin/tab/BRAdminRebatesTitle';
import { BRAdminRebatesTabs } from './section/BrAdmin/tab/BRAdminRebatesTabs';
import { MemberRebatesTitle } from './section/Member/tab/MemberRebatesTitle';
import { MemberRebatesTabs } from './section/Member/tab/MemberRebatesTabs';
import { VendorRebatesTabs } from './section/Member/tab/VendorRebatesTabs';
import '../rebates/rebates.scss';
import '../../scss/styles.scss';
import { FC } from 'react';
import { useStore } from 'react-redux';
import { useMachine } from '@xstate/react';
import { rebatesPermissionsMachine } from './machines';
import { useNavigate } from 'react-router-dom';

export const RebatesMain: FC<any> = (props) => {
  const store = useStore<{ user: any, common: any }>();
  const navigate = useNavigate();
  const [state] = useMachine(rebatesPermissionsMachine, {
    actions: {
      redirectUser: () => navigate('/'),
    },
    services: {
      loadUserPermissions: () => new Promise<{
        user: {
          isBRAdmin: false;
          isMember: false;
          isVendor: false;
          memberusers: {
            roleid: number;
          }[];
          vendorusers: {
            roleid: number;
          }[];
        };
        rolesList: {
          description: string;
          id: number;
          name: string;
        }[];
      }>(async (resolve, reject) => {
        try {
          const storeState = await store.getState();
          const { user, common } = storeState;
          resolve({ user, rolesList: common?.rolesList || [] });
        } catch (error) {
          reject(error);
        }
      }),
    },
  });

  if (state.hasTag('loading')) {
    return null;
  }

  if (state.context.isBRAdmin) {
    return (
      <div className="rebates">
        <BRAdminRebatesTitle {...props} />
        <BRAdminRebatesTabs {...props} />
      </div>
    );
  } else if (state.context.isMember) {
    return (
      <div className="rebates">
        <MemberRebatesTitle {...props} />
        <MemberRebatesTabs {...props} />
      </div>
    );
  } else {
    return (
      <div className="rebates">
        <MemberRebatesTitle {...props} />
        <BRAdminRebatesTabs {...props} />
        {/* <VendorRebatesTabs {...props} /> */}
      </div>
    );
  }
};
