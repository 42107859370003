import { MemberUpdateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';
import { removePhoneNoFormat } from '../../utils/constants';
import { removeZipcodeFormat } from '../../utils';

export const updateMember = async ({
  parentId,
  id,
  name,
  street,
  city,
  state,
  zip,
  phone,
  mobile,
  joiningDate,
  canHandDeliver,
  avatar, // TODO Why is this not used?
  avatarFile,
}) => {
  try {
    console.log("canHandDeliver : ", canHandDeliver);
    const bodyFormData = new FormData();
    bodyFormData.append('parentId', parentId ? parentId : '');
    bodyFormData.append('name', name);
    bodyFormData.append('street', street);
    bodyFormData.append('city', city);
    bodyFormData.append('state', state);
    bodyFormData.append('zip', removeZipcodeFormat(zip));
    bodyFormData.append('phone', phone ? removePhoneNoFormat(phone) : null);
    bodyFormData.append('mobile', mobile ? removePhoneNoFormat(mobile) : null);
    bodyFormData.append('startDate', joiningDate);
    bodyFormData.append('canHandDeliver', canHandDeliver === true ? 1 : 0);
    bodyFormData.append('avatar', avatarFile);
    bodyFormData.append('contactFirstName', 'Admin');
    bodyFormData.append('contactLastName', '');

    const response = await serviceInterceptor.patch(
      `/members/${id}`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw MemberUpdateError;
    }
    return response.data;
  } catch (error) {
    throw MemberUpdateError;
  }
};
