import {GET_VENDORS_MEMBERS_API, UPDATE_VENDOR_MEMBERS_LIST_ERROR} from "../../constants";

export const getVendorMembersList = async (vendorID) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDORS_MEMBERS_API}${vendorID}/members`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_VENDOR_MEMBERS_LIST_ERROR;
    }
}

