import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';
import { createMachine } from 'xstate';
import { timeFrameIntervalMachine } from '../../../../../../machines';
import serviceInterceptor from '../../../../../../services/ServiceInterceptor';

export const dashboardOpportunityListMachine = createMachine(
  {
    tsTypes: {} as import('./dashboardOpportunityListMachine.typegen').Typegen0,
    schema: {
      context: {} as {
        page: number;
        pageSize: number;
        opportunityList: {
          rebateId: number;
          productNumber: string;
          productName: string;
          manufacturerName: string;
          packSize: string;
          rebateAmount: number;
          rebatePercent: number;
          rebateproductlistrecordId: number;
          notes: string;
          rebateType: string;
        }[];
        selectedQuarter: number;
        selectedYear: number;
      },
      events: {} as
        | { type: 'updateSelectedIntervalValue'; intervalValue: number }
        | { type: 'updateSelectedYear'; year: number }
        | { type: 'updatePageNumber'; pageNumber: number },
      services: {} as {
        getOpportunityList: {
          data: AxiosResponse<{
            data?: {
              rebateId: number;
              productNumber: string;
              productName: string;
              manufacturerName: string;
              packSize: string;
              rebateAmount: number;
              rebatePercent: number;
              rebateproductlistrecordId: number;
              notes: string;
              rebateType: string;
            }[];
            success: boolean;
            message: string;
            error?: any;
          }>;
        };
      },
    },
    id: 'dashboardOpportunityList',
    context: {
      page: 0,
      pageSize: 5,
      opportunityList: [],
      selectedQuarter: DateTime.now().quarter,
      selectedYear: DateTime.now().year,
    },
    invoke: [
      {
        id: 'timeFrameInterval',
        src: 'timeFrameInterval',
      },
    ],
    on: {
      updateSelectedIntervalValue: {
        actions: 'assignSelectedQuarter',
        target: '.loading',
      },
      updateSelectedYear: {
        actions: 'assignSelectedYear',
        target: '.loading',
      },
    },
    initial: 'loading',
    states: {
      loading: {
        tags: 'loading',
        invoke: {
          id: 'Get opportunity list',
          src: 'getOpportunityList',
          onDone: {
            actions: ['assignOpportunityList'],
            target: 'idle',
          },
          onError: {
            actions : ['resetOpportunityList'],
            target: 'idle',
          }
        },
      },
      idle: {
        on: {
          updatePageNumber: [
            { cond: 'pageNumberIsTooLow' },
            { cond: 'pageNumberIsTooHigh' },
            {
              actions: 'assignPageNumber',
            },
          ],
        },
      },
      error: {},
    },
  },
  {
    actions: {
      assignOpportunityList: assign((context, event) => {
        context.page = 0;
        const opportunityList = event.data.data?.data;
        context.opportunityList = Array.isArray(opportunityList)
          ? opportunityList
          : [];
      }),
      resetOpportunityList: assign((context, event) => {
        context.opportunityList = [];
      }),
      assignPageNumber: assign((context, event) => {
        context.page = event.pageNumber;
      }),
      assignSelectedQuarter: assign((context, event) => {
        context.selectedQuarter = event.intervalValue;
      }),
      assignSelectedYear: assign((context, event) => {
        context.selectedYear = event.year;
      }),
    },
    guards: {
      pageNumberIsTooLow: (_, event) => event.pageNumber < 0,
      pageNumberIsTooHigh: (context, event) =>
        event.pageNumber * context.pageSize >
        context.opportunityList.length * context.pageSize,
    },
    services: {
      getOpportunityList: (context) => 
        serviceInterceptor(
          `/rebate-product-list-records?quarter=${context.selectedQuarter}&year=${context.selectedYear}`,
        ),
      timeFrameInterval: timeFrameIntervalMachine.withContext({
        intervalTypes: ['quarter'],
        intervalValueLists: {
          month: [],
          quarter: Array.from(Array(4)).map((_, index) => ({
            label: `${index + 1}`,
            value: index + 1,
          })),
        },
        selectedIntervalType: 'quarter',
        selectedIntervalValue: DateTime.now().quarter,
        selectedYear: DateTime.now().year,
        years: Array.from(Array(5)).map(
          (_, years) => DateTime.now().minus({ years }).year,
        ),
      }),
    },
  },
);

export type DashboardOpportunityListMachine =
  typeof dashboardOpportunityListMachine;

export default dashboardOpportunityListMachine;
