import {
  baseUrl,
  MEMBER_REBATES_QUARTER_ERROR,
} from '../../../constants';

export const getMemberRebatesQuarters = async () => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const response = await fetch(`${baseUrl}/member-rebates/quarters`, {
      headers: new Headers({
        'x-access-token': user.accessToken,
        'Content-Type': 'application/json',
      }),
    });
    if (!response.ok) throw MEMBER_REBATES_QUARTER_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw MEMBER_REBATES_QUARTER_ERROR;
  }
};
