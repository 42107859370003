import {makeStyles} from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    membersRoot: {
        flexGrow: 1,
        "& .Mui-selected": {
            color: "#0F76C2",
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#0F76C2",
        },
        "& .MuiBox-root": {
            padding: "20px 0px",
        },
    },
    profileRoot: {
        position: "relative",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: "100%",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
    icon: {
        color: "#3f51b5",
        fontSize: "1.5rem"
    },
    styles: {
        marginBottom: "7px"
    },
    dropdown: {
        position: "absolute",
        top: 70,
        right: 5,
        zIndex: 1,
        padding: theme.spacing(1),
        backgroundColor: theme.palette.background.paper,
    },
}));