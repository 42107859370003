import { FC, useState } from 'react';
import { RebateReceiptCounts } from '../../Member/tab/RebateReceiptCounts';
import { RebatesReceiptMain } from '../../Member/components/RebateReceipts/RebatesReceiptMain';
import { EarnedRebatesMain } from '../EarnedRebatesMain';
import { Overview } from '../Overview';
import { ProductRebates } from '../../../components/modal/ProductRebates';
import { MemberRebates } from '../../../components/modal/MemberRebates';
import { EditMemberRebates } from '../../../components/modal/EditMemberRebates';
import { ExportProductRebates } from '../../../components/modal/ExportProductRebates';
import { ExportMemberRebates } from '../../../components/modal/ExportMemberRebates';
import { Notes } from '../../../components/modal/Notes';
import { RebateAction } from '../../../components/modal/RebateAction';
import { RebatesOptionsTabPanel } from '../../../components/RebatesOptionsTabPanel';
import { a11yProps } from '../../../../../utils';
import '../../../rebates.scss';
import '../../../../../scss/styles.scss';
import { ClickAwayListener, Tab, Tabs } from '@material-ui/core';
import Opportunities from '../../../Opportunities';
import { trackPages } from '../../../../../api/trackPages';

export const BRAdminRebatesTabs: FC<any> = (props) => {
  const {
    rebates,
    updateActiveTab,
    productRebates,
    memberRebates: {
      isMemberRebateExportModal,
      memberExportQuarter,
      memberExportYear,
      memberQuarterList,
    },
    rebateReceipts: {
      isRebateReceiptAction,
      selectedRebate,
      rebateReceiptMode,
    },
    showProductRebateExportModal,
    showNotesEditModal,
    showNotesModal,
    updateSelectedProduct,
    handleNotesChange,
    closeRebateNotesModal,
    updateProductRebateNotes,
    handleExportQuarter,
    handleExportYear,
    onExportProductRebates,
    closeRebateExportModal,
    handleRequestRebateType,
    showMemberRebateExportModal,
    handleMemberExportQuarter,
    handleMemberExportYear,
    onExportMemberRebates,
    onExportMemberRebatesByQuarterYear,
    updateRebateActionModal,
    handleRebateReceiptMode,
    handleConfirmRebateReceipt,
    showClaimRebateExportModal,
  } = props;

  const {
    isProductRebateExportModal,
    isNotesModal,
    isNotesEditModal,
    selectedProduct,
    exportQuarter,
    exportYear,
    requestRebateType,
    quarterList,
  } = productRebates;

  const { rebatesModalSpinner, activeTab } = rebates;

 
  const tabMenuLabel = [
    {
      label: 'Overview',
      value: 0,
    },
    {
      label: 'Rebate Receipts',
      value: 1,
    },
    {
      label: 'Opportunities',
      value: 2,
    },
    {
      label: 'Earned Rebates',
      value: 3,
    },
  ];
  const handleChange = (_: any, newValue: any) => {
    const foundItem:{label:String,value:number}|any = tabMenuLabel.find(item => item.value === newValue);
    trackPages(foundItem.label === 'Opportunities' ? "Rebates Opportunities" : foundItem.label)
    updateActiveTab(newValue);
    handleClickAway();
  };
  const [tabMenuOpen, setTabMenuOpen] = useState(false);
  const tabResponsive = () => {
    setTabMenuOpen(!tabMenuOpen);
  };
  const handleClickAway = () => {
    setTabMenuOpen(false);
  };
  return (
    <div className="rebates-br-admin">
      <div className="container">
        <div className="br-tab">
          <div className={tabMenuOpen ? 'tab-ul tab-ul-open' : 'tab-ul'}>
              <button
                className="tab-button"
                tabIndex={0}
                onClick={tabResponsive}
              >
                {tabMenuLabel[activeTab].label}
                <i className="fas fa-caret-down" />
              </button>
            <Tabs
              value={activeTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Overview" {...a11yProps(0)} />
              <Tab label="Rebate Receipts" {...a11yProps(1)} />
              <Tab label="Opportunities" {...a11yProps(2)} />
              <Tab label="Earned Rebates" {...a11yProps(3)} />
            </Tabs>
          </div>

          <RebatesOptionsTabPanel value={activeTab} index={0}>
            <Overview {...props} />
          </RebatesOptionsTabPanel>

          <RebatesOptionsTabPanel value={activeTab} index={1}>
            <RebateReceiptCounts {...props} />
            <RebatesReceiptMain {...props} activeTab={activeTab} />
          </RebatesOptionsTabPanel>

          <RebatesOptionsTabPanel value={activeTab} index={2}>
            <Opportunities isBRAdmin
              showNotesModal={showNotesModal}
              updateSelectedProduct={updateSelectedProduct}
            />
          </RebatesOptionsTabPanel>

          <RebatesOptionsTabPanel value={activeTab} index={3}>
            <EarnedRebatesMain {...props} />
          </RebatesOptionsTabPanel>

          <ProductRebates {...props} />

          <MemberRebates {...props} />

          <EditMemberRebates {...props} />

          {isProductRebateExportModal && (
            <ExportProductRebates
              openModal={isProductRebateExportModal}
              setOpenModal={showProductRebateExportModal}
              handleExportQuarter={handleExportQuarter}
              exportQuarter={exportQuarter}
              exportYear={exportYear}
              handleExportYear={handleExportYear}
              onExportProductRebates={onExportProductRebates}
              rebatesModalSpinner={rebatesModalSpinner}
              closeRebateExportModal={closeRebateExportModal}
              handleRequestRebateType={handleRequestRebateType}
              requestRebateType={requestRebateType}
              quarterList={quarterList}
            />
          )}

          {isMemberRebateExportModal && (
            <ExportMemberRebates
              openModal={isMemberRebateExportModal}
              setOpenModal={showMemberRebateExportModal}
              handleExportQuarter={handleMemberExportQuarter}
              exportQuarter={memberExportQuarter}
              exportYear={memberExportYear}
              handleExportYear={handleMemberExportYear}
              onExportMemberRebates={onExportMemberRebates}
              rebatesModalSpinner={rebatesModalSpinner}
              closeRebateExportModal={showMemberRebateExportModal}
              quarterList={memberQuarterList}
            />
          )}

          <Notes
            openModal={isNotesModal}
            isNotesEditModal={isNotesEditModal}
            showNotesEditModal={showNotesEditModal}
            updateSelectedProduct={updateSelectedProduct}
            selectedProduct={selectedProduct}
            handleNotesChange={handleNotesChange}
            updateProductRebateNotes={updateProductRebateNotes}
            closeRebateNotesModal={closeRebateNotesModal}
            rebatesModalSpinner={rebatesModalSpinner}
          />

          <RebateAction
            openModal={isRebateReceiptAction}
            updateRebateActionModal={updateRebateActionModal}
            selectedRebate={selectedRebate}
            handleRebateReceiptMode={handleRebateReceiptMode}
            rebateReceiptMode={rebateReceiptMode}
            handleConfirmRebateReceipt={handleConfirmRebateReceipt}
            rebatesModalSpinner={rebatesModalSpinner}
          />
        </div>
      </div>
    </div>
  );
};

export default BRAdminRebatesTabs;
