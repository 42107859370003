import { Grid } from '@material-ui/core';
import { FC } from 'react';
import { gridHeader } from './util/constants';

export const EBrandTableRow: FC<{
  legacy?: string | number | null;
  tier1?: string | number | null;
  tier2?: string | number | null;
  tier3?: string | number | null;
  tier4?: string | number | null;
  totalPurchase?: string | number | null;
}> = ({ legacy, tier1, tier2, tier3, tier4, totalPurchase }) => (
  <Grid container spacing={0} className="tr">
    <Grid
      className="td xs-primary"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header grid-header-no-border">
            {gridHeader[0].label}
          </div>
          {gridHeader[0].subLabel ? (
            <div className="grid-header">{gridHeader[0].subLabel}</div>
          ) : null}
        </div>

        <div className="table-data-text">
          {totalPurchase ? totalPurchase : '-'}
        </div>
      </div>
    </Grid>
    <Grid
      className="td xs-primary"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header">{gridHeader[1].label}</div>
          {gridHeader[1].subLabel ? (
            <div className="grid-header">{gridHeader[1].subLabel}</div>
          ) : null}
        </div>
        <div className="table-data-text">{legacy ? legacy : '-'}</div>
      </div>
    </Grid>
    <Grid
      className="td xs-primary"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header">{gridHeader[2].label}</div>
          {gridHeader[2].subLabel ? (
            <div className="grid-header">{gridHeader[2].subLabel}</div>
          ) : null}
        </div>
        <div className="table-data-text">{tier1 ? tier1 : '-'}</div>
      </div>
    </Grid>
    <Grid
      className="td xs-primary table-data-nowrap"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header">{gridHeader[3].label}</div>
          {gridHeader[3].subLabel ? (
            <div className="grid-header">{gridHeader[3].subLabel}</div>
          ) : null}
        </div>
        <div className="table-data-text">{tier2 ? tier2 : '-'}</div>
      </div>
    </Grid>
    <Grid
      className="td xs-primary"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header">{gridHeader[4].label}</div>
          {gridHeader[4].subLabel ? (
            <div className="grid-header">{gridHeader[4].subLabel}</div>
          ) : null}
        </div>
        <div className="table-data-text">{tier3 ? tier3 : '-'}</div>
      </div>
    </Grid>
    <Grid
      className="td xs-primary"
      item
      xs={12}
      lg={2}
      container
      direction="row"
      alignItems="center"
    >
      <div className="table-data-box">
        <div className="brand-header mobile-table-label ebrand-header">
          <div className="grid-header">{gridHeader[5].label}</div>
          {gridHeader[5].subLabel ? (
            <div className="grid-header">{gridHeader[5].subLabel}</div>
          ) : null}
        </div>
        <div className="table-data-text">{tier4 ? tier4 : '-'}</div>
      </div>
    </Grid>
  </Grid>
);

export default EBrandTableRow;
