import { Fade, Grid, LinearProgress, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie } from 'recharts';
import { ActorRef } from 'xstate';
import { AnimateCount } from '../../../utils';

export const StandardPaymentTermsChart: FC<{
  actor: ActorRef<any, any>; // TODO Temporary fix
  id?: string;
}> = ({ actor, id }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [state] = useActor(actor);
  let pieProps = {
    isAnimationActive: false,
    data: [{ name: 'No Data Found', value: 1, fill: '#d6d6d6', key: '' }],
    cx: '50%',
    cy: '50%',
    innerRadius: '75%',
    outerRadius: '100%',
    fill: '#339933',
    dataKey: 'value',
    labelLine: false,
    minAngle: undefined as undefined | number,
    startAngle: undefined as undefined | number,
    className: undefined as undefined | string,
  };
  const selectedTerms =
    state.context.paymentTerms.filter(
      (
        paymentTerm: any, // TODO Temporary fix
      ) => paymentTerm.name === state.context.selectedPaymentTermName,
    )?.[0] ?? null;

  if (selectedTerms) {
    pieProps = {
      ...pieProps,
      labelLine: true,
      minAngle: 5,
      startAngle: -270,
      isAnimationActive: true,
      className: 'dashboard-pie-chart-box-chart',
    };
    if (
      selectedTerms.withinTermsAmount > 0 ||
      selectedTerms.outOfTermsAmount > 0
    ) {
      pieProps.data = [
        {
          name: 'Within Terms',
          value: selectedTerms.withinTermsAmount,
          key: 'within',
          fill: '#0f76c2',
        },
        {
          name: 'Outside Terms & Unpaid Invoices',
          value: selectedTerms.outOfTermsAmount,
          key: 'outside',
          fill: '#e15759',
        },
      ];
    }
  }

  return (
    <Grid
      id={id}
      container
      spacing={3}
      className="border-divider dashboard-section-height"
    >
      {!matches && (
        <Grid
          xs={12}
          md={9}
          alignItems="center"
          justifyContent="flex-start"
          item
          container
          alignContent="center"
        >
          <h3>Terms</h3>
          {selectedTerms && (
            <Grid container className="legend-label" spacing={2}>
              <Grid item sm={2} xs={12}>
                <div className="icon-within-terms" />
                <div className="text-within-terms">Within Terms</div>
              </Grid>
              <Grid item sm={4} xs={12}>
                <div className="icon-outside-terms" />
                <div className="text-outside-terms">
                  Outside Terms & Unpaid Invoices
                </div>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <Grid
        xs={12}
        md={3}
        item
      >
        <div className="dashboard-pie-chart">
          <ResponsiveContainer height={200}>
            <PieChart className="dashboard-pie-chart-box">
              <Pie
                key={`${selectedTerms?.withinTermsAmount} ${selectedTerms?.outOfTermsAmount}`}
                {...pieProps}
              />
            </PieChart>
          </ResponsiveContainer>
          <Fade in={state.hasTag('loading')}>
            <LinearProgress />
          </Fade>
          {selectedTerms && (
            <div className="terms-count-wrapper">
              {typeof selectedTerms?.withinTermsAmount === 'number' && (
                <span className="within-terms-count">
                  {Number(selectedTerms?.withinTermsAmount).toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                    },
                  )}
                </span>
              )}
              {typeof selectedTerms?.outOfTermsAmount === 'number' && (
                <span className="outside-terms-count">
                  {Number(selectedTerms?.outOfTermsAmount).toLocaleString(
                    'en-US',
                    {
                      style: 'currency',
                      currency: 'USD',
                    },
                  )}
                </span>
              )}
            </div>
          )}
        </div>
      </Grid>
      <Grid
        xs={12}
        md={9}
        alignItems="center"
        justifyContent="flex-start"
        item
        container
        alignContent="center"
      >
        {matches && (
          <>
            <Grid container xs={12}>
              <h3>Terms</h3>
            </Grid>
            {selectedTerms && (
              <Grid container className="legend-label" spacing={2}>
                <Grid item sm={2} xs={12}>
                  <div className="icon-within-terms" />
                  <div className="text-within-terms">Within Terms</div>
                </Grid>
                <Grid item sm={4} xs={12}>
                  <div className="icon-outside-terms" />
                  <div className="text-outside-terms">
                    Outside Terms & Unpaid Invoices
                  </div>
                </Grid>
              </Grid>
            )}
          </>
        )}
        {selectedTerms ? (
          <>
            {!state.context.selectedIntervalType && (
              <p>
                The terms you are viewing are from today’s date to 6 months
                back.
              </p>
            )}
            <p>
              Increase your rebates by paying invoices on time. You earn a 0.50
              % rebate on all items purchased from RFS/PFS on invoices paid
              within terms. You can also earn additional % Rebates on RFS/PFS
              Private Label (E-Brand) items paid within terms depending on your
              Average Drop Size, and the percentage of E-Brands you purchase per
              quarter.
            </p>
          </>
        ) : (
          <p>No terms available.</p>
        )}
      </Grid>
    </Grid>
  );
};

export default StandardPaymentTermsChart;
