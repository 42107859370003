import { connect } from 'react-redux';
import { CommunityMain } from './CommunityMain';
import {
  updateQuestionDetail,
  updateActiveDiscussionData,
  getCommunityData,
  editCommunityCategory,
  handleToggleChange,
  updateNewChannel,
  updateEditChannel,
  handleChannelName,
  handleChannelDesc,
  clearCommunityChannelModal,
  handleCreateChannel,
  handleUpdateChannel,
  updateActiveDiscussion,
  getChannelDiscussion,
  loadMoreComments,
  handleDiscussionTitle,
  handleDiscussionDesc,
  createNewDiscussion,
  clearCommunityDiscussionModal,
  handleNewDiscussionModal,
  handleNewComment,
  onCommentCancel,
  onAddNewComment,
  handleDiscussionLike,
  updatePageNumber,
  handleCommentLike,
  handleCommentDisLike,
  deleteDiscussionComment,
  updateDeleteCommentFlag,
  handleEditDiscussion,
  updateDiscussionDetails,
  handleEditDiscussionFlag,
  handleReport,
  handleReportReason,
  getReportReasonList,
  handleReportDiscussion,
  isReported,
  handleCommentReport,
  handleCommentReportChange,
  isCommentReported,
  handleCommentReportDiscussion,
  updateArchiveDiscussion,
  handleArchiveDiscussion,
  handleArchiveModal,
  handleCommentEdit,
  onUpdateCommentContent,
} from '../../actions/community';

const mapStateToProps = (state: any) => {
  const {
    communityChannel,
    communityDiscussion,
    communityComments,
    communityDiscussionActions,
    communityCommentActions,
    user,
  } = state;

  return {
    communityChannel,
    communityDiscussion,
    communityComments,
    communityDiscussionActions,
    communityCommentActions,
    user,
  };
};

export const Community = connect(mapStateToProps, {
  updateQuestionDetail,
  updateActiveDiscussionData,
  getCommunityData,
  editCommunityCategory,
  handleToggleChange,
  updateNewChannel,
  updateEditChannel,
  handleChannelName,
  handleChannelDesc,
  clearCommunityChannelModal,
  handleCreateChannel,
  handleUpdateChannel,
  updateActiveDiscussion,
  getChannelDiscussion,
  loadMoreComments,
  handleDiscussionTitle,
  handleDiscussionDesc,
  createNewDiscussion,
  clearCommunityDiscussionModal,
  handleNewDiscussionModal,
  handleNewComment,
  onCommentCancel,
  onAddNewComment,
  handleDiscussionLike,
  updatePageNumber,
  handleCommentLike,
  handleCommentDisLike,
  deleteDiscussionComment,
  updateDeleteCommentFlag,
  handleEditDiscussion,
  updateDiscussionDetails,
  handleEditDiscussionFlag,
  handleReport,
  handleReportReason,
  getReportReasonList,
  handleReportDiscussion,
  isReported,
  handleCommentReport,
  handleCommentReportChange,
  isCommentReported,
  handleCommentReportDiscussion,
  updateArchiveDiscussion,
  handleArchiveDiscussion,
  handleArchiveModal,
  handleCommentEdit,
  onUpdateCommentContent,
})(CommunityMain);

export default Community;
