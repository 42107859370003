import { FC } from "react";
import { ExportMemberAdminRebates } from "../../../../components/modal/ExportMemberAdminRebates";
import AuthorizationService from '../../../../../../authorization/AuthorizationService';

export const MemberActiveEarningsActions: FC<{
    handleMemberAdminExportQuarter: () => void;
    handleMemberAdminExportYear: () => void;
    onExportMemberAdminRebates: any;
    rebates: {
        rebatesModalSpinner: boolean;
    };
    memberAdminRebatesExportModal: any;
    memberAdminRebates: {
        isMemberAdminExport: boolean;
        exportQuarter: any;
        exportYear: any;
        //memberProductList: any;
    };
    memberProductList: any;
}> = ({ handleMemberAdminExportQuarter,
    handleMemberAdminExportYear,
    onExportMemberAdminRebates,
    rebates,
    memberAdminRebatesExportModal,
    memberAdminRebates,
    memberProductList,
}) => {
        const showExport = AuthorizationService.isAuthorizedHTML('earnedRebatesExport');
        return (
            <div className="header-filter-box-add">
                {showExport && <ExportMemberAdminRebates
                    handleMemberAdminExportQuarter={handleMemberAdminExportQuarter}
                    handleMemberAdminExportYear={handleMemberAdminExportYear}
                    onExportMemberAdminRebates={onExportMemberAdminRebates}
                    rebates={rebates}
                    memberAdminRebatesExportModal={memberAdminRebatesExportModal}
                    memberAdminRebates={memberAdminRebates}
                    memberProductList={memberProductList}
                />}
            </div>
        );
    };
