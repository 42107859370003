import { FC, Fragment } from 'react';
import {
  Chip,
  FormControlLabel,
  Icon,
  Radio,
  TextField,
} from '@material-ui/core';
import { AccountBalanceRounded, PersonRounded } from '@mui/icons-material';
import { useStyles } from '../../../../utils';
import Autocomplete from '@material-ui/lab/Autocomplete/Autocomplete';

const RenderIcon: FC<{ isVendor: boolean }> = ({ isVendor }) => {
  if (isVendor) {
    return <AccountBalanceRounded className="select-members-icon" />;
  } else {
    return <PersonRounded className="select-members-icon" />;
  }
};

export const SelectMembers: FC<any> = ({
  handleSelectMembers,
  handleSelectAllMembers,
  updateSelectedMembers,
  updateActiveMembers,
  documents: {
    isSelectMember,
    isAllMembers,
    membersList,
    documentDCList,
    selectedMembers,
    isEditDocument,
    selectMemberList,
  },
}) => {
  const classes = useStyles();
  const selectOptions = [...membersList, ...documentDCList];

  return (
    <>
      <h3>Access Level</h3>
      <div className="checkbox-document">
        <FormControlLabel
          value="end"
          control={
            <Radio
              color="primary"
              checked={isAllMembers}
              onChange={(e) => handleSelectAllMembers(e.target.checked)}
            />
          }
          label="All Members"
          disabled={isEditDocument}
          labelPlacement="end"
        />
      </div>

      <div className="checkbox-document">
        <FormControlLabel
          value="end"
          control={
            <Radio
              color="primary"
              checked={isSelectMember}
              onChange={(e) => handleSelectMembers(e.target.checked)}
            />
          }
          disabled={isEditDocument}
          label="Select Members"
          labelPlacement="end"
        />

        {isEditDocument ? (
          <div className="select-member-disable">
            <Autocomplete
              className="document-tags"
              multiple
              id="tags-outlined"
              options={
                selectMemberList && selectMemberList.length > 0
                  ? selectMemberList
                  : []
              }
              value={
                selectMemberList && selectMemberList.length > 0
                  ? selectMemberList
                  : ''
              }
              disabled={isEditDocument}
              getOptionLabel={(option) => option.name}
              renderOption={(option: any) => {
                return (
                  <Fragment>
                    <Icon>
                      <RenderIcon isVendor={option.isVendor ?? false} />
                    </Icon>
                    <span className="select-members-name">{option.name}</span>
                  </Fragment>
                );
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Members"
                  className="autocomplte-fullwidth"
                />
              )}
              renderTags={(options) => {
                return options.map((option) => (
                  <Fragment>
                    <Chip
                      size="medium"
                      disabled={isEditDocument}
                      className="select-members-icon-disabled"
                      avatar={
                        <RenderIcon isVendor={option.isVendor ?? false} />
                      }
                      label={option.name}
                      onDelete={() => {
                        const aciveMembers = selectedMembers.filter(
                          (entry: any) => entry !== option,
                        );
                        updateActiveMembers(aciveMembers);
                      }}
                    />
                  </Fragment>
                ));
              }}
            />
          </div>
        ) : (
          isSelectMember && (
            <Autocomplete
              noOptionsText={'No members found'}
              className="document-tags"
              multiple
              id="tags-outlined"
              options={selectOptions}
              value={selectedMembers}
              getOptionLabel={(option) => option.name}
              renderOption={(option: any) => {
                return (
                  <Fragment>
                    <Icon>
                      {<RenderIcon isVendor={option.isVendor ?? false} />}
                    </Icon>
                    <span className="select-members-name">{option.name}</span>
                  </Fragment>
                );
              }}
              filterSelectedOptions
              onChange={(event, value) => updateSelectedMembers(event, value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Members"
                  className="autocomplte-fullwidth"
                />
              )}
              renderTags={(options) => {
                return options.map((option) => (
                  <Fragment>
                    <Chip
                      size="medium"
                      className="select-members-icon"
                      avatar={
                        <RenderIcon isVendor={option.isVendor ?? false} />
                      }
                      label={option.name}
                      onDelete={() => {
                        const activeMembers = selectedMembers.filter(
                          (entry: any) => entry !== option,
                        );
                        updateActiveMembers(activeMembers);
                      }}
                    />
                  </Fragment>
                ));
              }}
            />
          )
        )}
      </div>
    </>
  );
};

export default SelectMembers;
