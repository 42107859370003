import React from "react";
import Grid from "@material-ui/core/Grid";
import ManageAdsCard from "./manageAdsCard";

export const ManageAdsList = (props) => {
  const {
    manageAdsList: { adsList },
  } = props;
  return (
    <Grid container spacing={4}>
      {adsList &&
        adsList.map((item) => {
          return (
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <ManageAdsCard item={item} {...props} />
            </Grid>
          );
        })}
    </Grid>
  );
};
