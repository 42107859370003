import { FC, useEffect } from "react";
import DialogWindow from "../../../../components/DialogWindow";
import SelectBox from "../../../../components/SelectBox";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";

const dialogConfiguration = {
  title: "New User",
  isCancel: true,
  submitText: "Create & Invite",
  isBack: true,
  backText: "Back to Assign",
};

const editDialogConfiguration = {
  title: "Edit User",
  isCancel: true,
  submitText: "Update User",
  isBack: true,
  backText: "Back to Assign",
};

export const AddSalesPersonnelModal: FC<any> = ({
  openDialog,
  closeDialog,
  vendorDCList,
  getVendorDC,
  vendorUserCreate: {
    spinner,
    isEdit,
    userId,
    firstName,
    lastName,
    email,
    jobTitle,
    phoneNo,
    selectedRole,
    selectedDC,
    errorFirstname,
    errorLastname,
    errorJobTitle,
    errorEmail,
    errorPhoneNo,
    errorRole,
    errorDC,
  },
  onChangeVendorUserFirstname,
  onChangeVendorUserLastname,
  onChangeVendorUserEmail,
  onChangeVendorUserJobtitle,
  onChangeVendorUserPhone,
  onChangeVendorUserCreateRole,
  onChangeVendorUserCreateDC,
  onAddNewVendorUser,
  onEditVendorUser,
  rolesList,
  handleBackToList,
  send,
}) => {
  
  const isLoading = spinner ? true : false;
  const isAddMode = isEdit ? false : true;

  useEffect(() => {
    getVendorDC();
  }, []);

  const handleSubmit = () => {
    if (userId) {
      onEditVendorUser();
    } else {
      onAddNewVendorUser();
    }
  }

  const handleSelectRoleChange = (e: any) => {
    onChangeVendorUserCreateRole(e.target.value)
  }
  const handleSelectDCChange = (e: any) => {
    onChangeVendorUserCreateDC(e.target.value)
  };

  return (
    <div className="vendor-assign-content">
      <DialogWindow
        openDialog={openDialog}
        dialogConfiguration={isAddMode ? dialogConfiguration : editDialogConfiguration}
        handleClose={closeDialog}
        handleSubmit={() => { 
          handleSubmit();
          setTimeout(() => send({
            type: 'addQueryStringValue',
            key: 'orderBy',
            value: 'id',
          }), 1000);
        }}
        handleBackToList={handleBackToList}
        spinnerEnabled={spinner}
      >
        <div className="br-form-element">
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={errorFirstname}
                id={errorFirstname ? "standard-error" : "outlined-basic"}
                label="First name"
                variant="outlined"
                className="text-box"
                helperText={errorFirstname ? "Please enter name" : ""}
                onChange={(e)=>onChangeVendorUserFirstname(e.target.value)}
                disabled={!isAddMode || isLoading}
                required
                value={firstName}
              />
            </Grid>
            <Grid item xs>
              <TextField
                error={errorLastname}
                id={errorLastname ? "standard-error" : "outlined-basic"}
                label="Last name"
                variant="outlined"
                className="text-box"
                helperText={errorLastname ? "Please enter last name" : ""}
                onChange={(e) => onChangeVendorUserLastname(e.target.value)}
                disabled={!isAddMode || isLoading}
                required
                value={lastName}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <TextField
                error={errorEmail}
                id={errorEmail ? "standard-error" : "outlined-basic"}
                label="Email"
                variant="outlined"
                className="text-box"
                helperText={errorEmail ? "Please enter email" : ""}
                onChange={(e) => onChangeVendorUserEmail(e.target.value)}
                disabled={!isAddMode || isLoading}
                required
                value={email}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                error={errorPhoneNo}
                id={errorPhoneNo ? "standard-error" : "outlined-basic"}
                label="Phone"
                variant="outlined"
                className="text-box"
                helperText={errorPhoneNo ? "Please enter phone number" : ""}
                onChange={(e) => onChangeVendorUserPhone(e.target.value)}
                disabled={!isAddMode || isLoading}
                required
                value={phoneNo}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs>
              <SelectBox
                labelName={"Role"}
                inputValue={selectedRole}
                handleSelectChange={handleSelectRoleChange}
                optionList={rolesList || null}
                errorState={errorRole}
                errorMessage={"Please select"}
                isLoading={isLoading}
              />
            </Grid>
            <Grid item xs>
              <TextField
                error={errorJobTitle}
                id={errorJobTitle ? "standard-error" : "outlined-basic"}
                label="Job Title"
                variant="outlined"
                className="text-box"
                helperText={errorJobTitle ? "Please enter job title" : ""}
                onChange={(e) => onChangeVendorUserJobtitle(e.target.value)}
                disabled={isLoading}
                required={selectedRole == 6}
                value={jobTitle}
              />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={6}>
              <SelectBox
                labelName={"Distribution Center"}
                inputValue={selectedDC}
                handleSelectChange={handleSelectDCChange}
                optionList={vendorDCList || null}
                errorState={errorDC}
                errorMessage={"Please select"}
                isLoading={isLoading}
              />
            </Grid>
          </Grid>
        </div>
      </DialogWindow>
    </div>
  );
};
