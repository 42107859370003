import { FC } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import { ActorRefFrom } from 'xstate';
import { RebateReceiptCheckTypeItemMachine } from './machines';
import { useActor } from '@xstate/react';

export const CheckMode: FC<{
  actor: ActorRefFrom<RebateReceiptCheckTypeItemMachine>;
}> = ({ actor }) => {
  const [state, send] = useActor(actor);
  return (
    <div className="check-tab">
      {state.context.canHandDeliver && (
        <FormControlLabel
          className={
            state.context.handDeliver
              ? 'check-deliver-box highlight'
              : 'check-deliver-box'
          }
          control={
            <Checkbox
              disabled={!state.context.canHandDeliver}
              checked={state.context.handDeliver}
              onChange={() => send('toggleHandDeliver')}
              name="json"
            />
          }
          label="Hand Deliver"
        />
      )}
      {state.context.handDeliver ? (
        <div className="deliver-notification">
          <i className="far fa-hand-point-right" />A Buy Right staff member will
          hand deliver a check to your place of business.
        </div>
      ) : (
        <div className="deliver-notification">
          <i className="far fa-hand-point-right" />
          Buy Right will mail a check to your place of business.
        </div>
      )}
    </div>
  );
};

export default CheckMode;
