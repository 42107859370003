import { FC } from 'react';
import { withStyles } from '@material-ui/core';
import { Loader } from '../../../utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';

const StyledDialogHead = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const ChannelModal: FC<any> = ({
  communityChannel: {
    isNewChannel,
    isEditChannel,
    channelName,
    channelDesc,
    isChannelNameError,
    isChannelDescError,
    isChannelFormUpdated,
    channelFormSpinner,
  },
  updateNewChannel,
  updateEditChannel,
  handleChannelName,
  handleChannelDesc,
  clearCommunityChannelModal,
  handleCreateChannel,
  handleUpdateChannel,
}) => {
  const errorFlag =
    !isChannelFormUpdated ||
    isChannelNameError ||
    isChannelDescError ||
    channelFormSpinner;

  const handleClose = () => {
    if (isEditChannel) {
      updateEditChannel(false);
    } else {
      updateNewChannel(false);
    }
    clearCommunityChannelModal();
  };

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={isNewChannel || isEditChannel}
        className="dialog-box"
      >
        {channelFormSpinner && (
          <div className="loader-wrapper">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        )}
        <StyledDialogHead onClose={handleClose}>
          {isEditChannel ? 'Edit Channel' : 'Create New Channel'}
        </StyledDialogHead>

        <StyledDialogContent>
          <div className="br-form-element">
            <Grid container spacing={6}>
              <Grid item xs>
                <TextField
                  error={isChannelNameError}
                  id={isChannelNameError ? 'standard-error' : 'outlined-basic'}
                  label="Channel Name"
                  placeholder="Channel Name"
                  variant="outlined"
                  className="text-box"
                  helperText={
                    isChannelNameError ? 'Please enter channel name' : ''
                  }
                  onChange={(e) => handleChannelName(e.target.value)}
                  disabled={channelFormSpinner}
                  required
                  value={channelName}
                />
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item xs>
                <TextField
                  error={isChannelDescError}
                  id={isChannelDescError ? 'standard-error' : 'outlined-basic'}
                  multiline
                  rows={3}
                  label="Channel Description"
                  placeholder="Channel Description"
                  variant="outlined"
                  className="text-box"
                  helperText={
                    isChannelDescError ? 'Please enter channel description' : ''
                  }
                  onChange={(e) => handleChannelDesc(e.target.value)}
                  disabled={channelFormSpinner}
                  required
                  value={channelDesc}
                />
              </Grid>
            </Grid>
          </div>
        </StyledDialogContent>
        <StyledDialogActions>
          <div className="modal-buttons-container">
            <div className="action-button">
              <button className="cancel-user-button" onClick={handleClose}>
                Cancel
              </button>
              <Button
                onClick={
                  isEditChannel ? handleUpdateChannel : handleCreateChannel
                }
                className={
                  errorFlag
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={errorFlag}
              >
                {isEditChannel ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default ChannelModal;
