import { FC, Fragment } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { CommunityTabPanel } from './CommunityTabPanel';
import { CommunityQuestions } from './CommunityQuestions';
import { Loader } from '../../../utils';
import '../community.scss';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const CommunityToggle: FC<any> = ({
  updateQuestionDetail,
  updateActiveDiscussion,
  communityDiscussion: {
    discussionList: { mostLiked, mostRecent },
    discussionSpinner,
    selectedTab,
    discussionFormSpinner,
  },
  communityDiscussionActions,
  searchText,
  handleDiscussionLike,
  setAutoFocus,
  handleToggleChange,
  user: { id: userId, isBRAdmin },
  handleEditDiscussion,
  handleReport,
  updateArchiveDiscussion,
  handleArchiveDiscussion,
  handleArchiveModal,
  isViewAsMember
}) => {
  const handleChange = (_: any, newValue: any) => {
    handleToggleChange(newValue);
  };

  const activeData = selectedTab === 0 ? mostRecent : mostLiked;
  const filteredData = activeData
    ? activeData.filter((lineItem: any) => {
      return Object.keys(lineItem).some((key) => {
        const searchItem = lineItem[key] && lineItem[key].toString();
        return (
          searchItem &&
          searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
        );
      });
    })
    : [];

  return (
    <div className="community-tab">
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label="Most Recent" {...a11yProps(0)} />
        <Tab label="Most Liked" {...a11yProps(1)} />
      </Tabs>

      {discussionSpinner ? (
        <div className="section-spinner">
          <Loader size={40} />
        </div>
      ) : (
        <Fragment>
          <CommunityTabPanel value={selectedTab} index={0}>
            <CommunityQuestions
              updateQuestionDetail={updateQuestionDetail}
              updateActiveDiscussion={updateActiveDiscussion}
              filteredData={filteredData}
              communityDiscussionActions={communityDiscussionActions}
              handleDiscussionLike={handleDiscussionLike}
              setAutoFocus={setAutoFocus}
              discussionSpinner={discussionSpinner}
              userId={userId}
              isBRAdmin={isBRAdmin}
              handleEditDiscussion={handleEditDiscussion}
              handleReport={handleReport}
              handleArchiveDiscussion={handleArchiveDiscussion}
              updateArchiveDiscussion={updateArchiveDiscussion}
              handleArchiveModal={handleArchiveModal}
              discussionFormSpinner={discussionFormSpinner}
              isViewAsMember={isViewAsMember}
            />
          </CommunityTabPanel>
          <CommunityTabPanel value={selectedTab} index={1}>
            <CommunityQuestions
              updateQuestionDetail={updateQuestionDetail}
              updateActiveDiscussion={updateActiveDiscussion}
              filteredData={filteredData}
              communityDiscussionActions={communityDiscussionActions}
              handleDiscussionLike={handleDiscussionLike}
              setAutoFocus={setAutoFocus}
              discussionSpinner={discussionSpinner}
              userId={userId}
              isBRAdmin={isBRAdmin}
              handleEditDiscussion={handleEditDiscussion}
              handleReport={handleReport}
              handleArchiveDiscussion={handleArchiveDiscussion}
              updateArchiveDiscussion={updateArchiveDiscussion}
              handleArchiveModal={handleArchiveModal}
              discussionFormSpinner={discussionFormSpinner}
              isViewAsMember={isViewAsMember}
            />
          </CommunityTabPanel>
        </Fragment>
      )}
    </div>
  );
};

export default CommunityToggle;
