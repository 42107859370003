import moment from 'moment';
import {
  SHOW_DOCUMENT_SPINNER,
  HIDE_DOCUMENT_SPINNER,
  UPDATE_DOCUMENT_TOGGLE,
  UPDATE_DC_MENU_LIST,
  UPDATE_MEMBERS_MENU_LIST,
  UPDATE_SELECTED_FILE_NAME,
  UPDATE_SELECTED_FILE_SIZE,
  UPDATE_SELECTED_FILE_EXTENSION,
  UPDATE_SELECT_MEMBERS,
  UPDATE_SELECT_ALL_MEMBERS,
  UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
  UPDATE_DOCUMENT_DESC_ERROR,
  UPDATE_SELECTED_FILE_CONTENT,
  CLEAR_DOCUMENT,
  CLEAR_DOCUMENT_MODAL,
  UPDATE_SELECTED_MEMBERS,
  UPDATE_SELECTED_DISTRIBUTION_CENTERS,
  UPDATE_DOCUMENTS_LIST,
  UPDATE_DC_MENU_LIST_ERROR,
  UPDATE_FILE_EXTENSION_ERROR,
  UPDATE_DOCUMENT_PUBLISH_ERROR,
  UPDATE_MEMBER_LIST_ERROR,
  UPDATE_DOCUMENT_LIST_ERROR,
  PublishDocumentError,
  DocumentDataError,
  MembersListError,
  DocumentListError,
  FetchError,
  DocumentPublishSuccess,
} from '../../constants';
import { showModal, closeModal } from '../modal';
import {
  getDocuments,
  publishDocument,
  getDocumentsListAPI,
} from '../../api/documents';
import { getMembers } from '../../api/members';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getFileName, getFileSize } from '../../utils';

export const handleToggle = (viewType) => async (dispatch) => {
  dispatch({
    type: UPDATE_DOCUMENT_TOGGLE,
    viewType,
  });
};

export const clearDocument = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT,
  });
};

export const showDocumentSpinner = () => async (dispatch) => {
  dispatch({
    type: SHOW_DOCUMENT_SPINNER,
  });
};

export const hideDocumentSpinner = () => async (dispatch) => {
  dispatch({
    type: HIDE_DOCUMENT_SPINNER,
  });
};

export const clearDocumentModal = () => async (dispatch) => {
  dispatch({
    type: CLEAR_DOCUMENT_MODAL,
  });
};

export const handleSelectMembers = (isSelectMember) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECT_MEMBERS,
    isSelectMember,
  });
  dispatch({
    type: UPDATE_SELECT_ALL_MEMBERS,
    isAllMembers: false,
  });
  dispatch({
    type: UPDATE_SELECTED_DISTRIBUTION_CENTERS,
    selectedDistributionCenters: [],
  });
};

export const handleSelectAllMembers = (isAllMembers) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECT_ALL_MEMBERS,
    isAllMembers,
  });
  dispatch({
    type: UPDATE_SELECT_MEMBERS,
    isSelectMember: false,
  });
};

export const handleRemoveFile = () => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_FILE_NAME,
    selectedFileName: null,
  });
  dispatch({
    type: UPDATE_SELECTED_FILE_SIZE,
    selectedFileSize: null,
  });
  dispatch({
    type: UPDATE_SELECTED_FILE_EXTENSION,
    selectedFileExtension: null,
  });
};

export const showAddDocumentModal = () => async (dispatch) => {
  dispatch(showModal());
};

export const closeAddDocumentModal = () => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_FILE_NAME,
    selectedFileName: null,
  });
  dispatch({
    type: UPDATE_SELECTED_FILE_SIZE,
    selectedFileSize: null,
  });
  dispatch({
    type: UPDATE_SELECTED_FILE_EXTENSION,
    selectedFileExtension: null,
  });
  dispatch(closeModal());
};

export const getDCLIST = () => async (dispatch, getState) => {
  const { vendor } = getState();
  const { vendorID } = vendor;
  try {
    const response = await getDocuments(vendorID);
    const formattedDCList =
      response.data &&
      response.data.map((item) => {
        return {
          ...item,
          isVendor: true,
        };
      });
    dispatch({
      type: UPDATE_DC_MENU_LIST,
      documentDCList: formattedDCList,
    });
  } catch (error) {
    if (error === UPDATE_DC_MENU_LIST_ERROR) {
      toast.error(DocumentDataError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getMemberMenuList = () => async (dispatch) => {
  try {
    const response = await getMembers();
    const formattedMemberList =
      response.data &&
      response.data.result.map((item) => {
        return {
          ...item,
          isVendor: false,
        };
      });
    dispatch({
      type: UPDATE_MEMBERS_MENU_LIST,
      membersList: formattedMemberList,
    });
  } catch (error) {
    if (error === UPDATE_MEMBER_LIST_ERROR) {
      toast.error(MembersListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const handleFileSelect = (fileContent) => async (dispatch) => {
  const fileName = getFileName(fileContent.name);
  const fileSize = getFileSize(fileContent.size);
  const fileExtension = fileContent.name.split('.').pop();
  const validFileExtension =
    fileExtension === 'pdf' ||
    fileExtension === 'xls' ||
    fileExtension === 'xlsx' ||
    fileExtension === 'doc' ||
    fileExtension === 'docx';

  dispatch({
    type: UPDATE_SELECTED_FILE_CONTENT,
    fileContent: fileContent,
  });

  if (fileName !== null && validFileExtension) {
    dispatch({
      type: UPDATE_SELECTED_FILE_NAME,
      selectedFileName: fileName,
    });
  } else {
    dispatch({
      type: UPDATE_SELECTED_FILE_NAME,
      selectedFileName: null,
    });
  }

  if (fileSize !== null && validFileExtension) {
    dispatch({
      type: UPDATE_SELECTED_FILE_SIZE,
      selectedFileSize: fileSize,
    });
  } else {
    dispatch({
      type: UPDATE_SELECTED_FILE_SIZE,
      selectedFileSize: null,
    });
  }

  if (fileExtension !== null && validFileExtension) {
    dispatch({
      type: UPDATE_FILE_EXTENSION_ERROR,
      fileExtensionError: false,
    });
    dispatch({
      type: UPDATE_SELECTED_FILE_EXTENSION,
      selectedFileExtension: fileExtension,
    });
  } else {
    dispatch({
      type: UPDATE_FILE_EXTENSION_ERROR,
      fileExtensionError: true,
    });
    dispatch({
      type: UPDATE_SELECTED_FILE_EXTENSION,
      selectedFileExtension: null,
    });
  }
};

export const updateSelectedMembers =
  (event, selectedMembers) => async (dispatch) => {
    dispatch({
      type: UPDATE_SELECTED_MEMBERS,
      selectedMembers,
    });
  };

export const updateActiveMembers = (selectedMembers) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_MEMBERS,
    selectedMembers: selectedMembers ? selectedMembers : null,
  });
};

export const handlePublish = () => async (dispatch, getState) => {
  const { documents, user } = getState();
  const { id } = user;
  const {
    fileContent,
    selectedMembers,
    selectedDistributionCenters,
    isAllMembers,
    selectedModalCategory,
    documentDesc,
    categoryError,
    documentDescError,
  } = documents;
  const membersList = selectedMembers.filter((item) => item.isVendor !== true);
  const dcList = selectedMembers.filter((item) => item.isVendor === true);
  const selectedMembersID = membersList.map((item) => item.id);
  const selectedDCID = dcList.map((item) => item.id);
  if (categoryError || selectedModalCategory === 'NA') {
    dispatch({
      type: UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
      categoryError: true,
    });
    return;
  }
  if (documentDesc === null || documentDesc === '' || !documentDesc) {
    dispatch({
      type: UPDATE_DOCUMENT_DESC_ERROR,
      documentDescError: true,
    });
  } else if (!categoryError || !documentDescError) {
    dispatch(showDocumentSpinner());
    try {
      const response = await publishDocument({
        id,
        documentTypeId: selectedModalCategory,
        fileContent,
        isAllMembers,
        selectedMembersID,
        selectedDCID,
        documentDesc,
      });
      const documentRecords = await getDocumentsListAPI();
      const { data } = documentRecords;
      const documentList =
        data &&
        data.map((item) => {
          const { uploadeddate, publicat } = item;
          const formattedUploadDate = uploadeddate
            ? moment(uploadeddate)
                .format('MM-DD-YYYY')
                .toString()
            : '';
          return {
            ...item,
            uploadeddate: formattedUploadDate,
            accessLevel:
              publicat && publicat.length > 0
                ? 'All Members'
                : 'Selected Members',
          };
        });
      dispatch({
        type: UPDATE_DOCUMENTS_LIST,
        documentList,
      });
      toast.success(DocumentPublishSuccess);
      dispatch(hideDocumentSpinner());
      dispatch(closeModal());
    } catch (error) {
      dispatch(closeModal());
      dispatch(hideDocumentSpinner());
      if (error === UPDATE_DOCUMENT_PUBLISH_ERROR) {
        toast.error(PublishDocumentError);
      } else if (error === UPDATE_DOCUMENT_LIST_ERROR) {
        toast.error(DocumentListError);
      } else {
        toast.error(FetchError);
      }
    }
  }
};
