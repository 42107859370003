import { baseUrl, EXPORT_EARNED_REBATE_OVERVIEW_ERROR, EARNED_REBATE_NO_RECORD_ERROR} from "../../../constants";

export const exportEarnedRebatesOverview = async ({
    memberId,
    quarter,
    year
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUrl}/members/${memberId}/rebate-funds-overview-detail/export?quarter=${quarter}&year=${year}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw EARNED_REBATE_NO_RECORD_ERROR;
        const blob = await response.blob();
        return blob;
    } catch (error) {
        if (error === EARNED_REBATE_NO_RECORD_ERROR) throw EARNED_REBATE_NO_RECORD_ERROR;
        else {
            throw EXPORT_EARNED_REBATE_OVERVIEW_ERROR;
        }
    }
}

