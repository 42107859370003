import { FC, useState } from 'react';
import { UserTable } from '../../tableUtils/UsersTable';
import { WarningPopup } from '../../../../utils';
import '../../vendors.scss';
import { VendorUsers } from '../../../../constants/vendors';
import { GridSize } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';

export const SalesPersonnelList: FC<any | { vendorUserList: VendorUsers }> = ({
  updateDeleteVendorUsers,
  isDeleteUser,
  handleDeleteClose,
  handleDeleteVendorUser,
  showVendorUser,
  user,
  rolesList,
  state,
  send,
}) => {
  const { memberusers, vendorusers, isBRAdmin } = user || {};

  const memberRoleids = rolesList.filter(
    (role: { id: number; name: string }) =>
      role.name === 'Member Manager' || role.name === 'Member Admin',
  );
  const vendorRoleids = rolesList.filter(
    (role: { id: number; name: string }) =>
      role.name === 'Account Representative' || role.name === 'Vendor Admin',
  );

  const userRoles = {
    isMemberUser:
      memberusers.filter((item: { roleid: number }) =>
        memberRoleids.some((role: { id: number }) => item.roleid === role.id),
      ).length > 0
        ? true
        : false,
    isVendorUser:
      vendorusers.filter((item: { roleid: number }) =>
        vendorRoleids.some((role: { id: number }) => item.roleid === role.id),
      ).length > 0
        ? true
        : false,
    isBRAdmin,
  };

  const hasActionPermission = userRoles.isBRAdmin || userRoles.isMemberUser;
  const isEditable = user?.isBRAdmin ?? false;
  const showDelete = user?.isBRAdmin ?? false;

  const columns: {
    label: string;
    value: string;
    sortKey: string;
    xs: GridSize;
    lg: GridSize;
  }[] = [
      {
        label: 'Name',
        value: 'userName',
        sortKey: 'userName',
        xs: 12,
        lg: 2,
      },
      {
        label: 'Job Title',
        value: 'jobTitle',
        sortKey: 'jobTitle',
        xs: 12,
        lg: 2,
      },
      {
        label: 'Email',
        value: 'email',
        sortKey: 'email',
        xs: 12,
        lg: 2,
      },
      {
        label: 'Distribution Center',
        value: 'distributionName',
        sortKey: 'distributionName',
        xs: 12,
        lg: 2,
      },
      {
        label: 'Phone',
        value: 'phone',
        sortKey: 'phone',
        xs: 12,
        lg: 2,
      },
    ];

  const gridHeader: {
    label: string;
    value: string;
    sortKey?: string;
    xs: GridSize;
    lg: GridSize;
  }[] =
    // isEditable || showDelete
    hasActionPermission
      ? [
        ...columns,
        {
          label: 'Action',
          value: 'action',
          xs: 12,
          lg: 2,
        },
      ]
      : columns;

  const [deleteUser, setDeleteUser] = useState(null);

  const handleDelete = (item: any) => {
    const name = item && `${item.firstName} ${item.lastName}`;
    setDeleteUser(name);
    updateDeleteVendorUsers(item);
  };

  const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
  const slicedRecords = state.context.records.slice(
    pageIndex,
    pageIndex + state.context.pageSize,
  );

  return (
    <div className="table-wrapper">
      <UserTable
        gridHeader={gridHeader}
        gridData={slicedRecords ? slicedRecords : []}
        isEdit={isEditable}
        isDelete
        handleDelete={handleDelete}
        showVendorUser={showVendorUser}
        showEdit={isEditable}
        showDelete={showDelete}
        user={user}
        hasActionPermission={hasActionPermission}
        state={state}
        send={send}
      />

{slicedRecords?.length ? (
        <TablePagination
          component="div"
          count={state.context.totalRecords}
          page={state.context.pageNumber - 1}
          onPageChange={(_, newPage) => {
            send({
              type: "goToPage",
              pageNumber: newPage + 1,
            });
          }}
          rowsPerPage={state.context.pageSize}
          onRowsPerPageChange={(event) =>
            send({
              type: "updatePageSize",
              pageSize: parseInt(event.target.value),
            })
          }
          rowsPerPageOptions={[10, 20, 30, 40]}
          labelDisplayedRows={({ from, to, count }) => {
            return `${from}–${to} of ${
              count !== -1 ? count.toLocaleString() : `more than ${to}`
            }`;
          }}
        />
      ):null}

      <WarningPopup
        open={isDeleteUser}
        type={'delete'}
        name={deleteUser}
        handleProceed={() => {
          handleDeleteVendorUser();
          setTimeout(() => send({
            type: 'addQueryStringValue',
            key: 'orderBy',
            value: 'id',
          }), 1000);
        }}
        handleClose={handleDeleteClose}
      />
    </div>
  );
};
