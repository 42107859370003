import {ASSIGN_VENDORS_API, VendorMemberUpdateError} from '../../constants';

export const updateVendorSalesPersonnel = async ({
    vendorID,
    memberId,
    vendorUserId,
    vendorMemberId,
    customerID,
    distributionId,
    customerAbbreviation
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${ASSIGN_VENDORS_API}${vendorID}/vendordistributioncenter/${distributionId}/member/${memberId}?customerId=${customerID}&vendorMemberId=${vendorMemberId}&salesPersonId=${vendorUserId}&customerAbbreviation=${customerAbbreviation}`, {
            method: 'PATCH',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            })
        });
        if (!response.ok) throw VendorMemberUpdateError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw VendorMemberUpdateError;
    }
};