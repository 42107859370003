import { assign } from '@xstate/immer';
import { createMachine, sendParent } from 'xstate';
import { OpportunityQuarter } from '../../../../../common';
import serviceInterceptor from '../../../../../services/ServiceInterceptor';

interface OpportunitiesEditContext {
  selectedOpportunityQuarter: OpportunityQuarter;
  opportunitiesDocumentFile: File | null;
}

type OpportunitiesEditEvent =
  | { type: 'cancel' }
  | { type: 'continue editing' }
  | { type: 'select file'; file: File }
  | { type: 'remove file' }
  | { type: 'submit file' };

///rebate-product-list-records/export

export const opportunitiesEditMachine = createMachine(
  {
    id: 'opportunitiesEdit',
    tsTypes: {} as import('./opportunitiesEditMachine.typegen').Typegen0,
    schema: {
      context: {} as OpportunitiesEditContext,
      events: {} as OpportunitiesEditEvent,
      services: {} as {
        'edit uploaded opportunities document record': {
          data: {
            success: boolean;
            data: any;
          };
        };
        'load document info': {
          data: File;
        };
      },
    },
    initial: 'editing',
    states: {
      editing: {
        tags: ['editing', 'user input required'],
        on: {
          cancel: [
            {
              actions: 'send parent close event',
              cond: 'no file has been selected',
            },
            {
              target: 'warning user of progress loss',
            },
          ],
          'remove file': {
            actions: 'unassign file',
          },
          'select file': {
            actions: 'assign file',
          },
          'submit file': [
            {
              actions: 'warn file is required',
              cond: 'no file has been selected',
            },
            {
              target: 'submitting',
            },
          ],
        },
      },
      'warning user of progress loss': {
        tags: ['warning', 'info', 'user input required'],
        on: {
          'continue editing': {
            target: 'editing',
          },
          cancel: {
            actions: 'send parent close event',
          },
        },
      },
      submitting: {
        tags: ['submitting', 'loading'],
        invoke: {
          id: 'edit uploaded opportunities document record',
          src: 'edit uploaded opportunities document record',
          onDone: 'complete',
          onError: {
            actions: 'notify failure',
            target: 'editing',
          },
        },
      },
      complete: { type: 'final' },
    },
  },
  {
    actions: {
      'assign file': assign(
        (context, event) => (context.opportunitiesDocumentFile = event.file),
      ),
      'send parent close event': sendParent('close modal'),
      'notify failure': () =>
        console.error('Failed to update rebate document opportunities file.'),
      'unassign file': assign(
        (context) => (context.opportunitiesDocumentFile = null),
      ),
      'warn file is required': () => console.warn('Please select a file.'),
    },
    guards: {
      'no file has been selected': (context) =>
        !context.opportunitiesDocumentFile,
    },
    services: {
      'edit uploaded opportunities document record': (context) => {
        const bodyFormData = new FormData();
        bodyFormData.append(
          'quarter',
          context.selectedOpportunityQuarter.quarter.toString(),
        );
        bodyFormData.append(
          'year',
          context.selectedOpportunityQuarter.year.toString(),
        );
        bodyFormData.append(
          'rebateProductList',
          context.opportunitiesDocumentFile as File,
        );
        return serviceInterceptor
          .patch('/rebate-product-list-records', bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => new Promise((resolve, reject) => {
            try {
              const timeout = setTimeout(() => {
                resolve(response.data);
                clearTimeout(timeout);
              }, 6000);
            } catch (error) {
              reject(error);
            }
          }));
      },
    },
  },
);

export type OpportunitiesEditMachine = typeof opportunitiesEditMachine;
export default opportunitiesEditMachine;
