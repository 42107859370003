import { TablePagination } from '@material-ui/core';
import { FC } from 'react';
import { connect } from 'react-redux';
import {
  handleChangeRowsPerPage,
  handleChangePage,
} from '../../actions/pagination';

const PaginationBase: FC<any> = (props) => {
  const {
    count,
    paginationReducer,
    handleChangePage,
    handleChangeRowsPerPage,
  } = props;
  const { rowsPerPage, page, pageType } = paginationReducer;

  const getRows = (page: string) => {
    if (page === 'DOCUMENTS') return [18, 24, 30, 36];
    else if (page === 'MEMBERS') return [8, 12, 16, 20];
    else if (page === 'DASHBOARD') return [5, 10, 15];
    else return [10, 20, 30, 40];
  };

  return (
    <TablePagination
      component="div"
      count={count}
      page={page}
      onPageChange={(event, newPage) => handleChangePage(event, newPage)}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={(event) =>
        handleChangeRowsPerPage(parseInt(event.target.value))
      }
      rowsPerPageOptions={getRows(pageType)}
      labelDisplayedRows={({ from, to, count }) => {
        return `${from}–${to} of ${
          count !== -1 ? count.toLocaleString() : `more than ${to}`
        }`;
      }}
    />
  );
};

const mapStateToProps = (state: { paginationReducer: any }) => {
  const { paginationReducer } = state;

  return {
    paginationReducer,
  };
};

export const ConnectedPagination = connect(mapStateToProps, {
  handleChangeRowsPerPage,
  handleChangePage,
})(PaginationBase);

export default ConnectedPagination;
