import { FetchError } from '../constants';
import serviceInterceptor from '../services/ServiceInterceptor';

export const userData = async () => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await serviceInterceptor.get('/users/user-data', {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json',
            }),
        });
        return response.data;
    } catch (error) {
        if (error?.response?.data) {
            return error.response.data;
        } else {
            throw FetchError;
        }
    }
};
