import React, {FC, useState, useEffect, Fragment} from "react";
import {LocationActions} from "./LocationsActions";
import {LocationsTable} from "./LocationsTableDisplay";
import {LocationTable} from "../../tableUtils/LocationsTable"
import {Loader, WarningPopup, useStyles} from "../../../../../utils";
import {ConnectedPagination} from "../../../../../components/ConnectedPagination";
import {getPaginatedData} from "../../../../../utils";

const gridHeader = [
  {
    label: 'Member',
    value: 'name',
    sortKey: 'name',
    xs: 12,
    lg: 2
  },
  {
    label: 'Street',
    value: 'street',
    sortKey: 'street',
    xs: 12,
    lg: 2
  },
  {
    label: 'City',
    value: 'city',
    sortKey: 'city',
    xs: 12,
    lg: 2
  },
  {
    label: 'State',
    value: 'state',
    sortKey: 'state',
    xs: 12,
    lg: 1
  },
  {
    label: 'ZIP',
    value: 'zip',
    sortKey: 'zip',
    xs: 12,
    lg: 1
  },
  {
    label: 'Phone',
    value: 'phone',
    sortKey: 'phone',
    xs: 12,
    lg: 1
  },
  {
    label: 'Mobile',
    value: 'mobile',
    sortKey: 'mobile',
    xs: 12,
    lg: 2
  },
  {
    label: 'Action',
    value: 'action',
    xs: 12,
    lg: 1
  }
];

export const MemberLocationsTab: FC<any> = (props) => {
  const {
    memberDetail,
    getMemberLocations,
    members: {
      locationsList,
      locationListSpinner,
      isDeleteMemberLocation,
      selectedLocation,
      deleteLocationSpinner
    },
    updateDeleteMemberLocation,
    handleDeleteMemberLocationClose,
    onDeleteMemberLocation,
    paginationReducer: {
      rowsPerPage,
      page
    },
    handleChangeRowsPerPage,
    handlePageType
  } = props;

  const classes = useStyles();
  const results = locationsList || [];
  const [memberLocationData, setMemberLocationData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState("");

  const setSortKey = (newSortKey: any) => {
    if (sortKey === newSortKey) {
      if (sortType === "DESC") {
        updateSortType("ASC");
      } else {
        updateSortType("DESC");
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType("ASC");
    }
  };

  useEffect(() => {
    getMemberLocations();
  }, []);

  useEffect(() => {
    setMemberLocationData(locationsList);
    handleChangeRowsPerPage(10);
  }, [locationsList]);

  const modifiedList = getPaginatedData(memberLocationData, rowsPerPage, page);

  const filteredData = (searchText && modifiedList.length > 0) ? (
    modifiedList.filter((lineItem: any) => {
      return Object.keys(lineItem).some((key) => {
        const searchItem = lineItem[key] && lineItem[key].toString();
        return searchItem && searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
      })
    })) : modifiedList;

  const sortedData = (sortKey && sortType && filteredData.length > 0) ? (filteredData && filteredData.sort((a: any, b: any) => {
    if (sortType === 'DESC') {
      return b[sortKey].localeCompare(a[sortKey]);
    }
    return a[sortKey].localeCompare(b[sortKey]);
  })) : filteredData;

  const selectedDeleteUser = selectedLocation && selectedLocation.name;

  return (
    <div className="locations-tab table-wrapper">
      <div className={classes.root}>
        <LocationActions
          rows={results}
          memberDetail={memberDetail}
          searchText={searchText}
          setSearchText={setSearchText}
        />
        <div className="relative-wrapper">
          {locationListSpinner ? (
            <div className="loader-wrapper list-spinner">
              <div className="document-loader">
                <Loader size={40} />
              </div>
            </div>
          ) : (
            <Fragment>
              <LocationTable
                gridHeader={gridHeader}
                gridData={sortedData ? sortedData : []}
                updateDeleteMemberLocation={updateDeleteMemberLocation}
                setSortKey={setSortKey}
                sortType={sortType}
                memberDetail={memberDetail}
              />
              {memberLocationData?.length > 5 && sortedData?.length > 0?
                <ConnectedPagination
                  count={memberLocationData?.length}
                /> : null
              }
            </Fragment>
          )}
        </div>
        <WarningPopup
          open={isDeleteMemberLocation}
          type={"unassign"}
          name={selectedDeleteUser}
          handleProceed={onDeleteMemberLocation}
          spinner={deleteLocationSpinner}
          handleClose={() => handleDeleteMemberLocationClose()}
        />
      </div>
    </div>
  );
};
