import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  UPDATE_PRODUCT_REBATES_SPINNER,
  UPDATE_PRODUCT_REBATES_LIST_SPINNER,
  UPDATE_PRODUCT_REBATES_QUARTER,
  UPDATE_PRODUCT_REBATES_YEAR,
  UPDATE_PRODUCT_REBATES_FILE_CONTENT,
  UPDATE_PRODUCT_REBATES_FILE_NAME,
  UPDATE_PRODUCT_REBATES_FILE_SIZE,
  UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
  UPDATE_PRODUCT_REBATES_LIST,
  UPDATE_PRODUCT_REBATES_INFO,
  UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR,
  CLEAR_PRODUCT_REBATES_MODAL,
  UPDATE_PRODUCT_REBATES_DRAFT,
  UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER,
  UPDATE_PRODUCT_REBATES_REQUEST_TYPE,
  UPLOAD_PRODUCT_REBATES_ERROR,
  PRODUCT_REBATES_QUARTER_LIST_ERROR,
  PUBLISH_PRODUCT_REBATES_ERROR,
  UploadProductRebatesError,
  ProductRebatesListError,
  PublishRebatesError,
  exportRebatesError,
  EXPORT_REEBATE_PRODUCT_LIST_ERROR,
  RebateProductLists,
  CompactRebates,
} from '../../constants';

import {
  getProductRebatesQuarter,
  hideProductRebateModal,
  updatePublishSpinner,
  rebatesModalSpinner,
  closeRebateExportModal,
} from './rebates';
import { getFileName, getFileSize } from '../../utils';
import {
  uploadProductRebates,
  updateProductRebates,
  getProductRebatesQuarterList,
  publishProductRebates,
  exportRebateProductList,
  exportCalculatedRebates,
} from '../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const productRebatesSpinner =
  (productRebatesSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_SPINNER,
      productRebatesSpinner,
    });
  };

export const productRebatesListSpinner =
  (productRebatesListSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_LIST_SPINNER,
      productRebatesListSpinner,
    });
  };

export const onChangeProductRebatesQuarter =
  (selectedQuarter) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_QUARTER,
      quarter: selectedQuarter,
    });
  };

export const onChangeProductRebatesYear =
  (selectedYear) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_YEAR,
      year: selectedYear,
    });
  };

export const updateProductRebatesFileDraft = (isDraft) => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT_REBATES_DRAFT,
    isDraft,
  });
};

export const onProductRebatesFileSelect =
  (uploadedFile) => async (dispatch) => {
    const fileName = getFileName(uploadedFile.name);
    const fileSize = getFileSize(uploadedFile.size);
    const fileExtension = uploadedFile.name.split('.').pop();
    const vaildFileExtension =
      fileExtension === 'xls' || fileExtension === 'xlsx';
    if (vaildFileExtension) {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_CONTENT,
        fileContent: uploadedFile,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_CONTENT,
        fileContent: null,
      });
    }

    if (fileName !== null && vaildFileExtension) {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_NAME,
        selectedFileName: fileName,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_NAME,
        selectedFileName: null,
      });
    }

    if (fileSize !== null && vaildFileExtension) {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_SIZE,
        selectedFileSize: fileSize,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_SIZE,
        selectedFileSize: null,
      });
    }

    if (fileExtension !== null && vaildFileExtension) {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR,
        fileExtensionError: false,
      });
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
        selectedFileExtension: fileExtension,
      });
    } else {
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR,
        fileExtensionError: true,
      });
      dispatch({
        type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
        selectedFileExtension: null,
      });
    }
  };

export const onProductRebatesFileRemove = () => async (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCT_REBATES_FILE_CONTENT,
    fileContent: null,
  });
  dispatch({
    type: UPDATE_PRODUCT_REBATES_FILE_NAME,
    selectedFileName: null,
  });
  dispatch({
    type: UPDATE_PRODUCT_REBATES_FILE_SIZE,
    selectedFileSize: null,
  });
  dispatch({
    type: UPDATE_PRODUCT_REBATES_FILE_EXTENSION,
    selectedFileExtension: null,
  });
};

export const clearProductRebatesModal = () => async (dispatch) => {
  dispatch({
    type: CLEAR_PRODUCT_REBATES_MODAL,
  });
  dispatch({
    type: UPDATE_PRODUCT_REBATES_INFO,
    productRebatesInfo: null,
  });
};

export const handleRequestRebateType = (selectedType) => async (dispatch) => {
  if (selectedType === RebateProductLists) {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_REQUEST_TYPE,
      requestRebateType: RebateProductLists,
    });
  } else {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_REQUEST_TYPE,
      requestRebateType: CompactRebates,
    });
  }
};

export const onUploadProductRebates = () => async (dispatch, getState) => {
  const { productRebates } = getState();
  const { quarter, year, fileContent, isEdit } = productRebates;
  dispatch(productRebatesSpinner(true));
  try {
    if (!isEdit) {
      const res = await uploadProductRebates({
        quarter,
        year,
        rebateProductList: fileContent,
      });
      const { success, message } = res;
      if (success) {
        dispatch(updateProductRebatesFileDraft(true));
        dispatch(productRebatesSpinner(false));
        toast.success(message);
        dispatch(getProductRebatesQuarter());
      }
    } else {
      const res = await updateProductRebates({
        quarter,
        year,
        rebateProductList: fileContent,
      });
      const { success, message } = res;
      if (success) {
        dispatch(updateProductRebatesFileDraft(true));
        dispatch(productRebatesSpinner(false));
        toast.success(message);
        dispatch(getProductRebatesQuarter());
      }
    }
    dispatch(hideProductRebateModal());
    dispatch(productRebatesSpinner(false));
  } catch (error) {
    dispatch(productRebatesSpinner(false));
    if (error === UPLOAD_PRODUCT_REBATES_ERROR) {
      toast.error(UploadProductRebatesError);
    }
  }
};

export const handleProductRebateQuarter =
  (selectedQuarter) => async (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER,
      activeQuarter: selectedQuarter,
    });
    const quarter = selectedQuarter !== null && selectedQuarter?.quarter;
    const year = selectedQuarter !== null && selectedQuarter?.year;
    dispatch({
      type: UPDATE_PRODUCT_REBATES_QUARTER,
      quarter,
    });
    dispatch({
      type: UPDATE_PRODUCT_REBATES_YEAR,
      year,
    });
    if (selectedQuarter.status === 'UPLOADED') {
      dispatch(updateProductRebatesFileDraft(true));
    } else {
      dispatch(updateProductRebatesFileDraft(false));
    }
    dispatch(productRebatesListSpinner(true));
    try {
      const response = await getProductRebatesQuarterList({
        quarter,
        year,
      });
      const { success, data } = response;

      const formattedData =
        data.length > 0 &&
        data.map((item) => {
          return {
            ...item,
          };
        })
      if (success && formattedData.length > 0) {
        dispatch({
          type: UPDATE_PRODUCT_REBATES_LIST,
          productRebateslist: formattedData,
        });
      } else {
        dispatch({
          type: UPDATE_PRODUCT_REBATES_LIST,
          productRebateslist: [],
        });
      }
      dispatch(productRebatesListSpinner(false));
    } catch (error) {
      dispatch(productRebatesListSpinner(false));
      if (error === PRODUCT_REBATES_QUARTER_LIST_ERROR) {
        toast.error(ProductRebatesListError);
      }
    }
  };

export const onPublishProductRebates = () => async (dispatch, getState) => {
  const { productRebates } = getState();
  const { activeQuarter } = productRebates;
  dispatch(updatePublishSpinner(true));
  const { quarter, year } = activeQuarter;
  try {
    const res = await publishProductRebates({
      quarter,
      year,
    });
    const { success, message } = res;
    if (success) {
      toast.success(message);
      dispatch(getProductRebatesQuarter());
    }
    dispatch(updatePublishSpinner(false));
  } catch (error) {
    dispatch(updatePublishSpinner(false));
    if (error === PUBLISH_PRODUCT_REBATES_ERROR) {
      toast.error(PublishRebatesError);
    }
  }
};

export const onExportProductRebates = () => async (dispatch, getState) => {
  const { productRebates } = getState();
  const { exportQuarter, exportYear, requestRebateType } = productRebates;
  dispatch(rebatesModalSpinner(true));
  try {
    if (requestRebateType === RebateProductLists) {
      const response = await exportRebateProductList({
        exportQuarter,
        exportYear,
      });
      const date = new Date();
      const fileName = `Product_Rebates_${moment(date).format(
        'DD_MMM_YYYY_hh:mm:ss_A',
      )}.xls`;
      saveAs(response, fileName);
      dispatch(rebatesModalSpinner(false));
      dispatch(closeRebateExportModal());
    } else {
      const response = await exportCalculatedRebates({
        exportQuarter,
        exportYear,
      });
      const date = new Date();
      const fileName = `${
        requestRebateType === RebateProductLists
          ? 'Product_Rebates'
          : 'Compact_Product_Rebates'
      }_${moment(date).format('DD_MMM_YYYY_hh:mm:ss_A')}.xls`;
      saveAs(response, fileName);
      dispatch(rebatesModalSpinner(false));
      dispatch(closeRebateExportModal());
    }
  } catch (error) {
    dispatch(closeRebateExportModal());
    dispatch(rebatesModalSpinner(false));
    if (error === EXPORT_REEBATE_PRODUCT_LIST_ERROR) {
      toast.error(exportRebatesError);
    }
  }
};
