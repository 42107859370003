import { FC, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { FormControlLabel, Checkbox } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { ProfileSideNav } from '../../components/sideNav';
import { FundTransferHeaderRow } from './FundTransferHeaderRow';
import { FundTransferListRow } from './FundTransferListRow';
import './fund-transfer.scss';
import { useMachine } from '@xstate/react';
import { fundingSourceMachine } from './fundingSourceMachine';
import { MemberFundingAccount } from '../../../../common';
import { Loader } from '../../../../utils';
import { useNavigate } from 'react-router-dom';
import { CustomModal } from "../../../../components/CustomModal/index";
import { useSelector } from 'react-redux';


export const FundTransferContent: FC<{
  user: any; // TODO
}> = (props) => {
  const { user } = props;
  const navigate = useNavigate();
  const [state, send] = useMachine(fundingSourceMachine, {
    context: {
      memberFundingAccounts: [],
      fundingSourceId: null,
      userId: user?.id,
      accountNumber: null,
      accountNumberConfirmation: null,
      bankAccountType: 'checking',
      accountNickName: null,
      routingNumber: null,
      routingNumberConfirmation: null,
      agreedDwollaTerms: false,
      isFormEdit: false,
    },
  });
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState('');
  const [showTermsModal, setShowTermsModal] = useState<boolean>(false);
  const store = useSelector((state) => state)

  const viewAsMember:any = store

  const closeTermsModal = () => {
    setShowTermsModal(false);
  }

  const setSortKey = (newSortKey: any) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  const sortedData =
    sortKey && sortType && state.context.memberFundingAccounts.length > 0
      ? state.context.memberFundingAccounts &&
      [...state.context.memberFundingAccounts].sort((a: any, b: any) => {
        if (sortType === 'DESC') {
          return b[sortKey]?.localeCompare(a[sortKey]);
        }
        return a[sortKey]?.localeCompare(b[sortKey]);
      })
      : state.context.memberFundingAccounts;

  const handleBankAccountTypeChange = (event: any) => {
    send({
      type: 'updateBankAccountType',
      bankAccountType: event.target.value,
    });
  };
  const handleEditItem = (item: MemberFundingAccount) => {
    send({ type: 'updateSelectedFundingAccount', item });
  };
  const handleDeleteItem = (item: MemberFundingAccount) => {
    send({ type: 'removeFundingSourceAccount', item });
  };

  useEffect(() => {
    if (process.env.REACT_APP_HIDE_DWOLLA_FEATURES == 'true') {
      navigate('/');
    }
  }, []);

  return (
    <div className="body-container">
      <Grid container className="change-password">
        <ProfileSideNav {...props} />
        <Grid item xs={9} className="change-password-right">
          {state.hasTag('loading') ? (
            <div className="relative-wrapper">
              <div className="loader-wrapper list-spinner">
                <div className="document-loader">
                  <Loader size={40} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="fund-transfer">
                <h2 className="fund-transfer-heading">Fund Transfer</h2>
                <Grid container spacing={4}>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Account Nick Name"
                      placeholder="Account Nick Name"
                      variant="outlined"
                      className="text-box"
                      value={state.context.accountNickName}
                      InputLabelProps={{ shrink: true }}
                      onChange={(e) =>
                        send({
                          type: 'updateAccountNickName',
                          accountNickName: e.target.value,
                        })
                      }
                      disabled={viewAsMember?.user?.isViewAsMember}
                      required
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FormControl
                      variant="outlined"
                      className="select-box"
                      required
                    >
                      <InputLabel htmlFor="account-type-select">
                        Account Type
                      </InputLabel>
                      <Select
                        native
                        value={state.context.bankAccountType}
                        defaultValue="checking"
                        onChange={handleBankAccountTypeChange}
                        label="Account Type"
                        inputProps={{
                          name: 'account',
                          id: 'account-type-select',
                        }}
                      disabled={viewAsMember?.user?.isViewAsMember}
                      >
                        <option value={'checking'}>Checking</option>
                        <option value={'savings'}>Savings</option>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Routing Number"
                      placeholder="Routing Number"
                      variant="outlined"
                      className="text-box"
                      InputLabelProps={{ shrink: true }}
                      value={state.context.routingNumber}
                      onChange={(e) =>
                        send({
                          type: 'updateRoutingNumber',
                          routingNumber: e.target.value,
                        })
                      }
                      disabled={viewAsMember?.user?.isViewAsMember}
                      required
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Confirm Routing Number"
                      placeholder="Confirm Routing Number"
                      variant="outlined"
                      className="text-box"
                      InputLabelProps={{ shrink: true }}
                      value={state.context.routingNumberConfirmation}
                      onChange={(e) =>
                        send({
                          type: 'updateRoutingNumberConfirmation',
                          routingNumberConfirmation: e.target.value,
                        })
                      }
                      disabled={viewAsMember?.user?.isViewAsMember}
                      required
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Account Number"
                      placeholder="Account Number"
                      variant="outlined"
                      className="text-box"
                      InputLabelProps={{ shrink: true }}
                      value={state.context.accountNumber}
                      onChange={(e) =>
                        send({
                          type: 'updateAccountNumber',
                          accountNumber: e.target.value,
                        })
                      }
                      disabled={viewAsMember?.user?.isViewAsMember}
                      required
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <TextField
                      label="Confirm Account Number"
                      placeholder="Confirm Account Number"
                      variant="outlined"
                      className="text-box"
                      InputLabelProps={{ shrink: true }}
                      value={state.context.accountNumberConfirmation}
                      onChange={(e) =>
                        send({
                          type: 'updateAccountNumberConfirmation',
                          accountNumberConfirmation: e.target.value,
                        })
                      }
                      disabled={viewAsMember?.user?.isViewAsMember}
                      required
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={state.context.agreedDwollaTerms}
                          onChange={(e) =>
                            send({
                              type: 'updateAgreedDwollaTerms',
                              agreedDwollaTerms: e.target.checked,
                            })
                          }
                          disabled={viewAsMember?.user?.isViewAsMember}
                        />
                      }
                      labelPlacement="end"
                      label={<span className='dwolla-terms'>By checking this box you agree to <a href={'https://buyrightpurchasing.com/tos/'} target={"_blank"}>Our Terms of Service</a> and <a href={'https://buyrightpurchasing.com/privacy-policy/'} target={'_blank'}>Privacy Policy</a>.</span>}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <div className="account-notes">
                      <i className="fas fa-info-circle" />
                      &nbsp;: $5 per account transfer fee will be deducted from
                      the transfer amount.
                    </div>
                  </Grid> */}
                  <Grid item xs={12}>
                    <Button
                      className="button-cancel"
                      onClick={() =>
                        send({ type: 'clearSelectedFundingAccount' })
                      }
                    >
                      Cancel
                    </Button>
                    <Button
                      disabled={
                        (!state.context.isFormEdit &&
                          state.context.memberFundingAccounts.length === 6) ||
                        !state.context.agreedDwollaTerms
                      }
                      className={
                        (!state.context.isFormEdit &&
                          state.context.memberFundingAccounts.length === 6) ||
                          !state.context.agreedDwollaTerms
                          ? 'button-save-disabled'
                          : 'button-save'
                      }
                      onClick={(e) => send({ type: 'submit' })}
                    >
                      {state.context.isFormEdit ? 'Agree & Update' : 'Agree & Add'}
                    </Button>
                  </Grid>
                </Grid>
                {showTermsModal && <CustomModal
                  showModal={showTermsModal}
                  closeModal={closeTermsModal}
                  modalTitle={"Terms of Service"}
                  isCancelButton={false}
                  isSubmitButton={true}
                  isSubmitText={"Ok"}
                  onSubmit={closeTermsModal}
                ><div style={{ lineHeight: '1.8' }}>If you register to create an account to receive payments via our
                  application, you expressly authorize Buy Right Purchasing Group,
                  LLC’s service provider, Dwolla, Inc. to originate credit transfers to
                  your financial institution account. You must be at least 13 years
                  old and obtain parental permission if under 18 to receive funds.
                  You authorize Buy Right Purchasing Group, LLC to collect and
                  share with Dwolla your personal information including full name,
                  email address and financial information, and you are responsible
                  for the accuracy and completeness of that data. Dwolla’s Privacy
                  Policy is available <a href={'https://www.dwolla.com/legal/privacy/'} target={'_blank'}>here</a>.</div>
                </CustomModal>}
              </div>
              {sortedData.length > 0 && (
                <div>
                  <Divider style={{ marginTop: '25px' }} variant="middle" />
                  <div className="table-wrapper" style={{ marginTop: '50px' }}>
                    <FundTransferHeaderRow
                      setSortKey={setSortKey}
                      sortType={sortType}
                    />
                    {sortedData.map((accountDetail) => {
                      return (
                        <FundTransferListRow
                          key={accountDetail.fundingSourceId}
                          memberAccountDetail={accountDetail}
                          handleEditItem={handleEditItem}
                          handleDeleteItem={handleDeleteItem}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};
