import {COMMUNITY_CATEGORY_API, GET_SINGLE_DISCUSSIONS_ERROR} from "../../../constants";


export const getSingleDiscussion = async ({
    discussionId,
    activeChannelID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${COMMUNITY_CATEGORY_API}/${activeChannelID}/discussions/${discussionId}?userId=${user.id}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_SINGLE_DISCUSSIONS_ERROR;
    }
}

