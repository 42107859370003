import {GET_VENDORS_KPI_API, UPDATE_VENDOR_KPI_ERROR} from "../../constants";


export const getVendorKPIList = async (
    vendorID,
) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDORS_KPI_API}?vendorid=${vendorID}`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
        }
        );
        if (!response.ok) throw UPDATE_VENDOR_KPI_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_VENDOR_KPI_ERROR;
    }
}

