import { FC, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { useStyles } from '../../utils';
import UserImg from '../../images/profile-placeholder.jpg';
import { updateProfileRoute } from '../../actions/profile';

const UserProfileDropdown: FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);
  const classes = useStyles();
  const userData: any = useSelector<{ user: any }>((state) => state.user);
  const dispatch = useDispatch();

  // on profile click
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  // on close call
  const handleClose = (event: any) => {
    if (anchorRef?.current?.contains?.(event.target)) {
      return;
    }
    setOpen(false);
  };

  function handleListKeyDown(event: any) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // reset focus to profile
  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  //on logout call
  const handleLogout = (event: any) => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/login');
    const activeMenu = {
      label: 'Logout',
      route: '/profile/messages',
    };
    dispatch(updateProfileRoute(activeMenu, navigate) as any); // TODO action builder needs to be properly handled and written
  };

  const handleClickAway = () => {
    setOpen(false);
  };

  const handleAccountSettingClick = (event: any) => {
    const activeMenu = {
      label: 'Account Settings',
      route: '/profile/account-settings',
    };
    dispatch(updateProfileRoute(activeMenu, navigate) as any); // TODO action builder needs to be properly handled and written
    handleClose(event);
  };

  return (
    <ClickAwayListener
      mouseEvent="onMouseDown"
      touchEvent="onTouchStart"
      onClickAway={handleClickAway}
    >
      <div className={classes.profileRoot}>
        <div
          className="nav-item-dropdown"
          ref={anchorRef}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <div className="nav-admin-options">
            <img src={userData?.avatar || UserImg} alt="User" title="User" />
            <span>
              {userData
                ? userData.firstname + ' ' + userData.lastname
                : 'Guest'}
            </span>
          </div>
          <i className="fas fa-chevron-down" />
        </div>
        {open && (
          <Paper elevation={3} className={classes.dropdown}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              onKeyDown={handleListKeyDown}
            >
              <MenuItem onClick={(e) => handleAccountSettingClick(e)}>
                Account Settings
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </MenuList>
          </Paper>
        )}
      </div>
    </ClickAwayListener>
  );
};
export default UserProfileDropdown;
