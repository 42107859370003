import { baseUrl, EXPORT_REBATE_RECEIPT_ERROR, REBATE_RECEIPT_NO_RECORD_ERROR} from "../../../constants";

export const exportRebateReceipt = async ({
    memberId,
    exportYear,
    exportQuarter
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUrl}/members/${memberId}/receipt-items/export?quarter=${exportQuarter}&year=${exportYear}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw REBATE_RECEIPT_NO_RECORD_ERROR;
        const blob = await response.blob();
        return blob;
    } catch (error) {
        if (error === REBATE_RECEIPT_NO_RECORD_ERROR) throw REBATE_RECEIPT_NO_RECORD_ERROR;
        else {
            throw EXPORT_REBATE_RECEIPT_ERROR;
        }
    }
}

