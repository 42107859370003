import {
  UPDATE_VENDOR_MEMBER_SELECT_USER,
  UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR,
  CLEAR_VENDOR_MEMBER_ASSIGN_MODAL,
  UPDATE_VENDOR_MEMBER_ASSIGN_MODAL,
  UPDATE_VENDOR_MEMBER_EDIT_MODAL,
  UPDATE_VENDOR_MEMBER_EDIT_DATA,
  SHOW_VENDOR_MEMBER_ASSIGN_SPINNER,
  HIDE_VENDOR_MEMBER_ASSIGN_SPINNER,
  SHOW_VENDOR_MEMBER_MODAL_LOADING,
  HIDE_VENDOR_MEMBER_MODAL_LOADING,
  UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR,
  UPDATE_VENDOR_MEMBER_CUSTOMER_ID,
  UPDATE_VENDOR_MEMBER_SELECTED_ID,
  UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM,
  UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER,
  UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION,
  UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR
} from "../../constants";

const initialState = {
  spinner: false,
  vendorUserId: 0,
  selectedMemberId: null,
  customerID: null,
  selectedMemberData: [],
  customerIDError: false,
  vendorUserError: false,
  memberAssignModal: false,
  isEditModal: false,
  loading: false,
  isEditFormUpdated: false,
  activeDistributionCenter: null,
  customerAbbreviation: null,
  customerAbbreviationError: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SHOW_VENDOR_MEMBER_MODAL_LOADING:
      return ({
        ...state,
        loading: true
      });
    case HIDE_VENDOR_MEMBER_MODAL_LOADING:
      return ({
        ...state,
        loading: false
      });
    case UPDATE_VENDOR_MEMBER_SELECT_USER:
      return {
        ...state,
        vendorUserId: action.vendorUserId,
      };
    case UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR:
      return {
        ...state,
        vendorUserError: action.vendorUserError,
      };
    case SHOW_VENDOR_MEMBER_ASSIGN_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case HIDE_VENDOR_MEMBER_ASSIGN_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case UPDATE_VENDOR_MEMBER_ASSIGN_MODAL:
      return {
        ...state,
        memberAssignModal: action.memberAssignModal,
      };
    case UPDATE_VENDOR_MEMBER_EDIT_MODAL:
      return {
        ...state,
        isEditModal: action.isEditModal,
      };
    case UPDATE_VENDOR_MEMBER_EDIT_DATA:
      return {
        ...state,
        selectedMemberData: action.selectedMemberData,
      };
    case UPDATE_VENDOR_MEMBER_CUSTOMER_ID:
      return {
        ...state,
        customerID: action.customerID,
      };
    case UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR:
      return {
        ...state,
        customerIDError: action.customerIDError,
      };
    case UPDATE_VENDOR_MEMBER_SELECTED_ID:
      return {
        ...state,
        selectedMemberId: action.selectedMemberId,
      };
    case UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM:
      return {
        ...state,
        isEditFormUpdated: action.isEditFormUpdated,
      };
    case UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER:
      return {
        ...state,
        activeDistributionCenter: action.activeDistributionCenter,
      };
    case UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION:
      return {
        ...state,
        customerAbbreviation: action.customerAbbreviation,
      };
    case UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR:
      return {
        ...state,
        customerAbbreviationError: action.customerAbbreviationError,
      };
    case CLEAR_VENDOR_MEMBER_ASSIGN_MODAL:
      return {
        ...state,
        spinner: false,
        vendorUserId: 0,
        selectedMemberId: null,
        customerID: null,
        customerIDError: false,
        vendorUserError: false,
        memberAssignModal: false,
        isEditModal: false,
        selectedMemberData: [],
        isEditFormUpdated: false,
        activeDistributionCenter: null,
        customerAbbreviation: null,
        customerAbbreviationError: false
      };
    default:
      return state;
  }
};
