import {toast} from "react-toastify";
import {
  UPDATE_VENDOR_USERS_LIST,
  CLEAR_VENDOR_USER_CREATE_MODAL,
  UPDATE_VENDOR_USER_IS_EDIT,
  UPDATE_VENDOR_USER_USERID,
  UPDATE_VENDOR_USER_CREATE_FNAME,
  UPDATE_VENDOR_USER_CREATE_LNAME,
  UPDATE_VENDOR_USER_CREATE_EMAIL,
  UPDATE_VENDOR_USER_CREATE_PHONE,
  UPDATE_VENDOR_USER_CREATE_JOBTITLE,
  UPDATE_VENDOR_USER_CREATE_ROLE,
  UPDATE_VENDOR_USER_CREATE_DC,
  UPDATE_VENDOR_USER_CREATE_FNAME_ERROR,
  UPDATE_VENDOR_USER_CREATE_LNAME_ERROR,
  UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR,
  UPDATE_VENDOR_USER_CREATE_PHONE_ERROR,
  UPDATE_VENDOR_USER_CREATE_ROLE_ERROR,
  UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
  UPDATE_VENDOR_USER_CREATE_DC_ERROR,
  SHOW_VENDOR_USER_CREATE_SPINNER,
  HIDE_VENDOR_USER_CREATE_SPINNER,
  VendorUserCreateSuccess,
  VendorUserCreateError,
  VendorUserUpdateSuccess,
  FetchError,
} from "../../../constants";

import {createVendorUser, updateVendorUser} from "../../../api/vendors";
import {showModal, closeModal} from "../../modal";
import {formatPhoneNumber} from "../../../utils";
import {removePhoneNoFormat} from "../../../utils/constants";
import getVendorUsers from "../../../api/vendors/getVendorUsers";

export const showVendorUserCreateModal = () => async (dispatch: any) => {
  dispatch(showModal(true));
};
export const clearVendorUserCreate = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_VENDOR_USER_CREATE_MODAL,
  });
  dispatch(closeModal());
};

export const clearVendorUserCreateModal = () => async (dispatch: any) => {
  dispatch({
    type: CLEAR_VENDOR_USER_CREATE_MODAL,
  });
};

export const onChangeVendorUserFirstname = (value: any) => async (dispatch: any) => {
  let flag = value && value.trim().length > 0 ? false : true;
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_FNAME_ERROR,
    errorFirstname: flag,
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_FNAME,
    firstName: value,
  });
};
export const onChangeVendorUserLastname = (value: any) => async (dispatch: any) => {
  let flag = value && value.trim().length > 0 ? false : true;
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_LNAME_ERROR,
    errorLastname: flag,
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_LNAME,
    lastName: value,
  });
};
export const onChangeVendorUserEmail = (value: any) => async (dispatch: any) => {
  let flag = value && value.trim().length > 0 ? false : true;
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR,
    errorEmail: flag,
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_EMAIL,
    email: value,
  });
};
export const onChangeVendorUserJobtitle =
  (value: any) => async (dispatch: any, getState: any) => {
    const {vendorUserCreate} = getState();
    const {selectedRole} = vendorUserCreate;
    let flag = isJobtitleValid(selectedRole, value);

    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_JOBTITLE,
      jobTitle: value,
    });
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
      errorJobTitle: !flag,
    });
  };
export const onChangeVendorUserPhone = (value: any) => async (dispatch: any) => {
  let flag = value && value.trim().length > 0 ? false : true;
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_PHONE_ERROR,
    errorPhoneNo: flag,
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_PHONE,
    phoneNo: formatPhoneNumber(value),
  });
};
export const onChangeVendorUserCreateRole =
  (roleid: any) => async (dispatch: any, getState: any) => {

    const {vendorUserCreate} = getState();
    const {jobTitle} = vendorUserCreate;

    const titleFlag = isJobtitleValid(roleid, jobTitle);
    const formatedId = returnValidatedNumber(roleid);
    const isValidId = validateSelectedId(formatedId);

    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_ROLE,
      selectedRole: formatedId,
    });
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_ROLE_ERROR,
      errorRole: !isValidId,
    });
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
      errorJobTitle: !titleFlag,
    });
  };
export const onChangeVendorUserCreateDC = (dcId: any) => async (dispatch: any) => {
  const formatedId = returnValidatedNumber(dcId);
  const isValidId = validateSelectedId(formatedId);

  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_DC,
    selectedDC: formatedId,
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_DC_ERROR,
    errorDC: isValidId ? false : true,
  });
};

export const onAddNewVendorUser = () => async (dispatch: any, getState: any) => {
  const {vendorUserCreate, vendor} = getState();
  const {vendorID, type} = vendor;
  const {
    firstName,
    lastName,
    email,
    phoneNo,
    selectedRole,
    jobTitle,
    selectedDC,
  } = vendorUserCreate;
  const isValidFirstname = validateLinkText(firstName);
  const isValidLastname = validateLinkText(lastName);
  const isValidEmail = validateLinkText(email);
  const isValidPhone = validateLinkText(phoneNo);
  const isValidRole = validateSelectedId(selectedRole);
  const isValidJobtitle = validateLinkText(jobTitle);
  const isValidDC = validateSelectedId(selectedDC);

  const isError =
    !isValidFirstname ||
    !isValidLastname ||
    !isValidEmail ||
    !isValidPhone ||
    !isValidRole ||
    !isValidJobtitle ||
    !isValidDC;

  if (!isValidFirstname) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_FNAME_ERROR,
      errorFirstname: true,
    });
  }
  if (!isValidLastname) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_LNAME_ERROR,
      errorLastname: true,
    });
  }
  if (!isValidEmail) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR,
      errorEmail: true,
    });
  }
  if (!isValidPhone) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_PHONE_ERROR,
      errorPhoneNo: true,
    });
  }
  if (!isValidRole) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_ROLE_ERROR,
      errorRole: true,
    });
  }
  if (!isValidJobtitle) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
      errorJobTitle: true,
    });
  }
  if (!isValidDC) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_DC_ERROR,
      errorDC: true,
    });
  }
  if (isError) {
    dispatch({
      type: HIDE_VENDOR_USER_CREATE_SPINNER,
      spinner: false,
    });
  } else {
    dispatch({
      type: SHOW_VENDOR_USER_CREATE_SPINNER,
      spinner: true,
    });
  }
  try {
    if (!isError) {
      let phone = removePhoneNoFormat(phoneNo);
      const response = await createVendorUser({
        vendorID,
        type,
        firstName,
        lastName,
        email,
        phone,
        selectedRole,
        jobTitle,
        selectedDC,
      });
      const {success} = response;
      success ? toast.success(VendorUserCreateSuccess) : null;
      if (success) {
        const getVendorUsersResult = await getVendorUsers(vendorID);
        dispatch({
          type: UPDATE_VENDOR_USERS_LIST,
          vendorUsersList: getVendorUsersResult.data,
        });
      }
      dispatch({
        type: HIDE_VENDOR_USER_CREATE_SPINNER,
        spinner: false,
      });
      if (success) {
        dispatch({
          type: CLEAR_VENDOR_USER_CREATE_MODAL,
        });
      }
      dispatch(closeModal());
    }
  } catch (error) {

    dispatch({
      type: HIDE_VENDOR_USER_CREATE_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: CLEAR_VENDOR_USER_CREATE_MODAL
      });
    }
    if (error) {
      toast.error(VendorUserCreateError);
    }
  }
};

export const showVendorUser = (selectedItem: any) => async (dispatch: any) => {
  const {userId, firstName, lastName, email, phone, roleId, jobTitle, distributionId, vendorUserId} = selectedItem
  dispatch(showModal(true));
  dispatch({
    type: UPDATE_VENDOR_USER_IS_EDIT,
    isEdit: true
  });
  dispatch({
    type: UPDATE_VENDOR_USER_USERID,
    userId: userId
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_FNAME,
    firstName: firstName
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_LNAME,
    lastName: lastName
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_EMAIL,
    email: email
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_PHONE,
    phoneNo: formatPhoneNumber(phone)
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_ROLE,
    selectedRole: roleId
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_JOBTITLE,
    jobTitle: jobTitle
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_DC,
    selectedDC: distributionId
  });
  dispatch({
    type: UPDATE_VENDOR_USER_CREATE_LNAME,
    lastName: lastName
  });

};

export const onEditVendorUser = () => async (dispatch: any, getState: any) => {

  const {vendorUserCreate, vendor} = getState();
  const {vendorID, type} = vendor;
  const {
    userId,
    selectedRole,
    jobTitle,
    selectedDC,
  } = vendorUserCreate;

  const isValidRole = validateSelectedId(selectedRole);
  const isValidJobtitle = isJobtitleValid(selectedRole, jobTitle);
  const isValidDC = validateSelectedId(selectedDC);

  const isError =
    !isValidRole ||
    !isValidJobtitle ||
    !isValidDC;

  if (!isValidRole) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_ROLE_ERROR,
      errorRole: true,
    });
  }
  if (!isValidJobtitle) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR,
      errorJobTitle: true,
    });
  }
  if (!isValidDC) {
    dispatch({
      type: UPDATE_VENDOR_USER_CREATE_DC_ERROR,
      errorDC: true,
    });
  }
  if (isError) {
    dispatch({
      type: HIDE_VENDOR_USER_CREATE_SPINNER,
      spinner: false,
    });
  } else {
    dispatch({
      type: SHOW_VENDOR_USER_CREATE_SPINNER,
      spinner: true,
    });
  }
  try {
    if (!isError) {

      const response = await updateVendorUser({
        vendorID,
        type,
        userId,
        selectedRole,
        jobTitle,
        selectedDC,
      });

      const {success} = response;
      success ? toast.success(VendorUserUpdateSuccess) : null;
      if (success) {
        const getVendorUsersResult = await getVendorUsers(vendorID);
        dispatch({
          type: UPDATE_VENDOR_USERS_LIST,
          vendorUsersList: getVendorUsersResult.data,
        });
      }
      dispatch({
        type: HIDE_VENDOR_USER_CREATE_SPINNER,
        spinner: false,
      });
      if (success) {
        dispatch({
          type: CLEAR_VENDOR_USER_CREATE_MODAL,
        });
      }
      dispatch(closeModal());
    }
  } catch (error) {

    dispatch({
      type: HIDE_VENDOR_USER_CREATE_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: CLEAR_VENDOR_USER_CREATE_MODAL
      });
    }
    if (error) {
      toast.error(VendorUserCreateError);
    }
  }
}

const validateLinkText = (linkText: string) => {
  const textRegex = (text: string) => {
    if (text === null || text === "") return false;
    else if (text.startsWith(" ")) return false;
    else if (text && text.charAt(text.length - 1) === " ") return false;
    else return true;
  };

  const isValidText = textRegex(linkText);
  if (isValidText) {
    return true;
  } else {
    return false;
  }
};
const validateSelectedId = (option: any) => {
  if (option && option != 0) return true;
  else {
    return false;
  }
};
const isJobtitleValid = (roleId: any, jobTitle: any) => {
  return roleId == "6" ? (jobTitle.length > 0 ? true : false) : true;
};

const returnValidatedNumber = (option: any) => {
  return option && !isNaN(parseInt(option)) ? parseInt(option) : "0";
};
