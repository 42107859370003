import {
    UPDATE_PAGINATION_SELECTED_ROWS,
    UPDATE_PAGINATION_PAGE,
    UPDATE_PAGE_TYPE
} from "../constants";

const initialState = {
    rowsPerPage: 20,
    page: 0,
    pageType: null
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PAGINATION_SELECTED_ROWS:
            return {
                ...state,
                rowsPerPage: action.rowsPerPage,
            };
        case UPDATE_PAGINATION_PAGE:
            return {
                ...state,
                page: action.page,
            };
        case UPDATE_PAGE_TYPE:
            return {
                ...state,
                pageType: action.pageType,
            };
        default:
            return state;
    }
};
