import { GET_MEMBER_LOCATIONS_API, UPDATE_MEMBER_LOCATION_LIST_ERROR } from "../../constants";

export const getMemberLocationList = async (
    id
) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const url = `${GET_MEMBER_LOCATIONS_API}?parentId=${id}`;
        const response = await fetch(url, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
              }), 
        }
        );
        if(!response.ok) throw UPDATE_MEMBER_LOCATION_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_MEMBER_LOCATION_LIST_ERROR;
    } 
}
