import { useMachine } from '@xstate/react';
import closeableDisplayMachine from '../machines/closeableDisplayMachine';

export const useCloseableDisplay = (
  defaultClosed = false,
): [
  opened: boolean,
  controls: { close: () => void; open: () => void; toggle: () => void },
] => {
  const [state, send] = useMachine(closeableDisplayMachine, {
    context: { defaultClosed },
  });
  return [
    state.matches('opened'),
    {
      close: () => send('close'),
      open: () => send('open'),
      toggle: () => send('toggle'),
    },
  ];
};

export default useCloseableDisplay;
