import React from "react";
import Button from "@material-ui/core/Button";

import {
  getRebatesExtensionClassName,
  getFileClassName,
} from "../../../../../../utils";
import UploadImg from "../../../../../../images/file-upload.svg";


export const FileTwoUpdate = ({
  memberRebates,
  handleMemberRebatesFileTwo,
  onMemberRebatesFileTwoRemove
}) => {

  const {fileTwoName, fileTwoSize, fileTwoExtension} = memberRebates;

  const onInputClick = (event) => {
    event.target.value = ''
  }

  return (
    <div className="edit-rebate-documents-box">
      {
        fileTwoName === null ? (
          <Button className="file-filed-document" component="label">
            <div className="file-filed-text-size-xs" style={{fontWeight:"bold"}}>
              FoodBuy Allowances Solutions
            </div>
            <img src={UploadImg} />
            <div className="file-filed-text-size-sm">
              Upload XLS OR XLSX files
            </div>
            <div className="file-filed-text-size-md">
              Drop your files here.{" "}
            </div>
            <div>
              or <span className="file-filed-text-size-highlight">browse</span>
            </div>
            <input
              type="file"
              id="fileUpload"
              title=""
              value=""
              onChange={(event) =>
                handleMemberRebatesFileTwo(event.target.files[0])
              }
            />
            {fileTwoExtension && (
              <div className="error-wrapper">
                <i className="fas fa-exclamation-triangle error-icon" />
                <div className="error-text">
                  {"Please upload only xls or xlsx extenstion file."}
                </div>
              </div>
            )}
          </Button>
        ) : (
          <div className="edit-doc-section">
            <div className="edit-doc-section-box">
              <div className="documents-grid-img">
                <i className={getFileClassName(fileTwoExtension)} />
                <span
                  className={getRebatesExtensionClassName(fileTwoExtension)}
                >
                  {fileTwoExtension}
                </span>
              </div>
              <div className="edit-doc-section-name">
                {fileTwoName}
              </div>
              <div className="edit-doc-section-size">
                {fileTwoSize}
              </div>
              <div className="edit-doc-section-buttons">
                <Button
                  className="remove-button"
                  onClick={onMemberRebatesFileTwoRemove}
                >
                  Remove
                </Button>
                <label className="replace-button" for={'fileTwoUpdate'}>
                  Replace
                </label>
                <input
                  type="file"
                  id={'fileTwoUpdate'}
                  hidden
                  onClick={onInputClick}
                  onChange={(event) => handleMemberRebatesFileTwo(event.target.files[0])}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

