import { createMachine, sendParent } from 'xstate';
import { OpportunityQuarter } from '../../../../../common';
import serviceInterceptor from '../../../../../services/ServiceInterceptor';

interface OpportunitiesPublishContext {
  selectedOpportunityQuarter: OpportunityQuarter;
}

type OpportunitiesPublishEvent = { type: 'cancel' } | { type: 'publish' };

export const opportunitiesPublishMachine = createMachine(
  {
    id: 'opportunitiesPublish',
    tsTypes: {} as import('./opportunitiesPublishMachine.typegen').Typegen0,
    schema: {
      context: {} as OpportunitiesPublishContext,
      events: {} as OpportunitiesPublishEvent,
      services: {} as {
        'publish rebate opportunities': {
          data: {
            success: boolean;
            data: any;
          };
        };
      },
    },
    initial: 'confirming publish',
    states: {
      'confirming publish': {
        on: {
          cancel: {
            actions: 'send parent close event',
          },
          publish: 'publishing',
        },
      },
      publishing: {
        tags: ['loading', 'publishing', 'submitting'],
        invoke: {
          id: 'publish rebate opportunities',
          src: 'publish rebate opportunities',
          onDone: 'complete',
          onError: {
            actions: 'notify failure',
            target: 'confirming publish',
          },
        },
      },
      complete: { type: 'final' },
    },
  },
  {
    actions: {
      'send parent close event': sendParent('close modal'),
      'notify failure': () =>
        console.error('Failed to publish rebate opportunities.'),
    },
    services: {
      'publish rebate opportunities': (context) =>
        serviceInterceptor
          .patch(
            `/rebate-product-list-records/publish?quarter=${context.selectedOpportunityQuarter.quarter}&year=${context.selectedOpportunityQuarter.year}`,
          )
          .then((response) => response.data),
    },
  },
);

export type OpportunitiesPublishMachine = typeof opportunitiesPublishMachine;
export default opportunitiesPublishMachine;
