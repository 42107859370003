import {
  UPLOAD_MEMBER_REBATES_ERROR,
  UploadMemberRebatesError,
} from '../../../constants';
import { rebatesModalSpinner, hideMemberRebateModal } from '../rebates';
import {
  updateMemberRebatesFileDraft,
  getMemberRebatesQuarter,
} from './memberRebates';
import { clearMemberRebateModal } from './fileUploadAction';
import { updateEarnedRebatesRecord } from '../../../api/rebates/memberRebates/updateEarnedRebatesRecord';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createEarnedRebatesRecord } from '../../../api/rebates/memberRebates/createEarnedRebatesRecord';

export const onUploadMemberRebates = () => async (dispatch, getState) => {
  const { memberRebates } = getState();
  const {
    quarter,
    year,
    fileOneContent,
    fileTwoContent,
    fileThreeContent,
    isEdit,
    memberActiveQuarter,
  } = memberRebates;
  dispatch(rebatesModalSpinner(true));
  try {
    let res = { success: false };
    if (isEdit) {
      res = await updateEarnedRebatesRecord({
        earnedRebatesRecordId: memberActiveQuarter.id,
        foodbuyItemLevelSpend: fileOneContent,
        foodbuyAllowancesSol: fileTwoContent,
        buyRightSol: fileThreeContent,
      });
    } else {
      res = await createEarnedRebatesRecord({
        foodbuyItemLevelSpend: fileOneContent,
        foodbuyAllowancesSol: fileTwoContent,
        buyRightSol: fileThreeContent,
        year,
        quarter,
      });
    }
    const { success, message } = res;
    if (success) {
      dispatch(updateMemberRebatesFileDraft(true));
      dispatch(rebatesModalSpinner(false));
      toast.success(message);
      dispatch(getMemberRebatesQuarter());
    }
    dispatch(clearMemberRebateModal());
    dispatch(hideMemberRebateModal());
    dispatch(rebatesModalSpinner(false));
  } catch (error) {
    dispatch(rebatesModalSpinner(false));
    const { success, data } = error || {};
    if (!success) {
      const { message } = data || '';
      message && toast.error(message);
    }
    if (error === UPLOAD_MEMBER_REBATES_ERROR) {
      toast.error(UploadMemberRebatesError);
    }
  }
};
