import serviceInterceptor from '../../../services/ServiceInterceptor';

export const createEarnedRebatesRecord = async ({
  year,
  quarter: poorlyFormattedQuarter,
  foodbuyItemLevelSpend,
  foodbuyAllowancesSol,
  buyRightSol,
}) => {
  let quarter = null;
  switch (poorlyFormattedQuarter) {
    case 'Q1':
    case 'q1':
    case '1':
    case 1:
      quarter = 1;
      break;
    case 'Q2':
    case 'q2':
    case '2':
    case 2:
      quarter = 2;
      break;
    case 'Q3':
    case 'q3':
    case '3':
    case 3:
      quarter = 3;
      break;
    case 'Q4':
    case 'q4':
    case '4':
    case 4:
      quarter = 4;
      break;
  }
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('quarter', quarter);
    bodyFormData.append('year', year);
    bodyFormData.append('foodbuyItemLevelSpend', foodbuyItemLevelSpend);
    bodyFormData.append('foodbuyAllowancesSol', foodbuyAllowancesSol);
    bodyFormData.append('buyRightSol', buyRightSol);
    const response = await serviceInterceptor.post(
      '/earned-rebates-records',
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw response;
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};
