import {
  UPDATE_VENDOR_USERS_LIST_ERROR,
  UPDATE_VENDOR_USERS_LIST,
  UPDATE_SELECTED_VENDOR_USER,
  UPDATE_DELETE_VENDOR_FLAG,
  CLEAR_VENDOR_USERS_LIST,
  VendorUserListError,
  VendorDeleteUserError,
  VendorUserDeleteSuccess,
  FetchError,
  UPDATE_VENDOR_USERS_SPINNER,
} from '../../../constants';
import { deleteVendorUser } from '../../../api/vendors';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import getVendorUsers from '../../../api/vendors/getVendorUsers';

export const clearVendorUserList = () => ({
  type: CLEAR_VENDOR_USERS_LIST,
});

export const getVendorUsersAction =
  () => async (dispatch: any, getState: any) => {
    const { vendor } = getState();
    const { vendorID } = vendor;
    dispatch({
      type: UPDATE_VENDOR_USERS_SPINNER,
      spinner: true,
    });
    try {
      const getVendorUsersResult = await getVendorUsers(vendorID);
      dispatch({
        type: UPDATE_VENDOR_USERS_LIST,
        vendorUsersList: getVendorUsersResult.data,
      });
      dispatch({
        type: UPDATE_VENDOR_USERS_SPINNER,
        spinner: false,
      });
    } catch (error) {
      dispatch({
        type: UPDATE_VENDOR_USERS_SPINNER,
        spinner: false,
      });
      if (error === UPDATE_VENDOR_USERS_LIST_ERROR) {
        toast.error(VendorUserListError);
      } else {
        toast.error(FetchError);
      }
    }
  };

export const updateDeleteVendorUsers = (item: any) => async (dispatch: any) => {
  dispatch({
    type: UPDATE_SELECTED_VENDOR_USER,
    selectedUser: item,
  });
  dispatch({
    type: UPDATE_DELETE_VENDOR_FLAG,
    isDeleteUser: true,
  });
};

export const handleDeleteClose = () => async (dispatch: any) => {
  dispatch({
    type: UPDATE_DELETE_VENDOR_FLAG,
    isDeleteUser: false,
  });
};

export const handleDeleteVendorUser =
  () => async (dispatch: any, getState: any) => {
    const { vendor } = getState();
    const { selectedUser, vendorID, type } = vendor;

    dispatch({
      type: UPDATE_VENDOR_USERS_SPINNER,
      spinner: true,
    });

    try {
      const response = await deleteVendorUser({
        selectedUser,
        vendorID,
        type,
      });
      const getVendorUsersResult = await getVendorUsers(vendorID);
      dispatch({
        type: UPDATE_VENDOR_USERS_LIST,
        vendorUsersList: getVendorUsersResult.data,
      });
      dispatch({
        type: UPDATE_SELECTED_VENDOR_USER,
        selectedUser: null,
      });
      dispatch({
        type: UPDATE_DELETE_VENDOR_FLAG,
        isDeleteUser: false,
      });
      dispatch({
        type: UPDATE_VENDOR_USERS_SPINNER,
        spinner: false,
      });
      const { success } = response;
      success ? toast.success(VendorUserDeleteSuccess) : null;
    } catch (error) {
      dispatch({
        type: UPDATE_SELECTED_VENDOR_USER,
        selectedUser: null,
      });
      dispatch({
        type: UPDATE_DELETE_VENDOR_FLAG,
        isDeleteUser: false,
      });
      dispatch({
        type: UPDATE_VENDOR_USERS_SPINNER,
        spinner: false,
      });
      if (error) {
        toast.error(VendorDeleteUserError);
      }
    }
  };
