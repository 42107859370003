import React from "react";
import { useEffect } from "react";
import DialogWindow from "../../../../components/DialogWindow";
import { AddAdsContent } from "./AddAdsContent";

export const AddAdsModal = (props) => {
  const {
    getProductBrands,
    getProductNames,
    showModal,
    clearAdsForm,
    handleCloseAddAdsModal,
    onAddNewAds,
    onEditAds,
    adsCreate: {
      loading,
      isEdit,
      isFormUpdated,
    }
  } = props;

  useEffect(()=>{
    getProductBrands();
    return ()=>{
      clearAdsForm()
    }
  },[]);

  const dialogWindowConfig = () => {
    const newConfig = {
      title: isEdit ? "Edit Ad" : "Create New Ad",
      isCancel: true,
      submitText: "Publish",
      isGoBack: false,
      backText: "",
    };
    return newConfig;
  };
  
  return (
    <DialogWindow
      openDialog={showModal}
      dialogConfiguration={dialogWindowConfig()}
      handleClose={handleCloseAddAdsModal}
      handleSubmit={isEdit ? onEditAds : onAddNewAds}
      isButtonDisabled={!isFormUpdated}
      spinnerEnabled={loading} 
      fullWidth={true}
      maxWidth="md"
    >
      <AddAdsContent {...props} />
    </DialogWindow>
  );
};
