import {
  UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST,
  UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER,
  UPDATE_DASHBORD_OPPORTUNITY_LIST,
  PRODUCT_REBATES_QUARTER_LIST_ERROR,
  MEMBER_REBATES_QUARTER_ERROR,
  DashboardOppQuarterListError,
  DashboardOppListError,
} from '../../constants';
import { updateActiveTab } from '../rebates';
import {
  DashboardOpportunityQuarterSpinner,
  DashboardOpportunityListSpinner,
} from './dashboardSpinners';
import {
  getProductRebatesQuarters,
  getProductRebatesQuarterList,
} from '../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { NavigateFunction } from 'react-router-dom';

export const updateOpportunitiesRoute =
  (navigate: NavigateFunction) => async (dispatch: any, getState: any) => {
    const { user } = getState();
    const { isBRAdmin, vendorusers } = user;
    navigate('/rebates');
    if (isBRAdmin || vendorusers?.length > 0) {
      dispatch(updateActiveTab(2));
    } else if (user?.memberusers?.length > 0) {
      dispatch(updateActiveTab(1));
    } else {
      dispatch(updateActiveTab(0));
    }
  };

export const getOpportunityQuarters = () => async (dispatch: any) => {
  dispatch(DashboardOpportunityQuarterSpinner(true));
  try {
    const response = await getProductRebatesQuarters();
    const { data, success } = response;
    if (success) {
      const filteredData = data?.filter((item: any) => item.status === 'PUBLISHED');
      dispatch({
        type: UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST,
        dashboardOppQuarterList: filteredData,
      });
      dispatch({
        type: UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER,
        dashboardOppActiveQuarter: filteredData[0],
      });
      dispatch(getOpportunityList(filteredData[0]));
      dispatch(DashboardOpportunityQuarterSpinner(false));
    }
    dispatch(DashboardOpportunityQuarterSpinner(false));
  } catch (error) {
    dispatch(DashboardOpportunityQuarterSpinner(false));
    if (error === MEMBER_REBATES_QUARTER_ERROR) {
      toast.error(DashboardOppQuarterListError);
    }
  }
};

export const getOpportunityList = (selectedQuarter: any) => async (dispatch: any) => {
  const quarter = selectedQuarter !== null && selectedQuarter?.quarter;
  const year = selectedQuarter !== null && selectedQuarter?.year;

  if (quarter && year) {
    try {
      dispatch(DashboardOpportunityListSpinner(true));
      const response = await getProductRebatesQuarterList({
        quarter,
        year,
      });
      const { data } = response;
      if (data) {
        dispatch({
          type: UPDATE_DASHBORD_OPPORTUNITY_LIST,
          dashboardOppList: data,
        });
        dispatch(DashboardOpportunityListSpinner(false));
      }
      dispatch(DashboardOpportunityListSpinner(false));
    } catch (error) {
      dispatch(DashboardOpportunityListSpinner(false));
      if (error === PRODUCT_REBATES_QUARTER_LIST_ERROR) {
        toast.error(DashboardOppListError);
      }
    }
  }
};

export const handleOppQuarterChange = (selectedQuarter: any) => async (dispatch: any) => {
  dispatch({
    type: UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER,
    dashboardOppActiveQuarter: selectedQuarter,
  });
  dispatch(getOpportunityList(selectedQuarter));
};

export const getDashboardOpportunityData = () => async (dispatch: any) => {
  try {
    dispatch(getOpportunityQuarters());
  } catch (error) {}
};
