import { FC, Fragment, useState } from 'react';
import { CommunityTabPanel } from './CommunityTabPanel';
import CommunityCommentsContent from './CommunityCommentsContent';
import { Loader } from '../../../utils';
import { Button, Tab, Tabs } from '@material-ui/core';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const CommunityQuestionsComments: FC<any> = (props) => {
  const { communityDiscussion, communityComments, loadMoreComments, lockedAt } =
    props;

  const {
    activeDiscussion,
    activeDiscussionComments,
    isLoadMore,
    loadMoreSpinner,
    pageNumber,
    totalCount,
  } = communityDiscussion || {};

  const { spinner } = communityComments;

  const showLoadMoreButton = activeDiscussionComments.length !== totalCount;

  const { commentCount } = activeDiscussion || '';
  const [value, setValue] = useState(0);

  const handleChange = (_: any, newValue: any) => {
    setValue(newValue);
  };

  const commentsList =
    value === 0
      ? activeDiscussionComments.mostRecent
      : activeDiscussionComments.mostLiked;

  if (activeDiscussionComments && activeDiscussionComments.length === 0) {
    return null;
  } else {
    return (
      <Fragment>
        <div className="community-comments-box">
          {spinner && (
            <div className={'loader-wrapper'}>
              <div className="document-loader">
                <Loader size={40} />
              </div>
            </div>
          )}
          <div className="community-tab">
            <div className="community-comments-number">
              {commentCount} Comments
            </div>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              className="community-comments-tab-labels-custom"
            >
              <Tab label="Most Recent" {...a11yProps(0)} />
              <Tab label="Most Liked" {...a11yProps(1)} />
            </Tabs>
            <CommunityTabPanel value={value} index={0}>
              <CommunityCommentsContent
                {...props}
                commentsList={commentsList}
                lockedAt={lockedAt}
              />
            </CommunityTabPanel>
            <CommunityTabPanel value={value} index={1}>
              <CommunityCommentsContent
                {...props}
                commentsList={commentsList}
                lockedAt={lockedAt}
              />
            </CommunityTabPanel>
          </div>

          {isLoadMore && showLoadMoreButton && (
            <Button
              variant="contained"
              size="small"
              className={
                loadMoreSpinner
                  ? 'btn-community-load-more button-disable'
                  : 'btn-community-load-more'
              }
              onClick={() => loadMoreComments(pageNumber + 1)}
            >
              <span className="button-spinner-wrapper">
                Load more comments{' '}
                {loadMoreSpinner && (
                  <div className="button-spinner">
                    <Loader size={15} color={'white'} />
                  </div>
                )}
              </span>
            </Button>
          )}
        </div>
      </Fragment>
    );
  }
};

export default CommunityQuestionsComments;
