import { LOGIN_ERROR } from '../constants';
import serviceInterceptor from '../services/ServiceInterceptor';

export const submitLogin = async ({ email, password }) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('email', email);
    bodyFormData.append('password', password);

    const response = await serviceInterceptor.post('/auth', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data;
  } catch (error) {
    if (error?.response?.data) {
      return error.response.data;
    } else {
      throw LOGIN_ERROR;
    }
  }
};
