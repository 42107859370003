import { FC } from 'react';
import { DwollaFundingSource, DwollaTransfer } from '../../../common';

export const StatusLabel: FC<{
  deliveredAt: Date | null;
  fundingSource?: DwollaFundingSource;
  transferInfo?: DwollaTransfer;
}> = ({ deliveredAt, fundingSource, transferInfo }) => {
  let label = 'Pending',
    className = 'rebate-pending';

  if (transferInfo) {
    switch (transferInfo.status) {
      case 'cancelled':
        label = 'Cancelled';
        className = 'rebate-cancelled';
        break;
      case 'failed':
        label = 'Failed';
        className = 'rebate-failed';
        break;
      case 'processed':
        label = 'Completed';
        className = 'rebate-completed';
        break;
      case 'pending':
      default:
        break;
    }
  } else if (fundingSource) {
    // TODO should this display something different?
  } else if (deliveredAt) {
    className = 'rebate-completed';
    label = 'Completed';
  }

  return <span className={className}>{label}</span>;
};

export default StatusLabel;
