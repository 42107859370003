import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { getExtensionClassName, getFileClassName } from '../../../../../utils';
import UploadImg from '../../../../../images/file-upload.svg';

export const ProductRebatesFileUpload: FC<any> = ({
  productRebates: {
    selectedFileName,
    selectedFileSize,
    selectedFileExtension,
    fileExtensionError,
  },
  onProductRebatesFileSelect,
  onProductRebatesFileRemove,
}) => {
  return (
    <div className="rebates-file-upload">
      <Grid container spacing={2} justifyContent={'center'}>
        <Grid item xs={12} lg={6}>
          {selectedFileName === null || fileExtensionError ? (
            <Button className="file-filed-document" component="label">
              <img src={UploadImg} />
              <div className="file-filed-text-size-sm">
                Upload XLS OR XLSX files
              </div>
              <div className="file-filed-text-size-md">
                Drop your files here.{' '}
              </div>
              <div>
                or{' '}
                <span className="file-filed-text-size-highlight">browse</span>
              </div>
              <input
                type="file"
                id="fileUpload"
                title=""
                value=""
                onChange={(event) =>
                  onProductRebatesFileSelect(event?.target?.files?.[0])
                }
              />
              {fileExtensionError && (
                <div className="error-wrapper">
                  <i className="fas fa-exclamation-triangle error-icon" />
                  <div className="error-text">
                    {'Please upload only xls or xlsx extenstion file.'}
                  </div>
                </div>
              )}
            </Button>
          ) : (
            <div className="edit-doc-section">
              <div className="edit-doc-section-box">
                <div className="documents-grid-img">
                  <i className={getFileClassName(selectedFileExtension)} />
                  <span
                    className={getExtensionClassName(selectedFileExtension)}
                  >
                    {selectedFileExtension}
                  </span>
                </div>

                <div className="edit-doc-section-name">{selectedFileName}</div>
                <div className="edit-doc-section-size">{selectedFileSize}</div>
                <div className="edit-doc-section-buttons">
                  <Button
                    className="remove-button"
                    onClick={onProductRebatesFileRemove}
                  >
                    Remove
                  </Button>
                  <label className="replace-button" htmlFor="replaceFile">
                    Replace
                  </label>
                  <input
                    type="file"
                    id="replaceFile"
                    hidden
                    onChange={(event) =>
                      onProductRebatesFileSelect(event?.target?.files?.[0])
                    }
                  />
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

export default ProductRebatesFileUpload;
