import { FC } from 'react';
import { useEffect } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import '../../css/fonts.css';
import '../../css/all.min.css';
import '../../css/style.css';
import { DashboardTitle } from './section/DashboardTitle';
import { DashboardKPI } from './section/DashboardKPI';
import DashboardEarnedRebates from './section/DashboardEarnedRebates';
import DashboardOpportunities from './section/DashboardOpportunities';
import { DateTime } from 'luxon';
import AuthorizationService from '../../authorization/AuthorizationService';
import OneSignal from 'react-onesignal';

export const DashboardMain: FC<{
  getDashboardInfo: () => void;
  updateActiveEarningsRoute: (navigate: NavigateFunction) => void;
  updateOpportunitiesRoute: (navigate: NavigateFunction) => void;
  dashboardOpportunities:any;
  getUserRoleList: () => void;
}> = ({
  getDashboardInfo,
  updateActiveEarningsRoute,
  updateOpportunitiesRoute,
  dashboardOpportunities,
  getUserRoleList,
}) => {
  const navigate = useNavigate();

  const showDashboardEarnedRebates = AuthorizationService.isAuthorizedHTML('dashboardEarnedRebates') ? true : false;
  let user = JSON.parse(localStorage.getItem('user') || '{}');
  const isMemberAdmin = (user?.memberusers || []).filter((mu:any) => mu.roleid === 3)?.length > 0;
  const activeQuarter = dashboardOpportunities?.dashboardOppActiveQuarter;
  const activeQuarterValue = activeQuarter?.quarter && +activeQuarter.quarter;
  const activeYearValue = activeQuarter?.year && +activeQuarter.year;

  const initialContext = {
    page: 0,
    pageSize: 5,
    opportunityList: [],
    selectedQuarter: activeQuarterValue ?? DateTime.now().quarter,
    selectedYear: activeYearValue ?? DateTime.now().year,
  }

  useEffect(() => {
    let user = localStorage.getItem('user');
    if (!user) {
      navigate('/');
    } else {
      //getDashboardInfo();
      //getUserRoleList();
    }
  }, []);

useEffect(() => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('One Signal APP ID', process.env.REACT_APP_APPID || '');
  } else {
    if (isMemberAdmin || user?.isBRAdmin) {
      OneSignal.init({ appId: process.env.REACT_APP_APPID || '' }).then(
        (res) => {
          // OneSignal.setExternalUserId(JSON.stringify(user?.email));
          OneSignal.isPushNotificationsEnabled().then((isEnabled) => {
            if (isEnabled) {
              console.log('Push notification enabled ', isEnabled);
            }
          });
          // OneSignal.showSlidedownPrompt().then((res) => console.log(res));
        }
      );
      OneSignal.setExternalUserId(user?.email)
        .then((res) => {
          console.log('External user ID set successfully.', res);
        })
        .catch((error) => {
          console.log('Error setting external user ID:', error);
        });
    }
  }
}, []);

  return (
    <div>
      <DashboardTitle />
      <div className="container">
        <DashboardKPI />
        {showDashboardEarnedRebates &&
          <DashboardEarnedRebates
            updateActiveEarningsRoute={updateActiveEarningsRoute}
          />}
        <DashboardOpportunities
          updateOpportunitiesRoute={updateOpportunitiesRoute} initialContext={initialContext} key={activeQuarterValue}
        />
      </div>
    </div>
  );
};
