import {
    UPDATE_VENDOR_DC_LIST,
    CLEAR_DC_MODAL,
    UPDATE_DC_IS_EDIT,
    UPDATE_VENDOR_DC_NAME_ERROR,
    UPDATE_VENDOR_DC_NAME,
    UPDATE_DC_FORM_INPUTS,
    UPDATE_VENDOR_DC_STREET_ERROR,
    UPDATE_VENDOR_DC_STREET,
    UPDATE_VENDOR_DC_CITY_ERROR,
    UPDATE_VENDOR_DC_CITY,
    UPDATE_VENDOR_DC_STATE_ERROR,
    UPDATE_VENDOR_DC_STATE,
    UPDATE_VENDOR_DC_ZIP_ERROR,
    UPDATE_VENDOR_DC_ZIP,
    UPDATE_VENDOR_DC_CODE_ERROR,
    UPDATE_VENDOR_DC_CODE,
    UPDATE_VENDOR_DC_ID,
    SHOW_VENDOR_DC_FORM_SPINNER,
    HIDE_VENDOR_DC_FORM_SPINNER,
    VendorDCUpdateSuccess,
    VendorDCUpdateError
} from "../../../constants";

import {
    updateVendorDC,
    getVendorDistributionCenterList
} from '../../../api/vendors';


import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {removeZipcodeFormat, isValidZipcode} from "../../../utils";

import {showModal, closeModal} from '../../modal';

export const showEditDcModal = (selectedItem) => async (dispatch) => {

    const {id, name, street, city, state, zip, code} = selectedItem;

    dispatch(showModal());
    dispatch({
        type: UPDATE_DC_IS_EDIT,
        isEdit: true
    });
    dispatch({
        type: UPDATE_VENDOR_DC_ID,
        id: id
    });
    dispatch({
        type: UPDATE_VENDOR_DC_NAME,
        dcName: name
    });
    dispatch({
        type: UPDATE_VENDOR_DC_STREET,
        dcStreet: street
    });
    dispatch({
        type: UPDATE_VENDOR_DC_CITY,
        dcCity: city
    });
    dispatch({
        type: UPDATE_VENDOR_DC_STATE,
        dcState: state
    });
    dispatch({
        type: UPDATE_VENDOR_DC_ZIP,
        dcZip: zip
    });
    dispatch({
        type: UPDATE_VENDOR_DC_CODE,
        dcCode: code
    });
};
export const onEditDC = () => async (dispatch, getState) => {

    const {vendorDCModal, vendor} = getState();
    const {vendorID} = vendor;
    const {id, dcName, dcStreet, dcCity, dcState, dcZip, dcCode} =
        vendorDCModal;

    const isValidName = isValidText(dcName);
    const isValidStreet = isValidText(dcStreet);
    const isValidCity = isValidText(dcCity);
    const isValidState = validateSelectionType(dcState);
    const isValidZip = isValidZipcode(dcZip);

    const isError = !isValidName || !isValidStreet || !isValidCity || !isValidState || !isValidZip;

    if (!isValidName) {
        dispatch({
            type: UPDATE_VENDOR_DC_NAME_ERROR,
            isDcNameError: true,
        });
    }
    if (!isValidStreet) {
        dispatch({
            type: UPDATE_VENDOR_DC_STREET_ERROR,
            isDcStreetError: true,
        });
    }
    if (!isValidCity) {
        dispatch({
            type: UPDATE_VENDOR_DC_CITY_ERROR,
            isDcCityError: true,
        });
    }
    if (!isValidState) {
        dispatch({
            type: UPDATE_VENDOR_DC_STATE_ERROR,
            isDcStateError: true,
        });
    }
    if (!isValidZip) {
        dispatch({
            type: UPDATE_VENDOR_DC_ZIP_ERROR,
            isDcZipError: true,
        });
    }
    try {
        if (!isError) {
            dispatch({
                type: SHOW_VENDOR_DC_FORM_SPINNER,
                spinner: true
            });
            const response = await updateVendorDC({
                vendorID,
                id,
                dcName,
                dcStreet,
                dcCity,
                dcState,
                dcZip,
                dcCode
            });
            const {success} = response;
            success ? toast.success(VendorDCUpdateSuccess) : null;
            if (success) {
                const vendorDCList = await getVendorDistributionCenterList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_DC_LIST,
                    vendorDCList: vendorDCList.data
                })
            }
            dispatch({
                type: HIDE_VENDOR_DC_FORM_SPINNER,
                spinner: false
            });
            if (success) {
                dispatch({
                    type: UPDATE_DC_FORM_INPUTS,
                    isFormUpdated: false
                });
                dispatch({
                    type: CLEAR_DC_MODAL
                });
            }
            dispatch(closeModal());
        }

    } catch (error) {
        dispatch({
            type: HIDE_VENDOR_DC_FORM_SPINNER,
            spinner: false
        });
        if (!error) {
            dispatch({
                type: UPDATE_DC_FORM_INPUTS,
                isFormUpdated: false
            });
            dispatch({
                type: CLEAR_DC_MODAL
            });
        }
        if (error) {
            toast.error(VendorDCUpdateError)
        }
    }
};

const isValidText = (value) => {
    return value && value.trim().length > 0 ? true : false;
}
const validateSelectionType = (selection) => {
    if (selection == "0" || selection == null || selection == "") return false;
    else {
        return true
    }
}