import {
  EXPORT_MEMBER_ADMIN_REBATES,
  EXPORT_MEMBER_ADMIN_REBATE_ERROR,
} from '../../../../constants';

export const exportMemberAdminList = async ({
  memberId,
  exportQuarter,
  exportYear,
}: any) => {
  try {
    const user = JSON.parse(localStorage.getItem('user') || '');
    const response = await fetch(
      `${EXPORT_MEMBER_ADMIN_REBATES}/member/${memberId}?quarter=${exportQuarter}&year=${exportYear}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw EXPORT_MEMBER_ADMIN_REBATE_ERROR;
    const blob = await response.blob();
    return blob;
  } catch (error) {
    throw EXPORT_MEMBER_ADMIN_REBATE_ERROR;
  }
};
