import axios from 'axios';
import AuthenticationService from './AuthenticationService';
import { baseUrl as baseURL } from '../constants/api';

// Add a request interceptor
const serviceInterceptor = axios.create({
  baseURL,
});
// Request call
serviceInterceptor.interceptors.request.use(
  async (config) => {
    const user = await AuthenticationService.getCurrentUser();

    if (user && user.accessToken) {
      config.headers['x-access-token'] = user.accessToken;
    }

    config.headers['cache-control'] = `no-cache`;

    // or
    // !config.url.includes('login)

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

// Response call
serviceInterceptor.interceptors.response.use(
  (next) => {
    return Promise.resolve(next);
  },
  (error) => {
    // You can handle error here and trigger warning message without get in the code inside
    return Promise.reject(error);
  },
);

export default serviceInterceptor;
