import { FC, useState } from 'react';
import { CommunityHeader } from './CommunityHeader';
import { CommunityToggle } from './CommunityToggle';
import { NewDiscussionModal } from '../modal/NewDiscussionModal';
import { SearchNotFound } from '../../../components/SearchNotFound';

export const HeaderWrapper: FC<any> = (props) => {
  const {
    communityDiscussion,
    handleDiscussionTitle,
    handleDiscussionDesc,
    createNewDiscussion,
    handleNewDiscussionModal,
    clearCommunityDiscussionModal,
    communityChannel,
    updateDiscussionDetails,
    handleEditDiscussionFlag,
    setDetailedDiscussion,
    isViewAsMember
  } = props;

  const { isNewDiscussionModal, isEditDiscussion, discussionList } =
    communityDiscussion;

  const { activeChannelID, communityCategory } = communityChannel;

  const activeChannel =
    communityCategory.length > 0
      ? communityCategory.find((item: any) => item.id === activeChannelID)
      : [];
  const activeChannelName = activeChannel ? activeChannel.name : '';

  const [searchText, setSearchText] = useState('');

  const handleModalClose = () => {
    handleNewDiscussionModal(false);
    clearCommunityDiscussionModal();
    handleEditDiscussionFlag(false);
  };

  return (
    <>
      <CommunityHeader
        {...props}
        searchText={searchText}
        setSearchText={setSearchText}
        setDetailedDiscussion={setDetailedDiscussion}
        isViewAsMember={isViewAsMember}
      />

      {discussionList !== null ? (
        <CommunityToggle {...props} searchText={searchText} isViewAsMember={isViewAsMember} />
      ) : (
        <SearchNotFound sectionType={'Discussion'} />
      )}

      <NewDiscussionModal
        openModal={isNewDiscussionModal || isEditDiscussion}
        handleClose={handleModalClose}
        activeChannelName={activeChannelName}
        handleDiscussionTitle={handleDiscussionTitle}
        handleDiscussionDesc={handleDiscussionDesc}
        communityDiscussion={communityDiscussion}
        createNewDiscussion={createNewDiscussion}
        isEditDiscussion={isEditDiscussion}
        updateDiscussionDetails={updateDiscussionDetails}
      />
    </>
  );
};

export default HeaderWrapper;
