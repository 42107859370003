import { FC, useState } from 'react';
import { DwollaFundingSource, DwollaTransfer } from '../../../../../common';
import { CustomModal } from '../../../../../components/CustomModal';
import { RebateActionModal } from './RebateActionModal';
import { Grid, TextField } from '@material-ui/core';
import { EMAIL_REGEXP } from "../../../../../utils/constants";
import serverApiV2Instance from '../../../../../services/serverApiV2Instance';
import { toast } from "react-toastify";

export const RebateAction: FC<{
  handleConfirmRebateReceipt: () => void;
  handleRebateReceiptMode: (rebateReceiptMode: string) => void;
  openModal: boolean;
  rebatesModalSpinner: boolean;
  rebateReceiptMode: string | number; // TODO
  selectedRebate: {
    amount: number;
    clearedat: null | Date;
    createdat: null | Date;
    deliveredat: null | Date;
    handdeliver: boolean | number;
    id: number;
    memberName: string | null;
    memberid: number;
    memberrebatereceiptid: number;
    memberrerebatereceiptitemtypeid: number;
    name: 'check' | 'historic';
    sentat: null | Date;
    updatedat: null | Date;
    fundingSource?: DwollaFundingSource;
    transferInfo?: DwollaTransfer;
    transferFee: number;
  };
  updateRebateActionModal: (isRebateReceiptAction?: boolean) => void;
}> = ({
  openModal,
  updateRebateActionModal,
  selectedRebate,
  rebateReceiptMode,
  handleConfirmRebateReceipt,
  rebatesModalSpinner,
}) => {

    const [showAuthenticateModal, setShowAuthenticateModal] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isValidEmail, setIsValidEmail] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const onChangeShowPassword = () => {
      setShowPassword(!showPassword);
    }

    const closeAuthenticateModal = () => {
      setShowAuthenticateModal(false);
      resetModalState();
    }

    const onChangeEmail = (value: string) => {
      const emailRegExp = new RegExp(EMAIL_REGEXP);
      setEmail(value);
      setIsValidEmail(emailRegExp.test(value));
    };

    const onChangePassword = (value: string) => {
      setPassword(value);
      value.toString().length > 0 ? setIsValidPassword(true) : setIsValidPassword(false);
    };

    const resetModalState = () => {
      setShowPassword(false);
      setEmail('');
      setPassword('');
      setIsValidEmail(true);
      setIsValidPassword(true);
      setIsLoading(false);
    }

    const authenticateUser = async () => {
      try {
        setIsLoading(true);
        const bodyFormData = new FormData();
        bodyFormData.append('email', email);
        bodyFormData.append('password', password);

        const response = await serverApiV2Instance.post('/users/validateUser', bodyFormData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        if (response?.data?.success) {
          console.log("Authentication successful!");
          toast.success("Authentication successful!", {
            toastId: 'validateUserResponse',
          });
          closeAuthenticateModal();
          handleConfirmRebateReceipt();
        } else {
          console.log("Authentication failed!");
          toast.error("Authentication failed!", {
            toastId: 'validateUserResponse',
          });
        }
        setIsLoading(false);
      }
      catch (error: any) {
        toast.error(`Authentication failed, ${error?.response?.data?.message}`, {
          toastId: 'validateUserResponse',
        });
        setIsLoading(false);
      }
    };

    const authenticateModal = (<CustomModal
      showModal={showAuthenticateModal}
      closeModal={() => closeAuthenticateModal()}
      modalTitle={`Authentication Required`}
      isCancelButton
      isSubmitButton={true}
      isSubmitText="Authenticate & Fulfill"
      onSubmit={() => authenticateUser() }
      isLoading={isLoading}
      isButtonDisabled={isLoading}
    >
      <Grid item xs={12} lg={9} className="change-password-right">
        <div className="br-form-element">
          <div className="relative-wrapper">
            <Grid container spacing={5}>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <div className="input-with-icons">
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="text-box"
                    type={'text'}
                    name="email"
                    value={email}
                    onChange={(e) => onChangeEmail(e.target.value)}
                    onBlur={(e) => onChangeEmail(e.target.value)}
                    autoComplete="off"
                    required
                    id={
                      !isValidEmail
                        ? 'standard-error'
                        : 'outlined-basic'
                    }
                    error={!isValidEmail}
                    helperText={
                      !isValidEmail ? 'Please enter a valid email' : ''
                    }
                  />
                </div>
              </Grid>
              <Grid item xs={12} lg={6} md={6} sm={6}>
                <div className="input-with-icons">
                  <TextField
                    label="Password"
                    variant="outlined"
                    className="text-box"
                    type={
                      showPassword ? 'text' : 'password'
                    }
                    name="password"
                    value={password}
                    onChange={(e) => onChangePassword(e.target.value)}
                    onBlur={(e) => onChangePassword(e.target.value)}
                    autoComplete="off"
                    required
                    id={
                      !isValidPassword ? 'standard-error' : 'outlined-basic'
                    }
                    error={!isValidPassword}
                    helperText={!isValidPassword && 'Please enter valid password'}
                  />
                  <i
                    className={
                      showPassword
                        ? 'fa fa-fw fa-eye field-icon'
                        : 'fa fa-fw fa-eye-slash field-icon'
                    }
                    onClick={() => onChangeShowPassword()}
                  ></i>
                </div>
              </Grid>
            </Grid>
          </div>
        </div>
      </Grid>
    </CustomModal >);

    return (
      <CustomModal
        showModal={openModal}
        closeModal={() => updateRebateActionModal()}
        modalTitle={
          selectedRebate?.transferInfo
            ? `Rebate Receipt Transfer Details - ${selectedRebate?.memberName}`
            : `Rebate Receipt Confirmation - ${selectedRebate?.memberName}`
        }
        isCancelButton
        isSubmitButton={selectedRebate?.transferInfo ? false : true}
        isSubmitText="Fulfill Request"
        // onSubmit={handleConfirmRebateReceipt} // TODO
        onSubmit={() => setShowAuthenticateModal(true)} // TODO
        isLoading={rebatesModalSpinner}
        isButtonDisabled={rebateReceiptMode === null}
      >
        <RebateActionModal
          key={selectedRebate?.toString()}
          selectedRebate={selectedRebate}
        />
        {showAuthenticateModal && authenticateModal}
      </CustomModal>
    );
  };

export default RebateAction;