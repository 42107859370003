import { FC } from 'react';
import { CustomModal } from '../../../../../components/CustomModal';
import { MemberRebatesModal } from './MemberRebatesModal';

export const MemberRebates: FC<any> = (props) => {
  const {
    hideMemberRebateModal,
    clearMemberRebateModal,
    rebates: { isMemberRebateModal, rebatesModalSpinner },
    memberRebates: {
      activeStep,
      fileOneContent,
      fileTwoContent,
      fileThreeContent,
    },
    onUploadMemberRebates,
  } = props;

  const closeModal = () => {
    hideMemberRebateModal();
    clearMemberRebateModal();
  };
  const isButtonDisabled =
    fileOneContent === null ||
    fileTwoContent === null ||
    fileThreeContent === null ||
    activeStep !== 3;

  return (
    <div>
      <CustomModal
        showModal={isMemberRebateModal}
        closeModal={closeModal}
        modalTitle="Upload Member Rebate Documents"
        isCancelButton={true}
        isSubmitButton={true}
        isSubmitText={'Upload'}
        onSubmit={onUploadMemberRebates}
        isLoading={rebatesModalSpinner}
        isButtonDisabled={isButtonDisabled}
      >
        <MemberRebatesModal {...props} />
      </CustomModal>
    </div>
  );
};

export default MemberRebates;
