import {FC, useEffect, useState} from "react";
import "./MemberVendorAssignedList";
import {VendorTable} from "../../tableUtils/VendorTable";
import MemberVendorAssignedList from "./MemberVendorAssignedList";
import {Loader} from "../../../../../utils";
import {ConnectedPagination} from "../../../../../components/ConnectedPagination";
import {getPaginatedData} from "../../../../../utils";

const gridHeader = [
  {
    label: 'Admin Person',
    value: 'userName',
    sortKey: 'userName',
    xs: 12,
    lg: 4
  },
  {
    label: 'Role',
    value: 'roleName',
    sortKey: 'roleName',
    xs: 12,
    lg: 3
  },
  {
    label: 'Phone',
    value: 'phone',
    sortKey: 'phone',
    xs: 12,
    lg: 3
  },
  {
    label: 'Action',
    value: 'action',
    sortKey: 'action',
    xs: 12,
    lg: 2
  }
];

export const MemberVendorTab: FC<any> = ({
  memberDetail,
  getMemberVendors,
  members: { vendorListSpinner, vendorsList },
  paginationReducer: {
    rowsPerPage,
    page
  },
  handleChangeRowsPerPage,
}) => {
  const [vendorUserList, setVendorUserList] = useState([]);
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState('');

  useEffect(() => {
    getMemberVendors();
  }, []);

  useEffect(() => {
    setVendorUserList(vendorsList);
    handleChangeRowsPerPage(10);
  }, [vendorsList]);

  const modifiedList = getPaginatedData(vendorUserList, rowsPerPage, page);

  const setSortKey = (newSortKey: any) => {
    if (sortKey === newSortKey) {
      if (sortType === "DESC") {
        updateSortType("ASC");
      } else {
        updateSortType("DESC");
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType("ASC");
    }
  };

  const sortedData =
    sortKey && sortType && modifiedList.length > 0
      ? modifiedList &&
      modifiedList.sort((a: any, b: any) => {
        if (sortType === 'DESC') {
          return b[sortKey]?.localeCompare(a[sortKey]);
        }
        return a[sortKey]?.localeCompare(b[sortKey]);
      })
      : modifiedList;

  const memberName = memberDetail?.name ? memberDetail?.name : '-';


  return (
    <div className="vendor-assign-content">
      {vendorListSpinner ? (
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      ) : (
        <>
          <VendorTable
            gridHeader={gridHeader}
            gridData={sortedData ? sortedData : []}
            setSortKey={setSortKey}
            sortType={sortType}
            memberName={memberName}
          />
          {vendorUserList?.length > 5 && modifiedList?.length > 0 ?
            <ConnectedPagination
              count={vendorUserList?.length}
            /> : null
          }
        </>
      )}
    </div>
  );
};

export default MemberVendorTab;
