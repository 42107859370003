import { assign } from '@xstate/immer';
import { createMachine } from 'xstate';
import getVendorUsers from '../../api/vendors/getVendorUsers';
import { VendorUsers } from '../../common';

export const vendorUsersMachine = createMachine(
  {
    schema: {
      context: {} as {
        selectedVendorId: null | number;
        assignedVendorUsers: VendorUsers;
        unassignedVendorUsers: VendorUsers;
      },
      events: {} as
        | { type: 'done.invoke.loadVendorUsers'; data: any }
        | { type: 'refresh' },
    },
    tsTypes: {} as import("./vendorUsersMachine.typegen").Typegen0,
    context: {
      selectedVendorId: null,
      assignedVendorUsers: [],
      unassignedVendorUsers: [],
    },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'loadVendorUsers',
          src: 'loadVendorUsers',
          onDone: {
            actions: 'assignVendorUsers',
            target: 'idle',
          },
          onError: 'error',
        },
        tags: ['loading'],
      },
      idle: {
        on: {
          refresh: 'loading',
        },
      },
      error: {},
    },
  },
  {
    actions: {
      assignVendorUsers: assign((context, event) => {
        context.assignedVendorUsers = event.data.assignedVendorUsers;
        context.unassignedVendorUsers = event.data.unassignedVendorUsers;
      }),
    },
    services: {
      loadVendorUsers: (context) => {
        if (!context?.selectedVendorId) {
          return Promise.reject('No valid vendor id.');
        }
        return getVendorUsers(context.selectedVendorId);
      },
    },
  },
);

export type VendorUsersMachine = typeof vendorUsersMachine;

export default vendorUsersMachine;
