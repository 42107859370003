import {
  PUBLISH_MEMBER_REBATES_ERROR,
  PublishMemberError,
} from '../../../constants';
import { updatePublishSpinner } from '../rebates';
import { getMemberRebatesQuarter } from './memberRebates';
import { publishMemberRebates } from '../../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const onPublishMemberRebates =
  () => async (dispatch: any, getState: any) => {
    const { memberRebates } = getState();
    const { memberActiveQuarter } = memberRebates;
    dispatch(updatePublishSpinner(true));
    const { quarter, year } = memberActiveQuarter;
    try {
      const res = await publishMemberRebates({
        quarter,
        year,
      });
      const { success, message, error } = res?.data;
      if (success) {
        toast.success(message);
        dispatch(getMemberRebatesQuarter());
      } else {
        toast.error(error);
      }
      dispatch(updatePublishSpinner(false));
    } catch (error) {
      dispatch(updatePublishSpinner(false));
      if (error === PUBLISH_MEMBER_REBATES_ERROR) {
        toast.error(PublishMemberError);
      }
    }
  };
