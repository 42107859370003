import { FC, useEffect, useState } from 'react';
import { SalesPersonnelHeader } from './SalesPersonnelHeader';
import { SalesPersonnelList } from './SalesPersonnelList';
import { AddSalesPersonnelModal } from './AddSalesPersonnelModal';
import { AssignNewUser } from './AssignUserModal';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../../../machines';
import serverApiV2Instance from '../../../../services/serverApiV2Instance';

export const SalesPersonnel: FC<any> = ({
  updateDeleteVendorUsers,
  handleDeleteClose,
  handleDeleteVendorUser,
  getVendorUsers,
  clearVendorUserList,
  getVendorDC,
  vendor: {
    vendorID,
    vendorUsersList,
    vendorDCList,
    isDeleteUser,
  },
  vendorUserCreate,
  modal: { showModal, isCreate },
  showVendorUserAssignModal,
  clearVendorUserCreate,
  clearVendorUserCreateModal,
  onChangeVendorUserFirstname,
  onChangeVendorUserLastname,
  onChangeVendorUserEmail,
  onChangeVendorUserJobtitle,
  onChangeVendorUserPhone,
  onChangeVendorUserCreateRole,
  onChangeVendorUserCreateDC,
  onChangeVendorUserAssignDC,
  onAddNewVendorUser,
  showVendorUser,
  onEditVendorUser,
  getUserRoleList,
  common: { rolesList },
  paginationReducer,
  clearVendorUserAssign,
  vendorUserAssign,
  onSelecteAssignUser,
  handleUserRoleChange,
  onAssignVendorUser,
  user,
}) => {
  const { unassignedVendorUsers } = vendorUsersList;
  const [searchText, setSearchText] = useState('');
  const [displayNewUserForm, setDisplayNewUserForm] = useState(false);

  useEffect(() => {
    if (!(rolesList && rolesList.length > 0)) {
      getUserRoleList();
    }
    getVendorUsers();
    getVendorDC();

    return () => {
      clearVendorUserList();
    };
  }, []);

  useEffect(() => {
    if (!showModal) {
      setDisplayNewUserForm(false);
    }
  }, [showModal]);

  const userRolesForVendor =
    rolesList &&
    rolesList.filter(
      (item: { name: string }) => {
        return item.name === "Account Representative" || item.name === 'Vendor Admin'
      },
    );

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: 'userName',
        sortOrder: 'asc',
      },
      pageSize: 10,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/vendors/${vendorID}/assigned-users?${query.toString()}`,
        );
      },
    },
  });

  return (
    <div className="vendor-assign-content">
      <SalesPersonnelHeader
        searchText={searchText}
        setSearchText={setSearchText}
        handleClickOpen={() => showVendorUserAssignModal()}
        state={state}
        send={send}
      />
      <SalesPersonnelList
        updateDeleteVendorUsers={updateDeleteVendorUsers}
        isDeleteUser={isDeleteUser}
        handleDeleteClose={handleDeleteClose}
        handleDeleteVendorUser={handleDeleteVendorUser}
        showVendorUser={showVendorUser}
        showModal={showModal}
        user={user}
        rolesList={rolesList}
        state={state}
        send={send}
      />
      {!isCreate && !displayNewUserForm && (
        <AssignNewUser
          openCreateNewUserModal={() => {
            setDisplayNewUserForm(true)
          }}
          showModal={showModal}
          vendorDCList={vendorDCList}
          onChangeVendorUserAssignDC={onChangeVendorUserAssignDC}
          clearVendorUserAssign={clearVendorUserAssign}
          vendorUserAssign={vendorUserAssign}
          unassignedVendorUsers={unassignedVendorUsers}
          paginationReducer={paginationReducer}
          onSelecteAssignUser={onSelecteAssignUser}
          handleUserRoleChange={handleUserRoleChange}
          onAssignVendorUser={onAssignVendorUser}
          clearVendorUserCreateModal={clearVendorUserCreateModal}
          send={send}
        />
      )}
      {(
        <AddSalesPersonnelModal
          openDialog={(!isCreate && !displayNewUserForm) ? false : showModal}
          closeDialog={clearVendorUserCreate}
          vendorUserCreate={vendorUserCreate}
          handleBackToList={() => {
            showVendorUserAssignModal()
            setDisplayNewUserForm(false);
          }}
          vendorDCList={vendorDCList}
          getVendorDC={getVendorDC}
          onChangeVendorUserFirstname={onChangeVendorUserFirstname}
          onChangeVendorUserLastname={onChangeVendorUserLastname}
          onChangeVendorUserEmail={onChangeVendorUserEmail}
          onChangeVendorUserJobtitle={onChangeVendorUserJobtitle}
          onChangeVendorUserPhone={onChangeVendorUserPhone}
          onChangeVendorUserCreateRole={onChangeVendorUserCreateRole}
          onChangeVendorUserCreateDC={onChangeVendorUserCreateDC}
          onAddNewVendorUser={onAddNewVendorUser}
          onEditVendorUser={onEditVendorUser}
          rolesList={userRolesForVendor}
          goBackToList={() => setDisplayNewUserForm(false)}
          send={send}
        />
      )}
    </div>
  );
};
