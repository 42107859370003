import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CLEAR_ADS_FORM,
  UPDATE_ADS_FORM_UPDATED,
  UPDATE_ADS_FORM_AD_URL,
  UPDATE_ADS_FORM_AD_TYPE,
  UPDATE_ADS_FORM_PRODUCT_NAME,
  UPDATE_ADS_FORM_PRODUCT_BRAND,
  UPDATE_ADS_FORM_SPECIAL,
  UPDATE_ADS_FORM_DESC,
  UPDATE_ADS_FORM_AD_STATUS,
  UPDATE_ADS_FORM_AD_RATING,
  UPDATE_ADS_FORM_IMAGE_FILE,
  UPDATE_ADS_FORM_UPLOADED_IMG_PATH,
  UPDATE_ADS_FORM_AD_URL_ERROR,
  UPDATE_ADS_FORM_AD_TYPE_ERROR,
  UPDATE_ADS_FORM_PRODUCT_NAME_ERROR,
  UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR,
  UPDATE_ADS_FORM_SPECIAL_ERROR,
  UPDATE_ADS_FORM_DESC_ERROR,
  UPDATE_ADS_FORM_AD_RATING_ERROR,
  AdCreateSuccess,
  AdCreateError,
  UPDATE_ADS_FORM_SPINNER
} from "../../constants";
import {createAd} from "../../api/manageads";
import {getProductNames} from "../../actions/products";
import {handleCloseAddAdsModal, getAdList} from "./adsList";

export const clearAdsForm = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ADS_FORM
  });
};
export const handleChangeAdUrl = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_AD_URL,
    value: value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValidAdUrl = validateText(value);
  dispatch({
    type: UPDATE_ADS_FORM_AD_URL_ERROR,
    errorFlag: !isValidAdUrl,
  });
};
export const handleChangeAdType = (event) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_AD_TYPE,
    value: event.target.value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateSelectionType(event.target.value);
  dispatch({
    type: UPDATE_ADS_FORM_AD_TYPE_ERROR,
    errorFlag: !isValid,
  });
};
export const handleChangeAdProductBrand = (event) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_PRODUCT_BRAND,
    value: event.target.value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateSelectionType(event.target.value);
  dispatch({
    type: UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR,
    errorFlag: !isValid,
  });
  if (isValid) {
    dispatch(getProductNames(event.target.value))
  }
};
export const handleChangeAdProductName = (event) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_PRODUCT_NAME,
    value: event.target.value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateSelectionType(event.target.value);
  dispatch({
    type: UPDATE_ADS_FORM_PRODUCT_NAME_ERROR,
    errorFlag: !isValid,
  });
};
export const handleChangeAdSpecial = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_SPECIAL,
    value: value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateText(value);
  dispatch({
    type: UPDATE_ADS_FORM_SPECIAL_ERROR,
    errorFlag: !isValid,
  });
};
export const handleChangeAdDescription = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_DESC,
    value: value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateText(value);
  dispatch({
    type: UPDATE_ADS_FORM_DESC_ERROR,
    errorFlag: !isValid,
  });
};
export const handleChangeAdStatus = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_AD_STATUS,
    value: value
  });
};
export const handleChangeAdRating = (event) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_AD_RATING,
    value: event.target.value
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
  const isValid = validateSelectionType(event.target.value);
  dispatch({
    type: UPDATE_ADS_FORM_AD_RATING_ERROR,
    errorFlag: !isValid,
  });
};
export const handleChangeAdImage = (fileObj) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADS_FORM_IMAGE_FILE,
    file: fileObj,
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPLOADED_IMG_PATH,
    imagePath: URL.createObjectURL(fileObj),
  });
  dispatch({
    type: UPDATE_ADS_FORM_UPDATED,
    flag: true,
  });
};

export const onAddNewAds = () => async (dispatch, getState) => {
  const {adsCreate} = getState();
  const {
    adUrl,
    adType,
    productName,
    productBrand,
    adSpecial,
    description,
    adStatus,
    adRating,
    adImage,
    adImagePath,
  } = adsCreate;

  const isValidAdUrl = validateText(adUrl);
  const isValidAdType = validateSelectionType(adType);
  const isValidProductName = validateSelectionType(productName);
  const isvalidProductBrand = validateSelectionType(productBrand);
  const isValidAdSpecial = validateText(adSpecial);
  const isValidDescription = validateText(description);
  const isValidAdRating = validateSelectionType(adRating)

  const isError =
    !isValidAdUrl ||
    !isValidAdType ||
    !isValidProductName ||
    !isvalidProductBrand ||
    !isValidAdSpecial ||
    !isValidDescription ||
    !isValidAdRating;

  if (!isValidAdUrl) {
    dispatch({
      type: UPDATE_ADS_FORM_AD_URL_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidAdType) {
    dispatch({
      type: UPDATE_ADS_FORM_AD_TYPE_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidProductName) {
    dispatch({
      type: UPDATE_ADS_FORM_PRODUCT_NAME_ERROR,
      errorFlag: true,
    });
  }
  if (!isvalidProductBrand) {
    dispatch({
      type: UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidAdSpecial) {
    dispatch({
      type: UPDATE_ADS_FORM_SPECIAL_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidDescription) {
    dispatch({
      type: UPDATE_ADS_FORM_DESC_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidAdRating) {
    dispatch({
      type: UPDATE_ADS_FORM_AD_RATING_ERROR,
      errorFlag: true,
    });
  }

  try {
    if (!isError) {

      dispatch({
        type: UPDATE_ADS_FORM_SPINNER,
        spinner: true,
      });

      const response = await createAd({
        adUrl,
        adType,
        productName,
        productBrand,
        adSpecial,
        description,
        adRating,
        adImage,
      });
      const {success} = response;
      success ? toast.success(AdCreateSuccess) : null;
      if (success) {
        dispatch(clearAdsForm());
        dispatch(handleCloseAddAdsModal());
        dispatch(getAdList());
      }
      dispatch({
        type: UPDATE_ADS_FORM_SPINNER,
        spinner: false,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ADS_FORM_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: UPDATE_ADS_FORM_UPDATED,
        isFormUpdated: false,
      });
    }
    if (error) {
      toast.error(AdCreateError);
    }
  }

};
export const onEditAds = () => async (dispatch, getState) => { };

const validateSelectionType = (selection) => {
  if (selection == "0" || selection == null || selection == "") return false;
  else {
    return true;
  }
};

const validateText = (text) => {
  if (text == "0" || text == null || text.trim() == "") return false;
  else {
    return true;
  }
};