import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import HeaderImage from '../../../../../images/bg-header-2.png';

export const BRAdminRebatesTitle: FC<{
  rebates: { activeTab: number };
  productRebates: { activeQuarter: any };
  memberRebates: { memberActiveQuarter: any };
}> = ({
  rebates: { activeTab },
  productRebates: { activeQuarter },
  memberRebates: { memberActiveQuarter },
}) => {
  const getCustomHeader = (quarter: any, rebateType: string) => {
    const activeQuarterLabel = quarter !== null ? quarter.value.split(' ') : '';
    if (Array.isArray(activeQuarterLabel)) {
      return `${activeQuarterLabel[0]} - ${activeQuarterLabel[1]} ${rebateType} -`;
    }
    return rebateType;
  };

  const getTitle = (activeTab: number) => {
    if (activeTab === 0) return 'Quarterly Rebate Receipt';
    else if (activeTab === 2)
      return getCustomHeader(activeQuarter, 'Opportunities');
    else if (activeTab === 3)
      return getCustomHeader(memberActiveQuarter, 'Earned Rebates');
    else return 'Rebates';
  };

  const getClassName = (status: string) => {
    if (status === 'UPLOADED') return 'details-title-draft';
    else if (status === 'INPROGRESS') return 'details-title-in-progress';
    else if (status === 'PUBLISHED') return 'details-title-published';
    else return '';
  };

  const getSuffixTitle = (status: string) => {
    if (status === 'UPLOADED') return 'Draft';
    else if (status === 'INPROGRESS') return 'In Progress';
    else if (status === 'PUBLISHED') return 'Published';
  };

  const productStatus = activeQuarter !== null ? activeQuarter.status : '';
  const memberStatus =
    memberActiveQuarter !== null ? memberActiveQuarter.status : '';
  if (activeTab !== 0 && activeTab !== 1 && activeTab === 2)
    return (
      <div className="rebates-header">
        <Grid container className="details-header">
          <div className="details-header-img">
            <img src={HeaderImage} />
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="details-header-content"
            >
              <Grid item xs={12}>
                <div className="details-header-left">
                  <h2 className="details-header-title">
                    {getTitle(activeTab)}
                    <span className="details-header-subtitle">
                      {activeTab === 2 && (
                        <span className={getClassName(productStatus)}>
                          {getSuffixTitle(productStatus)}
                        </span>
                      )}
                    </span>
                  </h2>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  else {
    return (
      <div className="rebates-header">
        <Grid container className="details-header">
          <div className="details-header-img">
            <img src={HeaderImage} />
            <Grid
              container
              spacing={2}
              alignItems="center"
              className="details-header-content"
            >
              <Grid item xs={12}>
                <div className="details-header-left">
                  <h2 className="details-header-title">
                    {getTitle(activeTab)}
                    <span className="details-header-subtitle">
                      {activeTab === 3 && (
                        <span className={getClassName(memberStatus)}>
                          {getSuffixTitle(memberStatus)}
                        </span>
                      )}
                    </span>
                  </h2>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </div>
    );
  }
};
