import { connect } from 'react-redux';
import { Messages as MessagesContent } from './Messages';

const mapStateToProps = ({ user, changePasswordForm, profile }) => ({
  user,
  changePasswordForm,
  profile,
});

export const Messages = connect(mapStateToProps, {})(MessagesContent);
