import { FC } from 'react';
import { CustomModal } from '../../../../../components/CustomModal';
import { ProductRebatesModal } from './ProductRebatesModal';

export const ProductRebates: FC<any> = (props) => {
  const {
    hideProductRebateModal,
    onUploadProductRebates,
    productRebates,
    rebates,
  } = props;
  const { productRebatesSpinner, fileContent, fileExtensionError, isEdit } =
    productRebates;
  const { isProductRebateModal, rebatesModalSpinner } = rebates;
  const disableFlag = fileContent === null || fileExtensionError === null;
  const closeModal = () => {
    hideProductRebateModal();
  };

  return (
    <CustomModal
      showModal={isEdit ? isEdit : isProductRebateModal}
      closeModal={closeModal}
      modalTitle={
        isEdit
          ? 'Update opportunities documents'
          : 'Upload opportunities documents'
      }
      isCancelButton
      isSubmitButton
      isSubmitText={isEdit ? 'Update' : 'Upload'}
      onSubmit={onUploadProductRebates}
      isButtonDisabled={disableFlag}
      isLoading={rebatesModalSpinner || productRebatesSpinner}
    >
      <ProductRebatesModal {...props} />
    </CustomModal>
  );
};

export default ProductRebates;
