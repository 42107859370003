import { VendorKPIAddError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const createVendorKPI = async ({
  kpiId,
  selectedKPIMemberId,
  comments,
}) => {
  const currentDate = () => {
    var d = new Date(),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  try {
    const bodyFormData = new FormData();
    bodyFormData.append('kpiId', kpiId);
    bodyFormData.append('kpiDate', currentDate());
    bodyFormData.append('comment', comments);
    bodyFormData.append('deactivateDate', '');

    const response = await serviceInterceptor.post(
      `/members/${selectedKPIMemberId}/kpi-agreements`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    
    if (!response?.data?.success) {
      throw VendorKPIAddError;
    }
    
    return response.data;
  } catch (error) {
    throw VendorKPIAddError;
  }
};
