import { FC, useMemo } from 'react';
import { TextField } from '@material-ui/core';
import { ActorRefFrom } from 'xstate';
import { RebateReceiptHistoricTypeItemMachine } from './machines';
import { useActor } from '@xstate/react';

export const HistoricMode: FC<{
  actor: ActorRefFrom<RebateReceiptHistoricTypeItemMachine>;
}> = ({ actor }) => {
  const [state, send] = useActor(actor);

  const { helperText, error } = useMemo(() => {
    if (state.context.amount <= 0) {
      return {
        helperText: 'The requested amount must be greater than zero.',
        error: true,
      };
    }
    if (state.context.amount > state.context.parentAmount) {
      return {
        error: true,
        helperText: 'The requested amount cannot exceed the available funds.'
      };
    }
    return { helperText: '', error: false };
  }, [state.context.amount > state.context.parentAmount, state.context.amount <= 0]);

  return (
    <TextField
      type="number"
      error={error}
      label="Rebate Item Amount"
      variant="outlined"
      className="text-box"
      helperText={helperText}
      onChange={(e) =>
        send({
          type: 'updateAmount',
          amount: Number(e.target.value) * 100,
        })
      }
      required
      value={state.context.amount / 100}
    />
  );
};

export default HistoricMode;
