import { rolesEnum } from './rolesConfiguration';
import { omit, pick } from 'lodash';

export const sideMenuAccess = [
  { menuId: 'dashboard', users: { ...rolesEnum } },
  { menuId: 'members', users: { ...rolesEnum } },
  { menuId: 'vendors', users: { ...rolesEnum } },
  { menuId: 'documents', users: { ...rolesEnum } },
  { menuId: 'manage-ads', users: pick(rolesEnum, ['bradmin']) },
  {
    menuId: 'rebates',
    users: omit(rolesEnum, ['memberuser']),
  },
  { menuId: 'kpi', users: pick(rolesEnum, ['bradmin']) },
  {
    menuId: 'community', users: { ...rolesEnum }
    // users: omit(rolesEnum, ['vendorsales', 'vendoradmin']),
  },
];

export const memberMenuOrder = [
  'dashboard',
  'community',
  'documents',
  'rebates',
  'vendors',
  'members',
];

export const vendorMenuOrder = ['dashboard', 'members', 'vendors', 'rebates', 'documents', 'community'];

export const brAdminMenuOrder = [
  'dashboard',
  'members',
  'vendors',
  'documents',
  'manage-ads',
  'rebates',
  'kpi',
  'community',
];
