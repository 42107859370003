import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import '../../css/fonts.css';
import '../../css/all.min.css';
import '../../css/style.css';
import '../../scss/styles.scss';
import Logo from '../../images/logo-login.png';

export const FeatureInfo: FC = () => {
  return (
    <div className="features-info">
      <Grid
        className="login-content"
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
      >
        <img src={Logo} alt="buyright" title="buyright" />
        <h3>Independent Restaurant Collective</h3>
        <p>Strength in Independents</p>
      </Grid>
    </div>
  );
};

export default FeatureInfo;
