import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDATE_VENDOR_KPI_LIST,
  CLEAR_VENDOR_KPI_MODAL,
  UPDATE_VENDOR_KPI_FORM_IS_EDIT,
  UPDATE_VENDOR_KPI_FORM_KPI,
  UPDATE_VENDOR_KPI_FORM_MEMBER,
  UPDATE_VENDOR_KPI_FORM_COMMENTS,
  UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
  UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
  UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
  SHOW_VENDOR_KPI_FORM_SPINNER,
  HIDE_VENDOR_KPI_FORM_SPINNER,
  UPDATE_VENDOR_KPI_FORM_INPUTS,
  VendorKPIAddSuccess,
  VendorKPIAddError,
  FetchError,
} from "../../constants";
import {showModal, closeModal} from "../modal";
import {getVendorKPIList, createVendorKPI} from "../../api/vendors";

export const clearVendorKPIModal = () => ({
  type: CLEAR_VENDOR_KPI_MODAL,
});
export const showAddKPIModal = () => async (dispatch) => {
  dispatch(showModal());
};
export const closeKPIModal = () => async (dispatch) => {
  dispatch({
    type: CLEAR_VENDOR_KPI_MODAL,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_IS_EDIT,
    isEdit: false,
  });
  dispatch(closeModal());
};
export const onChangeVendorKPIkpi = (kpiId) => async (dispatch) => {
  const formatedLinkType = kpiId != "0" ? parseInt(kpiId) : "0";
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_KPI,
    kpiId: formatedLinkType,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_INPUTS,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
    kpiIdError: kpiId == "0" ? true : false,
  });
};
export const onChangeVendorKPImember = (memberId) => async (dispatch) => {
  const formatedLinkType = memberId != "0" ? parseInt(memberId) : "0";
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_MEMBER,
    memberId: formatedLinkType,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_INPUTS,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
    memberIdError: memberId == "0" ? true : false,
  });
};
export const onChangeVendorKPIcomments = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_COMMENTS,
    comments: value,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
    commentsError:  false,
  });
  dispatch({
    type: UPDATE_VENDOR_KPI_FORM_INPUTS,
    isFormUpdated: true,
  });
};
export const onAddVendorKPI = () => async (dispatch, getState) => {
  const {vendorKPIModal, vendor} = getState();
  const {vendorID} = vendor;

  const {kpiId, selectedKPIMemberId, comments} = vendorKPIModal;
  const isValidKPI = validateSelectionType(kpiId);
  const isvalidMember = validateSelectionType(selectedKPIMemberId);
  // const isValidComments = comments && comments.trim().length > 0 ? true : false;
  const isError = !isValidKPI;

  if (!isValidKPI) {
    dispatch({
      type: UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
      kpiIdError: true
    });
  }
  if (!isvalidMember) {
    dispatch({
      type: UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
      memberIdError: true
    });
  }
  // if (!isValidComments) {
  //   dispatch({
  //     type: UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
  //     commentsError: false
  //     commentsError: true
  //   });
  // }
  if (isError) {
    dispatch({
      type: HIDE_VENDOR_KPI_FORM_SPINNER,
      spinner: false
    });
  } else {
    dispatch({
      type: SHOW_VENDOR_KPI_FORM_SPINNER,
      spinner: true
    });
  }
  try {
    if (!isError) {
      const response = await createVendorKPI({
        kpiId,
        selectedKPIMemberId,
        comments
      });
      const {success} = response;
      if (success) {
        const vendorKpiList = await getVendorKPIList(vendorID);
        dispatch({
          type: UPDATE_VENDOR_KPI_LIST,
          vendorKpiList: vendorKpiList.data
        })
      }
      dispatch({
        type: HIDE_VENDOR_KPI_FORM_SPINNER,
        spinner: false
      });
      if (success) {
        dispatch({
          type: UPDATE_VENDOR_KPI_FORM_INPUTS,
          isFormUpdated: false
        });
        dispatch({
          type: CLEAR_VENDOR_KPI_MODAL
        });
      }
      dispatch(closeModal());
      success ? toast.success(VendorKPIAddSuccess) : null;
    }

  } catch (error) {
    console.error('FDSKLJS:FJK: ', error)
    dispatch({
      type: HIDE_VENDOR_KPI_FORM_SPINNER,
      spinner: false
    });
    if (!error) {
      dispatch({
        type: UPDATE_VENDOR_KPI_FORM_INPUTS,
        isFormUpdated: false
      });
      dispatch({
        type: CLEAR_VENDOR_KPI_MODAL
      });
    }
    if (error) {
      toast.error(VendorKPIAddError)
    }
  }
}
const validateSelectionType = (selection) => {
  if (selection == "0" || selection == null || selection == "") return false;
  else {
    return true
  }
}