import {DISCUSSION_API, UPDATE_COMMENT_ERROR} from '../../../constants';

export const editComment = async ({
    discussionId,
    discussionCommentId,
    discussionComment,
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${discussionId}/comments/${discussionCommentId}`, {
            method: 'PATCH',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                content: discussionComment
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_COMMENT_ERROR;
    }
};