import { VendorDCUpdateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const updateVendorDC = async ({
  vendorID,
  id,
  dcName,
  dcStreet,
  dcCity,
  dcState,
  dcZip,
  dcCode,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('name', dcName);
    bodyFormData.append('street', dcStreet);
    bodyFormData.append('city', dcCity);
    bodyFormData.append('state', dcState);
    bodyFormData.append('zip', dcZip);
    bodyFormData.append('code', dcCode);

    const response = await serviceInterceptor.put(
      `/vendor/${vendorID}/distributioncenter/${id}`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw VendorDCUpdateError;
    }
    return response.data;
  } catch (error) {
    throw VendorDCUpdateError;
  }
};
