import { FC } from 'react';
import { OpportunityTable } from './OpportunityTable';
import '../../dashboard.scss';
import { useCloseableDisplay } from '../../../../reactHooks';
import { Grid } from '@mui/material';
import { BRDropdown } from '../../../../components/BRDropdown';
import DashboardCollapsibleSectionHeader from '../DashboardCollapsibleSectionHeader';
import { useActor, useMachine } from '@xstate/react';
import { dashboardOpportunityListMachine } from './machines';
import { timeFrameIntervalMachine } from '../../../../machines';
import { NavigateFunction } from 'react-router-dom';
import serviceInterceptor from '../../../../services/ServiceInterceptor';
import { DateTime } from 'luxon';

export const DashboardOpportunities: FC<{
  updateOpportunitiesRoute: (navigate: NavigateFunction) => void;
  initialContext: any;
}> = ({ updateOpportunitiesRoute, initialContext }) => {

  const [opened, { toggle }] = useCloseableDisplay();
  const [state, send] = useMachine(dashboardOpportunityListMachine, {
    context: {
      ...initialContext,
    },
    services: {
      getOpportunityList: (context) => 
      serviceInterceptor(
        `/rebate-product-list-records?quarter=${context.selectedQuarter}&year=${context.selectedYear}`,
      ),
      timeFrameInterval: timeFrameIntervalMachine.withContext({
        intervalTypes: ['quarter'],
        intervalValueLists: {
          month: [],
          quarter: Array.from(Array(4)).map((_, index) => ({
            label: `${index + 1}`,
            value: index + 1,
          })),
        },
        selectedIntervalType: 'quarter',
        selectedIntervalValue: initialContext?.selectedQuarter,
        selectedYear: initialContext?.selectedYear,
        years: Array.from(Array(5)).map(
          (_, years) => DateTime.now().minus({ years }).year,
        ),
      }),
    },
  });

  const [timeFrameIntervalState, timeFrameIntervalSend] = useActor(
    state.children.timeFrameInterval,
  );
 
  return ( 
    <div className="dashboard-section">
      <DashboardCollapsibleSectionHeader
        title="Opportunities"
        toggle={toggle}
        opened={opened}
      />
      {opened && (
        <Grid
          container
          justifyContent="flex-start"
          sx={{
            mt: 2,
            mb: 2,
            display: 'grid',
            gridTemplateColumns: '1fr min-content',
            gap: 1,
          }}
        >
          <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
            <BRDropdown
              selectedOption={state.context.selectedQuarter
              }
              menuList={
                timeFrameIntervalState.context.intervalValueLists[
                  timeFrameIntervalState.context.selectedIntervalType
                ].map((quarterData: any) => ({
                  label: `Quarter ${quarterData.label}`,
                  value: quarterData.value
                }))
              }
              handleSelected={(item: any) => {
                const intervalValue = item?.value;
                if (intervalValue) {
                  timeFrameIntervalSend({
                    intervalValue,
                    type: 'updateSelectedIntervalValue',
                  });
                }
              }}
              spinner={state.hasTag('loading')}
            />
          </Grid>
          <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
            <BRDropdown
              selectedOption={state.context.selectedYear}
              menuList={timeFrameIntervalState.context.years.map(
                (year: number) => ({
                  label: `${year}`,
                  value: year,
                }),
              )}
              handleSelected={(item: any) => {
                const year = item?.value;
                if (year) {
                  timeFrameIntervalSend({
                    year: Number(year),
                    type: 'updateSelectedYear',
                  });
                }
              }}
              spinner={state.hasTag('loading')}
            />
          </Grid>
        </Grid>
      )}
      <OpportunityTable
        pageSize={state.context.pageSize}
        page={state.context.page}
        loading={state.hasTag('loading')}
        onPageChange={(_: any, pageNumber: number) =>
          send({
            type: 'updatePageNumber',
            pageNumber,
          })
        }
        updateOpportunitiesRoute={updateOpportunitiesRoute}
        opportunityList={state.context.opportunityList}
        dashboardTable={opened}
      />
    </div>
  );
};

export default DashboardOpportunities;
