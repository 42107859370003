import { Grid } from '@material-ui/core';
import { FC, useMemo } from 'react';
import { ActorRefFrom } from 'xstate';
import { useActor } from '@xstate/react';
import { PaginationMachine } from '../../../../machines';
import OpportunitiesSearchBar from './OpportunitiesSearchBar';
import OpportunitiesDropdownMenu from './OpportunitiesDropdownMenu';
import OpportunitiesActionButton from './OpportunitiesActionButton';
import { OpportunityQuarters } from '../../../../common';
import AuthorizationService from '../../../../authorization/AuthorizationService';

export const OpportunitiesToolbar: FC<{
  isBRAdmin?: boolean;
  paginationActor: ActorRefFrom<PaginationMachine>;
  openUploadModal: () => void;
  openEditModal: () => void;
  openPublishModal: () => void;
  openExportModal: () => void;
  opportunityQuarters: OpportunityQuarters;
  selectedOpportunityQuarterId: number;
  setSelectedOpportunityQuarterId: (opportunityQuarterId: number) => void;
}> = ({
  isBRAdmin,
  opportunityQuarters,
  paginationActor,
  selectedOpportunityQuarterId,
  setSelectedOpportunityQuarterId,
  openUploadModal,
  openEditModal,
  openPublishModal,
  openExportModal,
}) => {
  const [state, send] = useActor(paginationActor);

  const selectedOpportunityQuarter = useMemo(
    () =>
      opportunityQuarters.filter(
        (opportunityQuarter) =>
          opportunityQuarter.id === selectedOpportunityQuarterId,
      )?.[0] ?? null,
    [
      opportunityQuarters.toString(),
      selectedOpportunityQuarterId,
      state.hasTag('loading'),
    ],
  );

  const { canPublish, canEdit, canUpload, canExport } = useMemo(() => {
    const permissions = {
      canEdit: false,
      canPublish: false,
      canExport: false,
      canUpload: true,
    };
    if (selectedOpportunityQuarter?.status === 'UPLOADED') {
      permissions.canEdit = true;
      permissions.canPublish = true;
      permissions.canExport = true;
      permissions.canUpload = false;
    }

    if (selectedOpportunityQuarter?.status === 'PUBLISHED') {
      permissions.canExport = true;
      permissions.canUpload = true;
    }

    if (
      selectedOpportunityQuarter?.status === 'INPROGRESS' ||
      selectedOpportunityQuarter?.status === 'ERROR' ||
      !selectedOpportunityQuarter?.status
    ) {
      permissions.canUpload = false;
    }

    if(selectedOpportunityQuarter?.status === undefined && opportunityQuarters.length === 0){
      permissions.canUpload = true;
    }

    if (!isBRAdmin) {
      permissions.canEdit = false;
      permissions.canPublish = false;
      permissions.canUpload = false;
    } 

    return permissions;
  }, [
    selectedOpportunityQuarter?.status,
    selectedOpportunityQuarter?.publishedAt,
  ]);

  const isOpportunityUpload = AuthorizationService.isAuthorizedHTML('rebatesOpportunityUpload');
  const isOpportunityExport = AuthorizationService.isAuthorizedHTML('rebatesOpportunityExport');

  return (
    <div className="rebates-filter">
      <Grid container spacing={3} alignItems="center">
        <Grid
          xs={12}
          lg={4}
          alignItems="center"
          justify="flex-start"
          item
          alignContent="center"
        >
          <div className="header-filter-box-left">
            <OpportunitiesDropdownMenu
              key={
                `${state.hasTag('loading')}` +
                  opportunityQuarters?.toString() ?? ''
              }
              opportunityQuarters={opportunityQuarters}
              status={selectedOpportunityQuarter?.status ?? (opportunityQuarters.length === 0 ? 'NOSTATUS' : 'INPROGRESS')}
              selectedOpportunityQuarterValue={
                selectedOpportunityQuarter?.value
              }
              selectedOpportunityQuarterId={selectedOpportunityQuarterId}
              setSelectedOpportunityQuarterId={setSelectedOpportunityQuarterId}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          lg={8}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className="header-filter-box-search-add">
            <OpportunitiesSearchBar
              loading={state.hasTag('loading')}
              defaultSearchStringValue={
                state.context.additionalQueryStringValues.searchString
              }
              updateSearchStringQueryValue={(searchString) =>
                send({
                  destructive: true,
                  key: 'searchString',
                  type: 'addQueryStringValue',
                  value: searchString,
                })
              }
            />
            {isOpportunityUpload && <OpportunitiesActionButton
              buttonText="Upload new"
              buttonClassName="btn-add"
              iconClassName="fas fa-file-upload"
              show={canUpload}
              onClick={openUploadModal}
            />}
            {isOpportunityUpload &&
            <OpportunitiesActionButton
              buttonText="Edit"
              buttonClassName="btn-edit"
              iconClassName="fas fa-edit"
              show={canEdit}
              onClick={openEditModal}
            />}
            {isOpportunityUpload &&
            <OpportunitiesActionButton
              buttonText="Publish"
              buttonClassName="btn-publish"
              iconClassName="fas fa-share"
              show={canPublish}
              onClick={openPublishModal}
            />}
            {isOpportunityExport &&
            <OpportunitiesActionButton
              buttonText="Export"
              buttonClassName="btn-add-blue"
              iconClassName="fas fa-file-export"
              show={canExport}
              onClick={openExportModal}
            />}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default OpportunitiesToolbar;
