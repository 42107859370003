import {
  CLEAR_LOGIN,
  CLEAR_LOGIN_ERROR,
  UPDATE_LOGIN_SPINNER,
  UPDATE_LOGIN_EMAIL,
  UPDATE_LOGIN_PASSWORD,
  UPDATE_LOGIN_SHOW_PASSWORD,
  UPDATE_LOGIN_EMAIL_ERROR,
  UPDATE_LOGIN_PASSWORD_ERROR,
  UPDATE_LOGIN_SERVER_ERROR,
  UPDATE_LOGIN_REDIRECT,
} from "../constants";

const initialState = {
  spinner: false,
  email: "",
  password: "",
  showPassword: false,
  errorEmail: "",
  errorPassword: "",
  errorServerResponse: "",
  redirect: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_EMAIL:
      return {
        ...state,
        email: action.email,
      };
    case UPDATE_LOGIN_PASSWORD:
      return {
        ...state,
        password: action.password,
      };
    case UPDATE_LOGIN_SHOW_PASSWORD:
      return {
        ...state,
        showPassword: action.showPassword,
      };
    case UPDATE_LOGIN_EMAIL_ERROR:
      return {
        ...state,
        errorEmail: action.errorEmail,
      };
    case UPDATE_LOGIN_PASSWORD_ERROR:
      return {
        ...state,
        errorPassword: action.errorPassword,
      };
    case UPDATE_LOGIN_SERVER_ERROR:
      return {
        ...state,
        errorServerResponse: action.errorMessage,
      };
    case UPDATE_LOGIN_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case UPDATE_LOGIN_REDIRECT:
      return {
        ...state,
        redirect: action.redirect,
        loggedInUser: action.payload,
      };
    case CLEAR_LOGIN:
      return {
        ...state,
        ...initialState,
      };
    case CLEAR_LOGIN_ERROR:
      return {
        ...state,
        errorServerResponse: "",
      };
    default:
      return state;
  }
};
