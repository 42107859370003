import {
  InputBase,
  Grid,
  FormControl,
  InputLabel,
  Select,
} from '@material-ui/core';
import { FC } from 'react';

export const AssignUserFilter: FC<any> = ({
  vendorDCList,
  onChangeVendorUserAssignDC,
  errorDistributionCenter,
  searchText,
  setSearchText,
}) => {
  return (
    <div className="vendor-member-text-box">
      <div className="header-filter-box-search">
        <div className="page-search-box">
          <i className="fas fa-search"></i>
          <InputBase
            placeholder="Search"
            value={searchText && searchText.toString()}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12}>
          <FormControl required variant="outlined" className="select-box">
            {!errorDistributionCenter ? (
              <InputLabel htmlFor="outlined-age-native-simple">
                Distribution Center
              </InputLabel>
            ) : (
              <InputLabel
                htmlFor="outlined-age-native-simple"
                style={{ color: 'red' }}
              >
                Distribution Center
              </InputLabel>
            )}
            <Select
              onChange={(e) => onChangeVendorUserAssignDC(e.target.value)}
              label="Distribution Center"
              error={errorDistributionCenter}
              id={
                errorDistributionCenter
                  ? 'standard-error'
                  : 'outlined-age-native-simple'
              }
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <option value={0}>{'Select'}</option>
              {vendorDCList &&
                vendorDCList.map((item: any, index: number) => {
                  return (
                    <option key={index} value={item.id}>
                      {item.name}
                    </option>
                  );
                })}
            </Select>
            {errorDistributionCenter ? (
              <p style={{ color: 'red', fontSize: '12px' }}>
                {'Please select a distribution center'}
              </p>
            ) : (
              ''
            )}
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};
