import {connect} from "react-redux";
import {FundTransferContent} from "./FundTransferContent";

const mapStateToProps = (state) => {
    const {user, changePasswordForm, profile} = state;
    return {
        user,
        changePasswordForm,
        profile
    };
};

export const FundTransfer = connect(mapStateToProps, {

})(FundTransferContent);

