import { FC, useEffect, useState } from 'react';
import { Navigate, useNavigate,useLocation } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import HeaderBGimage from '../../../../images/bg-header-2.png';
import { WarningPopup } from '../../../../utils';
import AuthorizationService from '../../../../authorization/AuthorizationService';

type assignedUsersType = {
  id: number;
  firstname: string;
  lastname: string;
  membername: string;
  memberUserId: number;
};

export const MemberBreadCrumb: FC<{
  members: { selectedMember: any; deleteSpinner: any; redirectOnDelete: any; assignedUsers : assignedUsersType[] };
  deleteMember: () => void;
  updateMemberInActive : () => void;
}> = ({
  members: { selectedMember, deleteSpinner, redirectOnDelete, assignedUsers},
  deleteMember,
  updateMemberInActive,
}) => {
  // declaration
  const navigate = useNavigate();
  const detail = selectedMember || null;
  const selectedDeleteUser = detail ? detail.name : null;
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [nav, setNav] = useState('none');
  const location:any = useLocation()
  const sessionstorage:any = sessionStorage.getItem('previous')
  const {path,isFromMemberTab,pageNumber,vendortabs,pageSize} = JSON.parse(sessionstorage) || {path:'',isFromMemberTab : false,pageNumber:'',vendortabs:false,pageSize:''}
  useEffect(() => {
    if (redirectOnDelete) {
      navigate('/members');
    }
  }, [redirectOnDelete]);


useEffect(() => {
  if(location.state.path !== undefined){
    const details = {
      'path' : location.state.path || '/members',
      'isFromMemberTab' : location?.state?.isFromMemberTab ? true : false,
      'pageNumber' : location?.state?.pageNumber,
      'vendortabs' : location?.state?.vendortabs,
      'pageSize' : location?.state?.pageSize ? location?.state?.pageSize : 8
    }
    sessionStorage.setItem('previous', JSON.stringify(details))
  }
},[])

  const handleDeleteMemberTrigger = () => {
    setDeleteWarning(true);
  };
  const handleProceedDelete = async () => {
    // clear popup
    // deleteMember();
    navigate(path, 
      { 
        state: { 
          isFromMemberTab : isFromMemberTab,
          pageNumber : pageNumber,
          vendortabs : vendortabs,
          pageSize : pageSize
        } 
      }
    )
    updateMemberInActive()
  };

  const handleClose = () => {
    setDeleteWarning(false);
  };

  if (nav === 'edit') {
    return (
      <Navigate
        to={`/members/edit/${detail.id}/`}
        state={{
          detail: detail,
          navigateFrom: 'VIEW',
          pageType: 'EDIT',
        }}
      />
    );
  }

  return (
    <Grid container className="details-header">
      <div className="details-header-img">
        <img src={HeaderBGimage} alt="member" title="Member Header" />
        <Grid
          container
          spacing={2}
          alignItems="center"
          className="details-header-content"
        >
          <Grid item xs={6} md={7}>
            <div className="details-header-left">
              <div className="breadcrumb-details-header">
                <div
                  className="details-header-back"
                  onClick={() =>
                    navigate(path, 
                      { 
                        state: { 
                          isFromMemberTab : isFromMemberTab,
                          pageNumber : pageNumber,
                          vendortabs : vendortabs,
                          pageSize : pageSize
                        } 
                      }
                    )
                  }
                >
                  <i className="fas fa-arrow-left"></i>
                  <span>Back</span>
                </div>
                <div className="details-header-breadcrumb">
                  <div>
                    <a
                      onClick={() => navigate('/dashboard')}
                      style={{ cursor: 'pointer' }}
                    >
                      <i className="fas fa-home"></i>
                    </a>
                  </div>
                  <div>
                    <a
                      onClick={() => navigate('/members')}
                      style={{ cursor: 'pointer' }}
                    >
                      Members
                    </a>
                  </div>
                  {detail ? (
                    <div>
                      <a>
                        <i className="fas fa-chevron-right"></i>
                        <span>{detail.name}</span>
                      </a>
                    </div>
                  ) : null}
                </div>
              </div>

              {detail ? (
                <div>
                  <h2 className="details-header-title">{detail.name}</h2>
                  <div className="details-header-member-info">
                    {detail.city}, {detail.state}
                  </div>
                </div>
              ) : null}
            </div>
          </Grid>
          <Grid item xs={6} md={5}>
            <div className="details-header-right">
              {AuthorizationService.isAuthorizedHTML('editmemberbutton') && (
                <div
                  className="details-header-edit"
                  onClick={() => {
                    if (detail) {
                      setNav('edit');
                    }
                  }}
                >
                  <i className="fas fa-pen"></i>
                  <span>Edit</span>
                </div>
              )}
              {AuthorizationService.isAuthorizedHTML('deleteemberbutton') && (
                <Tooltip title={'Delete'} placement="bottom">
                  <div
                    className="details-header-delete"
                    onClick={() => handleDeleteMemberTrigger()}
                  >
                    <i className="fas fa-trash-alt" />
                  </div>
                </Tooltip>
              )}
            </div>
          </Grid>
        </Grid>
      </div>
      <WarningPopup
        spinner={deleteSpinner}
        open={deleteWarning}
        name={selectedDeleteUser}
        type={assignedUsers.length > 0 ? 'assigned' : 'delete'}
        handleProceed={handleProceedDelete}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default MemberBreadCrumb;
