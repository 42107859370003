import { useMachine } from '@xstate/react';
import { FC, useEffect } from 'react';
import { dropSizeAgreementsMachine } from '../../../machines';
import CompactDropSizeChart from './CompactDropSizeChart';
import StandardDropSizeChart from './StandardDropSizeChart';
import { useDispatch } from 'react-redux';
import { savingsPercentage } from '../../../actions/dashboard';
import { useSelector } from 'react-redux';


export const DropSize: FC<{
  actor?: any;
  id?: string;
  compact?: boolean;
  memberId: number;
}> = ({ actor, id, compact, memberId }) => {
  const [state, send, service] = useMachine(dropSizeAgreementsMachine, {
    context: {
      memberFilters: [memberId],
    },
  });

  const dispatch:any = useDispatch()
  const {dashboardSavingsPercentage}:any = useSelector(state => state)


  useEffect(() => {
    dispatch(savingsPercentage())
  },[])

  useEffect(() => {
    if (memberId !== state.context.memberFilters?.[0]) {
      send({
        memberFilters: [memberId],
        type: 'updateMemberFilters',
      });
    }
  }, [memberId]);

  if (compact)
    return <CompactDropSizeChart key={memberId} id={id} dropSizeAgreementsActor={actor ?? service} />;

  return <StandardDropSizeChart key={memberId} id={id} dropSizeAgreementsActor={actor} savings={dashboardSavingsPercentage?.savingsPercentage}/>;
};

export default DropSize;
