import { FC } from 'react';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { FormControl, Grid, InputLabel, Select } from '@mui/material';
import { DateTime } from 'luxon';

const quarterList = [
  {
    label: 'Q1',
    value: 'Q1',
  },
  {
    label: 'Q2',
    value: 'Q2',
  },
  {
    label: 'Q3',
    value: 'Q3',
  },
  {
    label: 'Q4',
    value: 'Q4',
  },
];

export const ExportRebateReceiptsModal: FC<any> = ({
  rebateReceipts: { exportYear, exportQuarter },
  handleRebateReceiptExportQuarter,
  handleRebateReceiptExportYear,
}) => {
  const currentDate = DateTime.now();
  const currentYear = currentDate.year;
  const nextYear = currentDate.plus({ years: 1 }).year;

  const yearList = [
    {
      label: currentYear,
      value: currentYear,
    },
    {
      label: nextYear,
      value: nextYear,
    },
  ];

  return (
    <div className="export-wrapper">
      <Grid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Quarter
            </InputLabel>
            <Select
              value={exportQuarter}
              onChange={(event) =>
                handleRebateReceiptExportQuarter(event.target.value)
              }
              label="Select Quarter"
            >
              {quarterList &&
                quarterList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Year
            </InputLabel>
            <Select
              value={exportYear}
              onChange={(event) =>
                handleRebateReceiptExportYear(event.target.value)
              }
              label="Select Year"
            >
              {yearList &&
                yearList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </div>
  );
};

export default ExportRebateReceiptsModal;
