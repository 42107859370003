import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import InputBase from '@material-ui/core/InputBase';
import Button from '@material-ui/core/Button';
import { ITEMS_GRID_VIEW, ITEMS_LIST_VIEW } from '../../../constants';
import AuthorizationService from '../../../authorization/AuthorizationService';

export const MembersFilters: FC<{
  onChangeMemberView: any;
  viewType: any;
  searchText: any;
  setSearchText: any;
  send: any;
}> = ({
  onChangeMemberView,
  viewType,
  searchText,
  setSearchText,
  send,
}) => {
  const navigate = useNavigate();
  const callAddMember = () => {
    navigate('/members/members-add');
  };
  const showAddButton =
    AuthorizationService.isAuthorizedHTML('addmemberbutton');

  const handleSearch = (event: any) => {
    // handleChangePage(event, 0);
    setSearchText(event.target.value);
    send({
      destructive: true,
      key: 'searchString',
      type: 'addQueryStringValue',
      value: event.target.value,
    });
  };

  return (
    <Grid container spacing={3} className="header-filter-box">
      <Grid
        xs={12}
        md={5}
        alignItems="center"
        justify="flex-start"
        item
        container
        alignContent="center"
      >
        <div className="view-icon">
          <Tooltip title="Grid View">
            <i
              className={
                ITEMS_GRID_VIEW == viewType
                  ? 'fas fa-th-large active'
                  : 'fas fa-th-large'
              }
              onClick={() => onChangeMemberView(ITEMS_GRID_VIEW)}
            />
          </Tooltip>
          <Tooltip title="List View">
            <i
              className={
                ITEMS_LIST_VIEW == viewType
                  ? 'fas fa-list active'
                  : 'fas fa-list'
              }
              onClick={() => onChangeMemberView(ITEMS_LIST_VIEW)}
            />
          </Tooltip>
        </div>
      </Grid>

      <Grid
        item
        xs={12}
        md={7}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <div className="header-filter-box-search-add">
          <div className="header-filter-box-search">
            <div className="page-search-box ">
              <i className="fas fa-search" />
              <InputBase
                placeholder="Search"
                value={searchText && searchText.toString()}
                onChange={(event) => handleSearch(event)}
              />
            </div>
          </div>
          {showAddButton && (
            <div className="header-filter-box-add">
              <Button
                variant="contained"
                size="small"
                className="btn-add"
                onClick={() => callAddMember()}
              >
                <span className="fas fa-plus-circle"></span>{' '}
                <span>Add Member</span>
              </Button>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
};
