import { FC, useState, useEffect } from 'react';
import { MemberTitle } from './components/MemberTitle';
import { MembersFilters } from './components/MembersFilters';
import { MemberGrid } from './memberSection/MemberGrid';
import { MemberList } from './memberSection/MemberList';
import { ITEMS_GRID_VIEW } from '../../constants';
import { Loader } from '../../utils';
import './members.scss';
import { useMachine } from '@xstate/react';
import { paginationMachine } from '../../machines';
import serverApiV2Instance from '../../services/serverApiV2Instance';
import { useLocation } from 'react-router-dom';

export const MembersMain: FC<any> = ({
  onChangeMemberView,
  members: { viewType },
  common: { statesList },
  handleChangePage,
  user,
}) => {
  const location:any = useLocation()

  const [state, send] = useMachine(paginationMachine, {
    context: {
      additionalQueryStringValues: {
        orderBy: 'timestamp',
        sortOrder: 'desc',
      },
      pageSize: location?.state?.isFromMemberTab ? location?.state?.pageSize : 8,
      pageNumber : location?.state?.isFromMemberTab ? location?.state?.pageNumber : 1,
    },
    services: {
      recordLoader: (context) => {
        const query = new URLSearchParams({
          ...context.additionalQueryStringValues,
          pageNumber: context.pageNumber.toString(),
          pageSize: context.pageSize.toString(),
        });
        return serverApiV2Instance.get(
          `/members?${query.toString()}`,
        );
      },
    },
  });

  const [searchText, setSearchText] = useState('');
  useEffect(()=> window.history.replaceState({}, ''),[])
  return (
    <div>
      <MemberTitle />

      <div className="container">
        <MembersFilters
          onChangeMemberView={onChangeMemberView}
          viewType={viewType}
          searchText={searchText}
          setSearchText={setSearchText}
          send={send}
        />
        <div>
          {viewType === ITEMS_GRID_VIEW ? (
            <MemberGrid
              membersList={state.context.records}
              statesList={statesList}
              state={state}
              send={send}
            />
          ) : (
            <MemberList
              membersList={state.context.records}
              user={user}
              state={state}
              send={send}
            />
          )}
        </div>
      </div>
    </div>
  );
};
