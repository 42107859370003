import { VendorUserUpdateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const updateVendorUser = async ({
  vendorID,
  type,
  userId,
  selectedRole,
  jobTitle,
  selectedDC,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('vendorid', vendorID);
    bodyFormData.append('roleid', selectedRole);
    bodyFormData.append('jobtitle', jobTitle);
    bodyFormData.append('distributionid', selectedDC);

    const response = await serviceInterceptor.patch(
      `/user/${userId}?type=${type}`, // TODO NOT RESTFUL!!
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw VendorUserUpdateError;
    }
    return response.data;
  } catch (error) {
    throw VendorUserUpdateError;
  }
};
