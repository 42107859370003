import {
  Fade,
  Grid,
  LinearProgress,
  useMediaQuery,
} from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '@xstate/react';
import { FC } from 'react';
import GaugeChart from 'react-gauge-chart';
import { ActorRef } from 'xstate';
import useSelectedAgreementValues from './useSelectedAgreementValues';

export const CompactDropSizeChart: FC<{
  dropSizeAgreementsActor: ActorRef<any, any>; // TODO Temporary fix until correct format is discoverd or bug is resolved
  id?: string;
}> = ({ dropSizeAgreementsActor, id }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const loading = useSelector(dropSizeAgreementsActor, (state) =>
    state.hasTag('loading'),
  );

  const [
    gaugeChartProps,
    averageDropSize,
    lowRange,
    highRange,
    validGraph,
    avgFontClass,
    details,
  ] = useSelectedAgreementValues(dropSizeAgreementsActor, id);

  return (
    <Grid container direction="column">
      <Grid item container alignItems="center" alignContent="center">
        <div className="pie-header">
          <h3 className="pie-title">Drop Size</h3>
          {validGraph && (
            <div
              className="pie-sub-title"
              style={{
                color: 'rgb(130, 129, 129)',
                fontSize: '14px',
                padding: '5px 0 5px 0',
              }}
            >
              {'Target Range: '}
              {Number(lowRange).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
              })}
              {lowRange < highRange ? (
                <>
                  {' - '}
                  {Number(highRange).toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </>
              ) : (
                ' and above'
              )}
            </div>
          )}
        </div>
        {validGraph && (
          <Grid className="legend-label" container spacing={2}>
            <Grid item sm={3} xs={12}>
              <div className="legend-icon-below" />
              <div className="legend-text-below">Below Target</div>
            </Grid>
            {lowRange < highRange ? (
              <>
                <Grid item sm={3} xs={12}>
                  <div className="legend-icon-within" />
                  <div className="legend-text-within">Within Target</div>
                </Grid>
                <Grid item sm={3} xs={12}>
                  <div className="legend-icon-above" />
                  <div className="legend-text-above">Above Target</div>
                </Grid>
              </>
            ) : (
              <Grid item sm={3} xs={12}>
                <div className="legend-icon-above" />
                <div className="legend-text-above">Within Target</div>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
      <Grid item container alignItems="center" alignContent="center">
        <GaugeChart
          style={{
            maxWidth: matches ? '350px' : '200px',
            width: '100%',
            alignSelf: 'center',
            margin: '0 auto',
          }}
          {...gaugeChartProps}
        />
        {(matches || validGraph) && (
          <Fade in={typeof averageDropSize === 'number' && validGraph}>
            <div className="progress-details">
              <div>
                Average drop size:{' '}
                <span className={avgFontClass || undefined}>
                  {averageDropSize.toLocaleString('en-US', {
                    style: 'currency',
                    currency: 'USD',
                  })}
                </span>
              </div>
            </div>
          </Fade>
        )}
        <Fade in={loading}>
          <LinearProgress
            style={{ maxWidth: '300px', width: '100%', margin: '0 auto' }}
          />
        </Fade>
      </Grid>
      <Grid item container alignItems="center" alignContent="center">
        {validGraph ?
          'The drop size you are viewing is from today’s date to 6 months back.' : details}
      </Grid>
    </Grid>
  );
};

export default CompactDropSizeChart;
