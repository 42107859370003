import { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import Tooltip from '@material-ui/core/Tooltip';

const SideMenuItem: FC<{
  pathName: string;
  menuTitle: string;
  iconClassName: string;
  isMobileMenu: boolean;
  mobileMenuClose: () => void;
}> = ({
  pathName,
  menuTitle,
  iconClassName,
  isMobileMenu,
  mobileMenuClose,
}) => {
  const location = useLocation();
  return (
    <Link
      to={pathName}
      className={
        location.pathname === pathName
          ? 'side-bar-item active'
          : 'side-bar-item'
      }
      onClick={() => {
        menuTitle !== 'Community' && isMobileMenu && mobileMenuClose();
      }}
    >
      <Tooltip title={menuTitle}>
        <i className={iconClassName} />
      </Tooltip>
      <span>{menuTitle}</span>
    </Link>
  );
};
export default SideMenuItem;
