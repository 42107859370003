import Box from '@mui/material/Box';
import { FC, ReactNode } from 'react';

export const RebatesOptionsTabPanel: FC<{ value: any; index: number, children?: ReactNode }> = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

export default RebatesOptionsTabPanel;
