import { FC } from 'react';
import {
  Button,
  createStyles,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
} from '@material-ui/core';
import { ActorRefFrom } from 'xstate';
import {
  ClaimRebatesMachine,
  RebateReceiptElectronicTypeItemMachine,
} from '../machines';
import { useActor } from '@xstate/react';
import { centsToFormattedDollars, Loader } from '../../../../../../utils';
import RebateReceiptElectronicItemTypeUI from './RebateReceiptElectronicItemTypeUI';
import { AddCircle } from '@material-ui/icons';

export const ElectronicMode: FC<{
  actor: ActorRefFrom<ClaimRebatesMachine>;
}> = ({ actor }) => {
  const [state, send] = useActor(actor);

  const itemTotalAmount = state.context.items.reduce((total, item) => {
    const snapShot = item.getSnapshot();
    return total + (snapShot?.context?.amount ?? 0);
  }, 0);

  console.log(
    'state.context.items: ',
    state.context.items.map((item) => item.getSnapshot()?.context),
  );

  console.log(
    'state.context.items.ids: ',
    state.context.items.map((item) => item.id),
  );
  console.log('state.context.amount: ', state.context.amount);

  console.log('itemTotalAmount: ', itemTotalAmount);

  return (
    <div>
      {state.hasTag('loading') ? (
        <div className="relative-wrapper">
          <div className="loader-wrapper list-spinner">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        </div>
      ) : state.context.fundingSources.length < 1 ? (
        <div className="account-notes" style={{ textAlign: 'center' }}>
          Please set-up bank account settings in{' '}
          <a href="./profile/fund-transfer">Account Settings</a> in order to use
          the Electronic Transfer feature
        </div>
      ) : (
        <div className="electronic-tab">
          <List>
            {state.context.items.map((item, index) => {
              return (
                <>
                  <RebateReceiptElectronicItemTypeUI
                    key={item.id}
                    actor={
                      item as ActorRefFrom<RebateReceiptElectronicTypeItemMachine>
                    }
                    disabled={state.context.items.length <= 1}
                    fundingSources={state.context.fundingSources}
                    removeReceiptItem={(electronicRebateReceiptItemId) =>
                      send({
                        type: 'removeElectronicTransferItem',
                        electronicRebateReceiptItemId,
                      })
                    }
                  />
                  {index === state.context.items.length - 1 ? (
                    state.context.items.length < state.context.itemLimit && <ListItem>
                      <Button
                        variant="contained"
                        size="small"
                        className={
                          state.context.items.length === state.context.itemLimit
                            ? 'btn-disabled'
                            : 'btn-add'
                        }
                        disabled={
                          state.context.items.length === state.context.itemLimit
                        }
                        onClick={() =>
                          send({ type: 'addElectronicTransferItem' })
                        }
                      >
                        <span className="fas fa-plus-circle" />
                        <span>Add another account</span>
                      </Button>
                    </ListItem>
                  ) : (
                    <Divider component="li" />
                  )}
                </>
              );
            })}
          </List>
          <div className="mode-rebate-total">
            <div className="mode-rebate-total-text">
              <span className="mode-rebate-total-info">Total</span>
              <span
                className="mode-rebate-total-amount"
                style={{
                  color:
                    (state.context.amount ?? 0) === itemTotalAmount
                      ? 'green'
                      : 'red',
                }}
              >
                {centsToFormattedDollars(itemTotalAmount)}
              </span>
            </div>
          </div>
          <div>
            {state.context.fundingSources.length < 6 && (
              <div className="account-notes" style={{ textAlign: 'right' }}>
                Add a new bank account in{' '}
                <a href="./profile/fund-transfer">account settings</a>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ElectronicMode;
