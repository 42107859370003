import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { SearchNotFound } from '../../../components/SearchNotFound';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import { ConnectedPagination } from '../../../components/ConnectedPagination';
import {
  Loader,
  getFileClassName,
  getExtensionClassName,
  getPaginatedData,
} from '../../../utils';
import AuthorizationService from '../../../authorization/AuthorizationService';

const isDocumentEdit = AuthorizationService.isAuthorizedHTML('editDocument');
const isDocumentDelete =
  AuthorizationService.isAuthorizedHTML('deleteDocument');

const gridHeader = [
  {
    label: 'File Name',
    value: 'name',
    sortKey: 'name',
    xs: 12,
    lg: 4,
  },
  {
    label: 'Category',
    value: 'category',
    sortKey: 'category',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Access Level',
    value: 'accessLevel',
    sortKey: 'accessLevel',
    xs: 12,
    lg: isDocumentEdit || isDocumentDelete ? 2 : 3,
  },
  {
    label: 'Uploaded Date',
    value: 'uploadeddate',
    sortKey: 'uploadeddate',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Action',
    value: 'action',
    xs: 12,
    lg: isDocumentEdit || isDocumentDelete ? 2 : 1,
  },
];

export const DocumentsList = ({
  documents: {
    documentList,
    selectedCategoryID,
    downloadSpinner,
    downloadIconVisible,
  },
  showDocumentDetailModal,
  showDocumentEditModal,
  handleDeleteDocument,
  searchText,
  downloadDocument,
  paginationReducer: { rowsPerPage, page },
}) => {
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState('');
  const [activeHeader, setActiveHeader] = useState(null);
  const [selectedItemID, setSelectedItemID] = useState(null);

  const setSortKey = (newSortKey) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  const handleClick = (event, sortKey) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  const handleDocumentDownload = (item) => {
    downloadDocument(item);
    setSelectedItemID(item.id);
  };

  const categorisedData =
    selectedCategoryID === 0
      ? documentList
      : documentList.filter(
          (item) => item.documenttypeid === selectedCategoryID,
        );

  const filteredData =
    searchText && categorisedData.length > 0
      ? categorisedData.filter((lineItem) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : categorisedData;

  const sortedData =
    sortKey && sortType && filteredData.length > 0
      ? filteredData &&
        filteredData.sort((a, b) => {
          if (sortType === 'DESC') {
            return b[sortKey].localeCompare(a[sortKey]);
          }
          return a[sortKey].localeCompare(b[sortKey]);
        })
      : filteredData;

  const modifiedList = getPaginatedData(sortedData, rowsPerPage, page);

  if (documentList.length === 0) {
    return <SearchNotFound sectionType={'Documents'} />;
  } else {
    return (
      <div className="table-wrapper">
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {label === 'Action' ?
                  (<div className={label === 'Action' ? 'action-header' : ''}>
                    <span
                      className="grid-header"
                    >
                      {label}
                    </span>
                  </div>) :
                  (<div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {isValidHeader && label !== 'Action' ? (
                        sortType === '' ? (
                          <img className="sort-svg" src={sortDefault} />
                        ) : label === activeHeader && sortType === 'ASC' ? (
                          <img className="sort-svg" src={sortAscIcon} />
                        ) : label === activeHeader && sortType === 'DESC' ? (
                          <img className="sort-svg" src={sortDescIcon} />
                        ) : (
                          <img className="sort-svg" src={sortDefault} />
                        )
                      ) : null}
                    </span>
                  </div>)
                }
              </Grid>
            );
          })}
        </Grid>
        {modifiedList.map((item) => {
          const {
            id,
            name,
            uploadeddate,
            category,
            accessLevel,
            fileextension,
          } = item;
          const selectedDownloadItem = item.id === selectedItemID;
          const showDownloadIcon =
            downloadIconVisible && selectedDownloadItem && downloadSpinner;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                justifyContent="start"
                alignItems="center"
              >
                <div className="documents-list-data-name">
                  <div className="documents-grid-img">
                    {/* Need to change Doc icon and doc type*/}
                    <i className={getFileClassName(fileextension)} />
                    <span className={getExtensionClassName(fileextension)}>
                      {fileextension}
                    </span>
                  </div>
                  <span>{name}</span>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {category}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={isDocumentEdit || isDocumentDelete ? 2 : 3}
                container
                direction="row"
                alignItems="center"
              >
                {accessLevel}
              </Grid>
              <Grid
                className="td xs-left-align"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {uploadeddate}
              </Grid>
              <Grid
                className="td action-col"
                item
                xs={12}
                lg={isDocumentEdit || isDocumentDelete ? 2 : 1}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                <Tooltip title="Download">
                  <div className="download-icon-wrapper">
                    {selectedDownloadItem && downloadSpinner ? (
                      <div className="document-loader">
                        <Loader size={12} />
                      </div>
                    ) :
                    (<Button
                      className={
                        showDownloadIcon
                          ? 'btn-circle btn-primary'
                          : 'btn-circle btn-secondary'
                      }
                      variant="contained"
                      onClick={() => handleDocumentDownload(item)}
                      disabled={downloadIconVisible}
                    >
                      <i
                        class={
                          showDownloadIcon
                            ? 'fas fa-download icon-visibe'
                            : 'fas fa-download'
                        }
                      ></i>
                    </Button>)}
                  </div>
                </Tooltip>

                <Tooltip title="Details">
                  <Button
                    className="btn-circle btn-secondary"
                    variant="contained"
                    onClick={() => showDocumentDetailModal(item)}
                  >
                    <i class="far fa-eye" />
                  </Button>
                </Tooltip>

                {isDocumentEdit && (
                  <Tooltip title="Edit">
                    <Button
                      className="btn-circle btn-secondary"
                      variant="contained"
                      onClick={() => showDocumentEditModal(item)}
                    >
                      <i class="fas fa-pen" />
                    </Button>
                  </Tooltip>
                )}

                {isDocumentDelete && (
                  <Tooltip title="Delete">
                    <Button
                      className="btn-circle-trash"
                      variant="outlined"
                      color="secondary"
                      onClick={() => handleDeleteDocument(item)}
                    >
                      <i class="fas fa-times" />
                    </Button>
                  </Tooltip>
                )}
              </Grid>
            </Grid>
          );
        })}
        {documentList.length > 0 ? (
          <ConnectedPagination count={documentList.length} />
        ) : null}
      </div>
    );
  }
};
