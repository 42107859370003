import {
    SHOW_DISCUSSION_FORM_SPINNER,
    HIDE_DISCUSSION_FORM_SPINNER,
    UPDATE_DISCUSSION_TITLE_ERROR,
    UPDATE_DISCUSSION_ID,
    UPDATE_DISCUSSION_TITLE,
    UPDATE_EDIT_DISCUSSION_MODAL,
    UPDATE_DISCUSSION_INPUT_FORM,
    UPDATE_DISCUSSION_DESC_ERROR,
    UPDATE_DISCUSSION_DESC,
    CLEAR_COMMUNITY_DISCUSSION_MODAL,
    UPDATE_ALL_DISCUSSIONS,
    CREATE_NEW_DISCUSSION_ERROR,
    UPDATE_ALL_DISCUSSIONS_ERROR,
    EDIT_DISCUSSION_ERROR,
    HANDLE_NEW_DISCUSSION_MODAL,
    UPDATE_REPORT_FLAG,
    UPDATE_REPORT_REASON,
    UPDATE_REPORT_REASON_LIST,
    GET_REPROT_REASON_ERROR,
    ReportReasonListError,
    GetAllDiscussionsError,
    CreateDiscussionsError,
    FetchError
} from "../../../constants";

import {
    createDiscussion,
    getAllDiscussions,
    editDiscussion,
    getReportReasons
} from "../../../api/community";

import {isValidText} from "../channel/channel";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showDiscussionModalSpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_DISCUSSION_FORM_SPINNER
    });
};

export const hideDiscussionModalSpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_DISCUSSION_FORM_SPINNER
    });
};

export const handleNewDiscussionModal = (isNewDiscussionModal) => async (dispatch) => {
    dispatch({
        type: HANDLE_NEW_DISCUSSION_MODAL,
        isNewDiscussionModal
    });
};

export const handleDiscussionTitle = (value) => async (dispatch) => {
    if (value !== "") {
        dispatch({
            type: UPDATE_DISCUSSION_TITLE_ERROR,
            discussionTitleError: false
        });
    }
    dispatch({
        type: UPDATE_DISCUSSION_TITLE,
        discussionTitle: value,
    });
    dispatch({
        type: UPDATE_DISCUSSION_INPUT_FORM,
        isDiscussionFormUpdated: true
    });
};

export const handleDiscussionDesc = (value) => async (dispatch) => {
    if (value !== "") {
        dispatch({
            type: UPDATE_DISCUSSION_DESC_ERROR,
            discussionDescError: false
        });
    }
    dispatch({
        type: UPDATE_DISCUSSION_DESC,
        discussionDesc: value,
    });
    dispatch({
        type: UPDATE_DISCUSSION_INPUT_FORM,
        isDiscussionFormUpdated: true
    });
};

export const clearCommunityDiscussionModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_COMMUNITY_DISCUSSION_MODAL
    });
};

export const handleEditDiscussionFlag = (isEditDiscussion) => async (dispatch) => {
    dispatch({
        type: UPDATE_EDIT_DISCUSSION_MODAL,
        isEditDiscussion
    });
};

export const handleEditDiscussion = (isEditDiscussion, selectedDiscussion) => async (dispatch) => {
    const {id, title, description} = selectedDiscussion;
    dispatch(handleEditDiscussionFlag(isEditDiscussion));
    dispatch({
        type: UPDATE_DISCUSSION_ID,
        discussionId: id,
    });
    dispatch({
        type: UPDATE_DISCUSSION_TITLE,
        discussionTitle: title,
    });
    dispatch({
        type: UPDATE_DISCUSSION_DESC,
        discussionDesc: description,
    });
};


export const createNewDiscussion = () => async (dispatch, getState) => {
    const {user, communityChannel, communityDiscussion} = getState();
    const {activeChannelID} = communityChannel;
    const {id} = user;
    const {discussionTitle, discussionDesc} = communityDiscussion;
    const isValidDiscussionTitle = isValidText(discussionTitle);
    const isValidDiscussionDesc = isValidText(discussionDesc);

    if (!isValidDiscussionTitle) {
        dispatch({
            type: UPDATE_DISCUSSION_TITLE_ERROR,
            discussionTitleError: true
        });
    }
    if (!isValidDiscussionDesc) {
        dispatch({
            type: UPDATE_DISCUSSION_DESC_ERROR,
            discussionDescError: true
        });
    }
    const isError = !isValidDiscussionTitle || !isValidDiscussionDesc;
    try {
        if (!isError) {
            dispatch(showDiscussionModalSpinner());
            const response = await createDiscussion({
                activeChannelID,
                discussionTitle,
                discussionDesc,
                userId: id
            });
            const res = await getAllDiscussions({
                activeChannelID
            });
            const {data} = res;
            dispatch({
                type: UPDATE_ALL_DISCUSSIONS,
                discussionList: data
            })
            const {success, message} = response;
            if (!success) {
                dispatch(hideDiscussionModalSpinner());
                toast.error(CreateDiscussionsError)
            }
            else {
                dispatch({
                    type: UPDATE_DISCUSSION_INPUT_FORM,
                    isDiscussionFormUpdated: false
                });
                dispatch(hideDiscussionModalSpinner());
                dispatch(clearCommunityDiscussionModal())
                dispatch(handleNewDiscussionModal())
                toast.success(message);
            }
        }
    }
    catch (error) {
        dispatch(hideDiscussionModalSpinner());
        if (error === CREATE_NEW_DISCUSSION_ERROR) {
            toast.error(CreateDiscussionsError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const updateDiscussionDetails = () => async (dispatch, getState) => {
    const {user, communityChannel, communityDiscussion, communityDiscussionActions} = getState();
    const {activeChannelID} = communityChannel;
    const {id} = user;
    const {discussionTitle, discussionDesc} = communityDiscussion;
    const {discussionId} = communityDiscussionActions;
    const isValidDiscussionTitle = isValidText(discussionTitle);
    const isValidDiscussionDesc = isValidText(discussionDesc);

    if (!isValidDiscussionTitle) {
        dispatch({
            type: UPDATE_DISCUSSION_TITLE_ERROR,
            discussionTitleError: true
        });
    }
    if (!isValidDiscussionDesc) {
        dispatch({
            type: UPDATE_DISCUSSION_DESC_ERROR,
            discussionDescError: true
        });
    }
    const isError = !isValidDiscussionTitle || !isValidDiscussionDesc;
    try {
        if (!isError) {
            dispatch(showDiscussionModalSpinner());
            const response = await editDiscussion({
                activeChannelID,
                discussionId,
                userId: id,
                discussionTitle,
                discussionDesc
            });
            const res = await getAllDiscussions({
                activeChannelID
            });
            const {data} = res;
            dispatch({
                type: UPDATE_ALL_DISCUSSIONS,
                discussionList: data
            })
            const {success, message} = response;
            if (!success) {
                dispatch(hideDiscussionModalSpinner());
                toast.error(CreateDiscussionsError)
            }
            else {
                dispatch({
                    type: UPDATE_DISCUSSION_INPUT_FORM,
                    isDiscussionFormUpdated: false
                });
                dispatch(handleEditDiscussionFlag(false));
                dispatch(hideDiscussionModalSpinner());
                dispatch(clearCommunityDiscussionModal())
                dispatch(handleNewDiscussionModal())
                toast.success(message);
            }
        }
    }
    catch (error) {
        dispatch(hideDiscussionModalSpinner());
        if (error === EDIT_DISCUSSION_ERROR) {
            toast.error(CreateDiscussionsError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
}