import { DateTime } from 'luxon';
import { createMachine, sendParent } from 'xstate';
import { OpportunityQuarters } from '../../../../../common';
import serviceInterceptor from '../../../../../services/ServiceInterceptor';
import { assign } from '@xstate/immer';

interface OpportunitiesUploadContext {
  quarter: 1 | 2 | 3 | 4;
  opportunityQuarters: OpportunityQuarters;
  opportunitiesDocumentFile: File | null;
  year: number;
}

type OpportunitiesUploadEvent =
  | { type: 'cancel' }
  | { type: 'continue editing' }
  | { type: 'select file'; file: File }
  | { type: 'remove file' }
  | { type: 'submit file' }
  | { type: 'update quarter'; quarter: 1 | 2 | 3 | 4 }
  | { type: 'update year'; year: number };

export const opportunitiesUploadMachine = createMachine(
  {
    id: 'opportunitiesUpload',
    tsTypes: {} as import('./opportunitiesUploadMachine.typegen').Typegen0,
    schema: {
      context: {} as OpportunitiesUploadContext,
      events: {} as OpportunitiesUploadEvent,
      services: {} as {
        uploadRebateOpportunitiesDocumentFile: {
          data: {
            success: boolean;
            data: any;
          };
        };
      },
    },
    initial: 'editing',
    context: {
      quarter: 1,
      opportunitiesDocumentFile: null,
      opportunityQuarters: [],
      year: DateTime.now().year,
    },
    states: {
      editing: {
        tags: ['editing', 'user input required'],
        on: {
          cancel: [
            {
              actions: 'send parent close event',
              cond: 'no file has been selected',
            },
            {
              target: 'warning user of progress loss',
            },
          ],
          'remove file': {
            actions: 'unassign file',
          },
          'select file': {
            actions: 'assign file',
          },
          'submit file': [
            {
              actions: 'warn file is required',
              cond: 'no file has been selected',
            },
            {
              actions: 'warn year and quarter are uploaded',
              cond: 'the year and quarter combination has been uploaded already',
            },
            {
              target: 'submitting',
            },
          ],
          'update quarter': {
            actions: 'assign quarter',
            cond: 'requested quarter value is valid',
          },
          'update year': {
            actions: 'assign year',
            cond: 'requested year is valid',
          },
        },
      },
      'warning user of progress loss': {
        tags: ['warning', 'info', 'user input required'],
        on: {
          'continue editing': {
            target: 'editing',
          },
          cancel: {
            actions: 'send parent close event',
          },
        },
      },
      submitting: {
        tags: ['submitting', 'loading'],
        invoke: {
          id: 'Upload rebate document opportunities file',
          src: 'uploadRebateOpportunitiesDocumentFile',
          onDone: 'complete',
          onError: {
            actions: 'notify failure',
            target: 'editing',
          },
        },
      },
      complete: { type: 'final' },
    },
  },
  {
    actions: {
      'assign file': assign(
        (context, event) => (context.opportunitiesDocumentFile = event.file),
      ),
      'assign quarter': assign(
        (context, event) => (context.quarter = event.quarter),
      ),
      'assign year': assign((context, event) => (context.year = event.year)),
      'unassign file': assign(
        (context) => (context.opportunitiesDocumentFile = null),
      ),
      'send parent close event': sendParent('close modal'),
      'notify failure': () =>
        console.error('Failed to update rebate document opportunities file.'),
      'warn file is required': () => console.warn('Please select a file.'),
      'warn year and quarter are uploaded': () =>
        console.warn(
          'Year and quarter combination have already been selected.',
        ),
    },
    guards: {
      'no file has been selected': (context) =>
        !context.opportunitiesDocumentFile,
      'requested quarter value is valid': (context, event) =>
        context.quarter !== event.quarter,
      'requested year is valid': (context, event) =>
        context.year !== event.year,
      'the year and quarter combination has been uploaded already': (context) =>
        context.opportunityQuarters.filter(
          (opportunityQuarter) =>
            Number(opportunityQuarter.year) === context.year &&
            Number(opportunityQuarter.quarter) === context.quarter,
        ).length > 0,
    },
    services: {
      uploadRebateOpportunitiesDocumentFile: (context) => {
        // TODO update with v2
        const bodyFormData = new FormData();
        bodyFormData.append('quarter', context.quarter.toString());
        bodyFormData.append('year', context.year.toString());
        bodyFormData.append(
          'rebateProductList',
          context.opportunitiesDocumentFile as File,
        );
        return serviceInterceptor
          .post(`/rebate-product-list-records`, bodyFormData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => response.data);
      },
    },
  },
);

export type OpportunitiesUploadMachine = typeof opportunitiesUploadMachine;
export default opportunitiesUploadMachine;
