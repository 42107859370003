import React, {FC} from "react";
import PropTypes from "prop-types";
import { Grid, InputBase, Button } from '@material-ui/core';
import { SearchFilter } from "../../../../components/Search";

export const MemberHeader: FC<{
  searchText: any;
  setSearchText: any;
  showMemberAssignModal: any;
  state: any;
  send: any;
}> = ({ searchText, setSearchText, showMemberAssignModal, state, send }) => {
  return (
    <Grid container direction="row" alignItems="center" className="filter-form-header">
      <Grid>
        <div className="page-search-box">
          <i className="fas fa-search" />
          <InputBase
            placeholder="Search"
            value={searchText && searchText.toString()}
            onChange={(event) => {
              setSearchText(event.target.value);
              send({
                destructive: true,
                key: 'searchString',
                type: 'addQueryStringValue',
                value: event.target.value,
              });
            }}
            readOnly={state.hasTag('loading')}
          />
        </div>
      </Grid>
      <Grid>
        <Button
          className="btn-add ml-10"
          component="label"
          onClick={showMemberAssignModal}
        >
          <span className="fas fa-plus-circle" />
          <span>Assign New</span>
        </Button>
      </Grid>
    </Grid>
  );
};

MemberHeader.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
};
