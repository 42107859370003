import { UPDATE_PRODUCT_BRAND_LIST_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const getProductBrandList = async () => {
  try {
    const response = await serviceInterceptor.get('/brands');
    if (!response?.data?.success) {
      throw UPDATE_PRODUCT_BRAND_LIST_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPDATE_PRODUCT_BRAND_LIST_ERROR;
  }
};
