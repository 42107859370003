import { Grid, Button, Tooltip } from '@material-ui/core';
import { FC, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthorizationService from '../../../authorization/AuthorizationService';
import DefaultUserImg from '../../../images/profile-placeholder.jpg';

const formatContactNumber = (contactNumber: string) => {
  return contactNumber.replace(/(\d{3})(\d{3})(\d*)/, '($1) $2-$3');
};

export const MemberListRow: FC<{
  isBRAdmin: boolean;
  member: {
    avatar: null | string;
    id: number;
    name: null | string;
    city: string;
    contactfirstname?: string | null;
    contactlastname?: string | null;
    phone?: string | null;
    state?: string | null;
    childCount: number;
    street: string | null;
    zip: string | null;
    mobile: string | null;
  };
  pageNumber : number;
  pageSize : number;
}> = ({ isBRAdmin, member, pageNumber, pageSize }) => {
  const {
    id,
    name,
    childCount,
    city,
    state,
    contactfirstname,
    contactlastname,
    street,
    zip,
    mobile,
    phone,
    avatar,
  } = member ?? {};
  const [nav, setNav] = useState('none');

  const handleViewMember = () => {
    setNav('view');
  }
  const location:any = useLocation()

  if (nav === 'view') {
    return (
      <Navigate to={`/member/view/${member.id}/`} state={{ detail: member,isFromMemberTab: true,
        pageNumber : pageNumber,
        pageSize :pageSize,
        path : '/members'  }} />
    );
  }
  if (nav === 'edit') {
    return (
      <Navigate
        to={`/members/edit/${id}/`}
        state={{
          detail: member,
          navigateFrom: 'LIST',
          pageType: 'EDIT',
          isFromMemberTab: true,
          pageNumber : pageNumber,
          pageSize :pageSize,
          path : '/members' 
        }}
      />
    );
  }
  return (
    <Grid
      style={{ cursor: 'pointer' }}
      container
      spacing={0}
      className="tr"
    >
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={!isBRAdmin ? 3 : 2}
        container
        direction="row"
        alignItems="center"
        onClick={() => handleViewMember()}
      >
        <div className="table-member-details">
          <div className="member-img">
            <img src={avatar !== null ? `/${avatar}` : DefaultUserImg} />
          </div>
          <div className="member-profile-details">
            {name} {childCount > 0 && <i className="fa fa-users" />}
          </div>
        </div>
      </Grid>
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={3}
        container
        direction="row"
        alignItems="center"
        onClick={() => handleViewMember()}
      >
        {`${street}, ${city}, ${state}, ${zip}`}
      </Grid>
      <Grid
        className="td xs-left-align"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
        onClick={() => handleViewMember()}
      >
        {[contactfirstname, contactlastname].filter((name) => typeof name === 'string').join(' ')}
      </Grid>
      <Grid
        className="td xs-left-align"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
      >
        {phone && phone !== 'null' ?
          <a className="phone-link" href={`tel:${phone}`}>{formatContactNumber(phone)}</a> : "-"}
      </Grid>
      <Grid
        className="td xs-left-align"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
      >
        {mobile && mobile !== 'null' ?
          <a className="phone-link" href={`tel:${mobile}`}>{formatContactNumber(mobile)}</a> : "-"}
      </Grid>
      <Grid
        className="td action-col"
        item
        xs={12}
        lg={1}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* <Tooltip title="User">
          <Button
            className="btn-circle btn-secondary"
            variant="contained"
          >
            <i className="fas fa-user"></i>
          </Button>
        </Tooltip>

        {
          AuthorizationService.isAuthorizedHTML("chatmembericon") ?
            <Tooltip title="Chat">
              <Button
                className="btn-circle btn-secondary"
                variant="outlined"
              >
                <i className="fas fa-comment-dots"></i>
              </Button>
            </Tooltip>
            : null
        } */}

        {AuthorizationService.isAuthorizedHTML('editmemberbutton') && (
          <Tooltip title="Edit">
            <Button
              className="btn-circle btn-secondary"
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setNav('edit');
              }}
            >
              <i className="fas fa-pen" />
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default MemberListRow;
