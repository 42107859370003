import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
    UPDATE_VENDOR_KPI_LIST,
    CLEAR_VENDOR_KPI_MODAL,
    UPDATE_VENDOR_KPI_FORM_IS_EDIT,
    UPDATE_VENDOR_KPI_FORM_ID,
    UPDATE_VENDOR_KPI_FORM_KPI,
    UPDATE_VENDOR_KPI_FORM_KPI_DATE,
    UPDATE_VENDOR_KPI_FORM_MEMBER,
    UPDATE_VENDOR_KPI_FORM_COMMENTS,
    UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE,
    UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
    UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
    UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
    SHOW_VENDOR_KPI_FORM_SPINNER,
    HIDE_VENDOR_KPI_FORM_SPINNER,
    UPDATE_VENDOR_KPI_FORM_INPUTS,
    VendorKPIEditSuccess,
    VendorKPIEditError,
} from "../../constants";
import {showModal, closeModal} from "../modal";
import {getVendorKPIList, updateVendorKPI} from "../../api/vendors";


export const showEditVendorKPIModal = (selectedKPI) => async (dispatch) => {
    const {agreementId, kpiId, kpiDate, memberId, comment, deactivatedDate} = selectedKPI;
    dispatch({
        type: CLEAR_VENDOR_KPI_MODAL,
    });
    dispatch(showModal())
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_IS_EDIT,
        isEdit: true,
    });
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_ID,
        agreementId: agreementId,
    })
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_KPI,
        kpiId: kpiId,
    })
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_KPI_DATE,
        kpiDate: kpiDate,
    })
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_MEMBER,
        memberId: memberId,
    })
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_COMMENTS,
        comments: comment,
    })
    dispatch({
        type: UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE,
        deactivatedDate: deactivatedDate,
    })
}
export const onEditVendorKPI = () => async (dispatch, getState) => {
    const {vendorKPIModal, vendor} = getState();
    const {vendorID} = vendor;
    const {agreementId, kpiId, kpiDate, memberId, comments, deactivateDate} = vendorKPIModal;
    const isValidKPI = validateSelectionType(kpiId);
    const isvalidMember = validateSelectionType(memberId);
    const isValidComments = comments && comments.trim().length > 0 ? true : false;
    const isError = !isValidKPI || !isvalidMember || !isValidComments;

    if (!isValidKPI) {
        dispatch({
            type: UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
            kpiIdError: true
        });
    }
    if (!isvalidMember) {
        dispatch({
            type: UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
            memberIdError: true
        });
    }
    if (!isValidComments) {
        dispatch({
            type: UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
            commentsError: true
        });
    }
    if (isError) {
        dispatch({
            type: HIDE_VENDOR_KPI_FORM_SPINNER,
            spinner: false
        });
    } else {
        dispatch({
            type: SHOW_VENDOR_KPI_FORM_SPINNER,
            spinner: true
        });
    }
    try {
        if (!isError) {
            const response = await updateVendorKPI({
                agreementId,
                kpiId,
                kpiDate,
                memberId,
                comments,
                deactivateDate
            });
            const {success} = response;
            success ? toast.success(VendorKPIEditSuccess) : null;
            if (success) {
                const vendorKpiList = await getVendorKPIList(vendorID);
                dispatch({
                    type: UPDATE_VENDOR_KPI_LIST,
                    vendorKpiList: vendorKpiList.data
                })
            }
            dispatch({
                type: HIDE_VENDOR_KPI_FORM_SPINNER,
                spinner: false
            });
            if (success) {
                dispatch({
                    type: UPDATE_VENDOR_KPI_FORM_INPUTS,
                    isFormUpdated: false
                });
                dispatch({
                    type: CLEAR_VENDOR_KPI_MODAL
                });
            }
            dispatch(closeModal());
        }

    } catch (error) {
        dispatch({
            type: HIDE_VENDOR_KPI_FORM_SPINNER,
            spinner: false
        });
        if (!error) {
            dispatch({
                type: UPDATE_VENDOR_KPI_FORM_INPUTS,
                isFormUpdated: false
            });
            dispatch({
                type: CLEAR_VENDOR_KPI_MODAL
            });
        }
        if (error) {
            toast.error(VendorKPIEditError)
        }
    }
}
const validateSelectionType = (selection) => {
    if (selection == "0" || selection == null || selection == "") return false;
    else {
        return true
    }
}