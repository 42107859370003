import {ASSIGN_VENDORS_API, VendorMemberAssignError} from '../../constants';

export const assignVendorSalesPersonnel = async ({
    vendorUserId,
    selectedMemberId,
    customerID,
    distributionId,
    customerAbbreviation
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${ASSIGN_VENDORS_API}1/members`, {
            method: 'POST',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                memberId: selectedMemberId,
                vendorUserId: vendorUserId,
                customerId: customerID,
                distributionId,
                customerAbbreviation
            })
        });
        if (!response.ok) throw VendorMemberAssignError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw VendorMemberAssignError;
    }
};