import { useState, useEffect, FC } from 'react';
import { MemberRebatesFilter } from './header/memberRebates/MemberRebatesFilter';
import { EarnedRebates } from './components/EarnedRebates';

export const EarnedRebatesMain: FC<{
  getMemberRebatesQuarter: () => void;
  showMemberRebateExportModal: () => void;
  onExportMemberRebatesByQuarterYear: any;
}> = (props) => {
  const { showMemberRebateExportModal, getMemberRebatesQuarter, onExportMemberRebatesByQuarterYear } = props;

  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState<string | null>(null);
  const [sortType, updateSortType] = useState<'ASC' | 'DESC'>('ASC');

  const [quarter, setQuarter] = useState<string>('');
  const [year, setYear] = useState<string>('');

  const setSortKey = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  useEffect(() => {
    getMemberRebatesQuarter();
  }, []);

  return (
    <div>
      <MemberRebatesFilter
        showMemberRebateModal={function (): void {
          throw new Error('Function not implemented.');
        }} rebates={{
          quarterSpinner: false
        }} memberRebates={{
          isMemberRebateDraft: false,
          memberQuarterList: [],
          memberActiveQuarter: undefined
        }} handleMemberRebateQuarter={function (member: any): void {
          throw new Error('Function not implemented.');
        }} onPublishMemberRebates={function (): void {
          throw new Error('Function not implemented.');
        }} handleMemberEditRebates={function (): void {
          throw new Error('Function not implemented.');
        }}
        {...props}
        searchText={searchText}
        setSearchText={setSearchText}
        showMemberRebateExportModal={showMemberRebateExportModal}
        setQuarterAndYear={(item: any) => {
          setQuarter(item?.quarter ?? '');
          setYear(item?.year ?? '');
        }}
      />
      <EarnedRebates
        searchText={searchText}
        sortKey={sortKey}
        setSortKey={setSortKey}
        sortType={sortType}
        quarter={quarter}
        year={year}
        onExportMemberRebatesByQuarterYear={onExportMemberRebatesByQuarterYear}
      />
    </div>
  );
};

export default EarnedRebatesMain;
