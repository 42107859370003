import React, { useEffect, useState } from 'react';
import { DocumentTitle } from './components/header/DocumentTitle';
import { DocumentsFilters } from './components/header/DocumentsFilters';
import { DocumentCreateModal } from './components/modal/DocumentCreateModal';
import { DocumentEditModal } from './components/modal/DocumentEditModal';
import { DocumentDetailsModal } from './components/modal/DocumentDetailsModal';
import { DocumentsGrid } from './section/DocumentsGrid';
import { DocumentsList } from './section/DocumentsList';
import { GRID_VIEW } from '../../constants';
import { Loader, WarningPopup } from '../../utils';
import './documents.scss';
import {CustomModal} from "../../components/CustomModal/index";
import {DocumentCreateModalContent} from './components/modal/DocumentCreateModalContent';
import {DocumentEditModalContent} from './components/modal/DocumentEditModalContent';

export const DocumentMain = (props) => {
  const {
    documents,
    modal,
    clearDocument,
    getDocumentsList,
    getDocumentsCategory,
    onDeleteDocument,
    handleCloseDocumentDeleteModal,
    updateDocumentDelete,
  } = props;

  const { showModal } = modal;
  const {
    viewType,
    modalSpinner,
    loading,
    isDeleteDocument,
    isEditDocument,
    isDetailModal,
    fileExtensionError,
    categoryError,
    selectedFileName,
    isAllMembers,
    selectedMembers,
    documentEditError,
    documentDescError,
    spinner
  } = documents;
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    getDocumentsList();
    getDocumentsCategory();
    return () => {
      clearDocument();
    };
  }, []);

  const [deleteUser, setDeleteUser] = useState(null);

  const handleDeleteDocument = (item) => {
    const name = item && item.name;
    setDeleteUser(name);
    updateDocumentDelete(item.id);
  };

  const closeAddDocumentModal = () => {
    props.closeAddDocumentModal();
  }

  const handlePublish = () => {
    props.handlePublish();
  }

  const documentCreateModalChild = <DocumentCreateModalContent {...props} />;

  const addModalErrorFlag = fileExtensionError || categoryError || documentDescError || selectedFileName === null || (!isAllMembers && selectedMembers.length === 0);

  const closeDocumentEditModal = () => {
    props.closeDocumentEditModal();
  }

  const handleUpdate = () => {
    props.handleUpdate();
  }

  const editModalErrorFlag = documentEditError;
  const documentEditModalChild = <DocumentEditModalContent {...props} />;

  return (
    <div className="document-wrapper">
      <DocumentTitle />
      <div className="container">
        <DocumentsFilters
          searchText={searchText}
          setSearchText={setSearchText}
          {...props}
        />

        <div className="document-view">
          {loading ? (
            <div className="relative-wrapper">
              <div className="loader-wrapper list-spinner">
                <div className="document-loader">
                  <Loader size={40} />
                </div>
              </div>
            </div>
          ) : viewType === GRID_VIEW ? (
            <DocumentsGrid
              searchText={searchText}
              handleDeleteDocument={handleDeleteDocument}
              {...props}
            />
          ) : (
            <DocumentsList
              searchText={searchText}
              handleDeleteDocument={handleDeleteDocument}
              {...props}
            />
          )}
        </div>
        
        {showModal && <CustomModal
          showModal={showModal}
          closeModal={closeAddDocumentModal}
          modalTitle={"Add Document"}
          children={documentCreateModalChild}
          isCancelButton={true}
          isSubmitButton={true}
          isSubmitText={"Publish"}
          onSubmit={handlePublish}
          isButtonDisabled={addModalErrorFlag}
          isLoading={spinner}
        />}

        {isEditDocument && <CustomModal
          showModal={isEditDocument}
          closeModal={closeDocumentEditModal}
          modalTitle={"Edit Document"}
          children={documentEditModalChild}
          isCancelButton={true}
          isSubmitButton={true}
          isSubmitText={"Update"}
          onSubmit={handleUpdate}
          isButtonDisabled={editModalErrorFlag}
          isLoading={modalSpinner}
        />}

        {isDetailModal && <DocumentDetailsModal {...props} />}

        <WarningPopup
          open={isDeleteDocument}
          type={'delete'}
          name={deleteUser}
          handleProceed={onDeleteDocument}
          handleClose={handleCloseDocumentDeleteModal}
          spinner={modalSpinner}
        />
      </div>
    </div>
  );
};
