// common
export const ITEMS_GRID_VIEW = "GRID";
export const ITEMS_LIST_VIEW = "LIST";
//Vendors
export const GET_VENDOR_ID = 'GET_VENDOR_ID';
export const UPDATE_VENDOR_DC_LIST = 'UPDATE_VENDOR_DC_LIST';
export const UPDATE_VENDOR_DC_LIST_SPINNER = 'UPDATE_VENDOR_DC_LIST_SPINNER';
export const UPDATE_VENDOR_DC_ACTION_SPINNER = 'UPDATE_VENDOR_DC_ACTION_SPINNER';
export const UPDATE_VENDOR_SALES_LIST = 'UPDATE_VENDOR_SALES_LIST';
export const UPDATE_VENDOR_USERS_LIST = 'UPDATE_VENDOR_USERS_LIST';
export const UPDATE_VENDOR_MEMBERS_LIST = 'UPDATE_VENDOR_MEMBERS_LIST';
export const UPDATE_VENDOR_LINKS_LIST = 'UPDATE_VENDOR_LINKS_LIST';
export const UPDATE_SELECTED_VENDOR_USER = 'UPDATE_SELECTED_VENDOR_USER';
export const UPDATE_DELETE_VENDOR_FLAG = 'UPDATE_DELETE_VENDOR_FLAG';
export const UPDATE_SELECTED_VENDOR_MEMBER = 'UPDATE_SELECTED_VENDOR_MEMBER';
export const UPDATE_SELECTED_VENDOR_LINK = 'UPDATE_SELECTED_VENDOR_LINK';
export const UPDATE_VENDOR_KPI_LIST = 'UPDATE_VENDOR_KPI_LIST';
export const UPDATE_VENDOR_KPI_TYPES_LIST = 'UPDATE_VENDOR_KPI_TYPES_LIST';
export const UPDATE_SELECTED_VENDOR_KPI = 'UPDATE_SELECTED_VENDOR_KPI';
export const UPDATE_SELECTED_VENDOR_DC = 'UPDATE_SELECTED_VENDOR_DC';
export const CLEAR_VENDOR_DC_LIST = 'CLEAR_VENDOR_DC_LIST';
export const CLEAR_VENDOR_USERS_LIST = 'CLEAR_VENDOR_USERS_LIST';
export const UPDATE_VENDOR_USERS_SPINNER = 'UPDATE_VENDOR_USERS_SPINNER';
export const CLEAR_VENDOR_MEMBERS_LIST = 'CLEAR_VENDOR_MEMBERS_LIST';
export const UPDATE_VENDOR_MEMBERS_SPINNER = 'UPDATE_VENDOR_MEMBERS_SPINNER';
export const CLEAR_VENDOR_LINKS_LIST = 'CLEAR_VENDOR_LINKS_LIST';
export const UPDATE_VENDOR_LINKS_SPINNER = 'UPDATE_VENDOR_LINKS_SPINNER';
export const CLEAR_VENDOR_KPI_LIST = 'CLEAR_VENDOR_KPI_LIST';
export const CLEAR_VENDOR_SALES_LIST = 'CLEAR_VENDOR_SALES_LIST';
export const TOGGLE_MODAL = 'TOGGLE_MODAL';
export const UPDATE_VENDOR_LINKS_TYPE_LIST = 'UPDATE_VENDOR_LINKS_TYPE_LIST';
export const UPDATE_VENDOR_LINK_TYPE = 'UPDATE_VENDOR_LINK_TYPE';
export const UPDATE_VENDOR_LINK_TEXT = 'UPDATE_VENDOR_LINK_TEXT';
export const UPDATE_VENDOR_LINK_URL = 'UPDATE_VENDOR_LINK_URL';
export const UPDATE_VENDOR_LINK_TYPE_ERROR = 'UPDATE_VENDOR_LINK_TYPE_ERROR';
export const UPDATE_VENDOR_LINK_TEXT_ERROR = 'UPDATE_VENDOR_LINK_TEXT_ERROR';
export const UPDATE_VENDOR_LINK_URL_ERROR = 'UPDATE_VENDOR_LINK_URL_ERROR';
export const CLEAR_VENDOR_MODAL = 'CLEAR_VENDOR_MODAL';
export const SHOW_VENDOR_FORM_SPINNER = 'SHOW_VENDOR_FORM_SPINNER';
export const HIDE_VENDOR_FORM_SPINNER = 'HIDE_VENDOR_FORM_SPINNER';
export const UPDATE_VENDOR_LINK_IS_EDIT = 'UPDATE_VENDOR_LINK_IS_EDIT';
export const UPDATE_VENDOR_LINK_ID = 'UPDATE_VENDOR_LINK_ID';
export const UPDATE_FORM_INPUTS = 'UPDATE_FORM_INPUTS';
// vendor DC
export const CLEAR_DC_MODAL = 'CLEAR_DC_MODAL';
export const UPDATE_DC_IS_EDIT = 'UPDATE_DC_IS_EDIT';
export const UPDATE_VENDOR_DC_NAME_ERROR = 'UPDATE_VENDOR_DC_NAME_ERROR';
export const UPDATE_VENDOR_DC_NAME = 'UPDATE_VENDOR_DC_NAME';
export const UPDATE_DC_FORM_INPUTS = 'UPDATE_DC_FORM_INPUTS';
export const SHOW_VENDOR_DC_FORM_SPINNER = 'SHOW_VENDOR_DC_FORM_SPINNER';
export const HIDE_VENDOR_DC_FORM_SPINNER = 'HIDE_VENDOR_DC_FORM_SPINNER';
export const UPDATE_VENDOR_DC_STREET_ERROR = 'UPDATE_VENDOR_DC_STREET_ERROR';
export const UPDATE_VENDOR_DC_STREET = 'UPDATE_VENDOR_DC_STREET';
export const UPDATE_VENDOR_DC_CITY_ERROR = 'UPDATE_VENDOR_DC_CITY_ERROR';
export const UPDATE_VENDOR_DC_CITY = 'UPDATE_VENDOR_DC_CITY';
export const UPDATE_VENDOR_DC_STATE_ERROR = 'UPDATE_VENDOR_DC_STATE_ERROR';
export const UPDATE_VENDOR_DC_STATE = 'UPDATE_VENDOR_DC_STATE';
export const UPDATE_VENDOR_DC_ZIP_ERROR = 'UPDATE_VENDOR_DC_ZIP_ERROR';
export const UPDATE_VENDOR_DC_ZIP = 'UPDATE_VENDOR_DC_ZIP';
export const UPDATE_VENDOR_DC_CODE_ERROR = 'UPDATE_VENDOR_DC_CODE_ERROR';
export const UPDATE_VENDOR_DC_CODE = 'UPDATE_VENDOR_DC_CODE';
export const UPDATE_VENDOR_DC_ID = 'UPDATE_VENDOR_DC_ID';
// vendor KPI
export const CLEAR_VENDOR_KPI_MODAL = 'CLEAR_VENDOR_KPI_MODAL';
export const SHOW_VENDOR_KPI_FORM_SPINNER = 'SHOW_VENDOR_KPI_FORM_SPINNER';
export const HIDE_VENDOR_KPI_FORM_SPINNER = 'HIDE_VENDOR_KPI_FORM_SPINNER';
export const UPDATE_VENDOR_KPI_FORM_IS_EDIT = 'UPDATE_VENDOR_KPI_FORM_IS_EDIT';
export const UPDATE_VENDOR_KPI_FORM_INPUTS = 'UPDATE_VENDOR_KPI_FORM_INPUTS';
export const UPDATE_VENDOR_KPI_FORM_ID = 'UPDATE_VENDOR_KPI_FORM_ID';
export const UPDATE_VENDOR_KPI_FORM_KPI = 'UPDATE_VENDOR_KPI_FORM_KPI';
export const UPDATE_VENDOR_KPI_FORM_KPI_DATE = 'UPDATE_VENDOR_KPI_FORM_KPI_DATE';
export const UPDATE_VENDOR_KPI_FORM_MEMBER = 'UPDATE_VENDOR_KPI_FORM_MEMBER';
export const UPDATE_VENDOR_KPI_FORM_COMMENTS = 'UPDATE_VENDOR_KPI_FORM_COMMENTS';
export const UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE = 'UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE';
export const UPDATE_VENDOR_KPI_FORM_KPI_ERROR = 'UPDATE_VENDOR_KPI_FORM_KPI_ERROR';
export const UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR = 'UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR';
export const UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR = 'UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR';
export const UPDATE_MEMBER_KPI_DROPSIZE_DATA = 'UPDATE_MEMBER_KPI_DROPSIZE_DATA';
export const UPDATE_MEMBER_KPI_TERMS_DATA = 'UPDATE_MEMBER_KPI_TERMS_DATA';

// Vendor user assign
export const CLEAR_VENDOR_USER_ASSIGN_MODAL = 'CLEAR_VENDOR_USER_ASSIGN_MODAL';
export const UPDATE_VENDOR_USER_ASSIGN_SELECT_DC = 'UPDATE_VENDOR_USER_ASSIOGN_SELECT_DC';
export const UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS = 'UPDATE_VENDOR_USER_ASSIGN_SELECT_USERS';
export const UPDATE_VENDOR_USER_ASSIGN_DC_ERROR = 'UPDATE_VENDOR_USER_ASSIGN_DC_ERROR';
export const UPDATE_VENDOR_USER_SELECT_USER_ERROR = 'UPDATE_VENDOR_USER_SELECT_USER_ERROR';
export const SHOW_VENDOR_USER_ASSIGN_SPINNER = 'SHOW_VENDOR_USER_ASSIGN_SPINNER';
export const HIDE_VENDOR_USER_ASSIGN_SPINNER = 'HIDE_VENDOR_USER_ASSIGN_SPINNER';
export const UPDATE_VENDOR_USER_ROLE = 'UPDATE_VENDOR_USER_ROLE';
// Vendor user create
export const CLEAR_VENDOR_USER_CREATE_MODAL = 'CLEAR_VENDOR_USER_CREATE_MODAL';
export const SHOW_VENDOR_USER_CREATE_SPINNER = 'SHOW_VENDOR_USER_CREATE_SPINNER';
export const HIDE_VENDOR_USER_CREATE_SPINNER = 'HIDE_VENDOR_USER_CREATE_SPINNER';
export const UPDATE_VENDOR_USER_IS_EDIT = 'UPDATE_VENDOR_USER_IS_EDIT';
export const UPDATE_VENDOR_USER_USERID = 'UPDATE_VENDOR_USER_USERID';
export const UPDATE_VENDOR_USER_CREATE_FNAME = 'UPDATE_VENDOR_USER_CREATE_FNAME';
export const UPDATE_VENDOR_USER_CREATE_LNAME = 'UPDATE_VENDOR_USER_CREATE_LNAME';
export const UPDATE_VENDOR_USER_CREATE_EMAIL = 'UPDATE_VENDOR_USER_CREATE_EMAIL';
export const UPDATE_VENDOR_USER_CREATE_PHONE = 'UPDATE_VENDOR_USER_CREATE_PHONE';
export const UPDATE_VENDOR_USER_CREATE_ROLE = 'UPDATE_VENDOR_USER_CREATE_ROLE';
export const UPDATE_VENDOR_USER_CREATE_JOBTITLE = 'UPDATE_VENDOR_USER_CREATE_JOBTITLE';
export const UPDATE_VENDOR_USER_CREATE_DC = 'UPDATE_VENDOR_USER_CREATE_DC';
export const UPDATE_VENDOR_USER_CREATE_FNAME_ERROR = 'UPDATE_VENDOR_USER_CREATE_FNAME_ERROR';
export const UPDATE_VENDOR_USER_CREATE_LNAME_ERROR = 'UPDATE_VENDOR_USER_CREATE_LNAME_ERROR';
export const UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR = 'UPDATE_VENDOR_USER_CREATE_EMAIL_ERROR';
export const UPDATE_VENDOR_USER_CREATE_PHONE_ERROR = 'UPDATE_VENDOR_USER_CREATE_PHONE_ERROR';
export const UPDATE_VENDOR_USER_CREATE_ROLE_ERROR = 'UPDATE_VENDOR_USER_CREATE_ROLE_ERROR';
export const UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR = 'UPDATE_VENDOR_USER_CREATE_JOBTITLE_ERROR';
export const UPDATE_VENDOR_USER_CREATE_DC_ERROR = 'UPDATE_VENDOR_USER_CREATE_DC_ERROR';
//Documents
export const SHOW_DOCUMENT_LOADING = 'SHOW_DOCUMENT_LOADING';
export const HIDE_DOCUMENT_LOADING = 'HIDE_DOCUMENT_LOADING';
export const SHOW_DOCUMENT_SPINNER = 'SHOW_DOCUMENT_SPINNER';
export const HIDE_DOCUMENT_SPINNER = 'HIDE_DOCUMENT_SPINNER';
export const SHOW_DOCUMENT_MODAL_SPINNER = 'SHOW_DOCUMENT_MODAL_SPINNER';
export const HIDE_DOCUMENT_MODAL_SPINNER = 'HIDE_DOCUMENT_MODAL_SPINNER';
export const UPDATE_DOCUMENTS_LIST = 'UPDATE_DOCUMENTS_LIST';
export const UPDATE_DOCUMENTS_CATEGORY = 'UPDATE_DOCUMENTS_CATEGORY';
export const UPDATE_SELECT_CATEGORY = 'UPDATE_SELECT_CATEGORY';
export const UPDATE_SELECT_MODAL_CATEGORY = 'UPDATE_SELECT_MODAL_CATEGORY';
export const UPDATE_DOCUMENT_TOGGLE = 'UPDATE_DOCUMENT_TOGGLE';
export const UPDATE_DC_MENU_LIST = 'UPDATE_DC_MENU_LIST';
export const UPDATE_MEMBERS_MENU_LIST = 'UPDATE_MEMBERS_MENU_LIST'
export const UPDATE_SELECT_MEMBERS = 'UPDATE_SELECT_MEMBERS';
export const UPDATE_SELECT_ALL_MEMBERS = 'UPDATE_SELECT_ALL_MEMBERS';
export const UPDATE_SELECT_MEMBERS_DATA = 'UPDATE_SELECT_MEMBERS_DATA';
export const CLEAR_DOCUMENT = 'CLEAR_DOCUMENT';
export const UPDATE_SELECTED_FILE_CONTENT = 'UPDATE_SELECTED_FILE_CONTENT';
export const UPDATE_SELECTED_FILE_NAME = 'UPDATE_SELECTED_FILE_NAME';
export const UPDATE_SELECTED_FILE_SIZE = 'UPDATE_DOCUMENT_SELECTED_FILE_SIZE';
export const UPDATE_SELECTED_FILE_EXTENSION = 'UPDATE_SELECTED_FILE_EXTENSION';
export const UPDATE_SELECTED_MEMBERS = 'UPDATE_SELECTED_MEMBERS';
export const UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR = 'UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR';
export const UPDATE_FILE_EXTENSION_ERROR = 'UPDATE_FILE_EXTENSION_ERROR';
export const CLEAR_DOCUMENT_MODAL = 'CLEAR_DOCUMENT_MODAL';
export const UPDATE_DOCUMENT_DC_LIST = 'UPDATE_DOCUMENT_DC_LIST';
export const UPDATE_SELECTED_DISTRIBUTION_CENTERS = 'UPDATE_SELECTED_DISTRIBUTION_CENTERS';
export const UPDATE_SELECTED_DOCUMENT = 'UPDATE_SELECTED_DOCUMENT';
export const UPDATE_DELETE_DOCUMENT = 'UPDATE_DELETE_DOCUMENT';
export const UPDATE_IS_EDIT_DOCUMENT = 'UPDATE_IS_EDIT_DOCUMENT';
export const UPDATE_SELECTED_DOCUMENT_DATA = 'UPDATE_SELECTED_DOCUMENT_DATA';
export const UPDATE_DOCUMENT_DESCRIPTION = 'UPDATE_DOCUMENT_DESCRIPTION';
export const UPDATE_DOCUMENT_INPUT_FORM = 'UPDATE_DOCUMENT_INPUT_FORM';
export const UPDATE_IS_EDIT_DOCUMENT_ERROR = 'UPDATE_IS_EDIT_DOCUMENT_ERROR';
export const UPDATE_SELECT_MEMBERS_LIST = 'UPDATE_SELECT_MEMBERS_LIST';
export const SHOW_DOWLOAD_SPINNER = 'SHOW_DOWLOAD_SPINNER';
export const HIDE_DOWLOAD_SPINNER = 'HIDE_DOWLOAD_SPINNER';
export const UPDATE_IS_DETAIL_MODAL = 'UPDATE_IS_DETAIL_MODAL';
export const UPDATE_DOWNLOAD_ICON_VISIBILITY = 'UPDATE_DOWNLOAD_ICON_VISIBILITY';
export const UPDATE_DOCUMENT_DESC_ERROR = 'UPDATE_DOCUMENT_DESC_ERROR';
//Vendor member assign
export const UPDATE_VENDOR_MEMBER_ASSIGN_MODAL = 'UPDATE_VENDOR_MEMBER_ASSIGN_MODAL';
export const UPDATE_VENDOR_MEMBER_EDIT_MODAL = 'UPDATE_VENDOR_MEMBER_EDIT_MODAL';
export const UPDATE_VENDOR_MEMBER_EDIT_DATA = 'UPDATE_VENDOR_MEMBER_EDIT_DATA';
export const UPDATE_VENDOR_MEMBER_SELECT_USER = 'UPDATE_VENDOR_MEMBER_SELECT_USER';
export const CLEAR_VENDOR_MEMBER_ASSIGN_MODAL = 'CLEAR_VENDOR_MEMBER_ASSIGN_MODAL';
export const SHOW_VENDOR_MEMBER_ASSIGN_SPINNER = 'SHOW_VENDOR_MEMBER_ASSIGN_SPINNER';
export const HIDE_VENDOR_MEMBER_ASSIGN_SPINNER = 'HIDE_VENDOR_MEMBER_ASSIGN_SPINNER';
export const UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR = 'UPDATE_VENDOR_MEMBER_SELECT_USER_ERROR';
export const SHOW_VENDOR_MEMBER_MODAL_LOADING = 'SHOW_VENDOR_MEMBER_MODAL_LOADING';
export const HIDE_VENDOR_MEMBER_MODAL_LOADING = 'HIDE_VENDOR_MEMBER_MODAL_LOADING';
export const UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM = 'UPDATE_VENDOR_MEMBER_ASSIGN_EDIT_FORM';
export const UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER = 'UPDATE_VENDOR_MEMBER_ACTIVE_DISTRIBUTION_CENTER';
export const UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION = 'UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION';
export const UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR = 'UPDATE_VENDOR_MEMBER_CUSTOMER_ABBREVIATION_ERROR';

// members
export const UPDATE_MEMBERS_LIST_SPINNER = 'UPDATE_MEMBERS_LIST_SPINNER';
export const UPDATE_MEMBERS_MODAL_SPINNER = 'UPDATE_MEMBERS_MODAL_SPINNER';
export const UPDATE_MEMBERS_LIST = 'UPDATE_MEMBERS_LIST';
export const UPDATE_MEMBER_VIEW = 'UPDATE_MEMBER_VIEW';
export const CLEAR_MEMBER_DETAIL = 'CLEAR_MEMBER_DETAIL';
export const UPDATE_MEMBER_DETAIL = 'UPDATE_MEMBER_DETAIL';
export const UPDATE_MEMBER_USER_LIST = 'UPDATE_MEMBER_USER_LIST';
export const UPDATE_MEMBER_USER_DELETE = 'UPDATE_MEMBER_USER_DELETE';
export const UPDATE_USER_MEMBER_DELETE = 'UPDATE_USER_MEMBER_DELETE';
export const UPDATE_SELECTED_MEMBER_USER = 'UPDATE_SELECTED_MEMBER_USER';
export const UPDATE_MEMBER_USER_SPINNER = 'UPDATE_MEMBER_USER_SPINNER';
export const UPDATE_MEMBER_VENDOR_LIST = 'UPDATE_MEMBER_VENDOR_LIST';
export const UPDATE_MEMBER_VENDOR_SPINNER = 'UPDATE_MEMBER_VENDOR_SPINNER';
export const UPDATE_MEMBER_LOCATION_LIST = 'UPDATE_MEMBER_LOCATION_LIST';
export const UPDATE_MEMBER_LOCATION_SPINNER = 'UPDATE_MEMBER_LOCATION_SPINNER';
export const UPDATE_MEMBER_SELECTED_LOCATION = 'UPDATE_MEMBER_SELECTED_LOCATION';
export const UPDATE_MEMBER_LOCATION_DELETE_FLAG = 'UPDATE_MEMBER_LOCATION_DELETE_FLAG';
export const UPDATE_MEMBER_LOCATION_DELETE_SPINNER = 'UPDATE_MEMBER_LOCATION_DELETE_SPINNER';
export const UPDATE_MEMBER_DELETE_SPINNER = 'UPDATE_MEMBER_DELETE_SPINNER';
export const UPDATE_MEMBER_RIDERECT_ON_DELETE = 'UPDATE_MEMBER_RIDERECT_ON_DELETE';
export const UPDATE_SELECTED_MEMBER_KPI = 'UPDATE_SELECTED_MEMBER_KPI';
export const SHOW_KPI_SELECT_LOADER = 'SHOW_KPI_SELECT_LOADER';
export const HIDE_KPI_SELECT_LOADER = 'HIDE_KPI_SELECT_LOADER';
export const CLEAR_CHART_MODAL = 'CLEAR_CHART_MODAL';
export const SHOW_KPI_GRAPH_SPINNER = 'SHOW_KPI_GRAPH_SPINNER';
export const HIDE_KPI_GRAPH_SPINNER = 'HIDE_KPI_GRAPH_SPINNER';
export const UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR = 'UPDATE_VENDOR_MEMBER_CUSTOMER_ID_ERROR';
export const UPDATE_VENDOR_MEMBER_CUSTOMER_ID = 'UPDATE_VENDOR_MEMBER_CUSTOMER_ID';
export const UPDATE_VENDOR_MEMBER_SELECTED_ID = 'UPDATE_VENDOR_MEMBER_SELECTED_ID';
export const UPDATE_KPI_QUARTER_LIST = 'UPDATE_KPI_QUARTER_LIST';
export const UPDATE_KPI_SELECTED_QUARTER = 'UPDATE_KPI_SELECTED_QUARTER';
export const GET_MEMBER_LOGIN_ATTEMPT = 'GET_MEMBER_LOGIN_ATTEMPT';
export const VIEW_AS_MEMBER = "VIEW_AS_MEMBER";
export const MEMBER_VIEW_TRUE = "MEMBER_VIEW_TRUE";
export const MEMBER_VIEW_STATE = 'MEMBER_VIEW_STATE';
export const CLEAR_MEMBER_VIEW = 'CLEAR_MEMBER_VIEW';
export const GET_ASSIGNED_MEMBER_USERS = 'GET_ASSIGNED_MEMBER_USERS';

// member add
export const CLEAR_MEMBER_FORM = 'CLEAR_MEMBER_FORM';
export const UPDATE_MEMBER_FORM_SPINNER = 'UPDATE_MEMBER_FORM_SPINNER';
export const UPDATE_MEMBER_FORM_IS_EDIT = 'UPDATE_MEMBER_FORM_IS_EDIT';
export const UPDATE_MEMBER_FORM_FORM_UPDATED = 'UPDATE_MEMBER_FORM_FORM_UPDATED';
export const UPDATE_MEMBER_FORM_ID = 'UPDATE_MEMBER_FORM_ID';
export const UPDATE_MEMBER_FORM_PARENTID = 'UPDATE_MEMBER_FORM_PARENTID';
export const UPDATE_MEMBER_FORM_NAME = 'UPDATE_MEMBER_FORM_NAME';
export const UPDATE_MEMBER_FORM_STREET = 'UPDATE_MEMBER_FORM_STREET';
export const UPDATE_MEMBER_FORM_CITY = 'UPDATE_MEMBER_FORM_CITY';
export const UPDATE_MEMBER_FORM_STATE = 'UPDATE_MEMBER_FORM_STATE';
export const UPDATE_MEMBER_FORM_ZIP = 'UPDATE_MEMBER_FORM_ZIP';
export const UPDATE_MEMBER_FORM_PHONE = 'UPDATE_MEMBER_FORM_PHONE';
export const UPDATE_MEMBER_FORM_MOBILE = 'UPDATE_MEMBER_FORM_MOBILE';
export const UPDATE_MEMBER_FORM_JOININGDATE = 'UPDATE_MEMBER_FORM_JOININGDATE';
export const UPDATE_MEMBER_FORM_IMAGE_FILE = 'UPDATE_MEMBER_FORM_IMAGE_FILE';
export const UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME = 'UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME';
export const UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH = 'UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH';
export const UPDATE_MEMBER_FORM_CANHANDDELIVER = 'UPDATE_MEMBER_FORM_CANHANDDELIVER';
export const UPDATE_MEMBER_FORM_REDIRECT = 'UPDATE_MEMBER_FORM_REDIRECT';
export const UPDATE_MEMBER_NAV_FROM_PARENT = 'UPDATE_MEMBER_NAV_FROM_PARENT';
export const UPDATE_MEMBER_NAV_FROM_VIEW = 'UPDATE_MEMBER_NAV_FROM_VIEW';
export const UPDATE_MEMBER_NAV_FROM_TAB = 'UPDATE_MEMBER_NAV_FROM_TAB';
export const UPDATE_MEMBER_FORM_NAME_ERROR = 'UPDATE_MEMBER_FORM_NAME_ERROR';
export const UPDATE_MEMBER_FORM_STREET_ERROR = 'UPDATE_MEMBER_FORM_STREET_ERROR';
export const UPDATE_MEMBER_FORM_CITY_ERROR = 'UPDATE_MEMBER_FORM_CITY_ERROR';
export const UPDATE_MEMBER_FORM_STATE_ERROR = 'UPDATE_MEMBER_FORM_STATE_ERROR';
export const UPDATE_MEMBER_FORM_ZIP_ERROR = 'UPDATE_MEMBER_FORM_ZIP_ERROR';
export const UPDATE_MEMBER_FORM_PHONE_ERROR = 'UPDATE_MEMBER_FORM_PHONE_ERROR';
export const UPDATE_MEMBER_FORM_MOBILE_ERROR = 'UPDATE_MEMBER_FORM_MOBILE_ERROR';
export const UPDATE_MEMBER_FORM_JOININGDATE_ERROR = 'UPDATE_MEMBER_FORM_JOININGDATE_ERROR';
// memeber user add
export const CLEAR_MEMBER_USER_FORM = 'CLEAR_MEMBER_USER_FORM';
export const UPDATE_MEMBER_USER_FORM_SPINNER = 'UPDATE_MEMBER_USER_FORM_SPINNER';
export const UPDATE_MEMBER_USER_ID = 'UPDATE_MEMBER_USER_ID';
export const UPDATE_MEMBER_USER_IS_EDIT = 'UPDATE_MEMBER_USER_IS_EDIT';
export const UPDATE_MEMBER_USER_FIRST_NAME = 'UPDATE_MEMBER_USER_FIRST_NAME';
export const UPDATE_MEMBER_USER_LAST_NAME = 'UPDATE_MEMBER_USER_LAST_NAME';
export const UPDATE_MEMBER_USER_EMAIL = 'UPDATE_MEMBER_USER_EMAIL';
export const UPDATE_MEMBER_USER_ROLE = 'UPDATE_MEMBER_USER_ROLE';
export const UPDATE_MEMBER_USER_FIRST_NAME_ERROR = 'UPDATE_MEMBER_USER_FIRST_NAME_ERROR';
export const UPDATE_MEMBER_USER_LAST_NAME_ERROR = 'UPDATE_MEMBER_USER_LAST_NAME_ERROR';
export const UPDATE_MEMBER_USER_EMAIL_ERROR = 'UPDATE_MEMBER_USER_EMAIL_ERROR';
export const UPDATE_MEMBER_USER_ROLE_ERROR = 'UPDATE_MEMBER_USER_ROLE_ERROR';
// login
export const CLEAR_LOGIN = 'CLEAR_LOGIN';
export const CLEAR_LOGIN_ERROR = 'CLEAR_LOGIN_ERROR';
export const UPDATE_LOGIN_SPINNER = 'UPDATE_LOGIN_SPINNER';
export const UPDATE_LOGIN_EMAIL = 'UPDATE_LOGIN_EMAIL';
export const UPDATE_LOGIN_PASSWORD = 'UPDATE_LOGIN_PASSWORD';
export const UPDATE_LOGIN_SHOW_PASSWORD = 'UPDATE_LOGIN_SHOW_PASSWORD';
export const UPDATE_LOGIN_EMAIL_ERROR = 'UPDATE_LOGIN_EMAIL_ERROR';
export const UPDATE_LOGIN_PASSWORD_ERROR = 'UPDATE_LOGIN_PASSWORD_ERROR';
export const UPDATE_LOGIN_SERVER_ERROR = 'UPDATE_LOGIN_SERVER_ERROR';
export const UPDATE_LOGIN_REDIRECT = 'UPDATE_LOGIN_REDIRECT';

export const UPDATE_KPI_OVERVIEW_YEARS = 'UPDATE_KPI_OVERVIEW_YEARS';
export const UPDATE_SELECTED_KPI_YEAR = 'UPDATE_SELECTED_KPI_YEAR';
export const UPDATE_SELECTED_KPI_YEAR_ERROR = 'UPDATE_SELECTED_KPI_YEAR_ERROR';
export const UPDATE_SELECTED_KPI_QUARTER = 'UPDATE_SELECTED_KPI_QUARTER';
export const UPDATE_SELECTED_KPI_QUARTER_ERROR = 'UPDATE_SELECTED_KPI_QUARTER_ERROR';
export const UPDATE_VENDOR_KPI_MEMBER_DATA = 'UPDATE_VENDOR_KPI_MEMBER_DATA';

// Manage Ads
export const UPDATE_MANAGEADS_SPINNER = 'UPDATE_MANAGEADS_SPINNER';
export const SHOW_ADD_MANAGEADS_MODAL = 'SHOW_ADD_MANAGEADS_MODAL';
export const HIDE_ADD_MANAGEADS_MODAL = 'HIDE_ADD_MANAGEADS_MODAL';
export const SHOW_ADSTYPE_MODAL = 'SHOW_ADSTYPE_MODAL';
export const HIDE_ADSTYPE_MODAL = 'HIDE_ADSTYPE_MODAL';
export const UPDATE_ADS_LIST = 'UPDATE_ADS_LIST';
export const SHOW_VIEW_AD_MODAL = 'SHOW_VIEW_AD_MODAL';
export const HIDE_VIEW_AD_MODAL = 'HIDE_VIEW_AD_MODAL';
export const UPDATE_SELECTED_VIEW_AD = 'UPDATE_SELECTED_VIEW_AD';
// Ads type
export const CLEAR_ADSTYPE_FORM = 'CLEAR_ADSTYPE_FORM';
export const UPDATE_ADSTYPE_FORM_SPINNER = 'UPDATE_ADSTYPE_FORM_SPINNER';
export const UPDATE_ADSTYPE_IS_UPDATED = 'UPDATE_ADSTYPE_IS_UPDATED';
export const UPDATE_ADSTYPE_NAME = 'UPDATE_ADSTYPE_NAME';
export const UPDATE_ADSTYPE_DESC = 'UPDATE_ADSTYPE_DESC';
export const UPDATE_ADSTYPE_NAME_ERROR = 'UPDATE_ADSTYPE_NAME_ERROR';
export const UPDATE_ADSTYPE_DESC_ERROR = 'UPDATE_ADSTYPE_DESC_ERROR';
export const UPDATE_ADSTYPE_LIST = 'UPDATE_ADSTYPE_LIST';
// Add ads
export const CLEAR_ADS_FORM = 'CLEAR_ADS_FORM';
export const UPDATE_ADS_FORM_SPINNER = 'UPDATE_ADS_FORM_SPINNER';
export const UPDATE_ADS_FORM_UPDATED = 'UPDATE_ADS_FORM_UPDATED';
export const UPDATE_ADS_FORM_IS_EDIT = 'UPDATE_ADS_FORM_IS_EDIT';
export const UPDATE_ADS_FORM_AD_URL = 'UPDATE_ADS_FORM_AD_URL';
export const UPDATE_ADS_FORM_AD_TYPE = 'UPDATE_ADS_FORM_AD_TYPE';
export const UPDATE_ADS_FORM_PRODUCT_NAME = 'UPDATE_ADS_FORM_PRODUCT_NAME';
export const UPDATE_ADS_FORM_PRODUCT_BRAND = 'UPDATE_ADS_FORM_PRODUCT_BRAND';
export const UPDATE_ADS_FORM_SPECIAL = 'UPDATE_ADS_FORM_SPECIAL';
export const UPDATE_ADS_FORM_DESC = 'UPDATE_ADS_FORM_DESC';
export const UPDATE_ADS_FORM_AD_STATUS = 'UPDATE_ADS_FORM_AD_STATUS';
export const UPDATE_ADS_FORM_AD_RATING = 'UPDATE_ADS_FORM_AD_RATING';
export const UPDATE_ADS_FORM_IMAGE_FILE = 'UPDATE_ADS_FORM_IMAGE_FILE';
export const UPDATE_ADS_FORM_UPLOADED_IMG_PATH = 'UPDATE_ADS_FORM_UPLOADED_IMG_PATH';
// Ads error
export const UPDATE_ADS_FORM_AD_URL_ERROR = 'UPDATE_ADS_FORM_AD_URL_ERROR';
export const UPDATE_ADS_FORM_AD_TYPE_ERROR = 'UPDATE_ADS_FORM_AD_TYPE_ERROR';
export const UPDATE_ADS_FORM_PRODUCT_NAME_ERROR = 'UPDATE_ADS_FORM_PRODUCT_NAME_ERROR';
export const UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR = 'UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR';
export const UPDATE_ADS_FORM_SPECIAL_ERROR = 'UPDATE_ADS_FORM_SPECIAL_ERROR';
export const UPDATE_ADS_FORM_DESC_ERROR = 'UPDATE_ADS_FORM_DESC_ERROR';
export const UPDATE_ADS_FORM_AD_RATING_ERROR = 'UPDATE_ADS_FORM_AD_RATING_ERROR';

// Products
export const UPDATE_PRODUCT_BRAND_LIST = 'UPDATE_PRODUCT_BRAND_LIST';
export const UPDATE_PRODUCT_BRAND_LIST_SPINNER = 'UPDATE_PRODUCT_BRAND_LIST_SPINNER';
export const UPDATE_PRODUCT_LIST = 'UPDATE_PRODUCT_LIST';
export const UPDATE_PRODUCT_LIST_SPINNER = 'UPDATE_PRODUCT_LIST_SPINNER';
// Change password
export const CLEAR_CHANGE_PASSWORD = 'CLEAR_CHANGE_PASSWORD';
export const UPDATE_PASSWORD_USER_SPINNER = 'UPDATE_PASSWORD_USER_SPINNER';
export const UPDATE_PASSWORD_USER_OLD_PASSWORD = 'UPDATE_PASSWORD_USER_OLD_PASSWORD';
export const UPDATE_PASSWORD_USER_NEW_PASSWORD = 'UPDATE_PASSWORD_USER_NEW_PASSWORD';
export const UPDATE_PASSWORD_USER_CONFIRM_PASSWORD = 'UPDATE_PASSWORD_USER_CONFIRM_PASSWORD';
export const UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR = 'UPDATE_PASSWORD_USER_OLD_PASSWORD_ERROR';
export const UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR = 'UPDATE_PASSWORD_USER_NEW_PASSWORD_ERROR';
export const UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR = 'UPDATE_PASSWORD_USER_CONFIRM_PASSWORD_ERROR';
export const UPDATE_PASSWORD_NEW_PWD_MESSAGE = 'UPDATE_PASSWORD_NEW_PWD_MESSAGE';
export const UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE = 'UPDATE_PASSWORD_CONFIRM_PWD_MESSAGE';
export const UPDATE_PASSWORD_SHOW_STATUS = 'UPDATE_PASSWORD_SHOW_STATUS';
export const UPDTAE_PASSOWRD_IS_REDIRECT = 'UPDTAE_PASSOWRD_IS_REDIRECT';

// Community
//channel
export const SHOW_COMMUNITY_CATEGORY_SPINNER = 'SHOW_COMMUNITY_CATEGORY_SPINNER';
export const HIDE_COMMUNITY_CATEGORY_SPINNER = 'HIDE_COMMUNITY_CATEGORY_SPINNER';
export const UPDATE_QUESTION_DETAIL = 'UPDATE_QUESTION_DETAIL';
export const UPDATE_SELECTED_TAB = 'UPDATE_SELECTED_TAB';
export const UPDATE_COMMUNITY_CATEGORY = 'UPDATE_COMMUNITY_CATEGORY';
export const UPDATE_ACTIVE_CHANNEL = 'UPDATE_ACTIVE_CHANNEL';
export const UPDATE_NEW_CHANNEL = 'UPDATE_NEW_CHANNEL';
export const UPDATE_CHANNEL_ID = 'UPDATE_CHANNEL_ID';
export const UPDATE_CHANNEL_NAME = 'UPDATE_CHANNEL_NAME';
export const UPDATE_CHANNEL_DESC = 'UPDATE_CHANNEL_DESC';
export const UPDATE_CHANNEL_FORM_INPUTS = 'UPDATE_CHANNEL_FORM_INPUTS';
export const UPDATE_CHANNEL_NAME_ERROR = 'UPDATE_CHANNEL_NAME_ERROR';
export const UPDATE_CHANNEL_DESC_ERROR = 'UPDATE_CHANNEL_DESC_ERROR';
export const CLEAR_COMMUNITY_CHANNEL_MODAL = 'CLEAR_COMMUNITY_CHANNEL_MODAL';
export const SHOW_CHANNEL_FORM_SPINNER = 'SHOW_CHANNEL_FORM_SPINNER';
export const HIDE_CHANNEL_FORM_SPINNER = 'HIDE_CHANNEL_FORM_SPINNER';
export const UPDATE_EDIT_CHANNEL = 'UPDATE_EDIT_CHANNEL';
//discussion
export const SHOW_COMMUNITY_DISCUSSION_SPINNER = 'SHOW_COMMUNITY_DISCUSSION_SPINNER';
export const HIDE_COMMUNITY_DISCUSSION_SPINNER = 'HIDE_COMMUNITY_DISCUSSION_SPINNER';
export const UPDATE_ALL_DISCUSSIONS = 'UPDATE_ALL_DISCUSSIONS';
export const UPDATE_ALL_DISCUSSIONS_ERROR = 'UPDATE_ALL_DISCUSSIONS_ERROR';
export const UPDATE_ACTIVE_DISCUSSION = 'UPDATE_ACTIVE_DISCUSSION';
export const SHOW_DISCUSSION_DETAIL_SPINNER = 'SHOW_DISCUSSION_DETAIL_SPINNER';
export const HIDE_DISCUSSION_DETAIL_SPINNER = 'HIDE_DISCUSSION_DETAIL_SPINNER';
export const UPDATE_ACTIVE_DISCUSSION_COMMENTS = 'UPDATE_ACTIVE_DISCUSSION_COMMENTS';
export const UPDATE_DISCUSSION_LOAD_MORE = 'UPDATE_DISCUSSION_LOAD_MORE';
export const SHOW_LOAD_MORE_SPINNER = 'SHOW_LOAD_MORE_SPINNER';
export const HIDE_LOAD_MORE_SPINNER = 'HIDE_LOAD_MORE_SPINNER';
export const UPDATE_DISCUSSION_TITLE_ERROR = 'UPDATE_DISCUSSION_TITLE_ERROR';
export const UPDATE_DISCUSSION_TITLE = 'UPDATE_DISCUSSION_TITLE';
export const UPDATE_DISCUSSION_INPUT_FORM = 'UPDATE_DISCUSSION_INPUT_FORM';
export const UPDATE_DISCUSSION_DESC_ERROR = 'UPDATE_DISCUSSION_DESC_ERROR';
export const UPDATE_DISCUSSION_DESC = 'UPDATE_DISCUSSION_DESC';
export const SHOW_DISCUSSION_FORM_SPINNER = 'SHOW_DISCUSSION_FORM_SPINNER';
export const HIDE_DISCUSSION_FORM_SPINNER = 'HIDE_DISCUSSION_FORM_SPINNER';
export const CLEAR_COMMUNITY_DISCUSSION_MODAL = 'CLEAR_COMMUNITY_DISCUSSION_MODAL';
export const CREATE_NEW_DISCUSSION_ERROR = 'CREATE_NEW_DISCUSSION_ERROR';
export const EDIT_DISCUSSION_ERROR = 'EDIT_DISCUSSION_ERROR';
export const HANDLE_NEW_DISCUSSION_MODAL = 'HANDLE_NEW_DISCUSSION_MODAL';
export const UPDATE_DISCUSSION_COMMENT_ERROR = 'UPDATE_DISCUSSION_COMMENT_ERROR';
export const UPDATE_DISCUSSION_COMMENT = 'UPDATE_DISCUSSION_COMMENT';
export const UPDATE_DISCUSSION_COMMENT_ID = 'UPDATE_DISCUSSION_COMMENT_ID';
export const UPDATE_DISCUSSION_COMMENT_EDIT_FLAG = 'UPDATE_DISCUSSION_COMMENT_EDIT_FLAG';
export const UPDATE_DISCUSSION_COMMENT_INPUT_FORM = 'UPDATE_DISCUSSION_COMMENT_INPUT_FORM';
export const ADD_NEW_COMMENT_ERROR = 'ADD_NEW_COMMENT_ERROR';
export const UPDATE_COMMENT_ERROR = 'UPDATE_COMMENT_ERROR';
export const SHOW_DISCUSSION_COMMENT_SPINNER = 'SHOW_DISCUSSION_COMMENT_SPINNER';
export const HIDE_DISCUSSION_COMMENT_SPINNER = 'HIDE_DISCUSSION_COMMENT_SPINNER';
export const UPDATE_DISCUSSION_LIKE = 'UPDATE_DISCUSSION_LIKE';
export const UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER = 'UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER';
export const UPDATE_TOTAL_COMMENTS_COUNT = 'UPDATE_TOTAL_COMMENTS_COUNT';
export const CLEAR_COMMUNITY_COMMENT_STATE = 'CLEAR_COMMUNITY_COMMENT_STATE';
export const UPDATE_DISCUSSION_ID = 'UPDATE_DISCUSSION_ID';
export const ADD_DISCUSSION_LIKE_ERROR = 'ADD_DISCUSSION_LIKE_ERROR';
export const ADD_DISCUSSION_DISLIKE_ERROR = 'ADD_DISCUSSION_DISLIKE_ERROR';
export const UPDATE_DISCUSSION_LIKE_SUCCESS = 'UPDATE_DISCUSSION_LIKE_SUCCESS';
export const UPDATE_DISCUSSION_DISLIKE_SUCCESS = 'UPDATE_DISCUSSION_DISLIKE_SUCCESS';
export const UPDATE_DISCUSSION_LIKE_ID = 'UPDATE_DISCUSSION_LIKE_ID';
export const SHOW_DISCUSSION_ACTION_SPINNER = 'SHOW_DISCUSSION_ACTION_SPINNER';
export const HIDE_DISCUSSION_ACTION_SPINNER = 'HIDE_DISCUSSION_ACTION_SPINNER';
export const SHOW_COMMENT_ACTION_SPINNER = 'SHOW_COMMENT_ACTION_SPINNER';
export const HIDE_COMMENT_ACTION_SPINNER = 'HIDE_COMMENT_ACTION_SPINNER';
export const ADD_COMMENT_LIKE_ERROR = 'ADD_COMMENT_LIKE_ERROR';
export const ADD_COMMENT_DISLIKE_ERROR = 'ADD_COMMENT_DISLIKE_ERROR';
export const UPDATE_COMMENT_LIKE_STATUS = 'UPDATE_COMMENT_LIKE_STATUS';
export const UPDATE_COMMENT_LIKE_ID = 'UPDATE_COMMENT_LIKE_ID';
export const UPDATE_COMMENT_ID = 'UPDATE_COMMENT_ID';
export const DELETE_COMMENT_ERROR = 'DELETE_COMMENT_ERROR';
export const UPDATE_COMMENT_DELETE_FLAG = 'UPDATE_COMMENT_DELETE_FLAG';
export const UPDATE_EDIT_DISCUSSION_MODAL = 'UPDATE_EDIT_DISCUSSION_MODAL';
export const UPDATE_REPORT_FLAG = 'UPDATE_REPORT_FLAG';
export const UPDATE_REPORT_REASON = 'UPDATE_REPORT_REASON';
export const UPDATE_REPORT_REASON_LIST = 'UPDATE_REPORT_REASON_LIST';
export const REPORT_DISCUSSION_ERROR = 'REPORT_DISCUSSION_ERROR';
export const UPDATE_DISCUSSION_REPORTED_FLAG = 'UPDATE_DISCUSSION_REPORTED_FLAG';
export const UPDATE_COMMENT_REPORTED_FLAG = 'UPDATE_COMMENT_REPORTED_FLAG';
export const UPDATE_COMMENT_REPORT_ID = 'UPDATE_COMMENT_REPORT_ID';
export const UPDATE_COMMENT_REPORT_FLAG = 'UPDATE_COMMENT_REPORT_FLAG';
export const REPORT_COMMENT_ERROR = 'REPORT_COMMENT_ERROR';
export const SHOW_COMMENT_MODAL_SPINNER = 'SHOW_COMMENT_MODAL_SPINNER';
export const HIDE_COMMENT_MODAL_SPINNER = 'HIDE_COMMENT_MODAL_SPINNER';
export const ARCHIVE_DISCUSSION_ERROR = 'ARCHIVE_DISCUSSION_ERROR';
export const UPDATE_ARCHIVE_DISCUSSION = 'UPDATE_ARCHIVE_DISCUSSION';

//Rebates
export const UPDATE_REBATES_MODAL_SPINNER = 'UPDATE_REBATES_MODAL_SPINNER';
export const UPDATE_REBATES_ACTIVE_TAB = 'UPDATE_REBATES_ACTIVE_TAB';
export const UPDATE_PRODUCT_REBATE_MODAL = 'UPDATE_PRODUCT_REBATE_MODAL';
export const UPDATE_PRODUCT_REBATES_SPINNER = 'UPDATE_PRODUCT_REBATES_SPINNER';
export const UPDATE_PRODUCT_REBATES_LIST_SPINNER = 'UPDATE_PRODUCT_REBATES_LIST_SPINNER';
export const UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER = 'UPDATE_PRODUCT_REBATES_ACTIVE_QUARTER';
export const UPDATE_PRODUCT_REBATES_QUARTER = 'UPDATE_PRODUCT_REBATES_QUARTER';
export const UPDATE_PRODUCT_REBATES_LIST = 'UPDATE_PRODUCT_REBATES_LIST';
export const UPDATE_PRODUCT_REBATES_YEAR = 'UPDATE_PRODUCT_REBATES_YEAR';
export const UPDATE_PRODUCT_REBATES_FILE_CONTENT = 'UPDATE_PRODUCT_REBATES_FILE_CONTENT';
export const UPDATE_PRODUCT_REBATES_DRAFT = 'UPDATE_PRODUCT_REBATES_DRAFT';
export const UPDATE_PRODUCT_REBATES_FILE_NAME = 'UPDATE_PRODUCT_REBATES_FILE_NAME';
export const UPDATE_PRODUCT_REBATES_FILE_SIZE = 'UPDATE_PRODUCT_REBATES_FILE_SIZE';
export const UPDATE_PRODUCT_REBATES_FILE_EXTENSION = 'UPDATE_PRODUCT_REBATES_FILE_EXTENSION';
export const UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR = 'UPDATE_PRODUCT_REBATES_FILE_EXTENSION_ERROR';
export const CLEAR_PRODUCT_REBATES_MODAL = 'CLEAR_PRODUCT_REBATES_MODAL';
export const UPDATE_PRODUCT_REBATES_QUARTER_LIST = 'UPDATE_PRODUCT_REBATES_QUARTER_LIST';
export const UPDATE_REBATE_QUARTER_SPINNER = 'UPDATE_REBATE_QUARTER_SPINNER';
export const UPDATE_PRODUCT_REBATES_EDIT_FLAG = 'UPDATE_PRODUCT_REBATES_EDIT_FLAG';
export const UPDATE_PUBLISH_SPINNER = 'UPDATE_PUBLISH_SPINNER';
export const UPDATE_PRODUCT_REBATES_EXPORT_MODAL = 'UPDATE_PRODUCT_REBATES_EXPORT_MODAL';
export const UPDATE_PRODUCT_REBATES_DATE_RANGE = 'UPDATE_PRODUCT_REBATES_DATE_RANGE';
export const UPDATE_PRODUCT_REBATE_NOTES = 'UPDATE_PRODUCT_REBATE_NOTES';
export const UPDATE_PRODUCT_REBATE_NOTES_EDIT = 'UPDATE_PRODUCT_REBATE_NOTES_EDIT';
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT';
export const UPDATE_PRODUCT_REBATES_NOTES = 'UPDATE_PRODUCT_REBATES_NOTES';
export const UPDATE_PRODUCT_REBATES_NOTES_ERROR = 'UPDATE_PRODUCT_REBATES_NOTES_ERROR';
export const UPDATE_PRODUCT_REBATES_EXPORT_QUARTER = 'UPDATE_PRODUCT_REBATES_EXPORT_QUARTER';
export const UPDATE_PRODUCT_REBATES_EXPORT_YEAR = 'UPDATE_PRODUCT_REBATES_EXPORT_YEAR';
export const CLEAR_PRODUCT_REBATES_NOTES_MODAL = 'CLEAR_PRODUCT_REBATES_NOTES_MODAL';
export const CLEAR_PRODUCT_REBATES_EXPORT_MODAL = 'CLEAR_PRODUCT_REBATES_EXPORT_MODAL';
export const UPDATE_PRODUCT_REBATES_REQUEST_TYPE = 'UPDATE_PRODUCT_REBATES_REQUEST_TYPE';
export const UPDATE_PRODUCT_REBATES_INFO = 'UPDATE_PRODUCT_REBATES_INFO';
export const EARNED_REBATES_MACHINE = 'EARNED_REBATES_MACHINE';

//Member Rebates
export const UPDATE_MEMBER_REBATE_MODAL = 'UPDATE_MEMBER_REBATE_MODAL';
export const UPDATE_MEMBER_REBATES_QUARTER_LIST = 'UPDATE_MEMBER_REBATES_QUARTER_LIST';
export const UPDATE_MEMBER_REBATES_ACTIVE_QUARTER = 'UPDATE_MEMBER_REBATES_ACTIVE_QUARTER';
export const UPDATE_MEMBER_REBATES_QUARTER = 'UPDATE_MEMBER_REBATES_QUARTER';
export const UPDATE_MEMBER_REBATES_YEAR = 'UPDATE_MEMBER_REBATES_YEAR';
export const UPDATE_MEMBER_REBATES_EXPORT_QUARTER = 'UPDATE_MEMBER_REBATES_EXPORT_QUARTER';
export const UPDATE_MEMBER_REBATES_EXPORT_YEAR = 'UPDATE_MEMBER_REBATES_EXPORT_YEAR';
export const UPDATE_MEMBER_REBATES_LIST = 'UPDATE_MEMBER_REBATES_LIST';
export const UPDATE_MEMBER_REBATES_LIST_SPINNER = 'UPDATE_MEMBER_REBATES_LIST_SPINNER';
export const UPDATE_MEMBER_REBATES_DRAFT = 'UPDATE_MEMBER_REBATES_DRAFT';
export const UPDATE_MEMBER_REBATES_EXPORT_MODAL = 'UPDATE_MEMBER_REBATES_EXPORT_MODAL';
export const UPDATE_FILE_UPLOAD_ACTIVE_STEP = 'UPDATE_FILE_UPLOAD_ACTIVE_STEP';
export const UPDATE_MEMBER_REBATES_EDIT = 'UPDATE_MEMBER_REBATES_EDIT';

export const UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT = 'UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT';
export const UPDATE_MEMBER_REBATES_FILE_ONE_NAME = 'UPDATE_MEMBER_REBATES_FILE_ONE_NAME';
export const UPDATE_MEMBER_REBATES_FILE_ONE_SIZE = 'UPDATE_MEMBER_REBATES_FILE_ONE_SIZE';
export const UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION = 'UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION';

export const UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT = 'UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT';
export const UPDATE_MEMBER_REBATES_FILE_TWO_NAME = 'UPDATE_MEMBER_REBATES_FILE_TWO_NAME';
export const UPDATE_MEMBER_REBATES_FILE_TWO_SIZE = 'UPDATE_MEMBER_REBATES_FILE_TWO_SIZE';
export const UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION = 'UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION';

export const UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT = 'UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT';
export const UPDATE_MEMBER_REBATES_FILE_THREE_NAME = 'UPDATE_MEMBER_REBATES_FILE_THREE_NAME';
export const UPDATE_MEMBER_REBATES_FILE_THREE_SIZE = 'UPDATE_MEMBER_REBATES_FILE_THREE_SIZE';
export const UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION = 'UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION';

export const UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR = 'UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR';
export const CLEAR_MEMBER_REBATE_MODAL = 'CLEAR_MEMBER_REBATE_MODAL';
export const UPDATE_MEMBER_REBATES_INFO = 'UPDATE_MEMBER_REBATES_INFO';
export const UPDATE_SELECTED_MEMBER_REBATES_INFO = 'UPDATE_SELECTED_MEMBER_REBATES_INFO';
export const UPDATE_REBATE_OVERVIEW_SPINNER = 'UPDATE_REBATE_OVERVIEW_SPINNER';
export const UPDATE_REBATES_OVERVIEW_DATA = 'UPDATE_REBATES_OVERVIEW_DATA';

//Member Admin Rebates
export const UPDATE_REBATES_MEMBER_OPTION_SPINNER = 'UPDATE_REBATES_MEMBER_OPTION_SPINNER';
export const UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER = 'UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER';
export const UPDATE_SELECTED_REBATES_MEMBER = 'UPDATE_SELECTED_REBATES_MEMBER';
export const UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST = 'UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST';
export const UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER = 'UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER';
export const UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR = 'UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR';
export const UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST = 'UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST';
export const UPDATE_REBATES_MEMBER_QUARTER_SPINNER = 'UPDATE_REBATES_MEMBER_QUARTER_SPINNER';
export const UPDATE_REBATES_MEMBER_YEAR_SPINNER = 'UPDATE_REBATES_MEMBER_YEAR_SPINNER';
export const UPDATE_REBATES_MEMBER_EXPORT_MODAL = 'UPDATE_REBATES_MEMBER_EXPORT_MODAL';
export const UPDATE_REBATES_MEMBER_EXPORT_QUARTER = 'UPDATE_REBATES_MEMBER_EXPORT_QUARTER';
export const UPDATE_REBATES_MEMBER_EXPORT_YEAR = 'UPDATE_REBATES_MEMBER_EXPORT_YEAR';
export const UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST = 'UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST';
export const UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST = 'UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST';
export const UPDATE_SAVINGS_PERCENTAGE = 'UPDATE_SAVINGS_PERCENTAGE';

//Claim Rebates
export const UPDATE_CLAIM_REBATES_MODAL = 'UPDATE_CLAIM_REBATES_MODAL';
export const UPDATE_HAND_DELIVER_CHECK = 'UPDATE_HAND_DELIVER_CHECK';
export const UPDATE_CLAIM_REBATES_EXPORT_MODAL = 'UPDATE_CLAIM_REBATES_EXPORT_MODAL';
export const UPDATE_REBATE_RECEIPT_EXPORT_QUARTER = 'UPDATE_REBATE_RECEIPT_EXPORT_QUARTER';
export const UPDATE_REBATE_RECEIPT_EXPORT_YEAR = 'UPDATE_REBATE_RECEIPT_EXPORT_YEAR';
export const CLEAR_REBATE_RECEIPT_EXPORT_MODAL = 'CLEAR_REBATE_RECEIPT_EXPORT_MODAL';

//Pagination
export const UPDATE_PAGINATION_SELECTED_ROWS = 'UPDATE_PAGINATION_SELECTED_ROWS';
export const UPDATE_PAGINATION_PAGE = 'UPDATE_PAGINATION_PAGE';
export const UPDATE_PAGE_TYPE = 'UPDATE_PAGE_TYPE';

//Profile
export const UPDATE_PROFILE_ROUTE = 'UPDATE_PROFILE_ROUTE';

//Dashboard
export const SHOW_DASHBOARD_KPI_QUARTER_SPINNER = 'SHOW_DASHBOARD_KPI_QUARTER_SPINNER';
export const SHOW_DASHBOARD_KPI_MEMBER_SPINNER = 'SHOW_DASHBOARD_KPI_MEMBER_SPINNER';
export const SHOW_DASHBOARD_KPI_DETAIL_SPINNER = 'SHOW_DASHBOARD_KPI_DETAIL_SPINNER';
export const UPDATE_DASHBOARD_KPI_QUARTER_LIST = 'UPDATE_DASHBOARD_KPI_QUARTER_LIST';
export const UPDATE_DASHBOARD_KPI_MEMBER_LIST = 'UPDATE_DASHBOARD_KPI_MEMBER_LIST';
export const UPDATE_DASHBOARD_KPI_SELECTED_QUARTER = 'UPDATE_DASHBOARD_KPI_SELECTED_QUARTER';
export const UPDATE_DASHBOARD_KPI_SELECTED_MEMBER = 'UPDATE_DASHBOARD_KPI_SELECTED_MEMBER';
export const SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER = 'SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER';
export const UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST = 'UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST';
export const UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER = 'UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER';
export const UPDATE_DASHBORD_OPPORTUNITY_LIST = 'UPDATE_DASHBORD_OPPORTUNITY_LIST';
export const SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER = 'SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER';
export const SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER = 'SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER';
export const SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER = 'SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_QUARTER_LIST';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_QUARTER';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_MEMBER_LIST';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_SELECTED_MEMBER';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_DETAILS';
export const SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER = 'SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER';
export const UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW = 'UPDATE_DASHBOARD_ACTIVE_EARNINGS_OVERVIEW';
export const UPDATE_DASHBOARD_KPI_DROPSIZE_DATA = 'UPDATE_DASHBOARD_KPI_DROPSIZE_DATA';
export const UPDATE_DASHBOARD_KPI_TERMS_DATA = 'UPDATE_DASHBOARD_KPI_TERMS_DATA';

//Rebate Receipts
export const SHOW_REBATE_LIST_SPINNER = 'SHOW_REBATE_LIST_SPINNER';
export const SHOW_REBATE_RECEIPT_MEMBER_SPINNER = 'SHOW_REBATE_RECEIPT_MEMBER_SPINNER';
export const UPDATE_REBATE_RECEIPT_MEMBER_LIST = 'UPDATE_REBATE_RECEIPT_MEMBER_LIST';
export const UPDATE_REBATE_RECEIPT_LIST = 'UPDATE_REBATE_RECEIPT_LIST';
export const UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER = 'UPDATE_REBATE_RECEIPT_ACTIVE_MEMBER';
export const SHOW_REBATE_RECEIPT_MODAL_SPINNER = 'SHOW_REBATE_RECEIPT_MODAL_SPINNER';
export const UPDATE_AVAILABLE_REBATES = 'UPDATE_AVAILABLE_REBATES';
export const SHOW_REBATE_RECEIPT_EXPORT_SPINNER = 'SHOW_REBATE_RECEIPT_EXPORT_SPINNER';
export const UPDATE_SELECTED_REBATE_RECEIPT = 'UPDATE_SELECTED_REBATE_RECEIPT';
export const UPDATE_REBATE_RECEIPT_ACTION_MODAL = 'UPDATE_REBATE_RECEIPT_ACTION_MODAL';
export const UPDATE_REBATE_RECEIPT_MODE = 'UPDATE_REBATE_RECEIPT_MODE';
export const CLEAR_REBATE_RECEIPT_ACTION_MODAL = 'CLEAR_REBATE_RECEIPT_ACTION_MODAL';
export const UPDATE_CONFIRM_MODAL = 'UPDATE_CONFIRM_MODAL'