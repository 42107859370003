import { Grid, InputBase } from '@material-ui/core';
import { FC } from 'react';

export const SearchFilter: FC<{
  searchText: string | number;
  setSearchText: (searchText: string) => void;
}> = ({ searchText, setSearchText }) => (
  <Grid>
    <div className="page-search-box">
      <i className="fas fa-search" />
      <InputBase
        placeholder="Search"
        value={searchText && searchText.toString()}
        onChange={(event) => setSearchText(event.target.value)}
      />
    </div>
  </Grid>
);

export default SearchFilter;
