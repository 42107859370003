//Vendor
export const UPDATE_VENDOR_DC_ERROR = 'UPDATE_VENDOR_DC_ERROR';
export const UPDATE_VENDOR_SALES_ERROR = 'UPDATE_VENDOR_SALES_ERROR';
export const UPDATE_VENDOR_USERS_LIST_ERROR = 'UPDATE_VENDOR_USERS_LIST_ERROR';
export const UPDATE_VENDOR_MEMBERS_LIST_ERROR = 'UPDATE_VENDOR_MEMBERS_LIST_ERROR';
export const UPDATE_VENDOR_LINKS_ERROR = 'UPDATE_VENDOR_LINKS_ERROR';
export const UPDATE_VENDOR_KPI_ERROR = 'UPDATE_VENDOR_KPI_ERROR';
export const UPDATE_VENDOR_KPI_TYPES_ERROR = 'UPDATE_VENDOR_KPI_TYPES_ERROR';
export const DELETE_VENDOR_USER_ERROR = 'DELETE_VENDOR_USER_ERROR';
export const DELETE_VENDOR_MEMBER_ERROR = 'DELETE_VENDOR_MEMBER_ERROR';
export const DELETE_VENDOR_LINK_ERROR = 'DELETE_VENDOR_LINK_ERROR';
export const DELETE_VENDOR_KPI_ERROR = 'DELETE_VENDOR_KPI_ERROR';
export const GET_VENDOR_LINK_TYPES_ERROR = 'GET_VENDOR_LINK_TYPES_ERROR';
export const GET_KPI_OVERVIEW_ERROR = 'GET_KPI_OVERVIEW_ERROR';
export const GET_KPI_OVERVIEW_YEARS_ERROR = 'GET_KPI_OVERVIEW_YEARS_ERROR';
export const GET_KPI_OVERVIEW_MONTHS_ERROR = 'GET_KPI_OVERVIEW_MONTHS_ERROR';
export const UPDATE_VENDOR_MEMBER_KPI_DATA = 'UPDATE_VENDOR_MEMBER_KPI_DATA';

//Community
export const UPDATE_COMMUNITY_CATEGORY_ERROR = "UPDATE_COMMUNITY_CATEGORY_ERROR";
export const UPDATE_COMMUNITY_CHANNEL_ERROR = "UPDATE_COMMUNITY_CHANNEL_ERROR";
export const GET_ALL_DISCUSSIONS_ERROR = "GET_ALL_DISCUSSIONS_ERROR";
export const GET_SINGLE_DISCUSSIONS_ERROR = "GET_SINGLE_DISCUSSIONS_ERROR";
export const GET_DISCUSSION_COMMENTS_ERROR = "GET_DISCUSSION_COMMENTS_ERROR";
export const UPDATE_DISCUSSION_VIEW_ERROR = "UPDATE_DISCUSSION_VIEW_ERROR";
export const GET_MORE_COMMENTS_ERROR = "GET_MORE_COMMENTS_ERROR";
export const GET_REPROT_REASON_ERROR = "GET_REPROT_REASON_ERROR";

//Common
export const UPDATE_STATE_LIST = 'UPDATE_STATE_LIST';
export const UPDATE_STATE_LIST_ERROR = 'UPDATE_STATE_LIST_ERROR';
export const UPDATE_USER_ROLE_LIST = 'UPDATE_USER_ROLE_LIST';
export const UPDATE_USER_ROLE_LIST_ERROR = 'UPDATE_USER_ROLE_LIST_ERROR';

//Error Message
export const VendorSalesError = 'Unable to fetch sales personnel list';
export const VendorUserListError = 'Unable to fetch vendor users list';
export const VendorUserAssignError = 'Unable to assign vendor user';
export const VendorUserCreateError = 'Unable to create vendor user';
export const VendorUserUpdateError = 'Unable to update vendor user';
export const VendorMemberListError = 'Unable to fetch vendor members list';
export const VendorMemberAssignError = 'Unable to assign vendor member';
export const VendorMemberUpdateError = 'Unable to update vendor member';
export const VendorLinksError = 'Unable to fetch vendor links list';
export const VendorKPIError = `Unable to fetch vendor KPI's list`;
export const VendorKPITypesError = `Unable to fetch vendor KPI types list`;
export const VendorDeleteUserError = 'Unable to unassign vendor user';
export const VendorDeleteMemberError = 'Unable to unassign vendor member';
export const VendorDeleteLinkError = 'Unable to unassign vendor link';
export const VendorDeleteKpiError = 'Unable to unassign vendor kPI';
export const VendorDCCreateError = 'Unable to create distribution center';
export const VendorDCUpdateError = 'Unable to update distribution center';
export const VendorDeleteDCError = 'Unable to unassign distribution center';
export const VendorLinkTypeError = 'Unable to fetch vendor link types';
export const CreteVendorLinkError = 'Unable to add vendor link';
export const UpdateVendorLinkError = 'Unable to update vendor link';
export const UnAssignVendorLinkError = 'Unable to unassign vendor link';

export const StateListError = 'Unable to fetch states';
export const UserRoleListError = 'Unable to fetch user roles';
export const VendorKPIAddError = 'Unable to add vendor KPI';
export const VendorKPIEditError = 'Unable to update vendor KPI';

export const VendorKPIYearError = 'Unable to fetch kpi years';
export const VendorKPIMemberError = 'Unable to fetch kpi member details';

//Success Message
export const VendorUserAssignSuccess = 'User Added and Email Sent';//'User assigned successfully!';
export const VendorUserDeleteSuccess = 'User unassigned successfully!';
export const VendorUserCreateSuccess = 'User Created and Application Invitation Email Sent';//'User created successfully!';
export const VendorUserUpdateSuccess = 'User updated successfully!';
export const VendorMemberAssignSuccess = 'Member assigned successfully!';
export const VendorMemberUpdateSuccess = 'Member Updated successfully!';
export const VendorMemberDeleteSuccess = 'Member unassigned successfully!';
export const VendorDCCreateSuccess = 'Distribution center created successfully!';
export const VendorDCUpdateSuccess = 'Distribution center updated successfully!';
export const VendorDeleteDCSuccess = 'Distribution center unassigned successfully!';
export const VendorLinkAddSuccess = 'Vendor link added successfully!';
export const VendorLinkEditSuccess = 'Vendor link updated successfully!';
export const VendorLinkUnAssignSuccess = 'Vendor link unassigned successfully!';
export const VendorKPIAddSuccess = 'KPI added successfully!';
export const VendorKPIEditSuccess = 'KPI updated successfully!';
export const VendorKPIDeleteSuccess = 'KPI deleted successfully!';

//Documents
export const UPDATE_DC_MENU_LIST_ERROR = 'UPDATE_DC_MENU_LIST_ERROR';
export const UPDATE_DOCUMENT_LIST_ERROR = 'UPDATE_DOCUMENT_LIST_ERROR';
export const UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR';
export const UPDATE_DOCUMENT_CATEGORY_ERROR = 'UPDATE_DOCUMENT_CATEGORY_ERROR';
export const UPDATE_DOCUMENT_PUBLISH_ERROR = 'UPDATE_DOCUMENT_PUBLISH_ERROR';
export const UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR = 'UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR';
export const DOWNLOAD_DOCUMENT_ERROR = 'DOWNLOAD_DOCUMENT_ERROR';

//Rebates
export const UPLOAD_PRODUCT_REBATES_ERROR = 'UPLOAD_PRODUCT_REBATES_ERROR';
export const PRODUCT_REBATES_QUARTER_ERROR = 'PRODUCT_REBATES_QUARTER_ERROR';
export const PRODUCT_REBATES_QUARTER_LIST_ERROR = 'PRODUCT_REBATES_QUARTER_LIST_ERROR';
export const PUBLISH_PRODUCT_REBATES_ERROR = 'PUBLISH_PRODUCT_REBATES_ERROR';
export const EXPORT_REEBATE_PRODUCT_LIST_ERROR = 'EXPORT_REEBATE_PRODUCT_LIST_ERROR';
export const EXPORT_CALCULATED_REBATES_ERROR = 'EXPORT_CALCULATED_REBATES_ERROR';
export const GET_PRODUCT_REBATES_INFO_ERROR = 'GET_PRODUCT_REBATES_INFO_ERROR';
export const MEMBER_REBATES_QUARTER_ERROR = 'MEMBER_REBATES_QUARTER_ERROR';
export const MEMBER_REBATES_QUARTER_LIST_ERROR = 'MEMBER_REBATES_QUARTER_LIST_ERROR';
export const EXPORT_MEMBER_REBATE_ERROR = 'EXPORT_MEMBER_REBATE_ERROR'; ''
export const UPLOAD_MEMBER_REBATES_ERROR = 'UPLOAD_MEMBER_REBATES_ERROR';
export const PUBLISH_MEMBER_REBATES_ERROR = 'PUBLISH_MEMBER_REBATES_ERROR';
export const UPDATE_MEMBER_REBATES_ERROR = 'UPDATE_MEMBER_REBATES_ERROR';
export const GET_REBATES_OVERVIEW_ERROR = 'GET_REBATES_OVERVIEW_ERROR';
export const EXPORT_MEMBER_ADMIN_REBATE_ERROR = 'EXPORT_MEMBER_ADMIN_REBATE_ERROR'; ''
export const CREATE_REBATE_RECEIPT_ERROR = 'CREATE_REBATE_RECEIPT_ERROR';
export const EXPORT_REBATE_RECEIPT_ERROR = 'EXPORT_REBATE_RECEIPT_ERROR';
export const REBATE_RECEIPT_NO_RECORD_ERROR = 'REBATE_RECEIPT_NO_RECORD_ERROR';
export const CONFIRM_REBATE_RECEIPT_ERROR = 'CONFIRM_REBATE_RECEIPT_ERROR';
export const EXPORT_EARNED_REBATE_OVERVIEW_ERROR = 'EXPORT_EARNED_REBATE_OVERVIEW_ERROR';
export const EARNED_REBATE_NO_RECORD_ERROR = 'EARNED_REBATE_NO_RECORD_ERROR';

//Member Admin Rebates
export const GET_REBATE_MEMBERS_LIST_ERROR = 'GET_REBATE_MEMBERS_LIST_ERROR';
export const GET_REBATE_QUARTERS_LIST_ERROR = 'GET_REBATE_QUARTERS_LIST_ERROR';
export const GET_REBATE_MEMBER_PRODUCT_LIST_ERROR = 'GET_REBATE_MEMBER_PRODUCT_LIST_ERROR';

//Dashboard
export const GET_DASHBOARD_KPI_DETAILS_ERROR = 'GET_DASHBOARD_KPI_DETAILS_ERROR';

//Error Message
export const DocumentListError = 'Unable to fetch documents list';
export const DocumentCategoryError = 'Unable to fetch documents categories';
export const DocumentDataError = 'Unable to fetch document records';
export const PublishDocumentError = 'Unable to publish document';
export const DeleteDocumentError = 'Unable to delete document';
export const DocumentSelectMemberListError = 'Unable to fetch select member list';
export const DocumentDownloadError = 'Unable to download document';


//Success Message
export const DocumentPublishSuccess = 'Document published successfully!';
export const DocumentDeleteSuccess = 'Document deleted successfully!';
export const DocumentUpdateSuccess = 'Document updated successfully!';

//Rebates
export const UploadProductRebatesError = 'Unable to Upload opportunities list';
export const ProductRebatesQuarterError = 'Unable to fetch quarter list';
export const ProductRebatesListError = 'Unable to fetch opportunities list';
export const PublishRebatesError = 'Unable to Publish opportunities list';
export const UpdateNotesError = 'Unable to update notes';
export const exportRebatesError = 'Unable to export the file';
export const getProductRebatesInfoError = 'Unable to fetch product rebates details';
export const MemberRebatesListError = 'Unable to fetch Member Rebates list';
export const exportMemberRebatesError = 'Unable to export the file';
export const UploadMemberRebatesError = 'Unable to Upload Member Rebates';
export const PublishMemberError = 'Unable to Publish Product Member';
export const MemberRebateInfoError = 'Unable to Fetch Member Rebates Info';
export const UpdateMemberRebatesError = 'Unable to Update Member Rebates';
export const RebatesOverviewError = 'Unable to Fetch Rebates overivew';
export const MemberAdminRebatesMemberListError = 'Unable to Fetch Member List';
export const MemberAdminRebatesQuartersListError = 'Unable to Fetch Quarter List';
export const MemberAdminRebatesProductListError = 'Unable to Fetch Member Product List';


//Members
export const UPDATE_MEMBER_LIST_ERROR = 'UPDATE_MEMBER_LIST_ERROR';
export const UPDATE_MEMBER_GET_ERROR = 'UPDATE_MEMBER_GET_ERROR';
export const UPDATE_MEMBER_DELETE_ERROR = 'UPDATE_MEMBER_DELETE_ERROR';
export const UPDATE_MEMBER_LOCATION_DELETE_ERROR = 'UPDATE_MEMBER_LOCATION_DELETE_ERROR';
export const UPDATE_MEMBER_USER_DELETE_ERROR = 'UPDATE_MEMBER_USER_DELETE_ERROR';

export const MembersListError = 'Unable to fetch members list';
export const MemberCreateSuccess = 'Member created successfully!';
export const MemberCreateError = 'Unable to create the member';
export const MemberUpdateSuccess = 'Member updated successfully!';
export const MemberUpdateError = 'Unable to update the member';
export const MemberDeleteSuccess = 'Member deleted successfully!';
export const MemberDeleteError = 'Unable to delete the member';
export const MemberLocationDeleteSuccess = 'Member location deleted successfully!';
export const MemberLocationDeleteError = 'Unable to delete the member location';
export const MemberDeleteUserError = 'Unable to delete user';
export const MemberAvailableRebatesEmailSendError = 'Error sending reminder email';

// member user
export const MemberUserCreateSuccess = 'User Created and Application Invitation Email Sent';//'Member user created successfully!';
export const MemberUserCreateError = 'Unable to create the member user';
export const UPDATE_MEMBER_USER_CREATE_ERROR = 'UPDATE_MEMBER_USER_CREATE_ERROR';
export const MemberUserUpdateSuccess = 'Member user updated successfully!';
export const MemberUserDeleteSuccess = 'Member user deleted successfully!';
export const MemberUserUnassignSuccess = 'Member user unassigned successfully!';
export const UserMemberDeletedSuccess = 'Member user deleted successfully!';
export const MemberUserUpdateError = 'Unable to update the member user';
export const UPDATE_MEMBER_USER_UPDATE_ERROR = 'UPDATE_MEMBER_USER_UPDATE_ERROR';
export const SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR = 'SEND_MEMBER_AVAILABLE_REBATES_EMAIL_ERROR';

// login
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const LoginError = "Unable to login";

//Fetch Message
export const FetchError = 'Unable to fetch Records';

// manage ads
export const AdsTypeCreateSuccess = "Ads type created successfully!";
export const AdsTypeCreateError = "Unable to create the ads type";
export const AdsTypeListError = "Unable to fetch ads type";
export const AdCreateSuccess = "Ad created successfully!";
export const AdCreateError = "Unable to create the ad";
export const AdListError = "Unable to fetch ads list";

// products
export const UPDATE_PRODUCT_BRAND_LIST_ERROR = 'UPDATE_PRODUCT_BRAND_LIST_ERROR';
export const UPDATE_PRODUCT_LIST_ERROR = 'UPDATE_PRODUCT_LIST_ERROR';
export const BrandListError = "Unable to fetch brand list";
export const ProductListError = "Unable to fetch product list";
// change password
export const UPDATE_CHANGE_PASSWORD_ERROR = 'UPDATE_CHANGE_PASSWORD_ERROR';
export const ChangePasswordError = "Unable to change password";
export const ChangePasswordSuccess = "Password changed successfully!";

//Community
export const CommunityCategoryError = "Unable to fetch Community Category"
export const CreateChannelError = 'Unable to create channel';
export const UpdateChannelError = 'Unable to update channel';
export const UpdateDiscussionViewError = 'Unable to update discussion view';
export const getSingleDiscussionError = 'Unable to fetch discussion details';
export const getDiscussionCommentsError = 'Unable to fetch discussion comments';
export const GetAllDiscussionsError = 'Unable to fetch discussions';
export const AddNewCommentsError = 'Unable to add comment';
export const DiscussionLikeError = 'Unable to like the discussion';
export const DiscussionDisLikeError = 'Unable to dislike the discussion';
export const CreateDiscussionsError = 'Unable to create discussion. Please try again!';
export const AddCommentLikeError = 'Unable to like the comment';
export const AddCommentDisLikeError = 'Unable to dislike the comment';
export const ReportReasonListError = 'Unable to fetch Report reasons';
export const ReportDiscussionError = 'unable to report the discussion';
export const ArchiveDiscussionsError = 'Unable to archive discussion. Please try again!';
export const UpdateCommentsError = 'Unable to update comment';

//Dashboard
export const DashboardFetchError = 'Unable to fetch dashboard details';
export const DashboardOppQuarterListError = 'Unable to fetch Opportunity quarter list';
export const DashboardOppListError = 'Unable to fetch Opportunity list';
export const DashboardKPIDetailError = 'Unable to fetch KPI details';
export const ActiveEarningsQuarterListError = 'Unable to fetch Active Earnings quarter list';
export const ActiveEarningsMemberListError = 'Unable to fetch Active Earnings members list';
export const KPIMemberListError = 'Unable to fetch KPI members list';

//Rebate Receipt
export const RebatesListError = 'Unable to fetch rebate receipt list';
export const AvailableRebatesError = 'Unable to fetch available rebates details';
export const createRebateReceiptError = 'Unable to claim rebate receipt';
export const exportRebateReceiptError = 'Unable to export rebate receipt';
export const rebateReceiptExportError = 'No rebates found in the selected Quarter and year to export';
export const confirmRebateReceiptError = 'Unable to confirm rebate receipt request';
export const insufficientFundError = 'Insufficient funds.'
