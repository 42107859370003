import { FC, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useActor, useMachine } from '@xstate/react';
import { DashboardEBrand } from './EBrand/DashboardEBrand';
import { Loader } from '../../../../utils';
import '../../dashboard.scss';
import { DropSize, PaymentTerms } from '../../../../components/kpiTools';
import { useCloseableDisplay } from '../../../../reactHooks';
import { BRDropdown } from '../../../../components/BRDropdown';
import dashboardKpiMachine from './machines/dashboardKpiMachine';
import { IntervalType, Member, Vendor } from '../../../../common';
import DashboardCollapsibleSectionHeader from '../DashboardCollapsibleSectionHeader';
import { startCase } from 'lodash';
import { dashboardEarnedRebatesMachine } from '../DashboardEarnedRebates/machines';
import { useDispatch } from 'react-redux';

export const DashboardKPI: FC = () => {
  const [state] = useMachine(dashboardKpiMachine);
  const [memberState, memberSend] = useActor(state.children.members);
  const [timeFrameIntervalState, timeFrameIntervalSend] = useActor(
    state.children.timeFrameInterval,
  );
  const [vendorState] = useActor(state.children.vendors);

  const [opened, { toggle }] = useCloseableDisplay();
  // ----
  const [state2] = useMachine(dashboardEarnedRebatesMachine);
  const [memberState2, memberSend2] = useActor(state2.children.members);
  const dispatch = useDispatch()
  const { acquiredAmount, availableAmount, pendingAmount } = state2.context;
  
  useEffect(() => {
    dispatch({
      type : 'EARNED_REBATES_MACHINE',
      payload :{
        selectedMemberId: memberState.context.selectedMember?.id,
        acquiredAmount: acquiredAmount,
        availableAmount: availableAmount,
        pendingAmount: pendingAmount,
      }
    })
  },[state2])
  // ----
  return (
    <div className="dashboard-section">
      {memberState.hasTag('loading') ? (
        <div className="kpi-details-nodata">
          <Loader size={20} />
        </div>
      ) : (
        <>
          <DashboardCollapsibleSectionHeader
            title="KPIs - More Ways to Save"
            opened={opened}
            toggle={toggle}
          />
          {opened && (
            <Grid
              container
              justifyContent="flex-start"
              sx={{
                mt: 2,
                mb: 2,
                display: 'grid',
                gridTemplateColumns: '1fr min-content',
                gap: 1,
              }}
            >
              <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
                <BRDropdown
                  disabled
                  selectedOption={vendorState.context.selectedVendor?.id || 0}
                  menuList={vendorState.context.vendors.map(
                    (vendor: Vendor) => ({
                      value: vendor.id,
                      label: vendor.name,
                    }),
                  )}
                  handleSelected={(item: any) => {
                    const memberId = item?.value;
                    if (memberId) {
                      memberSend({
                        memberId,
                        type: 'updateSelectedMember',
                      });
                    }
                  }}
                  spinner={memberState.hasTag('loading')}
                />
              </Grid>

              <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
                <BRDropdown
                  selectedOption={memberState.context.selectedMember?.id || 0}
                  disabled={memberState.context.members.length <= 1}
                  menuList={memberState.context.members.map(
                    (member: Member) => ({
                      value: member.id,
                      label: member.name,
                    }),
                  )}
                  isMemberList = {true}
                  handleSelected={(item: any) => {
                    const memberId = item?.value;
                    if (memberId) {
                      memberSend({
                        memberId,
                        type: 'updateSelectedMember',
                      });
                      memberSend2({
                        memberId,
                        type: 'updateSelectedMember',
                      });
                    }
                  }}
                  spinner={memberState.hasTag('loading')}
                />
              </Grid>
              <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
                <BRDropdown
                  noOptionLabel="Select a time frame..."
                  selectedOption={
                    timeFrameIntervalState.context.selectedIntervalType
                  }
                  menuList={timeFrameIntervalState.context.intervalTypes.map(
                    (intervalType: IntervalType) => ({
                      value: intervalType,
                      label: startCase(intervalType),
                    }),
                  )}
                  handleSelected={(item: any) => {
                    const intervalType = item?.value;
                    if (intervalType) {
                      timeFrameIntervalSend({
                        intervalType,
                        type: 'updateSelectedIntervalType',
                      });
                    }
                  }}
                  spinner={memberState.hasTag('loading')}
                />
              </Grid>
              <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
                {timeFrameIntervalState.context.intervalValueLists[
                  timeFrameIntervalState.context.selectedIntervalType
                ]?.length > 0 ? (
                  <BRDropdown
                    selectedOption={
                      timeFrameIntervalState.context.selectedIntervalValue
                    }
                    menuList={
                      timeFrameIntervalState.context.intervalValueLists[
                        timeFrameIntervalState.context.selectedIntervalType
                      ]
                    }
                    handleSelected={(item: any) => {
                      const intervalValue = item?.value;
                      if (intervalValue) {
                        timeFrameIntervalSend({
                          intervalValue,
                          type: 'updateSelectedIntervalValue',
                        });
                      }
                    }}
                    spinner={memberState.hasTag('loading')}
                  />
                ) : (
                  <BRDropdown
                    disabled
                    noOptionLabel="-"
                    isYear={true}
                    selectedOption={null}
                    menuList={[]}
                    spinner={memberState.hasTag('loading')}
                  />
                )}
              </Grid>
              <Grid item sx={{ gridRow: '1' }} justifyContent="flex-end">
                <BRDropdown
                  selectedOption={timeFrameIntervalState.context.selectedYear}
                  menuList={timeFrameIntervalState.context.years.map(
                    (year: number) => ({
                      label: `${year}`,
                      value: year,
                    }),
                  )}
                  handleSelected={(item: any) => {
                    const year = item?.value;
                    if (year) {
                      timeFrameIntervalSend({
                        year: Number(year),
                        type: 'updateSelectedYear',
                      });
                    }
                  }}
                  spinner={memberState.hasTag('loading')}
                />
              </Grid>
            </Grid>
          )}
          <div
            className={
              opened ? 'table-wrapper' : 'table-wrapper table-wrapper-hide'
            }
          >
            {memberState.context.members.length > 0 && (
              <div>
                {state?.children?.dropSizeAgreements && (
                  <DropSize
                    actor={state.children.dropSizeAgreements}
                    id="dashboard-drop-size-chart"
                    memberId={memberState.context.selectedMember?.id ?? 0}
                  />
                )}
                {state?.children?.paymentTerms && (
                  <PaymentTerms
                    actor={state.children.paymentTerms}
                    id="dashboard-payment-terms-chart"
                    memberId={memberState.context.selectedMember?.id ?? 0}
                  />
                )}
                <DashboardEBrand /> {/* TODO memberId should be a prop */}
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardKPI;
