import { Button, Dialog } from '@mui/material';
import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import {
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from '../../../components/styledDialogChildren';
import { getFileSize, Loader } from '../../../utils';
import { OpportunitiesPublishMachine } from './machines/opportunitiesPublishMachine';

export const OpportunitiesPublishModal: FC<{
  opportunitiesPublishUploadActor: ActorRefFrom<OpportunitiesPublishMachine>;
}> = ({ opportunitiesPublishUploadActor: opportunitiesEditUploadActor }) => {
  const [state, send] = useActor(opportunitiesEditUploadActor);

  return (
    <Dialog
      open
      aria-labelledby="customized-dialog-title"
      className="dialog-box"
    >
      <StyledDialogTitle onClose={() => send('cancel')}>
        Publish uploaded rebate opportunities
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className="edit-doc-section">
          <div className="edit-doc-section-box">
            <div className="documents-grid-img">
              <i className="far fa-file-excel" />
              <span className="documents-doc-type doc-xls">xlsx</span>
            </div>

            <div className="edit-doc-section-name">
              rebate_product_list_documents/
              {state.context.selectedOpportunityQuarter.year}/
              {state.context.selectedOpportunityQuarter.quarter}/
              {state.context.selectedOpportunityQuarter.id}.xlsx
            </div>
            <div className="edit-doc-section-size">
              {getFileSize(
                state.context.selectedOpportunityQuarter.fileSize ?? 0,
              )}
            </div>
          </div>
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div className="action-button">
            <button
              className="cancel-user-button"
              onClick={() => send('cancel')}
            >
              Cancel
            </button>
            <Button
              autoFocus
              onClick={() => send('publish')}
              className={
                state.hasTag('loading')
                  ? 'invite-user-button button-disable'
                  : 'invite-user-button'
              }
              disabled={state.hasTag('loading')}
            >
              Publish uploaded document{' '}
              {state.hasTag('loading') && <Loader size={20} />}
            </Button>
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export default OpportunitiesPublishModal;
