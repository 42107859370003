import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
}));

export const AlertFunction = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Alert severity={props.alertType}>{props.message}</Alert>
    </div>
  );
}


AlertFunction.propTypes = {
  message: PropTypes.string.isRequired,
  alertType: PropTypes.oneOf(["error", "warning", "info", "success"])
    .isRequired,
};
