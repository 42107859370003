import { Grid, Button, Tooltip } from '@material-ui/core';
import { FC } from 'react';
import AuthorizationService from '../../../../authorization/AuthorizationService';
import { MemberFundingAccount } from '../../../../common';
import { useSelector } from 'react-redux';

export const FundTransferListRow: FC<{
  memberAccountDetail: MemberFundingAccount;
  handleEditItem: (item: MemberFundingAccount) => void;
  handleDeleteItem: (item: MemberFundingAccount) => void;
}> = ({ memberAccountDetail, handleEditItem, handleDeleteItem }) => {
  const { bankAccountType, accountNumber, accountNickName } =
    memberAccountDetail;

  const store = useSelector((state) => state)

  const viewAsMember:any = store

  const accountNameArray = accountNickName?.toString()?.split('<');
  const accountName = accountNameArray?.[0].trim();
  const fourDigitAccountNumber = accountNameArray?.[1]?.split('>')[0].trim() ?? '';

  return (
    <Grid style={{ cursor: 'pointer' }} container spacing={0} className="tr">
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={5}
        container
        direction="row"
        alignItems="center"
      >
        <div className="table-member-details">
          <div className="member-profile-details">
            {accountName}
            <span
              style={{ fontWeight: 'bold' }}
            >{` ${fourDigitAccountNumber}`}</span>
          </div>
        </div>
      </Grid>
      <Grid
        className="td xs-left-align"
        item
        xs={12}
        lg={5}
        container
        direction="row"
        alignItems="center"
      >
        {bankAccountType}
      </Grid>
      <Grid
        className="td action-col"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        justifyContent="flex-end"
        alignItems="center"
      >
        {/* {AuthorizationService.isAuthorizedHTML('') && ( */}
        <Tooltip title="Edit">
          <Button
            className="btn-circle btn-secondary"
            variant="outlined"
            onClick={(e) => {
              handleEditItem(memberAccountDetail);
            }}
            disabled={viewAsMember?.user?.isViewAsMember}
          >
            <i className="fas fa-pen" />
          </Button>
        </Tooltip>
        <Tooltip title="Remove">
          <Button
            className="btn-circle-trash"
            variant="outlined"
            color="secondary"
            onClick={() => handleDeleteItem(memberAccountDetail)}
            disabled={viewAsMember?.user?.isViewAsMember}
          >
            <i className="fas fa-times"></i>
          </Button>
        </Tooltip>
        {/* )} */}
      </Grid>
    </Grid>
  );
};

export default FundTransferListRow;
