import React from "react";
import Button from "@material-ui/core/Button";
import {
  getRebatesExtensionClassName,
  getFileClassName,
} from "../../../../../../utils";
import UploadImg from "../../../../../../images/file-upload.svg";


export const FileThreeUpdate = ({
  memberRebates,
  handleMemberRebatesFileThree,
  onMemberRebatesFileThreeRemove
}) => {

  const {fileThreeName, fileThreeSize, fileThreeExtension} = memberRebates;

  const onInputClick = (event) => {
    event.target.value = ''
  }

  return (
    <div className="edit-rebate-documents-box">
      {
        fileThreeName === null ? (
          <Button className="file-filed-document" component="label">
            <div className="file-filed-text-size-xs" style={{fontWeight:"bold"}}>
              Buy Right - "Janets Programs SOL"
            </div>
            <img src={UploadImg} />
            <div className="file-filed-text-size-sm">
              Upload XLS OR XLSX files
            </div>
            <div className="file-filed-text-size-md">
              Drop your files here.{" "}
            </div>
            <div>
              or <span className="file-filed-text-size-highlight">browse</span>
            </div>
            <input
              type="file"
              id="fileUpload"
              title=""
              value=""
              onChange={(event) =>
                handleMemberRebatesFileThree(event.target.files[0])
              }
            />
            {fileThreeExtension && (
              <div className="error-wrapper">
                <i className="fas fa-exclamation-triangle error-icon" />
                <div className="error-text">
                  {"Please upload only xls or xlsx extenstion file."}
                </div>
              </div>
            )}
          </Button>
        ) : (
          <div className="edit-doc-section">
            <div className="edit-doc-section-box">
              <div className="documents-grid-img">
                <i className={getFileClassName(fileThreeExtension)} />
                <span
                  className={getRebatesExtensionClassName(fileThreeExtension)}
                >
                  {fileThreeExtension}
                </span>
              </div>
              <div className="edit-doc-section-name">
                {fileThreeName}
              </div>
              <div className="edit-doc-section-size">
                {fileThreeSize}
              </div>
              <div className="edit-doc-section-buttons">
                <Button
                  className="remove-button"
                  onClick={onMemberRebatesFileThreeRemove}
                >
                  Remove
                </Button>
                <label className="replace-button" for={'fileThreeUpdate'}>
                  Replace
                </label>
                <input
                  type="file"
                  id={'fileThreeUpdate'}
                  hidden
                  onClick={onInputClick}
                  onChange={(event) => handleMemberRebatesFileThree(event.target.files[0])}
                />
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
};

