import {COMMUNITY_CATEGORY_API, GET_ALL_DISCUSSIONS_ERROR} from "../../../constants";


export const getAllDiscussions = async ({
    activeChannelID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const member = JSON.parse(sessionStorage.getItem('user') || '{}');
        let accessToken = Object.keys(member).length ? member.accessToken : user.accessToken
        const response = await fetch(`${COMMUNITY_CATEGORY_API}/${activeChannelID}/discussions?userId=${user.id}`, {
            headers: new Headers({
                "x-access-token": accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_ALL_DISCUSSIONS_ERROR;
    }
}

