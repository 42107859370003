import { FC, ReactNode } from 'react';
import Box from '@mui/material/Box';

export const ReportModeTabPanel: FC<{ matches: boolean; label: string, children?: ReactNode }> = ({
  children,
  matches,
  label,
}) => {
  return (
    <div
      role="tabpanel"
      hidden={!matches}
      id={`simple-tabpanel-${label}`}
      aria-labelledby={`simple-tab-${label}`}
    >
      {matches && <Box p={3}>{children}</Box>}
    </div>
  );
};
