import {
  Fade,
  Grid,
  LinearProgress,
} from '@material-ui/core';
import { useActor } from '@xstate/react';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie } from 'recharts';
import { ActorRef } from 'xstate';
import { AnimateCount } from '../../../utils';
import './payment-terms.scss';

export const CompactPaymentTermsChart: FC<{
  actor: ActorRef<any, any>; // TODO Temporary fix
  id?: string;
}> = ({ actor, id }) => {
  const [state] = useActor(actor);
  let pieProps = {
    isAnimationActive: false,
    data: [{ name: 'No Data Found', value: 1, fill: '#d6d6d6', key: '' }],
    cx: '50%',
    cy: '50%',
    innerRadius: '75%',
    outerRadius: '100%',
    fill: '#339933',
    dataKey: 'value',
    labelLine: false,
    minAngle: undefined as undefined | number,
    startAngle: undefined as undefined | number,
    className: 'mini-pie-chart-box-chart' as undefined | string,
  };
  const selectedTerms =
    state.context.paymentTerms.filter(
      (paymentTerm: any) => // TODO Temporary fix
        paymentTerm.name === state.context.selectedPaymentTermName,
    )?.[0] ?? null;

  if (selectedTerms) {
    pieProps = {
      ...pieProps,
      labelLine: true,
      minAngle: 5,
      startAngle: -270,
      isAnimationActive: true,
    };
    if (selectedTerms.withinTermsAmount > 0 || selectedTerms.outOfTermsAmount > 0) {
      pieProps.data = [
        {
          name: 'Within Terms',
          value: selectedTerms.withinTermsAmount,
          key: 'within',
          fill: '#0f76c2',
        },
        {
          name: 'Outside Terms & Unpaid Invoices',
          value: selectedTerms.outOfTermsAmount,
          key: 'outside',
          fill: '#e15759',
        },
      ];
    }
  }

  return (
    <Grid container direction="column">
      <Grid item container>
        <div className="pie-header">
          <h3 className="pie-title">Terms</h3>
        </div>
        {selectedTerms && (
          <Grid container className="legend-label" spacing={2}>
            <Grid item sm={3} xs={12}>
              <div className="icon-within-terms" />
              <div className="text-within-terms">Within Terms</div>
            </Grid>
            <Grid item sm={6} xs={12}>
              <div className="icon-outside-terms" />
              <div className="text-outside-terms">Outside Terms & Unpaid Invoices</div>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Grid item>
        <div style={{ width: '100%', position: 'relative' }}>
          <ResponsiveContainer minWidth={50} width={200} height={200}>
            <PieChart className="mini-pie-chart-box" style={{ margin: '0 auto'}}>
              <Pie key={`${selectedTerms?.withinTermsAmount} ${selectedTerms?.outOfTermsAmount}`} id={id} {...pieProps} />
            </PieChart>
          </ResponsiveContainer>
          <Fade in={state.hasTag('loading')}>
            <LinearProgress />
          </Fade>
          {selectedTerms ? (
            <div className="terms-count-wrapper">
              {typeof selectedTerms?.withinTermsAmount === 'number' && (
                <span className="within-terms-count">
                  <AnimateCount key={selectedTerms.withinTermsAmount} amount={selectedTerms.withinTermsAmount} />
                </span>
              )}
              {typeof selectedTerms?.outOfTermsAmount === 'number' && (
                <span className="outside-terms-count">
                  <AnimateCount key={selectedTerms.outOfTermsAmount} amount={selectedTerms.outOfTermsAmount} />
                </span>
              )}
            </div>
          ) : (
            <span className="terms-graph-nodata">
              {state.hasTag('loading') ? 'Loading...' : '-'}
            </span>
          )}
        </div>
      </Grid>
      <Grid item container>
      {selectedTerms && <p>The terms you are viewing are from today’s date to 6 months back.</p>}
      </Grid>
    </Grid>
  );
};

export default CompactPaymentTermsChart;
