import {
  CLEAR_MEMBER_FORM,
  UPDATE_MEMBER_FORM_SPINNER,
  UPDATE_MEMBER_FORM_IS_EDIT,
  UPDATE_MEMBER_FORM_FORM_UPDATED,
  UPDATE_MEMBER_FORM_PARENTID,
  UPDATE_MEMBER_FORM_ID,
  UPDATE_MEMBER_FORM_NAME,
  UPDATE_MEMBER_FORM_STREET,
  UPDATE_MEMBER_FORM_CITY,
  UPDATE_MEMBER_FORM_STATE,
  UPDATE_MEMBER_FORM_ZIP,
  UPDATE_MEMBER_FORM_PHONE,
  UPDATE_MEMBER_FORM_MOBILE,
  UPDATE_MEMBER_FORM_JOININGDATE,
  UPDATE_MEMBER_FORM_IMAGE_FILE,
  UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME,
  UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH,
  UPDATE_MEMBER_FORM_REDIRECT,
  UPDATE_MEMBER_NAV_FROM_PARENT,
  UPDATE_MEMBER_NAV_FROM_VIEW,
  UPDATE_MEMBER_NAV_FROM_TAB,
  UPDATE_MEMBER_FORM_NAME_ERROR,
  UPDATE_MEMBER_FORM_STREET_ERROR,
  UPDATE_MEMBER_FORM_CITY_ERROR,
  UPDATE_MEMBER_FORM_STATE_ERROR,
  UPDATE_MEMBER_FORM_ZIP_ERROR,
  UPDATE_MEMBER_FORM_PHONE_ERROR,
  UPDATE_MEMBER_FORM_MOBILE_ERROR,
  UPDATE_MEMBER_FORM_CANHANDDELIVER,
} from '../../constants';

const initialState = {
  spinner: false,
  isEdit: false,
  isFormUpdated: false,
  parentId: null,
  id: null,
  name: '',
  street: '',
  city: '',
  state: '0',
  zip: '',
  phone: '',
  mobile: '',
  joiningDate: new Date(),
  avatar: '',
  avatarPath: '',
  avatarFile: '',
  redirectTo: '',
  navFromParent: '',
  navFromView: '',
  navFromTab: '',
  childCount: 0,
  canHandDeliver: false,
  errorName: false,
  errorStreet: false,
  errorCity: false,
  errorState: false,
  errorZip: false,
  errorPhone: false,
  errorMobile: false,
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
  switch (action.type) {
    case UPDATE_MEMBER_FORM_PARENTID:
      return {
        ...state,
        parentId: action.parentId,
      };
    case UPDATE_MEMBER_FORM_ID:
      return {
        ...state,
        id: action.id,
      };
    case UPDATE_MEMBER_FORM_NAME:
      return {
        ...state,
        name: action.name,
      };
    case UPDATE_MEMBER_FORM_STREET:
      return {
        ...state,
        street: action.street,
      };
    case 'UPDATE_CHILD_COUNT':
      return {
        ...state,
        childCount: action.childCount,
      };
    case UPDATE_MEMBER_FORM_CITY:
      return {
        ...state,
        city: action.city,
      };
    case UPDATE_MEMBER_FORM_STATE:
      return {
        ...state,
        state: action.state,
      };
    case UPDATE_MEMBER_FORM_ZIP:
      return {
        ...state,
        zip: action.zip,
      };
    case UPDATE_MEMBER_FORM_PHONE:
      return {
        ...state,
        phone: action.phone,
      };
    case UPDATE_MEMBER_FORM_MOBILE:
      return {
        ...state,
        mobile: action.mobile,
      };
    case UPDATE_MEMBER_FORM_JOININGDATE:
      return {
        ...state,
        joiningDate: action.joiningDate,
      };
    case UPDATE_MEMBER_FORM_CANHANDDELIVER:
      return {
        ...state,
        canHandDeliver: action.canHandDeliver,
      };
    case UPDATE_MEMBER_FORM_IMAGE_FILE:
      return {
        ...state,
        avatarFile: action.avatarFile,
      };
    case UPDATE_MEMBER_FORM_UPLOADED_IMG_NAME:
      return {
        ...state,
        avatar: action.avatar,
      };
    case UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH:
      return {
        ...state,
        avatarPath: action.avatarPath,
      };
    case UPDATE_MEMBER_FORM_REDIRECT:
      return {
        ...state,
        redirectTo: action.redirectTo,
      };
    case UPDATE_MEMBER_FORM_NAME_ERROR:
      return {
        ...state,
        errorName: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_STREET_ERROR:
      return {
        ...state,
        errorStreet: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_CITY_ERROR:
      return {
        ...state,
        errorCity: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_STATE_ERROR:
      return {
        ...state,
        errorState: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_ZIP_ERROR:
      return {
        ...state,
        errorZip: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_PHONE_ERROR:
      return {
        ...state,
        errorPhone: action.errorFlag,
      };
    case UPDATE_MEMBER_FORM_MOBILE_ERROR:
      return {
        ...state,
        errorMobile: action.errorFlag,
      };
    case UPDATE_MEMBER_NAV_FROM_PARENT:
      return {
        ...state,
        navFromParent: action.navFromParent,
      };
    case UPDATE_MEMBER_NAV_FROM_VIEW:
      return {
        ...state,
        navFromView: action.navFromView,
      };
    case UPDATE_MEMBER_NAV_FROM_TAB:
      return {
        ...state,
        navFromTab: action.navFromTab,
      };
    case UPDATE_MEMBER_FORM_IS_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };
    case UPDATE_MEMBER_FORM_FORM_UPDATED:
      return {
        ...state,
        isFormUpdated: action.isFormUpdated,
      };
    case UPDATE_MEMBER_FORM_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case CLEAR_MEMBER_FORM:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
};
