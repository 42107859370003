import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@material-ui/core';
import { FC, useRef, useState } from 'react';
import { Loader } from '../../../utils';

export const Dropdown: FC<{
  documents: {
    categoryList: any[];
    spinner: boolean;
  };
  handleSelectedCategory: (category: any) => void;
}> = ({ documents: { categoryList, spinner }, handleSelectedCategory }) => {
  const [openMenu, setOpenMenu] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState('All');
  const anchorRef2 = useRef<any>(null);

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpenMenu(false);
    }
  };

  const handleDropdownToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleCloseMenu = (event: any) => {
    if (anchorRef2.current && anchorRef2.current.contains(event.target)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleSelected = (item: any, event: any) => {
    const { name } = item;
    handleSelectedCategory(item);
    setSelectedCategoryName(name);
    handleCloseMenu(event);
  };

  return (
    <div className="documents-dropmenu">
      <Button
        ref={anchorRef2}
        aria-controls={openMenu ? 'menu-list-dropdown' : undefined}
        aria-haspopup="true"
        onClick={handleDropdownToggle}
        className="documents-dropmenu-button"
      >
        Category: <b>{selectedCategoryName}</b>
        {spinner ? (
          <div className="category-spinner">
            <Loader size={15} />
          </div>
        ) : (
          <i className="fas fa-chevron-down" />
        )}
      </Button>
      <Popper
        open={openMenu}
        anchorEl={anchorRef2.current}
        role={undefined}
        transition
        disablePortal
        className="documents-dropmenu-part"
        modifiers={{
          preventOverflow: {
            enabled: true,
            escapeWithReference: true,
            boundariesElement: "viewport",
          },
        }}
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseMenu}>
                <MenuList
                  autoFocusItem={openMenu}
                  id="menu-list-dropdown"
                  onKeyDown={handleListKeyDown}
                >
                  {categoryList &&
                    categoryList.map((item) => {
                      const { id, name } = item;
                      return (
                        <MenuItem
                          onClick={(event) => handleSelected(item, event)}
                        >
                          {name}
                        </MenuItem>
                      );
                    })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default Dropdown;
