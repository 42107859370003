import {EXPORT_PRODUCT_REBATES, EXPORT_CALCULATED_REBATES_ERROR} from "../../../constants";

export const exportCalculatedRebates = async ({
    exportQuarter,
    exportYear
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${EXPORT_PRODUCT_REBATES}?quarter=${exportQuarter}&year=${exportYear}&product_list_rebate_export=true`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw DOWNLOAD_DOCUMENT_ERROR;
        const blob = await response.blob();
        return blob;
    } catch (error) {
        throw EXPORT_CALCULATED_REBATES_ERROR;
    }
}

