import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';

export const AssignMemberFilter: FC<any> = ({
  searchText,
  setSearchText,
  assignedVendorUsers,
  vendorUserId,
  vendorUserError,
  customerID,
  customerIDError,
  onChangeVendorMemberUser,
  updateMemberCustomerID,
  isEditModal,
  selectedMemberData,
  activeDistributionCenter,
  customerAbbreviation,
  updateMemberCustomerAbbr,
  customerAbbreviationError,
}) => {
  return (
    <div className="vendor-member-text-box">
      <div className="header-filter-box-search">
        <div className="page-search-box">
          <i className="fas fa-search" />
          <InputBase
            placeholder="Search"
            value={searchText && searchText.toString()}
            onChange={(event) => setSearchText(event.target.value)}
          />
        </div>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} className="assign-member-filters">
          <FormControl variant="outlined" className="select-box">
            {!vendorUserError ? (
              <InputLabel htmlFor="outlined-age-native-simple">
                Sales Personnel
              </InputLabel>
            ) : (
              <InputLabel
                htmlFor="outlined-age-native-simple"
                style={{ color: 'red' }}
              >
                Sales Personnel
              </InputLabel>
            )}
            <Select
              value={vendorUserId}
              onChange={(e) => onChangeVendorMemberUser(e.target.value)}
              label="Sales Personnel"
              error={vendorUserError}
              id={
                vendorUserError
                  ? 'standard-error'
                  : 'outlined-age-native-simple'
              }
            >
              <option value={0}>{'Select'}</option>
              {assignedVendorUsers?.map?.((item: any, index: number) => {
                return (
                  <option key={index} value={item.vendorUserId}>
                    {item.userName}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs lg={6}>
          <TextField
            label="Distribution Center"
            variant="outlined"
            className="text-box"
            disabled={true}
            value={
              activeDistributionCenter !== null && activeDistributionCenter
                ? activeDistributionCenter.distributionName
                : ''
            }
          />
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs lg={6}>
          <TextField
            error={customerAbbreviationError}
            id={customerAbbreviationError ? 'standard-error' : 'outlined-basic'}
            label="OpCo Abbreviation"
            variant="outlined"
            className="text-box"
            helperText={
              customerAbbreviation && customerAbbreviation.length < 3
                ? 'OpCo Abbreviation should be of 3 characters'
                : customerAbbreviationError
                ? 'Please enter OpCo Abbreviation'
                : ''
            }
            inputProps={{
              maxLength: 3,
            }}
            onChange={(e) => updateMemberCustomerAbbr(e.target.value)}
            required
            value={customerAbbreviation}
          />
        </Grid>
        <Grid item xs lg={6}>
          <TextField
            error={customerIDError}
            id={customerIDError ? 'standard-error' : 'outlined-basic'}
            label="Customer ID"
            variant="outlined"
            className="text-box"
            helperText={
              customerIDError
                ? 'Customer ID accepts only number. Please enter valid Id'
                : ''
            }
            inputProps={{
              maxLength: 5,
            }}
            onChange={(e) => updateMemberCustomerID(e.target.value)}
            required
            defaultValue={
              !isEditModal ? customerID : selectedMemberData.customerId
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};
