import {DOCUMENT_CATEGORY_API, UPDATE_DOCUMENT_CATEGORY_ERROR} from "../../constants";


export const getDocumentCategory = async () => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DOCUMENT_CATEGORY_API}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw UPDATE_DOCUMENT_CATEGORY_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DOCUMENT_CATEGORY_ERROR;
    }
}

