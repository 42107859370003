import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  UPDATE_MEMBER_REBATES_QUARTER_LIST,
  UPDATE_MEMBER_REBATES_ACTIVE_QUARTER,
  UPDATE_MEMBER_REBATES_QUARTER,
  UPDATE_MEMBER_REBATES_YEAR,
  UPDATE_MEMBER_REBATES_EXPORT_QUARTER,
  UPDATE_MEMBER_REBATES_EXPORT_YEAR,
  UPDATE_MEMBER_REBATES_LIST,
  UPDATE_MEMBER_REBATES_LIST_SPINNER,
  UPDATE_MEMBER_REBATES_DRAFT,
  MEMBER_REBATES_QUARTER_ERROR,
  MEMBER_REBATES_QUARTER_LIST_ERROR,
  EXPORT_MEMBER_REBATE_ERROR,
  MemberRebatesListError,
  exportMemberRebatesError,
  ProductRebatesQuarterError,
  EXPORT_MEMBER_ADMIN_REBATE_ERROR,
} from '../../../constants';

import {
  updateRebatesQuarterSpinner,
  rebatesModalSpinner,
  showMemberRebateExportModal,
} from '../rebates';
import {
  getMemberRebatesQuarters,
  getMemberRebatesQuarterList,
  exportMemberProductList,
} from '../../../api/rebates';
import {exportMemberAdminList} from "../../../api/rebates/memberAdminRebates/EarnedRebates";

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const handleMemberExportQuarter = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_REBATES_EXPORT_QUARTER,
    memberExportQuarter: value,
  });
};

export const handleMemberExportYear = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_REBATES_EXPORT_YEAR,
    memberExportYear: value,
  });
};

export const memberRebatesListSpinner =
  (memberRebatesListSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_REBATES_LIST_SPINNER,
      memberRebatesListSpinner,
    });
  };

export const updateMemberRebatesFileDraft =
  (isMemberRebateDraft) => async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_REBATES_DRAFT,
      isMemberRebateDraft,
    });
  };

export const onChangeMemberRebatesQuarter =
  (selectedQuarter) => async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_REBATES_QUARTER,
      quarter: selectedQuarter,
    });
  };

export const onChangeMemberRebatesYear = (selectedYear) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_REBATES_YEAR,
    year: selectedYear,
  });
};

export const getMemberRebatesQuarter = () => async (dispatch) => {
  dispatch(updateRebatesQuarterSpinner(true));
  try {
    const response = await getMemberRebatesQuarters();
    const { data } = response;
    if (data.length > 0) {
      dispatch({
        type: UPDATE_MEMBER_REBATES_QUARTER_LIST,
        memberQuarterList: data,
      });
      dispatch({
        type: UPDATE_MEMBER_REBATES_ACTIVE_QUARTER,
        memberActiveQuarter: data[0],
      });
      const formattedQuarter = data ? data[0].value.split(' ') : [];
      const quarter = formattedQuarter[0];
      const year = formattedQuarter[1];
      dispatch({
        type: UPDATE_MEMBER_REBATES_QUARTER,
        quarter,
      });
      dispatch({
        type: UPDATE_MEMBER_REBATES_YEAR,
        year,
      });
      dispatch(handleMemberExportQuarter(quarter));
      dispatch(handleMemberExportYear(year));
      dispatch(handleMemberRebateQuarter(data[0]));
      dispatch(updateRebatesQuarterSpinner(false));
    }
    dispatch(updateRebatesQuarterSpinner(false));
  } catch (error) {
    dispatch(updateRebatesQuarterSpinner(false));
    if (error === MEMBER_REBATES_QUARTER_ERROR) {
      toast.error(ProductRebatesQuarterError);
    }
  }
};

export const handleMemberRebateQuarter =
  (selectedQuarter) => async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_REBATES_ACTIVE_QUARTER,
      memberActiveQuarter: selectedQuarter,
    });
    const quarter = selectedQuarter && selectedQuarter?.quarter;
    const year = selectedQuarter && selectedQuarter?.year;
    dispatch({
      type: UPDATE_MEMBER_REBATES_QUARTER,
      quarter,
    });
    dispatch({
      type: UPDATE_MEMBER_REBATES_YEAR,
      year,
    });
    if (selectedQuarter.status === 'UPLOADED') {
      dispatch(updateMemberRebatesFileDraft(true));
    } else {
      dispatch(updateMemberRebatesFileDraft(false));
    }
    dispatch(memberRebatesListSpinner(true));
    try {
      const response = await getMemberRebatesQuarterList({
        quarter,
        year,
      });
      const { success, data } = response;
      const formattedData =
        data.length > 0 &&
        data.map((item) => {
          return {
            ...item,
            currentAvailableRebates: item.currentAvailableRebates.toString(),
          };
        });
      if (success && formattedData.length > 0) {
        dispatch({
          type: UPDATE_MEMBER_REBATES_LIST,
          memberRebateList: formattedData,
        });
      } else {
        dispatch({
          type: UPDATE_MEMBER_REBATES_LIST,
          memberRebateList: [],
        });
      }
      dispatch(memberRebatesListSpinner(false));
    } catch (error) {
      dispatch(memberRebatesListSpinner(false));
      if (error === MEMBER_REBATES_QUARTER_LIST_ERROR) {
        toast.error(MemberRebatesListError);
      }
    }
  };

export const onExportMemberRebates = () => async (dispatch, getState) => {
  const { memberRebates } = getState();
  const { memberExportQuarter, memberExportYear } = memberRebates;
  dispatch(rebatesModalSpinner(true));
  try {
    const response = await exportMemberProductList({
      exportQuarter: memberExportQuarter,
      exportYear: memberExportYear,
    });
    const date = new Date();
    const fileName = `Member_Rebates_${moment(date).format(
      'DD_MMM_YYYY_hh:mm:ss_A',
    )}.zip`;

    saveAs(response, fileName);
    dispatch(rebatesModalSpinner(false));
    dispatch(showMemberRebateExportModal(false));
  } catch (error) {
    dispatch(showMemberRebateExportModal(false));
    dispatch(rebatesModalSpinner(false));
    if (error === EXPORT_MEMBER_REBATE_ERROR) {
      toast.error(exportMemberRebatesError);
    }
  }
};

export const onExportMemberRebatesByQuarterYear = (memberId, exportQuarter, exportYear, setIsExport) => async (dispatch) => {
  setIsExport(true);
  try {
      const response = await exportMemberAdminList({
          memberId: memberId,
          exportQuarter,
          exportYear
      })
      const date = new Date();
      const fileName = `Member_Rebates_${moment(date).format('DD_MMM_YYYY_hh:mm:ss_A')}.xls`;

      saveAs(response, fileName);
      setIsExport(false);
  } catch (error) {
      setIsExport(false);
      if (error === EXPORT_MEMBER_ADMIN_REBATE_ERROR) {
          toast.error(exportMemberRebatesError)
      }
  }
}
