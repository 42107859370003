// email
export const EMAIL_EMPTY_MESSAGE = "Please enter email";
export const EMAIL_VALID_MESSAGE = "Please enter valid email";
// password
export const PASSWORD_EMPTY_MESSAGE = "Please enter password";
export const ZIP_EMPTY_MESSAGE = "Please enter zip";
export const ZIP_VALID_MESSAGE = "Please enter valid zip";
export const CODE_EMPTY_MESSAGE = "Please enter code";
export const CODE_VALID_MESSAGE = "Please enter valid code";
export const START_DATE_VALID_MESSAGE = "Please enter valid start date";
export const START_DATE_EMPTY_MESSAGE = "Please enter start date";
export const DATE_FORMAT_REGEXP = "/^\d{2}[./-]\d{2}[./-]\d{4}$/";
export const EMAIL_REGEXP = /^[a-zA-Z0-9.!#$%&'*+\=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const PASSWORD_REGEXP = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
// phone
export const PHONE_EMPTY_MESSAGE = "Please enter phone number";
export const PHONE_VALID_MESSAGE = "Please enter valid phone number";
// mobile
export const MOBILE_EMPTY_MESSAGE = "Please enter mobile number";
export const MOBILE_VALID_MESSAGE = "Please enter valid mobile number";
// clear format for save data 
export const removePhoneNoFormat = ( value ) => {
    // remove new lines
    value = value.replace(/(\r\n|\n|\r)/gm, "");
    // remove extra keys
    return value.replace(/[&\/\\#,+()$~%.'" :*?<>{}-]/g, "").trim();
}