import React from "react";
import {CustomModal} from "../../../../../components/CustomModal";
import {ExportProductRebatesModal} from "./ExportProductRebatesModal";

export const ExportProductRebates = ({
  openModal,
  setOpenModal,
  handleExportQuarter,
  handleExportYear,
  exportQuarter,
  exportYear,
  onExportProductRebates,
  rebatesModalSpinner,
  closeRebateExportModal,
  handleRequestRebateType,
  requestRebateType,
  quarterList
}) => {
  const closeModal = () => {
    closeRebateExportModal();
  };
  const formattedQuarters = quarterList && quarterList.filter((item) => item.status === "PUBLISHED")
  const child =
    <ExportProductRebatesModal
      handleExportQuarter={handleExportQuarter}
      handleExportYear={handleExportYear}
      exportQuarter={exportQuarter}
      exportYear={exportYear}
      handleRequestRebateType={handleRequestRebateType}
      requestRebateType={requestRebateType}
      quarterList={formattedQuarters}
    />

  return (
    <div>
      <CustomModal
        showModal={openModal}
        closeModal={closeModal}
        modalTitle={"Export Product Rebate Documents"}
        children={child}
        isCancelButton={true}
        isSubmitButton={false}
        isExportButton={true}
        isSubmitText={"Export"}
        onSubmit={onExportProductRebates}
        isLoading={rebatesModalSpinner}
      />
    </div>
  );
};
