import {GET_PRODUCT_REBATES_QUARTER, PRODUCT_REBATES_QUARTER_ERROR} from "../../../constants";


export const getProductRebatesQuarters = async () => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_PRODUCT_REBATES_QUARTER}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw PRODUCT_REBATES_QUARTER_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw PRODUCT_REBATES_QUARTER_ERROR;
    }
}

