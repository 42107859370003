import axios from 'axios';
import { URL_LOGIN } from '../constants/api';

class AuthenticationService {
  signIn = (data) => {
    return axios
      .post(URL_LOGIN, data)
      .then((response) => {
        if (response.data.data && response.data.data.user) {
          localStorage.setItem('user', JSON.stringify(response.data.data.user));
        }
        return response.data;
      })
      .catch((err) => {
        if (err.response) {
          throw err.response.data;
        } else {
          throw err;
        }
      });
  };

  signOut() {
    localStorage.removeItem('user');
    sessionStorage.removeItem('user');
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem('user') || '{}');
  }
}

export default new AuthenticationService();
