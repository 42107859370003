import React,{FC} from 'react'
import { UserDirectoryFilter } from './UserDirectoryFilter'
import UserDirectoryTitle from './UserDirectoryTitle'
import { useMachine } from '@xstate/react';
import serviceInterceptor from '../../../services/ServiceInterceptor';
import { paginationMachine } from '../../../machines';
import { CommunityUserList } from './Users/CommunityUserList';


const UserDirectory : FC<any> = () => {

    const [state, send] = useMachine(paginationMachine, {
        context: {
          additionalQueryStringValues: {
            orderBy: '',
            sortOrder: 'desc',
          },
          pageSize: 10,
          pageNumber:1
        },
        services: {
          recordLoader: (context) => {
            const query = new URLSearchParams({
              ...context.additionalQueryStringValues,
              pageNumber: context.pageNumber.toString(),
              pageSize: context.pageSize.toString(),
            });
            return serviceInterceptor.get(`/users/memberusers?${query.toString()}`)
          },
        },
      });

  return (
    <div>
    <UserDirectoryTitle />

    <div className="container">
      <UserDirectoryFilter send={send}/>
      <div>
        <CommunityUserList
          membersList={state.context.records}
          state={state}
          send={send}
        />
      </div>
    </div>
  </div>
  )
}

export default UserDirectory