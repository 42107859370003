import {FC, useEffect} from "react";
import Grid from "@material-ui/core/Grid";
import InputBase from "@material-ui/core/InputBase";
import {MemberActiveEarningsActions} from "./MemberActiveEarningsActions";
import {MemberDropdown, QuarterDropdown, YearDropdown} from "./dropdowns";

export const MemberActiveEarningsFilter: FC<{
  searchText: string;
  setSearchText: (searchText: string) => void;
  getEarnedRebateQuarters: () => void;
  state: any;
  send: any;
  updateMemberAdminRebatesSelectedMember: () => void;
  updateMemberAdminRebatesQuarter: () => void;
  updateMemberAdminRebatesYear: () => void;
  handleMemberAdminExportQuarter: () => void;
  handleMemberAdminExportYear: () => void;
  onExportMemberAdminRebates: any;
  rebates: {
    rebatesModalSpinner: boolean;
  };
  memberAdminRebatesExportModal: any;
  memberAdminRebates: {
    isMemberAdminExport: boolean;
    exportQuarter: any;
    exportYear: any;
    memberProductList: any;
    memberOptionSpinner: any;
    memberOptionList: any;
    selectedMember: any;
  };
}> = ({
  searchText,
  setSearchText,
  getEarnedRebateQuarters,
  state,
  send,
  memberAdminRebates,
  updateMemberAdminRebatesSelectedMember,
  updateMemberAdminRebatesQuarter,
  updateMemberAdminRebatesYear,
  handleMemberAdminExportQuarter,
  handleMemberAdminExportYear,
  onExportMemberAdminRebates,
  rebates,
  memberAdminRebatesExportModal,
}) => {

  useEffect(() => {
    getEarnedRebateQuarters();
  }, []);

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
    send({
      destructive: true,
      key: 'searchString',
      type: 'addQueryStringValue',
      value: event.target.value,
    });
  };

  return (
    <div className="rebates-filter">
      <Grid container spacing={3} alignItems="center">
        <Grid
          xs={12}
          lg={6}
          alignItems="center"
          justify="flex-start"
          item
          alignContent="center"
        >
          <div className="header-filter-box-left member-admin-rebates">
            <MemberDropdown
              memberAdminRebates={memberAdminRebates}
              updateMemberAdminRebatesSelectedMember={updateMemberAdminRebatesSelectedMember}
              setMemberId={(memberId: number | string) => {
                const valuePairs: { value: string; key: string }[] = [];
                valuePairs.push({ value: memberId.toString(), key: 'memberId' });
                send({
                  type: 'addQueryStringValues',
                  valuePairs,
                });
              }}
            />
            <QuarterDropdown
              memberAdminRebates={memberAdminRebates}
              updateMemberAdminRebatesQuarter={updateMemberAdminRebatesQuarter}
              setQuarter={(quarter: number | string) => {
                send({
                  type: 'addQueryStringValue',
                  key: 'quarter',
                  value: quarter,
                });
              }}
            />
            <YearDropdown
              memberAdminRebates={memberAdminRebates}
              updateMemberAdminRebatesYear={updateMemberAdminRebatesYear}
              setYear={(year: number | string) => {
                send({
                  type: 'addQueryStringValue',
                  key: 'year',
                  value: year,
                });
              }}
            />
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          lg={6}
          direction="row"
          justify="flex-end"
          alignItems="center"
        >
          <div className="header-filter-box-search-add">
            <div className="header-filter-box-search">
              <div className="page-search-box">
                <i className="fas fa-search"></i>
                <InputBase
                  placeholder="Search"
                  value={searchText && searchText.toString()}
                  onChange={(event) => handleSearch(event)}
                />
              </div>
            </div>

            <MemberActiveEarningsActions
              handleMemberAdminExportQuarter={handleMemberAdminExportQuarter}
              handleMemberAdminExportYear={handleMemberAdminExportYear}
              onExportMemberAdminRebates={onExportMemberAdminRebates}
              rebates={rebates}
              memberAdminRebatesExportModal={memberAdminRebatesExportModal}
              memberAdminRebates={memberAdminRebates}
              memberProductList={state.context.records}
            />
          </div>
        </Grid>
      </Grid>
    </div >
  );
};
