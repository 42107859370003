import { Grid, GridSize } from '@mui/material';
import { FC } from 'react';
import { centsToFormattedDollars } from '../../utils';
import AvailableRebates from '../../images/money-red.svg';
import PendingRebates from '../../images/money-orange.svg';
import AcquiredRebates from '../../images/money-green.svg';
import TotalRebates from '../../images/money-gray.svg';

export const RebatesTotalsOverview: FC<{
  claimedAmount: number;
  gridSize?: GridSize;
  grandTotalLabel?: string;
  pendingAmount: number;
  unclaimedAmount: number;
}> = ({
  claimedAmount,
  gridSize = 5,
  grandTotalLabel = 'Total Member Rebates',
  pendingAmount,
  unclaimedAmount,
}) => (
  <Grid item xs={12} lg={gridSize}>
    <div className="rebates-overview-box rebates-overview-total">
      <div className="rebates-overview-box-icon">
        <div className="rebate-icon">
          <img src={TotalRebates} alt="buyright" title="buyright" />
        </div>
      </div>
      <div className="rebates-overview-content">
        <div className="rebates-overview-money">
          {centsToFormattedDollars(
            claimedAmount + unclaimedAmount + pendingAmount,
          )}
        </div>
        <div className="rebates-overview-money-text">{grandTotalLabel}</div>
      </div>
    </div>

    <div className="rebates-overview-box rebates-overview-claimed">
      <div className="rebates-overview-box-icon">
        <div className="rebate-icon">
          <img src={AcquiredRebates} alt="buyright" title="buyright" />
        </div>
      </div>
      <div className="rebates-overview-content">
        <div className="rebates-overview-money">
          {centsToFormattedDollars(claimedAmount)}
        </div>
        <div className="rebates-overview-money-text">Claimed Rebates</div>
      </div>
    </div>

    <div className="rebates-overview-box rebates-overview-pending">
      <div className="rebates-overview-box-icon">
        <div className="rebate-icon">
          <img src={PendingRebates} alt="buyright" title="buyright" />
        </div>
      </div>
      <div className="rebates-overview-content">
        <div className="rebates-overview-money">
          {centsToFormattedDollars(pendingAmount)}
        </div>
        <div className="rebates-overview-money-text">Pending Rebates</div>
      </div>
    </div>

    <div className="rebates-overview-box rebates-overview-unclaimed">
      <div className="rebates-overview-box-icon">
        <div className="rebate-icon">
          <img src={AvailableRebates} alt="buyright" title="buyright" />
        </div>
      </div>
      <div className="rebates-overview-content">
        <div className="rebates-overview-money">
          {centsToFormattedDollars(unclaimedAmount)}
        </div>
        <div className="rebates-overview-money-text">Unclaimed Rebates</div>
      </div>
    </div>
  </Grid>
);

export default RebatesTotalsOverview;
