import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { createMachine, sendParent } from 'xstate';
import { MemberFundingAccount, MemberFundingAccounts } from '../../../../common';
import serverApiV2Instance from '../../../../services/serverApiV2Instance';
import { Encrypt, Decrypt } from '../../../../utils/cryptoFunction'
export const fundingSourceMachine = () => createMachine(
  {
    context: {
      memberFundingAccounts: [],
      fundingSourceId: null,
      userId: null,
      accountNumber: null,
      accountNumberConfirmation: null,
      bankAccountType: 'checking',
      accountNickName: null,
      routingNumber: null,
      routingNumberConfirmation: null,
      agreedDwollaTerms: false,
      isFormEdit: false,
    },
    schema: {
      context: {} as {
        memberFundingAccounts: MemberFundingAccounts;
        fundingSourceId: null | string;
        userId: null | string | number;
        accountNumber: null | string;
        accountNumberConfirmation: null | string;
        bankAccountType: null | string;
        accountNickName: null | string;
        routingNumber: null | string;
        routingNumberConfirmation: null | string;
        agreedDwollaTerms: boolean;
        isFormEdit: boolean;
      },
      events: {} as
        | { type: 'done.invoke.loadMemberFundingAccounts'; data: any }
        | { type: 'updateSelectedFundingAccount'; item: MemberFundingAccount }
        | { type: 'updateAccountNickName'; accountNickName: string }
        | { type: 'updateBankAccountType'; bankAccountType: string }
        | { type: 'updateRoutingNumber'; routingNumber: string }
        | { type: 'updateRoutingNumberConfirmation'; routingNumberConfirmation: string }
        | { type: 'updateAccountNumber'; accountNumber: string }
        | { type: 'updateAccountNumberConfirmation'; accountNumberConfirmation: string }
        | { type: 'updateAgreedDwollaTerms'; agreedDwollaTerms: boolean }
        | { type: 'removeFundingSourceAccount'; item: MemberFundingAccount }
        | { type: 'clearSelectedFundingAccount' }
        | { type: 'refresh' }
        | { type: 'submit' },
      services: {} as {
        loadMemberFundingAccounts: {
          data: AxiosResponse<{
            data?: {
              id: number | null;
              username: null | string;
              firstName: null | string;
              lastName: null | string;
              email: null | string;
              phoneNumber: null | string;
              avatar: null | string;
              isBRAdmin: boolean;
              createdAt: string;
              memberIds: number[];
              memberUserRoles: string[];
              financialInfo: {
                customer: {
                  id: string;
                  firstName: null | string;
                  lastName: null | string;
                  email: null | string;
                  type: null | string;
                  status: null | string;
                  created: null | string;
                }
              };
              fundingSources: {
                id: string;
                status: null | string;
                type: null | string;
                bankAccountType: null | string;
                name: null | string;
                created: string;
                removed: boolean;
                channels: string[];
                bankName: string;
                fingerprint: string;
              }[];
            };
          }>;
        };
      },
    },
    tsTypes: {} as import('./fundingSourceMachine.typegen').Typegen0,
    id: 'fundingSource',
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          id: 'loadMemberFundingAccounts',
          src: 'loadMemberFundingAccounts',
          onDone: {
            actions: 'assignFundTransferAccounts',
            target: 'idle',
          },
          onError: 'error',
        },
        tags: ['loading'],
      },
      idle: {
        on: {
          refresh: 'loading',
          updateSelectedFundingAccount: {
            actions: 'assignSelectedFundingAccount'
          },
          updateAccountNickName: {
            actions: 'assignAccountNickName'
          },
          updateBankAccountType: {
            actions: 'assignBankAccountType'
          },
          updateRoutingNumber: {
            actions: 'assignRoutingNumber'
          },
          updateRoutingNumberConfirmation: {
            actions: 'assignRoutingNumberConfirmation'
          },
          updateAccountNumber: {
            actions: 'assignAccountNumber'
          },
          updateAccountNumberConfirmation: {
            actions: 'assignAccountNumberConfirmation'
          },
          updateAgreedDwollaTerms: {
            actions: 'assignAgreedDwollaTerms'
          },
          removeFundingSourceAccount: {
            actions: 'assignFundingAccountToRemove',
            target: 'deletingFundingSource',
          },
          clearSelectedFundingAccount: {
            actions: 'resetContext',
            target: 'idle',
          },
          submit: [
            {
              actions: () => toast.error("Account nick name is required."),
              description: 'Account nick name is required.',
              cond: (context: any) => !context.accountNickName,
              target: 'idle',
            },
            {
              actions: () => toast.error("Routing number is required."),
              description: 'Routing number is required.',
              cond: (context: any) => !context.routingNumber,
              target: 'idle',
            },
            {
              actions: () => toast.error("Routing number should only contain numbers and must be 9 digits."),
              description: 'Routing number should only contain numbers and must be 9 digits.',
              cond: (context: any) => {
                const numberOnly = new RegExp("^\\d{9}$");
                return !numberOnly.test(context.routingNumber);
              },
              target: 'idle',
            },
            {
              actions: () => toast.error("Confirm routing number is required."),
              description: 'Confirm routing number is required.',
              cond: (context: any) => !context.routingNumberConfirmation,
              target: 'idle',
            },
            {
              actions: () => toast.error("Confirm routing number should match with routing number."),
              description: "Verify routing number should match with routing number.",
              cond: (context: any) => context.routingNumberConfirmation !== context.routingNumber,
              target: 'idle',
            },
            {
              actions: () => toast.error("Account number is required."),
              description: 'Account number is required.',
              cond: (context: any) => !context.accountNumber,
              target: 'idle',
            },
            {
              actions: () => toast.error("Account number should only contain numbers and must be atleast 4 digits."),
              description: 'Account number should only contain numbers and must be atleast 4 digits.',
              cond: (context: any) => {
                const numberOnly = new RegExp(/^[0-9]{4,}$/);
                return !numberOnly.test(context.accountNumber);
              },
              target: 'idle',
            },
            {
              actions: () => toast.error("Confirm account number is required."),
              description: 'Confirm account number is required.',
              cond: (context: any) => !context.accountNumberConfirmation,
              target: 'idle',
            },
            {
              actions: () => toast.error("Confirm account number should match with account number."),
              description: "Confirm account number should match with account number.",
              cond: (context: any) => context.accountNumberConfirmation !== context.accountNumber,
              target: 'idle',
            },
            {
              actions: () => toast.error("Bank account type is required."),
              description: 'Bank account type is required.',
              cond: (context: any) => !context.bankAccountType,
              target: 'idle',
            },
            {
              target: 'submittingForm',
            },
          ],
        },
      },
      submittingForm: {
        invoke: {
          src: 'submitFundingSourceRequest',
          id: 'Submit funding source request',
          onDone: {
            actions: (context, event) => {
              console.log("success event : ", event);
              toast.success(`Funding account ${context.isFormEdit ? 'updated' : 'added'} successfully.`);
            },
            target: '#fundingSource.loading',
          },
          onError: {
            actions: (_, event) => {
              type errorObjType = { code: string; message: string; }
              function isErrorObjType(o: any): o is errorObjType {
                return "code" in o && "message" in o
              }
              const errorObjectType = typeof event?.data?.response?.data?.error?.message;
              if (errorObjectType === 'object') {
                if (event?.data?.response?.data?.error?.message?.message.toString().length > 0) {
                  toast.error(event?.data?.response?.data?.error?.message?.message.toString());
                }
              }
              else if (errorObjectType === 'string') {
                const messageObj = JSON.parse(event?.data?.response?.data?.error?.message);
                if (isErrorObjType(messageObj)) {
                  toast.error(messageObj.message ?? 'An error occured while submitting the form.');
                }
              }
              else {
                toast.error("An error occured while submitting the form.");
              }
            },
            target: '#fundingSource.idle',
          },
        },
        tags: ['loading'],
      },
      deletingFundingSource: {
        invoke: {
          src: 'deleteFundingSource',
          id: 'Delete funding source.',
          onDone: {
            actions: () => toast.success(`Funding account deleted successfully.`),
            target: '#fundingSource.loading',
          },
          onError: {
            actions: (_, event) => {
              console.error("Delete funding source error event : ", event);
              toast.error("An error occured while deleting the funding account.");
            },
            target: '#fundingSource.idle',
          },
        },
        tags: ['loading'],
      },
      error: {},

    },
  },
  {
    actions: {
      assignFundTransferAccounts: assign((context, event) => {
        const financialInfoResponse: any = event?.data?.data?.financialInfo || {};
        const { customer, fundingSources } = financialInfoResponse;
        let memberFundingAccountList: MemberFundingAccounts = [];
        fundingSources && fundingSources.length > 0 && fundingSources.map((item: any) => {
          if (!item.removed) {
            const fundingSourceId = Decrypt(item.id);
            const fundingSourceName = Decrypt(item.name);
            const currentFundingAccount: MemberFundingAccount = {
              fundingSourceId: fundingSourceId.toString(),
              accountNickName: fundingSourceName.toString(),
              bankAccountType: item.bankAccountType.toString(),
              accountNumber: '',
              accountNumberConfirmation: '',
              userId: context.userId?.toString() ?? null,
              routingNumber: '',
              routingNumberConfirmation: '',
              agreedDwollaTerms: false,
            }
            memberFundingAccountList.push(currentFundingAccount);
          }
        });

        context.memberFundingAccounts = memberFundingAccountList;
        context.fundingSourceId = null;
        context.bankAccountType = 'checking';
        context.accountNumber = '';
        context.accountNumberConfirmation = '';
        context.accountNickName = '';
        context.routingNumber = '';
        context.routingNumberConfirmation = '';
        context.agreedDwollaTerms = false;
        context.isFormEdit = false;
      }),
      assignSelectedFundingAccount: assign((context, event) => {
        if (event.item) {
          const accountNickName = event.item?.accountNickName && event.item?.accountNickName.toString().split('<')[0].trim();
          context.fundingSourceId = event.item?.fundingSourceId ?? '';
          context.bankAccountType = event.item?.bankAccountType ?? 'checking';
          context.accountNumber = event.item?.accountNumber ?? '';
          context.accountNumberConfirmation = event.item?.accountNumber ?? '';
          context.accountNickName = accountNickName ?? event.item?.accountNickName ?? '';
          context.routingNumber = event.item?.routingNumber ?? '';
          context.routingNumberConfirmation = event.item?.routingNumber ?? '';
          context.agreedDwollaTerms = false;
          context.isFormEdit = true;
        }
      }),
      assignFundingAccountToRemove: assign((context, event) => {
        if (event.item) {
          context.fundingSourceId = event.item?.fundingSourceId ?? '';
        }
      }),
      assignAccountNickName: assign((context, event) => {
        context.accountNickName = event.accountNickName;
      }),
      assignBankAccountType: assign((context, event) => {
        context.bankAccountType = event.bankAccountType;
      }),
      assignRoutingNumber: assign((context, event) => {
        context.routingNumber = event.routingNumber;
      }),
      assignRoutingNumberConfirmation: assign((context, event) => {
        context.routingNumberConfirmation = event.routingNumberConfirmation;
      }),
      assignAccountNumber: assign((context, event) => {
        context.accountNumber = event.accountNumber;
      }),
      assignAccountNumberConfirmation: assign((context, event) => {
        context.accountNumberConfirmation = event.accountNumberConfirmation;
      }),
      assignAgreedDwollaTerms: assign((context, event) => {
        context.agreedDwollaTerms = event.agreedDwollaTerms;
      }),
      resetContext: assign((context, event) => {
        context.fundingSourceId = null;
        context.bankAccountType = 'checking';
        context.accountNumber = '';
        context.accountNumberConfirmation = '';
        context.accountNickName = '';
        context.routingNumber = '';
        context.routingNumberConfirmation = '';
        context.agreedDwollaTerms = false;
        context.isFormEdit = false;
      }),
    },
    guards: {
    },
    services: {
      loadMemberFundingAccounts: (context) => {
        return serverApiV2Instance.get(
          `/users/${context.userId?.toString()}?financialInfo=true`);
      },
      submitFundingSourceRequest: (context) => {
        const newAccountName = context.accountNickName && context.accountNickName.toString().split('<')[0].trim();
        const customAccountNickName = (context.accountNickName && context.accountNumber) ?
          `${newAccountName} <${context.accountNumber.toString().slice(-4)}>` : '';
        const encryptedAccountNo = Encrypt(context.accountNumber?.toString());
        const encryptedRoutingNo = Encrypt(context.routingNumber?.toString());

        const query = new URLSearchParams({
          accountNumber: encryptedAccountNo,
          accountNumberConfirmation: encryptedAccountNo,
          bankAccountType: context.bankAccountType ? context.bankAccountType?.toString() : '',
          name: customAccountNickName !== '' ? customAccountNickName : (context.accountNickName ? context.accountNickName?.toString() : ''),
          routingNumber: encryptedRoutingNo,
          routingNumberConfirmation: encryptedRoutingNo,
        });

        const encryptedFundingSourceId = encodeURIComponent(Encrypt(context?.fundingSourceId?.toString()));

        if (context.isFormEdit) {
          const response = serverApiV2Instance.patch(
            `/users/${context.userId?.toString()}/funding-sources/${encryptedFundingSourceId}?${query.toString()}`);
          return response;
        }
        else {
          const response = serverApiV2Instance.post(
            `/users/${context.userId?.toString()}/funding-sources?${query.toString()}`);
          return response;
        }
      },
      deleteFundingSource: (context) => {
        const encryptedFundingSourceId = encodeURIComponent(Encrypt(context?.fundingSourceId?.toString()));
        return serverApiV2Instance.delete(
          `/users/${context.userId?.toString()}/funding-sources/${encryptedFundingSourceId}`);
      },
    },
  },
);

export type FundingSourceMachine = typeof fundingSourceMachine;

export default fundingSourceMachine;
