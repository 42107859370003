import moment from 'moment';
import {
  UPDATE_VENDOR_KPI_LIST,
  UPDATE_SELECTED_VENDOR_KPI,
  UPDATE_DELETE_VENDOR_FLAG,
  CLEAR_VENDOR_KPI_LIST,
  VendorDeleteKpiError,
  VendorKPIDeleteSuccess,
  UPDATE_VENDOR_KPI_ERROR,
  FetchError,
  UPDATE_VENDOR_KPI_TYPES_LIST,
  UPDATE_VENDOR_KPI_TYPES_ERROR,
  UPDATE_SELECTED_MEMBER_KPI,
  UPDATE_KPI_OVERVIEW_YEARS,
  UPDATE_SELECTED_KPI_YEAR,
  UPDATE_SELECTED_KPI_QUARTER,
  UPDATE_VENDOR_KPI_MEMBER_DATA,
  SHOW_KPI_SELECT_LOADER,
  HIDE_KPI_SELECT_LOADER,
  CLEAR_CHART_MODAL,
  SHOW_KPI_GRAPH_SPINNER,
  HIDE_KPI_GRAPH_SPINNER,
  UPDATE_SELECTED_KPI_YEAR_ERROR,
  UPDATE_SELECTED_KPI_QUARTER_ERROR,
  VendorKPIYearError,
  VendorKPITypesError,
  VendorKPIError,
  VendorKPIMemberError,
  SHOW_VENDOR_KPI_FORM_SPINNER,
} from '../../constants';
import {
  getVendorKPIList,
  getVendorKPITypesList,
  deleteVendorKPI,
  getKpiOverviewYears,
  getVendorMemberKPIData,
} from '../../api/vendors';
import { quarterOfTheYear } from '../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const clearVendorKPIList = () => ({
  type: CLEAR_VENDOR_KPI_LIST,
});

export const getVendorKPIData = () => async (dispatch, getState) => {
  const { vendor } = getState();
  const { vendorID } = vendor;
  //show spinner
  try {
    const vendorKpiList = await getVendorKPIList(vendorID);
    dispatch({
      type: UPDATE_VENDOR_KPI_LIST,
      vendorKpiList: vendorKpiList.data,
    });
    //hide spinner
  } catch (error) {
    //hide spinner
    if (error === UPDATE_VENDOR_KPI_ERROR) {
      toast.error(VendorKPIError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const updateDeleteVendorKPI = (kpiId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_VENDOR_KPI,
    slectedkpiID: kpiId,
  });
  dispatch({
    type: UPDATE_DELETE_VENDOR_FLAG,
    isDeleteUser: true,
  });
};

export const handleDeleteVendorKPI = () => async (dispatch, getState) => {
  const { vendor } = getState();
  const { vendorID, slectedkpiID } = vendor;
  try {
    const response = await deleteVendorKPI({
      slectedkpiID,
    });
    const vendorKpiList = await getVendorKPIList(vendorID);
    dispatch({
      type: UPDATE_VENDOR_KPI_LIST,
      vendorKpiList: vendorKpiList.data,
    });
    dispatch({
      type: UPDATE_SELECTED_VENDOR_KPI,
      slectedkpiID: null,
    });
    dispatch({
      type: UPDATE_DELETE_VENDOR_FLAG,
      isDeleteUser: false,
    });
    const { success } = response;
    success ? toast.success(VendorKPIDeleteSuccess) : null;
  } catch (error) {
    dispatch({
      type: UPDATE_SELECTED_VENDOR_KPI,
      slectedkpiID: null,
    });
    dispatch({
      type: UPDATE_DELETE_VENDOR_FLAG,
      isDeleteUser: false,
    });
    //hide spinner
    if (error) {
      toast.error(VendorDeleteKpiError);
    }
  }
};

export const getVendorKPITypesData = () => async (dispatch, getState) => {
  const { vendor } = getState();
  const { vendorID } = vendor;
  //show spinner
  try {
    const vendorKpiTypesList = await getVendorKPITypesList(vendorID);
    dispatch({
      type: UPDATE_VENDOR_KPI_TYPES_LIST,
      vendorKpiTypesList: vendorKpiTypesList.data,
    });
    //hide spinner
  } catch (error) {
    //hide spinner
    if (error === UPDATE_VENDOR_KPI_TYPES_ERROR) {
      toast.error(VendorKPITypesError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const showKpiSelectLoader = () => async (dispatch) => {
  dispatch({
    type: SHOW_KPI_SELECT_LOADER,
  });
};

export const hideKpiSelectLoader = () => async (dispatch) => {
  dispatch({
    type: HIDE_KPI_SELECT_LOADER,
  });
};

export const showKpiGraphSpinner = () => async (dispatch) => {
  dispatch({
    type: SHOW_KPI_GRAPH_SPINNER,
  });
};

export const hideKpiGraphSpinner = () => async (dispatch) => {
  dispatch({
    type: HIDE_KPI_GRAPH_SPINNER,
  });
};

export const clearChartModal = () => async (dispatch) => {
  dispatch({
    type: CLEAR_CHART_MODAL,
  });
};

export const updateSelectedKPIMember =
  (selectedMemberId) => async (dispatch, getState) => {
    const currentYear = moment().format('YYYY');
    const currentDate = new Date();
    const currentQuarter = quarterOfTheYear(currentDate);
    dispatch({
      type: UPDATE_SELECTED_MEMBER_KPI,
      selectedKPIMemberId: selectedMemberId,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_QUARTER,
      kpiQuarter: currentQuarter,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_YEAR,
      kpiYear: currentYear,
    });
    const { vendorKPIModal } = getState();
    const { selectedKPIMemberId, kpiYear, kpiQuarter } = vendorKPIModal;
    dispatch(showKpiGraphSpinner());
    try {
      const response = await getVendorMemberKPIData({
        memberID: selectedKPIMemberId,
        kpiYear,
        kpiQuarter,
      });
      const { success, data } = response;
      if (success) {
        dispatch({
          type: UPDATE_VENDOR_KPI_MEMBER_DATA,
          kpiMemberData: data,
        });
      }
      dispatch(hideKpiGraphSpinner());
    } catch (error) {
      dispatch(hideKpiGraphSpinner());
      if (error) {
        toast.error(VendorKPIMemberError);
      }
    }
  };

export const getKpiYears = (selectedKPIMemberId) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_MEMBER_KPI,
    selectedKPIMemberId,
  });
  dispatch(showKpiSelectLoader());
  try {
    const response = await getKpiOverviewYears({
      memberID: selectedKPIMemberId,
    });
    const { success, data } = response;
    if (success) {
      dispatch({
        type: UPDATE_KPI_OVERVIEW_YEARS,
        kpiYearsList: data,
      });
    }
    dispatch(hideKpiSelectLoader());
  } catch (error) {
    dispatch(hideKpiSelectLoader());
    if (error) {
      toast.error(VendorKPIYearError);
    }
  }
};

export const handleSelectKpiYear = (kpiYear) => async (dispatch, getState) => {
  if (kpiYear === 'NA') {
    dispatch({
      type: UPDATE_SELECTED_KPI_YEAR_ERROR,
      kpiYearError: true,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_YEAR,
      kpiYear: null,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_QUARTER,
      kpiQuarter: null,
    });
  } else {
    dispatch({
      type: UPDATE_SELECTED_KPI_QUARTER,
      kpiQuarter: null,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_YEAR,
      kpiYear,
    });
    dispatch({
      type: UPDATE_SELECTED_KPI_YEAR_ERROR,
      kpiYearError: false,
    });
  }
};

export const handleSelectkpiQuarter =
  (kpiQuarter) => async (dispatch, getState) => {
    if (kpiQuarter === 'NA') {
      dispatch({
        type: UPDATE_SELECTED_KPI_QUARTER_ERROR,
        kpiQuarterError: true,
      });
      dispatch({
        type: UPDATE_SELECTED_KPI_QUARTER,
        kpiQuarter: null,
      });
    } else {
      dispatch({
        type: UPDATE_VENDOR_KPI_MEMBER_DATA,
        kpiMemberData: [],
      });
      dispatch({
        type: UPDATE_SELECTED_KPI_QUARTER,
        kpiQuarter,
      });
      dispatch({
        type: UPDATE_SELECTED_KPI_QUARTER_ERROR,
        kpiQuarterError: false,
      });
      const { vendorKPIModal } = getState();
      const { selectedKPIMemberId, kpiYear } = vendorKPIModal;
      dispatch(showKpiGraphSpinner());
      try {
        const response = await getVendorMemberKPIData({
          memberID: selectedKPIMemberId,
          kpiYear,
          kpiQuarter,
        });
        const { success, data } = response;
        if (success) {
          dispatch({
            type: UPDATE_VENDOR_KPI_MEMBER_DATA,
            kpiMemberData: data,
          });
        }
        dispatch(hideKpiGraphSpinner());
      } catch (error) {
        dispatch(hideKpiGraphSpinner());
        if (error) {
          toast.error(VendorKPIMemberError);
        }
      }
    }
  };

export const getKPIYearQuarter = (memberID) => async (dispatch, getState) => {
  dispatch({
    type: SHOW_VENDOR_KPI_FORM_SPINNER,
    spinner: true,
  });
  dispatch(showKpiGraphSpinner());
  try {
    dispatch({
      type: SHOW_VENDOR_KPI_FORM_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: SHOW_VENDOR_KPI_FORM_SPINNER,
      spinner: false,
    });
      toast.error(FetchError);
  }
};
