import React from "react";
import {CustomModal} from "../../../../../components/CustomModal";
import {EditMemberRebateDocuments} from "./EditMemberRebateDocuments";

export const EditMemberRebates = (props) => {

  const {
    handleMemberEditRebates,
    memberRebates,
    handleMemberRebatesFileOne,
    handleMemberRebatesFileTwo,
    handleMemberRebatesFileThree,
    onMemberRebatesFileOneRemove,
    onMemberRebatesFileTwoRemove,
    onMemberRebatesFileThreeRemove,
    onUpdateMemberRebates,
    rebates: {
      rebatesModalSpinner
    },
  } = props;

  const {fileOneContent, fileTwoContent, fileThreeContent, isEdit} = memberRebates;

  const isButtonDisabled = (fileOneContent === null || fileTwoContent === null || fileThreeContent === null);


  const closeModal = () => {
    handleMemberEditRebates(false);
    onMemberRebatesFileOneRemove()
    onMemberRebatesFileTwoRemove()
    onMemberRebatesFileThreeRemove()
  };

  const child =
    <EditMemberRebateDocuments
      memberRebates={memberRebates}
      handleMemberRebatesFileOne={handleMemberRebatesFileOne}
      handleMemberRebatesFileTwo={handleMemberRebatesFileTwo}
      handleMemberRebatesFileThree={handleMemberRebatesFileThree}
      onMemberRebatesFileOneRemove={onMemberRebatesFileOneRemove}
      onMemberRebatesFileTwoRemove={onMemberRebatesFileTwoRemove}
      onMemberRebatesFileThreeRemove={onMemberRebatesFileThreeRemove}
    />;

  return (
    <div>
      <CustomModal
        showModal={isEdit}
        closeModal={closeModal}
        modalTitle={"Update Rebate"}
        children={child}
        isCancelButton={true}
        isSubmitButton={true}
        isSubmitText={"Update"}
        onSubmit={onUpdateMemberRebates}
        isLoading={rebatesModalSpinner}
        isButtonDisabled={isButtonDisabled}
      />
    </div>
  );
};
