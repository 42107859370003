import moment from "moment";
import {
    UPDATE_REBATES_MEMBER_OPTION_SPINNER,
    UPDATE_SELECTED_REBATES_MEMBER,
    UPDATE_REBATES_MEMBER_QUARTER_SPINNER,
    UPDATE_REBATES_MEMBER_YEAR_SPINNER,
    UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST,
    UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER,
    UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR,
    UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER,
    UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
    UPDATE_REBATES_MEMBER_EXPORT_MODAL,
    UPDATE_REBATES_MEMBER_EXPORT_QUARTER,
    UPDATE_REBATES_MEMBER_EXPORT_YEAR,
    UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST,
    UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST
} from "../../constants";

import {quarterOfTheYear} from "../../utils";

const currentYear = moment().format('YYYY');
const currentDate = new Date();
const currentQuarter = moment().quarter();

const initialState = {
    memberOptionSpinner: false,
    productListSpinner: false,
    selectedMember: null,
    quarter: currentQuarter,
    year: currentYear,
    memberOptionList: [],
    memberProductList: [],
    isMemberAdminExport: false,
    exportQuarter: currentQuarter,
    exportYear: currentYear,
    memberQuartersList: [],
    memberYearsList: [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_REBATES_MEMBER_OPTION_SPINNER:
            return {
                ...state,
                memberOptionSpinner: action.memberOptionSpinner,
            };
        case UPDATE_REBATES_MEMBER_QUARTER_SPINNER:
            return {
                ...state,
                memberAdminQuarterSpinner: action.memberAdminQuarterSpinner,
            };
        case UPDATE_REBATES_MEMBER_YEAR_SPINNER:
            return {
                ...state,
                memberAdminRebatesYearSpinner: action.memberAdminRebatesYearSpinner,
            };
        case UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER:
            return {
                ...state,
                productListSpinner: action.productListSpinner,
            };
        case UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER:
            return {
                ...state,
                quarter: action.quarter,
            };
        case UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR:
            return {
                ...state,
                year: action.year,
            };
        case UPDATE_SELECTED_REBATES_MEMBER:
            return {
                ...state,
                selectedMember: action.selectedMember,
            };
        case UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST:
            return {
                ...state,
                memberOptionList: action.memberOptionList,
            };
        case UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST:
            return {
                ...state,
                memberProductList: action.memberProductList,
            };
        case UPDATE_REBATES_MEMBER_EXPORT_MODAL:
            return {
                ...state,
                isMemberAdminExport: action.isMemberAdminExport,
            };
        case UPDATE_REBATES_MEMBER_EXPORT_QUARTER:
            return {
                ...state,
                exportQuarter: action.exportQuarter,
            };
        case UPDATE_REBATES_MEMBER_EXPORT_YEAR:
            return {
                ...state,
                exportYear: action.exportYear,
            };
        case UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST:
            return {
                ...state,
                memberQuartersList: action.memberQuartersList,
            };
        case UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST:
            return {
                ...state,
                memberYearsList: action.memberYearsList,
            };
        default:
            return state;
    }
};
