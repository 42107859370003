import { FC, useEffect } from 'react';
import '../../../css/fonts.css';
import '../../../css/all.min.css';
import '../../../css/style.css';
import './members-details.scss';
import '../../../scss/styles.scss';
import { MemberBreadCrumb, MemberOverview, MembersTab } from './components';
import { useLocation, useParams } from 'react-router-dom';

const tabSelection = ['USERS', 'VENDORS', 'LOCATIONS', 'USAGE'];

export const MemberDetailComponent: FC<{
  viewMember: any;
  clearMemberDetails: () => void;
  members: any;
  fetchAssignedMemberUsers :any;
}> = (props) => {
  const { viewMember, clearMemberDetails, members, fetchAssignedMemberUsers } = props;
  const { selectedMember } = members;
  const { id } = useParams();
  const location: any = useLocation();

  const openTab = location.state && location.state.openTab ? location.state.openTab : 'USERS';
  const tabFoundIndex = tabSelection.indexOf(openTab ||'');
  const tabIndex = tabFoundIndex < 0 ? 0 : tabFoundIndex;

  useEffect(() => {
    viewMember(id);

    return () => {
      clearMemberDetails();
    };
  }, []);
  useEffect(() => {
    fetchAssignedMemberUsers(parseInt(id ?? "0"));
  },[id])

  return (
    <div className="relative-wrapper">
      <div>
        <MemberBreadCrumb
          deleteMember={function (): void {
            throw new Error('Function not implemented.');
          }}
          updateMemberInActive={function (): void {
            throw new Error('Function not implemented.');
          }}
          {...props}
        />
        <MemberOverview selectedMember={selectedMember} />
        <div className="container">
          {selectedMember ? (
            <MembersTab
              {...props}
              memberDetail={selectedMember}
              navigateTab={tabIndex}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
