import { useState, useEffect, useRef, FC } from 'react';

export const AnimateCount: FC<{ amount: number }> = ({ amount }) => {
  const [count, setCount] = useState(0);
  const mounted = useRef<boolean>();

  useEffect(() => {
    if (!mounted.current) {
      setCount(0);
      mounted.current = true;
    } else {
      if (amount > count) {
        const increment = getIncrement();
        const newCount = Math.floor(count + increment);
        setTimeout(() => {
          setCount(newCount);
        }, 300 / (amount - count));
      }
    }
  });

  const getIncrement = () => {
    const incrementArray = new Array(50);
    for (let index = 0; index < incrementArray.length; index++) {
      const number = Math.pow(2, index);
      if (amount - count < number * 100) {
        return number;
      }
    }
    return 1;
  };

  return (
    <span>
      {Number(count).toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      })}
    </span>
  );
};

export default AnimateCount;
