export const elementAccess = [
  { elementId: 'addmemberbutton', users: [6] },
  { elementId: 'editmemberbutton', users: [6] },
  { elementId: 'deletememberbutton', users: [6] },
  { elementId: 'chatmembericon', users: [6] },
  { elementId: 'messagememberbutton', users: [6] },
  { elementId: 'requestmemberbutton', users: [6] },
  { elementId: 'messageicon', users: [1, 2, 3, 4, 5] },

  { elementId: 'memberAssignButton', users: [6, 3, 2] },
  { elementId: 'editMemberUsers', users: [6] },
  { elementId: 'deleteMemberUsers', users: [6] },
  { elementId: 'messageMemberUsers', users: [6] },
  { elementId: 'memberHeaderCounts', users: [2, 3, 4, 5, 6] },
  { elementId: 'memberAssginLocationButton', users: [6] },
  { elementId: 'editMemberLocation', users: [6] },
  { elementId: 'deleteMemberLocation', users: [6] },
  { elementId: 'memberUsageTab', users: [6] },

  { elementId: 'VendorTitleButton', users: [6] },
  { elementId: 'VendorDcAddButton', users: [6] },
  { elementId: 'VendorDcEditAction', users: [6] },
  { elementId: 'VendorDcDeleteAction', users: [6] },
  { elementId: 'vendorHeaderCounts', users: [2, 3, 4, 5, 6] },
  { elementId: 'VendorSalesAssignButton', users: [4, 5, 6] },
  { elementId: 'VendorSalesEditAction', users: [4, 5, 6] },
  { elementId: 'VendorSalesDeleteAction', users: [4, 5, 6] },
  { elementId: 'VendorLinkTab', users: [4, 5, 6] },
  { elementId: 'VendorMembersTab', users: [4, 5, 6] },
  { elementId: 'VendorMemberDeleteAction', users: [6] },

  { elementId: 'addNewDocument', users: [6] },
  { elementId: 'editDocument', users: [6] },
  { elementId: 'deleteDocument', users: [6] },

  { elementId: 'rebatesOpportunityUpload', users: [6] },
  { elementId: 'rebatesOpportunityExport', users: [6, 3] },
  { elementId: 'rebatesOpportunityNote', users: [6] },

  { elementId: 'earnedRebatesUpload', users: [6]},
  { elementId: 'earnedRebatesExport', users: [6, 3]},
  { elementId: 'rebateReceiptsExport', users: [6, 3]},
  { elementId: 'rebateOverviewExport', users: [6]},
  { elementId: 'overviewWidgetAction', users: [6]},
  { elementId: 'assignNewKPI', users: [6]},
  { elementId: 'dashboardEarnedRebates', users: [6, 5, 4, 3, 2, 1] },

  {elementId : 'userDirectory', users:[6]},
  {elementId : 'viewAsMember', users:[6]},
  {elementId : 'addNewLinks', users:[6]},
  {elementId:'savingPercentage', users:[6]},
  {elementId:'userAnalytics',users:[6]}
];
