import { FC, useMemo } from 'react';
import { ReportModeTabPanel } from './RebateModeTabPanel';
import { CheckMode } from './CheckMode';
import { ElectronicMode } from './ElectronicMode/ElectronicMode';
import dollarGreenImage from '../../../../../images/money-green.svg';
import { ActorRefFrom, InterpreterFrom } from 'xstate';
import {
  ClaimRebatesMachine,
  RebateReceiptCheckTypeItemMachine,
  RebateReceiptHistoricTypeItemMachine,
} from './machines';
import { useActor } from '@xstate/react';
import { Tab, Tabs } from '@mui/material';
import { centsToFormattedDollars } from '../../../../../utils';
import HistoricMode from './HistoricMode';

export const ClaimRebatesModal: FC<{
  actor: InterpreterFrom<ClaimRebatesMachine>;
}> = ({ actor }) => {
  const [state, send] = useActor(actor);

  console.log('state.context: ', state.context);
  const a11yProps = (index: number) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  };

  const value = useMemo(() => {
    let val = '';

    if (state.matches('opened.checkRebateRequestForm'))
      val = 'openCheckRebateRequestForm';

    if (state.matches('opened.electronicTransferRequestForm'))
      val = 'openElectronicTransferRequestForm';

    if (state.matches('opened.historicalRebateRequestForm'))
      val = 'historicalRebateRequestForm';
    return val;
  }, [
    state.matches('opened.checkRebateRequestForm'),
    state.matches('opened.electronicTransferRequestForm'),
    state.matches('opened.historicalRebateRequestForm'),
  ]);

  return (
    <div className="mode-tab">
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Tabs
          key={value}
          aria-label="simple tabs"
          value={value}
          onChange={(e, value) => send({ type: value })}
        >
          {state.context.userType === 'memberAdmin' && (
            <Tab
              value="openCheckRebateRequestForm"
              label="Check"
              {...a11yProps(0)}
            />
          )}
          {process.env.REACT_APP_HIDE_DWOLLA_FEATURES != 'true' &&
            state.context.userType === 'memberAdmin' && (
              <Tab
                value="openElectronicTransferRequestForm"
                label="Electronic Transfer"
                {...a11yProps(1)}
              />
            )}
          {state.context.userType === 'buyRightAdmin' && (
            <Tab
              value="historicalRebateRequestForm"
              label="Previously credited"
              {...a11yProps(2)}
            />
          )}
        </Tabs>
        <div className="mode-rebate-total">
          <div className="mode-rebate-total-text">
            <span className="mode-rebate-total-amount">
              {centsToFormattedDollars(state.context.amount)}
            </span>
            <span className="mode-rebate-total-info">Total Rebates</span>
          </div>
          <div className="mode-rebate-total-icon">
            <div className="rebate-icon">
              <img src={dollarGreenImage} alt="buyright" title="buyright" />
            </div>
          </div>
        </div>
      </div>
      <ReportModeTabPanel
        label="check"
        matches={state.matches('opened.checkRebateRequestForm')}
      >
        {state.children.checkTypeItem && (
          <CheckMode
            actor={
              state.children
                .checkTypeItem as ActorRefFrom<RebateReceiptCheckTypeItemMachine>
            }
          />
        )}
      </ReportModeTabPanel>
      <ReportModeTabPanel
        label="historic"
        matches={state.matches('opened.historicalRebateRequestForm')}
      >
        {state.children.historicalTypeItem && (
          <HistoricMode
            actor={
              state.children
                .historicalTypeItem as ActorRefFrom<RebateReceiptHistoricTypeItemMachine>
            }
          />
        )}
      </ReportModeTabPanel>
      <ReportModeTabPanel
        label="electronic-transfer"
        matches={state.matches('opened.electronicTransferRequestForm')}
      >
        <ElectronicMode actor={actor} />
      </ReportModeTabPanel>
    </div>
  );
};

export default ClaimRebatesModal;
