import MuiDatePicker from '@mui/lab/DatePicker';
import './custom-date-picker.scss';
import { FC } from 'react';
import { createTheme, TextField } from '@mui/material';
import moment from 'moment';
import { ThemeProvider } from '@mui/styles';

const themeConfig: any = {
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#01518c',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#01518c',
      },
    },
  },
};

const materialTheme = createTheme(themeConfig);

export const CustomDatePicker: FC<{
  label?: string;
  value?: string;
  onChange: (date: any, value?: any) => void;
  maxDateLimit?: Date;
}> = ({ label, value, onChange, maxDateLimit }) => {
  const customDesktopModeMediaQuery = '@media (min-width: 1330px)';
  return (
    <ThemeProvider theme={materialTheme}>
      <div className="date-picker-container">
        <MuiDatePicker
          className="date-picker"
          inputFormat="MM/dd/yyyy"
          label={label}
          value={moment(value).toDate()}
          maxDate={maxDateLimit}
          onChange={onChange}
          desktopModeMediaQuery={customDesktopModeMediaQuery}
          renderInput={(props) => <TextField {...props} variant="outlined" className="text-box" />}
        />
    </div>
    </ThemeProvider>
  );
};

export default CustomDatePicker;
