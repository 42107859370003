import { FC, Fragment, useState } from 'react';
import moment from 'moment';
import { Loader, WarningPopup } from '../../../utils';
import profilePlaceholder from '../../../images/profile-placeholder.jpg';
import { StringAvatar } from '../../../utils/AvatarInitial';
import Avatar from '@mui/material/Avatar';

const CommunityCommentsContent: FC<any> = ({
  commentsList,
  handleCommentLike,
  handleCommentDisLike,
  deleteDiscussionComment,
  updateDeleteCommentFlag,
  communityCommentActions: { commentActionSpinner, isDeleteComment, commentId },
  user,
  handleCommentEdit,
  lockedAt,
}) => {
  const [selectedComment, setSelectedComment] = useState(null);

  const handleCommentDelete = (item: any) => {
    updateDeleteCommentFlag(true);
    setSelectedComment(item);
  };
  const { id: loggedInUserID, isBRAdmin } = user;

  return (
    <Fragment>
      {commentsList.length > 0 &&
        commentsList.map((item: any) => {
          const {
            id,
            content,
            createdat,
            likeCount,
            createdBy,
            discussionCommentLikeId,
            deletedat,
            flagReasonId,
            userid,
            avatar,
            userDiscussionCommentLikeId,
          } = item;
          const lastUpdated = moment(createdat).fromNow();
          return (
            <div className="community-comments-content">
              <div className="community-comments-content-left">
                <div className="community-comments-content-user">
                  <div className="details-user-img">
                    {avatar && avatar.length > 0 ? (
                      <img src={avatar} alt="user" />
                    ) : createdBy ? (
                      <Avatar {...StringAvatar(createdBy)} />
                    ) : (
                      <img src={profilePlaceholder} alt="user" />
                    )}
                  </div>
                </div>
              </div>

              <div className="community-comments-content-right">
                <h2 className="community-comments-content-title">
                  {createdBy}
                </h2>
                <div className="community-comments-content-created-info">
                  Commented: <b>{lastUpdated}</b>
                </div>
                <p className="community-comments-content-description">
                  {content}
                </p>
                {deletedat === null && (
                  <div className="community-comments-content-bottom">
                    <span
                      className={
                        lockedAt !== null
                          ? 'span-disabled'
                          : userDiscussionCommentLikeId !== null
                          ? 'like-community-style single-comment-liked'
                          : 'like-community-style single-comment-disliked'
                      }
                      onClick={() =>
                        userDiscussionCommentLikeId !== null
                          ? handleCommentDisLike(item)
                          : handleCommentLike(item)
                      }
                    >
                      {commentActionSpinner && id === commentId ? (
                        <span className="default-spinner">
                          <Loader size={10} />
                        </span>
                      ) : (
                        <i className="fas fa-heart" />
                      )}
                      {likeCount} likes
                    </span>
                    {loggedInUserID === userid && (
                      <span
                        className={
                          lockedAt !== null
                            ? 'span-disabled'
                            : flagReasonId
                            ? 'edit-community-style report-active'
                            : 'edit-community-style report-inactive'
                        }
                        onClick={() => handleCommentEdit(item)}
                      >
                        <i className="fas fa-pen" />
                        Edit
                      </span>
                    )}
                    {loggedInUserID === userid ||
                      (isBRAdmin && (
                        <span
                          className={
                            !isBRAdmin && lockedAt !== null
                              ? 'span-disabled delete-community-style'
                              : 'delete-community-style'
                          }
                          onClick={() => handleCommentDelete(item)}
                        >
                          <i className="fas fa-trash" />
                          Delete
                        </span>
                      ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      <WarningPopup
        open={isDeleteComment}
        type={'delete'}
        name={'comment'}
        handleProceed={() => deleteDiscussionComment(selectedComment)}
        handleClose={() => updateDeleteCommentFlag(false)}
        spinner={commentActionSpinner}
      />
    </Fragment>
  );
};

export default CommunityCommentsContent;
