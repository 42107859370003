import { useState, useEffect, FC } from 'react';
import { SearchNotFound } from '../../../../../components/SearchNotFound';
import { useStyles } from '../../../../../utils';
import { RoleSelect } from './RoleSelect';
import sortDefault from '../../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../../images/sortDesc.svg';
import DefaultUserImg from '../../../../../images/profile-placeholder.jpg';
import { Grid, FormControlLabel, Checkbox } from '@material-ui/core';
import {StringAvatar} from "../../../../../utils/AvatarInitial";
import Avatar from '@mui/material/Avatar';

export const AssignUserTable: FC<any> = ({
  gridHeader,
  gridData,
  setSortKey,
  sortType,
  onSelecteAssignUser,
  selectedUsers,
  handleUserRoleChange,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);
  const [activeUsers, setActiveUsers] = useState<any[]>([]);

  const handleClick = (event: any, sortKey: string) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  const handleCheck = (item: any) => {
    const activeUsersTemp: any[] = [...activeUsers];
    if (selectedUsers.find((elem: any) => elem.userId === item.userId)) {
      const index = activeUsersTemp.indexOf(item);
      if (index > -1) {
        activeUsersTemp.splice(index, 1);
      }
      setActiveUsers(activeUsersTemp);
    } else {
      setActiveUsers([...activeUsersTemp, item]);
    }
  };

  useEffect(() => {
    onSelecteAssignUser(activeUsers);
  }, [activeUsers]);
  return (
    <div className={classes.root}>
      {gridData.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                <div>
                  <span
                    className="grid-header"
                    onClick={(event) => handleClick(event, sortKey)}
                  >
                    {label}
                  </span>
                  <span className="sort-icon">
                    {isValidHeader ? (
                      sortType === '' ? (
                        <img className="sort-svg" src={sortDefault} />
                      ) : label === activeHeader && sortType === 'ASC' ? (
                        <img className="sort-svg" src={sortAscIcon} />
                      ) : label === activeHeader && sortType === 'DESC' ? (
                        <img className="sort-svg" src={sortDescIcon} />
                      ) : (
                        <img className="sort-svg" src={sortDefault} />
                      )
                    ) : null}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const { userId, avatar, firstName, lastName, email } = item;
          const name = `${firstName} ${lastName}`;
          const isChecked = selectedUsers.some((item: any) => item.userId === userId);
          return (
            <Grid
              container
              spacing={0}
              className={isChecked ? 'tr active-table-row' : 'tr'}
            >
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={1}
                container
                direction="row"
                alignItems="center"
              >
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      color="primary"
                      checked={isChecked}
                      onChange={() => handleCheck(item)}
                    />
                  }
                  labelPlacement="end"
                  label={undefined}
                />
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={7}
                container
                direction="row"
                alignItems="center"
              >
                <div className="table-member-details">
                  <div className="member-img">
                    {avatar && avatar.length > 0 ? (
                      <img src={avatar} alt="user" />)
                      : (name ? (
                        <Avatar {...StringAvatar(name)} />) : (
                        <img src={DefaultUserImg} alt="user" />)
                      )}
                  </div>
                  <div className="member-profile-details">
                    <div className="member-name">
                      {`${firstName} ${lastName}`}
                    </div>
                    <div className="member-mail-id">{email ? email : ''}</div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                alignItems="center"
              >
                <RoleSelect
                  item={item}
                  isChecked={isChecked}
                  handleUserRoleChange={handleUserRoleChange}
                />
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Member'} />
      )}
    </div>
  );
};
