import { FC } from 'react';
import { TextField } from '@material-ui/core';

export const NotesModal: FC<{
  isNotesEditModal: boolean;
  handleNotesChange: (notes: string) => void;
  selectedProduct: { notes?: string | null; manufacturerName?: string | null };
}> = ({ isNotesEditModal, handleNotesChange, selectedProduct }) => {
  
  const { notes, manufacturerName } = selectedProduct || {};

  return (
    <div style={{ marginTop: '1em' }}>
      <TextField
         style={{ marginBottom: '1em' }}
        aria-readonly
        label="Manufacturer Name"
        variant="outlined"
        className="text-box"
        value={manufacturerName}
        InputProps={{
          readOnly: true,
        }}
      />
      {isNotesEditModal ? (
        <TextField
          className="text-box"
          multiline
          id="outlined-multiline-static"
          label="Notes"
          rows={4}
          defaultValue={notes === null ? '' : notes}
          variant="outlined"
          onChange={(event) => handleNotesChange(event.target.value)}
        />
      ) : notes === null ? (
        <div className="notes-notFound">No additional notes available</div>
      ) : (
        <div>{notes}</div>
      )}
    </div>
  );
};

export default NotesModal;
