import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  UPDATE_MANAGEADS_SPINNER,
  UPDATE_ADS_LIST,
  SHOW_ADD_MANAGEADS_MODAL,
  HIDE_ADD_MANAGEADS_MODAL,
  SHOW_ADSTYPE_MODAL,
  HIDE_ADSTYPE_MODAL,
  SHOW_VIEW_AD_MODAL,
  HIDE_VIEW_AD_MODAL,
  UPDATE_SELECTED_VIEW_AD,
  UPDATE_ADSTYPE_LIST,
  AdListError,
  FetchError,
} from "../../constants";
import {getAdTypesList, getAllAdList} from "../../api/manageads"

export const getAdTypes = () => async (dispatch) => {
  const adTypesList = await getAdTypesList();
  dispatch({
    type: UPDATE_ADSTYPE_LIST,
    list: adTypesList.data,
  });
};

export const getAdList = () => async (dispatch, getState) => {
  dispatch({
    type: UPDATE_MANAGEADS_SPINNER,
    spinner: true,
  });
  try {
    const adsList = await getAllAdList();
    dispatch({
      type: UPDATE_ADS_LIST,
      list: adsList.data,
    });
    dispatch({
      type: UPDATE_MANAGEADS_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MANAGEADS_SPINNER,
      spinner: false,
    });

    if (error === AdListError) {
      toast.error(AdListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const handleShowAddAdsModal = () => async (dispatch) => {
  dispatch({
    type: SHOW_ADD_MANAGEADS_MODAL,
  });
};
export const handleCloseAddAdsModal = () => async (dispatch) => {
  dispatch({
    type: HIDE_ADD_MANAGEADS_MODAL,
  });
};
export const handleShowAdsTypeModal = () => async (dispatch) => {
  dispatch({
    type: SHOW_ADSTYPE_MODAL,
  });
};
export const handleCloseAdsTypeModal = () => async (dispatch) => {
  dispatch({
    type: HIDE_ADSTYPE_MODAL,
  });
};
export const handleShowViewAdModal = (item) => async (dispatch) => {
  dispatch({
    type: SHOW_VIEW_AD_MODAL,
  });
  dispatch({
    type: UPDATE_SELECTED_VIEW_AD,
    item: item
  });
};
export const handleCloseViewAdModal = () => async (dispatch) => {
  dispatch({
    type: HIDE_VIEW_AD_MODAL,
  });
};
