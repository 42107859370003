export const statusDisplay = {
  INPROGRESS: {
    className: 'details-title-in-progress',
    label: '(In Progress)',
  },
  UPLOADED: {
    className: 'details-title-draft',
    label: '(Draft)',
  },
  PUBLISHED: {
    className: 'details-title-published',
    label: '(Published)',
  },
  ERROR: {
    className: 'details-title-error',
    label: '(Error)',
  },
  NOSTATUS: {
    className: 'details-title-in-progress',
    label: '',
  },
};
