import { FC, useEffect } from 'react';
import { ProfileSideNav } from '../../components/sideNav';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actionCreators from '../../../../actions/userActions';
import { useMachine } from '@xstate/react';
import { accountSettingsMachine } from './accountSettingsMachine';
import serviceInterceptor from '../../../../services/ServiceInterceptor';
import profileplaceholder from '../../../../images/profile-placeholder.jpg';
import { formatPhoneNumber, isValidPhoneNo } from '../../../../utils';
import { useSelector } from 'react-redux';

export const AccountSettings: FC<{
  user: {
    avatar: string;
    email: string;
    firstname: string;
    id: number;
    isBRAdmin: boolean;
    lastname: string;
    phone: string | null;
  };
}> = (props) => {
  const dispatch = useDispatch();
  const { addUser } = bindActionCreators(actionCreators, dispatch);
  const [state, send] = useMachine(accountSettingsMachine, {
    context: {
      avatarUrl: props.user.avatar
        ? `${props.user.avatar}?${new Date().getTime()}`
        : null,
      userId: props.user.id,
      firstName: props.user.firstname,
      lastName: props.user.lastname,
      phoneNumber: props.user.phone ?? '',
      email: props.user.email,
    },
    actions: {
      notifyError: () => toast.error('Failed to update the user settings'),
      notifySuccess: (_, event) => {
        if (event.type === 'successfullyUpdated') {
          toast.success(event.message);
        }
      },
      updateUserSettings: ({ avatarUrl, firstName, lastName, phoneNumber }) => {
        const updatedUserDataToStore = {
          ...props.user,
          avatar: avatarUrl,
          firstname: firstName,
          lastname: lastName,
          phone: phoneNumber,
        };
        localStorage.setItem('user', JSON.stringify(updatedUserDataToStore));
        addUser(updatedUserDataToStore);
      },
    },
    services: {
      updateUserSettings:
        ({ avatarFile, firstName, lastName, userId, phoneNumber }) =>
        () => {
          const bodyFormData = new FormData();
          bodyFormData.append('firstName', firstName || '');
          bodyFormData.append('lastName', lastName || '');
          bodyFormData.append('avatar', avatarFile || '');
          bodyFormData.append('phoneNumber', phoneNumber || '');

          return serviceInterceptor
            .patch(`/users/${userId}`, bodyFormData)
            .then((response) => response.data)
            .catch((error) => error);
        },
    },
  });

  const navigate = useNavigate();
  const store = useSelector((state) => state)

  const {user}:any = store

  useEffect(() => {
    if (!state.context.userId) {
      navigate('/login');
    }
  }, [state.context.userId]);

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        send('submit');
      }}
      className="body-container"
    >
      <Grid container className="change-password">
        <ProfileSideNav {...props} />
        <Grid item xs={12} lg={9} className="change-password-right">
          <div className="br-form-element">
            <Typography variant="h5">My Account</Typography>
            <div className="general-account-settings">
              <Grid container spacing={5}>
                <Grid item xs={12} lg={6} md={6} sm={6}>
                  <TextField
                    label="First Name"
                    variant="outlined"
                    className="text-box"
                    type="text"
                    value={state.context.firstName}
                    onChange={(e) =>
                      send({
                        type: 'updateFirstName',
                        firstName: e.target.value,
                      })
                    }
                    disabled={state.hasTag('loading') || user?.isViewAsMember}
                  />
                  <TextField
                    label="Last Name"
                    variant="outlined"
                    className="text-box"
                    type="text"
                    value={state.context.lastName}
                    onChange={(e) =>
                      send({
                        type: 'updateLastName',
                        lastName: e.target.value,
                      })
                    }
                    disabled={state.hasTag('loading') || user?.isViewAsMember}
                  />
                  <TextField
                    label="Phone number"
                    variant="outlined"
                    className="text-box"
                    value={formatPhoneNumber(state.context.phoneNumber)}
                    inputProps={{
                      maxLength: 16,
                    }}
                    type="phone"
                    onChange={(e) =>
                      send({
                        type: 'updatePhoneNumber',
                        phoneNumber: e.target.value,
                      })
                    }
                    disabled={state.hasTag('loading') || user?.isViewAsMember}
                    error={!state.context.phoneNumber || !isValidPhoneNo(state.context.phoneNumber)}
                  />
                  <TextField
                    label="Email"
                    variant="outlined"
                    className="text-box"
                    type="text"
                    value={state.context.email}
                    disabled={state.hasTag('loading') || user?.isViewAsMember}
                  />
                </Grid>
                <Grid item xs={12} lg={6} md={6} sm={6}>
                  <div className="image-add-field">
                    <img
                      alt="User Avatar"
                      src={state.context.avatarUrl || profileplaceholder}
                      title="test"
                    />
                  </div>
                  <div className="upload-img">
                    <Button className="img-filed" component="label">
                      <i className="far fa-image image-field-icon" />
                      Upload New Image
                      <input
                        type="file"
                        disabled={state.hasTag('loading') || user?.isViewAsMember}
                        onChange={(e) => {
                          if (e?.target?.files?.[0]) {
                            send({
                              type: 'updateAvatarFile',
                              avatarFile: e.target.files[0],
                            });
                          }
                        }}
                        hidden
                      />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    className="button-cancel btn-mobile"
                    onClick={() => {
                      navigate('/dashboard');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button type="submit" className="button-save btn-mobile" disabled={user?.isViewAsMember}>
                    Save
                  </Button>
                </Grid>
              </Grid>
            </div>
          </div>
        </Grid>
      </Grid>
    </form>
  );
};
