import { GridSize } from "@material-ui/core";

export const gridHeader = [
  {
    label: 'E-Brands as % of Total Purchases',
    subLabel: '',
    value: 'total',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
  {
    label: 'Legacy',
    subLabel: '$500 - $1499 Avg.',
    value: 'Legacy',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
  {
    label: '1500 Tier',
    subLabel: '$1500 - $1999 Avg.',
    value: '1500',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
  {
    label: '2000 Tier',
    subLabel: '$2000 - $2749 Avg.',
    value: '2000',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
  {
    label: '2750 Tier',
    subLabel: '$2750 - $3499 Avg.',
    value: '2750',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
  {
    label: '3500 Tier',
    subLabel: '$3500+ Avg.',
    value: '3500',
    sortKey: '',
    xs: 12 as GridSize,
    lg: 2 as GridSize,
  },
];

export const gridData = [
  {
    totalPurchase: '40.00% - 49.99%',
    legacy: '0.25%',
    tier1: '0.50%',
    tier2: '0.50%',
    tier3: '0.75%',
    tier4: '1.00%',
  },
  {
    totalPurchase: '50.00% - 59.99%',
    legacy: '0.50%',
    tier1: '0.75%',
    tier2: '1.00%',
    tier3: '1.25%',
    tier4: '1.50%',
  },
  {
    totalPurchase: '60.00% +',
    legacy: '0.75%',
    tier1: '1.525%',
    tier2: '1.50%',
    tier3: '1.75%',
    tier4: '2.00%',
  },
];