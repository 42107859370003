import serviceInterceptor from '../../../services/ServiceInterceptor';

export const publishMemberRebates = async ({
  quarter,
  year,
}: {
  quarter: number;
  year: number;
}) => {
  const response = await serviceInterceptor.patch(
    `/earned-rebates-records/publish?quarter=${quarter}&year=${year}`,
  );
  return response;
};
