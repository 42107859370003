import { baseMembersUrl, UPDATE_MEMBER_DELETE_ERROR } from "../../constants";

export const updateMemberIsActive = async (id) => {
  try {
    const user = JSON.parse(localStorage.getItem("user"));
    const response = await fetch(`${baseMembersUrl}/${id}/inactive`, {
      method: "PATCH",
      headers: new Headers({
        "x-access-token": user.accessToken,
        "Content-Type": "application/json",
      }),
    });
    if (!response.ok) throw UPDATE_MEMBER_DELETE_ERROR;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw UPDATE_MEMBER_DELETE_ERROR;
  }
};
