import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';

export const UserDirectoryFilter: FC<{
  send: any;
}> = ({
  send,
}) => {
  
  const handleSearch = (event: any) => {
    send({
      destructive: true,
      key: 'searchString',
      type: 'addQueryStringValue',
      value: event.target.value,
    });
  };

  return (
    <Grid container spacing={3} className="header-filter-box">
      <Grid
        item
        xs={12}
        md={12}
        direction="row"
        justify="flex-end"
        alignItems="center"
      >
        <div className="header-filter-box-search-add">
          <div className="header-filter-box-search">
            <div className="page-search-box ">
              <i className="fas fa-search" />
              <InputBase
                placeholder="Search"
                defaultValue={''}
                onChange={(event) => handleSearch(event)}
              />
            </div>
          </div>
        </div>
      </Grid>
    </Grid>
  );
};
