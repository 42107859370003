import { FC } from 'react';
import { RebatesReceiptTable } from '../../../../tableUtils/RebatesReceiptTable';
import { getPaginatedData, Loader } from '../../../../../../utils';
import { ConnectedPagination } from '../../../../../../components/ConnectedPagination';
import '../../../../rebates.scss';
import '../../../../../../scss/styles.scss';
import { useStore } from 'react-redux';
import {
  adminGridHeader,
  memberGridHeader,
} from '../../../../tableUtils/constants';

export const ReceiptContent: FC<{
  searchText: string;
  sortKey: string | null;
  setSortKey: (sortKey: string) => void;
  sortType: 'ASC' | 'DESC';
  setSortType: (type: 'ASC' | 'DESC') => void;
  updateRebateAction: (selectedRebate: any) => void;
}> = ({ searchText, setSortKey, sortKey, sortType, updateRebateAction }) => {
  const store = useStore<{
    paginationReducer: { rowsPerPage: number; page: number };
    rebateReceipts: { rebateListSpinner: boolean; rebatesList: any[] };
    user: any;
  }>();
  const storeState = store.getState();

  const {
    paginationReducer: { rowsPerPage, page },
    rebateReceipts: { rebateListSpinner, rebatesList },
    user,
  } = storeState;

  console.log({ rebatesList });

  const gridHeader =
    user?.isBRAdmin ?? false ? adminGridHeader : memberGridHeader;

  const filteredData =
    searchText && rebatesList.length > 0
      ? rebatesList.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : rebatesList;

  const sortedData =
    sortKey && sortType && filteredData.length > 0
      ? filteredData &&
        filteredData.sort((a: any, b: any) => {
          if(sortKey !== 'amount'){
            if (sortType === 'DESC') {
              return b[sortKey].localeCompare(a[sortKey]);
            }else{
              return a[sortKey].localeCompare(b[sortKey]);
            }
          }else{
            if (sortType === 'DESC') {
              return b[sortKey] - a[sortKey];
            }else{
              return a[sortKey] - b[sortKey];
            }
          }
          
        })
      : filteredData;

  const modifiedList = getPaginatedData(sortedData, rowsPerPage, page);

  if (rebateListSpinner) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="table-wrapper">
        <RebatesReceiptTable
          gridHeader={gridHeader}
          gridData={modifiedList ? modifiedList : []}
          setSortKey={setSortKey}
          sortType={sortType}
          isBRAdmin={user?.isBRAdmin ?? false}
          updateRebateAction={updateRebateAction}
        />

        {rebatesList && rebatesList.length > 10 && (
          <ConnectedPagination count={rebatesList.length} />
        )}
      </div>
    );
  }
};
