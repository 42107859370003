import { FC } from 'react';
import { ActorRefFrom } from 'xstate';
import { useMachine } from '@xstate/react';
import { opportunityQuartersMachine } from './machines';
import { Loader } from '../../../utils';
import OpportunitiesToolbar from './OpportunitiesToolbar/OpportunitiesToolbar';
import { PaginationMachine } from '../../../machines';
import OpportunitiesTable from './OpportunitiesTable';
import OpportunitiesUploadModal from './OpportunitiesUploadModal';
import OpportunitiesEditModal from './OpportunitiesEditModal';
import OpportunitiesExportModal from './OpportunitiesExportModal';
import OpportunitiesPublishModal from './OpportunitiesPublishModal';
import { OpportunitiesEditMachine } from './machines/opportunitiesEditMachine';
import { OpportunitiesExportMachine } from './machines/opportunitiesExportMachine';
import { OpportunitiesPublishMachine } from './machines/opportunitiesPublishMachine';
import { OpportunitiesUploadMachine } from './machines/opportunitiesUploadMachine';
import SearchNotFound from '../../itemnotfound/searchresultnotfound';

export const Opportunities: FC<{ isBRAdmin?: boolean; showNotesModal: any; updateSelectedProduct: any }> = ({
  isBRAdmin = false,
  showNotesModal,
  updateSelectedProduct,
}) => {
  const [state, send] = useMachine(opportunityQuartersMachine);

  return (
    <div>
      {state.hasTag('loading') && (
        <div
          style={{
            width: '100%',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Loader size={100} />
        </div>
      )}
      {state.children['Opportunity paginated list'] &&

        (
          <>
            <OpportunitiesToolbar
              isBRAdmin={isBRAdmin}
              openEditModal={() => {
                if (isBRAdmin) send('open edit upload modal');
              }}
              openExportModal={() => send('open export upload modal')}
              openPublishModal={() => {
                if (isBRAdmin) send('open publish upload modal');
              }}
              openUploadModal={() => {
                if (isBRAdmin) send('open create upload modal');
              }}
              opportunityQuarters={state.context.opportunityQuarters}
              selectedOpportunityQuarterId={
                state.context.selectedOpportunityQuarterId ?? 1
              }
              setSelectedOpportunityQuarterId={(opportunityQuarterId) => {
                send({
                  type: 'update selectedOpportunityQuarterId',
                  opportunityQuarterId,
                });
              }}
              paginationActor={
                state.children[
                'Opportunity paginated list'
                ] as ActorRefFrom<PaginationMachine>
              }
            />
            {state.context.selectedOpportunityQuarterId ? (
              <OpportunitiesTable
                showNotesModal={showNotesModal}
                updateSelectedProduct={updateSelectedProduct}
                opportunityQuarters={state.context.opportunityQuarters}
                selectedOpportunityQuarterId={
                  state.context.selectedOpportunityQuarterId
                }
                paginationActor={
                  state.children[
                  'Opportunity paginated list'
                  ] as ActorRefFrom<PaginationMachine>
                }
              />
            ) : (
              <SearchNotFound userType={'Opportunities List'} />
            )}
          </>
        )}
      {isBRAdmin && state.matches('displaying.modalOpened.editUpload') && (
        <OpportunitiesEditModal
          opportunitiesEditUploadActor={
            state?.children[
            'Edit opportunities upload'
            ] as ActorRefFrom<OpportunitiesEditMachine>
          }
        />
      )}
      {isBRAdmin && state.matches('displaying.modalOpened.exportUpload') && (
        <OpportunitiesExportModal
          opportunitiesEditUploadActor={
            state?.children[
            'Export opportunities upload'
            ] as ActorRefFrom<OpportunitiesExportMachine>
          }
        />
      )}
      {isBRAdmin && state.matches('displaying.modalOpened.publishUpload') && (
        <OpportunitiesPublishModal
          opportunitiesPublishUploadActor={
            state?.children[
            'Publish opportunities upload'
            ] as ActorRefFrom<OpportunitiesPublishMachine>
          }
        />
      )}
      {state.matches('displaying.modalOpened.createUpload') && (
        <OpportunitiesUploadModal
          opportunitiesCreateUploadActor={
            state?.children[
            'Create opportunities upload'
            ] as ActorRefFrom<OpportunitiesUploadMachine>
          }
        />
      )}
    </div>
  );
};

export default Opportunities;
