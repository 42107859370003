import { FormControl, InputLabel, Select } from '@material-ui/core';
import { FC } from 'react';

const SelectBox: FC<{
  labelName: string;
  inputValue: string | number;
  handleSelectChange: (e: any) => void;
  optionList: any[];
  errorState: any;
  errorMessage?: string;
  isLoading?: boolean;
  objFormat?: any;
}> = ({
  labelName,
  inputValue,
  handleSelectChange,
  optionList,
  errorState,
  errorMessage,
  isLoading = false,
  objFormat,
}) => {
  return (
    <FormControl variant="outlined" className="select-box">
      {!errorState ? (
        <InputLabel htmlFor={labelName}>{labelName}</InputLabel>
      ) : (
        <InputLabel htmlFor={labelName} style={{ color: 'red' }}>
          {labelName}
        </InputLabel>
      )}

      <Select
        native
        value={inputValue}
        onChange={(e) => handleSelectChange(e)}
        label={labelName}
        error={errorState}
        id={errorState ? 'standard-error' : 'outlined-age-native-simple'}
        disabled={isLoading}
        MenuProps={{
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left"
          },
        }}
      >
        <option key={'state-NA'} value={0}></option>
        {optionList &&
          optionList.map((item) => {
            let id =
              objFormat && objFormat['key']
                ? item[objFormat['key']]
                : item['id'];
            let value =
              objFormat && objFormat['value']
                ? item[objFormat['value']]
                : item['name'];

            return (
              <option key={id} value={id}>
                {value}
              </option>
            );
          })}
      </Select>
      {errorState ? (
        <p style={{ color: 'red', fontSize: '12px' }}>{errorMessage}</p>
      ) : (
        ''
      )}
    </FormControl>
  );
};

export default SelectBox;
