import { FC, useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { centsToFormattedDollars, useStyles } from '../../../utils';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';

export const MemberActiveEarningsTable: FC<any> = ({
  gridHeader,
  gridData,
  orderBy,
  sortOrder,
  setOrderBy,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {gridData.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = (label !== '' && (sortKey !== '' || sortKey !== undefined));
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                <div onClick={() =>
                  sortKey && setOrderBy(sortKey)
                }>
                  <span
                    className="grid-header"
                  >
                    {label}
                  </span>
                  <span className="sort-icon">
                    {isValidHeader && (
                      sortKey === orderBy ? (
                        <img
                          className="sort-svg"
                          src={sortOrder === 'asc' ? sortAscIcon : sortDescIcon}
                        />
                      ) : (
                        <img className="sort-svg" src={sortDefault} />
                      )
                    )}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const { productName, productNumber, orderSize, amountEarned } = item;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="grid-tr-detail-box-td grid-tr-detail-box-amount"
                item
                lg={6}
              >
                {productName ? productName : '-'}
              </Grid>
              <Grid
                className="grid-tr-detail-box-td grid-tr-detail-box-date"
                item
                lg={2}
              >
                {productNumber ? productNumber : '-'}
              </Grid>
              <Grid
                className="grid-tr-detail-box-td grid-tr-detail-box-date"
                item
                lg={2}
              >
                {orderSize ? orderSize : '-'}
              </Grid>
              <Grid
                className="grid-tr-detail-box-td grid-tr-detail-box-pay-mode"
                item
                lg={2}
              >
                {Number(amountEarned)
                  ? centsToFormattedDollars(Number(amountEarned))
                  : '-'}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Member Rebates List'} />
      )}
    </div>
  );
};

export default MemberActiveEarningsTable;
