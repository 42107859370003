import React from "react";
import moment from "moment";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {RebateProductLists, CompactRebates} from "../../../../../constants";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const ExportProductRebatesModal = ({
    handleExportQuarter,
    handleExportYear,
    exportQuarter,
    exportYear,
    handleRequestRebateType,
    requestRebateType,
    quarterList
}) => {
       
    const formattedQuarterList = quarterList.map((item) => {
        const {label, value, quarter} = item;
        const formattedLabel = label.split(' ');
        const formattedValue = value.split(' ');
        return {
            label: formattedLabel[0],
            value: quarter
        }
    })

    const yearList = quarterList.map((item) => {
        const {label, value} = item;
        const formattedLabel = label.split(' ');
        const formattedValue = value.split(' ');
        return {
            label: formattedLabel[1],
            value: formattedValue[1]
        }
    });

    const formattedYearList = yearList.filter((item, index, self) =>
        index === self.findIndex((t) => (
            t.place === item.place && t.name === item.name
        ))
    );

    return (
        <div className="export-wrapper">
            <Grid container spacing={2} className="upload-rebateform-grid">
                <Grid container xs={12} direction="row" justifyContent="left" alignItems="left" className="radio-button-wrapper">
                    <Grid>
                        <FormControlLabel
                            control={
                                <Radio
                                    color="primary"
                                    checked={requestRebateType === RebateProductLists}
                                    onChange={() => handleRequestRebateType(RebateProductLists)}
                                />
                            }
                            label={RebateProductLists}
                            labelPlacement="end"
                        />
                    </Grid>
                    <Grid>
                        <FormControlLabel
                            control={
                                <Radio
                                    color="primary"
                                    checked={requestRebateType === CompactRebates}
                                    onChange={() => handleRequestRebateType(CompactRebates)}
                                />
                            }
                            label={CompactRebates}
                            labelPlacement="end"
                        />
                    </Grid>
                </Grid>

                <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="select-box">
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Select Quarter
                        </InputLabel>
                        <Select
                            value={exportQuarter}
                            onChange={(event) => handleExportQuarter(event.target.value)}
                            label="Select Quarter"
                        >
                            {formattedQuarterList &&
                                formattedQuarterList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="select-box">
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Select Year
                        </InputLabel>
                        <Select
                            value={exportYear}
                            onChange={(event) => handleExportYear(event.target.value)}
                            label="Select Year"
                        >
                            {formattedYearList &&
                                formattedYearList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.label}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
};