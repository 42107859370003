import React from 'react';
import Grid from '@material-ui/core/Grid';
// default menu
import { NotFound } from './NotFound';

import '../../../css/fonts.css';
import '../../../css/all.min.css';
import '../../../css/style.css';
import './notfound.scss';

export const PageNotFound = () => {
  return (
    <div>
      <Grid item xs={10} className="body-container">
        <NotFound />
      </Grid>
    </div>
  );
};
