import { connect } from 'react-redux';
import {
  getDocumentsList,
  getDocumentsCategory,
  handleToggle,
  handleSelectedCategory,
  showAddDocumentModal,
  closeAddDocumentModal,
  handleSelectMembers,
  clearDocument,
  handleFileSelect,
  clearDocumentModal,
  handleRemoveFile,
  handleSelectAllMembers,
  updateSelectedMembers,
  updateActiveMembers,
  handlePublish,
  getDCLIST,
  getMemberMenuList,
  handleModalCategory,
  updateDocumentDelete,
  onDeleteDocument,
  handleCloseDocumentDeleteModal,
  showDocumentEditModal,
  showDocumentDetailModal,
  closeDocumentEditModal,
  onChangeEditDocDesc,
  handleUpdate,
  getSelectMembersListFromDocumentEdit,
  downloadDocument,
  closeDocumentDetailModal,
} from '../../actions/documents';
import {
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage,
} from '../../actions/pagination';
import { DocumentMain } from './DocumentMain';

const mapStateToProps = (state) => {
  const { documents, modal, paginationReducer } = state;

  return {
    documents,
    modal,
    paginationReducer,
  };
};

export const Documents = connect(mapStateToProps, {
  getDocumentsList,
  getDocumentsCategory,
  handleSelectedCategory,
  handleToggle,
  showAddDocumentModal,
  closeAddDocumentModal,
  getMemberMenuList,
  handleSelectMembers,
  clearDocument,
  handleFileSelect,
  clearDocumentModal,
  handleRemoveFile,
  handleSelectAllMembers,
  updateSelectedMembers,
  updateActiveMembers,
  handlePublish,
  getDCLIST,
  handleModalCategory,
  updateDocumentDelete,
  onDeleteDocument,
  handleCloseDocumentDeleteModal,
  showDocumentEditModal,
  showDocumentDetailModal,
  closeDocumentEditModal,
  onChangeEditDocDesc,
  handleUpdate,
  getSelectMembersList: getSelectMembersListFromDocumentEdit,
  downloadDocument,
  closeDocumentDetailModal,
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage,
})(DocumentMain);
