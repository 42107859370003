import { FC, useState } from 'react';
import { ConnectedPagination } from '../../../../components/ConnectedPagination';
import { DistributionCenterTable } from '../../tableUtils/distributionCenterTable';
import { WarningPopup, getPaginatedData } from '../../../../utils';
import AuthorizationService from '../../../../authorization/AuthorizationService';
import '../../vendors.scss';

export const DistributionCentersList: FC<any> = ({ // TODO Update props
  vendorDCList,
  isDeleteUser,
  selectedDcID,
  updateDeleteVendorDC,
  editVendorDC,
  handleDeleteClose,
  handleDeleteVendorDC,
  searchText,
  sortKey,
  setSortKey,
  sortType,
  paginationReducer: { rowsPerPage, page },
}) => {
  const modifiedList = getPaginatedData(vendorDCList, rowsPerPage, page);
  const filteredData =
    searchText && modifiedList.length > 0
      ? modifiedList.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : modifiedList;

  const sortedData =
    sortKey && sortType && filteredData.length > 0
      ? filteredData &&
        filteredData.sort((a: any, b: any) => {
          if (sortType === 'DESC') {
            return b[sortKey].localeCompare(a[sortKey]);
          }
          return a[sortKey].localeCompare(b[sortKey]);
        })
      : filteredData;

  const showEdit = AuthorizationService.isAuthorizedHTML('VendorDcEditAction');
  const showDelete = AuthorizationService.isAuthorizedHTML(
    'VendorDcDeleteAction',
  );

  const columns = [
    {
      label: 'Name',
      value: 'name',
      sortKey: 'name',
      xs: 12,
      lg: 2,
    },
    {
      label: 'Distribution Center ID',
      value: 'code',
      sortKey: 'code',
      xs: 12,
      lg: 2,
    },
    {
      label: 'Street',
      value: 'street',
      sortKey: 'street',
      xs: 12,
      lg: showEdit || showDelete ? 2 : 3,
    },
    {
      label: 'City',
      value: 'city',
      sortKey: 'city',
      xs: 12,
      lg: 2,
    },
    {
      label: 'State',
      value: 'state',
      sortKey: 'state',
      xs: 12,
      lg: 1,
    },
    {
      label: 'Zip',
      value: 'zip',
      sortKey: 'zip',
      xs: 12,
      lg: 2,
    },
  ];

  const gridHeader =
    showEdit || showDelete
      ? [
          ...columns,
          {
            label: 'Action',
            value: 'action',
            xs: 12,
            lg: 1,
          },
        ]
      : columns;

  const [deleteUser, setDeleteUser] = useState(null);

  const handleDelete = (item: any) => {
    setDeleteUser(item.name);
    updateDeleteVendorDC(item.id);
  };

  return (
    <div className="table-wrapper">
      <DistributionCenterTable
        gridHeader={gridHeader}
        gridData={sortedData ? sortedData : []}
        isEdit={true}
        isDelete={true}
        handleDelete={handleDelete}
        editVendorDC={editVendorDC}
        setSortKey={setSortKey}
        sortType={sortType}
        selectedDcID={selectedDcID}
        showEdit={showEdit}
        showDelete={showDelete}
      />

      {vendorDCList.length > 0 ? (
        <ConnectedPagination count={vendorDCList.length} />
      ) : null}

      <WarningPopup
        open={isDeleteUser}
        type={'delete'}
        name={deleteUser}
        handleProceed={handleDeleteVendorDC}
        handleClose={handleDeleteClose}
      />
    </div>
  );
};

export type DistributionCenterListType = typeof DistributionCentersList;
