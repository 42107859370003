import { FC } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { SearchFilter } from '../../../../components/Search';
import AuthorizationService from "../../../../authorization/AuthorizationService";

export const LinkHeader: FC<any> = (props) => {
  const { searchText, setSearchText, showAddLinkModal } = props;
  return (
    <Grid
      container
      direction='row'
      alignItems='center'
      className='filter-form-header'
    >
      <Grid>
        <SearchFilter searchText={searchText} setSearchText={setSearchText} />
      </Grid>
      <Grid>
        {AuthorizationService.isAuthorizedHTML('addNewLinks') && (
          <Button
            className='btn-add ml-10'
            component='label'
            onClick={showAddLinkModal}
          >
            <span className='fas fa-plus-circle'></span>
            <span>Add New</span>
          </Button>
        )}
      </Grid>
    </Grid>
  );
};

LinkHeader.propTypes = {
  searchText: PropTypes.string,
  setSearchText: PropTypes.func,
  showAddLinkModal: PropTypes.func,
};
