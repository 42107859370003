import {baseUrl, PRODUCT_REBATES_QUARTER_LIST_ERROR} from "../../../constants";

export const getProductRebatesQuarterList = async ({
    quarter,
    year
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${baseUrl}/rebate-product-list-records?quarter=${quarter}&year=${year}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw PRODUCT_REBATES_QUARTER_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw PRODUCT_REBATES_QUARTER_LIST_ERROR;
    }
}

