import { FC, SyntheticEvent, useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { TablePagination } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import InputBase from '@material-ui/core/InputBase';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import Avatar from '@mui/material/Avatar';
// service
import {StringAvatar} from "../../../../../../../utils/AvatarInitial";
import serviceInterceptor from '../../../../../../../services/ServiceInterceptor';
import { Loader, useStyles } from '../../../../../../../utils';
import SearchNotFound from '../../../../../../itemnotfound/searchresultnotfound';
import ItemNotFound from '../../../../../../itemnotfound/itemlistnotfound';
import DefaultMemberImg from '../../../../../../../images/member-list-placeholder.jpg';
import { getComparator, stableSort } from './util';
import { EnhancedTableHead } from './EnhancedTableHead';
import { DialogHead } from './DialogHead';
import { StyledDialogContent } from './StyledDialogContent';
import { StyledDialogActions } from './StyledDialogActions';
import { Member, MemberUsers, Role, Roles } from './memberUserListPopupTypes';
import { toast } from 'react-toastify';

interface MemberUserListProps {
  open: boolean;
  assignedUsers: MemberUsers;
  userList: MemberUsers;
  module?: 'member' | 'vendor';
  memberDetail?: Member | null;
  getMemberUsers: () => void;
  handleClose: () => void;
  hideAssignMemberUserModal: () => void;
  handleLoadAssigneduser: () => void;
  handleOpenCreate: () => void;
  clearAddMemberUser: () => void;
}

export const MemberUserListPopup: FC<MemberUserListProps> = ({
  assignedUsers,
  getMemberUsers,
  memberDetail = null,
  module = 'member',
  open,
  userList,
  handleClose,
  handleOpenCreate,
  hideAssignMemberUserModal,
  handleLoadAssigneduser,
  clearAddMemberUser,
}) => {
  const stylesObj = useStyles();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState<number[]>([]);
  const [selectedRoles, setSelectedRoles] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [dense] = useState(false);
  const [rows, setRows] = useState<MemberUsers>(userList || []);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [searchRows] = useState(userList || []);
  const [isLoading, setLoading] = useState(false);
  const [roles, setRoles] = useState<Roles>([]);

  useEffect(() => {
    async function getRoles() {
      const rolesResponse = await serviceInterceptor.get('/roles');
      if (
        !rolesResponse?.data?.success ||
        rolesResponse?.data?.data?.length === 0
      ) {
        setRoles([]);
        return;
      }
      const filteredRoles = rolesResponse.data.data.filter(
        (role: Role) =>
          role.description.indexOf(module === 'vendor' ? 'Vendor' : 'Member') >
          -1,
      );
      setRoles(filteredRoles);
    }
    getRoles();
  }, []);

  useEffect(() => {
    setRows(searchRows);
  }, [searchRows]);

  const onChangeSearchText = (event: SyntheticEvent) => {
    let value = (event.target as HTMLInputElement).value;
    // update
    setSearchText(value);
    searchList(value);
  };
  const searchList = (searchValue: string) => {
    let tempAnswerList = [...searchRows];

    if (searchValue) {
      tempAnswerList = tempAnswerList.filter((item) => {
        return (
          (item.firstname &&
            item.firstname.toLowerCase().indexOf(searchValue.toLowerCase()) >
              -1) ||
          (item.email &&
            item.email.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) ||
          (item.rolename &&
            item.rolename.toLowerCase().indexOf(searchValue.toLowerCase()) > -1)
        );
      });
    }

    setRows(tempAnswerList);
  };

  const handleRequestSort = (_: SyntheticEvent, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleCheckboxClick = (userId: number) => {
    const selectedIndex = selected.indexOf(userId);
    let newSelected: number[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, userId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt((event as any).target.value, 10));
    setPage(0);
  };

  const isSelected = (id: number) => selected.indexOf(id) > -1;

  const inviteUser = async () => {
    if (selected.length > 0) {
      // validate role selection
      let isValidRoles = true;

      let uniqueMembers = rows.filter(
        (row, ind) => ind === rows.findIndex((elem) => elem.id === row.id),
      );

      let rolesForSelectedMembers = selected.map(
        (id) => uniqueMembers.find((member) => member.id === id)?.roleid || -1,
      );
      // validate ids
      rolesForSelectedMembers.forEach((roleId) => {
        if (roleId <= 0) {
          isValidRoles = false;
        }
      });

      if (isValidRoles) {
        setSelectedRoles(rolesForSelectedMembers);
        setLoading(true);
      } else {
        toast.warning('Please select user roles');
      }
    } else {
      toast.warning('Please select user');
    }
  };

  useEffect(() => {
    if (!isLoading || !memberDetail?.id) {
      return;
    }
    (async () => {
      const response = await serviceInterceptor
        .post(
          `/members/${memberDetail.id}/users?userRoleRequests=${JSON.stringify(selected.map((userId, index) => ({ userId, roleId: selectedRoles[index]})))}`,
        );
      if (!response?.data?.success) {
        toast.error(response?.data?.message);
        setLoading(false);
        return;
      }
      toast.success(response.data.message);
      getMemberUsers();
      hideAssignMemberUserModal();
      handleLoadAssigneduser();
      setLoading(false);
    })();
  }, [isLoading]);

  const isAssignUserMemberAdmin =
    assignedUsers && assignedUsers.filter((item) => item.roleid === 3);
  const roleOptions =
    isAssignUserMemberAdmin && isAssignUserMemberAdmin.length > 0
      ? roles.filter((item) => item.id !== 3)
      : roles;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      className="dialog-box"
    >
      {isLoading && (
        <div className="loader-wrapper">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      )}
      <DialogHead onClose={handleClose}>Assign User</DialogHead>
      <StyledDialogContent>
        {searchRows.length > 0 ? (
          <div className="page-search-box">
            <i className="fas fa-search"></i>
            <InputBase
              placeholder="Search"
              value={searchText.toString()}
              onChange={(event) => onChangeSearchText(event)}
              disabled={isLoading}
            />
          </div>
        ) : null}

        <div className="table-wrapper">
          <div className={stylesObj.root}>
            {rows.length > 0 ? (
              <TableContainer>
                <Table
                  className={stylesObj.table}
                  aria-labelledby="tableTitle"
                  size={dense ? 'small' : 'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={stylesObj}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(rows, getComparator(order, orderBy))
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.id);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        const name = `${row.firstname} ${row.lastname}`
                        return (
                          <TableRow
                            role="checkbox"
                            aria-checked={isItemSelected}
                            selected={isItemSelected}
                            tabIndex={-1}
                            key={`MUUL-${page}${index}`}
                          >
                            <TableCell
                              padding="checkbox"
                              // hover
                              onClick={() => handleCheckboxClick(row.id)}
                            >
                              <Checkbox
                                checked={isItemSelected}
                                inputProps={{ 'aria-labelledby': labelId }}
                              />
                            </TableCell>
                            <TableCell id={labelId} scope="row" padding="none" style={{width:'60%'}}>
                              <div className="table-member-details">
                                <div className="member-img">
                                  {row.avatar && row.avatar.length > 0 ? (
                                    <img src={row.avatar} />) : (name ? (
                                      <Avatar {...StringAvatar(name)} />) : (
                                      <img src={DefaultMemberImg} />)
                                  )}
                                </div>
                                <div className="member-profile-details">
                                  <div className="member-name">
                                    {row.firstname} {row.lastname}
                                  </div>
                                  <div className="member-mail-id">
                                    {row.email}
                                  </div>
                                </div>
                              </div>
                            </TableCell>
                            <TableCell align="left">
                              <Select
                                native
                                value={row.roleid}
                                className="select-box-general"
                                onChange={(e) => {
                                  let tempRows: MemberUsers = [...rows];
                                  let tempUpdatedRows: MemberUsers =
                                    tempRows.map((item) => {
                                      if (row.id === item.id) {
                                        item.roleid = e.target.value as number;
                                      }
                                      return item;
                                    });
                                  setRows(tempUpdatedRows);
                                }}
                                label="Role"
                                inputProps={{
                                  name: 'role',
                                  id: 'outlined-age-native-simple',
                                }}
                                disabled={isLoading}
                                required
                              >
                                <option value={''}>{'Select'}</option>
                                {roleOptions.map((item, index) => {
                                  return (
                                    <option
                                      key={`user-role-${index}`}
                                      value={item.id}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Select>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            ) : null}
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            {searchText && rows.length == 0 ? (
              <SearchNotFound userType={'user'} />
            ) : rows.length == 0 ? (
              <ItemNotFound userType={'user'} />
            ) : null}
          </div>
        </div>
      </StyledDialogContent>
      <StyledDialogActions>
        <div className="modal-buttons-container">
          <div>
            <button
              className="create-user-button"
              onClick={() => {
                clearAddMemberUser();
                handleOpenCreate();
              }}
              disabled={isLoading}
            >
              Create New User
            </button>
          </div>
          <div>
            <button
              className="cancel-user-button"
              onClick={handleClose}
              disabled={isLoading}
            >
              Cancel
            </button>
            <Button
              autoFocus
              onClick={inviteUser}
              className="invite-user-button"
              disabled={isLoading}
            >
              Assign &amp; Invite User
            </Button>
          </div>
        </div>
      </StyledDialogActions>
    </Dialog>
  );
};

export type MemberUserListPopupType = typeof MemberUserListPopup;
