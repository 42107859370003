import { FC, MouseEventHandler } from 'react';
import { Button, Tooltip } from '@mui/material';
import { DwollaFundingSource, DwollaTransfer } from '../../../common';

export const Action: FC<{
  deliveredAt?: Date | null;
  fundingSource?: DwollaFundingSource;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  transferInfo?: DwollaTransfer;
}> = ({ deliveredAt, fundingSource, onClick, transferInfo }) => {
  let title = 'Required action',
    buttonClassName = 'btn-circle-trash',
    iconClassName = 'fas fa-exclamation';
  if (transferInfo) {
    title = 'View transfer details'
    buttonClassName = 'btn-circle-info',
    iconClassName = 'fas fa-info';
  } else if (fundingSource) {
    title = 'ACH transfer request';
  } else if (deliveredAt) {
    title = 'Completed';
  }

  if (title === 'Completed') {
    return (
      <Button
        style={{ cursor: 'inherit' }}
        className="btn-circle-tick"
        variant="outlined"
        color="secondary"
      >
        <i className="fas fa-check" />
      </Button>
    );
  }

  return (
    <Tooltip title={title}>
      <Button
        className={buttonClassName}
        variant="outlined"
        color="secondary"
        onClick={onClick}
      >
        <i className={iconClassName} />
      </Button>
    </Tooltip>
  );
};

export default Action;
