export const addUser = (userObject) => {
  return {
    type: "SET_USER",
    payload: userObject,
  };
};

export function clearUser() {
  return {
    type: "CLEAR_USER",
    payload: null,
  };
}
