// Members
export const UPDATE_MEMBERS_LIST_ERROR = 'UPDATE_MEMBERS_LIST_ERROR';
export const UPDATE_MEMBER_USER_LIST_ERROR = 'UPDATE_MEMBER_USER_LIST_ERROR';
export const UPDATE_MEMBER_VENDOR_LIST_ERROR = 'UPDATE_MEMBER_VENDOR_LIST_ERROR';
export const UPDATE_MEMBER_LOCATION_LIST_ERROR = 'UPDATE_MEMBER_LOCATION_LIST_ERROR';
export const GET_MEMBER_REBATES_INFO_ERROR = 'GET_MEMBER_REBATES_INFO_ERROR';
export const GET_MEMBER_REBATES_EDIT_ERROR = 'GET_MEMBER_REBATES_EDIT_ERROR';

// success
// error
export const MemberListError = 'Unable to fetch members list';
export const MemberUserListError = 'Unable to fetch users list';
export const MemberVendorListError = 'Unable to fetch vendors list';
export const MemberLocationListError = 'Unable to fetch locations list';
