import { FC, useEffect, useState } from 'react';
import { Loader } from '../../../../../utils';
import { UserActions } from './userActions';
import { UserTable } from '../../tableUtils/userTable';

// popup window
import MemberUserListPopup from './modal/MemberUserListPopup';
import { AddMemberUser } from './modal/addMemberUser';
import { WarningPopup } from '../../../../../utils';
import { ConnectedPagination } from '../../../../../components/ConnectedPagination';
import { getPaginatedData } from '../../../../../utils';

const gridHeader = [
  {
    label: 'Users',
    value: 'firstname',
    sortKey: 'firstname',
    xs: 12,
    lg: 6,
  },
  {
    label: 'Role',
    value: 'rolename',
    sortKey: 'rolename',
    xs: 12,
    lg: 4,
  },
  {
    label: 'Action',
    value: 'action',
    xs: 12,
    lg: 2,
  },
];

export const MemberUserTab: FC<any> = (props) => {
  const {
    memberDetail,
    getMemberUsers,
    members,
    common: { rolesList },
    modal: { showModal, isCreate },
    showAssignMemberUserModal,
    showAddMemberUserModal,
    hideMemberUserModal,
    handleEditMemberUser,
    handleDeleteMemberUser,
    onDeleteMemberUser,
    paginationReducer: { rowsPerPage, page },
    handleChangeRowsPerPage,
    handlePageType,
    handleDeleteUserMember,
    onDeleteUserMember,
    viewAsMember,
  } = props;
  const {
    usersList,
    userListSpinner,
    isMemberUserDelete,
    selectedUser,
    memberModalSpinner,
    isUserMemberDelete,
  } = members;
  const { firstname, lastname } = selectedUser || '';
  const selectedDeleteUser = `${firstname} ${lastname}`;
  const { assignedUsers, unassignedUsers } = usersList || [];

  const [userAssignedList, setUserAssignedList] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState(null);
  const [sortType, updateSortType] = useState('');

  const memberName = memberDetail?.name ? memberDetail?.name : '-';

  const setSortKey = (newSortKey: any) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  useEffect(() => {
    getMemberUsers();
  }, []);

  useEffect(() => {
    setUserAssignedList(assignedUsers);
    handleChangeRowsPerPage(10);
  }, [assignedUsers]);

  const modifiedList = getPaginatedData(userAssignedList, rowsPerPage, page);

  const userRoles = rolesList.filter(
    (item: any) => item.description.indexOf('Member') > -1,
  );
  const memberAdminRole = userRoles.filter(
    (item: any) => item.description.indexOf('Admin') > -1,
  );
  const validMemberUsers = assignedUsers?.filter(
    (item: any) =>
      item.rolename && item.rolename.toLowerCase().indexOf('br admin') == -1,
  );

  const filteredData =
    searchText && modifiedList.length > 0
      ? modifiedList.filter((lineItem: any) => {
          return Object.keys(lineItem).some((key) => {
            const searchItem = lineItem[key] && lineItem[key].toString();
            return (
              searchItem &&
              searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1
            );
          });
        })
      : modifiedList;

  const sortedData =
    sortKey && sortType && filteredData.length > 0
      ? filteredData &&
        filteredData.sort((a: any, b: any) => {
          if (sortType === 'DESC') {
            return b[sortKey].localeCompare(a[sortKey]);
          }
          return a[sortKey].localeCompare(b[sortKey]);
        })
      : filteredData;

  return (
    <div className="vendor-assign-page">
      <UserActions
        searchText={searchText}
        assignedList={assignedUsers || []}
        setSearchText={setSearchText}
        handleClickOpen={showAssignMemberUserModal}
      />

      <div className="relative-wrapper">
        {userListSpinner ? (
          <div className="loader-wrapper list-spinner">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        ) : (
          <>
            <UserTable
              gridHeader={gridHeader}
              gridData={sortedData ? sortedData : []}
              handleEditMemberUser={handleEditMemberUser}
              handleDeleteMemberUser={handleDeleteMemberUser}
              handleDeleteUserMember={handleDeleteUserMember}
              setSortKey={setSortKey}
              sortType={sortType}
              memberName={memberName}
              viewAsMember = {viewAsMember}
            />
            {userAssignedList?.length > 5 && sortedData?.length > 0 ? (
              <ConnectedPagination count={userAssignedList?.length} />
            ) : null}
          </>
        )}

        {showModal && !isCreate && (
          <MemberUserListPopup
            open={showModal}
            handleClose={hideMemberUserModal}
            handleOpenCreate={showAddMemberUserModal}
            memberDetail={memberDetail}
            userList={unassignedUsers}
            assignedUsers={assignedUsers}
            userRoles={userRoles}
            {...props}
          />
        )}

        {showModal && isCreate && (
          <AddMemberUser
            handleBacktoPrimary={showAssignMemberUserModal}
            memberDetail={memberDetail}
            moduletype={2}
            userRoles={userRoles}
            memberAdminRole={memberAdminRole}
            assignedUsers={validMemberUsers}
            {...props}
          />
        )}

        {
          isUserMemberDelete ? (
            <WarningPopup
              open={isUserMemberDelete}
              type={'delete'}
              name={selectedDeleteUser}
              handleProceed={onDeleteUserMember}
              spinner={memberModalSpinner}
              handleClose={() => handleDeleteUserMember(false, null)}
            />
          ) : (
            <WarningPopup
              open={isMemberUserDelete}
              type={'unassign'}
              name={selectedDeleteUser}
              handleProceed={onDeleteMemberUser}
              spinner={memberModalSpinner}
              handleClose={() => handleDeleteMemberUser(false, null)}
            />
          )
        }
      </div>
    </div>
  );
};
