import {
  UPDATE_REBATES_MEMBER_OPTION_SPINNER,
  UPDATE_REBATES_MEMBER_QUARTER_SPINNER,
  UPDATE_REBATES_MEMBER_YEAR_SPINNER,
  UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER,
  UPDATE_SELECTED_REBATES_MEMBER,
  UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST,
  UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
  UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER,
  UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR,
  UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST,
  UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST,
  GET_REBATE_MEMBERS_LIST_ERROR,
  GET_REBATE_QUARTERS_LIST_ERROR,
  GET_REBATE_MEMBER_PRODUCT_LIST_ERROR,
  MemberAdminRebatesMemberListError,
  MemberAdminRebatesQuartersListError,
  MemberAdminRebatesProductListError,
  FetchError,
} from '../../../constants';

import {
  getRebateMembersList,
  getMemberProductList,
} from '../../../api/rebates/memberAdminRebates/EarnedRebates';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import serverApiV2Instance from '../../../services/serverApiV2Instance';

export const memberAdminRebatesOptionSpinner =
  (memberOptionSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_REBATES_MEMBER_OPTION_SPINNER,
      memberOptionSpinner,
    });
  };

export const memberAdminQuarterSpinner =
  (memberAdminQuarterSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_REBATES_MEMBER_QUARTER_SPINNER,
      memberAdminQuarterSpinner,
    });
  };

export const memberAdminRebatesYearSpinner =
  (memberAdminRebatesYearSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_REBATES_MEMBER_YEAR_SPINNER,
      memberAdminRebatesYearSpinner,
    });
  };

export const memberAdminRebatesListSpinner =
  (productListSpinner) => async (dispatch) => {
    dispatch({
      type: UPDATE_REBATES_MEMBER_PRODUCT_LIST_SPINNER,
      productListSpinner,
    });
  };

export const getRebateMemberOptions = () => async (dispatch) => {
  dispatch(memberAdminRebatesOptionSpinner(true));
  try {
    //const res = await getRebateMembersList();
    const query = new URLSearchParams({
      pageNumber: '1',
      pageSize: '100',
      sortOrder: 'desc',
    });
    const res = await serverApiV2Instance.get(
      `/members?${query.toString()}`,
    );
    const { data } = res;

    if (data) {
      dispatch({
        type: UPDATE_SELECTED_REBATES_MEMBER_OPTION_LIST,
        memberOptionList: data ? data.records : [],
      });
      const selectedMember = data ? data.records[0] : null;
      dispatch(getPaginatedRebateMemberProducts(selectedMember));
      dispatch(memberAdminRebatesOptionSpinner(false));
    }
    dispatch(memberAdminRebatesOptionSpinner(false));
  } catch (error) {
    dispatch(memberAdminRebatesOptionSpinner(false));
    if (error === GET_REBATE_MEMBERS_LIST_ERROR) {
      toast.error(MemberAdminRebatesMemberListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getRebateMemberProducts =
  (selectedMember) => async (dispatch, getstate) => {
    const { memberAdminRebates } = getstate();
    const { quarter, year } = memberAdminRebates;
    dispatch({
      type: UPDATE_SELECTED_REBATES_MEMBER,
      selectedMember,
    });
    dispatch(memberAdminRebatesListSpinner(true));
    try {
      const res = await getMemberProductList({
        memberId: selectedMember.id,
        quarter,
        year,
      });
      const { success, message, data } = res;
      if (success) {
        const formattedData =
          data &&
          data.map((item) => {
            return {
              ...item,
              orderSize: item.orderSize.toString(),
              amountEarned: item.amountEarned.toString(),
            };
          });
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: formattedData,
        });
        dispatch(memberAdminRebatesListSpinner(false));
      } else {
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: [],
        });
      }
      dispatch(memberAdminRebatesListSpinner(false));
    } catch (error) {
      dispatch(memberAdminRebatesListSpinner(false));
      if (error === GET_REBATE_MEMBER_PRODUCT_LIST_ERROR) {
        toast.error(MemberAdminRebatesProductListError);
      } else {
        toast.error(FetchError);
      }
    }
  };


export const getPaginatedRebateMemberProducts =
  (selectedMember) => async (dispatch, getstate) => {
    const { memberAdminRebates } = getstate();
    const { quarter, year } = memberAdminRebates;
    const query = new URLSearchParams({
      quarter: quarter ? quarter : '',
      year: year ? year : '',
      orderBy: 'productName',
      sortOrder: 'asc',
      pageNumber: '1',
      pageSize: '20',
    });
    dispatch({
      type: UPDATE_SELECTED_REBATES_MEMBER,
      selectedMember,
    });
    dispatch(memberAdminRebatesListSpinner(true));
    try {

      const res = await serverApiV2Instance.get(
        `/members/${selectedMember?.id}/earned-rebates?${query.toString()}`,
      );
      const { success, message, data } = res;
      if (success) {
        const formattedData =
          data?.data &&
          data.data?.records.map((item) => {
            return {
              ...item,
              orderSize: item.orderSize.toString(),
              amountEarned: item.amountEarned.toString(),
            };
          });
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: formattedData,
        });
        dispatch(memberAdminRebatesListSpinner(false));
      } else {
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: [],
        });
      }
      dispatch(memberAdminRebatesListSpinner(false));
    } catch (error) {
      dispatch(memberAdminRebatesListSpinner(false));
      if (error === GET_REBATE_MEMBER_PRODUCT_LIST_ERROR) {
        toast.error(MemberAdminRebatesProductListError);
      } else {
        toast.error(FetchError);
      }
    }
  };

export const getEarnedRebateQuarters = () => async (dispatch) => {
  dispatch(memberAdminRebatesOptionSpinner(true));
  try {
    let earnedRebateQuarterList = [];
    const res = await serverApiV2Instance.get(
      `/earned-rebate-quarters`,
    );
    const { data } = res;
    if (data) {
      earnedRebateQuarterList = data ? data.records : [];
      let quartersList = [];
      if (earnedRebateQuarterList.length > 0) {
        dispatch({
          type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER,
          quarter: +earnedRebateQuarterList[0]?.quarter,
        });

        dispatch({
          type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR,
          year: earnedRebateQuarterList[0]?.year,
        });
      }
      earnedRebateQuarterList && earnedRebateQuarterList.map(item => {
        const currentQuarter = { label: `Q${item.quarter}`, value: item.quarter };
        const index = quartersList.findIndex(x => x.value === item.quarter);
        if (index < 0) {
          quartersList.push(currentQuarter);
        }
      });
      let yearsList = [];
      earnedRebateQuarterList && earnedRebateQuarterList.map(item => {
        const currentYear = { label: item.year, value: item.year };
        const index = yearsList.findIndex(x => x.value === item.year);
        if (index < 0) {
          yearsList.push(currentYear);
        }
      });
      dispatch({
        type: UPDATE_SELECTED_REBATES_MEMBER_QUARTERS_LIST,
        memberQuartersList: quartersList,
      });
      dispatch({
        type: UPDATE_SELECTED_REBATES_MEMBER_YEARS_LIST,
        memberYearsList: yearsList,
      });
      dispatch(memberAdminRebatesOptionSpinner(false));
    }
    dispatch(memberAdminRebatesOptionSpinner(false));
  } catch (error) {
    dispatch(memberAdminRebatesOptionSpinner(false));
    if (error === GET_REBATE_QUARTERS_LIST_ERROR) {
      toast.error(MemberAdminRebatesQuartersListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const updateMemberAdminRebatesSelectedMember = (selectedMember) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_REBATES_MEMBER,
    selectedMember,
  });
}

export const updateMemberAdminRebatesQuarter = (selectedQuarter) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER,
    quarter: selectedQuarter,
  });
}

export const updateMemberAdminRebatesYear = (selectedYear) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR,
    year: selectedYear,
  });
}

export const updateMemberAdminQuarter =
  (selectedQuarter) => async (dispatch, getstate) => {
    dispatch({
      type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_QUARTER,
      quarter: selectedQuarter,
    });
    const { memberAdminRebates } = getstate();
    const { selectedMember, year } = memberAdminRebates;
    dispatch(memberAdminRebatesListSpinner(true));
    try {
      const res = await getMemberProductList({
        memberId: selectedMember.id,
        quarter: selectedQuarter,
        year,
      });
      const { success, message, data } = res;
      if (success) {
        const formattedData =
          data &&
          data.map((item) => {
            return {
              ...item,
              orderSize: item.orderSize.toString(),
              amountEarned: item.amountEarned.toString(),
            };
          });
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: formattedData,
        });
        dispatch(memberAdminRebatesListSpinner(false));
      } else {
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: [],
        });
      }
      dispatch(memberAdminRebatesListSpinner(false));
    } catch (error) {
      dispatch(memberAdminRebatesListSpinner(false));
      if (error === GET_REBATE_MEMBER_PRODUCT_LIST_ERROR) {
        toast.error(MemberAdminRebatesProductListError);
      } else {
        toast.error(FetchError);
      }
    }
  };

export const updateMemberAdminYear =
  (selectedYear) => async (dispatch, getstate) => {
    dispatch({
      type: UPDATE_MEMBER_ADMIN_REBATES_EARNING_YEAR,
      year: selectedYear,
    });
    const { memberAdminRebates } = getstate();
    const { selectedMember, quarter } = memberAdminRebates;
    dispatch(memberAdminRebatesListSpinner(true));
    try {
      const res = await getMemberProductList({
        memberId: selectedMember.id,
        quarter,
        year: selectedYear,
      });
      const { success, message, data } = res;
      if (success) {
        const formattedData =
          data &&
          data.map((item) => {
            return {
              ...item,
              orderSize: item.orderSize.toString(),
              amountEarned: item.amountEarned.toString(),
            };
          });
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: formattedData,
        });
        dispatch(memberAdminRebatesListSpinner(false));
      } else {
        dispatch({
          type: UPDATE_SELECTED_REBATES_MEMBER_PRODUCT_LIST,
          memberProductList: [],
        });
      }
      dispatch(memberAdminRebatesListSpinner(false));
    } catch (error) {
      dispatch(memberAdminRebatesListSpinner(false));
      if (error === GET_REBATE_MEMBER_PRODUCT_LIST_ERROR) {
        toast.error(MemberAdminRebatesProductListError);
      } else {
        toast.error(FetchError);
      }
    }
  };
