import { MemberCreateError } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';
import { removePhoneNoFormat } from '../../utils/constants';
import { removeZipcodeFormat } from '../../utils';

export const createMember = async ({
  parentId,
  name,
  street,
  city,
  state,
  zip,
  phone,
  mobile,
  joiningDate,
  canHandDeliver,
  avatar,
  avatarFile,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('parentId', parentId ? parentId : '');
    bodyFormData.append('name', name);
    bodyFormData.append('street', street);
    bodyFormData.append('city', city);
    bodyFormData.append('state', state);
    bodyFormData.append('zip', removeZipcodeFormat(zip));
    bodyFormData.append('phone', removePhoneNoFormat(phone));
    bodyFormData.append('mobile', removePhoneNoFormat(mobile));
    bodyFormData.append('startDate', joiningDate);
    bodyFormData.append('canHandDeliver', canHandDeliver === true ? 1 : 0);
    bodyFormData.append('avatar', avatarFile || avatar);
    bodyFormData.append('contactFirstName', 'Admin');
    bodyFormData.append('contactLastName', '');

    const response = await serviceInterceptor.post('/members', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response?.data) {
      throw MemberCreateError;
    }
    return response.data;
  } catch (error) {
    throw MemberCreateError;
  }
};
