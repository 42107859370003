import {
    SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER,
    SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER,
    UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST,
    UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER,
    UPDATE_DASHBORD_OPPORTUNITY_LIST
} from "../../constants";

const initialState = {
    dashboardOppQuarterSpinner: false,
    dashboardOpportunityListSpinner: false,
    dashboardOppQuarterList: [],
    dashboardOppActiveQuarter: null,
    dashboardOppList: []
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER:
            return ({
                ...state,
                dashboardOppQuarterSpinner: action.dashboardOppQuarterSpinner
            });
        case SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER:
            return ({
                ...state,
                dashboardOpportunityListSpinner: action.dashboardOpportunityListSpinner
            });
        case UPDATE_DASHBORD_OPPORTUNITY_QUARTER_LIST:
            return ({
                ...state,
                dashboardOppQuarterList: action.dashboardOppQuarterList
            });
        case UPDATE_DASHBORD_OPPORTUNITY_ACTIVE_QUARTER:
            return ({
                ...state,
                dashboardOppActiveQuarter: action.dashboardOppActiveQuarter
            });
        case UPDATE_DASHBORD_OPPORTUNITY_LIST:
            return ({
                ...state,
                dashboardOppList: action.dashboardOppList
            });
        default:
            return state;
    }
};
