import { FC, Fragment, useState } from 'react';
import SideMenuItem from './SideMenuItem';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import AuthorizationService from '../../authorization/AuthorizationService';
import { useLocation, Link } from 'react-router-dom';
import Divider from '@material-ui/core/Divider';

export const SideMenu: FC<{ mobileMenuClose: any; isMobileMenu: any; vendorID: (data:any) => any }> = ({ mobileMenuClose, isMobileMenu,vendorID }) => {
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const location = useLocation();

  const handleChange = (panel: any) => (_: any, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const MenuHTML: FC<{ menuItem: any; i: number }> = ({ menuItem, i }) => (
    <Accordion
      expanded={expanded === 'panel' + i}
      onChange={handleChange('panel' + i)}
      className={
        location?.pathname === menuItem?.url
          ? 'side-menu-accordion active-menu'
          : 'side-menu-accordion'
      }
    >
      <AccordionSummary
        expandIcon={menuItem?.subMenu?.length ? <ExpandMoreIcon /> : null}
        aria-controls="panel4bh-content"
        className="panel4bh-header"
      >
        <SideMenuItem
          menuTitle={menuItem?.label}
          pathName={menuItem?.url}
          iconClassName={menuItem?.iconStyle}
          isMobileMenu={isMobileMenu}
          mobileMenuClose={mobileMenuClose}
        />
      </AccordionSummary>
      {menuItem.subMenu.length ? (
        <SubMenuHTML subMenu={menuItem.subMenu} />
      ) : null}
    </Accordion>
  );

  const SubMenuHTML: FC<{ subMenu: any[] }> = ({ subMenu }) => (
    <AccordionDetails>
      {subMenu.map((subItem) => (
        <Fragment>
          <Link
            to={subItem?.url}
            className={
              location.pathname === subItem?.url
                ? 'side-bar-item active'
                : 'side-bar-item'
            }
            onClick={() => {
              isMobileMenu && mobileMenuClose();
              if(subItem?.isVendor){
                const ID = location.pathname === "/vendors/branded-image" ? 2 : subItem?.menuId
                vendorID(subItem?.menuId)
              }
            }}
            style={{paddingRight : '5px'}}
          >
            <span key={subItem?.label} className='side-menu-expand-list' style={{paddingRight : subItem?.menuId === 1 ? '10px' : 0}}>
              {subItem?.label}
            </span>
          </Link>
          <Divider variant="middle" />
        </Fragment>

      ))}
    </AccordionDetails>
  );

  return (
    <div className="side-menu-section">
      {AuthorizationService.getAuthorizedSideMenu().map(
        (menuItem: any, i: number) => (
          menuItem ? <MenuHTML key={i} i={i} menuItem={menuItem} /> : null
        ),
      )}
    </div>
  );
};

export default SideMenu;
