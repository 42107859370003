import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { rolesEnum } from '../../../../../authorization/rolesConfiguration';
import { FC } from 'react';

export const RoleSelect: FC<any> = ({
  item,
  isChecked,
  handleUserRoleChange,
}) => (
  <FormControl variant="outlined" className="select-box">
    <InputLabel style={{ color: 'red' }} />
    <Select
      native
      value={item?.roleId !== null ?  item.roleId : ''}
      onChange={(e) => handleUserRoleChange(e.target.value, item)}
      disabled={!isChecked}
    >
      <option value={''}>{'Select'}</option>
      {Object.keys(rolesEnum).map((obj) => {
        return (
          (rolesEnum as any)[obj].group === 'vendor' && (
            <option value={(rolesEnum as any)[obj].roleId}>
              {(rolesEnum as any)[obj].label}
            </option>
          )
        );
      })}
    </Select>
  </FormControl>
);
