import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CLEAR_ADSTYPE_FORM,
  UPDATE_ADSTYPE_FORM_SPINNER,
  UPDATE_ADSTYPE_IS_UPDATED,
  UPDATE_ADSTYPE_NAME,
  UPDATE_ADSTYPE_DESC,
  UPDATE_ADSTYPE_NAME_ERROR,
  UPDATE_ADSTYPE_DESC_ERROR,
  AdsTypeCreateSuccess,
  AdsTypeCreateError,
  UPDATE_ADSTYPE_LIST,
} from "../../constants";

import {createAdType, getAdTypesList} from "../../api/manageads";
import {handleCloseAdsTypeModal} from "./adsList";

export const clearAdsTypeForm = () => async (dispatch) => {
  dispatch({
    type: CLEAR_ADSTYPE_FORM,
  });
};
export const handleChangeAdTypeName = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADSTYPE_NAME,
    value: value,
  });
  dispatch({
    type: UPDATE_ADSTYPE_NAME_ERROR,
    flag: value ? false : true,
  });
  dispatch({
    type: UPDATE_ADSTYPE_IS_UPDATED,
    flag: true,
  });
};
export const handleChangeAdTypeDescription = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_ADSTYPE_DESC,
    value: value,
  });
  dispatch({
    type: UPDATE_ADSTYPE_DESC_ERROR,
    flag: value ? false : true,
  });
  dispatch({
    type: UPDATE_ADSTYPE_IS_UPDATED,
    flag: true,
  });
};
export const onAddNewAdType = () => async (dispatch, getState) => {
  const {adsTypeCreate} = getState();
  const {adTypeName, adTypeDesc} = adsTypeCreate;

  const isValidName = adTypeName && adTypeName.trim().length > 0 ? true : false;
  const isValidDescription =
    adTypeDesc && adTypeDesc.trim().length > 0 ? true : false;

  const isError = !isValidName || !isValidDescription;

  if (!isValidName) {
    dispatch({
      type: UPDATE_ADSTYPE_NAME_ERROR,
      flag: true,
    });
  }
  if (!isValidDescription) {
    dispatch({
      type: UPDATE_ADSTYPE_DESC_ERROR,
      flag: true,
    });
  }
  if (isError) {
    dispatch({
      type: UPDATE_ADSTYPE_FORM_SPINNER,
      spinner: false,
    });
  } else {
    dispatch({
      type: UPDATE_ADSTYPE_FORM_SPINNER,
      spinner: true,
    });
  }
  try {
    if (!isError) {
      const response = await createAdType({
        adTypeName,
        adTypeDesc,
      });
      const {success, message} = response;
      success ? toast.success(AdsTypeCreateSuccess) : null;
      if (success) {
        const adTypesList = await getAdTypesList();
        dispatch({
          type: UPDATE_ADSTYPE_LIST,
          list: adTypesList.data,
        });

        dispatch(handleCloseAdsTypeModal());
      } else {
        toast.error(message ? message : AdsTypeCreateError);
      }

      dispatch({
        type: UPDATE_ADSTYPE_FORM_SPINNER,
        spinner: false,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_ADSTYPE_FORM_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: CLEAR_ADSTYPE_FORM,
      });
    }
    if (error) {
      toast.error(AdsTypeCreateError);
    }
  }
};
