import { FC } from 'react';
import AvailableRebates from '../../../../../images/money-red.svg';
import PendingRebates from '../../../../../images/money-orange.svg';
import AcquiredRebates from '../../../../../images/money-green.svg';
import { centsToFormattedDollars } from '../../../../../utils';

export const RebateReceiptCounts: FC<{
  rebateReceipts: {
    rebatesAmount: {
      availableAmount: number;
      acquiredAmount: number;
      pendingAmount: number;
    };
  };
}> = (props) => {
  const { rebateReceipts } = props;
  const { rebatesAmount } = rebateReceipts || {};
  const {
    availableAmount,
    acquiredAmount: acquiredAmount,
    pendingAmount,
  } = rebatesAmount || {};

  return (
    <div className="rebates-dollar-wrapper">
      <div className="mode-rebate-total">
        <div className="mode-rebate-total-icon">
          <div className="rebate-icon">
            <img src={AvailableRebates} alt="buyright" title="buyright" />
          </div>
        </div>
        <div className="mode-rebate-total-text">
          <span className="mode-rebate-total-amount">
            {centsToFormattedDollars(availableAmount)}
          </span>
          <span className="mode-rebate-total-info">Available Rebates</span>
        </div>
      </div>
      <div className="mode-rebate-total">
        <div className="mode-rebate-total-icon mode-rebate-pending-icon">
          <div className="rebate-icon">
            <img src={PendingRebates} alt="buyright" title="buyright" />
          </div>
        </div>
        <div className="mode-rebate-total-text">
          <span className="mode-rebate-total-amount">
            {centsToFormattedDollars(pendingAmount)}
          </span>
          <span className="mode-rebate-total-info">Pending Rebates</span>
        </div>
      </div>
      <div className="mode-rebate-total">
        <div className="mode-rebate-total-icon">
          <div className="rebate-icon">
            <img src={AcquiredRebates} alt="buyright" title="buyright" />
          </div>
        </div>
        <div className="mode-rebate-total-text">
          <span className="mode-rebate-total-amount">
            {centsToFormattedDollars(acquiredAmount)}
          </span>
          <span className="mode-rebate-total-info">Acquired Rebates</span>
        </div>
      </div>
    </div>
  );
};

export default RebateReceiptCounts;
