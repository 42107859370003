import { Button } from '@material-ui/core';
import { useMachine } from '@xstate/react';
import { FC, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import serviceInterceptor from '../../services/ServiceInterceptor';
import { requestResetAccountPasswordMachine } from './requestResetAccountPasswordMachine';
import { Loader } from '../../utils';

export const ResetPasswordForm: FC = () => {
  const navigate = useNavigate();
  const [spinner, setSpinner] = useState(false);
  const [state, send] = useMachine(requestResetAccountPasswordMachine, {
    actions: {
      notifyFailure: (_, event) => {
        if ((event as any)?.data?.message) {
          return toast.error((event as any).data.message);
        }
        toast.error(
          'Failed to reset the account password, an unknown server error occurred.',
        );
      },
      notifyInvalidEmail: (context) => {
        toast.error(
          context.email
            ? 'Please enter a valid email.'
            : 'Your email is required to reset your account password.',
        );
      },
      notifySuccess: () => {
        if ((event as any)?.data?.message) {
          return toast.success((event as any).data.message);
        }
        toast.success('Successfully requested an account password reset, please check your email.');
      },
      redirectToLogin: () => navigate('/'),
    },
    services: {
      submitEmailForAccountPasswordReset: (context) =>
        serviceInterceptor
          .post(`/reset-password?email=${context.email}`)
          .then((response) => {
            setSpinner(false);
            return response?.data;

          })
          .catch((error) => {
            setSpinner(false)
            return error?.data;
          }),
    },
  });
  return (
    <form
      onSubmit={(event) => {
        setSpinner(true);
        event.preventDefault();
        send('submit');
      }}
    >
      <div className="form-group">
        <label className="form-label">Email Address</label>
        <input
          value={state.context.email}
          type="text"
          className="form-control"
          placeholder="Email"
          onChange={(event) =>
            send({
              type: 'updateEmail',
              email: event.target.value,
            })
          }
        />
      </div>
      <Button 
      type="submit" 
      className="btn btn-sign-in"
      disabled={spinner}>
        Submit {spinner && <Loader size={15} color={'white'} />}
      </Button>
      <div style={{ textAlign: 'center' }}>
        <a
          href="/"
          className="forgot-password-link"
        >
          Back to Login
        </a>
      </div>
    </form>
  );
};

export type ResetPasswordFormType = typeof ResetPasswordForm;
export default ResetPasswordForm;
