import { Grid, GridSize } from '@material-ui/core';
import { FC } from 'react';
import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';

const gridHeader = [
    {
        label: 'Name',
        value: 'name',
        sortKey: 'name',
        xs: 12,
        lg: 3,
    },
    {
        label: 'Location',
        value: 'location',
        sortKey: 'location',
        xs: 12,
        lg: 3,
    },
    {
        label: 'Member Admin',
        value: 'primaryContact',
        sortKey: 'primaryContact',
        xs: 12,
        lg: 2,
    },
    {
        label: 'Phone Number',
        value: 'phoneNumber',
        sortKey: 'phoneNumber',
        xs: 12,
        lg: 2,
    },
    {
        label: 'Mobile Number',
        value: 'mobileNumber',
        sortKey: 'mobileNumber',
        xs: 12,
        lg: 2,
    }
];

export const CommunityMembersListHeaderRow: FC<{
    orderBy: string;
    sortOrder: 'asc' | 'desc';
    setOrderBy: (orderBy: string) => void;
}> = ({ orderBy, sortOrder, setOrderBy }) => (
    <Grid container spacing={0} className="tr thead">
        {gridHeader.map((item, index) => {
            const { label, value, xs, lg, sortKey } = item;
            const isValidHeader = (label !== '' && (sortKey !== '' || sortKey !== undefined));

            return (
                <Grid
                    className="th"
                    item
                    xs={xs as GridSize}
                    lg={(lg as GridSize)}
                    key={index}
                >
                    <div onClick={() => sortKey && setOrderBy(sortKey)} >
                        <span
                            className="grid-header"
                        >
                            {label}
                        </span>
                        {<span className="sort-icon">
                            {isValidHeader && (
                                sortKey === orderBy ? (
                                    <img
                                        className="sort-svg"
                                        src={sortOrder === 'asc' ? sortAscIcon : sortDescIcon}
                                    />
                                ) : (
                                    <img className="sort-svg" src={sortDefault} />
                                )
                            )}
                        </span>
                        }
                    </div>
                </Grid>
            );
        })}
    </Grid>
);

export default CommunityMembersListHeaderRow;
