import {GET_MEMBER_LIST_API, UPDATE_VENDOR_MEMBER_KPI_DATA} from "../../constants";


export const getVendorMemberKPIData = async ({
    memberID,
    kpiYear,
    kpiQuarter
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_MEMBER_LIST_API}/${memberID}/kpi?year=${kpiYear}&quarter=${kpiQuarter}`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
        }
        );
        if (!response.ok) throw UPDATE_VENDOR_MEMBER_KPI_DATA;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_VENDOR_MEMBER_KPI_DATA;
    }
}

