import { FC } from 'react';
import { Grid, InputBase } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import AuthorizationService from '../../../../authorization/AuthorizationService';

export const SalesPersonnelHeader: FC<{
  handleClickOpen: (e: any) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  state: any;
  send: any;
}> = ({ handleClickOpen, searchText, setSearchText, state, send }) => (
  <Grid
    container
    direction="row"
    alignItems="center"
    className="filter-form-header"
  >
    <Grid>
      <Grid>
        <div className="page-search-box">
          <i className="fas fa-search" />
          <InputBase
            placeholder="Search"
            value={searchText && searchText.toString()}
            onChange={(event) => {
              setSearchText(event.target.value);
              send({
                destructive: true,
                key: 'searchString',
                type: 'addQueryStringValue',
                value: event.target.value,
              });
            }}
            readOnly={state.hasTag('loading')}
          />
        </div>
      </Grid>
    </Grid>
    {AuthorizationService.isAuthorizedHTML('VendorSalesAssignButton') && (
      <Grid>
        <Button
          className="btn-add ml-10"
          component="label"
          onClick={handleClickOpen} // to be handled in redux
        >
          <span className="fas fa-plus-circle" />
          <span>Assign New</span>
        </Button>
      </Grid>
    )}
  </Grid>
);
