import {
  UPDATE_VENDOR_KPI_FORM_ID,
  UPDATE_VENDOR_KPI_FORM_KPI,
  UPDATE_VENDOR_KPI_FORM_KPI_DATE,
  UPDATE_VENDOR_KPI_FORM_MEMBER,
  UPDATE_VENDOR_KPI_FORM_COMMENTS,
  UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE,
  UPDATE_VENDOR_KPI_FORM_KPI_ERROR,
  UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR,
  UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR,
  CLEAR_VENDOR_KPI_MODAL,
  SHOW_VENDOR_KPI_FORM_SPINNER,
  HIDE_VENDOR_KPI_FORM_SPINNER,
  SHOW_KPI_SELECT_LOADER,
  HIDE_KPI_SELECT_LOADER,
  UPDATE_VENDOR_KPI_FORM_IS_EDIT,
  UPDATE_VENDOR_KPI_FORM_INPUTS,
  UPDATE_SELECTED_MEMBER_KPI,
  UPDATE_KPI_OVERVIEW_YEARS,
  UPDATE_SELECTED_KPI_YEAR,
  UPDATE_VENDOR_KPI_MEMBER_DATA,
  UPDATE_SELECTED_KPI_YEAR_ERROR,
  UPDATE_SELECTED_KPI_QUARTER,
  UPDATE_SELECTED_KPI_QUARTER_ERROR,
  CLEAR_CHART_MODAL,
  SHOW_KPI_GRAPH_SPINNER,
  HIDE_KPI_GRAPH_SPINNER,
  UPDATE_KPI_QUARTER_LIST,
  UPDATE_KPI_SELECTED_QUARTER,
  UPDATE_MEMBER_KPI_DROPSIZE_DATA,
  UPDATE_MEMBER_KPI_TERMS_DATA
} from "../../constants";

const initialState = {
  agreementId: null,
  kpiId: null,
  kpiDate: null,
  memberId: null,
  comments: null,
  deactivateDate: "",
  kpiIdError: false,
  memberIdError: false,
  commentsError: false,
  spinner: false,
  isEdit: false,
  isFormUpdated: false,
  selectedKPIMemberId: null,
  kpiMemberData: [],
  kpiYearsList: [],
  kpiYear: null,
  kpiYearError: null,
  kpiQuarter: null,
  kpiQuarterError: null,
  isLoading: false,
  graphSpinner: false,
  kpiQuarterList: [],
  selectedKPIQuarter: null,
  memberDropSize: null,
  memberTerms: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_KPI_FORM_ID:
      return {
        ...state,
        agreementId: action.agreementId,
      };
    case UPDATE_VENDOR_KPI_FORM_KPI:
      return {
        ...state,
        kpiId: action.kpiId,
      };
    case UPDATE_VENDOR_KPI_FORM_KPI_DATE:
      return {
        ...state,
        kpiDate: action.kpiDate,
      };
    case UPDATE_VENDOR_KPI_FORM_MEMBER:
      return {
        ...state,
        memberId: action.memberId,
      };
    case UPDATE_VENDOR_KPI_FORM_COMMENTS:
      return {
        ...state,
        comments: action.comments,
      };
    case UPDATE_VENDOR_KPI_FORM_DEACTIVATE_DATE:
      return {
        ...state,
        deactivateDate: action.deactivateDate,
      };
    case UPDATE_VENDOR_KPI_FORM_KPI_ERROR:
      return {
        ...state,
        kpiIdError: action.kpiIdError,
      };
    case UPDATE_VENDOR_KPI_FORM_MEMBER_ERROR:
      return {
        ...state,
        memberIdError: action.memberIdError,
      };
    case UPDATE_VENDOR_KPI_FORM_COMMENTS_ERROR:
      return {
        ...state,
        commentsError: action.commentsError,
      };
    case UPDATE_KPI_QUARTER_LIST:
      return ({
        ...state,
        kpiQuarterList: action.kpiQuarterList
      });
    case UPDATE_KPI_SELECTED_QUARTER:
      return ({
        ...state,
        selectedKPIQuarter: action.selectedKPIQuarter
      });
    case CLEAR_VENDOR_KPI_MODAL:
      return {
        ...state,
        spinner: false,
        agreementId: null,
        kpiId: null,
        kpiDate: null,
        memberId: null,
        comments: null,
        deactivateDate: "",
        kpiIdError: false,
        memberIdError: false,
        commentsError: false,
        isEdit: false,
        isFormUpdated: false,
      };
    case SHOW_VENDOR_KPI_FORM_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case HIDE_VENDOR_KPI_FORM_SPINNER:
      return {
        ...state,
        spinner: action.spinner,
      };
    case UPDATE_VENDOR_KPI_FORM_IS_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };

    case UPDATE_VENDOR_KPI_FORM_INPUTS:
      return {
        ...state,
        isFormUpdated: action.isFormUpdated,
      };
    case UPDATE_SELECTED_MEMBER_KPI:
      return {
        ...state,
        selectedKPIMemberId: action.selectedKPIMemberId,
      };
    case SHOW_KPI_SELECT_LOADER:
      return {
        ...state,
        isLoading: true,
      };
    case HIDE_KPI_SELECT_LOADER:
      return {
        ...state,
        isLoading: false,
      };
    case SHOW_KPI_GRAPH_SPINNER:
      return {
        ...state,
        graphSpinner: true,
      };
    case HIDE_KPI_GRAPH_SPINNER:
      return {
        ...state,
        graphSpinner: false,
      };
    case UPDATE_VENDOR_KPI_MEMBER_DATA:
      return {
        ...state,
        kpiMemberData: action.kpiMemberData,
      };
    case UPDATE_KPI_OVERVIEW_YEARS:
      return {
        ...state,
        kpiYearsList: action.kpiYearsList,
      };
    case UPDATE_SELECTED_KPI_YEAR:
      return {
        ...state,
        kpiYear: action.kpiYear,
      };
    case UPDATE_SELECTED_KPI_QUARTER:
      return {
        ...state,
        kpiQuarter: action.kpiQuarter,
      };
    case UPDATE_SELECTED_KPI_YEAR_ERROR:
      return {
        ...state,
        kpiYearError: action.kpiYearError,
      };
    case UPDATE_SELECTED_KPI_QUARTER_ERROR:
      return {
        ...state,
        kpiQuarterError: action.kpiQuarterError,
      };
    case UPDATE_MEMBER_KPI_DROPSIZE_DATA:
      return ({
        ...state,
        memberDropSize: action.memberDropSize
      });
    case UPDATE_MEMBER_KPI_TERMS_DATA:
      return ({
        ...state,
        memberTerms: action.memberTerms
      });
    case CLEAR_CHART_MODAL:
      return {
        ...state,
        selectedKPIMemberId: null,
        kpiYearsList: [],
        kpiMemberData: [],
        kpiYear: null,
        kpiYearError: null,
        kpiQuarter: null,
        kpiQuarterError: null,
        isLoading: false,
        kpiQuarterList: [],
        selectedKPIQuarter: null,
        memberDropSize: null,
        memberTerms: null
      };
    default:
      return state;
  }
};
