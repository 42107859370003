import {
  UPDATE_MEMBER_SELECTED_LOCATION,
  UPDATE_MEMBER_LOCATION_DELETE_FLAG,
  UPDATE_MEMBER_LOCATION_DELETE_SPINNER,
  UPDATE_MEMBER_LOCATION_DELETE_ERROR,
  FetchError,
  MemberLocationDeleteSuccess,
  MemberLocationDeleteError,
} from "../../constants";

import {
  deleteMemberLocationById,
} from "../../api/members";

import {
  getMemberLocations
} from "./members";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const updateDeleteMemberLocation = (item) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_SELECTED_LOCATION,
    item: item,
  });
  dispatch({
    type: UPDATE_MEMBER_LOCATION_DELETE_FLAG,
    flag: true
  });
};

export const handleDeleteMemberLocationClose = () => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_LOCATION_DELETE_FLAG,
    flag: false,
  });
  dispatch({
    type: UPDATE_MEMBER_SELECTED_LOCATION,
    item: null,
  });
};

export const onDeleteMemberLocation = () => async (dispatch, getState) => {

  const {members} = getState();
  const {selectedLocation} = members;
  const {parentid, memberid} = selectedLocation;

  dispatch({
    type: UPDATE_MEMBER_LOCATION_DELETE_SPINNER,
    flag: true,
  });

  try {
    const deleteResponse = await deleteMemberLocationById(parentid, memberid);
    const {success} = deleteResponse;
    if (success) {
      dispatch(getMemberLocations());
      dispatch({
        type: UPDATE_MEMBER_LOCATION_DELETE_SPINNER,
        flag: false,
      });
      dispatch({
        type: UPDATE_MEMBER_SELECTED_LOCATION,
        item: null,
      });
      dispatch({
        type: UPDATE_MEMBER_LOCATION_DELETE_FLAG,
        flag: false,
      });
      toast.success(MemberLocationDeleteSuccess);
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_LOCATION_DELETE_SPINNER,
      flag: false,
    });
    if (error === UPDATE_MEMBER_LOCATION_DELETE_ERROR) {
      toast.error(MemberLocationDeleteError);
    } else {
      toast.error(FetchError);
    }
  }
};
