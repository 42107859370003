import { FC, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { VendorsTabPanel } from './VendorsTabPanel';
import { DistributionCenters } from './tabs/DistributionCenters';
import { SalesPersonnel } from './tabs/SalesPersonnel';
import { MemberSection } from './tabs/MemberSection';
import { LinkSection } from './tabs/links';
import { useStyles } from '../../utils';
import AuthorizationService from '../../authorization/AuthorizationService';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { useLocation } from 'react-router-dom';

const a11yProps = (index: number) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};

export const VendorTabs: FC = (props) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const handleChange = (_: React.ChangeEvent<{}>, newValue: any) => {
    setValue(newValue);
    handleClickAway();
  };
  const location:any = useLocation();

  const showMembersTab =
    AuthorizationService.isAuthorizedHTML('VendorMembersTab');

  const [tabMenuOpen, setTabMenuOpen] = useState(false);
  const tabResponsive = () => {
    setTabMenuOpen(!tabMenuOpen);
  };
  const handleClickAway = () => {
    setTabMenuOpen(false);
  };

  const tabMenuLabel = [
    {
      label: 'Links',
      value: 0,
    },
    {
      label: 'Distribution Centers',
      value: 1,
    },
    {
      label: 'Sales Personnel',
      value: 2,
    },
    {
      label: 'Members',
      value: 3,
    },
  ];

  useEffect(() => {
    window.history.replaceState({}, '')
    if(location.state){
      setValue(location.state.vendortabs)
    }else{
      setValue(0)
    }
  },[])
  return (
    <div className="container">
      <div className={classes.membersRoot}>
        <div className="br-tab">
          <div className={tabMenuOpen ? 'tab-ul tab-ul-open' : 'tab-ul'}>
            <button
              className="tab-button"
              tabIndex={0}
              onClick={tabResponsive}
            >
              {tabMenuLabel[value]?.label}
              <i className="fas fa-caret-down" />
            </button>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Links" {...a11yProps(0)} />
              <Tab label="Distribution Centers" {...a11yProps(1)} />
              <Tab label="Sales Personnel" {...a11yProps(2)} />
              {/* <Tab label="Links" {...a11yProps(2)} /> */}
              {showMembersTab && <Tab label="Members" {...a11yProps(3)} />}
            </Tabs>
          </div>
          <VendorsTabPanel value={value} index={0}>
            <LinkSection {...props} />
          </VendorsTabPanel>
          <VendorsTabPanel value={value} index={1}>
            <DistributionCenters {...props} />
          </VendorsTabPanel>
          <VendorsTabPanel value={value} index={2}>
            <SalesPersonnel {...props} />
          </VendorsTabPanel>

          {AuthorizationService.isAuthorizedHTML('VendorMembersTab') && (
            <VendorsTabPanel value={value} index={3}>
              <MemberSection {...props} />
            </VendorsTabPanel>
          )}
        </div>
      </div>
    </div>
  );
};

VendorTabs.propTypes = {
  vendor: PropTypes.object,
};
