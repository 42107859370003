import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CardImg from '../../../../images/ad1.jpg';
import Tooltip from '@material-ui/core/Tooltip';

function ManageAdsCard({
  handleShowViewAdModal,
  item,
  manageAdsList: { adsTypeList },
}) {
  const {
    adId,
    adImage,
    adUrl,
    ranking,
    productId,
    adTypeId,
    disableDate,
    specials,
    description,
  } = item;

  const getAdTypeName = () => {
    let item =
      adsTypeList.filter((currentItem) => currentItem.id == adTypeId) || [];
    return (item.length > 0 && item[0]['name']) || '-';
  };

  return (
    <Card
      className="manage-ads-card"
      onClick={() => handleShowViewAdModal(item)}
    >
      <CardActionArea>
        <CardMedia
          className="manage-ads-card-media"
          image={CardImg}
          title="Contemplative Reptile"
        />
        <div className="manage-ads-actions">
          <Tooltip title="View ad">
            <Button>
              <i class="fas fa-eye"></i>
            </Button>
          </Tooltip>

          <Tooltip title="Edit ad">
            <Button>
              <i class="fas fa-pen"></i>
            </Button>
          </Tooltip>
          <Tooltip title="Delete">
            <Button className="manage-ad-delete">
              <i class="fas fa-trash-alt"></i>
            </Button>
          </Tooltip>
        </div>
        <CardContent>
          <Typography className="manage-ads-h3">{adUrl}</Typography>
          <Typography className="manage-ads-h5">{getAdTypeName()}</Typography>
          <Typography className="manage-ads-h4">Convection Ovens</Typography>
          <Typography className="manage-ads-h6">by Adcraft</Typography>
          <Typography className="manage-ads-p">{description}</Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Grid container className="manage-ads-action-bar">
          <Grid item xs={7}>
            <Button className="manage-ads-active">Active</Button>
            <div className="manage-ads-title-info">{specials}</div>
          </Grid>
          <Grid item xs={5}>
            <div className="manage-ads-rank-number">#{ranking}</div>
            <div className="manage-ads-rank-text">Ranking</div>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
}
export default ManageAdsCard;
