import {
    SHOW_DISCUSSION_ACTION_SPINNER,
    HIDE_DISCUSSION_ACTION_SPINNER,
    UPDATE_DISCUSSION_LIKE,
    UPDATE_DISCUSSION_ID,
    UPDATE_ALL_DISCUSSIONS,
    UPDATE_DISCUSSION_LIKE_SUCCESS,
    UPDATE_DISCUSSION_DISLIKE_SUCCESS,
    UPDATE_DISCUSSION_TITLE,
    UPDATE_DISCUSSION_DESC,
    UPDATE_ARCHIVE_DISCUSSION,
    UPDATE_DISCUSSION_LIKE_ID,
    ARCHIVE_DISCUSSION_ERROR,
    ADD_DISCUSSION_LIKE_ERROR,
    ADD_DISCUSSION_DISLIKE_ERROR,
    DiscussionLikeError,
    DiscussionDisLikeError,
    GetAllDiscussionsError,
    ArchiveDiscussionsError,
    FetchError
} from "../../../constants";

import {
    addDiscussionLike,
    removeDiscussionLike,
    archiveDiscussion
} from "../../../api/community";

import {showDiscussionDetailSpinner, updateDiscussion} from './discussion';
import {showDiscussionModalSpinner, hideDiscussionModalSpinner} from './discussionModals'

import {
    getAllDiscussions
} from "../../../api/community/discussion/getAllDiscussions";


import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showDiscussionActionSpinner = () => async (dispatch) => {
    dispatch({
        type: SHOW_DISCUSSION_ACTION_SPINNER
    });
};

export const hideDiscussionActionSpinner = () => async (dispatch) => {
    dispatch({
        type: HIDE_DISCUSSION_ACTION_SPINNER
    });
};

export const handleDiscussionLike = (isDiscussionLiked, selectedDiscussion) => async (dispatch, getState) => {    
    const {id: discussionId, discussionLikeId, userDiscussionLikeId} = selectedDiscussion
    dispatch({
        type: UPDATE_DISCUSSION_ID,
        discussionId
    });
    dispatch({
        type: UPDATE_DISCUSSION_LIKE,
        isDiscussionLiked
    });
    try {
        dispatch(showDiscussionActionSpinner());
        dispatch(showDiscussionDetailSpinner());
        const {user, communityChannel, communityDiscussion} = getState();
        const {id: userId} = user;
        const {activeChannelID} = communityChannel;
        const {isQuestionDetail} = communityDiscussion;
        if (isDiscussionLiked && userDiscussionLikeId === null) {
            const res = await addDiscussionLike({
                discussionId,
                userId
            });
            const {success, data} = res;
            const {insertId} = data;
            if (success) {
                dispatch({
                    type: UPDATE_DISCUSSION_LIKE_ID,
                    likeId: insertId
                });
                dispatch({
                    type: UPDATE_DISCUSSION_LIKE_SUCCESS,
                    likeSuccess: success
                });
                dispatch({
                    type: UPDATE_DISCUSSION_DISLIKE_SUCCESS,
                    disLikeSuccess: false
                });
                if (!isQuestionDetail) {
                    const res = await getAllDiscussions({
                        activeChannelID
                    });
                    const {data} = res;
                    dispatch({
                        type: UPDATE_ALL_DISCUSSIONS,
                        discussionList: data
                    })
                }
                isQuestionDetail &&
                    dispatch(updateDiscussion(discussionId))
            }
        } else {
            dispatch(showDiscussionActionSpinner());
            dispatch(showDiscussionDetailSpinner());
            const res = await removeDiscussionLike({
                discussionId,
                userDiscussionLikeId
            });
            const {success} = res;
            if (success) {
                dispatch({
                    type: UPDATE_DISCUSSION_LIKE_SUCCESS,
                    likeSuccess: false
                });
                dispatch({
                    type: UPDATE_DISCUSSION_LIKE_ID,
                    likeId: null
                });
                dispatch({
                    type: UPDATE_DISCUSSION_DISLIKE_SUCCESS,
                    disLikeSuccess: success
                });
                dispatch({
                    type: UPDATE_DISCUSSION_LIKE,
                    isDiscussionLiked: false
                });
                if (!isQuestionDetail) {
                    const res = await getAllDiscussions({
                        activeChannelID
                    });
                    const {data} = res;
                    dispatch({
                        type: UPDATE_ALL_DISCUSSIONS,
                        discussionList: data
                    })
                }
                isQuestionDetail &&
                    dispatch(updateDiscussion(discussionId))
            }
        }
        !isQuestionDetail && dispatch(hideDiscussionActionSpinner());
    }
    catch (error) {
        dispatch(hideDiscussionActionSpinner());
        if (error === ADD_DISCUSSION_LIKE_ERROR) {
            toast.error(DiscussionLikeError)
        } else if (error === ADD_DISCUSSION_DISLIKE_ERROR) {
            toast.error(DiscussionDisLikeError)
        } else {
            toast.error(FetchError)
        }
    }
};

export const handleArchiveModal = (isArchive) => async (dispatch) => {
    dispatch({
        type: UPDATE_ARCHIVE_DISCUSSION,
        isArchive
    });
};

export const updateArchiveDiscussion = (selectedDiscussion) => async (dispatch) => {
    const {id, title, description} = selectedDiscussion;
    dispatch(handleArchiveModal(true));
    dispatch({
        type: UPDATE_DISCUSSION_ID,
        discussionId: id,
    });
    dispatch({
        type: UPDATE_DISCUSSION_TITLE,
        discussionTitle: title,
    });
    dispatch({
        type: UPDATE_DISCUSSION_DESC,
        discussionDesc: description,
    });
};

export const handleArchiveDiscussion = () => async (dispatch, getState) => {
    const {user, communityChannel, communityDiscussion, communityDiscussionActions} = getState();
    const {activeChannelID} = communityChannel;
    const {id} = user;
    const {discussionTitle, discussionDesc} = communityDiscussion;
    const {discussionId} = communityDiscussionActions;

    try {
        dispatch(showDiscussionModalSpinner());
        const response = await archiveDiscussion({
            activeChannelID,
            discussionId,
            userId: id,
            discussionTitle,
            discussionDesc
        });
        const {success, message} = response;
        const res = await getAllDiscussions({
            activeChannelID
        });
        const {data} = res;
        dispatch({
            type: UPDATE_ALL_DISCUSSIONS,
            discussionList: data
        })
        dispatch(handleArchiveModal(false));
        dispatch({
            type: UPDATE_DISCUSSION_ID,
            discussionId: null,
        });
        dispatch({
            type: UPDATE_DISCUSSION_TITLE,
            discussionTitle: null,
        });
        dispatch({
            type: UPDATE_DISCUSSION_DESC,
            discussionDesc: null,
        });
        dispatch(hideDiscussionModalSpinner());
        success && toast.success(message)
    }
    catch (error) {
        dispatch(hideDiscussionModalSpinner());
        if (error === ARCHIVE_DISCUSSION_ERROR) {
            toast.error(ArchiveDiscussionsError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
}