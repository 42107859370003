import { UPLOAD_PRODUCT_REBATES_ERROR } from '../../../constants';
import serviceInterceptor from '../../../services/ServiceInterceptor';

export const uploadProductRebates = async ({
  quarter,
  year,
  rebateProductList,
}) => {
  try {
    const bodyFormData = new FormData();
    let quarterValue = null;
    switch (quarter) {
      case 'Q1':
      case '1':
      case 1:
        quarterValue = 1;
        break;
      case 'Q2':
      case '2':
      case 2:
        quarterValue = 2;
        break;
      case 'Q3':
      case '3':
      case 3:
        quarterValue = 3;
        break;
      case 'Q4':
      case '4':
      case 4:
        quarterValue = 4;
        break;
    }
    bodyFormData.append('quarter', quarterValue);
    bodyFormData.append('year', year);
    bodyFormData.append('rebateProductList', rebateProductList);

    const response = await serviceInterceptor.post(
      `/rebate-product-list-records`,
      bodyFormData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
    if (!response?.data?.success) {
      throw UPLOAD_PRODUCT_REBATES_ERROR;
    }
    return response.data;
  } catch (error) {
    throw UPLOAD_PRODUCT_REBATES_ERROR;
  }
};
