import {
    SHOW_DASHBOARD_KPI_QUARTER_SPINNER,
    SHOW_DASHBOARD_KPI_MEMBER_SPINNER,
    SHOW_DASHBOARD_KPI_DETAIL_SPINNER,
    UPDATE_DASHBOARD_KPI_QUARTER_LIST,
    UPDATE_DASHBOARD_KPI_MEMBER_LIST,
    UPDATE_DASHBOARD_KPI_SELECTED_MEMBER,
    UPDATE_DASHBOARD_KPI_SELECTED_QUARTER,
    UPDATE_DASHBOARD_KPI_DROPSIZE_DATA,
    UPDATE_DASHBOARD_KPI_TERMS_DATA
} from "../../constants";

const initialState = {
    dashboardKPIQuarterSpinner: false,
    dashboardKPIMemberSpinner: false,
    dashboardKPIDetailSpinner: false,
    kpiQuarterList: [],
    kpiMemberList: [],
    selectedKPIQuarter: null,
    selectedKPIMember: null,
    dashboardDropSize: null,
    dashboardTerms: null
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DASHBOARD_KPI_QUARTER_SPINNER:
            return ({
                ...state,
                dashboardKPIQuarterSpinner: action.dashboardKPIQuarterSpinner
            });
        case SHOW_DASHBOARD_KPI_MEMBER_SPINNER:
            return ({
                ...state,
                dashboardKPIMemberSpinner: action.dashboardKPIMemberSpinner
            });
        case SHOW_DASHBOARD_KPI_DETAIL_SPINNER:
            return ({
                ...state,
                dashboardKPIDetailSpinner: action.dashboardKPIDetailSpinner
            });
        case UPDATE_DASHBOARD_KPI_QUARTER_LIST:
            return ({
                ...state,
                kpiQuarterList: action.kpiQuarterList
            });
        case UPDATE_DASHBOARD_KPI_MEMBER_LIST:
            return ({
                ...state,
                kpiMemberList: action.kpiMemberList
            });
        case UPDATE_DASHBOARD_KPI_SELECTED_QUARTER:
            return ({
                ...state,
                selectedKPIQuarter: action.selectedKPIQuarter
            });
        case UPDATE_DASHBOARD_KPI_SELECTED_MEMBER:
            return ({
                ...state,
                selectedKPIMember: action.selectedKPIMember
            });
        case UPDATE_DASHBOARD_KPI_DROPSIZE_DATA:
            return ({
                ...state,
                dashboardDropSize: action.dashboardDropSize
            });
        case UPDATE_DASHBOARD_KPI_TERMS_DATA:
            return ({
                ...state,
                dashboardTerms: action.dashboardTerms
            });
        default:
            return state;
    }
};
