import { FC } from 'react';
import Grid from '@mui/material/Grid';
import { EBrandTable } from './EBrandTable';

export const DashboardEBrand: FC = () => (
  <Grid
    container
    spacing={3}
    className="border-divider dashboard-section-height"
  >
    <Grid
      xs={12}
      md={3}
      alignItems="center"
      justifyItems="flex-start"
      item
      container
      alignContent="center"
    >
      <p>
        Maximize your E-Brand rebates by increasing your Average Drop Size and
        E-Brand purchases. This is in addition to the 0.50% rebate earned on all
        items.
      </p>
    </Grid>
    <Grid
      xs={12}
      md={9}
      alignItems="center"
      justifyItems="flex-start"
      item
      container
      alignContent="center"
    >
      <EBrandTable />
    </Grid>
  </Grid>
);

export default DashboardEBrand;
