import {
    SHOW_DOCUMENT_LOADING,
    HIDE_DOCUMENT_LOADING,
    SHOW_DOCUMENT_SPINNER,
    HIDE_DOCUMENT_SPINNER,
    SHOW_DOWLOAD_SPINNER,
    HIDE_DOWLOAD_SPINNER,
    SHOW_DOCUMENT_MODAL_SPINNER,
    HIDE_DOCUMENT_MODAL_SPINNER,
    UPDATE_DOCUMENT_TOGGLE,
    UPDATE_DOCUMENTS_LIST,
    UPDATE_DOCUMENTS_CATEGORY,
    UPDATE_SELECT_CATEGORY,
    UPDATE_SELECT_MODAL_CATEGORY,
    UPDATE_DC_MENU_LIST,
    UPDATE_SELECT_MEMBERS,
    UPDATE_SELECT_ALL_MEMBERS,
    UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR,
    UPDATE_MEMBERS_MENU_LIST,
    UPDATE_SELECTED_FILE_NAME,
    UPDATE_SELECTED_FILE_SIZE,
    UPDATE_SELECTED_FILE_EXTENSION,
    UPDATE_SELECTED_MEMBERS,
    UPDATE_FILE_EXTENSION_ERROR,
    UPDATE_IS_EDIT_DOCUMENT_ERROR,
    UPDATE_DOCUMENT_DESC_ERROR,
    CLEAR_DOCUMENT,
    CLEAR_DOCUMENT_MODAL,
    GRID_VIEW,
    UPDATE_IS_EDIT_DOCUMENT,
    UPDATE_SELECTED_DOCUMENT_DATA,
    UPDATE_DOCUMENT_DC_LIST,
    UPDATE_SELECTED_FILE_CONTENT,
    UPDATE_SELECTED_DISTRIBUTION_CENTERS,
    UPDATE_SELECTED_DOCUMENT,
    UPDATE_DELETE_DOCUMENT,
    UPDATE_DOCUMENT_DESCRIPTION,
    UPDATE_DOCUMENT_INPUT_FORM,
    UPDATE_SELECT_MEMBERS_LIST,
    UPDATE_IS_DETAIL_MODAL,
    UPDATE_DOWNLOAD_ICON_VISIBILITY
} from "../../constants";

const initialState = {
    viewType: GRID_VIEW,
    documentList: [],
    categoryList: [],
    selectedCategoryID: 0,
    selectedModalCategory: "NA",
    membersList: [],
    fileContent: null,
    documentDCList: [],
    selectedMembers: [],
    selectedDistributionCenters: [],
    selectMemberList: [],
    selectedDocumentID: null,
    selectedDocumentData: null,
    selectedFileName: null,
    documentDesc: null,
    selectedFileSize: null,
    selectedFileExtension: null,
    isSelectMember: false,
    isAllMembers: true,
    fileExtensionError: false,
    loading: false,
    spinner: false,
    downloadSpinner: false,
    modalSpinner: false,
    categoryError: false,
    documentDescError: false,
    isDeleteDocument: false,
    isEditDocument: false,
    isFormUpdated: false,
    documentEditError: false,
    isDetailModal: false,
    downloadIconVisible: false
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_DOCUMENT_LOADING:
            return ({
                ...state,
                loading: true
            });
        case HIDE_DOCUMENT_LOADING:
            return ({
                ...state,
                loading: false
            });
        case SHOW_DOCUMENT_SPINNER:
            return ({
                ...state,
                spinner: true
            });
        case HIDE_DOCUMENT_SPINNER:
            return ({
                ...state,
                spinner: false
            });
        case SHOW_DOWLOAD_SPINNER:
            return ({
                ...state,
                downloadSpinner: true
            });
        case HIDE_DOWLOAD_SPINNER:
            return ({
                ...state,
                downloadSpinner: false
            });
        case UPDATE_DOWNLOAD_ICON_VISIBILITY:
            return ({
                ...state,
                downloadIconVisible: action.downloadIconVisible
            });
        case SHOW_DOCUMENT_MODAL_SPINNER:
            return ({
                ...state,
                modalSpinner: true
            });
        case HIDE_DOCUMENT_MODAL_SPINNER:
            return ({
                ...state,
                modalSpinner: false
            });
        case UPDATE_DOCUMENT_TOGGLE:
            return ({
                ...state,
                viewType: action.viewType,
            });
        case UPDATE_SELECTED_FILE_CONTENT:
            return ({
                ...state,
                fileContent: action.fileContent,
            });
        case UPDATE_SELECT_MEMBERS_LIST:
            return ({
                ...state,
                selectMemberList: action.selectMemberList,
            });
        case UPDATE_SELECTED_DOCUMENT:
            return ({
                ...state,
                selectedDocumentID: action.selectedDocumentID,
            });
        case UPDATE_SELECTED_DOCUMENT_DATA:
            return ({
                ...state,
                selectedDocumentData: action.selectedDocumentData,
            });
        case UPDATE_IS_EDIT_DOCUMENT_ERROR:
            return ({
                ...state,
                documentEditError: action.documentEditError,
            });
        case UPDATE_DELETE_DOCUMENT:
            return ({
                ...state,
                isDeleteDocument: action.isDeleteDocument,
            });
        case UPDATE_DOCUMENTS_LIST:
            return ({
                ...state,
                documentList: action.documentList,
            });
        case UPDATE_DOCUMENT_DESC_ERROR:
            return ({
                ...state,
                documentDescError: action.documentDescError,
            });
        case UPDATE_DOCUMENTS_CATEGORY:
            return ({
                ...state,
                categoryList: action.categoryList,
            });
        case UPDATE_IS_EDIT_DOCUMENT:
            return ({
                ...state,
                isEditDocument: action.isEditDocument,
            });
        case UPDATE_DOCUMENT_DESCRIPTION:
            return ({
                ...state,
                documentDesc: action.documentDesc,
            });
        case UPDATE_DOCUMENT_INPUT_FORM:
            return ({
                ...state,
                isFormUpdated: action.isFormUpdated,
            });
        case UPDATE_SELECT_CATEGORY:
            return ({
                ...state,
                selectedCategoryID: action.selectedCategoryID,
            });
        case UPDATE_SELECT_MODAL_CATEGORY:
            return ({
                ...state,
                selectedModalCategory: action.selectedModalCategory,
            });
        case UPDATE_DC_MENU_LIST:
            return ({
                ...state,
                documentDCList: action.documentDCList,
            });
        case UPDATE_SELECT_MEMBERS:
            return ({
                ...state,
                isSelectMember: action.isSelectMember,
            });
        case UPDATE_SELECT_ALL_MEMBERS:
            return ({
                ...state,
                isAllMembers: action.isAllMembers,
            });
        case UPDATE_MEMBERS_MENU_LIST:
            return ({
                ...state,
                membersList: action.membersList,
            });
        case UPDATE_DOCUMENT_DC_LIST:
            return ({
                ...state,
                documentDCList: action.documentDCList,
            });
        case UPDATE_SELECTED_FILE_NAME:
            return ({
                ...state,
                selectedFileName: action.selectedFileName,
            });
        case UPDATE_SELECTED_FILE_SIZE:
            return ({
                ...state,
                selectedFileSize: action.selectedFileSize,
            });
        case UPDATE_SELECTED_FILE_EXTENSION:
            return ({
                ...state,
                selectedFileExtension: action.selectedFileExtension,
            });
        case UPDATE_FILE_EXTENSION_ERROR:
            return ({
                ...state,
                fileExtensionError: action.fileExtensionError,
            });
        case UPDATE_SELECTED_MEMBERS:
            return ({
                ...state,
                selectedMembers: action.selectedMembers,
            });
        case UPDATE_SELECTED_DISTRIBUTION_CENTERS:
            return ({
                ...state,
                selectedDistributionCenters: action.selectedDistributionCenters,
            });
        case UPDATE_SELECT_DOCUMENT_CATEGORY_ERROR:
            return ({
                ...state,
                categoryError: action.categoryError,
            });
        case UPDATE_IS_DETAIL_MODAL:
            return ({
                ...state,
                isDetailModal: action.isDetailModal,
            });
        case CLEAR_DOCUMENT_MODAL:
            return ({
                ...state,
                isSelectMember: false,
                selectedFileName: null,
                selectedFileSize: null,
                fileExtensionError: false,
                categoryError: false,
                isAllMembers: true,
                selectedMembers: [],
                selectedDistributionCenters: [],
                membersList: [],
                documentDCList: [],
                selectedModalCategory: "NA",
                selectedDocumentData: null,
                documentDesc: null,
                documentEditError: false,
                isFormUpdated: false,
                selectMemberList: []
            });
        case CLEAR_DOCUMENT:
            return initialState;
        default:
            return state;
    }
};