export const getPaginatedData = (data, rowsPerPage, page) => {
  if (data && data.length > 0) {
    const modifiedData = data.reduce((result, value, index, array) => {
      if (index % rowsPerPage === 0)
        result.push(array.slice(index, index + rowsPerPage));
      return result;
    }, []);
    const pagedData = modifiedData[page];
    return pagedData;
  }
  return data;
};
