import { FC, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { Loader } from "../../../../utils";
import { useStore } from "react-redux";
import { RebatesPieChart } from "../../../../components";
import RebatesTotalsOverview from "../../../../components/RebatesTotalsOverview/RebatesTotalsOverview";
import { BRDropdown } from "../../../../components/BRDropdown";
import { useMachine, useActor } from "@xstate/react";
import { Member } from "../../../../common";
import { dashboardEarnedRebatesMachine } from "../../../dashboard/section/DashboardEarnedRebates/machines";
import { OverviewTable } from "../../components/Table/OverviewTable";
export const Overview: FC<any> = (props) => {
  const [state] = useMachine(dashboardEarnedRebatesMachine);
  const [memberState, memberSend] = useActor(state.children.members);
  const { getRebatesOverview } = props;
  const store = useStore<{
    rebates: {
      overViewData: {
        claimedAmount: string;
        pendingAmount: string;
        unclaimedAmount: string;
      };
      overViewSpinner: boolean;
    };
  }>();
  const storeState = store.getState();
  const { overViewData, overViewSpinner } = storeState.rebates ?? {};
  const { claimedAmount, pendingAmount, unclaimedAmount } = overViewData ?? {};

  const [members, setMembers] = useState<any>([]);
  const [selectedMebers, setSelectedMembers] = useState(0);
  useEffect(() => {
    getRebatesOverview();
  }, []);
  useEffect(() => {
    setMembers([
      {
        id: 0,
        name: "All",
      },
      ...memberState.context.members,  
    ]);
  }, [memberState.context.members]);

  if (overViewSpinner) {
    return (
      <div className="relative-wrapper">
        <div className="loader-wrapper list-spinner">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Grid
          container
          justifyContent="flex-start"
          sx={{
            mt: 2,
            mb: 2,
            display: "grid",
            gridTemplateColumns: "1fr min-content",
            gap: 1,
          }}
        >
          <Grid item sx={{ gridRow: "1" }} justifyContent="flex-end">
            <BRDropdown
              selectedOption={selectedMebers || 0}
              disabled={members.length <= 1}
              menuList={members.map((member: Member) => ({
                value: member.id,
                label: member.name,
              }))}
              isMemberList = {true}
              handleSelected={(item: any) => {
                const memberId = item?.value;
                console.log(memberId);
                setSelectedMembers(memberId);
                if (memberId) {
                  memberSend({
                    memberId,
                    type: "updateSelectedMember",
                  });
                }
              }}
              spinner={memberState.hasTag("loading")}
            />
          </Grid>
        </Grid>
        
          <Grid container spacing={3} className="rebates-overview">
            <RebatesPieChart
              claimedAmount={
                selectedMebers === 0
                  ? parseInt(claimedAmount)
                  : state.context.acquiredAmount
              }
              unclaimedAmount={
                selectedMebers === 0
                  ? parseInt(unclaimedAmount)
                  : state.context.availableAmount
              }
              pendingAmount={
                selectedMebers === 0
                  ? parseInt(pendingAmount)
                  : state.context.pendingAmount
              }
            />
            <RebatesTotalsOverview
              claimedAmount={
                selectedMebers === 0
                  ? parseInt(claimedAmount)
                  : state.context.acquiredAmount
              }
              unclaimedAmount={
                selectedMebers === 0
                  ? parseInt(unclaimedAmount)
                  : state.context.availableAmount
              }
              pendingAmount={
                selectedMebers === 0
                  ? parseInt(pendingAmount)
                  : state.context.pendingAmount
              }
            />
          </Grid>
          <OverviewTable/>
      </>
    );
  }
};
