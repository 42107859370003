import Button from '@mui/material/Button';
import { FC } from 'react';
import AuthorizationService from '../../../../../../authorization/AuthorizationService';

export const MemberRebatesActions: FC<any> = ({
  isMemberRebateDraft,
  showMemberRebateExportModal,
  showMemberRebateModal,
  onPublishMemberRebates,
  handleMemberEditRebates,
}) => {

  const showExport = AuthorizationService.isAuthorizedHTML('earnedRebatesExport');
  const showUpload = AuthorizationService.isAuthorizedHTML('earnedRebatesUpload');

  if (isMemberRebateDraft) {
    return (
      <>
        <div className="header-filter-box-add">
          {showUpload && <Button
            onClick={() => handleMemberEditRebates(true)}
            variant="contained"
            size="small"
            className="btn-edit"
          >
            <span>Edit</span>
          </Button>}
        </div>
        <div className="header-filter-box-add">
          {showUpload && <Button
            onClick={() => onPublishMemberRebates()}
            variant="contained"
            size="small"
            className="btn-publish"
          >
            <span className="fas fa-plus-circle" />
            <span>Publish Earned Rebates</span>
          </Button>}
        </div>
      </>
    );
  }
  return (
    <>
      <div className="header-filter-box-add">
        {showUpload && <Button
          onClick={() => showMemberRebateModal()}
          variant="contained"
          size="small"
          className="btn-add"
        >
          <span className="fas fa-plus-circle" />
          <span>Upload Documents</span>
        </Button>}
      </div>
      <div className="header-filter-box-add">
        {showExport && <Button
          onClick={() => showMemberRebateExportModal(true)}
          variant="contained"
          size="small"
          className="btn-add-blue"
        >
          <span className="fas fa-file-export" />
          <span>Export</span>
        </Button>}
      </div>
    </>
  );
};
