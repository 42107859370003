import {GET_KPI_YEARS, GET_KPI_OVERVIEW_YEARS_ERROR} from "../../constants";


export const getKpiOverviewYears = async ({
    memberID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_KPI_YEARS}/${memberID}/years`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
        }
        );
        if (!response.ok) throw GET_KPI_OVERVIEW_YEARS_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_KPI_OVERVIEW_YEARS_ERROR;
    }
}

