import { FC, useState, useRef } from 'react';
import { RebatesReceiptActions } from './RebatesReceiptActions';
import { Loader } from '../../../../../../utils';
import {
  Button,
  ClickAwayListener,
  Grid,
  Grow,
  // InputBase,
  MenuItem,
  MenuList,
  Popper,
} from '@material-ui/core';
import { useStore } from 'react-redux';
import { Member, Members } from '../../../../../../common';
import useSortableData from '../../../../components/Table/useSortableData';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import { SearchNotFound } from '../../../../../../components/SearchNotFound';

export const RebatesReceiptFilter: FC<{
  handleRebateReceiptMemberChange: (selectedMember: any) => void;
  searchText: string;
  setSearchText: (searchText: string) => void;
  showClaimRebateExportModal: (isClaimRebatesExportModal: boolean) => void;
}> = (props) => {
  const { handleRebateReceiptMemberChange, searchText, setSearchText, showClaimRebateExportModal } = props;
  const[isASC,setIsASC] = useState(true)
  const[search,setSearch] = useState('')
  
  const store = useStore<{
    rebateReceipts: {
      memberListSpinner: boolean;
      memberList: Members;
      selectedMember: { label: string };
    };
  }>();
  const handleSearch = (e:any) => {
    setSearch(e.target.value)
    requestSearch(e.target.value)
  }

  const storeState = store.getState();

  const { memberListSpinner, memberList, selectedMember } =
    storeState.rebateReceipts;

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<any>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
    setSearch('')
    requestSearch('')
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListKeyDown = (event: any) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleSelected = (item: any, event: any) => {
    handleRebateReceiptMemberChange(item);
    handleClose(event);
  };
  const{ items, requestSort, requestSearch } = useSortableData(memberList)
  return (
    <div className="rebates-filter">
      <Grid container spacing={3} alignItems="center">
        <Grid
          container
          xs={12}
          lg={4}
          alignItems="center"
          // justifyItems="flex-start"
          justify="flex-start"
          item
          alignContent="center"
        >
          <div className="header-filter-box-left">
            <div className="br-dropdown-menu">
              <Button
                ref={anchorRef}
                aria-controls={open ? 'menu-list-grow' : ''}
                aria-haspopup="true"
                onClick={handleToggle}
                disabled={memberListSpinner || memberList.length === 0}
              >
                <span className="drop-menu-button-text">
                  {selectedMember ? selectedMember.label : ''}
                </span>

                {memberListSpinner ? (
                  <div className="category-spinner">
                    <Loader size={15} />
                  </div>
                ) : (
                  <i className="fas fa-chevron-down" />
                )}
              </Button>
              <Popper
                open={open}
                anchorEl={anchorRef.current}
                role={''}
                transition
                disablePortal
                modifiers={{
                  preventOverflow: {
                    enabled: true,
                    escapeWithReference: true,
                    boundariesElement: "viewport",
                  },
                }}
                className='member-drop-down'
                placement="bottom-start"
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <Paper
                        sx={{
                          pt: "12px" ,
                        }}
                      >
                        <Paper
                          sx={{
                            p: "2px 0px",
                            display: "flex",
                            alignItems: "center",
                            height: "50px",
                            width: "385px",
                            margin: "0 auto 5px auto",
                          }}
                        >
                          <div
                            // sx={{ p: '2px 5px' }}
                            style={{
                              width: "77px",
                              textAlign: "center",
                              display: "flex",
                              justifyContent: "space-around",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setIsASC(!isASC);
                              requestSort("label");
                            }}
                          >
                            <p>All</p>
                            <span className="sort-icon">
                              <i className={`sort-svg fas fa-sort-down`} />
                            </span>
                          </div>
                          <Divider
                            className="sortDivider"
                            orientation="vertical"
                          />
                          <InputBase
                            sx={{ ml: 2, flex: 1 }}
                            placeholder="Search"
                            value={search}
                            onChange={handleSearch}
                          />
                          <i className="fas fa-search search-icon" />
                        </Paper>
                        <MenuList
                          autoFocusItem={false}
                          id="menu-list-grow"
                          onKeyDown={handleListKeyDown}
                          className="member-dropdown-menu-list"
                        >
                          {items.length ? (
                              items.map((item: any) => {
                                const { label } = item;
                                return (
                                  <MenuItem
                                    onClick={(event) => handleSelected(item, event)}
                                    selected={item === selectedMember}
                                  >
                                    <>{label}</>
                                  </MenuItem>
                                );
                              })
                            ) : (
                              <MenuItem>
                                <SearchNotFound sectionType='Member'/>
                              </MenuItem>
                            )
                          }
                        </MenuList>
                      </Paper>
                    </ClickAwayListener>
                  </Grow>
                )}
              </Popper>
            </div>
          </div>
        </Grid>
        <Grid
          item
          container
          xs={12}
          lg={8}
          direction="row"
          // justifyItems="flex-end"
          justify="flex-end"
          alignItems="center"
        >
          <div className="header-filter-box-search-add">
            <div className="header-filter-box-search">
              <div className="page-search-box">
                <i className="fas fa-search"></i>
                <InputBase
                  placeholder="Search"
                  value={searchText && searchText.toString()}
                  onChange={(event) => setSearchText(event.target.value)}
                />
              </div>
            </div>
            <RebatesReceiptActions {...props} handleSelected={handleSelected} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default RebatesReceiptFilter;
