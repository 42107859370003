import { FC } from "react";
import {Grid} from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import {StepFileUpload} from "./StepFileUpload";
import { DateTime } from "luxon";

export const MemberRebatesModal: FC<any> = (props) => {

  const {
    memberRebates,
    onChangeMemberRebatesQuarter,
    onChangeMemberRebatesYear
  } = props;

  const {quarter, year} = memberRebates;

  const quarterList = [
    {
      label: "Q1",
      value: 1,
    },
    {
      label: "Q2",
      value: 2,
    },
    {
      label: "Q3",
      value: 3,
    },
    {
      label: "Q4",
      value: 4,
    },
  ];

  const lastYear = `${DateTime.now().minus({ years: 1 }).year}`;
  const currentYear = `${DateTime.now().year}`;
  const nextYear = `${DateTime.now().plus({ years: 1}).year}`;

  const yearList = [
    {
      label: lastYear,
      value: lastYear,
    },
    {
      label: currentYear,
      value: currentYear,
    },
    {
      label: nextYear,
      value: nextYear,
    },
  ];

  return (
    <div className="upload-rebateform">
      <Grid container spacing={2} className="upload-rebateform-grid">
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Fiscal Quarter
            </InputLabel>
            <Select
              value={quarter}
              onChange={(event) =>
                onChangeMemberRebatesQuarter(event.target.value)
              }
              label="Select Quarter"
              style={{letterSpacing:"0.0958em"}}
            >
              {quarterList &&
                quarterList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={6}>
          <FormControl variant="outlined" className="select-box">
            <InputLabel htmlFor="outlined-age-native-simple">
              Select Year
            </InputLabel>
            <Select
              value={year}
              onChange={(event) =>
                onChangeMemberRebatesYear(event.target.value)
              }
              label="Select Year"
            >
              {yearList &&
                yearList.map((item, index) => {
                  return (
                    <option key={index} value={item.label}>
                      {item.label}
                    </option>
                  );
                })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <StepFileUpload
          {...props}
        />
      </Grid>
    </div>
  );
};

export default MemberRebatesModal;
