import React, {useState} from "react";
import PropTypes from "prop-types";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import Link from "@material-ui/core/Link";
import {SearchNotFound} from "../../../../components/SearchNotFound";
import AuthorizationService from "../../../../authorization/AuthorizationService";
import {useStyles} from "../../../../utils";
import {StringAvatar} from "../../../../utils/AvatarInitial";
import Avatar from '@mui/material/Avatar';
import DefaultMemberImg from "../../../../images/member-list-placeholder.jpg";
import { useNavigate } from "react-router-dom";
import sortDefault from '../../../../images/sortDefault.svg';
import sortAscIcon from '../../../../images/sortAsc.svg';
import sortDescIcon from '../../../../images/sortDesc.svg';

export const UserTable = ({
  gridHeader,
  gridData,
  handleEditMemberUser,
  handleDeleteMemberUser,
  setSortKey,
  sortType,
  memberName,
  handleDeleteUserMember,
  viewAsMember
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event, sortKey) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  const navigate = useNavigate();

  return (
    <div className={`${classes.root} table-wrapper`}>
      {gridData?.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item) => {
            const {label, value, xs, lg, sortKey} = item;
            const isValidHeader = label !== "";
            return (
              <Grid className="th" item xs={xs} lg={lg}>
                {label === 'Action' ?
                  (<div className={label === 'Action' ? 'action-header' : ''}>
                    <span
                      className="grid-header"
                    >
                      {label}
                    </span>
                  </div>) :
                  (<div>
                    <span
                      className="grid-header"
                      onClick={(event) => handleClick(event, sortKey)}
                    >
                      {label}
                    </span>
                    <span className="sort-icon">
                      {
                        isValidHeader && (
                          sortType === '' ?
                            <img className="sort-svg" src={sortDefault} /> : (label === activeHeader && sortType === 'ASC') ?
                              <img className="sort-svg" src={sortAscIcon} /> : (label === activeHeader && sortType === 'DESC') ?
                                <img className="sort-svg" src={sortDescIcon} /> : <img className="sort-svg" src={sortDefault} />
                        )
                      }
                    </span>
                  </div>)}
              </Grid>
            );
          })}
        </Grid>
      )}

      {gridData?.length > 0 ? (
        gridData.map((item) => {
          const {firstname, lastname, rolename, email, isParentUser, avatar,memberid} = item;
          const name = `${firstname} ${lastname}`;
          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={6}
                container
                direction="row"
                alignItems="center"
              >
                <div className="table-member-details">
                  <div className="member-img">
                    {avatar?.length > 0 ? (
                      <img src={avatar} alt="user" />)
                      : (name ? (
                        <Avatar {...StringAvatar(name)} />) : (
                        <img src={DefaultMemberImg} alt="user" />)
                      )}
                  </div>
                  <div className="member-profile-details">
                    <div className="member-name">
                      {firstname} {lastname}
                    </div>
                    <div className="member-mail-id">{email}</div>
                  </div>
                </div>
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                alignItems="center"
              >
                {rolename ? rolename : '-'}
              </Grid>

              <Grid
                className="td action-col"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
              >
                {/* {
                  AuthorizationService.isAuthorizedHTML("messageMemberUsers") ?
                    <Tooltip title="Chat">
                      <Button
                        className="btn-circle btn-secondary"
                      >
                        <i className="fas fa-comment-dots"></i>
                      </Button>
                    </Tooltip>
                    : null
                } */}
                {
                  AuthorizationService.isAuthorizedHTML("viewAsMember") && 
                  <Tooltip title="view as member">
                      <Button
                        className="btn-circle btn-secondary"
                        onClick={() => {
                          viewAsMember(email)
                          setTimeout(()=>navigate('/dashboard'),500)
                        }}
                      >
                        <i className="fas fa-user-secret"></i>
                      </Button>
                    </Tooltip>
                }
                <Tooltip title="Email">
                  <Button
                    className="btn-circle btn-secondary"
                    variant="contained"
                  >
                    <a className="email-link" href={`mailto:${email}?subject=Buy Right Application Inquiry for ${memberName}`}>
                      <i className="fas fa-envelope" />
                    </a>
                  </Button>
                </Tooltip>
                {
                  AuthorizationService.isAuthorizedHTML("editMemberUsers") &&
                    !isParentUser && <Tooltip title="Edit">
                      <Button
                        className="btn-circle btn-secondary"
                        onClick={() => handleEditMemberUser(item)}
                      >
                        <i className="fas fa-pen"></i>
                      </Button>
                    </Tooltip>
                }
                {
                  AuthorizationService.isAuthorizedHTML("deleteMemberUsers") &&
                    !isParentUser && <Tooltip title="Unassign">
                      <Button
                        className="btn-circle-trash"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteMemberUser(true, item)}
                      >
                        <i class="fas fa-times"></i>
                      </Button>
                    </Tooltip>
                }
                {/* {
                  AuthorizationService.isAuthorizedHTML("deleteMemberUsers") &&
                    !isParentUser && <Tooltip title="Delete">
                      <Button
                        className="btn-circle-trash"
                        variant="outlined"
                        color="secondary"
                        onClick={() => handleDeleteUserMember(true, item)}
                      >
                        <i class="fas fa-trash"></i>
                      </Button>
                    </Tooltip>
                } */}
              </Grid>
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={"Users"} />
      )}
    </div>
  );
};

UserTable.propTypes = {
  gridHeader: PropTypes.array,
  gridData: PropTypes.array,
  setSortKey: PropTypes.func,
  sortType: PropTypes.string,
};