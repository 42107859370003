import {COMMUNITY_CATEGORY_API, UPDATE_COMMUNITY_CHANNEL_ERROR} from "../../../constants";


export const updateCommunityCategory = async ({
    channelID,
    channelName,
    channelDesc
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${COMMUNITY_CATEGORY_API}/${channelID}`, {
            method: "PATCH",
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
            body: JSON.stringify({
                name: channelName,
                description: channelDesc
            })
        }
        );
        if (!response.ok) throw UPDATE_COMMUNITY_CHANNEL_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_COMMUNITY_CHANNEL_ERROR;
    }
};
