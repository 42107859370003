import React, { FC, useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { centsToFormattedDollars, useStyles } from '../../../utils';
import { MemberRebateInfoTable } from './MemberRebateInfoTable';
import DefaultUserImg from '../../../images/profile-placeholder.jpg';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import {CustomModal} from "../../../components/CustomModal/index";
import InputBase from '@material-ui/core/InputBase';
import Button from '@mui/material/Button';
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import AuthorizationService from '../../../authorization/AuthorizationService';


export const MemberRebatesTable: FC<any> = ({
  gridHeader,
  gridData,
  setSortKey,
  sortType,
  memberActiveQuarter,
  updateMemberRebatesInfo,
  updateMemberRebatesInfoDetails,
  quarter,
  year,
  onExportMemberRebatesByQuarterYear,
}) => {
  const classes = useStyles();

  const [activeHeader, setActiveHeader] = useState(null);
  const [showProductDataModal, setShowProductDataModal] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [memberId, setMemberId] = useState<string>('');
  const [memberNameForModal, setMemberNameForModal] = useState<string>('');
  const [isExport, setIsExport] = useState<boolean>(false);

  const handleClick = (event: any, sortKey: any) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  const handleSearch = (event: any) => {
    setSearchText(event.target.value);
  };

  const resetProductDetailData = () => {
    updateMemberRebatesInfoDetails('', []);
    setShowProductDataModal(false);
  }

  const loadMemberProductDetailData = (item : any) => {
    setMemberId(item?.id.toString() ?? '');
    setMemberNameForModal(item?.memberName.toString() ?? '');
    setShowProductDataModal(true);
  }

  const showExport = AuthorizationService.isAuthorizedHTML('earnedRebatesExport');

  const productDataModalChild = (
    <Grid container spacing={2} direction="row" className="filter-form-headers">
      <Grid
        item
        xs={12}
        md={9}
        direction="row"
        justify="flex-end"
        alignItems="center">
        <div className="page-search-box">
          <i className="fas fa-search"></i>
          <InputBase
            placeholder="Search"
            value={searchText.toString()}
            onChange={(event) => handleSearch(event)}
          />
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        direction="row"
        justify="flex-end"
        alignItems="center">
        {showExport && <Button
          onClick={() => {
            onExportMemberRebatesByQuarterYear(memberId, quarter, year, setIsExport);
            console.log("isExport After: ", isExport);
          }}
          variant="contained"
          size="small"
          className="btn-add-blue"
        >
          <span className="fas fa-file-export" />
          <span>Export</span>
        </Button>}
      </Grid>
      <Grid
        item
        xs={12}
        md={12}
        direction="row"
        justify="flex-end"
        alignItems="center">
        <div className="table-wrapper">
          <MemberRebateInfoTable
            updateMemberRebatesInfo={updateMemberRebatesInfo}
            tableType={'MemberRebates'}
            quarter={quarter}
            year={year}
            memberId={memberId}
            searchText={searchText}
            key={searchText}
          />
        </div>
      </Grid>
    </Grid>
  );

  const productDataModalChild1 = (<div className="table-wrapper"><div className="page-search-box">
    <i className="fas fa-search"></i>
    <InputBase
      placeholder="Search"
      value={searchText.toString()}
      onChange={(event) => handleSearch(event)}
    />
  </div>
    <div className="header-filter-box-add">
      {showExport && <Button
        onClick={() => console.log("Export Clicked")}
        variant="contained"
        size="small"
        className="btn-add-blue"
      >
        <span className="fas fa-file-export" />
        <span>Export</span>
      </Button>}
    </div>
    <MemberRebateInfoTable
      updateMemberRebatesInfo={updateMemberRebatesInfo}
      tableType={'MemberRebates'}
      quarter={quarter}
      year={year}
      memberId={memberId}
      searchText={searchText}
      key={searchText}
    /></div>);

  const { status } = memberActiveQuarter || '';
  return (
    <div className={classes.root}>
      {gridData.length > 0 ? (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item: any) => {
            const { label, xs, lg, sortKey } = item;
            const isValidHeader = label !== '';
            return (
              <Grid key={item.value} className="th" item xs={xs} lg={lg}>
                <div>
                  <span
                    className="grid-header"
                    onClick={(event) => handleClick(event, sortKey)}
                  >
                    {label}
                  </span>
                  <span className="sort-icon">
                    {isValidHeader ? (
                      sortType === '' ? (
                        <img className="sort-svg" src={sortDefault} />
                      ) : label === activeHeader && sortType === 'ASC' ? (
                        <img className="sort-svg" src={sortAscIcon} />
                      ) : label === activeHeader && sortType === 'DESC' ? (
                        <img className="sort-svg" src={sortDescIcon} />
                      ) : (
                        <img className="sort-svg" src={sortDefault} />
                      )
                    ) : null}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      ) : null}

      {gridData.length > 0 ? (
        gridData.map((item: any) => {
          const {
            memberName,
            firstName,
            lastName,
            email,
            currentAvailableRebates,
            lastActionTaken,
          } = item;
          return (
            <div className="table-wrapper">
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {memberName ? (
                  <div className="rebates-member">
                      <div className="member-profile-details">
                        <div className="member-name" style={{textDecoration: 'underline', fontWeight: 'bold'}} onClick={() => loadMemberProductDetailData(item)}>
                          {memberName}
                        </div>
                      </div>
                  </div>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={4}
                container
                direction="row"
                alignItems="center"
              >
                {firstName && lastName && email ? (
                  <div className="table-member-details">
                    <div className="member-img">
                      <img src={DefaultUserImg} />
                    </div>
                    <div className="member-profile-details">
                      <div className="member-name">
                        {[firstName, lastName]
                          .filter((name) => !!name)
                          .join(' ')}
                      </div>
                      <div className="member-mail-id">{email}</div>
                    </div>
                  </div>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                {Number(currentAvailableRebates) ? (
                  <div className="rebates-amount">
                    {centsToFormattedDollars(Number(currentAvailableRebates))}
                  </div>
                ) : (
                  '-'
                )}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {lastActionTaken ? (
                  <div>
                    <div className="rebates-deposited">Check Deposited</div>
                    <div className="rebates-date">On {lastActionTaken}</div>
                  </div>
                ) : (
                  '-'
                )}
              </Grid>
              </Grid>
              {showProductDataModal && (
                <ClickAwayListener onClickAway={() => resetProductDetailData()} ><CustomModal
                  showModal={showProductDataModal}
                  closeModal={() => resetProductDetailData()}
                  modalTitle={`${memberNameForModal} - Earned Rebates Detail`}
                  children={productDataModalChild}
                  isCancelButton={true}
                  isSubmitButton={false}
                  isLoading={isExport}
                /></ClickAwayListener>
              )}
            </div>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Member Rebates List'} status={status} />
      )}     
    </div>
  );
};

MemberRebatesTable.propTypes = {
  gridHeader: PropTypes.array,
  gridData: PropTypes.array,
  setSortKey: PropTypes.func,
  sortType: PropTypes.string,
};
