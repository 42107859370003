import moment from 'moment';
import { Button, Tooltip } from '@material-ui/core';
import { QuestionDetailWrapper } from './QuestionDetailWrapper';
import { Loader } from '../../../utils';
import '../community.scss';
import { CommunityQuestionsDetailsBottomContent } from './CommunityQuestionsDetailsBottomContent';
import profilePlaceholder from '../../../images/profile-placeholder.jpg';
import { FC } from 'react';
import { StringAvatar } from '../../../utils/AvatarInitial';
import Avatar from '@mui/material/Avatar';

export const CommunityQuestionsDetail: FC<any> = (props) => {
  const {
    updateQuestionDetail,
    updateActiveDiscussionData,
    activeDiscussion,
    updateActiveDiscussion,
    getChannelDiscussion,
    handleDiscussionLike,
    updatePageNumber,
    communityDiscussionActions: { isDiscussionLiked, discussionId, spinner },
    communityDiscussion: { discussionDetailSpinner, discussionSpinner },
    isAutoFocus,
    setAutoFocus,
  } = props;

  const {
    id,
    channelId,
    likeCount,
    title,
    description,
    createdBy,
    createdAt,
    nextDiscussionId,
    lockedAt,
    avatar,
    userDiscussionLikeId,
  } = activeDiscussion || {};

  const lastUpdated = moment(createdAt).fromNow();

  const handleBackClick = () => {
    updateQuestionDetail(false);
    getChannelDiscussion(channelId);
    updateActiveDiscussionData(null);
    updatePageNumber(1);
  };

  if (activeDiscussion === null) {
    return (
      <div className="section-spinner">
        <Loader size={40} />
      </div>
    );
  } else {
    return (
      <>
        <div className="community-questions-back-previous">
          <div
            className="community-questions-back-previous-button"
            onClick={handleBackClick}
          >
            <i className="fas fa-arrow-left" />
            <span>Back to all Discussions</span>
          </div>
          {nextDiscussionId !== null && (
            <div
              className="community-questions-back-previous-button"
              onClick={() => updateActiveDiscussion(nextDiscussionId)}
            >
              <span>Next Discussions</span>
              <i className="fas fa-arrow-right" />
            </div>
          )}
        </div>

        {discussionSpinner ? (
          <div className="section-spinner">
            <Loader size={40} />
          </div>
        ) : (
          <div className={'discussion-inactive'}>
            <div>
              <div className="community-questions">
                {discussionDetailSpinner &&
                  activeDiscussion &&
                  activeDiscussion.length === 0 && (
                    <div className="discussion-spinner">
                      <Loader size={40} />
                    </div>
                  )}
                <div className="community-questions-left">
                  <div className="community-questions-user">
                    {avatar && avatar.length > 0 ? (
                      <img src={avatar} alt="user" />
                    ) : createdBy ? (
                      <Avatar {...StringAvatar(createdBy)} />
                    ) : (
                      <img src={profilePlaceholder} alt="user" />
                    )}
                  </div>

                  <div className="community-questions-likes">
                    <Tooltip
                      title={userDiscussionLikeId !== null ? 'Unlike' : 'Like'}
                      placement="left"
                    >
                      <Button
                        variant="contained"
                        size="small"
                        className={
                          userDiscussionLikeId !== null
                            ? 'btn-community-trash btn-like comment-liked'
                            : 'btn-community-trash btn-like'
                        }
                        onClick={() =>
                          handleDiscussionLike(
                            discussionId === id ? !isDiscussionLiked : true,
                            activeDiscussion,
                          )
                        }
                        disabled={(spinner && id === discussionId) || lockedAt}
                      >
                        {spinner ? (
                          <div className="section-spinner">
                            <Loader size={10} />
                          </div>
                        ) : (
                          <i className="far fa-heart" />
                        )}
                      </Button>
                    </Tooltip>
                    <div className="community-questions-likes-text">
                      {likeCount}
                    </div>
                  </div>
                </div>

                <div className="community-questions-right">
                  <h2 className="community-questions-title community-questions-title-details">
                    {title}
                  </h2>
                  <div className="community-questions-created-info">
                    <span>
                      By <b>{createdBy}</b>
                    </span>
                    <span>
                      <b>{lastUpdated}</b>
                    </span>
                  </div>
                  <p className="community-questions-description">
                    {description}
                  </p>
                  <CommunityQuestionsDetailsBottomContent {...props} />
                </div>
              </div>
            </div>
            <QuestionDetailWrapper
              {...props}
              isAutoFocus={isAutoFocus}
              setAutoFocus={setAutoFocus}
              lockedAt={lockedAt}
            />
          </div>
        )}
      </>
    );
  }
};

export default CommunityQuestionsDetail;
