import {FC} from 'react';
import '../../scss/styles.scss';
import NotFoundIconSearch from '../../images/search-not-found.svg';

const SearchNotFound: FC<{ userType: any }> = ({ userType }) => {
  return (
    <div className="section-not-found">
      <div className="section-not-found-img">
        <img src={NotFoundIconSearch} />
      </div>
      <h4>No {userType ? userType : 'Items'} Found</h4>
      <p>
        We can’t find any {userType ? userType : 'item'} matching
        your search
      </p>
    </div>
  );
}

export default SearchNotFound;
