import {
  UPDATE_MEMBER_REBATES_EDIT,
  GET_MEMBER_REBATES_EDIT_ERROR,
  UPDATE_MEMBER_REBATES_ERROR,
  MemberRebateInfoError,
  UpdateMemberRebatesError,
} from '../../../constants';

import { rebatesModalSpinner, hideMemberRebateModal } from '../rebates';
import {
  updateMemberRebatesFileDraft,
  getMemberRebatesQuarter,
} from './memberRebates';
import {
  clearMemberRebateModal,
  onChangeFileOne,
  onChangeFileTwo,
  onChangeFileThree,
} from './fileUploadAction';
import {
  getMemberRebatesEditData,
} from '../../../api/rebates';
import {
  getMemberRebateEditFileName,
  getFileSize,
  getEditFileExtension,
} from '../../../utils';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { updateEarnedRebatesRecord } from '../../../api/rebates/memberRebates/updateEarnedRebatesRecord';
import { createEarnedRebatesRecord } from '../../../api/rebates/memberRebates/createEarnedRebatesRecord';

export const handleMemberEditRebates =
  (isEdit) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_MEMBER_REBATES_EDIT,
      isEdit,
    });
    isEdit ? dispatch(onEditMemberRebase()) : null;
  };

export const onEditMemberRebase = () => async (dispatch, getState) => {
  const { memberRebates } = getState();
  const { quarter, year } = memberRebates;
  dispatch(rebatesModalSpinner(true));
  try {
    const res = await getMemberRebatesEditData({
      year,
      quarter,
    });
    const { success, data } = res;
    if (success) {
      const fileOneName = getMemberRebateEditFileName(data[0].fileName1);
      const fileOneSize = getFileSize(data[0].fileSize1);
      const fileOneExtension = getEditFileExtension(data[0].fileName1);

      const fileTwoName = getMemberRebateEditFileName(data[0].fileName2);
      const fileTwoSize = getFileSize(data[0].fileSize2);
      const fileTwoExtension = getEditFileExtension(data[0].fileName2);

      const fileThreeName = getMemberRebateEditFileName(data[0].fileName3);
      const fileThreeSize = getFileSize(data[0].fileSize3);
      const fileThreeExtension = getEditFileExtension(data[0].fileName3);
      dispatch(onChangeFileOne(fileOneName, fileOneSize, fileOneExtension));
      dispatch(onChangeFileTwo(fileTwoName, fileTwoSize, fileTwoExtension));
      dispatch(
        onChangeFileThree(fileThreeName, fileThreeSize, fileThreeExtension),
      );
    }
    dispatch(rebatesModalSpinner(false));
  } catch (error) {
    dispatch(rebatesModalSpinner(false));
    if (error === GET_MEMBER_REBATES_EDIT_ERROR) {
      toast.error(MemberRebateInfoError);
    }
  }
};

export const onUpdateMemberRebates = () => async (dispatch, getState) => {
  const { memberRebates } = getState();
  const {
    quarter,
    year,
    fileOneContent,
    fileTwoContent,
    fileThreeContent,
    isEdit,
    memberActiveQuarter,
  } = memberRebates;
  dispatch(rebatesModalSpinner(true));
  try {
    let res = { success: false };
    if (isEdit) {
      res = await updateEarnedRebatesRecord({
        earnedRebatesRecordId: memberActiveQuarter.id,
        foodbuyItemLevelSpend: fileOneContent,
        foodbuyAllowancesSol: fileTwoContent,
        buyRightSol: fileThreeContent,
      });
    } else {
      res = await createEarnedRebatesRecord({
        foodbuyItemLevelSpend: fileOneContent,
        foodbuyAllowancesSol: fileTwoContent,
        buyRightSol: fileThreeContent,
        year,
        quarter,
      })
    }
    const { success, message } = res;
    if (success) {
      dispatch(updateMemberRebatesFileDraft(true));
      dispatch(clearMemberRebateModal());
      dispatch({
        type: UPDATE_MEMBER_REBATES_EDIT,
        isEdit: false,
      });
      dispatch(rebatesModalSpinner(false));
      toast.success(message);
      dispatch(getMemberRebatesQuarter());
    }
    dispatch(rebatesModalSpinner(false));
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_REBATES_EDIT,
      isEdit: false,
    });
    dispatch(rebatesModalSpinner(false));
    if (error === UPDATE_MEMBER_REBATES_ERROR) {
      toast.error(UpdateMemberRebatesError);
    }
  }
};
