
export const StringToColor = (colorString: string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < colorString.length; i += 1) {
    hash = colorString.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const StringAvatar = (name: string, height: number | null = 32, width: number | null = 32, fontSize: number | null = 12, bgcolor: string | null = StringToColor(name)) => {
  if (name && name.indexOf(' ') >= 0) {
    return {
      sx: {
        bgcolor: bgcolor, height: height, width: width, fontSize: fontSize, //This can be used if you need random background color for different names
      },
      children: `${name?.split(' ')[0][0]?.toUpperCase()}${name?.split(' ')[1][0]?.toUpperCase()}`,
    };
  }
  else if (name) {
    return {
      sx: {
        bgcolor: bgcolor, height: height, width: width, fontSize: fontSize, //This can be used if you need random background color for different names
      },
      children: `${name?.charAt(0)?.toUpperCase()}`,
    };
  }
}