import { FC, useMemo } from "react";
import { MemberActiveEarningsTable } from "../../../../tableUtils/MemberActiveEarningsTable";
import { Loader } from "../../../../../../utils";
import { TablePagination } from '@material-ui/core';
import { SearchNotFound } from '../../../../../../components/SearchNotFound';
import "../../../../rebates.scss";
import "../../../../../../scss/styles.scss";

const gridHeader = [
  {
    label: 'Product',
    value: 'productName',
    sortKey: 'productName',
    xs: 12,
    lg: 6
  },
  {
    label: 'ID',
    value: 'productNumber',
    sortKey: 'productNumber',
    xs: 12,
    lg: 2
  },
  {
    label: 'Quantity',
    value: 'orderSize',
    sortKey: 'orderSize',
    xs: 12,
    lg: 2
  },
  {
    label: 'Amount Earned',
    value: 'amountEarned',
    sortKey: 'amountEarned',
    xs: 12,
    lg: 2
  }
];


export const MemberActiveEarnings: FC<{
  state: any;
  send: any;
}> = ({
  state,
  send,
}) => {

    const showLoader = useMemo(() => {
      return [state.context.records.length === 0, state.hasTag('loading')].every(
        (value) => value,
      );
    }, [state.context.records.length, state.hasTag('loading')]);

    const pageIndex = (state.context.pageNumber - 1) * state.context.pageSize;
    const slicedRecords = state.context.records.slice(
      pageIndex,
      pageIndex + state.context.pageSize,
    );

    const loading = state.hasTag('loading');

    if (showLoader) {
      return (
        <div className="relative-wrapper">
          <div className="loader-wrapper list-spinner">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="table-wrapper">
          {state.context.records.length > 0 ? (<MemberActiveEarningsTable
            gridHeader={gridHeader}
            gridData={slicedRecords ? slicedRecords : []}
            sortOrder={
              state.context.additionalQueryStringValues.sortOrder as
              | 'asc'
              | 'desc'
            }
            setOrderBy={(orderBy: string) => {
              if (state.context.additionalQueryStringValues.orderBy === orderBy) {
                return send({
                  type: 'addQueryStringValue',
                  key: 'sortOrder',
                  value:
                    state.context.additionalQueryStringValues.sortOrder === 'asc'
                      ? 'desc'
                      : 'asc',
                });
              }
              send({
                type: 'addQueryStringValue',
                key: 'orderBy',
                value: orderBy,
              });
            }}
            orderBy={state.context.additionalQueryStringValues.orderBy as string}
          />) : (
            <SearchNotFound sectionType={'Member Rebates List'} />
          )}

          {state.context.records.length > 0 && <TablePagination
            component="div"
            count={state.context.totalRecords}
            page={state.context.pageNumber - 1}
            onPageChange={(_, newPage) => {
              send({
                type: 'goToPage',
                pageNumber: newPage + 1,
              })
            }
            }
            rowsPerPage={state.context.pageSize}
            onRowsPerPageChange={(event) =>
              send({
                type: 'updatePageSize',
                pageSize: parseInt(event.target.value),
              })
            }
            rowsPerPageOptions={[10, 20, 30, 40]}
            labelDisplayedRows={({ from, to, count }) => {
              return `${from}–${to} of ${count !== -1 ? count.toLocaleString() : `more than ${to}`
                }`;
            }}
          />}
        </div >
      );
    };
  };
