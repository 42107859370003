import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import {RebateProductLists, CompactRebates} from "../../../../../constants";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

export const ExportMemberAdminRebatesModal = ({
    handleMemberAdminExportQuarter,
    handleMemberAdminExportYear,
    exportQuarter,
    exportYear,
    quarterList,
    yearList
}) => {

    return (
        <div className="export-wrapper">
            <Grid container spacing={2} className="upload-rebateform-grid">
                <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="select-box">
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Select Quarter
                        </InputLabel>
                        <Select
                            value={exportQuarter}
                            onChange={(event) => handleMemberAdminExportQuarter(event.target.value)}
                            label="Select Quarter"
                        >
                            {quarterList &&
                                quarterList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.value}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <FormControl variant="outlined" className="select-box">
                        <InputLabel htmlFor="outlined-age-native-simple">
                            Select Year
                        </InputLabel>
                        <Select
                            value={exportYear}
                            onChange={(event) => handleMemberAdminExportYear(event.target.value)}
                            label="Select Year"
                        >
                            {yearList &&
                                yearList.map((item, index) => {
                                    return (
                                        <option key={index} value={item.label}>
                                            {item.label}
                                        </option>
                                    );
                                })}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    )
};