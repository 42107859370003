import {
    SHOW_DASHBOARD_KPI_QUARTER_SPINNER,
    SHOW_DASHBOARD_KPI_MEMBER_SPINNER,
    SHOW_DASHBOARD_KPI_DETAIL_SPINNER,
    SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER,
    SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER,
    SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER,
    SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER,
    SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER
} from "../../constants";

export const DashboardKPIQuarterSpinner = (dashboardKPIQuarterSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_KPI_QUARTER_SPINNER,
        dashboardKPIQuarterSpinner
    });
};

export const DashboardKPIMemberSpinner = (dashboardKPIMemberSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_KPI_MEMBER_SPINNER,
        dashboardKPIMemberSpinner
    });
};

export const DashboardKPIDetailSpinner = (dashboardKPIDetailSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_KPI_DETAIL_SPINNER,
        dashboardKPIDetailSpinner
    });
};

export const DashboardOpportunityQuarterSpinner = (dashboardOppQuarterSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_OPPORTUNITY_QUARTER_SPINNER,
        dashboardOppQuarterSpinner
    });
};

export const DashboardOpportunityListSpinner = (dashboardOpportunityListSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_OPPORTUNITY_LIST_SPINNER,
        dashboardOpportunityListSpinner
    });
};

export const DashboardActiveEarningsQuarterSpinner = (dashboardActiveEarningsQuarterSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_ACTIVE_EARNINGS_QUARTER_SPINNER,
        dashboardActiveEarningsQuarterSpinner
    });
};

export const DashboardActiveEarningsMemberSpinner = (dashboardActiveEarningsMemberSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_ACTIVE_EARNINGS_MEMBER_SPINNER,
        dashboardActiveEarningsMemberSpinner
    });
};

export const DashboardActiveEarningsDetailSpinner = (dashboardActiveEarningsDetailSpinner) => async (dispatch) => {
    dispatch({
        type: SHOW_DASHBOARD_ACTIVE_EARNINGS_DETAIL_SPINNER,
        dashboardActiveEarningsDetailSpinner
    });
};