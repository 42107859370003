import {
  CLEAR_ADSTYPE_FORM,
  UPDATE_ADSTYPE_FORM_SPINNER,
  UPDATE_ADSTYPE_IS_UPDATED,
  UPDATE_ADSTYPE_NAME,
  UPDATE_ADSTYPE_DESC,
  UPDATE_ADSTYPE_NAME_ERROR,
  UPDATE_ADSTYPE_DESC_ERROR,
} from "../../constants";

const initialState = {
  loading: false,
  isFormUpdated: false,
  adTypeName: "",
  adTypeDesc: "",
  errorAdTypeName: false,
  errorAdTypeDesc: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADSTYPE_FORM_SPINNER:
      return {
        ...state,
        loading: action.spinner,
      };
    case UPDATE_ADSTYPE_IS_UPDATED:
      return {
        ...state,
        isFormUpdated: action.flag,
      };
    case UPDATE_ADSTYPE_NAME:
      return {
        ...state,
        adTypeName: action.value,
      };
    case UPDATE_ADSTYPE_DESC:
      return {
        ...state,
        adTypeDesc: action.value,
      };
    case UPDATE_ADSTYPE_NAME_ERROR:
      return {
        ...state,
        errorAdTypeName: action.flag,
      };
    case UPDATE_ADSTYPE_DESC_ERROR:
      return {
        ...state,
        errorAdTypeDesc: action.flag,
      };
    case CLEAR_ADSTYPE_FORM:
        return initialState;
    default:
      return state;
  }
};
