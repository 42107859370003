import { useActor } from '@xstate/react';
import { useMemo } from 'react';
import { GaugeChartProps } from 'react-gauge-chart';
import { ActorRef } from 'xstate';
import { useSelector as useReduxSelector, shallowEqual } from 'react-redux';

export const useSelectedAgreementValues = (
  dropSizeAgreementsActor: ActorRef<any, any>, // TODO Temporary fix until correct format is discoverd or bug is resolved,
  id?: string,
): [
  gaugeChartProps: GaugeChartProps,
  averageDropSize: number,
  lowRange: number,
  highRange: number,
  validGraph: any,
  avgFontClass:
    | 'below-avg-score'
    | 'above-avg-score'
    | 'within-avg-score'
    | null,
  details: string,
] => {
  const [state] = useActor(dropSizeAgreementsActor);
  const isBRAdmin = useReduxSelector(
    (state: { user: { isBRAdmin: boolean } }) =>
      state?.user?.isBRAdmin ?? false,
    shallowEqual,
  );

  const { averageDropSize, lowRange, highRange, superHighRange } = useMemo(
    () =>
      state.context.agreements.filter(
        (agreement: any) =>
          agreement.name === state.context.selectedAgreementName, // TODO Temporary fix until correct format is discoverd or bug is resolved,
      )?.[0] ?? {},
    [state.hasTag('loading'), state.event.type],
  );

  const validGraph = useMemo(
    () =>
      [
        (lowRange ?? null) !== null,
        (highRange ?? null) !== null,
        (superHighRange ?? null) !== null,
      ].some((value) => value),
    [lowRange, highRange, superHighRange],
  );

  const avgFontClass = useMemo(() => {
    if (
      typeof averageDropSize === 'number' &&
      typeof lowRange === 'number' &&
      typeof highRange === 'number'
    ) {
      if (averageDropSize < lowRange) return 'below-avg-score';
      if (averageDropSize > lowRange && averageDropSize < highRange)
        return 'above-avg-score';
      return 'within-avg-score';
    }
    return null;
  }, [averageDropSize, lowRange, highRange, validGraph, superHighRange]);

  const [details, gaugeChartProps] = useMemo(() => {
    let message = `No drop size available.${
      isBRAdmin && !validGraph && !state.hasTag('loading')
        ? ' No drop size agreement assigned for this Member.'
        : ''
    }`;
    let props: GaugeChartProps = {
      id: id || 'drop-size-kpi-agreement-gauge-chart',
      arcsLength: [0.3, 0.5, 0.2],
      colors: ['#d6d6d6', '#d6d6d6', '#d6d6d6'],
      arcPadding: 0.02,
      arcWidth: 0.24,
      cornerRadius: 0,
      needleColor: '#878582',
      hideText: true,
      animate: false,
      percent: 0,
    };
    if (validGraph) {
      message = `Your pricing tier is based on your Average Drop Size. Your current target is ${
        highRange
          ? `between ${(lowRange ?? 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}-${highRange.toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`
          : `at least ${(lowRange ?? 0).toLocaleString('en-US', {
              style: 'currency',
              currency: 'USD',
            })}`
      }. ${
        (highRange ?? 0) > 0
          ? 'You can improve your overall pricing by increasing your Average Drop Size above this range. '
          : ''
      }If your Average Drop Size dips below the current tier, pricing is subject to
        review after 90 days.`;

      const arcOne =
        typeof lowRange === 'number' && typeof superHighRange === 'number'
          ? lowRange / superHighRange
          : 0;
      const arcTwo =
        typeof highRange === 'number' &&
        typeof arcOne === 'number' &&
        typeof superHighRange === 'number'
          ? highRange / superHighRange - arcOne
          : 0;
      const arcThree =
        typeof arcTwo === 'number' && typeof arcOne === 'number'
          ? 1 - arcTwo - arcOne
          : 0;
      const percentValue =
        typeof superHighRange === 'number' &&
          typeof averageDropSize === 'number'
          ? (lowRange > highRange && highRange === 0) ? averageDropSize / (2 * superHighRange) : averageDropSize / superHighRange
          : 0;
      const percent = percentValue < 1 ? percentValue : 1; //This will prevent the pointer pointing beyond the max value of the chart.

      props = {
        ...props,
        animate: true,
        animateDuration: 2000,
        arcsLength: [arcOne, arcTwo, arcThree],
        colors: ['#ff9900', '#0f76c2', '#27ae60'],
        percent,
      };
    }
    return [message, props];
  }, [
    validGraph,
    averageDropSize,
    lowRange,
    highRange,
    superHighRange,
    state.hasTag('loading'),
  ]);

  return [
    gaugeChartProps,
    averageDropSize ?? 0,
    lowRange ?? 0,
    highRange ?? 0,
    validGraph,
    avgFontClass,
    details,
  ];
};

export default useSelectedAgreementValues;
