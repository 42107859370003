export const memberGridHeader = [
  {
    label: 'Amount Requested',
    value: 'amount',
    sortKey: 'amount',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Requested On',
    value: 'createdat',
    sortKey: 'createdat',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Payment Type',
    value: 'name',
    sortKey: 'name',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Status',
    value: 'deliveredat', //status key to get the status of claim
    sortKey: 'deliveredat',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Received On',
    value: 'deliveredat',
    sortKey: 'deliveredat',
    xs: 12,
    lg: 2,
  },
];

export const adminGridHeader = [
  {
    label: 'Amount Requested',
    value: 'amount',
    sortKey: 'amount',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Requested On',
    value: 'createdat',
    sortKey: 'createdat',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Payment Type',
    value: 'name',
    sortKey: 'name',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Status',
    value: 'deliveredat',
    sortKey: 'deliveredat',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Received On',
    value: 'deliveredat',
    sortKey: 'deliveredat',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Action',
    value: '',
    sortKey: '',
    xs: 12,
    lg: 1,
  },
];