import { FC } from 'react';
import { useStyles } from '../../../../../utils';
import { Grid } from '@material-ui/core';
import { gridHeader, gridData } from './util/constants';
import EBrandTableHeaderRow from './EBrandTableHeaderRow';
import EBrandTableRow from './EBrandTableRow';

export const EBrandTable: FC = () => {
  const classes = useStyles();
  return (
    <div
      style={{ paddingLeft: '12px', paddingTop: '24px' }}
      className="dashboard-ebrand-table static-height"
    >
      <Grid container spacing={3}>
        <h3>E-Brands</h3>
        <div className={classes.root}>
          <div className="brand-title">% Rebates earned on E-Brand items</div>
          {gridHeader.length > 0 && (
            <Grid container spacing={0} className="tr thead">
              {gridHeader.map((item, key) => (
                <EBrandTableHeaderRow {...item} key={key} />
              ))}
            </Grid>
          )}
          {gridData.map((item, key) => (
            <EBrandTableRow {...item} key={key} />
          ))}
        </div>
      </Grid>
    </div>
  );
};
