export const getFileSize = (fileInBytes: number) => {
  const decimals = 2;
  if (fileInBytes === 0) return '0 fileInBytes';
  const fileKb = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['fileInBytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(fileInBytes) / Math.log(fileKb));
  const fileSize =
    parseFloat((fileInBytes / Math.pow(fileKb, i)).toFixed(dm)) +
    ' ' +
    sizes[i];
  return fileSize;
};
