import {
  UPDATE_MEMBERS_LIST,
  UPDATE_MEMBERS_LIST_ERROR,
  MemberListError,
  UPDATE_MEMBER_VIEW,
  UPDATE_MEMBERS_LIST_SPINNER,
  CLEAR_MEMBER_DETAIL,
  UPDATE_MEMBER_DETAIL,
  UPDATE_MEMBER_USER_SPINNER,
  UPDATE_MEMBER_USER_LIST,
  UPDATE_MEMBER_USER_LIST_ERROR,
  MemberUserListError,
  UPDATE_MEMBER_VENDOR_LIST,
  UPDATE_MEMBER_VENDOR_SPINNER,
  UPDATE_MEMBER_VENDOR_LIST_ERROR,
  MemberVendorListError,
  UPDATE_MEMBER_LOCATION_LIST,
  UPDATE_MEMBER_LOCATION_SPINNER,
  UPDATE_MEMBER_LOCATION_LIST_ERROR,
  MemberLocationListError,
  UPDATE_MEMBER_DELETE_SPINNER,
  UPDATE_MEMBER_RIDERECT_ON_DELETE,
  UPDATE_MEMBER_DELETE_ERROR,
  FetchError,
  MemberDeleteSuccess,
  MemberDeleteError,
  GET_MEMBER_LOGIN_ATTEMPT,
  VIEW_AS_MEMBER,
  MEMBER_VIEW_STATE,
  CLEAR_MEMBER_VIEW,
  GET_ASSIGNED_MEMBER_USERS,
} from "../../constants";
import {
  getMembers as getMembersListFunc,
  getMemberById,
  getMemberUserList,
  getMemberVendorList,
  getMemberLocationList,
  deleteMemberById,
  updateMemberIsActive,
  getMemberLoginAttempt,
  viewAdminAsMember,
  getAssignedMemberUsers,
} from "../../api/members";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const onChangeMemberView = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_VIEW,
    viewType: value,
  });
};

export const getMembers = () => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBERS_LIST_SPINNER,
    spinner: true,
  });
  try {
    const membersList = await getMembersListFunc();
    dispatch({
      type: UPDATE_MEMBERS_LIST,
      membersList: membersList.data.result,
    });
    dispatch({
      type: UPDATE_MEMBERS_LIST_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBERS_LIST_SPINNER,
      spinner: false,
    });

    if (error === UPDATE_MEMBERS_LIST_ERROR) {
      toast.error(MemberListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const clearMemberDetails = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MEMBER_DETAIL
  });
};

export const viewMember = (id) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBERS_LIST_SPINNER,
    spinner: true,
  });
  try {
    const memberDetail = await getMemberById(id);
    if (memberDetail.success) {
      dispatch({
        type: UPDATE_MEMBER_DETAIL,
        detail: memberDetail.data,
      });
      dispatch({
        type: UPDATE_MEMBERS_LIST_SPINNER,
        spinner: false,
      });
    }
  } catch (error) {
  }
};

export const getMemberUsers = () => async (dispatch, getState) => {
  const {members} = getState();
  const {type, selectedMember} = members;
  dispatch({
    type: UPDATE_MEMBER_USER_SPINNER,
    spinner: true,
  });
  try {
    const usersList = await getMemberUserList(selectedMember?.id, type);
    dispatch({
      type: UPDATE_MEMBER_USER_LIST,
      list: usersList.data,
    });
    dispatch({
      type: UPDATE_MEMBER_USER_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_USER_SPINNER,
      spinner: false,
    });

    if (error === UPDATE_MEMBER_USER_LIST_ERROR) {
      toast.error(MemberUserListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getMemberLogin = () => async (dispatch, getState) => {
  const {members} = getState();
  const {selectedMember} = members;
  dispatch({
    type: UPDATE_MEMBER_VENDOR_SPINNER,
    spinner: true,
  });
  try {
    const usersList = await getMemberLoginAttempt(selectedMember?.id);
    dispatch({
      type: GET_MEMBER_LOGIN_ATTEMPT,
      list: usersList.data.data,
    });
    dispatch({
      type: UPDATE_MEMBER_VENDOR_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_VENDOR_SPINNER,
      spinner: false,
    });
    if (error === UPDATE_MEMBER_VENDOR_LIST_ERROR) {
      toast.error(MemberVendorListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getMemberVendors = () => async (dispatch, getState) => {
  const {members} = getState();
  const {type, selectedMember} = members;
  dispatch({
    type: UPDATE_MEMBER_VENDOR_SPINNER,
    spinner: true,
  });
  try {
    const usersList = await getMemberVendorList(selectedMember.id);
    dispatch({
      type: UPDATE_MEMBER_VENDOR_LIST,
      list: usersList.data,
    });
    dispatch({
      type: UPDATE_MEMBER_VENDOR_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_VENDOR_SPINNER,
      spinner: false,
    });

    if (error === UPDATE_MEMBER_VENDOR_LIST_ERROR) {
      toast.error(MemberVendorListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const getMemberLocations = () => async (dispatch, getState) => {
  const {members} = getState();
  const {selectedMember} = members;
  const {id} = selectedMember;
  dispatch({
    type: UPDATE_MEMBER_LOCATION_SPINNER,
    spinner: true,
  });
  try {
    const usersList = await getMemberLocationList(id);
    dispatch({
      type: UPDATE_MEMBER_LOCATION_LIST,
      list: usersList.data,
    });
    dispatch({
      type: UPDATE_MEMBER_LOCATION_SPINNER,
      spinner: false,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_LOCATION_SPINNER,
      spinner: false,
    });

    if (error === UPDATE_MEMBER_LOCATION_LIST_ERROR) {
      toast.error(MemberLocationListError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const deleteMember = () => async (dispatch, getState) => {
  const {members} = getState();
  const {selectedMember} = members;
  const {id} = selectedMember;

  dispatch({
    type: UPDATE_MEMBER_DELETE_SPINNER,
    spinner: true,
  });

  try {
    const deleteResponse = await deleteMemberById(id);
    const {success} = deleteResponse;
    if (success) {
      toast.success(MemberDeleteSuccess);

      dispatch({
        type: UPDATE_MEMBER_DELETE_SPINNER,
        spinner: false,
      });
      dispatch({
        type: UPDATE_MEMBER_RIDERECT_ON_DELETE,
        redirect: true,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_DELETE_SPINNER,
      spinner: false,
    });
    if (error === UPDATE_MEMBER_DELETE_ERROR) {
      toast.error(MemberDeleteError);
    } else {
      toast.error(FetchError);
    }
  }
};
export const updateMemberInActive = () => async (dispatch, getState) => {
  const {members} = getState();
  const {selectedMember} = members;
  const {id} = selectedMember;

  dispatch({
    type: UPDATE_MEMBER_DELETE_SPINNER,
    spinner: true,
  });

  try {
    const deleteResponse = await updateMemberIsActive(id);
    const {success} = deleteResponse;
    if (success) {
      toast.success(MemberDeleteSuccess);

      dispatch({
        type: UPDATE_MEMBER_DELETE_SPINNER,
        spinner: false,
      });
      dispatch({
        type: UPDATE_MEMBER_RIDERECT_ON_DELETE,
        redirect: true,
      });
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_DELETE_SPINNER,
      spinner: false,
    });
    if (error === UPDATE_MEMBER_DELETE_ERROR) {
      toast.error(MemberDeleteError);
    } else {
      toast.error(FetchError);
    }
  }
};

export const viewAsMember = (email,id) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBERS_LIST_SPINNER,
    spinner: true,
  });
  if(email){
    try{
      const response = await viewAdminAsMember({email})
      dispatch({
        type : MEMBER_VIEW_STATE,
        data : true
      })
      dispatch({
        type : VIEW_AS_MEMBER,
        data : response,
      })
      dispatch({
        type : MEMBER_VIEW_STATE,
      })
    }catch(err){
      console.log('Error in view as member action file -',err)
    }
  }

};

// clear view as member
export const clearMemberView = () => async(dispatch) => {
  dispatch({
    type : CLEAR_MEMBER_VIEW,
  })
}

export const fetchAssignedMemberUsers = (ID) => async (dispatch, getState) => {
  const { members } = getState();
  const { selectedMember } = members;
  // const { id } = selectedMember;
  const id = selectedMember !== null ? selectedMember?.id : ID
  try {
    const assignedMemberUserList = await getAssignedMemberUsers(id);
    dispatch({
      type: GET_ASSIGNED_MEMBER_USERS,
      payload: assignedMemberUserList?.data?.data?.assignedUsers,
    });
  } catch (error) {
    console.log(error);
    toast.error(FetchError);
  }
};