import { baseUrl, EXPORT_REEBATE_PRODUCT_LIST_ERROR } from '../../../constants';

export const exportRebateProductList = async ({
  exportQuarter,
  exportYear,
}) => {
  try {
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch(
      `${baseUrl}/rebate-product-list-records/export?quarter=${exportQuarter}&year=${exportYear}`,
      {
        headers: new Headers({
          'x-access-token': user.accessToken,
          'Content-Type': 'application/json',
        }),
      },
    );
    if (!response.ok) throw DOWNLOAD_DOCUMENT_ERROR;
    const blob = await response.blob();
    return blob;
  } catch (error) {
    throw EXPORT_REEBATE_PRODUCT_LIST_ERROR;
  }
};
