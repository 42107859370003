import { baseUrl, VendorUserAssignError } from '../../constants';

export const assignVendorUser = async (formattedActiveUsers) => {
  try {
    const vendorDistributionCenterId = formattedActiveUsers?.[0]?.selectedDC;
    const reformattedActiveUsers = formattedActiveUsers.map(({ roleId, userId }) => ({
      roleId,
      userId,
    }))    
    const user = JSON.parse(localStorage.getItem('user'));
    const response = await fetch(`${baseUrl}/vendor-distribution-centers/${vendorDistributionCenterId}/users?userRolePairList=${JSON.stringify(reformattedActiveUsers)}`, {
      method: 'POST',
      headers: new Headers({
        'x-access-token': user.accessToken,
        'Content-Type': 'application/json',
      }),
      body: JSON.stringify(formattedActiveUsers),
    });
    if (!response.ok) throw response;
    const responseJson = await response.json();
    return responseJson;
  } catch (error) {
    throw VendorUserAssignError;
  }
};
