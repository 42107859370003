import { FC } from 'react';
import Grid from '@material-ui/core/Grid';

interface Props {
  image: string;
}

export const VendorsHeader: FC<Props> = ({image}) => (
  <Grid container spacing={2} className="details-info-box">
    <Grid item xs={12} lg={4}>
      <div className="details-info-user">
        <div className="details-info-user-left">
          <div className="details-user-img">
            <img
              src={image}
              alt="Performance Foodservice®, 'We are in this together.'"
              title="Vendor - Performance Foodservice"
            />
          </div>
        </div>
        {/* <div className="details-info-user-right">
          <div className="details-user-content">
            <div className="details-user-content-text">
              <i className="fas fa-mobile-alt"></i>
              <span>817-562-7569</span>
            </div>
          </div>
          <div className="details-user-content">
            <div className="details-user-content-text">
              <i className="far fa-calendar-check"></i>
              <span>03/23/2020</span>
              Joined Since
            </div>
          </div>
        </div> */}
      </div>
    </Grid>
  </Grid>
);

export type VendorsHeaderType = typeof VendorsHeader;
