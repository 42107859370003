import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export const Loader = (props) => {
  const { size, color } = props;

  return (
    <span
      style={{
        paddingLeft: 15,
        color: "white",
      }}
    >
      <CircularProgress
        size={size}
        sx={{ position: "absolute" }}
        style={{ color: color }}
      />
    </span>
  );
};
