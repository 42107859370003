import {
    UPDATE_IS_DETAIL_MODAL,
    UPDATE_SELECTED_DOCUMENT_DATA,
    UPDATE_SELECT_MEMBERS_LIST,
    UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR,
    DocumentSelectMemberListError,
    FetchError
} from "../../constants";
import {showDocumentModalSpinner, hideDocumentModalSpinner} from "./documents";
import {getSelectMemberList} from "../../api/documents"

export const getSelectMembersList = (memberID, publicat) => async (dispatch) => {
    dispatch(showDocumentModalSpinner());
    try {
        const response = await getSelectMemberList(memberID, publicat);
        const {data} = response || {};
        const {members} = data || [];
        dispatch({
            type: UPDATE_SELECT_MEMBERS_LIST,
            selectMemberList: members
        })
        dispatch(hideDocumentModalSpinner());
    } catch (error) {
        dispatch(hideDocumentModalSpinner());
        if (error === UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR) {
            toast.error(DocumentSelectMemberListError)
        } else {
            toast.error(FetchError)
        }
    }
}

export const showDocumentDetailModal = (selectedDocument) => async (dispatch, getState) => {
    const {
        id,
        publicat,
    } = selectedDocument;
    dispatch(getSelectMembersList(id, publicat));
    const {documents} = getState();
    const {categoryList} = documents;
    dispatch({
        type: UPDATE_IS_DETAIL_MODAL,
        isDetailModal: true
    });
    dispatch({
        type: UPDATE_SELECTED_DOCUMENT_DATA,
        selectedDocumentData: selectedDocument
    });
};

export const closeDocumentDetailModal = () => async (dispatch, getState) => {
    dispatch({
        type: UPDATE_IS_DETAIL_MODAL,
        isDetailModal: false
    });
    dispatch({
        type: UPDATE_SELECTED_DOCUMENT_DATA,
        selectedDocumentData: null
    });
};
