import { FC } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import { ProductRebatesTable } from '../../../rebates/tableUtils/ProductRebatesTable';
import { Loader } from '../../../../utils';
import { TablePagination } from '@mui/material';

const gridHeader = [
  {
    label: 'Product Name',
    value: 'productName',
    sortKey: '',
    xs: 12,
    lg: 4,
  },
  {
    label: 'Vendor Product #',
    value: 'productNumber',
    sortKey: '',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Pack Size',
    value: 'packSize',
    sortKey: '',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Type',
    value: 'rebateType',
    sortKey: '',
    xs: 12,
    lg: 1,
  },
  {
    label: 'Amount',
    value: 'rebateAmount',
    sortKey: '',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Percent',
    value: 'rebatePercent',
    sortKey: '',
    xs: 12,
    lg: 1,
  },
];

export const OpportunityTable: FC<{
  opportunityList: {
    rebateId: number;
    productNumber: string;
    productName: string;
    manufacturerName: string;
    packSize: string;
    rebateAmount: number;
    rebatePercent: number;
    rebateproductlistrecordId: number;
    notes: string;
    rebateType: string;
  }[];
  dashboardTable: boolean;
  loading: boolean;
  updateOpportunitiesRoute: (navigate: NavigateFunction) => void;
  pageSize: number;
  page: number;
  onPageChange: (event: any, pageSize: number) => void;
}> = ({
  opportunityList,
  dashboardTable,
  loading,
  updateOpportunitiesRoute,
  page,
  pageSize,
  onPageChange,
}) => {
  const navigate = useNavigate();
  const paginatedOpportunityList = [...opportunityList].splice(
    page * pageSize,
    pageSize,
  );
  return (
    <div
      className={
        dashboardTable
          ? 'dashboard-table static-height'
          : 'dashboard-table table-wrapper-hide'
      }
    >
      <Grid container spacing={3}>
        <div
          className={
            dashboardTable
              ? 'table-wrapper'
              : 'table-wrapper table-wrapper-hide'
          }
        >
          {loading ? (
            <div className="relative-wrapper">
              <div className="loader-wrapper list-spinner">
                <div className="document-loader">
                  <Loader size={20} />
                </div>
              </div>
            </div>
          ) : (
            <div>
              <ProductRebatesTable
                gridHeader={gridHeader}
                gridData={paginatedOpportunityList}
                showNotesModal={false}
                activeQuarter={1} // TODO
                isRebateNote={false}
                isDashboardList={true}
                updateSelectedProduct={undefined}
                setSortKey={undefined}
                sortType={undefined}
              />
              {opportunityList.length > 0 && (
                <TablePagination
                  component="div"
                  count={opportunityList.length}
                  page={page}
                  onPageChange={onPageChange}
                  rowsPerPage={pageSize}
                  rowsPerPageOptions={[pageSize]}
                  labelDisplayedRows={({ from, to, count }) => {
                    return `${from}–${to} of ${
                      count !== -1 ? count.toLocaleString() : `more than ${to}`
                    }`;
                  }}
                />
              )}
            </div>
          )}
        </div>
      </Grid>

      {!loading && (
        <div
          className="dashboard-link"
          onClick={() => updateOpportunitiesRoute(navigate)}
        >
          <span>View Detailed Opportunities</span>
          <i className="fas fa-chevron-right" />
        </div>
      )}
    </div>
  );
};

export default OpportunityTable;
