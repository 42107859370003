import { Grid, Tooltip } from '@material-ui/core';
import { FC } from 'react';
import AuthorizationService from '../../../../authorization/AuthorizationService';
import { Opportunity } from '../../../../common';
import { Loader, centsToFormattedDollars } from '../../../../utils';

export const OpportunitiesTableRow: FC<{
  loading?: boolean;
  opportunity: Opportunity;
  handleInfoClick: (opportunity: Opportunity) => void;
}> = ({ handleInfoClick, loading = false, opportunity }) => {
  const {
    productName,
    productNumber,
    packSize,
    rebateType,
    rebateAmount,
    rebatePercent,
  } = opportunity;
  const isRebateNote = AuthorizationService.isAuthorizedHTML(
    'rebatesOpportunityNote',
  );
  return (
    <Grid container spacing={0} className="tr">
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={3}
        container
        direction="row"
        alignItems="center"
      >
        {loading ? <Loader size={10} /> : productName ? productName : '-'}
      </Grid>
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
      >
        <div className="br-number-normal">
          {loading ? <Loader size={10} /> : productNumber ? productNumber : '-'}
        </div>
      </Grid>
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
      >
        <div className="br-number-normal">
          {loading ? <Loader size={10} /> : packSize ? packSize : '-'}
        </div>
      </Grid>
      <Grid
        className="td xs-left-align xs-primary table-data-nowrap"
        item
        xs={12}
        lg={1}
        container
        direction="row"
        alignItems="center"
      >
        {loading ? <Loader size={10} /> : rebateType ? rebateType : '-'}
      </Grid>
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={2}
        container
        direction="row"
        alignItems="center"
      >
        <div className="br-number-normal br-align-right br-block-100 table-data-spacing">
          {loading ? (
            <Loader size={10} />
          ) : (rebateAmount ?? 0) > 0 &&
            Number(rebateAmount) ? (
            centsToFormattedDollars(Number(rebateAmount))
          ) : (
            '-'
          )}
        </div>
      </Grid>
      <Grid
        className="td xs-left-align xs-primary"
        item
        xs={12}
        lg={1}
        container
        direction="row"
        alignItems="center"
      >
        <div className="br-number-normal">
          {loading ? (
            <Loader size={10} />
          ) : rebatePercent ? (
            `${rebatePercent} %`
          ) : (
            '-'
          )}
        </div>
      </Grid>

      {isRebateNote && (
        <Grid
          className="td action-col"
          item
          xs={12}
          lg={1}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title="Notes">
            <div
              className="info-rebates-notes"
              onClick={() => handleInfoClick(opportunity)}
            >
              <i className="fas fa-info" />
            </div>
          </Tooltip>
        </Grid>
      )}
    </Grid>
  );
};

export default OpportunitiesTableRow;
