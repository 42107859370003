import serverApiV2Instance from '../../../services/serverApiV2Instance';

export const addSavingsPercentage = async (percentage) => {
    try {

        const response = await serverApiV2Instance.post('/saving-percentage',{percentage});

        if(!response?.data){
            throw 'Failed to add saving percentage'
        }
   
        return response?.data
    } catch (error) {
        if(error){
            throw `Failed to add savings percentage - ${error?.response?.data?.message}`
        }        
    }
}