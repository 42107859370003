import { useState, useEffect, FC } from 'react';
import SelectBox from '../../../components/SelectBox';
import InputWithFloatingLabel from '../../../components/InputWithFloatingLabel';
import { CustomDatePicker } from '../../../components/CustomDatePicker';
import MemberImg from '../../../images/member-grid-placeholder.jpg';
import { ZIP_EMPTY_MESSAGE, ZIP_VALID_MESSAGE } from '../../../utils';
import moment from 'moment';
import { Button, Grid, TextField } from '@material-ui/core';
import { Checkbox, FormControlLabel } from '@mui/material';

interface AddMemberFormProps {
  handleClickCancel: any;
  onChangeAddMemberParent: any;
  onChangeAddMemberName: any;
  onChangeAddMemberStreet: any;
  onChangeAddMemberCity: any;
  onChangeAddMemberState: any;
  onChangeAddMemberZip: any;
  onChangeAddMemberPhone: any;
  onChangeAddMemberMobile: any;
  onChangeAddMemberJoinDate: any;
  onChangeAddMemberAvatarImage: any;
  onChangeAddMemberCanHandDeliver: any;
  onAddMember: any;
  onEditMember: any;
  memberForm: any;
  membersList: any;
  statesList: any;
  isLocationMode: any;
}

export const AddMemberForm: FC<AddMemberFormProps> = ({
  handleClickCancel,
  onChangeAddMemberParent,
  onChangeAddMemberName,
  onChangeAddMemberStreet,
  onChangeAddMemberCity,
  onChangeAddMemberState,
  onChangeAddMemberZip,
  onChangeAddMemberPhone,
  onChangeAddMemberMobile,
  onChangeAddMemberJoinDate,
  onChangeAddMemberAvatarImage,
  onChangeAddMemberCanHandDeliver,
  onAddMember,
  onEditMember,
  memberForm,
  membersList,
  statesList,
  isLocationMode,
}) => {
  const {
    spinner,
    isEdit,
    isFormUpdated,
    parentId,
    id,
    name,
    street,
    city,
    state,
    zip,
    phone,
    mobile,
    joiningDate,
    canHandDeliver,
    avatarPath,
    errorName,
    errorStreet,
    errorCity,
    errorState,
    errorZip,
    childCount,
  } = memberForm;

  // declare
  const isLoading = spinner || false;
  const isAddMode = !id;
  const disableFlag = !isFormUpdated || isLoading;
  const [selectedImg, setSelectedImg] = useState(
    avatarPath ? `/${avatarPath}` : MemberImg,
  );
  const [selectedUser, setSelectedUser] = useState('');

  const onImageUpload = (file?: any) => {
    if (!file) return;
    setSelectedUser(file);
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setSelectedImg(reader.result);
      }
    };
  };

  useEffect(() => {
    onChangeAddMemberAvatarImage(selectedUser, selectedImg);
  }, [selectedImg]);

  return (
    <div className="container add-member-content br-form-element">
      <Grid container spacing={6}>
        <Grid item xs={12} sm={7} md={8}>
          <Grid>
            {childCount > 0 ? (
              <div style={{ fontWeight: 'bold' }}>PARENT MEMBER</div>
            ) : (
              <SelectBox
                objFormat={null}
                labelName="Parent Member"
                inputValue={parentId}
                handleSelectChange={onChangeAddMemberParent}
                optionList={
                  membersList.filter((member: any) => !member.parentid && memberForm.id !== member.id) || null
                }
                errorState={false}
                errorMessage={''}
                isLoading={false}
              />
            )}
          </Grid>
          <Grid className="mt-24">
            <InputWithFloatingLabel
              labelName="Name"
              inputValue={name}
              handleOnChange={onChangeAddMemberName}
              errorStatus={errorName}
              errorMessage={'Please enter name'}
              required={true}
              isLoading={isLoading}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <div className="image-add-field">
            {selectedImg &&
              (isAddMode ? (
                <img
                  alt="memberImage"
                  src={selectedImg}
                  onError={() => {
                    setSelectedImg(MemberImg);
                  }}
                />
              ) : (
                <img
                  alt="memberImage"
                  src={selectedImg !== MemberImg ? selectedImg : (avatarPath ? `/${avatarPath}` : MemberImg)}
                  onError={() => {
                    setSelectedImg(MemberImg);
                  }}
                />)
              )
            }
          </div>
          <div className="upload-img">
            <Button className="img-filed" component="label">
              <i className="far fa-image image-field-icon"></i>
              {isAddMode ? 'Add Image' : 'Update Image'}
              <input
                type="file"
                onChange={(e) => onImageUpload(e?.target?.files?.[0])}
                hidden
                disabled={isLoading}
              />
            </Button>
          </div>
        </Grid>
      </Grid>

      <h3 className="add-member-heading">Member Location</h3>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            error={errorStreet}
            id={errorStreet ? 'standard-error' : 'outlined-basic'}
            label="Street"
            variant="outlined"
            className="text-box"
            helperText={errorStreet ? 'Please enter street' : ''}
            onChange={(e) => onChangeAddMemberStreet(e.target.value)}
            required
            disabled={isLoading}
            value={street}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            error={errorCity}
            id={errorCity ? 'standard-error' : 'outlined-basic'}
            label="City"
            variant="outlined"
            className="text-box"
            helperText={errorCity ? 'Please enter city' : ''}
            onChange={(e) => onChangeAddMemberCity(e.target.value)}
            required
            disabled={isLoading}
            value={city}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <SelectBox
            labelName={'State *'}
            inputValue={state}
            handleSelectChange={onChangeAddMemberState}
            optionList={statesList || null}
            errorState={errorState}
            errorMessage={'Please select state'}
            isLoading={spinner}
            objFormat={{ key: 'abbreviation', value: 'name' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            error={errorZip}
            id={errorZip ? 'standard-error' : 'outlined-basic'}
            label="Zip"
            variant="outlined"
            className="text-box"
            type="text"
            value={zip}
            inputProps={{
              maxLength: 10,
            }}
            helperText={
              errorZip ? (zip ? ZIP_VALID_MESSAGE : ZIP_EMPTY_MESSAGE) : ''
            }
            onChange={(e) => onChangeAddMemberZip(e.target.value)}
            required
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            label="Phone"
            variant="outlined"
            className="text-box"
            value={phone}
            inputProps={{
              maxLength: 16,
            }}
            type="phone"
            onChange={(e) => onChangeAddMemberPhone(e.target.value)}
            disabled={isLoading}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            label="Mobile"
            variant="outlined"
            className="text-box"
            type="phone"
            inputProps={{
              maxLength: 16,
            }}
            value={mobile}
            onChange={(e) => onChangeAddMemberMobile(e.target.value)}
            disabled={isLoading}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <CustomDatePicker
            label={'Joining date'}
            maxDateLimit={moment().toDate()}
            value={joiningDate}
            onChange={(date) => onChangeAddMemberJoinDate(date)}
          />
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={4}>
          <div className="checkbox-handDeliver">
            <FormControlLabel
              className={
                canHandDeliver
                  ? 'check-deliver-box highlight'
                  : 'check-deliver-box'
              }
              control={
                <Checkbox
                  color="primary"
                  checked={canHandDeliver}
                  onChange={(e) => onChangeAddMemberCanHandDeliver(e.target.checked)}
                  name="handDeliver"
                />
              }
              label="Can Hand Deliver"
            />
          </div>
        </Grid>
      </Grid>  
      <Grid className="button-container">
        <Button
          className="button-cancel"
          onClick={handleClickCancel}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          className={disableFlag ? 'button-save button-disable' : 'button-save'}
          onClick={() =>
            isEdit ? onEditMember(selectedImg) : onAddMember(isLocationMode)
          }
          disabled={disableFlag}
        >
          {isAddMode ? 'Save' : 'Update'}
        </Button>
      </Grid>
    </div>
  );
};
