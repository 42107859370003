import { withStyles, DialogTitle, Typography, IconButton, DialogActions, DialogContent } from "@material-ui/core";

export const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

export const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

export const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);
