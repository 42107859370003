import { FC, useState } from 'react';
import {AssignMemberFilter} from "./AssignMemberFilter";
import {AssignMemberTable} from "./AssignMemberTable";
import {getPaginatedData} from "../../../../../../utils";
import { TablePagination } from '@material-ui/core';

const gridHeader = [
    {
        label: '',
        value: '',
        sortKey: '',
        xs: 12,
        lg: 1
    },
    {
        label: 'Member Name',
        value: 'name',
        sortKey: 'name',
        xs: 12,
        lg: 4
    },
    {
        label: 'Mobile',
        value: 'mobile',
        sortKey: 'mobile',
        xs: 12,
        lg: 3
    },
    {
        label: 'Location',
        value: 'street',
        xs: 12,
        lg: 4
    }
];

export const AssignNewMemberModal: FC<any> = ({
    assignedVendorUsers,
    assignmembers,
    unassignmembers, 
    onChangeVendorMemberUser, 
    selectedVendorUser,
    updateMemberCustomerID,
    onSelecteAssignMember,
    vendorMemberAssignModal: {
        vendorUserId,
        vendorUserError,
        isEditModal,
        selectedMemberData,
        selectedMemberId,
        customerID,
        customerIDError,
        activeDistributionCenter,
        customerAbbreviation,
        customerAbbreviationError
    },
    updateMemberCustomerAbbr
}) => {

    const [searchText, setSearchText] = useState('');
    const [sortKey, updateSortKey] = useState<string | null>(null);
    const [sortType, updateSortType] = useState('ASC');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const setSortKey = (newSortKey: string) => {
        if (sortKey === newSortKey) {
            if (sortType === 'DESC') {
                updateSortType('ASC')
            }
            else {
                updateSortType('DESC')
            }
        }
        else {
            updateSortKey(newSortKey);
            updateSortType('ASC')
        }
    }

    const activeData = isEditModal ? [selectedMemberData] : unassignmembers;

    const filteredData = (searchText && activeData.length > 0) ? (
        activeData.filter((lineItem: any) => {
            return Object.keys(lineItem).some((key) => {
                const searchItem = lineItem[key] && lineItem[key].toString();
                return searchItem && searchItem.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
            })
        })) : activeData;

    const sortedData = (sortKey && sortType && filteredData.length > 0) ? (filteredData && filteredData.sort((a: any, b: any) => {
        if (sortType === 'DESC') {
            return b[sortKey].localeCompare(a[sortKey]);
        }
        return a[sortKey].localeCompare(b[sortKey]);
    })) : filteredData;

    const modifiedList = getPaginatedData(sortedData, rowsPerPage, page);

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        setRowsPerPage(parseInt((event as any).target.value, 10));
        setPage(0);
    };

    return (
        <div className="table-wrapper">
            <AssignMemberFilter
                searchText={searchText}
                setSearchText={setSearchText}
                assignedVendorUsers={assignedVendorUsers}
                vendorUserId={vendorUserId}
                vendorUserError={vendorUserError}
                customerID={customerID}
                customerIDError={customerIDError}
                onChangeVendorMemberUser={onChangeVendorMemberUser}
                updateMemberCustomerID={updateMemberCustomerID}
                isEditModal={isEditModal}
                selectedMemberData={selectedMemberData}
                activeDistributionCenter={activeDistributionCenter}
                customerAbbreviation={customerAbbreviation}
                updateMemberCustomerAbbr={updateMemberCustomerAbbr}
                customerAbbreviationError={customerAbbreviationError}
            />

            <AssignMemberTable
                gridHeader={gridHeader}
                gridData={modifiedList ? modifiedList : []}
                onSelecteAssignMember={onSelecteAssignMember}
                selectedMemberId={selectedMemberId}
                isEditModal={isEditModal}
                setSortKey={setSortKey}
                sortType={sortType}
            />
            {filteredData.length > 0 && (
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={filteredData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
        </div>
    );
};
