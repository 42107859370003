import {CREATE_COMMUNITY_CATEGORY_API, CreateChannelError} from '../../../constants';

export const createChannel = async ({
    channelName,
    channelDesc
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(CREATE_COMMUNITY_CATEGORY_API, {
            method: 'POST',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                name: channelName,
                description: channelDesc
            })
        });
        if (!response.ok) throw CreateChannelError;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw CreateChannelError;
    }
};