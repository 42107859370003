import { assign } from '@xstate/immer';
import { DateTime } from 'luxon';
import { createMachine, send } from 'xstate';
import { forwardTo } from 'xstate/lib/actions';
import { OpportunityQuarters, OpportunityQuarter } from '../../../../../common';
import { paginationMachine } from '../../../../../machines';
import serverApiV2Instance from '../../../../../services/serverApiV2Instance';
import opportunitiesEditMachine from '../opportunitiesEditMachine';
import opportunitiesExportMachine from '../opportunitiesExportMachine';
import opportunitiesPublishMachine from '../opportunitiesPublishMachine';
import opportunitiesUploadMachine from '../opportunitiesUploadMachine';
import {
  OpportunitiesQuartersEvent,
  OpportunitiesQuartersContext,
} from './opportunityQuartersMachineTypes';

export const opportunityQuartersMachine = createMachine(
  {
    id: 'opportunityQuarters',
    tsTypes: {} as import('./opportunityQuartersMachine.typegen').Typegen0,
    schema: {
      context: {} as OpportunitiesQuartersContext,
      events: {} as OpportunitiesQuartersEvent,
      services: {} as {
        loadOpportunityQuarters: {
          data: {
            records: OpportunityQuarters;
          };
        };
      },
    },
    context: {
      selectedOpportunityQuarterId: null,
      pollingPeriod: 6000,
      opportunityQuarters: [],
    },
    initial: 'loading',
    states: {
      loading: {
        tags: ['loading'],
        invoke: {
          id: 'Load opportunity quarter data',
          src: 'loadOpportunityQuarters',
          onDone: {
            actions: 'assign opportunity quarters',
            description: 'Load successful, set context values.',
            target: 'displaying',
          },
        },
      },
      displaying: {
        on: {
          'update selectedOpportunityQuarterId': {
            actions: [
              'assign selected opportunity id',
              'send addQueryStringValues',
            ],
            cond: 'requested opportunity id is valid',
          },
          addQueryStringValues: {
            actions: 'forward event to opportunity list service',
          },
        },
        initial: 'modalClosed',
        states: {
          modalClosed: {
            on: {
              'open create upload modal': {
                cond: 'can create upload',
                target: 'modalOpened.createUpload',
              },
              'open edit upload modal': {
                cond: 'can edit or publish upload',
                target: 'modalOpened.editUpload',
              },
              'open export upload modal': {
                cond: 'can export upload',
                target: 'modalOpened.exportUpload',
              },
              'open publish upload modal': {
                cond: 'can edit or publish upload',
                target: 'modalOpened.publishUpload',
              },
            },
            initial: 'shouldPeriodicallyLoad',
            states: {
              shouldPeriodicallyLoad: {
                always: [
                  {
                    cond: 'list contains an in progress opportunity quarter',
                    target: 'periodicallyLoadingListData',
                  },
                  { target: 'idle' },
                ],
              },
              periodicallyLoadingListData: {
                initial: 'waiting',
                states: {
                  waiting: {
                    after: {
                      'polling period time frame': {
                        target: 'loading',
                      },
                    },
                  },
                  loading: {
                    tags: ['soft loading'],
                    invoke: {
                      id: 'Load opportunity quarter data periodically',
                      src: 'loadOpportunityQuarters',
                      onDone: [
                        {
                          actions:
                            'assign opportunity quarters after load period',
                          cond: 'list from event contains an in progress opportunity quarter',
                          description: 'Load successful, set context values.',
                          target: 'waiting',
                        },
                        {
                          actions:
                            [ 'assign opportunity quarters after load period', 'send pagination refresh' ],
                          description: 'Load successful, set context values.',
                          target:
                            '#opportunityQuarters.displaying.modalClosed.shouldPeriodicallyLoad',
                        },
                      ],
                    },
                  },
                },
              },
              idle: {},
            },
          },
          modalOpened: {
            tags: ['modal is open'],
            initial: 'createUpload',
            on: {
              'close modal': 'modalClosed',
            },
            states: {
              createUpload: {
                tags: ['create upload'],
                invoke: {
                  id: 'Create opportunities upload',
                  data: (context) => ({
                    quarter: 1,
                    opportunitiesDocumentFile: null,
                    opportunityQuarters: context.opportunityQuarters,
                    year: DateTime.now().year,
                  }),
                  src: 'opportunitiesUploadModal',
                  onDone: {
                    target: '#opportunityQuarters.loading',
                  },
                },
              },
              publishUpload: {
                tags: ['publish upload'],
                invoke: {
                  id: 'Publish opportunities upload',
                  data: (context) => ({
                    selectedOpportunityQuarter:
                      context.opportunityQuarters.filter(
                        (opportunityQuarter: OpportunityQuarter) =>
                          opportunityQuarter.id ===
                          context.selectedOpportunityQuarterId,
                      )[0],
                  }),
                  src: 'opportunitiesPublishModal',
                  onDone: {
                    target: '#opportunityQuarters.loading',
                  },
                },
              },
              editUpload: {
                tags: ['edit upload'],
                invoke: {
                  id: 'Edit opportunities upload',
                  data: (context) => ({
                    selectedOpportunityQuarter:
                      context.opportunityQuarters.filter(
                        (opportunityQuarter: OpportunityQuarter) =>
                          opportunityQuarter.id ===
                          context.selectedOpportunityQuarterId,
                      )[0],
                    opportunitiesDocumentFile: null,
                  }),
                  src: 'opportunitiesEditModal',
                  onDone: {
                    target: '#opportunityQuarters.loading',
                  },
                },
              },
              exportUpload: {
                tags: ['export upload'],
                invoke: {
                  id: 'Export opportunities upload',
                  data: (context) => ({
                    selectedOpportunityQuarter:
                      context.opportunityQuarters.filter(
                        (opportunityQuarter: OpportunityQuarter) =>
                          opportunityQuarter.id ===
                          context.selectedOpportunityQuarterId,
                      )[0],
                  }),
                  src: 'opportunitiesExportModal',
                  onDone: {
                    target: '#opportunityQuarters.loading', // TODO hand off
                  },
                },
              },
            },
          },
        },
        invoke: {
          id: 'Opportunity paginated list',
          src: 'opportunityList',
          data: (context) => {
            let selectedOpportunityQuarter: OpportunityQuarter | null = null;
            context.opportunityQuarters.forEach(
              (opportunityQuarter: OpportunityQuarter) => {
                if (
                  opportunityQuarter.id === context.selectedOpportunityQuarterId
                ) {
                  selectedOpportunityQuarter = opportunityQuarter;
                }
              },
            );
            return {
              ...paginationMachine.context,
              additionalQueryStringValues: {
                orderBy: 'productName',
                quarter: (
                  selectedOpportunityQuarter as unknown as OpportunityQuarter
                )?.quarter ?? '1',
                sortOrder: 'asc',
                year: (
                  selectedOpportunityQuarter as unknown as OpportunityQuarter
                )?.year ?? DateTime.now().year.toString(),
              },
            };
          },
        },
      },
    },
  },
  {
    actions: {
      'assign opportunity quarters': assign((context, event) => {
        context.opportunityQuarters = event.data.records;
        if (event.data.records.length > 0) {
          context.selectedOpportunityQuarterId = event.data.records[0].id;
        }
      }),
      'assign opportunity quarters after load period': assign(
        (context, event) => {
          context.opportunityQuarters = event.data.records;
          let selectedOpportunityQuarterId =
            context.selectedOpportunityQuarterId;
          let valid = false;
          event.data.records.forEach((record) => {
            if (record.id === selectedOpportunityQuarterId) {
              valid = true;
            }
          });
          if (!valid) {
            selectedOpportunityQuarterId = event.data.records?.[0].id ?? null;
          }
          if (
            selectedOpportunityQuarterId !==
            context.selectedOpportunityQuarterId
          )
            context.selectedOpportunityQuarterId = selectedOpportunityQuarterId;
        },
      ),
      'assign selected opportunity id': assign((context, event) => {
        context.selectedOpportunityQuarterId = event.opportunityQuarterId;
      }),
      'forward event to opportunity list service': forwardTo(
        'Opportunity paginated list',
      ),
      'send addQueryStringValues': send((context) => {
        let selectedOpportunityQuarter: OpportunityQuarter | null = null;
        context.opportunityQuarters.forEach(
          (opportunityQuarter: OpportunityQuarter) => {
            if (
              opportunityQuarter.id === context.selectedOpportunityQuarterId
            ) {
              selectedOpportunityQuarter = opportunityQuarter;
            }
          },
        );
        return {
          type: 'addQueryStringValues',
          valuePairs: [
            {
              destructive: true,
              key: 'quarter',
              value: (
                selectedOpportunityQuarter as unknown as OpportunityQuarter
              )?.quarter,
            },
            {
              destructive: true,
              key: 'year',
              value: (
                selectedOpportunityQuarter as unknown as OpportunityQuarter
              )?.year,
            },
          ],
        };
      }),
      'send pagination refresh': send('refresh', { to: 'Opportunity paginated list' }),
    },
    delays: {
      'polling period time frame': (context) => context.pollingPeriod,
    },
    guards: {
      'can create upload': (context) => {
        if (context.selectedOpportunityQuarterId === null) return true;
        const selectedOpportunityQuarter = context.opportunityQuarters.filter(
          (opportunityQuarter: OpportunityQuarter) =>
            opportunityQuarter.id === context.selectedOpportunityQuarterId,
        )?.[0];
        return selectedOpportunityQuarter.status === 'PUBLISHED';
      },
      'can edit or publish upload': (context) => {
        if (context.selectedOpportunityQuarterId === null) return false;
        const selectedOpportunityQuarter = context.opportunityQuarters.filter(
          (opportunityQuarter: OpportunityQuarter) =>
            opportunityQuarter.id === context.selectedOpportunityQuarterId,
        )?.[0];
        return selectedOpportunityQuarter.status === 'UPLOADED';
      },
      'can export upload': (context) => {
        if (context.selectedOpportunityQuarterId === null) return false;
        const selectedOpportunityQuarter = context.opportunityQuarters.filter(
          (opportunityQuarter: OpportunityQuarter) =>
            opportunityQuarter.id === context.selectedOpportunityQuarterId,
        )?.[0];
        let canExport : boolean = (selectedOpportunityQuarter.status === 'UPLOADED' ||
        selectedOpportunityQuarter.status === 'PUBLISHED');
        return canExport;
      },
      'list contains an in progress opportunity quarter': (context) =>
        context.opportunityQuarters.filter(
          (opportunityQuarter: OpportunityQuarter) =>
            opportunityQuarter.status === 'INPROGRESS' || opportunityQuarter.status === null,
        ).length > 0,
      'list from event contains an in progress opportunity quarter': (
        _,
        event,
      ) =>
        event.data.records.filter(
          (opportunityQuarter: OpportunityQuarter) =>
            opportunityQuarter.status === 'INPROGRESS' || opportunityQuarter.status === null,
        ).length > 0,
      'requested opportunity id is valid': (context, event) => {
        if (context.selectedOpportunityQuarterId === event.opportunityQuarterId)
          return false;
        let valid = false;
        context.opportunityQuarters.forEach(
          (opportunityQuarter: OpportunityQuarter) => {
            if (opportunityQuarter.id === event.opportunityQuarterId)
              valid = true;
          },
        );
        return valid;
      },
    },
    services: {
      loadOpportunityQuarters: () =>
        serverApiV2Instance
          .get('/opportunity-quarters')
          .then((response) => response.data),
      opportunityList: paginationMachine.withConfig({
        services: {
          recordLoader: ({
            additionalQueryStringValues,
            pageNumber,
            pageSize,
          }) => {
            const query = new URLSearchParams({
              ...additionalQueryStringValues,
              pageNumber: pageNumber.toString(),
              pageSize: pageSize.toString(),
            });
            return serverApiV2Instance.get(
              `/opportunities?${query.toString()}`,
            );
          },
        },
      }),
      opportunitiesUploadModal: opportunitiesUploadMachine,
      opportunitiesPublishModal: opportunitiesPublishMachine,
      opportunitiesEditModal: opportunitiesEditMachine,
      opportunitiesExportModal: opportunitiesExportMachine,
    },
  },
);

export type OpportunityQuartersMachine = typeof opportunityQuartersMachine;

export default opportunityQuartersMachine;
