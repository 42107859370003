import { FC } from 'react';
import { CustomModal } from '../../../../../components/CustomModal';
import { ExportMemberRebatesModal } from './ExportMemberRebatesModal';

export const ExportMemberRebates: FC<any> = ({
  openModal,
  handleExportQuarter,
  handleExportYear,
  exportQuarter,
  exportYear,
  onExportMemberRebates,
  rebatesModalSpinner,
  closeRebateExportModal,
  handleRequestRebateType,
  requestRebateType,
  quarterList,
}) => (
  <CustomModal
    showModal={openModal}
    closeModal={() => closeRebateExportModal(false)}
    modalTitle={'Export Member Rebate Documents'}
    isCancelButton={true}
    isSubmitButton={false}
    isExportButton={true}
    isSubmitText={'Export'}
    onSubmit={onExportMemberRebates}
    isLoading={rebatesModalSpinner}
  >
    <ExportMemberRebatesModal
      handleExportQuarter={handleExportQuarter}
      handleExportYear={handleExportYear}
      exportQuarter={exportQuarter}
      exportYear={exportYear}
      handleRequestRebateType={handleRequestRebateType}
      requestRebateType={requestRebateType}
      quarterList={
        quarterList?.filter(
          (item: { status: string }) => item.status === 'PUBLISHED',
        ) ?? []
      }
    />
  </CustomModal>
);

export default ExportMemberRebates;
