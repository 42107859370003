import {
  UPDATE_CONFIRM_MODAL,
  confirmRebateReceiptError,
  insufficientFundError,
} from '../../../constants';
import {
  rebateReceiptModalSpinner,
  getRebatesMemberList,
} from './rebateReceipts';
import { updateRebateActionModal } from './rebateAction';
import { confirmRebateReceipt } from '../../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const handleConfirmRebateReceipt =
  () => async (dispatch: any, getState: () => any) => {
    const { rebateReceipts } = getState();
    const { selectedRebate } = rebateReceipts;
    const { id: memberRebateReceiptItemId, memberrebatereceiptid } =
      selectedRebate;
    dispatch(rebateReceiptModalSpinner(true));
    try {
      const res = await confirmRebateReceipt(memberRebateReceiptItemId);
      const { error, message } = res?.data;
      if (message) {
        toast.success(message);
      } else {
        toast.error(message);
      }
      dispatch({
        type: UPDATE_CONFIRM_MODAL,
        isConfirmModalUpdate: true,
      });
      dispatch(getRebatesMemberList());
      dispatch(updateRebateActionModal(false));
      dispatch(rebateReceiptModalSpinner(false));
    } catch (error: any) {
      dispatch(updateRebateActionModal(false));
      dispatch(rebateReceiptModalSpinner(false));
      const errorMessage = JSON.parse(error?.response?.data?.error?.message)
      if (error?.response?.data?.error?.name === 'MissingFundingSource') {
        toast.error(error?.response?.data?.error?.message);
      }else if(errorMessage._embedded.errors[0].code === 'InsufficientFunds'){
        toast.error(insufficientFundError)
      }
      else {
        toast.error(confirmRebateReceiptError);
      }
    }
  };