import { FC } from 'react';
import { withStyles } from '@material-ui/core';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import { Loader } from '../../../utils';

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose && (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      )}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const NewDiscussionModal: FC<any> = ({
  openModal,
  handleClose,
  activeChannelName,
  handleDiscussionTitle,
  handleDiscussionDesc,
  createNewDiscussion,
  communityDiscussion: {
    discussionTitle,
    discussionDesc,
    discussionTitleError,
    discussionDescError,
    isDiscussionFormUpdated,
    discussionFormSpinner,
  },
  isEditDiscussion,
  updateDiscussionDetails,
}) => {
  const disableFlag =
    !isDiscussionFormUpdated || discussionTitleError || discussionDescError;

  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openModal}
        className="dialog-box"
      >
        {discussionFormSpinner && (
          <div className="loader-wrapper">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        )}

        <StyledDialogTitle onClose={handleClose}>
          Create New Discussion
        </StyledDialogTitle>

        <StyledDialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Channel Name"
                variant="outlined"
                className="text-box freeze-text-box"
                required
                value={activeChannelName}
              />
              <div className="channel-name-info">
                <span>
                  <i className="fas fa-exclamation-circle" />
                </span>
                <span>
                  Its default to the Channel you are currently in, If you want
                  to create it under different channel, navigate to the
                  appropriate channel first.
                </span>
              </div>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Discussion Title"
                placeholder="Discussion Title"
                variant="outlined"
                className="text-box"
                onChange={(e) => handleDiscussionTitle(e.target.value)}
                id={discussionTitleError ? 'standard-error' : 'outlined-basic'}
                error={discussionTitleError}
                helperText={
                  discussionTitleError ? 'Please enter valid title' : ''
                }
                defaultValue={discussionTitle}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Description"
                placeholder="Discussion Description"
                variant="outlined"
                className="text-box"
                onChange={(e) => handleDiscussionDesc(e.target.value)}
                inputProps={{ maxLength: 2000 }}
                defaultValue={discussionDesc}
                id={discussionDescError ? 'standard-error' : 'outlined-basic'}
                error={discussionDescError}
                helperText={
                  discussionDescError ? 'Please enter valid description' : ''
                }
                required
                multiline
                minRows={3}
                maxRows={4}
              />
            </Grid>
          </Grid>
        </StyledDialogContent>

        <StyledDialogActions>
          <div className="modal-buttons-container">
            <div className="action-button">
              <button className="cancel-user-button" onClick={handleClose}>
                Cancel
              </button>
              <Button
                onClick={
                  isEditDiscussion
                    ? updateDiscussionDetails
                    : createNewDiscussion
                }
                className={
                  disableFlag
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={disableFlag}
              >
                {isEditDiscussion ? 'Update' : 'Create'}
              </Button>
            </div>
          </div>
        </StyledDialogActions>
      </Dialog>
    </div>
  );
};

export default NewDiscussionModal;
