import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'redux';
import { UPDATE_PROFILE_ROUTE } from '../../constants';

export const updateProfileRoute: (
  activeMenu: {
    route: string;
    label: string;
  },
  navigate: NavigateFunction,
) => (dispatch: Dispatch) => Promise<void> =
  (activeMenu, navigate) => async (dispatch: Dispatch) => {
    const { route, label } = activeMenu;
    dispatch({
      type: UPDATE_PROFILE_ROUTE,
      profileRoute: route,
    });
    if (label !== 'Logout') {
      navigate(route);
    }
  };
