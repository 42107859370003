import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import {
  CLEAR_MEMBER_USER_FORM,
  UPDATE_MEMBER_USER_FORM_SPINNER,
  UPDATE_MEMBER_USER_FIRST_NAME,
  UPDATE_MEMBER_USER_LAST_NAME,
  UPDATE_MEMBER_USER_EMAIL,
  UPDATE_MEMBER_USER_ROLE,
  UPDATE_MEMBER_USER_FIRST_NAME_ERROR,
  UPDATE_MEMBER_USER_LAST_NAME_ERROR,
  UPDATE_MEMBER_USER_EMAIL_ERROR,
  UPDATE_MEMBER_USER_ROLE_ERROR,
  MemberUserCreateSuccess,
  MemberUserCreateError,
  UPDATE_MEMBER_USER_CREATE_ERROR,
  UPDATE_MEMBER_USER_LIST,
} from "../../constants";

import {showModal, closeModal} from "../modal";

import {EMAIL_REGEXP} from "../../utils/constants";
import {createMemberUser, getMemberUserList} from "../../api/members";

export const showAssignMemberUserModal = () => async (dispatch) => {
  dispatch(showModal());
};
export const hideAssignMemberUserModal = () => async (dispatch) => {
  dispatch(closeModal());
};
export const showAddMemberUserModal = () => async (dispatch) => {
  dispatch(showModal(true));
};
export const hideMemberUserModal = () => async (dispatch) => {
  dispatch(closeModal());
};

export const clearAddMemberUser = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MEMBER_USER_FORM,
  });
};
export const onChangeAddMemberUserFname = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_USER_FIRST_NAME,
    value: value,
  });
  dispatch({
    type: UPDATE_MEMBER_USER_FIRST_NAME_ERROR,
    flag: !isValidText(value),
  });
};
export const onChangeAddMemberUserLname = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_USER_LAST_NAME,
    value: value,
  });
  dispatch({
    type: UPDATE_MEMBER_USER_LAST_NAME_ERROR,
    flag: !isValidText(value),
  });
};
export const onChangeAddMemberUserMail = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_USER_EMAIL,
    value: value,
  });
  dispatch({
    type: UPDATE_MEMBER_USER_EMAIL_ERROR,
    flag: !EMAIL_REGEXP.test(value),
  });
};

export const onChangeAddMemberUserRole = (value) => async (dispatch) => {
  const formatedType = value != "NA" ? parseInt(value) : "0";
  dispatch({
    type: UPDATE_MEMBER_USER_ROLE,
    value: formatedType,
  });
  dispatch({
    type: UPDATE_MEMBER_USER_ROLE_ERROR,
    flag: !isValidateSelectionType(formatedType),
  });
};

export const onAddMemberUser = () => async (dispatch, getState) => {
  const {memberUserForm, members} = getState();
  const {type, selectedMember} = members || {};
  const {id} = selectedMember;
  const {typeid, userId, firstName, lastName, email, role} = memberUserForm || {};

  const isValidFirstName = isValidText(firstName);
  const isValidLastName = isValidText(lastName);
  const isValidEmail = isValidText(email);
  const isvalidRole = isValidateSelectionType(role);

  const isError =
    !isValidFirstName || !isValidLastName || !isValidEmail || !isvalidRole;

  if (!isValidFirstName) {
    dispatch({
      type: UPDATE_MEMBER_USER_FIRST_NAME_ERROR,
      flag: true,
    });
  }
  if (!isValidLastName) {
    dispatch({
      type: UPDATE_MEMBER_USER_LAST_NAME_ERROR,
      flag: true,
    });
  }
  if (!isValidEmail) {
    dispatch({
      type: UPDATE_MEMBER_USER_EMAIL_ERROR,
      flag: true,
    });
  }
  if (!isvalidRole) {
    dispatch({
      type: UPDATE_MEMBER_USER_ROLE_ERROR,
      flag: true,
    });
  }
  try {
    if (!isError) {
      dispatch({
        type: UPDATE_MEMBER_USER_FORM_SPINNER,
        spinner: true,
      });
      const response = await createMemberUser({
        id,
        typeid,
        userId,
        firstName,
        lastName,
        email,
        role,
      });
      const {success, message, data} = response;
      success ? toast.success(MemberUserCreateSuccess) : null;
      if (success) {
        const usersList = await getMemberUserList(id, type);
        dispatch({
          type: UPDATE_MEMBER_USER_LIST,
          list: usersList.data,
        });

        dispatch(closeModal());
      }
      dispatch({
        type: UPDATE_MEMBER_USER_FORM_SPINNER,
        spinner: false,
      });
      if (!success) {
        toast.error(message);
      }
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_USER_FORM_SPINNER,
      spinner: false,
    });
    if (error == UPDATE_MEMBER_USER_CREATE_ERROR) {
      toast.error(MemberUserCreateError);
    }
  }
};

const isValidateSelectionType = (selection) => {
  if (selection == "0" || selection == null || selection == "") return false;
  else {
    return true;
  }
};

const isValidText = (value) => {
  return !(value?.trim() == "");
};
