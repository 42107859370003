import {
  UPDATE_VENDOR_DC_LIST,
  UPDATE_VENDOR_SALES_LIST,
  UPDATE_VENDOR_USERS_LIST,
  UPDATE_VENDOR_MEMBERS_LIST,
  UPDATE_VENDOR_LINKS_LIST,
  UPDATE_VENDOR_KPI_LIST,
  UPDATE_VENDOR_KPI_TYPES_LIST,
  UPDATE_SELECTED_VENDOR_USER,
  UPDATE_DELETE_VENDOR_FLAG,
  UPDATE_SELECTED_VENDOR_MEMBER,
  UPDATE_SELECTED_VENDOR_LINK,
  UPDATE_SELECTED_VENDOR_KPI,
  UPDATE_SELECTED_VENDOR_DC,
  CLEAR_VENDOR_DC_LIST,
  CLEAR_VENDOR_SALES_LIST,
  CLEAR_VENDOR_USERS_LIST,
  CLEAR_VENDOR_MEMBERS_LIST,
  CLEAR_VENDOR_LINKS_LIST,
  CLEAR_VENDOR_KPI_LIST,
  UPDATE_VENDOR_USERS_SPINNER,
  UPDATE_VENDOR_DC_LIST_SPINNER,
  UPDATE_VENDOR_DC_ACTION_SPINNER,
  UPDATE_VENDOR_LINKS_SPINNER,
  UPDATE_VENDOR_MEMBERS_SPINNER,
  GET_VENDOR_ID,
} from "../../constants";

const initialState = {
  vendorID: 1,
  type: 1,
  vendorDCList: [],
  vendorSalesList: [],
  vendorUsersList: [],
  vendorMembersList: [],
  vendorLinksList: [],
  vendorKpiList: [],
  vendorKpiTypesList: [],
  selectedUser: null,
  slectedUserID: null,
  slectedMemberID: null,
  slectedLinkID: null,
  slectedkpiID: null,
  selectedDcID: null,
  isDeleteUser: false,
  isEdit: false,
  vendorUserListSpinner: false,
  vendorDcListSpinner: false,
  vendorLinkListSpinner: false,
  vendorMemberListSpinner: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_VENDOR_DC_LIST:
      return {
        ...state,
        vendorDCList: action.vendorDCList,
      };
    case UPDATE_VENDOR_SALES_LIST:
      return {
        ...state,
        vendorSalesList: action.vendorSalesList,
      };
    case UPDATE_VENDOR_USERS_LIST:
      return {
        ...state,
        vendorUsersList: action.vendorUsersList,
      };
    case UPDATE_VENDOR_MEMBERS_LIST:
      return {
        ...state,
        vendorMembersList: action.vendorMembersList,
      };
    case UPDATE_VENDOR_LINKS_LIST:
      return {
        ...state,
        vendorLinksList: action.vendorLinksList,
      };
    case UPDATE_VENDOR_KPI_LIST:
      return {
        ...state,
        vendorKpiList: action.vendorKpiList,
      };
    case CLEAR_VENDOR_DC_LIST:
      return {
        ...state,
        vendorDCList: [],
      };
    case CLEAR_VENDOR_SALES_LIST:
      return {
        ...state,
        vendorSalesList: [],
      };
    case CLEAR_VENDOR_USERS_LIST:
      return {
        ...state,
        vendorUsersList: [],
      };
    case CLEAR_VENDOR_MEMBERS_LIST:
      return {
        ...state,
        vendorMembersList: [],
      };
    case CLEAR_VENDOR_LINKS_LIST:
      return {
        ...state,
        vendorLinksList: [],
      };
    case CLEAR_VENDOR_KPI_LIST:
      return {
        ...state,
        vendorKpiList: [],
      };
    case UPDATE_SELECTED_VENDOR_USER:
      return {
        ...state,
        selectedUser: action.selectedUser,
      };
    case UPDATE_DELETE_VENDOR_FLAG:
      return {
        ...state,
        isDeleteUser: action.isDeleteUser,
      };
    case UPDATE_SELECTED_VENDOR_MEMBER:
      return {
        ...state,
        slectedMemberID: action.slectedMemberID,
      };
    case UPDATE_SELECTED_VENDOR_LINK:
      return {
        ...state,
        slectedLinkID: action.slectedLinkID,
      };
    case UPDATE_SELECTED_VENDOR_KPI:
      return {
        ...state,
        slectedkpiID: action.slectedkpiID,
      };
    case UPDATE_SELECTED_VENDOR_DC:
      return {
        ...state,
        selectedDcID: action.selectedDcID,
      };
    case UPDATE_VENDOR_KPI_TYPES_LIST:
      return {
        ...state,
        vendorKpiTypesList: action.vendorKpiTypesList,
      };
    case UPDATE_VENDOR_USERS_SPINNER:
      return {
        ...state,
        vendorUserListSpinner: action.spinner,
      };
    case UPDATE_VENDOR_DC_LIST_SPINNER:
      return {
        ...state,
        vendorDcListSpinner: action.vendorDcListSpinner,
      };
    case UPDATE_VENDOR_LINKS_SPINNER:
      return {
        ...state,
        vendorLinkListSpinner: action.spinner,
      };
    case UPDATE_VENDOR_MEMBERS_SPINNER:
      return {
        ...state,
        vendorMemberListSpinner: action.spinner,
      };
    case GET_VENDOR_ID:
      return {
        ...state,
        vendorID: action.vendorId,
      };
    default:
      return state;
  }
};
