import {DISCUSSION_API, GET_MORE_COMMENTS_ERROR} from "../../../constants";


export const getMoreComments = async ({
    activeDiscussionID,
    pageNumber
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${activeDiscussionID}/comments?pageSize=5&page=${pageNumber}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_MORE_COMMENTS_ERROR;
    }
}

