import { Navigate } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import InputBase from '@material-ui/core/InputBase';
import AuthorizationService from '../../../../../authorization/AuthorizationService';
import { FC, useState } from 'react';

export const LocationActions: FC<{
  memberDetail: any;
  rows: any;
  searchText: string;
  setSearchText: (searchText: string) => void;
}> = ({ memberDetail, rows, searchText, setSearchText }) => {
  const [nav, setNav] = useState('none');

  if (nav === 'add') {
    return (
      <Navigate
        to="/members/members-add"
        state={{
          navigateFrom: 'VIEW',
          navigateTab: 'LOCATIONS',
          pageType: '',
          parentID: memberDetail ? memberDetail.id : null,
        }}
      />
    );
  }

  return (
    <Grid container direction="row" justify="flex-end" alignItems="center">
      {rows.length > 0 ? (
        <Grid>
          <div className="page-search-box">
            <i className="fas fa-search"></i>
            <InputBase
              placeholder="Search"
              value={searchText.toString()}
              onChange={(event) => setSearchText(event.target.value)}
            />
          </div>
        </Grid>
      ) : null}
      {AuthorizationService.isAuthorizedHTML('memberAssginLocationButton') && (
        <Grid>
          <Button
            className="btn-add ml-10"
            component="label"
            onClick={() => setNav('add')}
          >
            <span className="fas fa-plus-circle"></span>
            <span>Assign New Location</span>
          </Button>
        </Grid>
      )}
    </Grid>
  );
};
