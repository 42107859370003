import { Grid, GridSize } from '@mui/material';
import { FC } from 'react';
import { ResponsiveContainer, PieChart, Pie } from 'recharts';
import { centsToFormattedDollars } from '../../utils';

export const RebatesPieChart: FC<{
  claimedAmount: number;
  gridSize?: GridSize;
  legend?: string;
  pendingAmount: number;
  unclaimedAmount: number;
}> = ({
  claimedAmount,
  gridSize = 7,
  legend = 'Total Member Rebates',
  pendingAmount,
  unclaimedAmount,
}) => (
  <Grid item xs={12} lg={gridSize}>
    <Grid item container className="overview-chart-label">
      {claimedAmount > 0 && (
        <Grid item className="overview-chart-label-content">
          <span className="overview-chart-label-indicator overview-chart-label-indicator-green" />
          <span className="overview-chart-label-text">Claimed rebates</span>
        </Grid>
      )}
      {pendingAmount > 0 && (
        <Grid item className="overview-chart-label-content">
          <span className="overview-chart-label-indicator overview-chart-label-indicator-yellow" />
          <span className="overview-chart-label-text">Pending rebates</span>
        </Grid>
      )}
      {unclaimedAmount > 0 && (
        <Grid item className="overview-chart-label-content">
          <span className="overview-chart-label-indicator overview-chart-label-indicator-red" />
          <span className="overview-chart-label-text">Unclaimed rebates</span>
        </Grid>
      )}
    </Grid>
    <div className="overview-chart-pie-chart-wrapper">
      <ResponsiveContainer className="recharts-responsive-container" minHeight={210}>
        <PieChart className="mini-pie-chart-box" style={{ margin: '0 auto' }}>
          <Pie
            key={`${claimedAmount} ${unclaimedAmount} ${pendingAmount}`}
            data={[
              {
                name: 'Claimed',
                value: claimedAmount,
                key: 'claimed',
                fill: '#27AE60',
              },
              {
                name: 'Pending',
                value: pendingAmount,
                key: 'pending',
                fill: '#FF9900',
              },
              {
                name: 'Unclaimed',
                value: unclaimedAmount,
                key: 'unclaimed',
                fill: '#e15759',
              },
            ]}
            cx="50%"
            cy="50%"
            innerRadius="75%"
            outerRadius="100%"
            fill="#339933"
            dataKey="value"
            className="mini-pie-chart-box-chart"
            labelLine
            minAngle={5}
            startAngle={-270}
            isAnimationActive
          />
        </PieChart>
      </ResponsiveContainer>
      <div className="rebates-progress-legend-wrapper">
        <div className="rebates-total">
          {centsToFormattedDollars(
            claimedAmount + unclaimedAmount + pendingAmount,
          )}
        </div>
        <div className="rebates-legend">{legend}</div>
      </div>
    </div>
  </Grid>
);

export default RebatesPieChart;
