import {connect} from "react-redux";
import {
  getMembers,
  onChangeMemberView,
} from "../../actions/members";
import {
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage
} from "../../actions/pagination"

import {MembersMain} from "./MembersMain";

const mapStateToProps = (state: any) => {
  const {common, members, paginationReducer, user} = state;
  return {
    common,
    members,
    paginationReducer,
    user
  };
};

export const Members = connect(mapStateToProps, {
  getMembers: getMembers,
  onChangeMemberView,
  handleChangeRowsPerPage,
  handlePageType,
  handleChangePage
})(MembersMain);
