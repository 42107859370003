import {
    UPDATE_PROFILE_ROUTE
} from "../../constants";

const initialState = {
    profileRoute: '/profile/messages'
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case UPDATE_PROFILE_ROUTE:
            return {
                ...state,
                profileRoute: action.profileRoute,
            };
        default:
            return state;
    }
};
