import { Button, Fade, Grid, LinearProgress, Tooltip, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@mui/material/styles';
import { useSelector } from '@xstate/react';
import { FC, useState, useEffect } from 'react';
import GaugeChart from 'react-gauge-chart';
import { ActorRef } from 'xstate';
import useSelectedAgreementValues from './useSelectedAgreementValues';
import { percentage } from '../../../utils/percentage';
import AuthorizationService from '../../../authorization/AuthorizationService';
import SavingsForm from '../../../modules/savingspercentage/components/SavingsForm';
import { useDispatch } from 'react-redux';
import { savingsPercentage } from '../../../actions/dashboard';

export const StandardDropSizeChart: FC<{
  dropSizeAgreementsActor: ActorRef<any, any>; // TODO Temporary fix until correct format is discoverd or bug is resolved,
  id?: string;
  savings : number;
}> = ({ dropSizeAgreementsActor, id,savings }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const loading = useSelector(dropSizeAgreementsActor, (state) =>
    state.hasTag('loading'),
  );

  const renderTimeFrameMessage = useSelector(
    dropSizeAgreementsActor,
    (state) => !state.context.selectedIntervalType,
  );

  const dispatch:any = useDispatch()

  const [
    gaugeChartProps,
    averageDropSize,
    lowRange,
    highRange,
    validGraph,
    avgFontClass,
    details,
  ] = useSelectedAgreementValues(dropSizeAgreementsActor, id);

  const [show,setIsShow] = useState(false)

  const handleShow= () =>{
    setIsShow(false)
  }

  useEffect(() => {
    console.log('Success!!')
    dispatch(savingsPercentage())
  },[show])

  return (
    <>
      <Grid container spacing={3}>
        {!matches && (
          <Grid
            xs={12}
            md={3}
            alignItems="center"
            justifyContent="flex-start"
            item
            container
            alignContent="center"
          >
            <div className="pie-header">
              <h3 className="pie-title">Drop Size</h3>
              {validGraph && (
                <div
                  className="pie-sub-title"
                  style={{
                    color: "rgb(130, 129, 129)",
                    fontSize: "14px",
                    padding: "5px 0 5px 0",
                  }}
                >
                  {"Target Range: "}
                  {Number(lowRange).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                  {lowRange < highRange ? (
                    <>
                      {" - "}
                      {Number(highRange).toLocaleString("en-US", {
                        style: "currency",
                        currency: "USD",
                      })}
                    </>
                  ) : (
                    " and above"
                  )}
                </div>
              )}
            </div>
            {validGraph && (
              <Grid className="legend-label" container spacing={2}>
                <Grid item sm={2} xs={12}>
                  <div className="legend-icon-below" />
                  <div className="legend-text-below">Below Target</div>
                </Grid>
                {lowRange < highRange ? (
                  <>
                    <Grid item sm={2} xs={12}>
                      <div className="legend-icon-within" />
                      <div className="legend-text-within">Within Target</div>
                    </Grid>
                    <Grid item sm={2} xs={12}>
                      <div className="legend-icon-above" />
                      <div className="legend-text-above">Above Target</div>
                    </Grid>
                  </>
                ) : (
                  <Grid item sm={2} xs={12}>
                    <div className="legend-icon-above" />
                    <div className="legend-text-above">Within Target</div>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>
        )}
        <Grid
          xs={12}
          md={3}
          alignItems="flex-start"
          justifyContent={matches ? "flex-start" : "center"}
          item
          container
          alignContent="flex-start"
        >
          <GaugeChart
            style={matches ? undefined : { maxWidth: "350px", width: "100%" }}
            {...gaugeChartProps}
          />
          {(matches || validGraph) && (
            <Fade in={typeof averageDropSize === "number" && validGraph}>
              <div className="progress-details">
                <div>
                  Average drop size:{" "}
                  <span className={avgFontClass || undefined}>
                    {averageDropSize.toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                  </span>
                </div>
                <div>
                  Estimated savings per drop:{" "}
                  <span className={avgFontClass || undefined}>
                    {percentage(averageDropSize, savings || 0)}{" "}
                    {AuthorizationService.isAuthorizedHTML(
                      "savingPercentage"
                    ) && (
                      <span>
                        {`(${savings || 0}%`}

                        <Tooltip title="Add Savings Percentage">

                            <i className="fas fa-pen saving-icon" onClick={() => setIsShow(true)}></i>

                        </Tooltip>

                        {`)`}
                      </span>
                    )}
                  </span>
                </div>
              </div>
            </Fade>
          )}
          <Fade in={loading}>
            <LinearProgress
              style={{ maxWidth: matches ? undefined : "300px", width: "100%" }}
            />
          </Fade>
        </Grid>
        <Grid
          xs={12}
          md={9}
          alignItems="flex-start"
          justifyContent="flex-start"
          item
          container
          alignContent="flex-start"
        >
          {matches && (
            <>
              <div className="pie-header">
                <h3 className="pie-title">Drop Size</h3>
                {validGraph && (
                  <div
                    className="pie-sub-title"
                    style={{
                      color: "rgb(130, 129, 129)",
                      fontSize: "14px",
                      padding: "5px 0 5px 0",
                    }}
                  >
                    {"Target Range: "}
                    {Number(lowRange).toLocaleString("en-US", {
                      style: "currency",
                      currency: "USD",
                    })}
                    {lowRange < highRange ? (
                      <>
                        {" - "}
                        {Number(highRange).toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })}
                      </>
                    ) : (
                      " and above"
                    )}
                  </div>
                )}
              </div>
              <Fade in={validGraph}>
                <Grid className="legend-label" container spacing={2}>
                  <Grid item sm={2} xs={12}>
                    <div className="legend-icon-below" />
                    <div className="legend-text-below">Below Target</div>
                  </Grid>
                  {lowRange < highRange ? (
                    <>
                      <Grid item sm={2} xs={12}>
                        <div className="legend-icon-within" />
                        <div className="legend-text-within">Within Target</div>
                      </Grid>
                      <Grid item sm={2} xs={12}>
                        <div className="legend-icon-above" />
                        <div className="legend-text-above">Above Target</div>
                      </Grid>
                    </>
                  ) : (
                    <Grid item sm={2} xs={12}>
                      <div className="legend-icon-above" />
                      <div className="legend-text-above">Within Target</div>
                    </Grid>
                  )}
                </Grid>
              </Fade>
            </>
          )}
          {validGraph && renderTimeFrameMessage && (
            <p>
              The drop size you are viewing is from today’s date to 6 months
              back.
            </p>
          )}
          <p>{details}</p>
        </Grid>
      </Grid>
      <SavingsForm show={show} handleShow={handleShow} />
    </>
  );
};

export default StandardDropSizeChart;
