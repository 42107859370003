import { connect } from 'react-redux';
import { EarnedRebatesContent } from './EarnedRebatesContent';
import {
  getMemberRebatesQuarter,
  updateMemberRebatesInfo,
  updateMemberRebatesInfoDetails,
} from '../../../../../../actions/rebates';

const mapStateToProps = (state: any) => {
  const {
    rebates,
    claimRebates,
    productRebates,
    memberRebates,
    paginationReducer,
  } = state;

  return {
    rebates,
    claimRebates,
    productRebates,
    memberRebates,
    paginationReducer,
  };
};

export const EarnedRebates = connect(mapStateToProps, {
  getMemberRebatesQuarter,
  updateMemberRebatesInfo,
  updateMemberRebatesInfoDetails,
})(EarnedRebatesContent);
