import {DOCUMENT_RECORDS_API, UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR} from "../../constants";


export const getSelectMemberList = async (memberID, publicat) => {
    const isAllMembers = publicat && publicat.length > 0 ? true : false;
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DOCUMENT_RECORDS_API}/${memberID}${isAllMembers ? "" : "?includeMembers=true"}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DOCUMENT_SELECTE_MEMBER_LIST_ERROR;
    }
}

