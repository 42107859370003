import {DELETE_VENDOR_MEMBER_API, DELETE_VENDOR_MEMBER_ERROR} from '../../constants';

export const deleteVendorMember = async ({
    vendorID,
    slectedMemberID
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DELETE_VENDOR_MEMBER_API}/vendor/${vendorID}/member/${slectedMemberID}`, {
            method: 'DELETE',
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
            }),
            body: JSON.stringify({
                memberid: slectedMemberID
            })
        });
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw DELETE_VENDOR_MEMBER_ERROR;
    }
};