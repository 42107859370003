import { assign } from '@xstate/immer';
import { AxiosResponse } from 'axios';
import { createMachine } from 'xstate';
import { Vendor, Vendors } from '../../common';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const vendorsMachine = createMachine(
  {
    id: 'vendors',
    tsTypes: {} as import('./vendorsMachine.typegen').Typegen0,
    schema: {
      context: {} as {
        vendors: Vendors;
        selectedVendor: null | Vendor;
      },
      events: {} as
        | { type: 'refresh' }
        | { type: 'updateSelectedMember'; memberId: number },
      services: {} as {
        loadVendors: {
          data: AxiosResponse<{
            data?: Vendors;
            message: string;
            success: boolean;
          }>;
        };
      },
    },
    context: { vendors: [], selectedVendor: null },
    initial: 'loading',
    states: {
      loading: {
        invoke: {
          src: 'loadVendors',
          id: 'loadVendors',
          onDone: [
            {
              actions: 'assignVendors',
              cond: 'loadVendorsSuccess',
              target: 'idle',
            },
          ],
          onError: [
            {
              target: 'error',
            },
          ],
        },
      },
      idle: {
        on: {
          refresh: {
            target: 'loading',
          },
          updateSelectedMember: {
            target: 'idle',
          },
        },
      },
      error: {},
    },
  },
  {
    actions: {
      assignVendors: assign((context, { data }) => {
        const vendors = data.data?.data;
        if (Array.isArray(vendors)) {
          context.vendors = vendors;
          if (vendors.length > 0 && !context.selectedVendor) {
            context.selectedVendor = vendors[0];
          }
        }
      }),
    },
    guards: {
      loadVendorsSuccess: (_, { data }) => data.data.success,
    },
    services: {
      loadVendors: () => serviceInterceptor.get('/vendors'),
    },
  },
);

export type VendorsMachine = typeof vendorsMachine;

export default vendorsMachine;
