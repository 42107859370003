import {
  UPDATE_MEMBER_REBATES_INFO,
  UPDATE_SELECTED_MEMBER_REBATES_INFO,
  GET_MEMBER_REBATES_INFO_ERROR,
  MemberRebateInfoError,
  FetchError,
} from '../../../constants';
import { memberRebatesListSpinner } from './memberRebates';
import { getMemberRebatesInfoAPI } from '../../../api/rebates';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const updateMemberRebatesInfo = () => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_REBATES_INFO,
    memberRebatesInfo: [],
  });
};

export const updateMemberRebatesInfoDetails = (rebateId, memberRebatesInfo) => async (dispatch) => {
  dispatch({
    type: UPDATE_SELECTED_MEMBER_REBATES_INFO,
    memberRebatesId: rebateId,
  });
  dispatch(memberRebatesListSpinner(true));
  dispatch({
    type: UPDATE_MEMBER_REBATES_INFO,
    memberRebatesInfo: memberRebatesInfo,
  });
  dispatch(memberRebatesListSpinner(false));
};

export const getMemberRebatesInfo =
  (rebateId) => async (dispatch, getState) => {
    dispatch({
      type: UPDATE_SELECTED_MEMBER_REBATES_INFO,
      memberRebatesId: rebateId,
    });
    dispatch(memberRebatesListSpinner(true));
    const { memberRebates } = getState();
    const { quarter, year } = memberRebates;
    try {
      const res = await getMemberRebatesInfoAPI({
        quarter,
        year,
        rebateId,
      });
      const { success, data } = res;
      const formattedData =
        data &&
        data.map((item) => {
          return {
            ...item,
            orderSize: item.orderSize.toString(),
            amountEarned: item.amountEarned.toString(),
          };
        });
      if (success) {
        dispatch({
          type: UPDATE_MEMBER_REBATES_INFO,
          memberRebatesInfo: formattedData,
        });
        dispatch(memberRebatesListSpinner(false));
      }
      dispatch(memberRebatesListSpinner(false));
    } catch (error) {
      console.error('get member rebates info error: ', error);
      dispatch(memberRebatesListSpinner(false));
      if (error === GET_MEMBER_REBATES_INFO_ERROR) {
        toast.error(MemberRebateInfoError);
      } else {
        toast.error(FetchError);
      }
    }
  };
