import { connect } from 'react-redux';
import { RebatesMain } from './RebatesMain';
import {
  updateActiveTab,
  getProductRebatesQuarter,
  handleProductRebateQuarter,
  showProductRebateModal,
  hideProductRebateModal,
  showMemberRebateModal,
  hideMemberRebateModal,
  onChangeProductRebatesQuarter,
  onChangeProductRebatesYear,
  onProductRebatesFileSelect,
  onProductRebatesFileRemove,
  clearProductRebatesModal,
  onUploadProductRebates,
  onEditProductRebase,
  onPublishProductRebates,
  showProductRebateExportModal,
  showClaimRebatesModal,
  showClaimRebateExportModal,
  updateExportDateRange,
  showNotesModal,
  showNotesEditModal,
  updateSelectedProduct,
  handleNotesChange,
  updateProductRebateNotes,
  closeRebateNotesModal,
  handleExportQuarter,
  handleExportYear,
  onExportProductRebates,
  closeRebateExportModal,
  handleRequestRebateType,
  handleMemberRebateQuarter,
  showMemberRebateExportModal,
  handleMemberExportQuarter,
  handleMemberExportYear,
  onExportMemberRebates,
  onExportMemberRebatesByQuarterYear,
  onChangeMemberRebatesQuarter,
  onChangeMemberRebatesYear,
  getRebateReceiptDetails,
  getMemberRebatesQuarter,
  handleRebateReceiptMemberChange,
  handleClaimRebatesCheck,
  onExportRebateReceipts,
  clearRebateReceiptExportModal,
  handleConfirmRebateReceipt,
} from '../../actions/rebates';

import {
  handleMemberRebatesFileOne,
  handleMemberRebatesFileTwo,
  handleMemberRebatesFileThree,
  updateActiveStep,
  clearMemberRebateModal,
  onMemberRebatesFileOneRemove,
  onMemberRebatesFileTwoRemove,
  onMemberRebatesFileThreeRemove,
} from '../../actions/rebates/memberRebates/fileUploadAction';
import { getRebatesOverview } from '../../actions/rebates/overView';
import { onUploadMemberRebates } from '../../actions/rebates/memberRebates/uploadMemberRebates';
import { onPublishMemberRebates } from '../../actions/rebates/memberRebates/publishMemberRebates';
import {
  onEditMemberRebase,
  handleMemberEditRebates,
  onUpdateMemberRebates,
} from '../../actions/rebates/memberRebates/updateMemberRebates';
import {
  getRebateMemberOptions,
  getRebateMemberProducts,
  updateMemberAdminQuarter,
  updateMemberAdminYear,
  updateMemberAdminRebatesSelectedMember,
  updateMemberAdminRebatesQuarter,
  updateMemberAdminRebatesYear,
  getEarnedRebateQuarters,
} from '../../actions/rebates/memberAdminRebates';
import {
  memberAdminRebatesExportModal,
  handleMemberAdminExportQuarter,
  handleMemberAdminExportYear,
  onExportMemberAdminRebates,
} from '../../actions/rebates/memberAdminRebates/exportRebates';
import {
  handleRebateReceiptExportQuarter,
  handleRebateReceiptExportYear,
  updateRebateAction,
  updateRebateActionModal,
  handleRebateReceiptMode,
} from '../../actions/rebates/rebateReceipts';

const mapStateToProps = (state: any) => {
  const {
    user,
    rebates,
    rebateReceipts,
    productRebates,
    memberRebates,
    memberAdminRebates,
    paginationReducer,
  } = state;

  return {
    user,
    rebates,
    rebateReceipts,
    productRebates,
    memberRebates,
    memberAdminRebates,
    paginationReducer,
  };
};

export const Rebates = connect(mapStateToProps, {
  updateActiveTab,
  getProductRebatesQuarter,
  handleProductRebateQuarter,
  showProductRebateModal,
  hideProductRebateModal,
  showMemberRebateModal,
  hideMemberRebateModal,
  onChangeProductRebatesQuarter,
  onChangeProductRebatesYear,
  onProductRebatesFileSelect,
  onProductRebatesFileRemove,
  clearProductRebatesModal,
  onUploadProductRebates,
  onEditProductRebase,
  onPublishProductRebates,
  showProductRebateExportModal,
  showClaimRebatesModal,
  showClaimRebateExportModal,
  updateExportDateRange,
  showNotesModal,
  showNotesEditModal,
  updateSelectedProduct,
  handleNotesChange,
  updateProductRebateNotes,
  closeRebateNotesModal,
  handleExportQuarter,
  handleExportYear,
  onExportProductRebates,
  closeRebateExportModal,
  handleRequestRebateType,
  handleMemberRebateQuarter,
  showMemberRebateExportModal,
  handleMemberExportQuarter,
  handleMemberExportYear,
  onExportMemberRebates,
  onExportMemberRebatesByQuarterYear,
  onChangeMemberRebatesQuarter,
  onChangeMemberRebatesYear,
  handleMemberRebatesFileOne,
  handleMemberRebatesFileTwo,
  handleMemberRebatesFileThree,
  updateActiveStep,
  clearMemberRebateModal,
  onMemberRebatesFileOneRemove,
  onMemberRebatesFileTwoRemove,
  onMemberRebatesFileThreeRemove,
  onUploadMemberRebates,
  onPublishMemberRebates,
  onEditMemberRebase,
  handleMemberEditRebates,
  onUpdateMemberRebates,
  getRebatesOverview,
  getRebateMemberOptions,
  getRebateMemberProducts,
  updateMemberAdminRebatesSelectedMember,
  updateMemberAdminRebatesQuarter,
  updateMemberAdminRebatesYear,
  getEarnedRebateQuarters,
  updateMemberAdminQuarter,
  updateMemberAdminYear,
  memberAdminRebatesExportModal,
  handleMemberAdminExportQuarter,
  handleMemberAdminExportYear,
  onExportMemberAdminRebates,
  getRebateReceiptDetails,
  getMemberRebatesQuarter,
  handleRebateReceiptMemberChange,
  handleClaimRebatesCheck,
  onExportRebateReceipts,
  handleRebateReceiptExportQuarter,
  handleRebateReceiptExportYear,
  clearRebateReceiptExportModal,
  updateRebateAction,
  updateRebateActionModal,
  handleRebateReceiptMode,
  handleConfirmRebateReceipt,
})(RebatesMain);
