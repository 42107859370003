import {combineReducers} from "redux";
import loginForm from "./loginForm";
import userReducer from "./user";
import commonReducer from './common';
import modalReducer from './modal';
import dashboardKPI from './dashboard/dashboardKPI';
import dashboardEarnedRebates from "./dashboard/dashboardEarnedRebates";
import dashboardActiveEarnings from './dashboard/dashboardActiveEarnings';
import dashboardOpportunities from './dashboard/dashboardOpportunities';
import memberListReducer from "./member/members";
import memberForm from "./member/memberForm";
import addMemberUser from "./member/addMemberUser";
import vendorReducer from './vendors/vendor';
import vendorLinkModalReducer from './vendors/vendorLinkModal';
import vendorMemberAssignReducer from "./vendors/vendorMemberAssign";
import vendorUserAssign from "./vendors/vendorUserAssign";
import vendorUserCreate from "./vendors/vendorUserCreate";
import vendorKPIModalReducer from "./vendors/vendorKPIModalReducer";
import vendorDCModalReducer from './vendors/vendorDCModal';
import documentReducer from './documents/document';
import manageAdsList from "./manageAds/list";
import adsCreate from "./manageAds/add";
import adsTypeCreate from "./manageAds/adsType";
import products from "./products/list";
import rebates from "./rebates/rebates";
import rebateReceipts from "./rebates/rebateReceipts";
import productRebates from "./rebates/productRebates";
import memberRebates from "./rebates/memberRebates";
import memberAdminRebates from "./rebates/memberAdminRebates";
import communityChannel from "./community/communityChannel";
import communityDiscussion from "./community/communityDiscussion";
import communityComments from "./community/communityComments";
import communityDiscussionActions from "./community/communityDiscussionActions";
import communityCommentActions from "./community/communityCommentActions";
import ProfileReducer from "./profile/profile";
import changePasswordReducer from "./profile/changePasswordReducer";
import paginationReducer from "./pagination";
import dashboardSavingPercentage from "./dashboard/dashboardSavingPercentage";

const rootReducer = combineReducers({
  login: loginForm,
  user: userReducer,
  common: commonReducer,
  modal: modalReducer,
  paginationReducer: paginationReducer,
  dashboardKPI: dashboardKPI,
  dashboardActiveEarnings: dashboardActiveEarnings,
  dashboardOpportunities: dashboardOpportunities,
  members: memberListReducer,
  memberForm: memberForm,
  memberUserForm: addMemberUser,
  vendor: vendorReducer,
  vendorDCModal: vendorDCModalReducer,
  vendorLinkModal: vendorLinkModalReducer,
  vendorMemberAssignModal: vendorMemberAssignReducer,
  vendorUserAssign: vendorUserAssign,
  vendorUserCreate: vendorUserCreate,
  vendorKPIModal: vendorKPIModalReducer,
  documents: documentReducer,
  manageAdsList: manageAdsList,
  adsCreate: adsCreate,
  adsTypeCreate: adsTypeCreate,
  products: products,
  rebates: rebates,
  rebateReceipts: rebateReceipts,
  productRebates: productRebates,
  memberRebates: memberRebates,
  memberAdminRebates: memberAdminRebates,
  communityChannel: communityChannel,
  communityDiscussion: communityDiscussion,
  communityComments: communityComments,
  communityDiscussionActions: communityDiscussionActions,
  communityCommentActions: communityCommentActions,
  profile: ProfileReducer,
  changePasswordForm: changePasswordReducer,
  dashboardEarnedRebates : dashboardEarnedRebates,
  dashboardSavingsPercentage: dashboardSavingPercentage
});

export default rootReducer;
