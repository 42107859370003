import {
    SHOW_COMMUNITY_DISCUSSION_SPINNER,
    HIDE_COMMUNITY_DISCUSSION_SPINNER,
    SHOW_DISCUSSION_DETAIL_SPINNER,
    HIDE_DISCUSSION_DETAIL_SPINNER,
    SHOW_LOAD_MORE_SPINNER,
    HIDE_LOAD_MORE_SPINNER,
    UPDATE_SELECTED_TAB,
    UPDATE_QUESTION_DETAIL,
    UPDATE_ACTIVE_DISCUSSION,
    UPDATE_ACTIVE_DISCUSSION_COMMENTS,
    UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER,
    CLEAR_COMMUNITY_COMMENT_STATE,
    UPDATE_ACTIVE_CHANNEL,
    UPDATE_ALL_DISCUSSIONS,
    UPDATE_DISCUSSION_LOAD_MORE,
    UPDATE_TOTAL_COMMENTS_COUNT,
    UPDATE_ALL_DISCUSSIONS_ERROR,
    UPDATE_COMMUNITY_CATEGORY_ERROR,
    UPDATE_DISCUSSION_VIEW_ERROR,
    GET_SINGLE_DISCUSSIONS_ERROR,
    GET_DISCUSSION_COMMENTS_ERROR,
    GetAllDiscussionsError,
    UpdateDiscussionViewError,
    getSingleDiscussionError,
    getDiscussionCommentsError,
    CommunityCategoryError,
    FetchError
} from "../../../constants";

import {hideDiscussionActionSpinner} from '../discussion/discussionActions';

import {
    getAllDiscussions,
    UpdateViewDiscussion,
    getSingleDiscussion,
    getDiscussionComments,
    getMoreComments
} from "../../../api/community";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const showDiscussionSpinner = () => async (dispatch: any) => {
    dispatch({
        type: SHOW_COMMUNITY_DISCUSSION_SPINNER
    });
};

export const hideDiscussionSpinner = () => async (dispatch: any) => {
    dispatch({
        type: HIDE_COMMUNITY_DISCUSSION_SPINNER
    });
};

export const showDiscussionDetailSpinner = () => async (dispatch: any) => {
    dispatch({
        type: SHOW_DISCUSSION_DETAIL_SPINNER
    });
};

export const hideDiscussionDetailSpinner = () => async (dispatch: any) => {
    dispatch({
        type: HIDE_DISCUSSION_DETAIL_SPINNER
    });
};

export const showLodeMoreSpinner = () => async (dispatch: any) => {
    dispatch({
        type: SHOW_LOAD_MORE_SPINNER
    });
};

export const hideLodeMoreSpinner = () => async (dispatch: any) => {
    dispatch({
        type: HIDE_LOAD_MORE_SPINNER
    });
};

export const updateQuestionDetail = (isQuestionDetail: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: UPDATE_QUESTION_DETAIL,
        isQuestionDetail
    });
};

export const updateActiveDiscussionData = (data: any) => async (dispatch: any, getState: any) => {
    dispatch({
        type: UPDATE_ACTIVE_DISCUSSION,
        activeDiscussion: data
    });
};

export const handleToggleChange = (selectedTab: any) => async (dispatch: any) => {
    dispatch({
        type: UPDATE_SELECTED_TAB,
        selectedTab
    });
}

export const updatePageNumber = (pageNumber: any) => async (dispatch: any) => {
    dispatch({
        type: UPDATE_DISCUSSION_COMMENTS_PAGE_NUMBER,
        pageNumber
    })
}

export const updateActiveDiscussion = (discussionId: any) => async (dispatch: any, getState: any) => {
    const {user, communityChannel, communityDiscussion} = getState();
    const {pageNumber, activeDiscussion} = communityDiscussion;
    const {id} = user;
    const {activeChannelID} = communityChannel;
    dispatch(showDiscussionDetailSpinner());
    activeDiscussion && activeDiscussion.length === 0 && dispatch(showDiscussionSpinner());
    try {
        const viewCommentRes = await UpdateViewDiscussion({
            userId: id,
            discussionId
        });
        const {success} = viewCommentRes;
        if (success) {
            const res = await getSingleDiscussion({
                discussionId,
                activeChannelID
            });
            const {data, success: singleDiscussionStatus} = res;
            dispatch(updateActiveDiscussionData(data));
            if (singleDiscussionStatus) {
                const res = await getDiscussionComments({
                    discussionId,
                    pageNumber
                });
                const {data} = res;
                const totalCount = data?.totalCount;
                const isLoadMore = data && data.length > 0 ? true : false;
                dispatch({
                    type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                    activeDiscussionComments: data
                });
                dispatch({
                    type: UPDATE_TOTAL_COMMENTS_COUNT,
                    totalCount
                });
                dispatch({
                    type: UPDATE_DISCUSSION_LOAD_MORE,
                    isLoadMore
                });
            }
        }
        dispatch(hideDiscussionDetailSpinner());
        dispatch(hideDiscussionActionSpinner());
        activeDiscussion && activeDiscussion.length === 0 && dispatch(hideDiscussionSpinner());
    } catch (error) {
        dispatch(hideDiscussionDetailSpinner());
        dispatch(hideDiscussionActionSpinner());
        activeDiscussion && activeDiscussion.length === 0 && dispatch(hideDiscussionSpinner());
        if (error === UPDATE_DISCUSSION_VIEW_ERROR) {
            toast.error(UpdateDiscussionViewError)
        } else if (error === GET_SINGLE_DISCUSSIONS_ERROR) {
            toast.error(getSingleDiscussionError)
        } else if (error === GET_DISCUSSION_COMMENTS_ERROR) {
            toast.error(getDiscussionCommentsError)
        }
        else {
            toast.error(FetchError)
        }
    }
};

export const getChannelDiscussion = (activeChannelID: any) => async (dispatch: any) => {
    dispatch({
        type: CLEAR_COMMUNITY_COMMENT_STATE
    })
    dispatch({
        type: UPDATE_ACTIVE_CHANNEL,
        activeChannelID
    })
    dispatch(showDiscussionSpinner());
    try {
        const res = await getAllDiscussions({
            activeChannelID
        });
        const {data} = res;
        dispatch({
            type: UPDATE_ALL_DISCUSSIONS,
            discussionList: data
        })
        dispatch(hideDiscussionSpinner());
    } catch (error) {
        dispatch(hideDiscussionSpinner());
        if (error === UPDATE_COMMUNITY_CATEGORY_ERROR) {
            toast.error(CommunityCategoryError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
};

export const loadMoreComments = (pageNumber: any) => async (dispatch: any, getState: any) => {
    const {communityDiscussion} = getState();
    const {activeDiscussion, activeDiscussionComments} = communityDiscussion;
    dispatch(updatePageNumber(pageNumber))
    const {id} = activeDiscussion;
    dispatch(showLodeMoreSpinner());
    try {
        const res = await getMoreComments({
            activeDiscussionID: id,
            pageNumber
        });
        const {data, totalCount} = res;
        const defaultData = activeDiscussionComments.map((item: any) => item.id)
        const newData = data.map((item: any) => item.id)
        const isSameRecords = (defaultData: any, newData: any) => JSON.stringify(defaultData) === JSON.stringify(newData);
        const filteredData = isSameRecords(defaultData, newData);
        if (!filteredData) {
            const updatedCommentList = [...activeDiscussionComments, ...data];
            dispatch({
                type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
                activeDiscussionComments: updatedCommentList
            });
        }
        if (activeDiscussionComments.length === totalCount) {
            dispatch({
                type: UPDATE_DISCUSSION_LOAD_MORE,
                isLoadMore: false
            });
        }
        dispatch(hideLodeMoreSpinner());
    } catch (error) {
        dispatch(hideLodeMoreSpinner());
        if (error === UPDATE_COMMUNITY_CATEGORY_ERROR) {
            toast.error(CommunityCategoryError)
        } else if (error === UPDATE_ALL_DISCUSSIONS_ERROR) {
            toast.error(GetAllDiscussionsError)
        } else {
            toast.error(FetchError)
        }
    }
};

export const updateDiscussion = (discussionId: any) => async (dispatch: any, getState: any) => {
    const {communityChannel, communityDiscussion} = getState();
    const {activeDiscussion} = communityDiscussion;
    const {activeChannelID} = communityChannel;
    activeDiscussion && activeDiscussion.length === 0 && dispatch(showDiscussionSpinner());
    try {
        const res = await getSingleDiscussion({
            discussionId,
            activeChannelID
        });
        const {data} = res;
        dispatch(updateActiveDiscussionData(data));
        dispatch(hideDiscussionActionSpinner());
        activeDiscussion && activeDiscussion.length === 0 && dispatch(hideDiscussionSpinner());
    } catch (error) {
        dispatch(hideDiscussionActionSpinner());
        activeDiscussion && activeDiscussion.length === 0 && dispatch(hideDiscussionSpinner());
        if (error === UPDATE_DISCUSSION_VIEW_ERROR) {
            toast.error(UpdateDiscussionViewError)
        } else if (error === GET_SINGLE_DISCUSSIONS_ERROR) {
            toast.error(getSingleDiscussionError)
        } else {
            toast.error(FetchError)
        }
    }
};
