import { assign } from '@xstate/immer';
import { createMachine } from 'xstate';
import { sendUpdate } from 'xstate/lib/actions';
import { RebateReceiptItem } from '../machineTypes';

const rebateReceiptElectronicTypeItemMachine = createMachine(
  {
    id: 'rebateReceiptItem',
    schema: {
      context: {} as RebateReceiptItem & {
        parentAmount: number;
        minimumAmount: 1;
        transferFee: number;
        funding_source_id: string;
      },
      events: {} as { type: 'update amount'; amount: number } | { type: 'update funding source'; funding_source_id: string },
    },
    tsTypes:
      {} as import('./rebateReceiptElectronicTypeItemMachine.typegen').Typegen0,
    context: {
      amount: 0,
      parentAmount: 0,
      minimumAmount: 1,
      transferFee: 0,
      type: 'dwolla_ach',
      funding_source_id: '',
    },
    on: {
      'update amount': {
        actions: ['assign amount', 'send update'],
        cond: 'greater than zero',
      },
      'update funding source': {
        actions: 'assign funding source id',
      }
    },
  },
  {
    actions: {
      'assign amount': assign((context, event) => {
        // if (event.amount < 501) {
        //   context.amount = 501;
        // } else {
        //   context.amount = event.amount;
        // }
        context.amount = event.amount;
      }),
      'assign funding source id': assign((context, event) => {
        context.funding_source_id = event.funding_source_id;
      }),
      'send update': sendUpdate(),
    },
    guards: {
      'greater than zero': (_, event) => event.amount > 0,
    },
  },
);

export type RebateReceiptElectronicTypeItemMachine =
  typeof rebateReceiptElectronicTypeItemMachine;
export default rebateReceiptElectronicTypeItemMachine;
