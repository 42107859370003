import { FC, useState } from 'react';
import { SearchNotFound } from '../../../components/SearchNotFound';
import { useStyles } from '../../../utils';
import sortDefault from '../../../images/sortDefault.svg';
import sortAscIcon from '../../../images/sortAsc.svg';
import sortDescIcon from '../../../images/sortDesc.svg';
import { adminGridHeader, memberGridHeader } from './constants';
import { Grid, GridSize } from '@mui/material';
import { DwollaFundingSource, DwollaTransfer } from '../../../common';
import StatusLabel from './StatusLabel';
import Action from './Action';
import Amount from './Amount';
import { rebatesReceiptTableMachine } from './machines';
import { useMachine } from '@xstate/react';
import { DateTime } from 'luxon';
import { Encrypt, Decrypt } from '../../../utils/cryptoFunction'

export const RebatesReceiptTable: FC<{
  gridData: {
    amount: number;
    clearedat: null | Date;
    createdat: null | Date;
    deliveredat: null | Date;
    fundingSource?: DwollaFundingSource;
    handdeliver: boolean;
    id: number;
    memberName: string | null;
    memberid: number;
    memberrebatereceiptid: number;
    memberrerebatereceiptitemtypeid: number;
    name: 'check' | 'historic';
    sentat: null | Date;
    updatedat: null | Date;
    transferInfo?: DwollaTransfer;
  }[];
  gridHeader: typeof adminGridHeader | typeof memberGridHeader;
  isBRAdmin: boolean;
  setSortKey: (sortKey: string) => void;
  sortType: 'ASC' | 'DESC' | '';
  updateRebateAction: (selectedRebate: { [key: string]: any }) => void;
}> = ({
  gridHeader,
  gridData,
  setSortKey,
  sortType,
  isBRAdmin,
  updateRebateAction,
}) => {
  const classes = useStyles();

  const [state] = useMachine(rebatesReceiptTableMachine);

  const [activeHeader, setActiveHeader] = useState(null);

  const handleClick = (event: any, sortKey: string) => {
    const selectedHeader = event.target.innerHTML;
    setActiveHeader(selectedHeader);
    setSortKey(sortKey);
  };

  if (state.hasTag('loading')) {
    return null;
  }

  if (state.hasTag('error')) {
    console.error(state.event);
    return null;
  }

  return (
    <div className={classes.root}>
      {gridData.length > 0 && (
        <Grid container spacing={0} className="tr thead">
          {gridHeader.map((item) => {
            const { label, xs, lg, sortKey } = item;
            const isValidHeader = label !== '' && sortKey !== '';
            return (
              <Grid className="th" item xs={xs as GridSize} lg={lg as GridSize}>
                <div>
                  <span
                    className="grid-header"
                    onClick={(event) => handleClick(event, sortKey)}
                  >
                    {label}
                  </span>
                  <span className="sort-icon">
                    {isValidHeader &&
                      (sortType === '' ? (
                        <img className="sort-svg" src={sortDefault} />
                      ) : label === activeHeader && sortType === 'ASC' ? (
                        <img className="sort-svg" src={sortAscIcon} />
                      ) : label === activeHeader && sortType === 'DESC' ? (
                        <img className="sort-svg" src={sortDescIcon} />
                      ) : (
                        <img className="sort-svg" src={sortDefault} />
                      ))}
                  </span>
                </div>
              </Grid>
            );
          })}
        </Grid>
      )}

      {gridData.length > 0 ? (
        gridData.map((rebateReceiptItem) => {
          const {
            amount,
            createdat,
            name,
            deliveredat,
            transferInfo,
            fundingSource,
          } = rebateReceiptItem;

          const fundingAccountName = Decrypt(fundingSource?.name?.toString());

          let formattedUploadDate = deliveredat
            ? DateTime.fromISO(deliveredat.toString()).toLocaleString()
            : '';

          if (transferInfo) {
            formattedUploadDate = DateTime.fromISO(
              transferInfo?.created?.toString() ?? '',
            ).toLocaleString();
          }

          return (
            <Grid container spacing={0} className="tr">
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={3}
                container
                direction="row"
                alignItems="center"
              >
                <Amount
                  amount={amount}
                  fundingSource={fundingSource}
                  // transferFee={
                  //   state.context['transfer fees'].filter(
                  //     (transferFee) => transferFee.name === 'standard',
                  //   )?.[0].amount
                  // }
                  transferFee={0}
                  transferInfo={transferInfo}
                />
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={isBRAdmin ? 2 : 3}
                container
                direction="row"
                alignItems="center"
              >
                {createdat
                  ? DateTime.fromISO(
                      new Date(createdat).toISOString(),
                    ).toLocaleString()
                  : '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {{
                  check: 'Check',
                  dwolla_ach: `ACH - ${fundingAccountName}`,
                  historic: 'Credited outside of application',
                }[name] ?? '-'}
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                <StatusLabel
                  deliveredAt={deliveredat}
                  fundingSource={fundingSource}
                  transferInfo={transferInfo}
                />
              </Grid>
              <Grid
                className="td xs-left-align xs-primary"
                item
                xs={12}
                lg={2}
                container
                direction="row"
                alignItems="center"
              >
                {formattedUploadDate ? formattedUploadDate : '-'}
              </Grid>
              {isBRAdmin && (
                <Grid
                  className="td xs-left-align xs-primary"
                  item
                  xs={12}
                  lg={1}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Action
                    deliveredAt={deliveredat}
                    fundingSource={fundingSource}
                    onClick={() =>
                      updateRebateAction({
                        ...rebateReceiptItem,
                        // transferFee:
                        //   state.context['transfer fees']?.filter(
                        //     (transferFee) => transferFee.name === 'standard',
                        //   )?.[0].amount ?? 0,
                        transferFee: 0,
                      })
                    }
                    transferInfo={transferInfo}
                  />
                </Grid>
              )}
            </Grid>
          );
        })
      ) : (
        <SearchNotFound sectionType={'Rebate Receipt Items'} />
      )}
    </div>
  );
};

export default RebatesReceiptTable;
