import moment from 'moment';
import {
  CLEAR_MEMBER_FORM,
  UPDATE_MEMBER_FORM_SPINNER,
  UPDATE_MEMBER_FORM_FORM_UPDATED,
  UPDATE_MEMBER_FORM_PARENTID,
  UPDATE_MEMBER_FORM_ID,
  UPDATE_MEMBER_FORM_NAME,
  UPDATE_MEMBER_FORM_STREET,
  UPDATE_MEMBER_FORM_CITY,
  UPDATE_MEMBER_FORM_STATE,
  UPDATE_MEMBER_FORM_ZIP,
  UPDATE_MEMBER_FORM_PHONE,
  UPDATE_MEMBER_FORM_MOBILE,
  UPDATE_MEMBER_FORM_JOININGDATE,
  UPDATE_MEMBER_FORM_CANHANDDELIVER,
  UPDATE_MEMBER_FORM_IMAGE_FILE,
  UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH,
  UPDATE_MEMBER_FORM_REDIRECT,
  UPDATE_MEMBER_NAV_FROM_PARENT,
  UPDATE_MEMBER_NAV_FROM_VIEW,
  UPDATE_MEMBER_NAV_FROM_TAB,
  UPDATE_MEMBER_FORM_NAME_ERROR,
  UPDATE_MEMBER_FORM_STREET_ERROR,
  UPDATE_MEMBER_FORM_CITY_ERROR,
  UPDATE_MEMBER_FORM_STATE_ERROR,
  UPDATE_MEMBER_FORM_ZIP_ERROR,
  UPDATE_MEMBER_FORM_PHONE_ERROR,
  UPDATE_MEMBER_FORM_MOBILE_ERROR,
  MemberCreateSuccess,
  MemberCreateError,
} from '../../constants';
import {
  removeZipcodeFormat,
  isValidZipcode,
  ZipcodeFormat,
  formatPhoneNumber,
} from '../../utils';
import { removePhoneNoFormat } from '../../utils/constants';
import { createMember } from '../../api/members/';

import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const clearAddMember = () => async (dispatch) => {
  dispatch({
    type: CLEAR_MEMBER_FORM,
  });
};

export const onChangeAddMemberParent = (event) => async (dispatch) => {
  const parentId = event?.target.value || 0;
  const formatedType = parentId != '0' ? parseInt(parentId) : '0';
  dispatch({
    type: UPDATE_MEMBER_FORM_PARENTID,
    parentId: formatedType,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
};

export const onChangeAddMemberName = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_FORM_NAME,
    name: value,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_NAME_ERROR,
    errorFlag: value && value.length > 0 ? false : true,
  });
};

export const onChangeAddMemberStreet = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_FORM_STREET,
    street: value,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_STREET_ERROR,
    errorFlag: value && value.length > 0 ? false : true,
  });
};

export const onChangeAddMemberCity = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_FORM_CITY,
    city: value,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_CITY_ERROR,
    errorFlag: value && value.length > 0 ? false : true,
  });
};

export const onChangeAddMemberState = (event) => async (dispatch) => {
  const stateId = event?.target.value;
  dispatch({
    type: UPDATE_MEMBER_FORM_STATE,
    state: stateId,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_STATE_ERROR,
    errorFlag: !validateSelectionType(stateId),
  });
};

export const onChangeAddMemberCanHandDeliver = (value) => async (dispatch) => {
  console.log("Checked: ", value);
  dispatch({
    type: UPDATE_MEMBER_FORM_CANHANDDELIVER,
    canHandDeliver: value,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
};

export const onChangeAddMemberZip = (value) => async (dispatch, getState) => {
  const numberOnly = new RegExp(/[0-9]+$/);
  let { memberForm } = getState();
  let { zip } = memberForm;

  value = removeZipcodeFormat(value);
  dispatch({
    type: UPDATE_MEMBER_FORM_ZIP_ERROR,
    errorFlag: !isValidZipcode(value),
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_ZIP,
    zip: ZipcodeFormat(
      numberOnly.test(value) == true || value == '' ? value : zip,
    ),
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
};

export const onChangeAddMemberPhone = (input) => async (dispatch, getState) => {
  const numberOnly = new RegExp(/[0-9]+$/);
  let { memberForm } = getState();
  let { phone } = memberForm;

  phone = removePhoneNoFormat(phone);
  let value = removePhoneNoFormat(input);

  if (
    (phone.length <= 10 || value.length <= 10) &&
    (numberOnly.test(value) || value == '')
  ) {
    dispatch({
      type: UPDATE_MEMBER_FORM_PHONE_ERROR,
      errorFlag: !(value && value.length == 10),
    });
    dispatch({
      type: UPDATE_MEMBER_FORM_PHONE,
      phone: formatPhoneNumber(value),
    });
    dispatch({
      type: UPDATE_MEMBER_FORM_FORM_UPDATED,
      isFormUpdated: true,
    });
  }
};

export const onChangeAddMemberMobile =
  (input) => async (dispatch, getState) => {
    const numberOnly = new RegExp(/[0-9]+$/);
    let { memberForm } = getState();
    let { mobile } = memberForm;

    mobile = removePhoneNoFormat(mobile);
    let value = removePhoneNoFormat(input);

    if (
      (mobile.length <= 10 || value.length <= 10) &&
      (numberOnly.test(value) || value == '')
    ) {
      dispatch({
        type: UPDATE_MEMBER_FORM_MOBILE_ERROR,
        errorFlag: !(value.length == 0 || value.length == 10),
      });
      dispatch({
        type: UPDATE_MEMBER_FORM_MOBILE,
        mobile: formatPhoneNumber(value),
      });
      dispatch({
        type: UPDATE_MEMBER_FORM_FORM_UPDATED,
        isFormUpdated: true,
      });
    }
  };

export const onChangeAddMemberJoinDate = (value) => async (dispatch) => {
  const formattedDate = moment(value).format('YYYY-MM-DD');
  dispatch({
    type: UPDATE_MEMBER_FORM_JOININGDATE,
    joiningDate: formattedDate,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_FORM_UPDATED,
    isFormUpdated: true,
  });
};

export const onChangeAddMemberAvatarImage =
  (fileObj, selectedImg) => async (dispatch) => {
    dispatch({
      type: UPDATE_MEMBER_FORM_IMAGE_FILE,
      avatarFile: fileObj,
    });
    dispatch({
      type: UPDATE_MEMBER_FORM_UPLOADED_IMG_PATH,
      avatarPath: selectedImg,
    });
    dispatch({
      type: UPDATE_MEMBER_FORM_FORM_UPDATED,
      isFormUpdated: true,
    });
  };

export const updateNavigationFromParent = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_NAV_FROM_PARENT,
    navFromParent: value,
  });
  dispatch({
    type: UPDATE_MEMBER_FORM_PARENTID,
    parentId: value,
  });
};

export const updateNavigationFromView = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_NAV_FROM_VIEW,
    navFromView: value,
  });
};

export const updateNavigationFromTab = (value) => async (dispatch) => {
  dispatch({
    type: UPDATE_MEMBER_NAV_FROM_TAB,
    navFromTab: value,
  });
};

export const onAddMember = (isLocationMode) => async (dispatch, getState) => {
  const { memberForm } = getState();
  const {
    parentId,
    name,
    street,
    city,
    state,
    zip,
    phone,
    mobile,
    joiningDate,
    canHandDeliver,
    avatar,
    avatarFile,
  } = memberForm;

  const isValidName = validateText(name);
  const isValidStreet = validateText(street);
  const isValidCity = validateText(city);
  const isvalidState = validateSelectionType(state);
  const isValidZip = isValidZipcode(zip);

  const isError =
    !isValidName ||
    !isValidStreet ||
    !isValidCity ||
    !isvalidState ||
    !isValidZip;

  if (!isValidName) {
    dispatch({
      type: UPDATE_MEMBER_FORM_NAME_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidStreet) {
    dispatch({
      type: UPDATE_MEMBER_FORM_STREET_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidCity) {
    dispatch({
      type: UPDATE_MEMBER_FORM_CITY_ERROR,
      errorFlag: true,
    });
  }
  if (!isvalidState) {
    dispatch({
      type: UPDATE_MEMBER_FORM_STATE_ERROR,
      errorFlag: true,
    });
  }
  if (!isValidZip) {
    dispatch({
      type: UPDATE_MEMBER_FORM_ZIP_ERROR,
      errorFlag: true,
    });
  }

  try {
    if (!isError) {
      dispatch({
        type: UPDATE_MEMBER_FORM_SPINNER,
        spinner: true,
      });
      const formattedDate = moment(joiningDate).format('YYYY-MM-DD');
      const response = await createMember({
        parentId,
        name,
        street,
        city,
        state,
        zip,
        phone,
        mobile,
        joiningDate: formattedDate,
        canHandDeliver,
        avatar,
        avatarFile,
      });
      const { success, message, data } = response;
      if (isLocationMode) {
        toast.success('Member location created successfully');
      } else {
        toast.success(MemberCreateSuccess);
      }

      if (success) {
        dispatch({
          type: UPDATE_MEMBER_FORM_ID,
          id: data.id || null,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_REDIRECT,
          redirectTo: true,
        });
        dispatch({
          type: UPDATE_MEMBER_FORM_FORM_UPDATED,
          isFormUpdated: false,
        });
      }
      dispatch({
        type: UPDATE_MEMBER_FORM_SPINNER,
        spinner: false,
      });
      if (!success) {
        toast.error(message);
      }
    }
  } catch (error) {
    dispatch({
      type: UPDATE_MEMBER_FORM_SPINNER,
      spinner: false,
    });
    if (!error) {
      dispatch({
        type: UPDATE_MEMBER_FORM_FORM_UPDATED,
        isFormUpdated: false,
      });
    }
    if (error) {
      toast.error(MemberCreateError);
    }
  }
};

const validateSelectionType = (selection) => {
  if (selection == '0' || selection == null || selection == '') return false;
  else {
    return true;
  }
};

const validateText = (text) => {
  if (text == '0' || text == null || text.trim() == '') return false;
  else {
    return true;
  }
};
