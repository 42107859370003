import { connect } from 'react-redux';
import {
  getVendorDC,
  updateDeleteVendorDC,
  clearVendorDCList,
  handleDeleteVendorDC,
  showDCModal,
  closeDCModal,
  onChangeDCName,
  onChangeStreet,
  onChangeCity,
  onChangeState,
  onChangeZip,
  onChangeCode,
  onAddDC,
  showEditDcModal,
  onEditDC,
} from '../../../actions/vendors/distributionCenter';
import {
  getVendorSales,
  clearVendorSalesList,
} from '../../../actions/vendors/salesPersonnel';
import {
  getVendorLinks,
  updateDeleteVendorLink,
  clearVendorLinkList,
  handleDeleteVendorLink,
  showAddLinkModal,
  closeLinkModal,
  getVendorLinkTypeList,
  onChangeLinkType,
  onChangeLinkText,
  onChangeLinkUrl,
  onAddNewLink,
  showEditLinkModal,
  onEditLink,
} from '../../../actions/vendors/links';
import {
  getVendorMembers,
  updateDeleteVendorMembers,
  clearVendorMemberList,
  handleDeleteVendorMember,
  showMemberAssignModal,
  showEditVendorMember,
  onChangeVendorMemberUser,
  onAssignSalesPersonnel,
  clearVendorMemberAssign,
  onUpdateSalesPersonnel,
  updateMemberCustomerID,
  onSelecteAssignMember,
  updateMemberCustomerAbbr,
} from '../../../actions/vendors/members';
import {
  getVendorUsersAction,
  updateDeleteVendorUsers,
  clearVendorUserList,
  handleDeleteVendorUser,
  handleDeleteClose,
  showVendorUserAssignModal,
  clearVendorUserAssign,
  onChangeVendorUserAssignDC,
  onSelecteAssignUser,
  onAssignVendorUser,
  showVendorUserCreateModal,
  clearVendorUserCreate,
  clearVendorUserCreateModal,
  onChangeVendorUserFirstname,
  onChangeVendorUserLastname,
  onChangeVendorUserEmail,
  onChangeVendorUserJobtitle,
  onChangeVendorUserPhone,
  onChangeVendorUserCreateRole,
  onChangeVendorUserCreateDC,
  onAddNewVendorUser,
  showVendorUser,
  onEditVendorUser,
  handleUserRoleChange,
} from '../../../actions/vendors/users';
import {
  getVendorKPIData,
  updateDeleteVendorKPI,
  clearVendorKPIList,
  handleDeleteVendorKPI,
  getVendorKPITypesData,
  getKpiYears,
  handleSelectKpiYear,
  handleSelectkpiQuarter,
  clearChartModal,
  updateSelectedKPIMember,
  getKPIYearQuarter,
} from '../../../actions/vendors/kpi';
import {
  clearVendorKPIModal,
  showAddKPIModal,
  closeKPIModal,
  onChangeVendorKPIkpi,
  onChangeVendorKPImember,
  onChangeVendorKPIcomments,
  onAddVendorKPI,
} from '../../../actions/vendors/kpiAdd';
import {
  showEditVendorKPIModal,
  onEditVendorKPI,
} from '../../../actions/vendors/kpiEdit';
import { getStateLists, getUserRoleList } from '../../../actions/common';
import { BrandedImageMain } from './BrandedImageMain';

const mapStateToProps = ({
  vendor,
  modal,
  vendorDCModal,
  vendorLinkModal,
  vendorMemberAssignModal,
  vendorKPIModal,
  vendorUserAssign,
  vendorUserCreate,
  common,
  paginationReducer,
  user,
}: any) => {
  // TODO type store
  return {
    vendor,
    vendorDCModal,
    vendorLinkModal,
    vendorMemberAssignModal,
    vendorUserAssign,
    vendorUserCreate,
    modal,
    vendorKPIModal,
    common,
    paginationReducer,
    user,
  };
};

export const BrandedImage = connect(mapStateToProps, {
  getVendorDC,
  getVendorUsers: getVendorUsersAction,
  getVendorMembers,
  getVendorLinks,
  getVendorKPIData,
  clearVendorDCList,
  clearVendorUserList,
  clearVendorMemberList,
  clearVendorLinkList,
  showAddLinkModal,
  closeLinkModal,
  clearVendorKPIList,
  updateDeleteVendorUsers,
  handleDeleteClose,
  handleDeleteVendorUser,
  updateDeleteVendorMembers,
  handleDeleteVendorMember,
  updateDeleteVendorLink,
  handleDeleteVendorLink,
  updateDeleteVendorKPI,
  handleDeleteVendorKPI,
  getVendorKPITypesData,
  updateDeleteVendorDC,
  handleDeleteVendorDC,
  getVendorSales,
  clearVendorSalesList,
  getVendorLinkTypeList,
  onChangeLinkType,
  onChangeLinkText,
  onChangeLinkUrl,
  onAddNewLink,
  showEditLinkModal,
  onEditLink,
  showMemberAssignModal,
  showEditVendorMember,
  onUpdateSalesPersonnel,
  updateMemberCustomerID,
  onSelecteAssignMember,
  onChangeVendorMemberUser,
  onAssignSalesPersonnel,
  clearVendorMemberAssign,
  clearVendorKPIModal,
  showAddKPIModal,
  closeKPIModal,
  onChangeVendorKPIkpi,
  onChangeVendorKPImember,
  onChangeVendorKPIcomments,
  onAddVendorKPI,
  showEditVendorKPIModal,
  onEditVendorKPI,
  showVendorUserAssignModal,
  clearVendorUserAssign,
  onChangeVendorUserAssignDC,
  onSelecteAssignUser,
  onAssignVendorUser,
  showVendorUserCreateModal,
  clearVendorUserCreate,
  clearVendorUserCreateModal,
  onChangeVendorUserFirstname,
  onChangeVendorUserLastname,
  onChangeVendorUserEmail,
  onChangeVendorUserJobtitle,
  onChangeVendorUserPhone,
  onChangeVendorUserCreateRole,
  onChangeVendorUserCreateDC,
  onAddNewVendorUser,
  showVendorUser,
  onEditVendorUser,
  showDCModal,
  closeDCModal,
  onChangeDCName,
  onChangeStreet,
  onChangeCity,
  getStateLists,
  getUserRoleList,
  onChangeState,
  onChangeZip,
  onChangeCode,
  onAddDC,
  showEditDcModal,
  onEditDC,
  updateSelectedKPIMember,
  getKpiYears,
  handleSelectKpiYear,
  handleSelectkpiQuarter,
  clearChartModal,
  updateMemberCustomerAbbr,
  handleUserRoleChange,
  getKPIYearQuarter,
})(BrandedImageMain);
