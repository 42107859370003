import {DISCUSSION_API, GET_DISCUSSION_COMMENTS_ERROR} from "../../../constants";


export const getDiscussionComments = async ({
    discussionId,
    pageNumber
}) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DISCUSSION_API}/${discussionId}/comments?pageSize=5&page=${pageNumber}&userId=${user.id}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw response;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_DISCUSSION_COMMENTS_ERROR;
    }
}

