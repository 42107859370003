import { FC, useEffect } from 'react';
import { withStyles } from '@mui/styles';
import { Loader } from '../../../utils';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

const StyledDialogTitle = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2, 5),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(4),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  icon: {
    color: '#3f51b5',
  },
  styles: {
    marginBottom: '7px',
  },
}))((props: any) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times" />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
});

const StyledDialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2, 5),
  },
}))(DialogContent);

const StyledDialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(3, 5),
    background: '#e9f0f9',
  },
}))(DialogActions);

export const CommentReportModal: FC<any> = ({
  openModal,
  handleClose,
  handleCommentReportChange,
  getReportReasonList,
  reportReasonList,
  handleCommentReportDiscussion,
  communityCommentActions: {
    commentReportId,
    isAlreadyReportedComment,
    commentModalSpinner,
  },
}) => {
  useEffect(() => {
    getReportReasonList();
    return () => {
      handleCommentReportChange(null);
    };
  }, []);
  const disableFlag = commentReportId === null;
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openModal}
      className="dialog-box"
    >
      {commentModalSpinner && (
        <div className="loader-wrapper">
          <div className="document-loader">
            <Loader size={40} />
          </div>
        </div>
      )}
      <StyledDialogTitle onClose={handleClose}>
        Report Discussion
      </StyledDialogTitle>
      <StyledDialogContent>
        <div className="report-discussion-container">
          <h2> Reason for reporting ? </h2>
          <RadioGroup
            className="custom-radio-group"
            value={commentReportId}
            onChange={(event) => handleCommentReportChange(event.target.value)}
          >
            {reportReasonList &&
              reportReasonList.map((item: any) => {
                const { id, name } = item;
                return (
                  <FormControlLabel
                    value={id}
                    disabled={isAlreadyReportedComment}
                    control={<Radio />}
                    label={name}
                  />
                );
              })}
          </RadioGroup>
        </div>
      </StyledDialogContent>
      {!isAlreadyReportedComment && (
        <StyledDialogActions>
          <div className="modal-buttons-container">
            <div className="action-button">
              <button className="cancel-user-button" onClick={handleClose}>
                Cancel
              </button>
              <Button
                className={
                  disableFlag
                    ? 'invite-user-button button-disable'
                    : 'invite-user-button'
                }
                disabled={disableFlag}
                onClick={handleCommentReportDiscussion}
              >
                Report
              </Button>
            </div>
          </div>
        </StyledDialogActions>
      )}
    </Dialog>
  );
};

export default CommentReportModal;
