import {
  UPDATE_DISCUSSION_COMMENT_ERROR,
  UPDATE_DISCUSSION_COMMENT,
  UPDATE_DISCUSSION_COMMENT_INPUT_FORM,
  SHOW_DISCUSSION_COMMENT_SPINNER,
  HIDE_DISCUSSION_COMMENT_SPINNER,
  UPDATE_ACTIVE_DISCUSSION_COMMENTS,
  UPDATE_DISCUSSION_LOAD_MORE,
  ADD_NEW_COMMENT_ERROR,
  GET_SINGLE_DISCUSSIONS_ERROR,
  GET_DISCUSSION_COMMENTS_ERROR,
  AddNewCommentsError,
  getSingleDiscussionError,
  getDiscussionCommentsError,
  FetchError,
} from '../../../constants';

import {
  addNewComment,
  getSingleDiscussion,
  getDiscussionComments,
} from '../../../api/community';

import {
  updateActiveDiscussionData,
  showDiscussionDetailSpinner,
  hideDiscussionDetailSpinner,
} from '../discussion/discussion';

import {isValidText} from '../channel/channel';

import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const showDiscussionCommentSpinner = () => async (dispatch) => {
  dispatch({
    type: SHOW_DISCUSSION_COMMENT_SPINNER,
  });
};

export const hideDiscussionCommentSpinner = () => async (dispatch) => {
  dispatch({
    type: HIDE_DISCUSSION_COMMENT_SPINNER,
  });
};

export const handleNewComment = (value) => async (dispatch) => {
  if (value !== '') {
    dispatch({
      type: UPDATE_DISCUSSION_COMMENT_ERROR,
      discussionCommentError: false,
    });
  }
  dispatch({
    type: UPDATE_DISCUSSION_COMMENT,
    discussionComment: value,
  });
  dispatch({
    type: UPDATE_DISCUSSION_COMMENT_INPUT_FORM,
    isCommentFormUpdated: true,
  });
};

export const onCommentCancel = () => async (dispatch) => {
  dispatch({
    type: UPDATE_DISCUSSION_COMMENT,
    discussionComment: '',
  });
};

export const onAddNewComment = () => async (dispatch, getState) => {
  const {user, communityChannel, communityDiscussion, communityComments} = getState();
  const {id: userId} = user;
  const {activeChannelID} = communityChannel;
  const {activeDiscussion, pageNumber} = communityDiscussion;
  const {discussionComment} = communityComments;
  const {id: discussionId} = activeDiscussion;
  const isValidDiscussionComment = isValidText(discussionComment);
  if (!isValidDiscussionComment) {
    dispatch({
      type: UPDATE_DISCUSSION_COMMENT_ERROR,
      discussionCommentError: true,
    });
  }
  try {
    if (isValidDiscussionComment) {
      dispatch(showDiscussionCommentSpinner());
      const response = await addNewComment({
        discussionId,
        discussionComment,
        userId,
      });
      const {success, message} = response;
      dispatch(showDiscussionDetailSpinner());
      if (success) {
        dispatch(onCommentCancel());
        const res = await getSingleDiscussion({
          discussionId,
          activeChannelID,
        });
        const {data, success: singleDiscussionStatus} = res;
        dispatch(updateActiveDiscussionData(data));
        if (singleDiscussionStatus) {
          const res = await getDiscussionComments({
            discussionId,
            pageNumber,
          });
          const {data, totalCount} = res;
          const isLoadMore = totalCount && totalCount > 5 ? true : false;
          dispatch({
            type: UPDATE_ACTIVE_DISCUSSION_COMMENTS,
            activeDiscussionComments: data,
          });
          dispatch({
            type: UPDATE_DISCUSSION_LOAD_MORE,
            isLoadMore,
          });
        }
        dispatch(hideDiscussionCommentSpinner());
        dispatch(hideDiscussionDetailSpinner());
      } else {
        dispatch(hideDiscussionCommentSpinner());
        dispatch(hideDiscussionDetailSpinner());
        dispatch({
          type: UPDATE_DISCUSSION_COMMENT_INPUT_FORM,
          isCommentFormUpdated: false,
        });
        toast.success(message);
      }
    }
    dispatch(hideDiscussionDetailSpinner());
  } catch (error) {
    dispatch(hideDiscussionCommentSpinner());
    dispatch(hideDiscussionDetailSpinner());
    if (error === ADD_NEW_COMMENT_ERROR) {
      toast.error(AddNewCommentsError);
    } else if (error === GET_SINGLE_DISCUSSIONS_ERROR) {
      toast.error(getSingleDiscussionError);
    } else if (error === GET_DISCUSSION_COMMENTS_ERROR) {
      toast.error(getDiscussionCommentsError);
    } else {
      toast.error(FetchError);
    }
  }
};
