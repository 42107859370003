import React from 'react';
import { NavLink } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import NotFoundImg from '../../../images/404.svg';

export const NotFound = () => {
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      className="page-notfound"
    >
      <grid>
        <img src={NotFoundImg}></img>
      </grid>
      <grid className="page-not-found-text">Page Not Found</grid>
      <grid className="page-not-found-home">
        <NavLink className="not-found-back" to={'/'}>
          Go back
        </NavLink>
      </grid>
    </Grid>
  );
};
