import { UPDATE_SAVINGS_PERCENTAGE } from "../../constants";

const initialState = {
  savingsPercentage: 0,
};

export default (
  state = initialState,
  action: { type: string; [key: string]: any }
) => {
  switch (action.type) {
    case UPDATE_SAVINGS_PERCENTAGE:
      return action.payload;
    default:
      return state;
  }
};
