import { FC, useEffect, useState } from 'react';
import { LinkHeader } from './linkHeader';
import { LinkList } from './linkList';
import { AddLinkModal } from './modal/addLinkModal';
import { Loader } from '../../../../utils';

export const LinkSection: FC<any> = ({
  updateDeleteVendorLink,
  handleDeleteClose,
  handleDeleteVendorLink,
  showAddLinkModal,
  closeLinkModal,
  modal,
  getVendorLinkTypeList,
  vendorLinkModal,
  onChangeLinkType,
  onChangeLinkText,
  onChangeLinkUrl,
  onAddNewLink,
  showEditLinkModal,
  onEditLink,
  getVendorLinks,
  clearVendorLinkList,
  vendor: { vendorLinksList, isDeleteUser, vendorLinkListSpinner },
  paginationReducer,
  user,
}) => {
  const { showModal } = modal;
  const [searchText, setSearchText] = useState('');
  const [sortKey, updateSortKey] = useState<string | null>(null);
  const [sortType, updateSortType] = useState('ASC');

  const setSortKey = (newSortKey: string) => {
    if (sortKey === newSortKey) {
      if (sortType === 'DESC') {
        updateSortType('ASC');
      } else {
        updateSortType('DESC');
      }
    } else {
      updateSortKey(newSortKey);
      updateSortType('ASC');
    }
  };

  useEffect(() => {
    getVendorLinks();

    return () => {
      clearVendorLinkList();
    };
  }, []);

  return (
    <div className="vendor-assign-content">
      <LinkHeader
        searchText={searchText}
        setSearchText={setSearchText}
        showAddLinkModal={showAddLinkModal}
      />
      {vendorLinkListSpinner ? (
        <div className="relative-wrapper">
          <div className="loader-wrapper list-spinner">
            <div className="document-loader">
              <Loader size={40} />
            </div>
          </div>
        </div>
      ) : (
        <LinkList
          vendorLinksList={vendorLinksList || []}
          updateDeleteVendorLink={updateDeleteVendorLink}
          showEditLinkModal={showEditLinkModal}
          isDeleteUser={isDeleteUser}
          handleDeleteClose={handleDeleteClose}
          handleDeleteVendorLink={handleDeleteVendorLink}
          searchText={searchText}
          sortKey={sortKey}
          setSortKey={setSortKey}
          sortType={sortType}
          paginationReducer={paginationReducer}
          user={user}
        />
      )}
      <AddLinkModal
        showModal={showModal}
        closeModal={closeLinkModal}
        getVendorLinkTypeList={getVendorLinkTypeList}
        onChangeLinkType={onChangeLinkType}
        onChangeLinkText={onChangeLinkText}
        onChangeLinkUrl={onChangeLinkUrl}
        vendorLinkModal={vendorLinkModal}
        onAddNewLink={onAddNewLink}
        onEditLink={onEditLink}
      />
    </div>
  );
};
