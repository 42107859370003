import {
    UPDATE_COMMUNITY_CATEGORY,
    UPDATE_EDIT_CHANNEL,
    UPDATE_CHANNEL_ID,
    UPDATE_CHANNEL_NAME,
    UPDATE_CHANNEL_DESC,
    UPDATE_CHANNEL_FORM_INPUTS,
    SHOW_CHANNEL_FORM_SPINNER,
    HIDE_CHANNEL_FORM_SPINNER,
    UPDATE_CHANNEL_NAME_ERROR,
    UPDATE_CHANNEL_DESC_ERROR,
    UPDATE_COMMUNITY_CHANNEL_ERROR,
    UPDATE_COMMUNITY_CATEGORY_ERROR,
    CommunityCategoryError,
    FetchError
} from "../../../constants";

import {clearCommunityChannelModal, isValidText} from "./channel";

import {
    getCommunityCategory,
    updateCommunityCategory,
} from "../../../api/community";

import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const updateEditChannel = (isEditChannel) => async (dispatch) => {
    dispatch({
        type: UPDATE_EDIT_CHANNEL,
        isEditChannel
    })
}

export const editCommunityCategory = (selectedChannel) => async (dispatch) => {
    const {id, name, description} = selectedChannel || {};
    dispatch(updateEditChannel(true));
    dispatch({
        type: UPDATE_CHANNEL_ID,
        channelID: id
    })
    dispatch({
        type: UPDATE_CHANNEL_NAME,
        channelName: name
    })
    dispatch({
        type: UPDATE_CHANNEL_DESC,
        channelDesc: description
    })
}

export const handleUpdateChannel = () => async (dispatch, getState) => {
    const {communityChannel} = getState();
    const {channelID, channelName, channelDesc} = communityChannel;
    const isValidchannelName = isValidText(channelName);
    const isValidChannelDesc = isValidText(channelDesc);
    if (!isValidchannelName) {
        dispatch({
            type: UPDATE_CHANNEL_NAME_ERROR,
            isChannelNameError: true
        });
    }
    if (!isValidChannelDesc) {
        dispatch({
            type: UPDATE_CHANNEL_DESC_ERROR,
            isChannelDescError: true
        });
    }
    const isError = !isValidchannelName || !isValidChannelDesc;
    try {
        if (!isError) {
            dispatch({
                type: SHOW_CHANNEL_FORM_SPINNER,
                channelFormSpinner: true
            });
            const response = await updateCommunityCategory({
                channelID,
                channelName,
                channelDesc
            });
            const categoryRes = await getCommunityCategory();
            const {data} = categoryRes;
            dispatch({
                type: UPDATE_COMMUNITY_CATEGORY,
                communityCategory: data
            })
            dispatch({
                type: HIDE_CHANNEL_FORM_SPINNER,
                channelFormSpinner: false
            });
            const {success, message} = response;
            if (!success) {
                toast.error(message)
            }
            else {
                dispatch({
                    type: UPDATE_CHANNEL_FORM_INPUTS,
                    isChannelFormUpdated: false
                });
                dispatch(clearCommunityChannelModal())
                dispatch(updateEditChannel(false));
                toast.success(message);
            }
        }
    } catch (error) {
        if (error === UPDATE_COMMUNITY_CHANNEL_ERROR) {
            toast.error(UpdateChannelError)
        }
        else if (error === UPDATE_COMMUNITY_CATEGORY_ERROR) {
            toast.error(CommunityCategoryError)
        }
        else {
            toast.error(FetchError)
        }
    }
}

