import { Grid } from "@material-ui/core";
import { FC, useState, useEffect } from "react";
import DefaultUserImg from "../../../../images/profile-placeholder.jpg";
import { CustomModal } from "../../../../components/CustomModal/index";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";
import AuthorizationService from "../../../../authorization/AuthorizationService";
import { WarningPopup } from "../../../../utils";
import {
  handleDeleteUserMember,
  handleEditMemberUser,
} from "../../../../actions/members";
import { deleteUserMember } from "../../../../api/members";
import { toast } from "react-toastify";
import { UserMemberDeletedSuccess } from "../../../../constants";
import { FetchError, MemberDeleteUserError } from "../../../../constants/error";
import { AddMemberUser } from "../../../members/memberDetails/tabs/users/modal/addMemberUser";

const formatContactNumber = (contactNumber: string) => {
  return contactNumber.replace(/(\d{3})(\d{3})(\d*)/, "($1) $2-$3");
};

export const CommunityUserListRow: FC<{
  member: {
    avatar: null | string;
    id: number;
    firstname: null | string;
    lastname: null | string;
    email: null | string;
    members: null | string;
    phone?: string | null;
  };
  send: any;
}> = ({ member, send }) => {
  const { id, firstname, lastname, email, members, phone, avatar } = member;

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isUserMemberDelete, SetIsUserMemberDelete] = useState<boolean>(false);
  const [selectedDeleteUser, setSelectedDeleteUser] = useState<any>("");
  const [userId, setUserId] = useState(0);

  const closeModal = (state:any) => {
    setShowModal(state);
  };

  const handleMemberClick = () => {
    setShowModal(true);
  };
  const handleDelete = async () => {
    try {
      const response = await deleteUserMember(userId);
      const { success, message, data } = response;
      if (success) {
        success ? toast.success(UserMemberDeletedSuccess) : null;
        SetIsUserMemberDelete(false);
        setSelectedDeleteUser("");
        setUserId(0);
        handleDeleteUserMember(false, null);
        send({
          type: "refresh",
        });
      } else {
        throw "update member user delete error";
      }
    } catch (error) {
      if (error === "update member user delete error") {
        toast.error(MemberDeleteUserError);
      } else {
        toast.error(FetchError);
      }
    }
  };

  return (
    <>
      <Grid style={{ cursor: "pointer" }} container spacing={0} className="tr">
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={4}
          container
          direction="row"
          alignItems="center"
        >
          <div className="table-member-details">
            <div className="member-img">
              <img src={avatar !== null ? `/${avatar}` : DefaultUserImg} />
            </div>
            <div className="member-profile-details">
              <div>{`${firstname} ${lastname}`}</div>
              <div className="member-mail-id" style={{ marginTop: "4px" }}>
                <div className="br-number-normal">{email ? email : ""}</div>
              </div>
            </div>
          </div>
        </Grid>
        <Grid
          className="td xs-left-align xs-primary"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          alignItems="center"
        >
          {phone && phone !== "null" ? (
            <a className="phone-link" href={`tel:${phone}`}>
              {formatContactNumber(phone)}
            </a>
          ) : (
            "-"
          )}
        </Grid>
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={4}
          container
          direction="row"
          alignItems="center"
        >
          {members && members !== "null" ? members : "-"}
        </Grid>
        <Grid
          className="td xs-left-align"
          item
          xs={12}
          lg={2}
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
        >
          <Tooltip title="Email">
            <Button className="btn-circle btn-secondary" variant="contained">
              <a
                className="email-link"
                href={`mailto:${email}?subject=Buy Right Application Inquiry for ${members}`}
              >
                <i className="fas fa-envelope" />
              </a>
            </Button>
          </Tooltip>
          {/* {AuthorizationService.isAuthorizedHTML("deleteMemberUsers") && (
            <Tooltip title="Edit">
              <Button
                className="btn-circle btn-secondary"
                onClick={() => {
                  handleEditMemberUser(member)
                  setShowModal(true)
                }}
              >
                <i className="fas fa-pen"></i>
              </Button>
            </Tooltip>
          )} */}
          {AuthorizationService.isAuthorizedHTML("deleteMemberUsers") && (
            <Tooltip title="Delete">
              <Button
                className="btn-circle-trash"
                variant="outlined"
                color="secondary"
                onClick={() => {
                  SetIsUserMemberDelete(true);
                  setSelectedDeleteUser(`${firstname} ${lastname}`);
                  setUserId(id);
                  handleDeleteUserMember(true, member);
                }}
              >
                <i className="fas fa-trash-alt"></i>
              </Button>
            </Tooltip>
          )}
        </Grid>
      </Grid>
      <WarningPopup
        open={isUserMemberDelete}
        type={"delete"}
        isMemberDelete={true}
        name={selectedDeleteUser}
        handleProceed={handleDelete}
        spinner={false}
        handleUnAssign={() => null}
        assignedMember={members === null ? '' : members}
        handleClose={() => {
          SetIsUserMemberDelete(false);
          setSelectedDeleteUser("");
          setUserId(0);
          handleDeleteUserMember(false, null);
        }}
      />
    </>
  );
};

export default CommunityUserListRow;
