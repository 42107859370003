import { getSavingsPercentage } from "../../api/dashboard/savings/getSavingsPercentage"
import { UPDATE_SAVINGS_PERCENTAGE } from "../../constants"

export const savingsPercentage = () => async(dispatch) =>{
    try {
        const response  = await getSavingsPercentage()
        const {data,success} = response

        if(success){
            dispatch({
                type : UPDATE_SAVINGS_PERCENTAGE,
                payload : {
                    savingsPercentage : data[0]?.percentage
                }
            })
        }

    } catch (error) {
        console.log(error)
    }
}