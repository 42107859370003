import { useMachine } from '@xstate/react';
import { FC, useEffect } from 'react';
import paymentTermsMachine from '../../../machines/paymentTermsMachine/paymentTermsMachine';
import CompactPaymentTermsChart from './CompactPaymentTermChart';
import StandardPaymentTermsChart from './StandardPaymentTermsChart';

export const PaymentTerms: FC<{
  actor?: any;
  id?: string;
  compact?: boolean;
  memberId: number;
}> = ({ actor, id, compact, memberId }) => {
  const [state, send, service] = useMachine(paymentTermsMachine, {
    context: {
      memberFilters: [memberId],
    },
  });

  useEffect(() => {
    if (memberId !== state.context.memberFilters?.[0]) {
      send({
        memberFilters: [memberId],
        type: 'updateMemberFilters',
      });
    }
  }, [memberId]);

  if (compact) return <CompactPaymentTermsChart key={memberId} actor={actor ?? service} id={id} />;
  return <StandardPaymentTermsChart key={memberId} actor={actor} id={id} />;
};

export default PaymentTerms;
