import { assign } from 'xstate';
import { createModel } from 'xstate/lib/model';

const requestResetAccountPasswordModel = createModel(
  {
    email: '',
    emailRegExp: new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/),
  },
  {
    events: {
      submit: () => ({}),
      updateEmail: (email: string) => ({ email }),
    },
  },
);

export const requestResetAccountPasswordMachine =
  requestResetAccountPasswordModel.createMachine(
    {
      id: 'requestResetAccountPassword',
      context: requestResetAccountPasswordModel.initialContext,
      initial: 'editing',
      states: {
        editing: {
          description:
            'The user can edit and submit the email to their account.',
          on: {
            submit: [
              {
                actions: 'notifyInvalidEmail',
                cond: ({ email, emailRegExp }) => !emailRegExp.test(email),
                description:
                  'Notify an error to the user that the given email is invalid.',
              },
              {
                description: 'Email is valid and can be submitted.',
                target: 'submitting',
              },
            ],
            updateEmail: {
              actions: assign({
                email: (_, { email }) => email,
              }),
              description:
                'The email value is updated to the value from the event request.',
            },
          },
          tags: 'editing',
        },
        submitting: {
          invoke: {
            id: 'submitEmailForAccountPasswordReset',
            src: 'submitEmailForAccountPasswordReset',
            onDone: [
              {
                actions: 'notifyFailure',
                cond: (_, { data }) => !data.success,
                description:
                  'No errors occurred while submitting the request but the password was not correctly reset. Notify an error to the user.',
                target: 'editing',
              },
              {
                actions: 'notifySuccess',
                description:
                  'No errors occurred while submitting the request and the request was submitted successfully. Notify a success message to the user and complete the process.',
                target: 'complete',
              },
            ],
            onError: {
              actions: 'notifyFailure',
              description:
                'An error occurred while submitting the request, the password was not correctly reset. Notify an error to the user.',
              target: 'editing',
            },
          },
          tags: 'loading',
        },
        complete: { entry: 'redirectToLogin', type: 'final' },
      },
    },
    {
      actions: {
        notifyFailure: () => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              'The "notifyFailure" action was fired but it was not implemented correctly.',
            );
          }
        },
        notifyInvalidEmail: () => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              'The "notifyInvalidEmail" action was fired but it was not implemented correctly.',
            );
          }
        },
        notifySuccess: () => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              'The "notifySuccess" action was fired but it was not implemented correctly.',
            );
          }
        },
        redirectToLogin: () => {
          if (process.env.NODE_ENV !== 'production') {
            console.error(
              'The "redirectToLogin" action was fired but it was not implemented correctly.',
            );
          }
        },
      },
      services: {
        submitEmailForAccountPasswordReset: () =>
          Promise.reject(
            'The "submitEmailForAccountPasswordReset" service was invoked but it was not implemented correctly.',
          ),
      },
    },
  );

export type RequestResetAccountPasswordMachine =
  typeof requestResetAccountPasswordMachine;
