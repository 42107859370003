import {
    SHOW_COMMUNITY_CATEGORY_SPINNER,
    HIDE_COMMUNITY_CATEGORY_SPINNER,
    SHOW_CHANNEL_FORM_SPINNER,
    HIDE_CHANNEL_FORM_SPINNER,
    SHOW_COMMUNITY_DISCUSSION_SPINNER,
    HIDE_COMMUNITY_DISCUSSION_SPINNER,
    UPDATE_COMMUNITY_CATEGORY,
    UPDATE_ACTIVE_CHANNEL,
    UPDATE_NEW_CHANNEL,
    UPDATE_CHANNEL_FORM_INPUTS,
    UPDATE_CHANNEL_ID,
    UPDATE_CHANNEL_NAME,
    UPDATE_CHANNEL_DESC,
    UPDATE_CHANNEL_NAME_ERROR,
    UPDATE_CHANNEL_DESC_ERROR,
    CLEAR_COMMUNITY_CHANNEL_MODAL,
    UPDATE_EDIT_CHANNEL,
    UPDATE_ALL_DISCUSSIONS
} from "../../constants";

const initialState = {
    categorySpinner: false,
    channelFormSpinner: false,
    discussionSpinner: false,
    communityCategory: [],
    discussionList: null,
    activeChannelID: null,
    isNewChannel: false,
    isEditChannel: false,
    channelID: null,
    channelName: null,
    channelDesc: null,
    isChannelNameError: false,
    isChannelDescError: false,
    isChannelFormUpdated: false
};

export default (state = initialState, action: { type: string; [key: string]: any; }) => {
    switch (action.type) {
        case SHOW_COMMUNITY_CATEGORY_SPINNER:
            return ({
                ...state,
                categorySpinner: true
            });
        case HIDE_COMMUNITY_CATEGORY_SPINNER:
            return ({
                ...state,
                categorySpinner: false
            });
        case SHOW_CHANNEL_FORM_SPINNER:
            return ({
                ...state,
                channelFormSpinner: true
            });
        case HIDE_CHANNEL_FORM_SPINNER:
            return ({
                ...state,
                channelFormSpinner: false
            });
        case SHOW_COMMUNITY_DISCUSSION_SPINNER:
            return ({
                ...state,
                discussionSpinner: true
            });
        case HIDE_COMMUNITY_DISCUSSION_SPINNER:
            return ({
                ...state,
                discussionSpinner: false
            });

        case UPDATE_COMMUNITY_CATEGORY:
            return {
                ...state,
                communityCategory: action.communityCategory
            };
        case UPDATE_ACTIVE_CHANNEL:
            return {
                ...state,
                activeChannelID: action.activeChannelID
            };
        case UPDATE_NEW_CHANNEL:
            return {
                ...state,
                isNewChannel: action.isNewChannel
            };
        case UPDATE_CHANNEL_ID:
            return {
                ...state,
                channelID: action.channelID
            };
        case UPDATE_CHANNEL_NAME:
            return {
                ...state,
                channelName: action.channelName
            };
        case UPDATE_CHANNEL_DESC:
            return {
                ...state,
                channelDesc: action.channelDesc
            };
        case UPDATE_CHANNEL_NAME_ERROR:
            return {
                ...state,
                isChannelNameError: action.isChannelNameError
            };
        case UPDATE_CHANNEL_DESC_ERROR:
            return {
                ...state,
                isChannelDescError: action.isChannelDescError
            };
        case UPDATE_CHANNEL_FORM_INPUTS:
            return {
                ...state,
                isChannelFormUpdated: action.isChannelFormUpdated
            };
        case UPDATE_EDIT_CHANNEL:
            return {
                ...state,
                isEditChannel: action.isEditChannel
            };
        case UPDATE_ALL_DISCUSSIONS:
            return {
                ...state,
                discussionList: action.discussionList
            };
        case CLEAR_COMMUNITY_CHANNEL_MODAL:
            return {
                ...state,
                isNewChannel: false,
                channelID: null,
                channelName: null,
                channelDesc: null,
                isChannelNameError: false,
                isChannelDescError: false,
                isChannelFormUpdated: false
            };
        default:
            return state;
    }
};
