import { GET_VENDOR_LINK_TYPES_API, GET_VENDOR_LINK_TYPES_ERROR } from "../../constants";


export const getVendorLinkTypes = async () => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${GET_VENDOR_LINK_TYPES_API}`, {
            headers: new Headers({
                'x-access-token': user.accessToken,
                'Content-Type': 'application/json'
              }), 
        }
        );
        if(!response.ok) throw GET_VENDOR_LINK_TYPES_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw GET_VENDOR_LINK_TYPES_ERROR;
    } 
}

