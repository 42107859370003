export const columns = [
  {
    label: 'Product Name',
    value: 'productName',
    sortKey: 'productName',
    xs: 12,
    lg: 3,
  },
  {
    label: 'Vendor Product #',
    value: 'productNumber',
    sortKey: 'productNumber',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Pack Size',
    value: 'packSize',
    sortKey: 'packSize',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Type',
    value: 'rebateType',
    sortKey: 'rebateType',
    xs: 12,
    lg: 1,
  },
  {
    label: 'Amount',
    value: 'rebateAmount',
    sortKey: 'rebateAmount',
    xs: 12,
    lg: 2,
  },
  {
    label: 'Percent',
    value: 'rebatePercent',
    sortKey: 'rebatePercent',
    xs: 12,
    lg: 1,
  },
];

export const actionColumns = [
  {
    label: '',
    value: 'action',
    xs: 12,
    lg: 1,
  },
];