import { baseUrl, GET_REBATE_MEMBERS_LIST_ERROR } from '../../../constants';
import serverApiV2Instance from '../../../services/serverApiV2Instance';

export const getRebateReceiptsList = (memberId: number) => serverApiV2Instance.get(`/members/${memberId}/rebate-receipt-items`);

// export const getRebateReceiptsList = async (memberId: number) => {
//   try {
//     const user = JSON.parse(localStorage.getItem('user') || '{}');
//     const response = await fetch(
//       `${baseUrl}/members/${memberId}/rebate-receipt-items`,
//       {
//         headers: new Headers({
//           'x-access-token': user.accessToken,
//           'Content-Type': 'application/json',
//         }),
//       },
//     );
//     if (!response.ok) throw GET_REBATE_MEMBERS_LIST_ERROR;
//     const responseJson = await response.json();
//     return responseJson;
//   } catch (error) {
//     throw GET_REBATE_MEMBERS_LIST_ERROR;
//   }
// };
