import { UPDATE_DOCUMENT_PUBLISH_ERROR } from '../../constants';
import serviceInterceptor from '../../services/ServiceInterceptor';

export const publishDocument = async ({
  id,
  documentTypeId,
  fileContent,
  isAllMembers,
  selectedMembersID,
  selectedDCID,
  documentDesc,
}) => {
  try {
    const bodyFormData = new FormData();
    bodyFormData.append('userId', id);
    bodyFormData.append('documentTypeId', documentTypeId);
    bodyFormData.append('description', documentDesc);
    bodyFormData.append('documentFile', fileContent);
    !isAllMembers &&
      selectedMembersID?.length > 0 &&
      selectedMembersID.forEach((memberId) => {
        bodyFormData.append('memberIds', memberId);
      });
    !isAllMembers &&
      selectedDCID?.length > 0 &&
      selectedDCID.forEach((dcID) => {
        bodyFormData.append('vendorDistributionCenterIds', dcID);
      });

    const response = await serviceInterceptor.post('/documents', bodyFormData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    if (!response?.data?.success) {
      throw UPDATE_DOCUMENT_PUBLISH_ERROR;
    }
    return response.data.data;
  } catch (error) {
    throw UPDATE_DOCUMENT_PUBLISH_ERROR;
  }
};
