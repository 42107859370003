import {DOCUMENT_RECORDS_API, UPDATE_DOCUMENT_LIST_ERROR} from "../../constants";


export const getDocumentsListAPI = async () => {
    try {
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        const member = JSON.parse(sessionStorage.getItem('user') || '{}');
        let accessToken = Object.keys(member).length ? member.accessToken : user.accessToken
        const {id, isBRAdmin, vendorusers, memberusers} = user;
        const showAllDocs = isBRAdmin || (vendorusers?.length > 0 && memberusers?.length <= 0);
        const response = await fetch(`${DOCUMENT_RECORDS_API}?userId=${Object.keys(member).length ? member.id : id}&isBRAdmin=${Object.keys(member).length ? false : showAllDocs}`, {
            headers: new Headers({
                "x-access-token": accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw UPDATE_DOCUMENT_LIST_ERROR;
        const responseJson = await response.json();
        return responseJson;
    } catch (error) {
        throw UPDATE_DOCUMENT_LIST_ERROR;
    }
}

