import {DOCUMENT_RECORDS_API, DOWNLOAD_DOCUMENT_ERROR} from "../../constants";


export const downloadDocumentAPI = async (
    documentID,
    fileName,
    fileextension
) => {
    try {
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await fetch(`${DOCUMENT_RECORDS_API}/${documentID}/file/${fileName}.${fileextension}`, {
            headers: new Headers({
                "x-access-token": user.accessToken,
                "Content-Type": "application/json"
            }),
        }
        );
        if (!response.ok) throw DOWNLOAD_DOCUMENT_ERROR;
        const blob = await response.blob();
        return blob;
    } catch (error) {
        throw DOWNLOAD_DOCUMENT_ERROR;
    }
}

