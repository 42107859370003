import {
  withStyles,
  Typography,
  IconButton,
  DialogTitle,
} from '@material-ui/core';
import { FC, ReactNode } from 'react';

const DialogHeadContent: FC<{
  classes: {
    closeButton: string;
    root: string;
  };
  onClose?: () => void;
  children?: ReactNode;
}> = ({ children, classes, onClose, ...other }) => {
  return (
    <DialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <i className="fas fa-times"></i>
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export const DialogHead = withStyles(
  (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
)(DialogHeadContent);

export type DialogHeadType = typeof DialogHead;
