import { assign } from '@xstate/immer';
import { createMachine } from 'xstate';
import { RebateReceiptItem } from '../machineTypes';

const rebateReceiptHistoricTypeItemMachine = createMachine(
  {
    id: 'rebateReceiptItem',
    schema: {
      context: {} as RebateReceiptItem & {
        parentAmount: number;
      },
      events: {} as {
        type: 'updateAmount';
        amount: number;
      },
    },
    tsTypes:
      {} as import('./rebateReceiptHistoricTypeItemMachine.typegen').Typegen0,
    context: {
      amount: 0,
      parentAmount: 0,
      type: 'historic',
    },
    on: {
      updateAmount: {
        actions: 'assignAmount',
      },
    },
  },
  {
    actions: {
      assignAmount: assign((context, event) => {
        context.amount = event.amount > 0 ? Math.floor(event.amount) : 0;
      }),
    },
  },
);

export type RebateReceiptHistoricTypeItemMachine =
  typeof rebateReceiptHistoricTypeItemMachine;
export default rebateReceiptHistoricTypeItemMachine;
