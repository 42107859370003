import { connect } from 'react-redux';
import { PasswordUpdate as PasswordUpdateContent } from './PasswordUpdate';
import {
  clearChangePassword,
  onChangeOldPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  handleChangePasswordShow,
  onSubmitChangePassword,
  updateProfileRoute,
} from '../../actions/profile';

const mapStateToProps = ({ user, changePasswordForm, profile}: any) => ({
  user,
  changePasswordForm,
  profile,
});

export const PasswordUpdate = connect(mapStateToProps, {
  clearChangePassword,
  onChangeOldPassword,
  onChangeNewPassword,
  onChangeConfirmPassword,
  handleChangePasswordShow,
  onSubmitChangePassword,
  updateProfileRoute,
})(PasswordUpdateContent);
