import {
    UPDATE_FILE_UPLOAD_ACTIVE_STEP,
    UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_ONE_NAME,
    UPDATE_MEMBER_REBATES_FILE_ONE_SIZE,
    UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_TWO_NAME,
    UPDATE_MEMBER_REBATES_FILE_TWO_SIZE,
    UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT,
    UPDATE_MEMBER_REBATES_FILE_THREE_NAME,
    UPDATE_MEMBER_REBATES_FILE_THREE_SIZE,
    UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION,
    UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
    CLEAR_MEMBER_REBATE_MODAL,
} from "../../../constants";

import { getFileName, getFileSize } from "../../../utils";

export const updateActiveStep = (activeStep) => async (dispatch) => {
    dispatch({
        type: UPDATE_FILE_UPLOAD_ACTIVE_STEP,
        activeStep
    })
}

export const handleMemberRebatesFileOne = (uploadedFile) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
        fileExtensionError: false
    })
    const fileName = getFileName(uploadedFile && uploadedFile.name);
    const fileSize = getFileSize(uploadedFile && uploadedFile.size);
    const fileExtension = uploadedFile?.name?.split('.')?.pop() ?? '';
    const vaildFileExtension = fileExtension === "xls" || fileExtension === "xlsx" && fileName !== null && fileSize !== null && fileExtension !== null;
    if (vaildFileExtension) {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT,
            fileOneContent: uploadedFile
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_ONE_NAME,
            fileOneName: fileName
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_ONE_SIZE,
            fileOneSize: fileSize
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION,
            fileOneExtension: fileExtension
        })
    } else {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
            fileExtensionError: true
        })
    }
}

export const handleMemberRebatesFileTwo = (uploadedFile) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
        fileExtensionError: false
    })
    const fileName = getFileName(uploadedFile.name);
    const fileSize = getFileSize(uploadedFile.size)
    const fileExtension = uploadedFile?.name?.split('.')?.pop() ?? '';
    const vaildFileExtension = fileExtension === "xls" || fileExtension === "xlsx" && fileName !== null && fileSize !== null && fileExtension !== null;
    if (vaildFileExtension) {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT,
            fileTwoContent: uploadedFile
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_TWO_NAME,
            fileTwoName: fileName
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_TWO_SIZE,
            fileTwoSize: fileSize
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION,
            fileTwoExtension: fileExtension
        })
    } else {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
            fileExtensionError: true
        })
    }
}

export const handleMemberRebatesFileThree = (uploadedFile) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
        fileExtensionError: false
    })
    const fileName = getFileName(uploadedFile.name);
    const fileSize = getFileSize(uploadedFile.size);
    const fileExtension = uploadedFile?.name?.split('.')?.pop() ?? '';
    const vaildFileExtension = fileExtension === "xls" || fileExtension === "xlsx" && fileName !== null && fileSize !== null && fileExtension !== null;
    if (vaildFileExtension) {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT,
            fileThreeContent: uploadedFile
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_THREE_NAME,
            fileThreeName: fileName
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_THREE_SIZE,
            fileThreeSize: fileSize
        })
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION,
            fileThreeExtension: fileExtension
        })
    } else {
        dispatch({
            type: UPDATE_MEMBER_REBATES_FILE_EXTENSION_ERROR,
            fileExtensionError: true
        })
    }
}

export const clearMemberRebateModal = () => async (dispatch) => {
    dispatch({
        type: CLEAR_MEMBER_REBATE_MODAL,
    })
}

export const onMemberRebatesFileOneRemove = () => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_CONTENT,
        fileOneContent: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_NAME,
        fileOneName: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_SIZE,
        fileOneSize: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION,
        fileOneExtension: null
    })
}

export const onMemberRebatesFileTwoRemove = () => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_CONTENT,
        fileTwoContent: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_NAME,
        fileTwoName: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_SIZE,
        fileTwoSize: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION,
        fileTwoExtension: null
    })
}

export const onMemberRebatesFileThreeRemove = () => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_CONTENT,
        fileThreeContent: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_NAME,
        fileThreeName: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_SIZE,
        fileThreeSize: null
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION,
        fileThreeExtension: null
    })
}

export const onChangeFileOne = (fileOneName, fileOneSize, fileOneExtension) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_NAME,
        fileOneName
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_SIZE,
        fileOneSize
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_ONE_EXTENSION,
        fileOneExtension
    })
}

export const onChangeFileTwo = (fileTwoName, fileTwoSize, fileTwoExtension) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_NAME,
        fileTwoName
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_SIZE,
        fileTwoSize
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_TWO_EXTENSION,
        fileTwoExtension
    })
}


export const onChangeFileThree = (fileThreeName, fileThreeSize, fileThreeExtension) => async (dispatch) => {
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_NAME,
        fileThreeName
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_SIZE,
        fileThreeSize
    })
    dispatch({
        type: UPDATE_MEMBER_REBATES_FILE_THREE_EXTENSION,
        fileThreeExtension
    })
}
