import {
  CLEAR_ADS_FORM,
  UPDATE_ADS_FORM_SPINNER,
  UPDATE_ADS_FORM_UPDATED,
  UPDATE_ADS_FORM_IS_EDIT,
  UPDATE_ADS_FORM_AD_URL,
  UPDATE_ADS_FORM_AD_TYPE,
  UPDATE_ADS_FORM_PRODUCT_NAME,
  UPDATE_ADS_FORM_PRODUCT_BRAND,
  UPDATE_ADS_FORM_SPECIAL,
  UPDATE_ADS_FORM_DESC,
  UPDATE_ADS_FORM_AD_STATUS,
  UPDATE_ADS_FORM_AD_RATING,
  UPDATE_ADS_FORM_IMAGE_FILE,
  UPDATE_ADS_FORM_UPLOADED_IMG_PATH,
  UPDATE_ADS_FORM_AD_URL_ERROR,
  UPDATE_ADS_FORM_AD_TYPE_ERROR,
  UPDATE_ADS_FORM_PRODUCT_NAME_ERROR,
  UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR,
  UPDATE_ADS_FORM_SPECIAL_ERROR,
  UPDATE_ADS_FORM_DESC_ERROR,
  UPDATE_ADS_FORM_AD_RATING_ERROR,
} from "../../constants";

const initialState = {
  loading: false,
  isFormUpdated: false,
  isEdit: false,
  adUrl: "",
  adType: "",
  productName: "",
  productBrand: "",
  adSpecial: "",
  description: "",
  adStatus: "",
  adRating: "",
  adImage: "",
  adImagePath: "",
  errorAdUrl: false,
  errorAdType: false,
  errorProductName: false,
  errorBrandName: false,
  errorSpecial: false,
  errorDesc: false,
  errorAdRating: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_ADS_FORM_SPINNER:
      return {
        ...state,
        loading: action.spinner,
      };
    case UPDATE_ADS_FORM_UPDATED:
      return {
        ...state,
        isFormUpdated: action.flag,
      };
    case UPDATE_ADS_FORM_IS_EDIT:
      return {
        ...state,
        isEdit: action.isEdit,
      };
    case UPDATE_ADS_FORM_AD_URL:
      return {
        ...state,
        adUrl: action.value,
      };
    case UPDATE_ADS_FORM_AD_TYPE:
      return {
        ...state,
        adType: action.value,
      };
    case UPDATE_ADS_FORM_PRODUCT_NAME:
      return {
        ...state,
        productName: action.value,
      };
    case UPDATE_ADS_FORM_PRODUCT_BRAND:
      return {
        ...state,
        productBrand: action.value,
      };
    case UPDATE_ADS_FORM_SPECIAL:
      return {
        ...state,
        adSpecial: action.value,
      };
    case UPDATE_ADS_FORM_DESC:
      return {
        ...state,
        description: action.value,
      };
    case UPDATE_ADS_FORM_AD_STATUS:
      return {
        ...state,
        adStatus: action.value,
      };
    case UPDATE_ADS_FORM_AD_RATING:
      return {
        ...state,
        adRating: action.value,
      };
    case UPDATE_ADS_FORM_SPECIAL:
      return {
        ...state,
        adSpecial: action.value,
      };
    case UPDATE_ADS_FORM_IMAGE_FILE:
      return {
        ...state,
        adImage: action.file,
      };
    case UPDATE_ADS_FORM_UPLOADED_IMG_PATH:
      return {
        ...state,
        adImagePath: action.imagePath,
      };
    case CLEAR_ADS_FORM:
      return initialState;
    case UPDATE_ADS_FORM_AD_URL_ERROR:
      return {
        ...state,
        errorAdUrl: action.errorFlag,
      };
    case UPDATE_ADS_FORM_AD_TYPE_ERROR:
      return {
        ...state,
        errorAdType: action.errorFlag,
      };
    case UPDATE_ADS_FORM_PRODUCT_NAME_ERROR:
      return {
        ...state,
        errorProductName: action.errorFlag,
      };
    case UPDATE_ADS_FORM_PRODUCT_BRAND_ERROR:
      return {
        ...state,
        errorBrandName: action.errorFlag,
      };
    case UPDATE_ADS_FORM_SPECIAL_ERROR:
      return {
        ...state,
        errorSpecial: action.errorFlag,
      };
    case UPDATE_ADS_FORM_DESC_ERROR:
      return {
        ...state,
        errorDesc: action.errorFlag,
      };
    case UPDATE_ADS_FORM_AD_RATING_ERROR:
      return {
        ...state,
        errorAdRating: action.errorFlag,
      };

    default:
      return state;
  }
};
