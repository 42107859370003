import { FC } from 'react';
import { CustomModal } from '../../../../../components/CustomModal';
import { NotesModal } from './NotesModal';

export const Notes: FC<any> = ({
  openModal,
  isNotesEditModal,
  showNotesEditModal,
  closeRebateNotesModal,
  selectedProduct,
  handleNotesChange,
  updateProductRebateNotes,
  rebatesModalSpinner,
}) => {
  const closeModal = () => {
    closeRebateNotesModal();
  };

  return (
    <CustomModal
      showModal={openModal}
      closeModal={closeModal}
      modalTitle={'Notes'}
      children={
        <NotesModal
          isNotesEditModal={isNotesEditModal}
          selectedProduct={selectedProduct}
          handleNotesChange={handleNotesChange}
        />
      }
      isCancelButton={isNotesEditModal ? true : false}
      isSubmitButton={isNotesEditModal ? true : false}
      isSubmitText={'Submit'}
      onSubmit={updateProductRebateNotes}
      isLoading={rebatesModalSpinner}
      isEditButton={true}
      showNotesEditModal={showNotesEditModal}
    />
  );
};
