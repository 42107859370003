import {
    UPDATE_REBATES_MODAL_SPINNER,
    UPDATE_REBATE_QUARTER_SPINNER,
    UPDATE_REBATES_ACTIVE_TAB,
    UPDATE_PRODUCT_REBATE_MODAL,
    UPDATE_PUBLISH_SPINNER,
    UPDATE_MEMBER_REBATE_MODAL,
    UPDATE_REBATE_OVERVIEW_SPINNER,
    UPDATE_REBATES_OVERVIEW_DATA
} from "../../constants";

const initialState = {
    activeTab: 0,
    overViewData: [],
    isProductRebateModal: false,
    quarterSpinner: false,
    publishSpinner: false,
    rebatesModalSpinner: false,
    isMemberRebateModal: false,
    overViewSpinner: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_REBATES_ACTIVE_TAB:
            return {
                ...state,
                activeTab: action.activeTab,
            };
        case UPDATE_REBATES_MODAL_SPINNER:
            return {
                ...state,
                rebatesModalSpinner: action.rebatesModalSpinner,
            };
        case UPDATE_PRODUCT_REBATE_MODAL:
            return {
                ...state,
                isProductRebateModal: action.isProductRebateModal,
            };
        case UPDATE_REBATE_QUARTER_SPINNER:
            return {
                ...state,
                quarterSpinner: action.quarterSpinner,
            };
        case UPDATE_MEMBER_REBATE_MODAL:
            return {
                ...state,
                isMemberRebateModal: action.isMemberRebateModal,
            };
        case UPDATE_PUBLISH_SPINNER:
            return {
                ...state,
                publishSpinner: action.publishSpinner,
            };
        case UPDATE_REBATE_OVERVIEW_SPINNER:
            return {
                ...state,
                overViewSpinner: action.overViewSpinner,
            };
        case UPDATE_REBATES_OVERVIEW_DATA:
            return {
                ...state,
                overViewData: action.overViewData,
            };
        default:
            return state;
    }
};
