import {
    UPDATE_REBATE_RECEIPT_EXPORT_QUARTER,
    UPDATE_REBATE_RECEIPT_EXPORT_YEAR,
    CLEAR_REBATE_RECEIPT_EXPORT_MODAL
} from "../../../constants";

export const handleRebateReceiptExportQuarter = (exportQuarter) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATE_RECEIPT_EXPORT_QUARTER,
        exportQuarter
    });
};

export const handleRebateReceiptExportYear = (exportYear) => async (dispatch) => {
    dispatch({
        type: UPDATE_REBATE_RECEIPT_EXPORT_YEAR,
        exportYear
    });
};

export const clearRebateReceiptExportModal = (exportYear) => async (dispatch) => {
    dispatch({
        type: CLEAR_REBATE_RECEIPT_EXPORT_MODAL
    });
};